import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { postTeamChartInspiredUsingTeamidAndChartid } from '../../../api/cloudApiGenerated';
import { getChartConfig } from '../../../pages/ChartEditorPage/selectors/chartEditor';
import { toggleInspiredAction } from '../../../redux/actions/projectConfig';
import { getProfileConfig } from '../../../redux/selectors/profile';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import PrimaryButton from '../../buttons/PrimaryButton';
import Modal from '../../modal/Modal';
import { snackBar } from 'editor/editors/highed.init';

export default function ChartExamplesModal() {
  const dispatch = useDispatch();
  const { toggleInspired, type } = useSelector(getProjectConfig);
  const { chartId, isMap } = useSelector(getChartConfig);
  const { team } = useSelector(getProfileConfig);

  const projectTypeText = isMap ? 'map' : type ?? 'project';

  const closeChartExamplesModal = () => {
    dispatch(toggleInspiredAction(false));
  };

  const share = async () => {
    await postTeamChartInspiredUsingTeamidAndChartid(team.id, chartId);
    snackBar('Project submitted');
    closeChartExamplesModal();
  };

  return (
    <Modal
      isOpen={toggleInspired}
      onClose={closeChartExamplesModal}
      title={`Share your ${projectTypeText} to chart examples`}
      width="w-4/12 max-w-xl"
      bg="bg-ev-yellow-1"
    >
      <p className="pb-6 m-0">
        Get more exposure on your work and inspire others - share your {projectTypeText} with our community on&nbsp;
        <a
          className="whitespace-nowrap font-medium"
          href="https://www.everviz.com/chart-examples/"
          target="_blank"
          rel="noreferrer"
        >
          everviz.com/chart-examples. <i className="fas fa-external-link" />{' '}
        </a>
      </p>

      <div className="flex gap-2">
        <PrimaryButton onClick={share} text="Confirm" />
        <PrimaryButton onClick={closeChartExamplesModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
      </div>
    </Modal>
  );
}
