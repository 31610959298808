import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import Modal from 'shared/modal/Modal';
import InputWidget from 'shared/widgets/InputWidget';
import SelectWidget from 'shared/widgets/SelectWidget';
import { updateThemeDataAction } from '../actions/themesPage';

interface Division {
  id: number;
  name: string;
  deleted?: number;
}

type ThemeModalProps = {
  isOpen: boolean;
  isCompanyTheme: boolean;
  theme: any;
  refreshThemes?: () => void;
  toggleModal: (toggle: boolean) => void;
};

const ThemeModal = ({ isOpen, isCompanyTheme, theme, refreshThemes, toggleModal }: ThemeModalProps) => {
  const dispatch = useDispatch();
  const { team, division, divisions } = useSelector((state: RootState) => state.profile);
  const [formData, setFormData] = useState<{ themeName: string; divisionId: number | null }>({
    themeName: '',
    divisionId: null
  });

  useEffect(() => {
    setFormData({
      themeName: theme.name ?? '',
      divisionId: theme.division ?? division.id ?? null
    });
  }, [theme.name, theme.division]);

  const handleModalClose = () => {
    toggleModal(false);
  };

  const handleThemeNameChange = (value: any) => {
    setFormData((prev) => ({ ...prev, themeName: value.val }));
  };

  const handleDivisionChange = (value: any) => {
    setFormData((prev) => ({ ...prev, divisionId: value.value }));
  };

  const handleUpdateThemeData = () => {
    dispatch(updateThemeDataAction({ team, theme, isCompanyTheme, formData, refreshThemes, handleModalClose }));
  };

  const parsedDivisions = divisions.map((division: Division) => ({ label: division.name, value: division.id }));

  return (
    <Modal
      title="Change theme details"
      isOpen={isOpen}
      onClose={handleModalClose}
      bg="bg-ev-yellow-1"
      allowOverflow={true}
      width="w-7/12 max-w-xl"
      bodyClass="overflow-auto p-8"
    >
      <div className="flex flex-col gap-y-2">
        <InputWidget placeholder="Name" label="Name:" onChange={handleThemeNameChange} value={formData.themeName} />
        {!isCompanyTheme && (
          <SelectWidget
            selectOptions={parsedDivisions}
            label="Subteam:"
            onChange={handleDivisionChange}
            value={formData.divisionId}
          />
        )}
        <hr />
        <div className="pt-6 flex gap-2">
          <PrimaryButton onClick={handleUpdateThemeData} text="Save" />
          <PrimaryButton onClick={handleModalClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
      </div>
    </Modal>
  );
};

export default ThemeModal;
