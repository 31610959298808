import React from 'react';

export default function MapIcon(props) {
  return (
    <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
      <path
        className="group-active:fill-[#AEC8FA]"
        d="m2.1312 23.771 3.486 5.2234c1.3533 2.0411 3.3864 3.5198 5.7297 4.1948l9.5628 2.7644c2.9091 0.7875 4.6609 3.3108 4.6609 6.1877v6.4128c0 1.7679 1.1412 3.3751 2.5716 4.018 1.7197 0.9322 2.5715 2.5394 2.5715 4.3073v6.2681c0 2.5072 2.5394 4.3073 4.9502 3.6162 2.6037-0.7393 4.5966-2.9412 5.2555-5.4163l0.4501-1.8c0.675-2.7162 2.4429-5.0467 4.8698-6.4449l1.3018-0.7394c2.4108-1.3661 3.8895-3.9215 3.8895-6.702v-1.3179c0-2.0572-0.8197-4.018-2.2662-5.4645l-0.6107-0.6107c-1.4465-1.4465-3.4234-2.4109-5.4645-2.4109h-7.0717c-1.6394 0-3.4073-0.3214-4.9663-1.2054l-5.5449-3.1822c-0.6911-0.3858-1.2214-1.0286-1.4625-1.784-0.5143-1.5429 0.1768-3.2305 1.6233-3.9537l0.9643-0.4661c1.0607-0.5304 2.2822-0.6268 3.2787-0.2411l3.8734 1.2375c1.3018 0.434 2.7483-0.0643 3.5197-1.2054 0.7554-1.1411 0.6751-2.6358-0.1928-3.6805l-2.1858-2.6036c-1.6072-1.9287-1.5912-4.7413 0.0482-6.6378l2.5233-2.954c1.4143-1.6506 1.6393-4.0116 0.5625-5.8984l-1.8804-3.2819c13.549 0.067503 25.33 7.6198 31.421 18.74l-6.5253 2.6037c-2.5233 1.0125-3.8251 3.8091-2.9733 6.3967l2.7965 8.3896c0.5143 1.5429 1.7197 2.7483 3.2626 3.2626l7.3771 2.459c-2.7965 17.101-17.647 30.006-35.535 30.006-19.881 0-36.001-15.976-36.001-36.001 0-4.1465 0.75217-8.2771 2.1312-12.086z"
        fill="#28277E"
      />
      <path
        className="group-hover:fill-[#fff] group-active:fill-[#fff]"
        d="m38.056 3.2819c1.0768 1.8869 0.8518 4.2494-0.5626 5.8984l-2.5233 2.954c-1.6393 1.8965-1.6554 4.7091-0.0482 6.6378l2.1858 2.6036c0.8679 1.0447 0.9483 2.5394 0.1929 3.6805-0.7715 1.1411-2.218 1.6394-3.5198 1.2054l-3.8734-1.2375c-0.9964-0.3857-2.2179-0.2893-3.2787 0.2411l-0.9643 0.4661c-1.4465 0.7393-2.1376 2.4108-1.6233 3.9537 0.2411 0.7554 0.7715 1.3983 1.4626 1.784l5.5449 3.1822c1.5589 0.884 3.3269 1.3501 4.9662 1.3501h7.0717c2.0412 0 4.018 0.8197 5.4645 2.2662l0.6108 0.6107c1.4464 1.4465 2.2661 3.4233 2.2661 5.4645v1.3179c0 2.7805-1.4786 5.3359-3.8894 6.702l-1.3019 0.7554c-2.4268 1.3822-4.1948 3.7127-4.8698 6.4289l-0.45 1.8c-0.659 2.4751-2.6519 4.677-5.2556 5.4163-2.4108 0.6911-4.9502-1.109-4.9502-3.6162v-6.2681c0-1.7679-0.8518-3.3751-2.5715-4.1627-1.4304-0.7875-2.5715-2.3947-2.5715-4.1626v-6.4128c0-2.8769-1.7519-5.4002-4.6609-6.1877l-9.5629-2.7644c-2.3433-0.675-4.3764-2.1537-5.7297-4.1948l-3.486-5.2234c5.0064-13.864 18.28-23.771 33.726-23.771h0.3214l1.8805 3.2819zm29.54 15.458c2.8126 5.127 4.4037 11.009 4.4037 17.261 0 1.9929-0.3053 3.9376-0.4661 5.8502l-7.377-2.459c-1.5429-0.5143-2.7483-1.7197-3.2627-3.2626l-2.7965-8.3896c-0.8518-2.5716 0.45-5.3842 2.9733-6.3967l6.5253-2.6037z"
        fill="#AEC8FA"
      />
    </svg>
  );
}
