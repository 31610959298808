export default {
  series: 3,
  data: 'Category;A;B;C\n0;4;6;3\n1;3;2;4\n2;5;4;3\n3;6;4;1\n4;2;4;6\n5;3;2;4',
  assignDataFields: [
    {
      labels: 'A',
      values: 'B'
    },
    {
      labels: 'A',
      values: 'C'
    },
    {
      labels: 'A',
      values: 'D'
    }
  ],
  seriesMapping: [
    {
      x: 0,
      y: 1
    },
    {
      x: 0,
      y: 2
    },
    {
      x: 0,
      y: 3
    }
  ]
};
