import { TemplateProps } from '../../types';

const StackedBarPercentWithLabels: TemplateProps = {
  title: 'Stacked bar percent w/ labels',
  description: '',
  constructor: '',
  thumbnail: 'izoqyx.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column',
      inverted: true,
      polar: false
    },
    plotOptions: {
      series: {
        stacking: 'percent',
        dataLabels: {
          enabled: true
        }
      }
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'bar',
  advancedParent: 'Stacked bar',
  dataValidator: false,
  advanced: {
    name: 'Percent + label'
  }
};

export default StackedBarPercentWithLabels;
