import React, { useState, useEffect, ChangeEvent } from 'react';
import SelectInput from '../inputs/SelectInput';
import AlertMessage from '../message/AlertMessage';
import TableRow from './TableRow';
import TablePagination from './TablePagination';
import { generateBottomButtons, generateBulkActions, getPageSizes } from 'shared/utils/tableHelper';
import { PageSizeSelectInputProps, PageSizesType, TableProps } from 'shared/types/tableModels';

const PageSizeSelectInput = ({ onSelectPageSize, pageSizes, pageSize }: PageSizeSelectInputProps) => (
  <SelectInput
    name="Items per page"
    onChange={onSelectPageSize}
    label="Items per page:"
    extraClass="table-page-size"
    options={pageSizes}
    value={pageSize.toString()}
  />
);

const Table = ({
  columns,
  rows,
  page,
  pageSize,
  pageCount,
  dataReturned,
  className,
  rowCount,
  bulkActions,
  pagePosition,
  editableColumns,
  disabledRowsIds,
  editText,
  errorMessage,
  hideLoading,
  bottomButtons,
  bulkActionsClassName,
  hasEditPermission,
  isActionCell,
  editActions,
  editActionsDisableIds,
  onRowSave,
  selectPageSize,
  selectPage,
  onRowDataChange
}: TableProps) => {
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [columnHeaders, setColumnHeaders] = useState<(string | JSX.Element)[]>([]);
  const [pageSizes, setPageSizes] = useState<PageSizesType>([]);

  useEffect(() => {
    if (Array.isArray(rows) && rows.length > 0) {
      setRowsData(rows);
    } else {
      setRowsData([]);
    }
  }, [rows]);

  useEffect(() => {
    const tempColumnHeaders = columns.map((column) => column.title);
    setColumnHeaders(tempColumnHeaders);
  }, [columns, rowsData, hasEditPermission]);

  useEffect(() => {
    const tempPageSizes = getPageSizes(rowCount, pageSize);
    setPageSizes(tempPageSizes);
  }, [rowCount, pageSize]);

  const onSelectPageSize = (event: ChangeEvent<HTMLSelectElement>) => {
    if (selectPageSize) {
      selectPageSize(parseInt(event.target.value, 10));
    }
  };

  const onSelectPage = (page: number) => {
    if (selectPage) {
      selectPage(page);
    }
  };

  const handleOnRowDataChange = (rowData: any) => {
    setRowsData((prev) => prev.map((prevRow) => (prevRow?.id === rowData?.id ? rowData : prevRow)));

    if (onRowDataChange) {
      onRowDataChange(rowData);
    }
  };

  return (
    <div className="text-ev-navy-blue">
      {selectPageSize && pageSize && pagePosition !== 'bottom' && (
        <PageSizeSelectInput onSelectPageSize={onSelectPageSize} pageSizes={pageSizes} pageSize={pageSize} />
      )}

      {bulkActions && (
        <div
          className={`min-h-[56px] py-2 pr-4 pl-4 bg-ev-grey shadow-ev-standard ev-sm:pl-6 ev-md:pl-10 ${
            bulkActionsClassName ?? ''
          }`}
        >
          {bulkActions.map((action: any, index: number) =>
            generateBulkActions(action, index, !!hasEditPermission, rowsData)
          )}
        </div>
      )}

      <table className={`w-full bg-white ${className ?? ''}`}>
        <thead className="hidden h-14 shadow-ev-standard ev-md:table-header-group">
          <tr>
            {columnHeaders.map((header, index) => (
              <th key={index} className={`align-middle px-4 ${index === 0 && 'pl-10'}`}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowsData.map((row, index) => {
            return (
              <TableRow
                key={index}
                columns={columns}
                editableColumns={editableColumns}
                row={row}
                onRowSave={onRowSave}
                editText={editText}
                columnHeaders={columnHeaders}
                isDisabled={!hasEditPermission || !!disabledRowsIds?.some((id) => id === row?.id)}
                isActionCell={isActionCell}
                onRowDataChange={handleOnRowDataChange}
                editActions={editActions}
                editActionsDisableIds={editActionsDisableIds}
              />
            );
          })}
          {rowsData.length === 0 && dataReturned && (
            <tr>
              <td className="h-14 text-center shadow-ev-standard" colSpan={columnHeaders.length}>
                <b>No data</b>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {rowsData.length < 1 && errorMessage && (
        <AlertMessage type="warning" text={errorMessage} tag="No data" extraClass="mt-4" />
      )}

      {typeof pageCount === 'number' && pageCount > 1 && (
        <div className="mt-4 w-full flex justify-center">
          <TablePagination pageCount={pageCount} currentPage={page ?? 1} onSelectPage={onSelectPage} />
        </div>
      )}

      {!dataReturned && !hideLoading && (
        <div className="center">
          <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          <span className="sr-only">Loading...</span>
        </div>
      )}

      {onSelectPageSize && pageSize && pagePosition === 'bottom' && (
        <PageSizeSelectInput onSelectPageSize={onSelectPageSize} pageSizes={pageSizes} pageSize={pageSize} />
      )}

      {bottomButtons &&
        bottomButtons.map((action: any, index: number) => generateBottomButtons(action, index, rowsData))}
    </div>
  );
};

export default Table;
