import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import { setAction as setProjectConfigAction } from 'redux/actions/projectConfig';
import { getTeamPackagesAction, searchTeamPackagesAction } from './actions/packagesPage';
import { RootState } from 'redux/store';
import { isArray } from 'lodash';
import { Package } from './types';
import PackageSection from './components/PackageSection';
import InputSearch from 'shared/inputs/InputSearch';

const PackagesPage = () => {
  const dispatch = useDispatch();
  const { packages } = useSelector((state: RootState) => state.packagesPage);

  const [searchTerm, setSearchTerm] = useState('');
  const [time, setTime] = useState('');

  const handleSearch = (e?: any) => {
    if (e) e.preventDefault();
    if (searchTerm) dispatch(searchTeamPackagesAction({ query: searchTerm }));
  };

  useEffect(() => {
    const currentTime = new Date().toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    setTime(currentTime);
  }, [packages]);

  const handleReset = () => {
    setSearchTerm('');
    dispatch(getTeamPackagesAction());
  };

  useEffect(() => {
    dispatch(getTeamPackagesAction());

    dispatch(
      setProjectConfigAction({
        inPackagesMode: true
      })
    );
  }, []);

  return (
    <ProfilePageContainer
      title="Packages"
      header={'Packages'}
      description={'The packages page features ready-made projects designed for various major events.'}
    >
      <ProfilePageSectionContainer>
        <div className="charts-search mb-4 ev-sm:mb-6 rounded shadow-ev-standard">
          <form onSubmit={(e) => handleSearch(e)}>
            <InputSearch
              label="Search by title"
              placeholder="Search by title"
              value={searchTerm}
              reset={handleReset}
              onChange={setSearchTerm}
              onClick={handleSearch}
              simple={false}
              className="p-4"
            />
          </form>
        </div>

        {isArray(packages) &&
          packages.map((packageDetails: Package, index) => {
            return (
              <PackageSection
                packageDetails={packageDetails}
                index={index}
                key={`package-${packageDetails.uuid}-${index}-${time}`}
              />
            );
          })}

        {!isArray(packages) && (
          <div>
            You have no package. Email&nbsp;
            <a title="email sales" href="mailto:sales@everviz.com">
              sales@everviz.com
            </a>
            &nbsp;for more information
          </div>
        )}
      </ProfilePageSectionContainer>
    </ProfilePageContainer>
  );
};

export default PackagesPage;
