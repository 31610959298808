import { OptionProps } from '../CustomizeOptions';

export const getRichtextElement = (container: HTMLElement, type: string) => {
  return container.querySelectorAll(`#ev-map-${type} .layout-${type}-container .caption`) ?? [];
};

export const textOptions: OptionProps[] = [
  {
    id: 'text-category',
    pid: 'text.category',
    text: 'option.subcat.text',

    options: [
      {
        id: 'title.text',
        pid: 'title.text',
        type: 'richtextgroup',
        default: {
          text: 'Map title'
        },
        richtext: {
          rawId: 'title',
          rawKey: 'text'
        },
        inline: {
          source: (container: HTMLElement) => getRichtextElement(container, 'title')
        },
        className: 'title-text',
        nestedValue: ['global.style.fontFamily'] // Currently only used in fontpicker widget
      },

      {
        id: 'subtitle.text',
        richtext: {
          rawId: 'subtitle',
          rawKey: 'text'
        },
        pid: 'subtitle.text',
        type: 'richtextgroup',
        inline: {
          source: (container: HTMLElement) => getRichtextElement(container, 'subtitle')
        },
        className: 'subtitle-text',
        nestedValue: ['global.style.fontFamily']
      },
      {
        id: 'caption.text',
        richtext: {
          rawId: 'caption',
          rawKey: 'text'
        },
        pid: 'caption.text',
        type: 'richtextgroup',
        inline: {
          source: (container: HTMLElement) => getRichtextElement(container, 'caption')
        },
        className: 'caption-text',
        nestedValue: ['global.style.fontFamily']
      }
    ]
  }
];

const options: Record<string, OptionProps[]> = {
  'option.cat.text': textOptions,

  'option.cat.appearance': [
    {
      id: 'text-category',
      pid: 'text.category',
      text: 'option.subcat.appearance',
      options: [
        {
          id: 'global.style',
          pid: 'chart.style',
          type: 'fontpicker',
          custom: {
            simple: true
          },
          default: {
            fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
            fontSize: '12px'
          }
        },
        {
          id: 'minienabled',
          pid: 'minienabled',
          type: 'checkbox',
          default: false
        }
      ]
    }
  ],
  'option.cat.interactivity': [
    {
      id: 'text-category',
      pid: 'text.category',
      text: 'option.subcat.interactivity',
      options: [
        {
          id: 'map.mapNavigation.enabled',
          pid: 'mapNavigation.enabled',
          type: 'checkbox',
          default: false
        },
        {
          id: 'map.fullScreenControls.enabled',
          pid: 'map.fullScreenControls.enabled',
          type: 'checkbox',
          default: false
        },
        {
          id: 'map.scaleControls.enabled',
          pid: 'map.scaleControls.enabled',
          type: 'checkbox',
          default: false
        },
        {
          id: 'map.geolocateControls.enabled',
          pid: 'map.geolocateControls.enabled',
          type: 'checkbox',
          default: false
        }
      ]
    }
  ],

  'option.cat.animation': [
    {
      text: 'option.util.empty',

      options: [
        {
          id: 'map.animation.duration',
          pid: 'plotOptions.series.animation.duration',
          type: 'number',
          default: 1000,
          useCustomizedOptions: true,
          custom: {
            minValue: 0,
            maxValue: 9000
          }
        },
        {
          id: 'play.animation',
          pid: 'play.animation',
          type: 'action'
        }
      ]
    }
  ],

  'option.cat.map-layers': [
    {
      id: 'text-category',
      pid: 'text.category',
      text: 'option.subcat.map-layers',
      options: [
        {
          pid: 'option.util.empty',
          text: 'option.util.empty',
          type: 'array<checkbox>',
          id: 'style.layers',
          rawKey: 'enabled',
          className: 'bg-white',
          custom: {
            hideAddButton: true,
            hideDeleteButtons: true,
            useNameValueAsLabel: true
          }
        }
      ]
    }
  ]
};

export default options;
