import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from 'static/icons/add.svg';
import { beginAddMarkerWorkflowAction } from '../../../../pages/ChartEditorPage/actions/locationMap';
import { ProjectConfigLocationMapProps } from '../../../../pages/Editor/reducers/locationMapConfigTypes';
import { selectEditorMapRef } from '../../../../redux/reducers/locationMap/instanceReducer';
import { getProjectConfig } from '../../../../redux/selectors/projectConfig';
import SvgIconButton from '../../../buttons/SvgIconButton';
import TextButton from '../../../buttons/TextButton';
import { ButtonColor } from '../../../buttons/types/ButtonModels';
import { SectionNode } from '../SectionNode';
import { useSectionStore } from '../sectionStore';
import { MarkerSection } from './MarkerSection';

export type MarkersSectionProps = {
  showContainer?: boolean;
  openedByDefault?: boolean;
};
const sectionKey = 'markers';

export const MarkersSection = ({ showContainer, openedByDefault }: MarkersSectionProps) => {
  const dispatch = useDispatch();

  const { aggregatedOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);
  const editorMapInstance = useSelector(selectEditorMapRef);

  const featureAmount = aggregatedOptions.markers.length;

  const setSelectedMarkerSection = useSectionStore((state) => state.setSelectedSection);

  useEffect(() => {
    if (!editorMapInstance) return;
    const onMarkerClick = (id: string) => {
      setSelectedMarkerSection(id);
    };

    editorMapInstance.on('markerClick', onMarkerClick);

    return () => {
      editorMapInstance.off('markerClick', onMarkerClick);
    };
  }, [editorMapInstance]);

  const addMarker = () => {
    dispatch(
      beginAddMarkerWorkflowAction({
        type: 'marker'
      })
    );
  };

  const childrenSectionKeys = useMemo(() => {
    return aggregatedOptions.markers.map((x) => x.id);
  }, [aggregatedOptions.markers]);

  return (
    <SectionNode
      showContainer={showContainer}
      sectionKey={sectionKey}
      childrenSectionKeys={childrenSectionKeys}
      headerText="Markers"
      openedByDefault={openedByDefault}
      selectedChildren={featureAmount.toString()}
    >
      <div className="flex flex-col gap-2">
        {aggregatedOptions.markers.map((marker) => {
          return <MarkerSection key={marker.id} sectionKey={marker.id} marker={marker} parentSectionKey={sectionKey} />;
        })}
      </div>
      <div className="flex items-center justify-end pr-1 py-3">
        <TextButton text="Add marker" onClick={addMarker} className="text-sm" />
        <SvgIconButton
          width={12}
          height={12}
          onClick={addMarker}
          buttonClasses="ml-2 p-2 rounded-full"
          Icon={AddIcon}
          buttonColor={ButtonColor.NavyBlue}
        />
      </div>
    </SectionNode>
  );
};
