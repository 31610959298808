import React from 'react';
import ContentEditable from 'react-contenteditable';
import Buttons from '../containers/Buttons';
import Carousel from './Carousel';
import ChartModal from './ChartModal';
import DraggableComponent from './DraggableComponent';
import Slider from './Slider';

class LayoutComponent extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.changeText = this.changeText.bind(this);
    this.changeSubtitleText = this.changeSubtitleText.bind(this);
    this.changeCaptionText = this.changeCaptionText.bind(this);
    this.addChartToGrid = this.addChartToGrid.bind(this);
    this.addImageToGrid = this.addImageToGrid.bind(this);

    this.onRemove = this.onRemove.bind(this);

    this.state = {
      smallWidth: 500
    };
  }

  onRemove(section) {
    this.props.remove(section);
  }

  changeText(e) {
    this.props.changeText(e, this.props.customizedOptions, 'title');
  }
  changeSubtitleText(e) {
    this.props.changeText(e, this.props.customizedOptions, 'subtitle');
  }
  changeCaptionText(e) {
    this.props.changeText(e, this.props.customizedOptions, 'caption');
  }

  addChartToGrid(chart) {
    this.props.addChartToGrid(chart, this.props.parentToAttachChartTo, this.props.dataOptions);
  }
  addImageToGrid(image) {
    this.props.addImageToGrid(image, this.props.parentToAttachChartTo, this.props.dataOptions);
  }

  render() {
    const {
      dataOptions,
      templateOptions,
      templateUsed,
      aggregatedOptions,
      isCarouselOrLinks,
      isSlider,
      titleStyle,
      subtitleStyle,
      captionStyle,
      canvasStyle,
      logoStyle,
      setting,
      showWizard
    } = this.props;

    const parentSelector = () => document.querySelector('.story');
    return (
      <>
        <ChartModal
          key={this.props.parentToAttachChartTo}
          team={this.props.team}
          parentSelector={parentSelector}
          isOpen={this.props.isChartModalOpen}
          addChartToGrid={this.addChartToGrid}
          addImageToGrid={this.addImageToGrid}
          close={this.props.toggleChartModal}
          dataOptions={dataOptions}
          showImageInput={isSlider}
          checkPermission={this.props.checkPermission}
        />
        <div
          className={
            'canvas-container overflow-auto ev-sm-max:h-full ' +
            (showWizard ? ' ev-sm:h-[500px] ' : '') +
            (aggregatedOptions && aggregatedOptions.layout ? aggregatedOptions.layout.type : '')
          }
          style={this.props.resolution}
        >
          <div
            className={
              'upper-container ' + (aggregatedOptions.title && aggregatedOptions.title.text !== '' ? '' : ' hidden')
            }
            style={titleStyle}
          >
            <div
              className={
                'layout-title-container ' +
                (aggregatedOptions && aggregatedOptions.layout
                  ? aggregatedOptions.layout.buttonPosition + ' ' + aggregatedOptions.layout.auto
                  : '')
              }
            >
              <div className="page-caption">
                <ContentEditable
                  className="caption inline-component"
                  placeholder={'[Insert Title Here]'}
                  html={(aggregatedOptions.title && aggregatedOptions.title.text) || ''}
                  onChange={this.changeText}
                  suppressContentEditableWarning={true}
                  style={titleStyle}
                />
              </div>
            </div>
          </div>

          <div
            className={
              'subtitle-container ' +
              (aggregatedOptions.subtitle && aggregatedOptions.subtitle.text !== '' ? '' : ' hidden')
            }
            style={subtitleStyle}
          >
            <div className={'layout-subtitle-container '}>
              <div className="page-caption">
                <ContentEditable
                  className="caption subtitle"
                  html={(aggregatedOptions.subtitle && aggregatedOptions.subtitle.text) || ''}
                  onChange={this.changeSubtitleText}
                  suppressContentEditableWarning={true}
                  style={subtitleStyle}
                />
              </div>
            </div>
          </div>
          <div className={'canvas'} id="canvas" style={canvasStyle}>
            {isCarouselOrLinks && (
              <Carousel
                dataOptions={dataOptions}
                templateOptions={templateOptions}
                aggregatedOptions={aggregatedOptions}
                templateUsed={templateUsed}
                onRemove={this.onRemove}
              />
            )}
            {isSlider && (
              <Slider
                dataOptions={dataOptions}
                templateOptions={templateOptions}
                aggregatedOptions={aggregatedOptions}
                templateUsed={templateUsed}
                onRemove={this.onRemove}
              />
            )}

            {/* Block does not yet have an item. Show placeholder */}
            {!isCarouselOrLinks &&
              !isSlider &&
              dataOptions.length > 0 &&
              dataOptions.map((section, sectionIndex) => {
                const onRemove = () => {
                  this.onRemove(sectionIndex);
                };

                const getResolutionStyling = () => {
                  if (
                    templateOptions[sectionIndex]?.smallStyle &&
                    this.props.resolution.width !== null &&
                    this.props.resolution.width <= this.state.smallWidth
                  ) {
                    return templateOptions[sectionIndex].smallStyle;
                  }
                  return templateOptions[sectionIndex]?.style ?? {};
                };

                return (
                  <div
                    key={sectionIndex}
                    className={'section ' + (setting && setting[sectionIndex].settings.classes)}
                    style={getResolutionStyling()}
                  >
                    {section.hash && (
                      <DraggableComponent
                        type={'img'}
                        onRemove={onRemove}
                        option={section}
                        id={sectionIndex}
                        aggregatedOptions={aggregatedOptions}
                      />
                    )}
                    {!section.hash && <Buttons section={section} sectionIndex={sectionIndex} />}
                  </div>
                );
              })}
          </div>

          <div
            className={
              'caption-container ' +
              (aggregatedOptions.caption && aggregatedOptions.caption.text !== '' ? '' : ' hidden')
            }
            style={captionStyle}
          >
            <div className={'layout-caption-container '}>
              <div className="page-caption">
                <ContentEditable
                  className="caption subtitle"
                  html={(aggregatedOptions.caption && aggregatedOptions.caption.text) || ''}
                  onChange={this.changeCaptionText}
                  suppressContentEditableWarning={true}
                  style={captionStyle}
                />
              </div>
            </div>
          </div>

          {aggregatedOptions?.logo?.url && aggregatedOptions.logo.url !== 'undefined' && (
            <div
              className={'logo-container ' + aggregatedOptions.logo.position}
              style={{ ...logoStyle, width: aggregatedOptions.logo.width, minWidth: aggregatedOptions.logo.minWidth }}
            >
              <img src={aggregatedOptions.logo.url} />
            </div>
          )}
        </div>
      </>
    );
  }
}

LayoutComponent.propTypes = {};

export default LayoutComponent;
