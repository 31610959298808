import React, { useState } from 'react';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { LocatorResult } from '../../../editor/core/highed.mapapi';
import RadioGroup from '../../inputs/RadioGroup';
import Modal from '../../modal/Modal';

type LocatorModalProps = {
  locatorResults: LocatorResult[];
  showLocatorModal: boolean;
  closeLocatorModal: () => void;
  addPoint: (point: LocatorResult) => void;
};

const LocatorModal = ({ locatorResults, showLocatorModal, closeLocatorModal, addPoint }: LocatorModalProps) => {
  const [value, setValue] = useState(0);

  const onChange = (i: string) => {
    setValue(parseInt(i, 10));
  };

  const selectLocation = () => {
    addPoint(locatorResults[value]);
    closeLocatorModal();
    setValue(0);
  };

  return (
    <Modal
      isOpen={showLocatorModal}
      onClose={closeLocatorModal}
      title={'Select point'}
      width={'w-7/12 max-w-xl'}
      bg={'bg-ev-yellow-1'}
      bodyClass={'px-8 py-6'}
    >
      <RadioGroup
        options={locatorResults || []}
        checked={value}
        groupName="points"
        onSelect={onChange}
        type={'radio-list'}
        selectedClassName={null}
      />

      <div className="flex mt-6">
        <PrimaryButton text="Add data point to map" onClick={selectLocation} className="mr-4" />
        <PrimaryButton text="Cancel" onClick={closeLocatorModal} buttonColor={ButtonColor.BabyBlue} />
      </div>
    </Modal>
  );
};

export default LocatorModal;
