import React from 'react';
import AnnotationLabelWidget from './AnnotationLabelWidget';
import AnnotationHighlightWidget from './AnnotationHighlightWidget';
import AnnotationLineWidget from './AnnotationLineWidget';
import { OptionProps } from 'pages/ChartEditorPage/meta/CustomizeOptions';

export default (props: { option: OptionProps }) => {
  const labelIndex = false;
  const active = false;
  const { option } = props;

  return (
    <>
      <AnnotationLabelWidget option={option?.options?.[0]} active={active} labelIndex={labelIndex} />
      <AnnotationHighlightWidget option={option?.options?.[1]} active={active} labelIndex={labelIndex} />
      <AnnotationLineWidget option={option?.options?.[2]} active={active} labelIndex={labelIndex} />
    </>
  );
};
