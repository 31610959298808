import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { PlanFeature } from 'pages/SubscriptionPage/types/plansModels';
import {
  TrialUIInfo,
  getTrialPlanUIInfo,
  getFormattedPlanFeatures,
  getPlanById,
  nextPlanMap,
  getRemainingDays
} from 'pages/SubscriptionPage/utils/plansHelper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import Accordion from 'shared/Accordion';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { AccordionType } from 'shared/utils/accordionHelper';
import PageDimmer from 'shared/wizard/components/PageDimmer';
import StarIcon from 'static/icons/star.svg';

export default function TopBarUpsell() {
  const [open, setOpen] = useState(false);
  const [trialUIInfo, setTrialUIInfo] = useState<TrialUIInfo>();
  const history = useHistory();
  const { chartsCreated, team, subscription } = useSelector((state: RootState) => state.profile);
  const paths = useSelector((state: RootState) => state.app.paths);

  const onStoragePlan = subscription?.id === 17;
  const currentPlan = getPlanById(subscription?.id);
  const nextPlan = getPlanById(nextPlanMap[subscription?.id]);
  const nextPlanFeatures: PlanFeature[] = getFormattedPlanFeatures(nextPlan) ?? [];
  const isProjectsLimit = chartsCreated >= team?.charts_max || (trialUIInfo && trialUIInfo.remainingDays < 1);

  const readPercentOverfilled = () => {
    if (trialUIInfo) return trialUIInfo.dayPercent;

    const min = chartsCreated || 0;
    const max = team ? team.charts_max : 10;
    return Math.min((min / max) * 100, 100);
  };

  const projectsProgressClasses = classNames('h-1 progress-charts-bar rounded-full', {
    danger: !isProjectsLimit,
    'bg-ev-red': isProjectsLimit
  });

  const toggle = () => {
    setOpen(!open);
  };

  const goToSubscriptionPage = () => {
    setOpen(false);
    history.push(paths.profileSubscription);
  };

  const close = () => setOpen(false);

  useEffect(() => {
    if (subscription) {
      const remainingDays = getRemainingDays(subscription.period_end_time);
      setTrialUIInfo(getTrialPlanUIInfo(currentPlan, remainingDays, subscription));
    }
  }, [subscription, currentPlan]);

  return (
    <div className="content-centered min-w-[200px]">
      {open && <PageDimmer onClick={close} className="opacity-0" />}
      <Accordion
        accordionClasses={
          'bg-ev-light-orange relative w-full group hover:bg-ev-baby-blue-2 hover:text-ev-navy-blue-2 active:bg-ev-navy-blue-2 active:text-white active:border-ev-navy-blue-2'
        }
        accordionType={AccordionType.Compact}
        childrenClasses="absolute z-[10000000] w-full p-0 m-0 mt-[45px] top-[-5px] flex-col"
        headerText={
          <div className="flex-col w-full px-3 py-1 text-ev-navy-blue bg-ev-light-orange rounded group-hover:bg-ev-baby-blue-2 group-hover:text-ev-navy-blue-2  group-active:bg-ev-navy-blue-2 group-active:text-white">
            {!onStoragePlan && (
              <div className="flex justify-between w-full space-x-2 transition">
                <p className="text-xs mb-2">
                  {trialUIInfo && trialUIInfo.remainingDays > 0 && (
                    <>
                      <span className="font-normal"> {trialUIInfo.text} </span>

                      {trialUIInfo.dayPercent !== 100 && (
                        <span className="text-xs font-bold">
                          {trialUIInfo.remainingDays} {trialUIInfo.remainingDays === 1 ? 'day' : 'days'}
                        </span>
                      )}
                    </>
                  )}

                  {trialUIInfo && trialUIInfo.remainingDays < 1 && (
                    <span
                      className="font-normal"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(trialUIInfo.endText) }}
                    />
                  )}

                  {!trialUIInfo && (
                    <>
                      <span className="text-xs font-bold">
                        {chartsCreated ?? 0} of {team?.charts_max}{' '}
                      </span>
                      <span className="font-normal">projects used</span>
                    </>
                  )}
                </p>
              </div>
            )}

            {onStoragePlan && <p className="hidden mb-0 py-2 text-xs font-bold md:block">Storage Plan</p>}

            {!onStoragePlan && (
              <div className="w-36 h-1 mb-1 bg-white rounded-full transition">
                <div className={projectsProgressClasses} style={{ width: `${readPercentOverfilled()}%` }} />
              </div>
            )}
          </div>
        }
        selected={open}
        onClick={toggle}
      >
        <div className="p-4 pt-2 m-0 text-ev-navy-blue bg-ev-light-orange rounded">
          <p className="text-xs font-medium text-ev-navy-blue">
            Your subscription:&nbsp;
            <span className="text-xs font-bold">
              {onStoragePlan ? 'Storage' : trialUIInfo ? trialUIInfo.shortName : currentPlan}
            </span>
          </p>

          <hr className="my-2 border-t-ev-red-2" />

          {trialUIInfo && (
            <span className="text-xs mb-2 font-bold flex gap-1 items-center">Continue with {nextPlan} plan:</span>
          )}

          {!trialUIInfo && (
            <p className="text-xs mb-2 font-bold flex gap-1 items-center">
              <StarIcon width={17} height={16} /> Upgrade to {nextPlan}:
            </p>
          )}

          <ul className="pl-0 space-y-2 list-none">
            {nextPlanFeatures.map(({ text }, i) => (
              <li className="text-xs flex items-center" key={`upsell_${i}`}>
                <span className="text-xs font-bold pr-1">+&nbsp;</span>
                <span className="text-ev-navy-blue" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />
              </li>
            ))}
          </ul>

          <PrimaryButton
            text="Go to subscriptions"
            onClick={goToSubscriptionPage}
            buttonType={ButtonType.Info}
            buttonColor={ButtonColor.White}
            className="text-xs w-full"
          />
        </div>
      </Accordion>
    </div>
  );
}
