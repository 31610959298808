import { TemplateProps } from '../../types';

const StackedBarPercent: TemplateProps = {
  title: 'Stacked percent bar',
  description: '',
  constructor: '',
  thumbnail: 'yhekaq.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column',
      inverted: true,
      polar: false
    },
    plotOptions: {
      series: {
        stacking: 'percent'
      }
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'bar',
  advancedParent: 'Stacked bar',
  dataValidator: false,
  advanced: {
    name: 'Percent'
  }
};

export default StackedBarPercent;
