import React from 'react';
import Button from 'shared/buttons/Button';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import InputSearch from 'shared/inputs/InputSearch';
import TextInput from 'shared/inputs/TextInput';
import { PageSizesType } from 'shared/types/tableModels';
import SelectWidget from 'shared/widgets/SelectWidget';

export const getPageSizes = (rowCount: number | undefined, pageSize: number | undefined) => {
  const tempPageSizes: PageSizesType = [];
  const maxSize = 100;
  const tempRowCount = rowCount || pageSize;

  if (tempRowCount) {
    for (let size = 10; size < tempRowCount && size < maxSize; size += 10) {
      tempPageSizes.push({
        value: size,
        text: size
      });
    }
    if (tempRowCount < maxSize) {
      tempPageSizes.push({
        value: tempRowCount,
        text: 'Show all'
      });
    }
  }

  return tempPageSizes;
};

export const generateBulkActions = (action: any, index: number, hasEditPermission: boolean, rowsData: any) => {
  const bulkAction = () => {
    if ((Array.isArray(rowsData) && rowsData.length > 0) || action.bypassCheck) {
      action.onClick(rowsData);
    }
  };

  if (action.type) {
    if (action.type === 'select') {
      return (
        <SelectWidget
          key={index}
          label={action.text}
          selectOptions={action.options}
          onChange={action.onChange}
          className={action.className}
          value={action.value}
          isDisabled={!action.hasPermission}
          hideLabel={true}
          inlineSelect={true}
        />
      );
    } else if (action.type === 'input') {
      return (
        <TextInput
          name={action.text}
          label=""
          key={index}
          inlineLabel={action.text}
          extraClass={`bulk-select bulk-input m-0 p-0 ' + ${action.className ?? ''}`}
          value={action.value}
          onChange={action.onChange}
          placeholder={action.placeholder}
        />
      );
    } else if (action.type === 'icon-button') {
      return (
        <IconButton
          key={index}
          icon={action.buttonIcon}
          className={action.className}
          buttonColor={ButtonColor.TransparentNavyBlue2}
          hoverText={action.text}
          onClick={bulkAction}
          disabled={!hasEditPermission || action.disabled}
        />
      );
    } else if (action.type === 'search') {
      return (
        <InputSearch
          className={action.className}
          value={action.value}
          placeholder={action.text}
          onClick={action.onClick}
          onChange={action.onChange}
          reset={action.reset}
        />
      );
    }
  } else {
    return (
      <Button
        key={index}
        extraClass={`bulk-action bulk-action-btn m-0 ' + ${action.className ?? ''}`}
        hasPermission={action.hasPermission}
        buttonText={action.text}
        onClick={bulkAction}
        icon={action.buttonIcon}
        iconClass={action.iconClass}
        iconLeft={true}
        disabled={action.hasPermission !== undefined && !action.hasPermission ? false : action.disabled || false}
      />
    );
  }
};

export const generateBottomButtons = (action: any, index: number, rowsData: any) => {
  const bulkAction = () => {
    if ((rowsData && rowsData.length > 0) || action.bypassCheck) {
      action.onClick(rowsData);
    }
  };

  return (
    <Button
      key={index}
      extraClass="bulk-action bulk-action-btn pull-right"
      buttonText={action.text}
      onClick={bulkAction}
      disabled={action.disabled || false}
    />
  );
};
