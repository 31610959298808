import { cloneDeep, get, merge, set } from 'lodash';
import { getSeriesType } from 'shared/wizard/utils/seriesHelper';

const actions = {
  locationMap: {
    chart: (userAggregatedOptions) => {
      return userAggregatedOptions;
    }
  },
  highcharts: {
    chart: (userAggregatedOptions, chart) => {
      const options =
        chart?.options || chart?.userOptions
          ? merge({}, chart.options ? chart.options : chart.userOptions)
          : merge({}, userAggregatedOptions ?? {});

      return parseConfigOptions(options, userAggregatedOptions);
    }
  }
};

// There are options in Highcharts that stack in multiple places
// to make up the final configuration for the option. Merge all these
// together so the values are correct for whichever you click.

// Properties that stack (If you find more, add them to the list below):
// 1) PlotOptions series -> Plot Options type -> Series
// 2) Tooltip -> PlotOptions series -> Plot Options type -> Series

const getAggregatedPlotOptions = (chartOptions, userOptions, chartPlotOptionSeries, userPlotOptionSeries, key) => {
  const chartPlotOptionsType = cloneDeep(get(chartOptions, key) ?? {});
  const userPlotOptionsType = cloneDeep(get(userOptions, key) ?? {});
  return merge(
    merge(cloneDeep(chartPlotOptionSeries), cloneDeep(chartPlotOptionsType)),
    merge(cloneDeep(userPlotOptionSeries), cloneDeep(userPlotOptionsType))
  );
};

// Series properties can be set in:
// 1) series[n]
// 2) plotOptions[type]
// 3) plotOptions.series
export const mergeSeriesOptions = (chartOptions, userOptions, seriesTypes, uniqueSeriesTypes) => {
  const chartPlotOptionSeries = get(chartOptions, 'plotOptions.series') ?? {};
  const userPlotOptionSeries = get(userOptions, 'plotOptions.series') ?? {};
  mergeTooltipOptions(chartOptions);

  // Update plotOptions[type]
  (uniqueSeriesTypes ?? []).forEach((type) => {
    const typeKey = `plotOptions.${type}`;
    const plotOptions = getAggregatedPlotOptions(
      chartOptions,
      userOptions,
      chartPlotOptionSeries,
      userPlotOptionSeries,
      typeKey
    );

    set(chartOptions, typeKey, plotOptions);
  });

  // Update series[n]
  (chartOptions.series ?? []).forEach((series, index) => {
    const typeKey = `plotOptions.${seriesTypes[index]}`;
    const plotOptions = getAggregatedPlotOptions(
      chartOptions,
      userOptions,
      chartPlotOptionSeries,
      userPlotOptionSeries,
      typeKey
    );
    const key = `series[${index}]`;
    const seriesOptions = merge(plotOptions, cloneDeep(series));
    set(chartOptions, key, seriesOptions);
  });

  return chartOptions;
};

// Tooltip has its own `tooltip` object at the root of options
// Merge this into plotOptions.series.tooltip first so it can be picked
// up when merging all the rest later.
export const mergeTooltipOptions = (chartOptions) => {
  const tooltipOption = cloneDeep(chartOptions.tooltip ?? {});
  const plotOptionsSeriesTooltip = cloneDeep(get(chartOptions, 'plotOptions.series.tooltip') ?? {});
  let tooltip = merge(tooltipOption, plotOptionsSeriesTooltip);
  set(chartOptions, 'plotOptions.series.tooltip', tooltip);
};

export const parseConfigOptions = (chartOptions, userOptions) => {
  const seriesTypes = getSeriesType(chartOptions);
  const uniqueSeriesTypes = [...new Set(seriesTypes)];

  return mergeSeriesOptions(chartOptions, userOptions, seriesTypes, uniqueSeriesTypes);
};

export const getAggregatedOptionsFunction = (provider, type) => actions[provider][type];
