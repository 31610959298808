import React from 'react';
import StarIcon from 'static/icons/star.svg';

type PremiumFeatureProps = {
  className?: string;
};

export default function PremiumFeature(props: PremiumFeatureProps) {
  return (
    <div
      className={`bg-ev-blue text-white font-bold rounded-md max-w-[180px] p-1.5 flex gap-2 items-center px-4 ${props.className}`}
    >
      <StarIcon width={17} height={16} />
      Premium feature
    </div>
  );
}
