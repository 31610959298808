import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect, { SelectItem, customSelectColors } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import { updateCustomizedAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { RootState } from 'redux/store';
import { dateFormatOptions } from 'shared/meta/dateFormatOptions';

const DateFormatTab = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: RootState) => state.projectConfig.aggregatedOptions);
  const { dateFormat } = data ?? {};

  const handleDateFormatChange = (selected: SelectItem | null) => {
    const val = selected?.value;
    const optionProps = { id: 'data.dateFormat' };
    dispatch(updateCustomizedAction({ optionProps, val }));
  };

  const getSelectedDateFormat = () => {
    const option = dateFormatOptions.find((x) => x.value === dateFormat);
    return option ?? dateFormatOptions[0];
  };

  return (
    <div>
      <CustomSelect
        label="Date format"
        containerClasses="my-2"
        value={getSelectedDateFormat()}
        options={dateFormatOptions}
        styles={editorStyles}
        onChange={(selected) => handleDateFormatChange(selected)}
        backgroundColor={customSelectColors.white}
      />
    </div>
  );
};

export default DateFormatTab;
