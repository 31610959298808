import classNames from 'classnames';
import React from 'react';

type LinkButtonProps = {
  text: string;
  onClick: React.MouseEventHandler<HTMLSpanElement>;
  className?: string;
  thin?: boolean;
  medium?: boolean;
};

const TextButton = ({ text, onClick, className, thin = false, medium = false }: LinkButtonProps) => {
  const classes = classNames(
    'underline cursor-pointer font-bold text-ev-navy-blue-2 hover:text-ev-baby-blue-2',
    className,
    {
      'font-normal': thin,
      'font-medium': medium
    }
  );

  return (
    <span onClick={onClick} className={classes}>
      {text}
    </span>
  );
};

export default TextButton;
