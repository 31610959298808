export default (type, data, config, headers) => {
  return {
    chart: {
      type: type,
      height: 70,
      margin: [20, 0, 20, 35],
      events: {
        load: function () {
          const main = () => {
            this.series.forEach((s) => {
              applyEndpoints(s, ...makeEndpoints());
            });
          };

          const applyEndpoints = (s, left, right) => {
            const lastPointIndex = s.points.filter((p) => !p.isNull).length - 1;

            s.points[lastPointIndex].update(right);
            s.points[0].update(left);
          };

          const makeEndpoints = () => {
            const marker = {
              marker: {
                enabled: true,
              },
            };

            if ([true, undefined].includes(config.showStartEndValues)) {
              return [useDataLabel(-5, 'right', marker), useDataLabel(5, 'left', marker)];
            }

            return [marker, marker];
          };

          const useDataLabel = (x, align, base) =>
            Object.assign(
              {
                dataLabels: {
                  x: x,
                  align: align,
                  enabled: true,
                },
              },
              base
            );

          main();
        },
      },
    },
    series: [
      {
        ...([true, undefined].includes(config.smoothenLines) && {
          type: type === 'line' ? 'spline' : 'areaspline',
        }),
        data: data.map((d) => parseFloat(typeof d === 'string' ? d.replace(/ /g, '').replace(/,/g, '.') : d)),
        pointStart: 1,
        label: {
          enabled: false,
        },
      },
    ],
    xAxis: {
      visible: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          style: {
            fontSize: '13px',
          },
          overflow: 'allow',
          crop: false,
          verticalAlign: 'middle',
          enabled: false,
        },
        lineWidth: 3,
        states: {
          hover: {
            lineWidth: 3,
            lineWidthPlus: 0,
          },
        },
        marker: {
          enabled: false,
          radius: 3,
        },
      },
    },
    colors: config.chartColors || [
      '#235A61',
      '#DD495E',
      '#2A2383',
      '#F2C60E',
      '#24CBE5',
      '#64E572',
      '#FF9655',
      '#FFF263',
      '#6AF9C4',
    ],
    tooltip: {
      enabled: config.showTooltip ? true : false,
      headerFormat: '<b>' + config.columnName + '</b><br/>',
      pointFormat: '<b>{point.x}</b>{point.y}',
      formatter: function () {
        return '<b>' + config.columnName + '</b><br/><b>' + headers[this.x - 1] + ':</b> ' + this.y;
      },
    },
  };
};
