import { combineReducers } from 'redux';
import apiPage from '../../pages/ApiPage/reducers/apiPageReducer';
import chartEditorPage from '../../pages/ChartEditorPage/reducers/chartEditorReducer';
import companyThemeEditorPage from '../../pages/CompanyThemeEditorPage/reducers/themeEditorPageReducer';
import customiseEditorPage from '../../pages/CustomiseEditorPage/reducers/customiseEditorPageReducer.js';
import layoutEditorPage from '../../pages/LayoutEditorPage/reducers/layoutEditorReducer';
import projectsPage from '../../pages/ProjectsPage/reducers/projectsPageReducer.js';
import rolesPage from '../../pages/RolesPage/reducers/teamGroupsPageReducer';
import redeemInvitePage from '../../pages/RedeemInvitePage/reducers/redeemInvitePageReducer';
import settingsPage from '../../pages/SettingsPage/reducers/settingsPageReducer';
import showChartPage from '../../pages/ShowChartPage/reducers/showChartPageReducer';
import plansPage from '../../pages/SubscriptionPage/reducers/plansPageReducer';
import tableEditorPage from '../../pages/TableEditorPage/reducers/tableEditorReducer';
import packagesPage from '../../pages/PackagesPage/reducers/PackagesPage';
import teamInvitationsPage from '../../pages/TeamPage/reducers/teamInvitationsReducer';
import teamMembersPage from '../../pages/TeamPage/reducers/teamMembersReducer';
import teamWebhooksPage from '../../pages/TeamWebhooksPage/reducers/teamWebhooksPageReducer';
import themesPage from '../../pages/ThemesPage/reducers/themesPageReducer';
import app from './appReducer';
import dataGridConfig from './dataGridReducer';
import profile from './profileReducer';
import projectConfig from './projectConfigReducer';
import wizardConfig from './wizardReducer';
import animation from './locationMap/animationReducer';
import locationMapInstance from './locationMap/instanceReducer';

export default combineReducers({
  projectsPage,
  customiseEditorPage,
  app,
  profile,
  settingsPage,
  teamMembersPage,
  teamInvitationsPage,
  teamWebhooksPage,
  rolesPage,
  apiPage,
  themesPage,
  plansPage,
  layoutEditorPage,
  tableEditorPage,
  showChartPage,
  chartEditorPage,
  projectConfig,
  packagesPage,
  dataGridConfig,
  wizardConfig,
  companyThemeEditorPage,
  redeemInvitePage,
  animation,
  locationMapInstance
});
