import React from 'react';
import { SectionNode } from '../../../editor/generic/SectionNode';
import { SectionRoot } from '../../../editor/generic/SectionRoot';
import { AppearanceSection } from '../../../editor/generic/locationMap/AppearanceSection';
import { InteractivitySection } from '../../../editor/generic/locationMap/InteractivitySection';
import { LayersSection } from '../../../editor/generic/locationMap/LayersSection';
import { TextSection } from '../../../editor/generic/locationMap/TextSection';

export const CustomizeLocationMapSection = () => {
  return (
    <SectionRoot>
      <TextSection isCompact={true} openedByDefault={true} />
      <SectionNode sectionKey="map" headerText={'Map'}>
        <InteractivitySection isCompact={true} showContainer={false} />
        <AppearanceSection isCompact={true} showContainer={false} />
      </SectionNode>

      <LayersSection isCompact={true} />
    </SectionRoot>
  );
};
