import actionTypes from '../../../redux/actions/action-types';

export const setAction = (data) => ({
  type: actionTypes.showChartPage.set,
  data
});
export const loadChartAction = (data) => ({
  type: actionTypes.showChartPage.loadChart,
  data
});
export const loadPackageAction = (data) => ({
  type: actionTypes.showChartPage.loadPackage,
  data
});
export const duplicateChartAction = (data) => ({
  type: actionTypes.showChartPage.duplicateChart,
  data
});
export const editChartAction = (data) => ({
  type: actionTypes.showChartPage.editChart,
  data
});
