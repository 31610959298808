import React, { memo, useLayoutEffect } from 'react';
import { useSectionStore } from './sectionStore';

export type SectionParentProps = {
  children: React.ReactNode[] | React.ReactNode;
};

export const SectionRoot = memo(({ children }: SectionParentProps) => {
  const resetSectionStore = useSectionStore((state) => state.resetSectionStore);
  useLayoutEffect(() => {
    resetSectionStore();
  }, []);
  return <>{children}</>;
});
