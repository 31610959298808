type DataLayerType = {
  event: string;
  signupMethod?: string;
  loginMethod?: string;
  ecommerce?: DataLayerEcommerceData;
};

interface DataLayerEcommerceData {
  currency: string;
  items: DataLayerEcommerceDataItems[];
}

interface DataLayerEcommerceDataItems {
  item_id: number;
  item_name: string;
  price: number;
  quantity: number;
}

type PlanDetailsType = {
  id: number;
  name: string;
  price: number;
};

export const handleGoogleTagManagerEvent = (dataLayer: DataLayerType) => {
  if (typeof (window as any).dataLayer !== 'undefined' && dataLayer) {
    (window as any).dataLayer.push(dataLayer);
  }
};

export const handleGoogleTagManagerPurchaseEvent = (event: string, planDetails: PlanDetailsType) => {
  handleGoogleTagManagerEvent({
    event: event,
    ecommerce: {
      currency: 'USD',
      items: [
        {
          item_id: planDetails.id,
          item_name: planDetails.name,
          price: planDetails.price,
          quantity: 1
        }
      ]
    }
  });
};
