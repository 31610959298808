import Tippy, { TippyProps } from '@tippyjs/react';
import * as React from 'react';
import 'tippy.js/dist/tippy.css';
import './styles.css';

type TooltipProps = {
  children: React.ReactElement;
  tippyProps: TippyProps;
};

const Tooltip = (props: TooltipProps) => {
  return (
    <Tippy theme="navyblue" className="arrow-theme" {...props.tippyProps}>
      {props.children}
    </Tippy>
  );
};

export default Tooltip;
