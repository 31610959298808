import initialState from './initial-state';
import actionTypes from '../actions/action-types';

const profileReducer = (state = initialState.profile, action) => {
  //TODO something better here
  switch (action.type) {
    case actionTypes.profile.setAll:
    case actionTypes.profile.setSubteams:
    case actionTypes.profile.setAnnouncements:
      return {
        ...state,
        ...action.data,
      };
    case actionTypes.profile.deleteTeam:
      return deleteTeam(state, action.data);
    case actionTypes.profile.updateTeams:
      return updateTeams(state, action.data);
    case actionTypes.profile.logout:
      return {
        ...state,
        user: undefined,
        team: undefined,
        division: undefined,
      };
  }
  return state;
};

const deleteTeam = (state, data) => {
  const teamId = data.team.id;

  const teams = state.teams.filter(function (obj) {
    return obj.id !== teamId;
  });

  if (teams.length >= 1) {
    localStorage.setItem('team', JSON.stringify(teams[0]));
    return {
      ...state,
      team: teams[0],
    };
  } else {
    // No more teams left
    localStorage.removeItem('team');
    localStorage.removeItem('editorTeam');
    return {
      ...state,
      team: undefined,
    };
  }
};

const updateTeams = (state, data) => {
  const team = data.teamSettings;
  let currentTeam = state.team;
  let allTeams = state.teams;

  currentTeam.name = team.name;
  allTeams.some((e) => {
    if (e.id === currentTeam.id) {
      e.name = team.name;
      return true;
    }
  });

  localStorage.setItem('team', JSON.stringify(currentTeam));
  return {
    ...state,
    team: currentTeam,
    teams: allTeams,
  };
};

export default profileReducer;
