/* eslint-disable react/no-danger */
import React from 'react';
import { IconPreset } from '../../../pages/ChartEditorPage/middleware/LocationMap/LocationMapMarker';
import Accordion from '../../Accordion';
import IconButton from '../../buttons/IconButton';

export type MarkerIcon = {
  id: string;
  name: string;
  keywords: string[];
  skins: { src: string; unified: string }[];
};

export type MarkerIconProps = {
  selectedIcon: { name: string; src: string };
  icons: IconPreset[];
  onSelectIcon: (id: string) => void;
  className?: string;
};
export const MarkerIconPicker = ({ className, icons, selectedIcon, onSelectIcon }: MarkerIconProps) => {
  return (
    <Accordion
      headerText={'Icon'}
      smallHeaderText={true}
      useInternalSelect={true}
      defaultSelection={false}
      selectedChildren={selectedIcon.name}
      accordionClasses={className ?? 'bg-white'}
    >
      <div className="flex flex-row flex-wrap gap-1">
        {icons.map((x) => (
          <IconButton
            key={x.id}
            icon={
              <div className="highed-icon-picker">
                <div dangerouslySetInnerHTML={{ __html: x.svg }} style={{ height: '28px', width: '28px' }} />
              </div>
            }
            className="rounded-sm px-2 bg-ev-grey border-ev-grey"
            onClick={() => onSelectIcon(x.id)}
          />
        ))}
      </div>
    </Accordion>
  );
};
