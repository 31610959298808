import { ProviderTypes } from 'pages/ChartEditorPage/meta/templates/types';
import { TypeProps } from 'shared/types/chooseTheme';

export const mapping = {
  bold: {
    key: 'fontWeight',
    value: 'bold'
  },
  underline: {
    key: 'textDecoration',
    value: 'underline'
  },
  italic: {
    key: 'fontStyle',
    value: 'italic'
  }
};

export const defaultFontSizes = {
  title: '18px',
  subtitle: '13px',
  caption: '13px',
  credits: '10px',
  legend: '13px',
  xAxis: '13px',
  yAxis: '13px',
  xAxisTick: '11px',
  yAxisTick: '11px'
};

export const fontSizes = [
  {
    value: '9px',
    label: 9
  },
  {
    value: '10px',
    label: 10
  },
  {
    value: '12px',
    label: 12
  },
  {
    value: '13px',
    label: 13
  },
  {
    value: '14px',
    label: 14
  },
  {
    value: '16px',
    label: 16
  },
  {
    value: '18px',
    label: 18
  },
  {
    value: '20px',
    label: 20
  },
  {
    value: '22px',
    label: 22
  },
  {
    value: '24px',
    label: 24
  },
  {
    value: '26px',
    label: 26
  },
  {
    value: '28px',
    label: 28
  },
  {
    value: '30px',
    label: 30
  },
  {
    value: '32px',
    label: 32
  },
  {
    value: '36px',
    label: 36
  },
  {
    value: '48px',
    label: 48
  },
  {
    value: '60px',
    label: 60
  },
  {
    value: '72px',
    label: 72
  },
  {
    value: '96px',
    label: 96
  },
  {
    value: '100px',
    label: 100
  }
];

export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'color',
  'link',
  'image',
  'align',
  'script',
  'size'
];

export const tableMapping = {
  align: 'textAlign'
};

export const alignValues = [
  { value: 'left', icon: 'align-left' },
  { value: 'center', icon: 'align-center' },
  { value: 'right', icon: 'align-right' }
];

export const axisAlignValues = [
  { value: 'low', icon: 'align-left' },
  { value: 'middle', icon: 'align-center' },
  { value: 'high', icon: 'align-right' }
];

export const alignValuePath = (provider: ProviderTypes, type: TypeProps) => {
  switch (type) {
    case 'chart':
      return {
        locationMap: 'style.textAlign',
        highcharts: 'align'
      }[provider];
    case 'layout':
    case 'table':
      return 'style.textAlign';
  }
};
