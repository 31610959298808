import classNames from 'classnames';
import React, { useRef } from 'react';
import DependencySelect, { components, GroupBase } from 'react-select';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { useDispatch } from 'react-redux';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import ChevronDown from 'static/icons/arrow_down.svg';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import Close from 'static/icons/close.svg';
import { setAction, setSelectFilterAction } from './../../pages/ProjectsPage/actions/projectsPage';
import Select from 'react-select/dist/declarations/src/Select';

export type SelectItem = { label: string; value: string; disabled?: boolean; index?: number; isLocked?: boolean };

export const customSelectColors = {
  grey: '#F7F8F8',
  white: '#FFFFFF'
};

type CustomSelectProps = {
  label?: string;
  options: SelectItem[];
  onChange: (selected: SelectItem | null) => void;
  styles: Record<string, unknown>;
  containerClasses?: string;
  labelClasses?: string;
  optionalLabelClass?: string;
  backgroundColor?: typeof customSelectColors;
  isDisabled?: boolean;
  isOptionDisabled?: (option: SelectItem) => boolean;
  value?: SelectItem;
  components?: Partial<SelectComponents<SelectItem, false, GroupBase<SelectItem>>>;
  hideLabel?: boolean;
  placeholder?: string;
  clearable?: boolean;
  searchable?: boolean;
};

export default function CustomSelect(props: CustomSelectProps) {
  const { label } = props;
  const dispatch = useDispatch();
  const value = props.value ?? props.options[0];
  const labelColor = props.labelClasses?.includes('text-ev-navy-blue-2') ? '' : 'text-ev-navy-blue-4';
  const selectRef = useRef<Select<SelectItem, false, GroupBase<SelectItem>>>(null);

  const labelClasses = classNames(`${props.labelClasses} ${labelColor} font-bold`, {
    hidden: props.hideLabel
  });

  const SingleValue = ({ selectProps, data }: { selectProps: any; data: any }) => {
    const optionalLabelClass = `truncate max-w-[225px] ${props?.optionalLabelClass ?? 'font-bold text-ev-dark-purple'}`;

    return (
      <>
        <div className="flex justify-between flex-wrap items-center w-full gap-1 py-1">
          {props.label && <div className={labelClasses}>{label}</div>}
          <div className={optionalLabelClass}>{selectProps.getOptionLabel(data)}</div>
          {props.clearable && <Close className="text-stale-900 hover:text-gray-700 h-3 w-3" onClick={reset} />}
        </div>
      </>
    );
  };

  const DropdownIndicator = (props: any) => {
    if (props.isDisabled) {
      return (
        <components.DropdownIndicator {...props}>
          <i className="fas fa-lock" />
        </components.DropdownIndicator>
      );
    }
    return (
      <components.DropdownIndicator {...props}>
        <SvgIconButton
          Icon={ChevronDown}
          width={14}
          height={9}
          buttonColor={ButtonColor.Transparent}
          onClick={() => null}
          tabIndex={-1}
        />
      </components.DropdownIndicator>
    );
  };

  const reset = () => {
    dispatch(setAction({ page: 1 }));
    dispatch(setSelectFilterAction({ selectCreatorValue: [] }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    // Remove this check when we see it works for other places and can just use it generically
    // Only works for map selector in wizard right now
    if (!props.searchable) return;

    const aToZ = /^[a-zA-Z]$/;
    if (!aToZ.test(e.key)) return;

    const letter = e.key.toLowerCase();
    const index = props.options.findIndex((option) => option.label.toLowerCase().startsWith(letter));

    if (index !== -1 && selectRef?.current?.menuListRef) {
      const optionElement = selectRef.current.menuListRef.children[index] as HTMLElement;
      if (optionElement) {
        selectRef.current.menuListRef.scrollTo({
          top: optionElement.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  };

  const CustomOption = (props: any) => {
    const { lockIcon: LockIcon, label, isLocked } = props.data;

    if (props.label) {
      return (
        <components.Option {...props}>
          {isLocked && (
            <div className="flex">
              <span className="mr-2">{label}</span>
              {LockIcon && <LockIcon width={17} height={16} />}
            </div>
          )}
          {!isLocked && label}
        </components.Option>
      );
    } else return null;
  };

  return (
    <div className={`relative ${props.containerClasses}`}>
      <DependencySelect
        ref={selectRef}
        className="label-select-container"
        classNamePrefix="everviz-select"
        components={{ ...props.components, SingleValue, DropdownIndicator, Option: CustomOption }}
        isSearchable={false}
        value={value}
        onKeyDown={handleKeyDown}
        menuPlacement="auto"
        options={props.options}
        onChange={props.onChange}
        isDisabled={props.isDisabled}
        styles={props.styles}
        isOptionDisabled={props.isOptionDisabled}
        //@ts-expect-error ts-2322
        backgroundColor={props.backgroundColor}
        placeholder={props.placeholder}
      />
    </div>
  );
}
