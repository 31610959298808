import { tooltipFormatter } from 'editor/core-ui/tooltip/highed.tooltip.helper.utils';
import { cloneDeep, get } from 'lodash';
import { updateCustomizedAction, updateCustomizedBulkAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { OptionProps } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import { Dispatch } from 'react';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import { isNull, merge } from '../../../editor/core/highcharts-editor';
import { SelectProps } from '../DependencySelectWidget';
import { extractRichtextValue, getSeries } from './widgetHelper';

type TooltipProps = {
  valueDecimals?: number;
  format?: string;
  tooltip?: any;
  type?: string;
};

type AggregatedOptionsProps = {
  chart: { type?: string };
  series: TooltipProps[];
  tooltip?: TooltipProps;
};
const findAllIndexes = (array: string[], val: string) => {
  const indexes = [];
  for (let i = 0; i < array.length; i++) if (array[i] === val) indexes.push(i);
  return indexes;
};

const handleGlobalTooltipChange = (
  customizedOptions: AggregatedOptionsProps,
  isTextFieldChange: boolean,
  varName: string,
  value: any,
  dispatch: Dispatch<any>
) => {
  const options: any = {
    ...(customizedOptions?.tooltip ?? {})
  };
  const series = cloneDeep(get(customizedOptions, 'series') ?? []);

  // Handle text change
  if (isTextFieldChange) {
    options[varName] = value;
    const payload = {
      tooltip: {
        ...options,
        footerFormat: '',
        formatter: tooltipFormatter(),
        useHTML: true
      },
      everviz: {
        tooltip: {
          useEvervizHelper: true,
          options: {
            vars: {}
          }
        }
      }
    };

    for (const item of series) {
      if (item.tooltip && item.tooltip[varName]) {
        delete item.tooltip[varName];
      }
    }

    dispatch(
      updateCustomizedAction({
        optionProps: { id: 'series' },
        val: series
      })
    );

    return dispatch(updateCustomizedBulkAction({ options: payload }));
  }

  // Handle all other properties change (ex: Visible)
  return dispatch(
    updateCustomizedAction({
      optionProps: { id: varName },
      val: value
    })
  );
};

// Handle changing individual series text
// This code only works for text changes, we dont allow for any styling changes for individual series.
// If we have to add this in future this part will need to be changed to allow for it.
const handleSeriesTooltipChange = (
  customizedOptions: AggregatedOptionsProps,
  varName: string,
  value: any,
  detailIndex: number,
  dispatch: Dispatch<any>
) => {
  const series = cloneDeep(get(customizedOptions, 'series') ?? []);
  const options: any = {
    ...(series[detailIndex].tooltip ?? {}),
    [varName]: value
  };

  if ('formatter' in options) delete options.formatter;

  series[detailIndex] = {
    ...series[detailIndex],
    tooltip: {
      ...options,
      useHTML: true
    }
  };

  dispatch(
    updateCustomizedAction({
      optionProps: { id: 'series' },
      val: series
    })
  );
};

export const onChange = (
  option: any,
  newValue: any,
  props: GenericInputProps,
  detailIndex: any,
  dispatch: Dispatch<any>
) => {
  const { id: varName } = option;
  const { customizedOptions } = props;
  const value = newValue.val ?? newValue;
  const isAllSeries = detailIndex === 'all';
  const isTextFieldChange = varName === 'pointFormat' || varName === 'headerFormat';
  const tooltipOption =
    (isAllSeries ? customizedOptions?.tooltip : customizedOptions.series[detailIndex].tooltip) ?? {};

  if (isTextFieldChange && tooltipOption?.[varName] === value) return;

  if (isAllSeries) {
    return handleGlobalTooltipChange(customizedOptions, isTextFieldChange, varName, value, dispatch);
  }
  handleSeriesTooltipChange(customizedOptions, varName, value, detailIndex, dispatch);
};

export const extractTooltipOptions = (
  aggregatedOptions: AggregatedOptionsProps,
  group: OptionProps,
  detailIndex: any,
  chart: any
) => {
  const types = (aggregatedOptions.series || []).map(
    (series) => series.type || (aggregatedOptions.chart && aggregatedOptions.chart.type) || 'line'
  );
  const uniqueTypes = [...new Set(types)];
  const isAll = detailIndex === 'all';
  const isTooltipHelper = group.rawKey === 'tooltip';

  // Highcharts has 4 different places where a tooltip can be added.
  // 1. tooltip
  // 2. Each Series Options -> tooltip
  // 3. Plot Options -> Template Type (ex: Line) -> tooltip
  // 4. Plot Options -> Series -> tooltip

  // This function either return a value for the property
  // or it returns a tooltip object, including all styling/text information so we can use
  // this in the tooltip richtext widget.

  const tooltipProp: TooltipProps = get(aggregatedOptions, 'tooltip') ?? {};
  const seriesTooltipProp: TooltipProps = get(aggregatedOptions, 'plotOptions.series.tooltip') ?? {};
  const allSeriesTooltipProp = aggregatedOptions.series.map(() =>
    merge(cloneDeep(tooltipProp), cloneDeep(seriesTooltipProp || {}), null, {})
  );

  uniqueTypes.forEach((type) => {
    const typeTooltip = get(aggregatedOptions, `plotOptions.${type}.tooltip`) ?? {};
    const indexes = findAllIndexes(types, type);
    indexes.forEach((index) => {
      allSeriesTooltipProp[index] = merge(cloneDeep(allSeriesTooltipProp[index]), typeTooltip, null, {});
    });
  });

  aggregatedOptions.series.forEach((series, i) => {
    const richtextOption = cloneDeep(
      extractRichtextValue('highcharts', 'chart', group, chart, aggregatedOptions, isAll ? 0 : detailIndex)
    );
    const tooltip = merge(richtextOption, series.tooltip || {}, null, {});
    allSeriesTooltipProp[i] = merge(cloneDeep(allSeriesTooltipProp[i]), tooltip, null, {});
  });

  const rawKey = group.rawKey ?? '';
  if (isAll) {
    // All Series
    let isEqual = false;
    if (!isNull(get(allSeriesTooltipProp[0], rawKey))) {
      isEqual = allSeriesTooltipProp.every((prop) => {
        return get(allSeriesTooltipProp[0], rawKey) === get(prop, rawKey);
      });
    }
    if (isTooltipHelper) return allSeriesTooltipProp[0];
    if (isEqual) return get(allSeriesTooltipProp[0], rawKey) ?? group.default;
    return group.default;
  }

  // Individual series

  if (isTooltipHelper) return allSeriesTooltipProp[detailIndex];
  const opt = get(allSeriesTooltipProp[detailIndex], rawKey) ?? group.default;

  if (!isNull(opt)) {
    return get(allSeriesTooltipProp[detailIndex], rawKey) ?? group.default;
  }
};

export default ({
  dispatch,
  props,
  detailIndex,
  onOptionChange
}: {
  dispatch: Dispatch<any>;
  props: GenericInputProps;
  detailIndex: any;
  onOptionChange: (value: any) => void;
}) => {
  const { option, aggregatedOptions, userAggregatedOptions, chart } = props;
  const selectOptions: [] = getSeries(option, aggregatedOptions, chart) || [];
  const selectValue: any = selectOptions.find((d: { value: string }) => d.value === detailIndex);

  let filteredOptions = option as OptionProps;
  if (selectValue?.value !== 'all') {
    filteredOptions = cloneDeep(option) as OptionProps;
    filteredOptions.filteredOptions = (filteredOptions.options ?? []).filter((option) => option.id === 'tooltip');
  }

  return {
    selectOptions,
    selectValue,
    onChangeSelect: onOptionChange,
    filteredOptions,
    extractValue: (property: OptionProps) => extractTooltipOptions(userAggregatedOptions, property, detailIndex, chart),
    onChangeValue: (_: string, option: OptionProps, val: any) => onChange(option, val, props, detailIndex, dispatch)
  } as SelectProps;
};
