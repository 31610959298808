import React from 'react';
import DependencySelect from 'react-select';
import { selectMultiStyles } from './../utils/selectStylesHelper.ts';

export default function CreatableSelect(props) {
  const customStyles = {
    ...selectMultiStyles,
    ...props.selectStyles
  };
  return <DependencySelect styles={customStyles} {...props} />;
}
