import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import CustomSelect, { SelectItem } from 'shared/inputs/CustomSelect';
import { SeriesAssign } from 'shared/types/commonPropTypes';
import BinIcon from 'static/icons/bin.svg';
import { updateSeriesTypes } from '../utils/seriesHelper';
import { customStyles, selectCustomStyles } from 'shared/utils/selectStylesHelper';

type SeriesProps = {
  assignOption: SeriesAssign;
  index: number;
  options: SelectItem[];
  moreThanOneSeries: boolean;
  setActiveColumns: (index: number[], series: number[]) => void;
  labelSelectStyle: Record<string, unknown>;
  deleteSeries: (index: number) => void;
  onMouseLeave: () => void;
  optionKey: string;
  type: string;
  backgroundColor: string;
};

const SeriesOption = React.memo((props: SeriesProps) => {
  const { options, index, assignOption, moreThanOneSeries, labelSelectStyle } = props;
  const { showWizard } = useSelector((state: RootState) => state.projectConfig);
  const dispatch = useDispatch();

  const handleSeriesChange = (selected: SelectItem | null) => {
    dispatch(
      updateSeriesTypes[props.type]({
        newSeries: selected?.value,
        newIndex: selected?.index,
        seriesIndex: index,
        key: props.optionKey
      })
    );
  };

  const deleteSeries = useCallback(() => props.deleteSeries(index), [assignOption]);
  const onMouseEnter = useCallback(() => props.setActiveColumns(assignOption.rawValue, [index]), [assignOption]);

  const shouldRender = assignOption.isData || (!showWizard && assignOption.isExtra);
  if (!shouldRender || assignOption.hidden) return <></>;

  const selectOptions = [...options];
  const emptyOption = {
    label: 'None',
    value: '-1'
  };

  if (!assignOption.mandatory) selectOptions.unshift(emptyOption);
  let selectValue = { label: `Column ${assignOption.value}`, value: assignOption.value };
  if (!assignOption.value) selectValue = emptyOption;

  const label = assignOption.name ?? (showWizard ? 'Series ' + (moreThanOneSeries ? index + 1 : '') : 'Value');
  return (
    <div key={`series-${index}`} onMouseEnter={onMouseEnter} onMouseLeave={props.onMouseLeave} className="flex w-full">
      <CustomSelect
        label={label}
        options={selectOptions}
        value={selectValue}
        onChange={handleSeriesChange}
        isOptionDisabled={(option) => Boolean(option.disabled)}
        labelClasses="text-base"
        containerClasses="w-full"
        backgroundColor={props.backgroundColor}
        styles={labelSelectStyle ? { ...customStyles, labelSelectStyle } : { ...selectCustomStyles }}
        isDisabled={assignOption.locked}
      />
      {showWizard && assignOption.canDelete && moreThanOneSeries && (
        <SvgIconButton
          width={20}
          height={20}
          onClick={deleteSeries}
          buttonClasses="p-2 rounded ml-1"
          buttonColor={ButtonColor.Grey}
          Icon={BinIcon}
        />
      )}
    </div>
  );
});

export default SeriesOption;
