import React, { RefObject, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { setAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import lang from '../../../editor/en';
import './../../../../node_modules/react-quill/dist/quill.snow.css';
import IconButton from './../../../shared/buttons/IconButton';
import { getRichtextBoxPosition } from '../utils/richtextModalHelper';
import RichtextGroupModalContent from './RichtextGroupModalContent';

type ModalProps = {
  aggregatedOptions: any;
};

const RichtextGroupModal = ({ aggregatedOptions }: ModalProps) => {
  const projectConfig = useSelector<any, any>((state: RootState) => state.projectConfig);
  const { richtextGroupOptions: richtextOptions } = projectConfig;

  const dispatch = useDispatch();

  const quillRef = useRef() as RefObject<ReactQuill>;
  const [position, setPosition] = useState({
    top: '200px',
    left: '200px'
  });

  const set = (params: any) => dispatch(setAction(params));

  useEffect(() => {
    setTimeout(() => {
      if (quillRef?.current) quillRef.current.focus();
    }, 100);

    // Position correctly
    if (richtextOptions) {
      const container = document.querySelector('.richtext-editor-container');
      setPosition(getRichtextBoxPosition(richtextOptions.box, container));
    }
  }, [richtextOptions]);

  const onClose = () => {
    set({
      richtextGroupOptions: false
    });
  };

  const langEntriesKey = (richtextOptions &&
    richtextOptions.option &&
    'option.text.' + richtextOptions.option.pid) as keyof typeof lang.entries;

  return (
    <>
      {richtextOptions && (
        <div
          className=" w-[340px] absolute z-[9998] bg-white min-h-[120px] shadow-md rounded richtext-editor-container"
          style={position}
        >
          <IconButton
            icon="times"
            className="absolute right-2 top-2 rounded w-8 h-8"
            iconClass="text-base"
            onClick={onClose}
          />

          <div className="p-4">
            <div className="text-ev-blue font-bold text-base">
              Edit {(lang.entries[langEntriesKey ?? ''] ?? '').toLowerCase()} text:
            </div>
            <div>
              <RichtextGroupModalContent aggregatedOptions={aggregatedOptions} {...richtextOptions} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RichtextGroupModal;
