import React from 'react';
import PropTypes from 'prop-types';
import Select from './inputs/Select';
import Button from './buttons/Button';
import { L } from 'highed';

class MultipleSelectFields extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
    this.loadUserOptions = this.loadUserOptions.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.setup = this.setup.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.state = {};
  }

  componentDidMount() {
    this.setup();
  }

  setup() {
    let flatOptions = {};

    function isArr(what) {
      return !(typeof what === 'undefined' || what === null) && what.constructor.toString().indexOf('Array') > -1;
    }

    function dive(tree) {
      if (tree) {
        if (isArr(tree)) {
          tree.forEach((t) => {
            t.header = tree.header;
            t.subheader = t.text;
            t.group = t.group;
            dive(t);
          });
        } else if (tree.options) {
          if (tree.id) flatOptions[tree.id] = tree;
          if (isArr(tree.options)) {
            tree.options.forEach((t) => {
              t.header = tree.header;
              t.subheader = tree.subheader;
              t.group = tree.group;
              dive(t);
            });
          } else {
            Object.keys(tree.options).forEach(function (key) {
              tree.options[key].header = key;
              dive(tree.options[key]);
            });
          }
        } else if (tree.id) {
          flatOptions[tree.id] = tree;
        }
      }
    }
    dive(this.props.options);
    let allOptions = [];
    let structuredOptions = {};

    Object.keys(flatOptions).map((key, i) => {
      const option = flatOptions[key];
      if (!structuredOptions[L(option.header)]) structuredOptions[L(option.header)] = {};
      if (option.dataType === 'header') return;

      if (option.group) {
        const properties = {
          value: option.id,
          parent: L(option.header),
          text: L('option.text.' + option.pid),
          id: i,
          subheader: L(option.subheader),
          group: option.group
        };

        if (!structuredOptions[L(option.header)]['group_' + option.group])
          structuredOptions[L(option.header)]['group_' + option.group] = {};
        if (!structuredOptions[L(option.header)]['group_' + option.group][L(option.subheader)])
          structuredOptions[L(option.header)]['group_' + option.group][L(option.subheader)] = [];
        structuredOptions[L(option.header)]['group_' + option.group][L(option.subheader)].push(properties);
      } else {
        if (!structuredOptions[L(option.header)][L(option.subheader)])
          structuredOptions[L(option.header)][L(option.subheader)] = [];

        structuredOptions[L(option.header)][L(option.subheader)].push({
          value: option.id,
          parent: L(option.header),
          text: L('option.text.' + option.pid),
          id: i,
          subheader: L(option.subheader)
        });
      }

      allOptions.push({
        value: flatOptions[key].id,
        parent: L(flatOptions[key].header),
        text: L('option.text.' + flatOptions[key].pid),
        id: i
      });
    });

    this.setState({
      options: flatOptions,
      userOptionsArr: this.props.userOptions,
      userOptions: [],
      structuredOptions,
      untouchedAllOptions: this.sortByKey(allOptions, 'parent'),
      allOptions: this.sortByKey(allOptions, 'parent')
    });
    this.loadUserOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //Change this
    this.setState(
      {
        userOptionsArr: nextProps.userOptions
      },
      () => {
        this.setup();
      }
    );
  }

  loadUserOptions() {
    let untouchedAllOptions = this.state.untouchedAllOptions || [];
    const userOptionsArr = this.state.userOptionsArr || [];
    let delta = untouchedAllOptions
      .filter(function (item) {
        return userOptionsArr.indexOf(item.value) > -1;
      })
      .map((x) => x.value);

    const c = untouchedAllOptions.filter(function (item) {
      return userOptionsArr.indexOf(item.value) === -1;
    });

    if (delta.length === 0) {
      delta = (this.state.allOptions || []).map((x) => x.value);
    }

    this.setState({
      allOptions: c,
      userOptions: delta
    });
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  onClick() {
    this.props.onClick(this.state.userOptions);
  }

  selectAll() {
    this.setState({
      userOptions: this.state.untouchedAllOptions,
      allOptions: [],
      rightActiveOptions: [],
      leftActiveOptions: []
    });
  }

  clearAll() {
    this.setState({
      allOptions: this.state.untouchedAllOptions,
      userOptions: [],
      rightActiveOptions: [],
      leftActiveOptions: []
    });
  }

  render() {
    const options = this.state.allOptions,
      userOptions = this.state.userOptions,
      structuredOptions = this.state.structuredOptions || {};
    let leftParent = '',
      rightParent = '';

    const toggleOption = (o) => {
      const index = userOptions.indexOf(o.value);
      if (index > -1) {
        userOptions.splice(index, 1);
      } else {
        userOptions.push(o.value);
      }
      this.setState({
        userOptions
      });
    };

    return (
      <div className="multiple-select-field-container">
        <div className="multiple-select-fields">
          {Object.keys(structuredOptions).map((optionKey, index) => {
            return (
              <div className={'parent-block ' + optionKey.replace(' ', '_').toLowerCase()} key={index}>
                <div className="header">{optionKey} tab options</div>
                {Object.keys(structuredOptions[optionKey]).map((subOptionKey, i) => {
                  const isArray = structuredOptions[optionKey][subOptionKey].constructor === Array;
                  const object = structuredOptions[optionKey][subOptionKey];

                  if (!isArray) {
                    return (
                      <div key={'g_' + i} className="grouped-block">
                        {Object.keys(object).map((key, i) => {
                          return (
                            <div key={'block_' + i} className={key.replace(' ', '') + ' child-block ' + ('block_' + i)}>
                              {key && <div className="child-header">{key}</div>}
                              {object[key].map((o, x) => {
                                const toggle = () => {
                                  toggleOption(o);
                                };

                                return (
                                  <div
                                    key={x}
                                    className={'sub-child-block' + (userOptions.includes(o.value) ? ' active' : '')}
                                    onClick={toggle}
                                  >
                                    {' '}
                                    {o.text}{' '}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }

                  return (
                    <div key={'child_' + i} className={' child-block ' + subOptionKey}>
                      {subOptionKey && isArray && <div className="child-header">{subOptionKey} </div>}

                      {isArray &&
                        structuredOptions[optionKey][subOptionKey].map((option, i) => {
                          const toggle = () => {
                            toggleOption(option);
                          };
                          return (
                            <div
                              key={'c_' + i}
                              className={'sub-child-block' + (userOptions.includes(option.value) ? ' active' : '')}
                              onClick={toggle}
                            >
                              {' '}
                              {option.text}{' '}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="buttons-container">
          <Button buttonText="Save" extraClass="bulk-action bulk-action-btn" onClick={this.onClick} />
        </div>
      </div>
    );
  }
}

MultipleSelectFields.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  userOptions: PropTypes.array,
  onClick: PropTypes.func.isRequired
};

export default MultipleSelectFields;
