import React from 'react';
import { deleteItemArrayAction } from '../../actions/chartEditor';
import { useDispatch } from 'react-redux';

type DeleteButtonProps = {
  elementKey: string;
  index: number | null;
  resetSelection: (deletedPath: string) => void;
};

export default (props: DeleteButtonProps) => {
  const { index, elementKey } = props;
  const dispatch = useDispatch();

  const deleteItem = (e: React.MouseEvent) => {
    dispatch(
      deleteItemArrayAction({
        index,
        key: elementKey
      })
    );
    props.resetSelection(elementKey);
    e.preventDefault();
    e.stopPropagation();
  };

  return <span className="far fa-minus-square float-right relative top-[5px]" onClick={deleteItem} />;
};
