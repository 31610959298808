import React from 'react';
import { useIsMobile } from 'shared/utils/mediaHelper';

type WizardStepperProps = {
  wizardOptions: any[];
  onClickStep: (index: number) => void;
  step: number;
};

export default function WizardStepper(props: WizardStepperProps) {
  const { wizardOptions, onClickStep, step } = props;
  const isMobile = useIsMobile();

  const mobileStepper = (
    <div className="content-centered w-full text-ev-navy-blue-2 font-medium">
      Step {`${step + 1}/${wizardOptions.length}`}
    </div>
  );
  const regularStepper = (
    <div className="content-centered grow mx-20 ev-md:mx-10">
      {(wizardOptions || []).map((opt, i) => {
        if (opt.onlyMap) return <span key={'step_' + i} />;
        const pointDone = i < step;
        const clickable = i <= step + 1;
        const pointActive = i === step;
        const lastStep = i === wizardOptions.length - 1;

        return (
          <div
            className={`content-centered flex-col step relative w-[25%] ${clickable ? 'clickable group' : ''}`}
            key={'wizard_' + i}
            onClick={() => onClickStep(i)}
          >
            <div
              className={`absolute border w-[100%] h-[0px] top-[20px] left-[50%] z-[-1] ${
                pointDone ? 'border-solid border-ev-navy-blue-2' : 'border-dashed border-ev-light-orange'
              } ${lastStep ? 'hidden' : ''}`}
            />

            <div
              className={`content-centered rounded-full w-6 h-6 border border-solid text-center text-xs font-semibold m-2 ml-0 
              ${
                pointActive || pointDone
                  ? ' text-white bg-ev-navy-blue-2 border-ev-navy-blue-2 '
                  : ' text-ev-navy-blue-2 bg-ev-light-orange border-ev-light-orange'
              }
              ${
                pointDone
                  ? 'after:border-solid after:border-ev-navy-blue-2 group-hover:bg-ev-baby-blue-2 group-hover:border-ev-baby-blue-2 group-hover:text-ev-navy-blue-2'
                  : 'after:border-dashed after:border-ev-light-orange'
              }`}
            >
              {pointDone ? (
                <span>
                  <i className="fa fa-check" />
                </span>
              ) : (
                <span>{i + 1}</span>
              )}
            </div>
            <div
              className={`text-ev-navy-blue-2 hidden ml-[-4px] ev-lg:block text-xs group-hover:font-bold ${
                pointActive ? 'font-bold' : 'font-medium'
              } ${clickable && !pointActive ? ' clickable underline' : ''}`}
            >
              {opt.stepText}
            </div>
          </div>
        );
      })}
    </div>
  );
  return isMobile ? mobileStepper : regularStepper;
}
