import { saveChartAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { saveLayoutAction } from 'pages/LayoutEditorPage/actions/layoutEditor';
import { saveTableAction } from 'pages/TableEditorPage/actions/tableEditor';
import paths from 'paths';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUnsavedChangesModalAction } from 'redux/actions/projectConfig';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import TextButton from 'shared/buttons/TextButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import Modal from 'shared/modal/Modal';

export default function UnsavedChangesModal(props) {
  const dispatch = useDispatch();
  const { showUnsavedChangesModal } = useSelector(getProjectConfig);
  const history = useHistory();
  const { pathname } = history.location;

  const toggleModal = () => {
    dispatch(toggleUnsavedChangesModalAction(false));
  };

  const exit = () => {
    history.push({
      pathname: paths.profileCharts,
      state: {
        from: pathname
      }
    });
  };

  const saveAndExit = () => {
    const { type } = props;
    const saveMap = {
      chart: saveChartAction,
      layout: saveLayoutAction,
      table: saveTableAction
    };

    dispatch(
      saveMap[type]({
        reload: false,
        callback: () => {
          exit();
        }
      })
    );
  };

  return (
    <Modal
      isOpen={showUnsavedChangesModal}
      onClose={toggleModal}
      title={'Leaving the project wizard'}
      width={'w-4/12 max-w-xl'}
      bg={'bg-ev-yellow-1'}
    >
      <p className="font-medium pb-6 m-0">Are you sure you want to leave? You have unsaved changes.</p>

      <div className="flex gap-2">
        <PrimaryButton onClick={exit} text={'Yes, I want to leave'} />
        <PrimaryButton onClick={toggleModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
      </div>

      <div className="pt-6">
        <TextButton text={'Save and exit the project wizard'} onClick={saveAndExit} />
      </div>
    </Modal>
  );
}
