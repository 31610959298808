import React, { useEffect } from 'react';

export default ({ simple = false, onlySubsection = false }) => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    hbspt.forms.create({
      region: 'na1',
      portalId: '6876929',
      formId: '6dbc3ffb-22d4-4d18-9450-32db935ba0f3',
      target: '#hubspot-form'
    });
  }, []);

  const main = () => (
    <footer className="row bg-ev-navy-blue font-medium text-white">
      {!onlySubsection && (
        <div className="grid grid-cols-12 w-4/5 mx-auto my-12">
          <div className="m-8 col-span-12 md:col-span-8 lg:col-span-4">
            {simple ? simpleLeftGroup : defaultLeftGroup}
          </div>

          {sitemap.map(makeSection)}
        </div>
      )}

      {simple ? simpleSubsection : defaultSubsection}
    </footer>
  );

  const makeSection = (x) => (
    <div className="m-8 col-span-6 md:col-span-4 lg:col-span-2">
      {makeHeader(x.title)}
      <ul className="flex flex-col space-y-3 p-0 list-none">{x.data.map(makeSectionLink)}</ul>
    </div>
  );

  const makeSectionLink = (x) => (
    <li>
      <a href={x.href} className="no-underline hover:underline text-white">
        {x.text}
      </a>
    </li>
  );

  const makeHeader = (text) => (
    <>
      <h4 className="text-xl mt-0 text-[#DDD8F5] mb-1">{text}</h4>
      <span
        className="inline-block w-12
                 mb-6 transition-all h-1 bg-ev-red"
      />
    </>
  );

  const defaultSubsection = (
    <div className="bg-[#DDD8F5] space-x-24 flex items-center justify-center px-5 h-36">
      <img
        src="https://app-everviz.s3.eu-west-1.amazonaws.com/static/images/poweredby-inverted.svg"
        alt="powered by Highcharts"
        className="hidden md:block h-16"
      />
      <div>
        <p className="text-ev-blue m-0">{copyright[0]}</p>
        <p className="text-ev-blue m-0">{copyright[1]}</p>
      </div>
    </div>
  );

  const simpleSubsection = (
    <div className="bg-[#DDD8F5] flex items-center justify-center px-5 h-20">
      <p className="text-ev-blue m-0">{copyright[0]}</p>
      <span className="w-0.5 h-8 bg-ev-red mx-4" />
      <p className="text-ev-blue m-0">{copyright[1]}</p>
    </div>
  );

  const defaultLeftGroup = (
    <div className="w-11/12 ">
      {makeHeader('Newsletter')}
      <div id="hubspot-form" />
    </div>
  );

  const simpleLeftGroup = (
    <img alt="powered by Highcharts" className="w-3/4" src="https://app.everviz.com/static/images/poweredby.svg" />
  );

  return main();
};

const copyright = [
  `© ${new Date().getFullYear()} everviz All Rights Reserved`,
  'everviz is a service by Visual Elements of Norway'
];

const sitemap = [
  {
    title: 'Contact',
    data: [
      {
        href: 'mailto:hello@everviz.com',
        text: 'hello@everviz.com'
      },
      {
        href: 'tel:+47 411 27 174',
        text: '+47 411 27 174'
      },
      {
        href: 'https://goo.gl/maps/wcmAdXcKy33SpQ9t7',
        text: 'Lars Hilles gate 30, 5008 Bergen'
      }
    ]
  },
  {
    title: 'Social',
    data: [
      {
        href: 'https://www.facebook.com/EvervizApp',
        text: 'Facebook'
      },
      {
        href: 'https://twitter.com/EvervizApp',
        text: 'Twitter'
      },
      {
        href: 'https://www.linkedin.com/company/everviz/',
        text: 'LinkedIn'
      }
    ]
  },
  {
    title: 'Resources',
    data: [
      {
        href: 'https://help.everviz.com/',
        text: 'Help'
      },
      {
        href: 'https://www.everviz.com/support-options/',
        text: 'Support'
      },
      {
        href: 'https://www.everviz.com/partners',
        text: 'Partners'
      },
      {
        href: 'https://www.everviz.com/about',
        text: 'About us'
      },
      {
        href: 'https://www.everviz.com/contact-us',
        text: 'Contact us'
      },
      {
        href: 'https://www.everviz.com/blog',
        text: 'Blog'
      }
    ]
  },
  {
    title: 'Information',
    data: [
      {
        href: 'https://www.everviz.com/terms',
        text: 'Terms'
      },
      {
        href: 'https://www.everviz.com/privacy',
        text: 'Privacy policy'
      },
      {
        href: 'https://www.everviz.com/cookies',
        text: 'Cookies'
      }
    ]
  }
];
