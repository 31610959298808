import { get, isNumber } from 'lodash';
import { OptionProps } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { customSelectColors } from 'shared/inputs/CustomSelect';
import { GenericInputProps, InputChangeParams } from 'shared/types/commonPropTypes';
import { onSeriesEnter, onSeriesLeave } from 'shared/wizard/utils/seriesHelper';
import BuildInputs from './../../shared/widgets/BuildInputs';
import TooltipSelectorHelper from './utils/tooltipSelectorHelper';
import ChartTypeHelper from './utils/chartTypeHelper';
import DataLabelsHelper from './utils/dataLabelsHelper';
import { getFilteredValue, getIndexFromPath } from './utils/widgetHelper';
import SelectWidget from './SelectWidget';
import { components } from 'react-select';

export type SelectProps = {
  selectOptions: [];
  selectValue: unknown;
  onChangeSelect: (value: any) => void;
  filteredOptions: OptionProps;
  extractValue?: (property: OptionProps) => any;
  onChangeValue?: (type: unknown, option: unknown, val: unknown) => void;
};

const getPropsMap = {
  chartType: ChartTypeHelper,
  dataLabels: DataLabelsHelper,
  tooltip: TooltipSelectorHelper
};

let dropdownValue = false;

const DependencySelectWidget = (props: GenericInputProps) => {
  const [detailIndex, setDetailIndex] = useState<unknown>(props.option?.controlledBy?.default ?? 0);
  const dispatch = useDispatch();
  const { seriesAssigns } = useSelector((state: RootState) => state.projectConfig);
  const { option } = props;
  const controlledBy = option?.controlledBy;
  const propsMapIndex = controlledBy?.type as keyof typeof getPropsMap;
  const onOptionChange = (value: InputChangeParams) => setDetailIndex(value.val);

  const { selectOptions, selectValue, onChangeSelect, filteredOptions, extractValue, onChangeValue }: SelectProps =
    getPropsMap[propsMapIndex]({ dispatch, props, detailIndex, onOptionChange, seriesAssigns });

  let className = '';
  if (propsMapIndex === 'chartType') {
    const series = get(props.aggregatedOptions, 'series');
    const moreThanOneSeries = (series ?? []).length > 1;
    if (moreThanOneSeries) className = 'bg-ev-grey';
    else className = 'bg-white';

    filteredOptions.filteredOptions = (filteredOptions.filteredOptions ?? []).map((field: any) => ({
      ...field,
      className,
      backgroundColor: moreThanOneSeries ? customSelectColors.grey : customSelectColors.white
    }));
  }

  let showDropdown = true;

  if (option?.filteredBy && option.subType) {
    const index = getIndexFromPath(props.parentPath);
    const type = getFilteredValue(option, props.chart, index);
    showDropdown = option.subType.includes(type);
  }

  const onChange = (value: any) => {
    const { val } = value;
    if (isNumber(val)) onSeriesEnter(dispatch, [val], [val]);
    else onSeriesLeave(dispatch);
    onChangeSelect(value);

    setTimeout(onLeave, 1000);
  };

  const onLeave = () => onSeriesLeave(dispatch);

  const Option = (props: any) => {
    return (
      <span
        onMouseOver={() => {
          const { data } = props;
          const { value } = data;
          if (dropdownValue !== value) {
            if (isNumber(value)) onSeriesEnter(dispatch, [value], [value]);
            else onSeriesLeave(dispatch);
          }
          dropdownValue = value;
        }}
      >
        <components.Option {...props} />
      </span>
    );
  };

  return (
    <>
      {showDropdown && (
        <div className="highed-customize-master-dropdown">
          <SelectWidget
            selectOptions={selectOptions}
            value={selectValue}
            onChange={onChange}
            label={props.label}
            className={className}
            backgroundColor={className}
            option={props.option}
            components={{
              Option
            }}
          />
          {option?.showSeparatorLine && <hr className="border-ev-grey-3 mt-2 mb-4" />}
        </div>
      )}

      {((filteredOptions as any).options || filteredOptions.filteredOptions) && (
        <div className="highed-customizer-table flex flex-col w-full mt-0">
          <BuildInputs
            section={filteredOptions}
            parentKey={''}
            parentPath={`${option?.id}[${detailIndex}]`}
            overrideOnChange={onChangeValue}
            overrideExtractValue={extractValue}
            index={(detailIndex ?? 0) as number}
          />
        </div>
      )}
    </>
  );
};

export default DependencySelectWidget;
