import { cloneDeep } from 'lodash';
import store from 'redux/store';
import { dashStyleOptions } from 'editor/meta/highed.format.options';
import { updateThemeConfigAction } from 'pages/CompanyThemeEditorPage/actions/ThemeEditorPage';

interface SelectOption {
  label: string;
  value: string;
}

export const customAxisDashStyleOptions = [{ label: 'None', value: 'None' }, ...dashStyleOptions];

export const xAxisGridLineWidthProps = {
  id: 'xAxis[0].gridLineWidth',
  pid: 'xAxis.gridLineWidth',
  aliasKey: ['chart.all.xAxis.gridLineWidth']
};

export const yAxisGridLineWidthProps = {
  id: 'yAxis[0].gridLineWidth',
  pid: 'yAxis.gridLineWidth',
  aliasKey: ['chart.all.yAxis.gridLineWidth']
};

export const xAxisGridLineDashStyleProps = {
  id: 'xAxis[0].gridLineDashStyle',
  pid: 'xAxis.gridLineDashStyle',
  aliasKey: ['chart.all.xAxis.gridLineDashStyle']
};

export const yAxisGridLineDashStyleProps = {
  id: 'yAxis[0].gridLineDashStyle',
  pid: 'yAxis.gridLineDashStyle',
  aliasKey: ['chart.all.yAxis.gridLineDashStyle']
};

export const handleXAxisGridLineStyleSelection = (aggregatedOptions: any, selection: SelectOption) => {
  const aggregated = cloneDeep(aggregatedOptions);
  aggregated['xAxis'] = { ...aggregated.xAxis };
  const gridLineWidthValue = selection.value === 'None' ? 0 : aggregated.xAxis.gridLineWidth || 1;
  store.dispatch(updateThemeConfigAction({ optionProps: xAxisGridLineWidthProps, val: gridLineWidthValue }));
  aggregated['xAxis'] = { ...aggregated.xAxis, gridLineDashStyle: selection.value, gridLineWidth: gridLineWidthValue };
  return aggregated;
};

export const handleYAxisGridLineStyleSelection = (aggregatedOptions: any, selection: SelectOption) => {
  const aggregated = cloneDeep(aggregatedOptions);
  aggregated['yAxis'] = { ...aggregated.yAxis };
  const gridLineWidthValue = selection.value === 'None' ? 0 : aggregated.yAxis.gridLineWidth || 1;
  store.dispatch(updateThemeConfigAction({ optionProps: yAxisGridLineWidthProps, val: gridLineWidthValue }));
  aggregated['yAxis'] = { ...aggregated.yAxis, gridLineDashStyle: selection.value, gridLineWidth: gridLineWidthValue };
  return aggregated;
};

export const handleSymbol = (val: boolean) => {
  const newValue = val ? 'menu' : null;
  return newValue;
};

export const handleXAxisGridLineWidthChange = (aggregatedOptions: any, value: number) => {
  let aggregated = cloneDeep(aggregatedOptions);
  if (value === 0) {
    aggregated = handleXAxisGridLineStyleSelection(aggregated, { label: 'None', value: 'None' });
    store.dispatch(updateThemeConfigAction({ optionProps: xAxisGridLineDashStyleProps, val: 'None' }));
  } else if (value > 0 && (!aggregated?.xAxis?.gridLineDashStyle || aggregated?.xAxis?.gridLineDashStyle === 'None')) {
    aggregated = handleXAxisGridLineStyleSelection(aggregated, { label: 'Solid', value: 'Solid' });
    store.dispatch(updateThemeConfigAction({ optionProps: xAxisGridLineDashStyleProps, val: 'Solid' }));
  }
  aggregated['xAxis'] = { ...aggregated.xAxis, gridLineWidth: value };

  const {
    xAxis: { gridLineDashStyle, gridLineWidth }
  } = aggregated;
  const gridLineAggregated = {
    xAxis: {
      gridLineDashStyle,
      gridLineWidth
    }
  };

  return gridLineAggregated;
};

export const handleYAxisGridLineWidthChange = (aggregatedOptions: any, value: number) => {
  let aggregated = cloneDeep(aggregatedOptions);
  if (value === 0) {
    aggregated = handleYAxisGridLineStyleSelection(aggregated, { label: 'None', value: 'None' });
    // Force dispatch to save in company theme
    store.dispatch(updateThemeConfigAction({ optionProps: yAxisGridLineDashStyleProps, val: 'None' }));
  } else if (value > 0 && (!aggregated?.yAxis?.gridLineDashStyle || aggregated?.yAxis?.gridLineDashStyle === 'None')) {
    aggregated = handleYAxisGridLineStyleSelection(aggregated, { label: 'Solid', value: 'Solid' });
    store.dispatch(updateThemeConfigAction({ optionProps: yAxisGridLineDashStyleProps, val: 'Solid' }));
  }
  aggregated['yAxis'] = { ...aggregated.yAxis, gridLineWidth: value };

  // Only save dash style and width property in customizedOptions
  const {
    yAxis: { gridLineDashStyle, gridLineWidth }
  } = aggregated;
  const gridLineAggregated = {
    yAxis: {
      gridLineDashStyle,
      gridLineWidth
    }
  };

  return gridLineAggregated;
};
