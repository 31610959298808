import React, { FocusEventHandler, forwardRef } from 'react';
import { GenericInputProps } from 'shared/types/commonPropTypes';

type TextWidgetProps = {
  placeholder?: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  readOnly?: boolean;
  rows?: number;
};

export const TextWidget = forwardRef<HTMLTextAreaElement, GenericInputProps & TextWidgetProps>((props, ref) => {
  const { value, className, placeholder, onBlur, label } = props;

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange({ val: e.target.value });
  };

  return (
    <div className={`${className} ${label ? 'mt-2 w-full' : ''}`} id={props.option?.id}>
      {label && <div className={'text-ev-navy-blue-4 font-bold mb-2'}>{label}</div>}
      <textarea
        ref={ref}
        className={`highed-field-input ${className}`}
        onChange={onChange}
        placeholder={placeholder || ''}
        onBlur={onBlur}
        readOnly={props?.readOnly}
        value={(value ?? '') as string}
        rows={props.rows}
      />
    </div>
  );
});

export default TextWidget;
