import { TemplateTypeProps } from '../../types';
import Donut from './Donut';
import Donut3D from './Donut3D';
import Donut3DWithLegend from './Donut3DWithLegend';
import DonutWithLegend from './DonutWithLegend';
import SemiCircleDonut from './SemiCircleDonut';

const DonutTemplate: TemplateTypeProps = {
  description: '',
  icon: '',
  sampleData: [],
  templates: {
    Donut,
    '3D Donut': Donut3D,
    '3D Donut w/ legend': Donut3DWithLegend,
    'Donut w/ legend': DonutWithLegend,
    'Semi circle donut': SemiCircleDonut
  }
};

export default DonutTemplate;
