import classNames from 'classnames';
import { isBoolean, isNumber } from 'lodash';
import { updateCustomizedBulkAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupBase } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { RootState } from 'redux/store';
import CustomSelect, { SelectItem } from 'shared/inputs/CustomSelect';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import { isNull, isStr } from '../../editor/core/highcharts-editor';

type SelectWidgetProps = {
  customStyles?: Record<string, unknown>;
  backgroundColor?: string;
  inlineSelect?: boolean;
  canBeEmpty?: boolean;
  components?: Partial<SelectComponents<SelectItem, false, GroupBase<SelectItem>>>;
  className?: string;
  optionalLabelClass?: string;
  isDisabled?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  clearable?: boolean;
};

const SelectWidget = (props: GenericInputProps & SelectWidgetProps) => {
  const { value, canBeEmpty, isDisabled, hideLabel } = props;
  const { customizedOptions } = useSelector((state: RootState) => state.projectConfig);
  const dispatch = useDispatch();

  const onChange = (selected: SelectItem | null) => {
    if (props.option?.onOptionsChange) {
      const options = props.option.onOptionsChange(customizedOptions, selected);
      dispatch(updateCustomizedBulkAction({ options }));
    }
    props.onChange({ ...selected, val: selected?.value, label: selected?.label });
  };

  const options = props.option?.basedOnColumns
    ? props.dataOptions[0].map((data: any, i: number) => {
        return {
          label: data,
          value: i
        };
      })
    : props.selectOptions;

  const findValue = !canBeEmpty && (isStr(value) || isNull(value) || isBoolean(value) || isNumber(value));
  const selectValue = findValue ? options.find((d: any) => d.value === value) ?? options[0] : value;

  const containerClasses = classNames(props.className, {
    'w-full': !props.inlineSelect
  });

  return (
    <CustomSelect
      options={options}
      onChange={onChange}
      value={selectValue}
      label={props.label as string}
      labelClasses={props.option?.labelClasses}
      optionalLabelClass={props.optionalLabelClass}
      placeholder={props.placeholder}
      styles={props.customStyles ? { ...props.customStyles } : { ...editorStyles }}
      containerClasses={containerClasses}
      backgroundColor={props.backgroundColor || props.option?.backgroundColor}
      components={props.components}
      isDisabled={isDisabled}
      hideLabel={hideLabel}
      clearable={props.clearable}
    />
  );
};

export default SelectWidget;
