import classNames from 'classnames';
import * as React from 'react';
import Checked from 'static/icons/checked.svg';
import Cross from 'static/icons/cross.svg';
type ToggleProps = {
  checked: boolean;
  onToggle: () => void;
};

const Toggle = (props: ToggleProps) => {
  const { checked, onToggle } = props;

  const toggleClasses = classNames(
    'w-14 h-8 rounded-full relative transition hover:bg-ev-baby-blue-2 border-2 border-transparent focus:outline-none',
    {
      'bg-ev-navy-blue-2 focus:shadow-dark': checked,
      'bg-ev-grey-3 focus:shadow-light': !checked
    }
  );
  const sliderClasses = classNames(
    'absolute rounded-full bg-white w-6 h-6 content-centered top-0.5 left-1 transition',
    {
      'translate-x-5': checked
    }
  );
  return (
    <div
      className={toggleClasses}
      onClick={onToggle}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onToggle();
      }}
    >
      <span className={sliderClasses}>
        {checked ? (
          <Checked width={14} height={12} className="text-ev-navy-blue-2" />
        ) : (
          <Cross width={10} height={10} className="text-ev-grey-3" />
        )}
      </span>
    </div>
  );
};

export default Toggle;
