/* eslint-disable no-use-before-define */
import { getMostPopular, getAllInCatToArray } from 'editor/core/highed.templateman';
import { merge } from 'lodash';
import { snackBar } from 'editor/editors/highed.init';
import { loadProject } from 'pages/ChartEditorPage/middleware/ChartEditor';
import { setTemplate } from 'pages/ChartEditorPage/middleware/ChartEditorTemplates';
import { assignTheme } from 'pages/ChartEditorPage/middleware/ChartEditorThemes';
import qs from 'qs';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { generateQueryString } from 'shared/utils/editorHelper';
import {
  getTeamChartWithTeamidAndChartid,
  postTeamsThemeUsingTeamidAndThemeid,
  postTeamThemeUsingTeamid
} from '../../../api/cloudApiGenerated';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { getAppConfig, getProfileConfig } from '../../../redux/selectors/profile';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { setAction as setChartAction } from '../../ChartEditorPage/actions/chartEditor';
import { getAggregatedConfig, getChartConfig } from '../../ChartEditorPage/selectors/chartEditor';

export function* initThemeEditor(params) {
  const { defaultThemeDetails, isMap, isLocationMap } = params.data;
  const mostPopular = isMap ? getAllInCatToArray('Map') : getMostPopular();
  const selectedTemplate = isLocationMap ? mostPopular[7] : mostPopular[0];
  yield call(setTemplate, { data: { template: selectedTemplate, defaultThemeDetails: defaultThemeDetails } });
  if (defaultThemeDetails && !isLocationMap) {
    yield call(assignTheme, { data: { theme: merge({}, defaultThemeDetails) } });
  }
}

export function* saveTheme(params) {
  let { body } = params.data;

  const profileConfig = yield select(getProfileConfig);
  const chartConfig = yield select(getChartConfig);
  const { showWizard, tab, urlParam, type } = yield select((state) => state.projectConfig);
  const { chartId, chartOwner } = chartConfig;
  const appConfig = yield select(getAppConfig);
  const projectConfig = yield select(getProjectConfig);

  const { paths } = appConfig;
  const { division, team, user } = profileConfig;
  let { projectName } = projectConfig;

  const anonymous = !user && !team;

  const teamId = anonymous ? undefined : team.id;

  if (!body) {
    let project = {};
    project = getAggregatedConfig(projectConfig, chartConfig);

    let titleValue = projectName;

    if (!projectName) {
      if (project?.title?.text) {
        titleValue = project?.title?.text;
      } else titleValue = 'Untitled chart';
    }

    body = {
      data: JSON.stringify(project)
    };
    if (showWizard || titleValue !== 'Untitled chart') body.name = titleValue;
    // else body.name = window.Everviz.evervizEditor.getChartTitle();
  }

  snackBar('Saving theme');

  let saveChart;
  let parameters = [];

  if (!chartId || chartOwner !== teamId) {
    if (division && division.id !== null) body.division_id = division.id;
    saveChart = postTeamThemeUsingTeamid;
    parameters = [teamId, body];
  } else {
    saveChart = postTeamsThemeUsingTeamidAndThemeid;
    parameters = [teamId, chartId, body];
  }

  let data = false;

  try {
    data = yield call(saveChart, ...parameters);
    snackBar('Theme saved');

    if (!chartId) {
      const queryString = generateQueryString(qs, showWizard ? urlParam : tab, type, showWizard, false);
      let newPath = paths.editTheme.replace(':themeid', data.chart_id);
      window.history.pushState(null, null, newPath + queryString);
    }

    yield all([
      put(
        setChartAction({
          chartId: data.chart_id,
          chartOwner: data.team_owner
        })
      ),
      put(
        setProjectConfigAction({
          saved: true,
          uuid: data.uuid,
          changeMade: false,
          lastSaved: new Date()
        })
      )
    ]);
  } catch (e) {
    console.log('error:', e);
  }
}

export function* loadTheme(params) {
  const { history, chartId: id } = params.data;
  const chartConfig = yield select(getChartConfig);
  const profileConfig = yield select(getProfileConfig);
  const appConfig = yield select(getAppConfig);

  const { paths } = appConfig;
  const { localTeam, chartVersion } = chartConfig;
  const { team } = profileConfig;

  let getChart = getTeamChartWithTeamidAndChartid;

  let chartId = chartConfig.chartId || id;
  try {
    const data = yield call(getChart, ...[(localTeam ?? team).id, chartId, chartVersion]);

    yield all([
      put(
        setChartAction({
          ...data,
          chartOwner: data.team_owner,
          chartData: data,
          dataset: data.details,
          chartId
        })
      ),
      put(
        setProjectConfigAction({
          saved: true,
          uuid: data.uuid,
          changeMade: false,
          lastSaved: data.creation_time,
          projectName: data.name
        })
      )
    ]);

    let project = JSON.parse(data.data);
    if (data.details) {
      const dataset = JSON.parse(data.details);
      project.settings.dataProvider = dataset;
    }

    yield call(loadProject, {
      data: {
        project
      }
    });

    // if (cb) return cb();
  } catch (error) {
    console.log(error);
    history.push({
      pathname: paths.login
    });
  }
}

/** Watch functions */
export function* watchSaveTheme() {
  yield takeEvery(actionTypes.themeEditor.saveTheme, saveTheme);
}
export function* watchLoadTheme() {
  yield takeEvery(actionTypes.themeEditor.loadTheme, loadTheme);
}

export default function* rootSaga() {
  yield all([watchSaveTheme(), watchLoadTheme()]);
}
