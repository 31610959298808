import { LocationMapContainerRef } from '@visual-elements/location-map';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useResizeObserver from 'use-resize-observer';
import { updateReferencePointResolutionAction } from '../../../../pages/ChartEditorPage/actions/locationMap';

export type ViewBoxProps = {
  locationMapRef: LocationMapContainerRef | null;
  aspectRatio: number;
};

export const ViewBox = forwardRef<HTMLDivElement, ViewBoxProps>(({ locationMapRef, aspectRatio }, ref) => {
  const dispatch = useDispatch();

  const internalRef = useRef<HTMLDivElement | null>(null);
  const map = locationMapRef?.mapRef?.getMap();

  const [mapAspectRatio, setMapAspectRatio] = useState(map ? map.transform.width / map.transform.height : 1);

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      setMapAspectRatio(map ? map.transform.width / map.transform.height : 1);
    }
  });

  useEffect(() => {
    if (internalRef.current) {
      const { height, width } = internalRef.current.getBoundingClientRect();
      dispatch(updateReferencePointResolutionAction({ height: height, width: width }));
    }
  }, [aspectRatio]);

  useEffect(() => {
    const viewBoxContainer = internalRef.current;
    const mapContainer = map?.getContainer();

    if (viewBoxContainer && mapContainer) {
      const controlContainer = mapContainer.querySelector('div.maplibregl-control-container');
      const childrenContainer = mapContainer.querySelector('[mapboxgl-children]');

      if (controlContainer && !viewBoxContainer.contains(controlContainer)) {
        viewBoxContainer.appendChild(controlContainer);
      }

      if (childrenContainer && !viewBoxContainer.contains(childrenContainer)) {
        viewBoxContainer.appendChild(childrenContainer);
      }
    }

    return () => {
      if (viewBoxContainer) {
        if (mapContainer) {
          const controlContainer = viewBoxContainer.querySelector('div.maplibregl-control-container');
          if (controlContainer) mapContainer.appendChild(controlContainer);

          const childrenContainer = viewBoxContainer.querySelector('[mapboxgl-children]');
          if (childrenContainer) mapContainer.appendChild(childrenContainer);
        }
      }
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;
    const onResize = () => {
      setMapAspectRatio(map ? map.transform.width / map.transform.height : 1);
    };
    map.on('resize', onResize);
    return () => {
      map.off('resize', onResize);
    };
  }, [map]);

  const callbackRef = useCallback((element: HTMLDivElement) => {
    if (element) {
      internalRef.current = element;
      if (typeof ref === 'function') {
        return ref(element);
      }
      if (ref) ref.current = element;
    }
  }, []);

  if (!map) return <></>;

  const computedStyling = aspectRatio > mapAspectRatio ? { width: '90%' } : { height: '90%' };
  return (
    <div
      ref={callbackRef}
      style={{
        ...computedStyling,
        aspectRatio: aspectRatio,
        position: 'absolute',
        top: '50%',
        left: '50%',
        pointerEvents: 'none',
        transform: 'translate(-50%, -50%)',
        border: '#00000026 dashed',
        boxShadow: '0px 0px 0px 100vh #9d9d9d8a'
      }}
    />
  );
});
