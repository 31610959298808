import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getChartAccesstoWithUuid,
  getPackagesProjectWithTeamidAndUuid,
  getShowChartWithUuid,
  getTeamChartWithTeamidAndChartid,
  postTeamChartUsingTeamid
} from '../../../api/cloudApiGenerated';
import paths from '../../../paths';
import { snackBar } from 'editor/editors/highed.init';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProfileAction } from '../../../redux/actions/profile';
import { getProfileConfig } from '../../../redux/selectors/profile';
import { setAction } from '../actions/showChartPage';

export function* loadChartDetails(chart, team, setNavActions, duplicateChart, editChart) {
  if (chart && chart.data) {
    if (chart.name) chart.name = (chart.name || '').replace(/<[^>]+>/g, '');

    let actions = [];
    actions.push({
      text: '',
      tooltip: {
        text: 'Duplicate'
      },
      icon: 'bar-chart',
      className: '',
      onClick: () => {
        duplicateChart();
      }
    });

    if (team) {
      const data = yield call(getChartAccesstoWithUuid, chart.uuid);
      if (data.access) {
        actions.push({
          text: '',
          tooltip: {
            text: 'Edit'
          },
          icon: 'fa fa-pencil-alt',
          className: '',
          onClick: () => {
            editChart();
          }
        });
      }
      setNavActions(actions);
      yield put(
        setAction({
          teamId: data.teamID,
          teamName: data.teamName,
          hasAccess: true
        })
      );
    } else {
      yield put(setAction({ hasAccess: false, teamId: null, teamName: null }));
    }

    yield put(
      setAction({
        chart,
        isPublished: chart.has_published,
        twitterUrl: 'https://twitter.com/intent/tweet?url=' + encodeURI(chart.shareLink),
        facebookUrl: 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(chart.shareLink)
      })
    );
  }
}

export function* loadPackage(params) {
  const { team, id } = params.data;

  yield put(setAction({ loading: true }));
  try {
    const chart = yield call(getPackagesProjectWithTeamidAndUuid, team?.id, id);
    const shareLink = `${window.hcconfig?.backend?.hostname}/package/share/${chart?.uuid}`;

    yield put(
      setAction({
        chart,
        isPublished: true,
        twitterUrl: 'https://twitter.com/intent/tweet?url=' + encodeURI(shareLink),
        facebookUrl: 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(shareLink)
      })
    );
    yield put(setAction({ loading: false }));
  } catch (error) {
    console.error(error);
    yield put(setAction({ error: true, loading: false }));
  }
}

export function* loadChart(params) {
  const { team, id, setNavActions, duplicateChart, editChart } = params.data;

  const loadChartAjax = function () {
    if (!isNaN(Number(id))) {
      if (team === undefined) return;
      return getTeamChartWithTeamidAndChartid(team.id, id);
    } else {
      return getShowChartWithUuid(id);
    }
  };
  yield put(setAction({ loading: true }));

  try {
    const chart = yield call(loadChartAjax);
    if (chart && chart.data) yield call(loadChartDetails, chart, team, setNavActions, duplicateChart, editChart);
    yield put(setAction({ loading: false }));
  } catch (error) {
    console.error(error);
    yield put(setAction({ error: true, loading: false }));
  }
}

export function* duplicateChart(params) {
  const { team, chart, history } = params.data;
  const config = yield select(getProfileConfig);

  if (!team) return;
  const project = JSON.parse(chart.data);
  const dataset = chart.details;

  if (dataset) {
    const dataset = JSON.parse(chart.details);
    project.settings.dataProvider = dataset;
  }

  if (chart && chart.mapData) {
    //Attach map data to chart config too
    if (!project.options.chart) project.options.chart = {};
    project.options.chart.map = JSON.parse(chart.mapData);
  }

  const body = {
    data: JSON.stringify(project),
    dataId: chart.dataId || null,
    live: chart.live
  };

  try {
    const data = yield call(postTeamChartUsingTeamid, team.id, body);
    yield put(
      setProfileAction({
        chartsCreated: config.chartsCreated + 1
      })
    );
    let newPath = paths.editChartLatest.replace(':chartid', data.chart_id);
    history.push(newPath);
  } catch (error) {
    console.error(error);
    if (error && error.response && error.response.message && error.response.message[0] === 'Could not create chart') {
      snackBar('Sorry, you have run out of projects');
    }
  }
}

export function* editChart(params) {
  let { history, chartId } = params.data;
  const { teamName, teamId } = yield select((state) => state.showChartPage);
  let newPath = paths.editChartLatest.replace(':chartid', chartId);
  localStorage.setItem('editorTeam', JSON.stringify({ id: teamId, name: teamName }));
  history.push(newPath);
}

/** Watch functions */
export function* watchLoadChart() {
  yield takeEvery(actionTypes.showChartPage.loadChart, loadChart);
}

export function* watchLoadProject() {
  yield takeEvery(actionTypes.showChartPage.loadPackage, loadPackage);
}

export function* watchEditChart() {
  yield takeEvery(actionTypes.showChartPage.editChart, editChart);
}

export function* watchDuplicateChart() {
  yield takeEvery(actionTypes.showChartPage.duplicateChart, duplicateChart);
}

export default function* rootSaga() {
  yield all([watchLoadChart(), watchEditChart(), watchDuplicateChart(), watchLoadProject()]);
}
