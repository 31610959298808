import { TemplateProps } from '../../types';

const PolarLine: TemplateProps = {
  title: 'Polar line',
  description: '',
  constructor: '',
  thumbnail: 'ajogud.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'line',
      polar: true
    }
  },
  sampleSet: 'polar',
  advancedParent: 'Polar area',
  dataValidator: false
};

export default PolarLine;
