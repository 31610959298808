import { TemplateProps } from '../../types';

const ErrorBar: TemplateProps = {
  title: 'Error bar',
  description: '',
  constructor: '',
  thumbnail: 'omikax.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'errorbar',
      inverted: true,
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    series: [
      {
        type: 'errorbar'
      },
      {
        type: 'bar'
      }
    ]
  },
  sampleSet: 'bar',
  advancedParent: 'Bar',
  dataValidator: false
};

export default ErrorBar;
