export const checkPlanPermission = (billingPermissions, permission) =>
  billingPermissions && billingPermissions[permission];

export const getDivisions = (state) => state.divisions || [];

export const getFormattedDivisions = (state) => {
  // Selector doesnt seem to be working arrays
  const divisions = state.divisions || [];
  return divisions.map((division) => {
    return {
      value: division.id?.toString(),
      label: division.name
    };
  });
};
