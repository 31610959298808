export default {
  series: 1,
  data: 'Category;Series 1 (y);Series 1 (z)\n63.4;51.8;15.4\n64;82.9;31.3\n65.4;50.8;28.5\n65.5;126.4;35.3\n68.6;20;16\n69.2;57.6;10.4\n71;93.2;24.7\n73.5;83.1;10\n74.2;68.5;14.5\n78.4;70.1;16.6\n80.3;86.1;11.8\n80.4;102.5;12\n80.8;91.5;15.8\n86.5;102.9;14.7\n95;95;13.8',
  assignDataFields: [
    {
      labels: 'A',
      y: 'B',
      z: 'C',
    },
  ],
  seriesMapping: [
    {
      x: 0,
      y: 1,
      z: 2,
    },
  ],
};
