import evervizTheme from 'editor/meta/highed.meta.everviz.theme';
import { cloneDeep } from 'lodash';
import DefaultThemes from 'pages/TableEditorPage/meta/DefaultThemes';

const chartDefault = cloneDeep(evervizTheme);
const evervizLibraryDefaults = cloneDeep(DefaultThemes[0].template.options);
const tableDefault = cloneDeep(DefaultThemes[0].template.options);
const allDefault = {
  title: cloneDeep(chartDefault.title),
  subtitle: cloneDeep(chartDefault.subtitle)
};

delete chartDefault.title;
delete chartDefault.subtitle;

delete tableDefault.title;
delete tableDefault.subtitle;
delete tableDefault.caption;

delete evervizLibraryDefaults.title.text;
delete evervizLibraryDefaults.subtitle.text;
delete evervizLibraryDefaults.caption.text;

export default {
  groupId: '',
  themeId: false,
  name: '',
  error: '',
  showSaveModal: false,
  showDiscardModal: false,
  view: 'Charts',
  defaultMapTypeSelection: { label: 'Choropleth', value: 'choropleth' },
  defaultChartTypeSelection: { label: 'Line', value: 'line' },
  chartType: { label: 'Line', value: 'line' },
  options: {
    all: allDefault,
    highcharts: {
      all: chartDefault
    },
    everviz: {
      all: {
        title: evervizLibraryDefaults.title,
        subtitle: evervizLibraryDefaults.subtitle,
        caption: evervizLibraryDefaults.caption
      }
    },
    chart: {
      all: {}
    },
    map: {
      all: {
        everviz: {
          colors: cloneDeep(chartDefault.colors)
        }
      }
    },
    table: {
      all: tableDefault
    },
    cssModules: [...DefaultThemes[0].template.cssModules]
  }
};
