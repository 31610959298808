export default {
  groupId: '',
  name: '',
  groups: null,
  page: 1,
  pageSize: 10,
  pageCount: 1,
  keys: [],
  error: '',
  dataReturned: true,
};
