export enum VideoOption {
  MOV = 'MOV (w/alpha)',
  GIF = 'GIF',
  MP4 = 'MP4',
  WEBP = 'WEBP'
}

export enum LiveVideoOption {
  VizFlowics = 'Viz flowics'
}

export type VideoMimeType = 'video/quicktime' | 'image/gif' | 'video/mp4' | 'image/webp';

export const VideoTypes = ['video/quicktime', 'image/gif', 'video/mp4', 'image/webp'];

export type VideoOptionsType = {
  id: VideoOption;
  item: {
    label: VideoOption;
    value: VideoMimeType;
    type: 'video';
  };
  dimensions: { width: number | null; height: number | null };
};

export const fpsOptions = [
  {
    value: '60',
    label: '60FPS (Web)'
  },
  {
    value: '25',
    label: '25FPS (Web)'
  },
  {
    value: '50',
    label: '50P (Pal)'
  },
  {
    value: '60i',
    label: '60i (NTSC)'
  }
];

export const gifVideoOption: VideoOptionsType = {
  id: VideoOption.GIF,
  item: { label: VideoOption.GIF, value: 'image/gif', type: 'video' },
  dimensions: { width: 1920, height: 1080 }
};

export const mp4VideoOption: VideoOptionsType = {
  id: VideoOption.MP4,
  item: { label: VideoOption.MP4, value: 'video/mp4', type: 'video' },
  dimensions: { width: 1920, height: 1080 }
};

export const webPVideoOption: VideoOptionsType = {
  id: VideoOption.WEBP,
  item: { label: VideoOption.WEBP, value: 'image/webp', type: 'video' },
  dimensions: { width: 1920, height: 1080 }
};

export const movVideoOption: VideoOptionsType = {
  id: VideoOption.MOV,
  item: { label: VideoOption.MOV, value: 'video/quicktime', type: 'video' },
  dimensions: { width: 1920, height: 1080 }
};

export const videoOptions: VideoOptionsType[] = [mp4VideoOption, gifVideoOption, webPVideoOption, movVideoOption];

export const liveVideoOptions = [
  {
    id: LiveVideoOption.VizFlowics,
    item: { label: LiveVideoOption.VizFlowics, value: LiveVideoOption.VizFlowics }
  }
];
