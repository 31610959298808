import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const SpiderArea: TemplateProps = {
  title: 'Spider area',
  description: '',
  constructor: '',
  thumbnail: 'exajib.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      polar: true
    },
    xAxis: {
      tickmarkPlacement: 'on',
      lineWidth: 0
    },
    yAxis: {
      lineWidth: 0,
      gridLineInterpolation: 'polygon'
    }
  },
  sampleSet: 'polar',
  dataValidator: false,
  advancedParent: 'Spider',
  basic: {
    order: 24,
    thumbnail: 'spider_chart.svg',
    icon: 'SpiderArea'
  },
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter)
};

export default SpiderArea;
