import React, { Component } from 'react';

export default class BorderAllEdgesIcon extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="border-icon"
        viewBox="0 0 24 24"
        fill="#444"
        width="35px"
        height="19px"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M13 7h-2v2h2V7zm0 4h-2v2h2v-2zm4 0h-2v2h2v-2zM3 3v18h18V3H3zm16 16H5V5h14v14zm-6-4h-2v2h2v-2zm-4-4H7v2h2v-2z" />
      </svg>
    );
  }
}
