import { TemplateTypeProps } from '../../types';
import Scatter from './Scatter';

const ScatterTemplate: TemplateTypeProps = {
  templates: {
    'Scatter chart': Scatter
  }
};

export default ScatterTemplate;
