import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAction } from '../actions/projectsPage';

export default function Wizard(props) {
  const dispatch = useDispatch();
  const steps = [
    {
      title: 'Charts page',
      text: 'Welcome to everviz! This is where all your charts are stored. The chart you just created can be seen here!',
      icon: 'bar-chart',
      position: {
        element: '.profile-nav-bar-chart',
      },
      style: {},
      highlight: ['.profile-nav-chart-bar .fa-bar-chart'],
    },
    {
      title: 'New project',
      text: 'Create a new chart/map here!',
      icon: 'plus-circle',
      position: {
        element: '.create-chart-list',
      },
      style: {
        marginTop: '28px',
        marginLeft: '-20px',
      },
      highlight: ['.create-chart-list .bulk-action-btn.ev-red.everviz-red'],
    },
    {
      title: 'Team members',
      icon: 'users',
      text: 'Invite a colleague to your team and start collaborating on your visualisations together!',
      position: {
        element: '.profile-nav-users',
      },
      style: {},
      highlight: ['.profile-nav-users .fa-users'],
    },
    {
      title: 'Themes page',
      text: 'Brand your charts by creating a theme!',
      icon: 'paint-brush',
      position: {
        element: '.profile-nav-paint-brush',
      },
      style: {},
      highlight: ['.profile-nav-paint-brush .fa-paint-brush'],
    },
  ];
  let [index, setIndex] = useState(0);

  useEffect(() => {
    if (props.isWizardActive) {
      dimNavs();
      setupHighlight(0);
    }
  }, [props.isWizardActive]);

  const dimNavs = () => {
    const profileNav = document.querySelector('.navigation-container-vertical');
    const topNav = document.querySelector('.navbar-collapse');
    const navBarHeader = document.querySelector('.navbar.navbar-default  .navbar-header');
    profileNav.classList += ' wizard-active';
    topNav.classList += ' wizard-active';
    navBarHeader.classList += ' wizard-active';
  };

  const removeWizardNavs = () => {
    const profileNav = document.querySelector('.navigation-container-vertical');
    const topNav = document.querySelector('.navbar-collapse');
    const navBarHeader = document.querySelector('.navbar.navbar-default  .navbar-header');
    profileNav.classList.remove('wizard-active');
    topNav.classList.remove('wizard-active');
    navBarHeader.classList.remove('wizard-active');
  };

  const setupHighlight = (index) => {
    const activeStep = steps[index];

    if (index > 0) {
      //Remove previous highlight
      if (steps[index - 1].highlight) {
        steps[index - 1].highlight.forEach((h) => {
          const e = document.querySelectorAll(h);
          e.forEach((i) => {
            i.classList.remove('wizard-active-highlight');
          });
        });
      }
    }

    if (activeStep.highlight) {
      activeStep.highlight.forEach((h) => {
        const e = document.querySelectorAll(h);
        e.forEach((i) => {
          i.classList += ' wizard-active-highlight';
        });
      });
    }
  };

  const removeAllHighlighting = () => {
    steps.forEach((s) => {
      if (s.highlight) {
        s.highlight.forEach((h) => {
          const e = document.querySelectorAll(h);
          e.forEach((i) => {
            i.classList.remove('wizard-active-highlight');
          });
        });
      }
    });
  };

  const next = () => {
    if (index + 1 < steps.length) {
      setIndex(index + 1);
      setupHighlight(index + 1);
    } else {
      //Wizard finished, remove all
      dispatch(
        setAction({
          isWizardActive: false,
        })
      );
      removeWizardNavs();
      removeAllHighlighting();
    }
  };

  const skip = () => {
    dispatch(
      setAction({
        isWizardActive: false,
      })
    );
    removeWizardNavs();
    removeAllHighlighting();
  };

  const activeStep = steps[index];
  var rect = document.querySelector(activeStep.position.element).getBoundingClientRect();
  return (
    <div className={'wizard-overlay ' + (props.isWizardActive ? 'active' : '')}>
      <div
        className="modal-window"
        style={{ top: rect.bottom - 66 + 'px', left: rect.left - 61 + 'px', ...activeStep.style }}
      >
        <div className="wizard-container">
          <div className="icon">
            <i className={'fa fa-' + activeStep.icon} />
          </div>
          <div className="wizard-text-container">
            <div className="header"> {activeStep.title} </div>
            <div className="text"> {activeStep.text} </div>
          </div>
        </div>
        <div className="button-container">
          <button
            onClick={next}
            className={`bulk-action-btn next-button text-ev-navy-blue-2 hover:text-ev-navy-blue-2 focus:text-ev-navy-blue-2 `}
          >
            {index === steps.length - 1 ? 'Finish' : 'Next'}
          </button>
          <button onClick={skip} className="bulk-action-btn next-button skip-button text-ev-grey">
            Skip tips
          </button>
        </div>
      </div>
    </div>
  );
}
