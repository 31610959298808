/* eslint-disable no-invalid-this */
import React, { useState } from 'react';
import { copyTextToClipboard } from '../../utils/inputHelper';
import IconButton from '../buttons/IconButton';
import LoaderIcon from 'shared/loader/LoaderIcon';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { merge } from 'lodash';
import { openPaidPlanModalAction } from 'redux/actions/app';
import { useDispatch } from 'react-redux';

type CopyButtonTypes = { icon: string | JSX.Element; text: string };

enum CopyButtonStatus {
  Ready = 'Ready',
  Pending = 'Pending',
  Done = 'Done'
}

type CopyInputProps = {
  value: string;
  tabIndex?: number;
  noText?: boolean;
  states?: Record<CopyButtonStatus, Partial<CopyButtonTypes>>;
  useTooltip?: boolean;
  onClick?: (value: string) => void;
  locked?: boolean;
};

const CopyButton = ({ value, tabIndex, noText, states, useTooltip, onClick, locked }: CopyInputProps) => {
  const [buttonStatus, setButtonStatus] = useState(CopyButtonStatus.Ready);
  const dispatch = useDispatch();

  const handleCopy = () => {
    setButtonStatus(CopyButtonStatus.Done);
    setTimeout(() => setButtonStatus(CopyButtonStatus.Ready), 2000);
  };
  // timeout for displaying pending status - just to inform user about the action
  const handleCopyClick = () => {
    if (locked) {
      dispatch(openPaidPlanModalAction({ paidPlanType: 'starter' }));
      return;
    }
    if (onClick) onClick(value);
    else copyTextToClipboard(value, true);
    setButtonStatus(CopyButtonStatus.Pending);
    setTimeout(() => handleCopy(), 700);
  };

  const getButtonDataForStatus = (status: CopyButtonStatus): CopyButtonTypes => {
    switch (status) {
      case CopyButtonStatus.Ready:
        return merge({ icon: 'copy', text: 'Copy' }, states?.Ready ?? {});
      case CopyButtonStatus.Pending:
        return merge({ icon: <LoaderIcon loading={true} />, text: 'Copy' }, states?.Pending ?? {});
      case CopyButtonStatus.Done:
        return merge({ icon: 'check-circle', text: 'Copied' }, states?.Done ?? {});
      default:
        return merge({ icon: 'copy', text: 'Copy' }, states?.Ready ?? {});
    }
  };

  const buttonState = getButtonDataForStatus(buttonStatus);

  return (
    <span
      className={`
    min-w-[40px] w-[40px] h-[40px] 
    group 
    transition-all ${noText ? '' : 'hover:w-[100px] hover:min-w-[100px] '} ${useTooltip ? '' : 'overflow-hidden'}`}
    >
      <IconButton
        icon={buttonState.icon}
        onClick={handleCopyClick}
        tabIndex={tabIndex}
        className={`
        rounded w-10 h-10 overflow-hidden 
        relative ${noText ? '' : 'group-hover:w-[100px] group-hover:text-left group-hover:pl-3 '}`}
        buttonColor={buttonStatus === CopyButtonStatus.Ready ? ButtonColor.White : ButtonColor.NavyBlue}
        iconClass={`text-xl ${
          buttonStatus === CopyButtonStatus.Ready || buttonStatus === CopyButtonStatus.Pending ? 'fad' : ''
        }`}
        hoverText={
          noText || locked ? (
            ''
          ) : (
            <span className="font-bold absolute top-2 left-9 w-0 opacity-0 group-hover:w-10 h-10 group-hover:opacity-100 transition-all ">
              {buttonState.text}
            </span>
          )
        }
        tooltipText={useTooltip ? buttonState?.text : ''}
      />
    </span>
  );
};

export default CopyButton;
