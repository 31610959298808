import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocationMapCustomizedAction } from '../../../../pages/ChartEditorPage/actions/locationMap';
import { ProjectConfigLocationMapProps } from '../../../../pages/Editor/reducers/locationMapConfigTypes';
import { getProjectConfig } from '../../../../redux/selectors/projectConfig';
import CheckboxWidget from '../../../widgets/CheckboxWidget';
import FunctionPickerWidget from '../../../widgets/FontPickerWidget';
import { SectionNode } from '../SectionNode';

export type AppearanceSectionProps = {
  isCompact?: boolean;
  showContainer?: boolean;
  openedByDefault?: boolean;
};

export const AppearanceSection = ({ isCompact, showContainer, openedByDefault }: AppearanceSectionProps) => {
  const { aggregatedOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);
  const dispatch = useDispatch();

  const setAction = (path: string, value: unknown) => {
    dispatch(
      updateLocationMapCustomizedAction({
        optionProps: path,
        val: value
      })
    );
  };

  return (
    <SectionNode
      showContainer={showContainer}
      sectionKey="appearance"
      headerText="Appearance"
      openedByDefault={openedByDefault}
    >
      <CheckboxWidget
        value={aggregatedOptions.miniMap.enabled}
        onChange={(e) => setAction('miniMap.enabled', e.val as boolean)}
        label={'Minimap'}
      />
      {!isCompact && (
        <>
          <FunctionPickerWidget
            aggregatedOptions={aggregatedOptions}
            label="Text font"
            option={{
              custom: { simple: true },
              default: {
                fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
                fontSize: '12px'
              },
              id: 'styleOverride',
              pid: 'chart.style'
            }}
            onChange={(e: { val: string }) => setAction('styleOverride.fontFamily', e.val)}
          />
        </>
      )}
    </SectionNode>
  );
};
