import React from 'react';
import { stripHtml } from '../../utils/helper';
import ColorWidget from './ColorWidget';
import InputWidget from './InputWidget';
import SelectWidget from './SelectWidget';
import CheckboxWidget from './CheckboxWidget';
import FontStyler from './FontStyler';
import { isObj } from 'editor/core/highcharts-editor';

class ColumnSelector extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.draggedOver = this.draggedOver.bind(this);
    this.onDropped = this.onDropped.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.processConditionalFormatting = this.processConditionalFormatting.bind(this);
    this.onChangeShowColumnValue = this.onChangeShowColumnValue.bind(this);
    this.onChangeAlign = this.onChangeAlign.bind(this);
    this.isSelectedChart = this.isSelectedChart.bind(this);
    this.onColorClick = this.onColorClick.bind(this);

    this.state = {
      value: this.props.value || '',
      selected: 0,
      ruleValue: null,
      showColumnValue: true,
      rules: [
        {
          value: '=',
          label: 'Equals'
        },
        {
          value: 'like',
          label: 'Text Contains'
        },
        {
          value: '>',
          label: 'Greater than'
        },
        {
          value: '<',
          label: 'Less than'
        }
      ],
      textValue: '',
      colorValue: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      });
    }
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    });

    this.props.onChange({ val: e.target.value });
  }

  onSelectChange(newValue) {
    this.setState(
      {
        ruleValue: newValue.val
      },
      () => {
        this.processConditionalFormatting();
      }
    );
  }

  onChangeValue(newValue) {
    this.setState(
      {
        textValue: newValue.val
      },
      () => {
        this.processConditionalFormatting();
      }
    );
  }

  onColorClick(newValue) {
    this.setState(
      {
        colorValue: newValue.val
      },
      () => {
        this.processConditionalFormatting();
      }
    );
  }

  onChangeShowColumnValue(newValue) {
    this.setState({
      showColumnValue: newValue.val
    });

    this.props.updateCustomizedArrayProp('columns', this.state.selected, {
      visible: newValue.val
    });
  }

  onClick(e) {
    const index = parseInt(e.target.getAttribute('data-index'), 10);
    const aggOption = this.props.aggregatedOptions.columns[index];

    this.setState({
      selected: index,
      ruleValue: aggOption.rule || '=',
      textValue: aggOption.value || '',
      colorValue: aggOption.color || '',
      showColumnValue: aggOption.visible,
      style: aggOption.style
    });
  }

  draggedOver(e) {
    if (this.props.isGSheet) return;
    e.preventDefault();
    const index = parseInt(e.target.getAttribute('data-index'), 10);
    if (this.state.draggedOver !== e.target.getAttribute('data-index')) {
      this.setState({
        draggedOver: index
      });
    }
  }
  onDragStart(e) {
    if (this.props.isGSheet) return;
    e.dataTransfer.setData('element', e.target.getAttribute('data-index'));
    e.dataTransfer.setData('isChart', e.target.getAttribute('data-ischart'));
  }

  onDropped(e) {
    if (this.props.isGSheet) return;
    e.preventDefault();
    let data = e.dataTransfer.getData('element');
    let isChart = e.dataTransfer.getData('isChart');
    this.setState({
      draggedOver: null
    });

    this.props.changeColumnOrder(
      parseInt(data, 10),
      parseInt(e.target.getAttribute('data-index'), 10),
      isChart === 'false'
    );
  }

  processConditionalFormatting() {
    this.props.processConditionalFormatting(
      this.state.selected,
      this.state.ruleValue || '=',
      this.state.textValue || '',
      this.state.colorValue || 'black'
    );
  }

  onChangeAlign(newValue) {
    this.setState({
      style: {
        textAlign: newValue.val
      }
    });
    this.props.updateCustomizedArrayProp('columns', this.state.selected, {
      style: {
        textAlign: newValue.val
      }
    });
  }

  isSelectedChart() {
    const { selected } = this.state;
    const columns = this.props.aggregatedOptions.columns || [];
    if (columns && columns[selected]) {
      if (columns[selected].type === 'chart') {
        return true;
      }
    }
    return false;
  }

  render() {
    const { aggregatedOptions, dataOptions, isGSheet = false } = this.props;
    const hasSelectedChart = this.isSelectedChart();
    let i = -1;

    return (
      <>
        <p className="column-text"> Columns can be moved by dragging them up and down the list box. </p>
        <div className={'column-selector-widget'}>
          {dataOptions.length > 0 &&
            (aggregatedOptions.columns || []).map((element, x) => {
              const isChart = 'columnName' in element;
              if (!isChart) i++;
              const isDataObj = isObj(dataOptions[0][i]);
              if (isDataObj && dataOptions[0][i].mergedCell) return <React.Fragment key={'mergedcell_' + x} />;

              return (
                <div
                  data-index={x}
                  data-ischart={isChart}
                  onClick={!isChart ? this.onClick : null}
                  key={`col_${x}`}
                  className={
                    'column-option ' +
                    (isChart ? 'is-chart ' : '') +
                    (this.state.selected === x && !isChart ? 'active' : '') +
                    (this.state.draggedOver === x ? ' dragged-over' : '')
                  }
                  draggable={!isGSheet}
                  onDragStart={this.onDragStart}
                  onDragOver={this.draggedOver}
                  onDrop={this.onDropped}
                >
                  {'columnName' in element ? (
                    <span>
                      <i className="fa fa-bar-chart" /> {stripHtml(element.columnName)}
                    </span>
                  ) : (
                    stripHtml(isDataObj ? dataOptions[0][i].value : dataOptions[0][i])
                  )}
                  <span className="move-icon">
                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                  </span>
                </div>
              );
            })}
        </div>
        {!hasSelectedChart && (
          <>
            <div className="column-options p-2">
              <div className="property-label conditional-formatting-label">Conditional formatting</div>

              <div className="flex flex-col gap-2">
                <SelectWidget
                  label={'Rules'}
                  selectOptions={this.state.rules}
                  onChange={this.onSelectChange}
                  value={this.state.ruleValue}
                />

                <InputWidget label={'Value'} value={this.state.textValue} onChange={this.onChangeValue} />
                <ColorWidget label={'Color'} onChange={this.onColorClick} value={this.state.colorValue || ''} />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <CheckboxWidget
                label={'Show column'}
                onChange={this.onChangeShowColumnValue}
                value={this.state.showColumnValue}
              />

              <FontStyler
                label={'Position'}
                aggregatedOptions={{
                  style: {
                    textAlign: this.state.style ? this.state.style.textAlign || 'left' : 'left'
                  }
                }}
                option={{
                  id: 'style',
                  custom: {
                    fields: {
                      align: 1
                    }
                  }
                }}
                onChange={this.onChangeAlign}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default ColumnSelector;
