import React, { useEffect, useState } from 'react';
import { Quill } from 'react-quill';
import { useSelector } from 'react-redux';
import './../../../node_modules/react-quill/dist/quill.snow.css';
import { getDefaultTooltipConfig, getTooltipOptions } from './utils/tooltipHelper';
import RichtextGroupWidget from './richtextgroup/RichtextGroupWidget';
import SelectWidget from './SelectWidget';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import PlusIcon from './PlusIcon';

export const generateDropdown = (dropdownOptions, onChange, value, addSelectedDropdownValue, label) => (
  <div className="relative p-1 pt-4 w-full">
    <div className="text-ev-navy-blue font-bold mb-2">{label}</div>
    <div className="flex w-full gap-2 content-centered">
      <div className="w-full ev-sm:w-60">
        <SelectWidget selectOptions={dropdownOptions} onChange={onChange} value={value} customStyles={editorStyles} />
      </div>
      <PlusIcon onClick={addSelectedDropdownValue} />
    </div>
  </div>
);

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

export default (props) => {
  const { value, parentPath, chart } = props;
  const { pointFormat, headerFormat } = value ?? {};
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdownValue, setDropdownValue] = useState();
  const [activeDropdown, setActiveDropdown] = useState('header');
  const { tab, dataOptions } = useSelector((state) => state.projectConfig);

  useEffect(() => {
    if (tab === 'customize' && chart) {
      if (chart.update) chart.update(getDefaultTooltipConfig(), true);

      if (chart.series?.[0]?.points?.length && chart.tooltip?.refresh) {
        chart.tooltip.refresh([chart.series[0].points[0]]);
      }

      const [dataGridHeaders] = dataOptions;
      const options = getTooltipOptions(chart?.evervizTooltipOptions ?? {}, dataGridHeaders);
      setDropdownOptions(options);
    }
  }, [tab, chart]);

  const addValue = (variable, text) => {
    let getBody = (str) => new DOMParser().parseFromString(str, 'text/html').body;
    let node = getBody(variable);
    const firstNode = node.childNodes[0];
    const textNode = document.createTextNode(text);
    if (firstNode.tagName === 'SPAN') {
      const divNode = document.createElement('div');
      divNode.innerHTML = node.innerHTML;
      divNode.appendChild(textNode);
      return divNode.outerHTML;
    }
    node.appendChild(textNode);
    return node.innerHTML;
  };

  const addSelectedDropdownValue = (type, val) => {
    const { aggregatedOptions } = props;
    if (!val && (dropdownOptions ?? []).length) {
      // Probably selected first option
      val = dropdownOptions[0].value;
    }

    const mergeFieldText = '{' + val + '}';
    if (val) {
      if (type === 'header') {
        const value = addValue(aggregatedOptions.tooltip.headerFormat, mergeFieldText);
        onChangeText('headerFormat', { val: value });
      } else {
        const value = addValue(aggregatedOptions.tooltip.pointFormat, mergeFieldText);
        onChangeText('pointFormat', { val: value });
      }
    }
  };

  const onChangeText = (varName, newValue) =>
    props.onChange({ optional: { ...props.option, id: varName }, val: newValue });

  const headerDropdown = generateDropdown(
    dropdownOptions,
    setDropdownValue,
    dropdownValue,
    () => addSelectedDropdownValue('header', dropdownValue?.val),
    'Add information from data'
  );
  const bodyDropdown = generateDropdown(
    dropdownOptions,
    setDropdownValue,
    dropdownValue,
    () => addSelectedDropdownValue('body', dropdownValue?.val),
    'Add information from data'
  );

  return (
    <>
      <div className="highed-tooltip-helper-container">
        <div className="highed-tooltip-helper-section-container">
          <RichtextGroupWidget
            option={{
              ...props.option,
              id: 'headerFormat',
              richtext: {
                ...props.option.richtext,
                rawKey: 'headerFormat'
              }
            }}
            chart={props.chart}
            aggregatedOptions={props.aggregatedOptions}
            type={props.type}
            onChange={(val) => onChangeText('headerFormat', val)}
            onClick={() => setActiveDropdown('header')}
            value={{ text: headerFormat ?? '' }}
            parentPath={parentPath}
          />

          <RichtextGroupWidget
            option={{
              ...props.option,
              id: 'pointFormat',
              richtext: {
                ...props.option.richtext,
                rawKey: 'pointFormat'
              }
            }}
            chart={props.chart}
            aggregatedOptions={props.aggregatedOptions}
            type={props.type}
            onChange={(val) => onChangeText('pointFormat', val)}
            className={'caption-text'}
            onClick={() => setActiveDropdown('body')}
            value={{ text: pointFormat ?? '' }}
            parentPath={parentPath}
          />
          {activeDropdown === 'header' && headerDropdown}
          {activeDropdown === 'body' && bodyDropdown}
        </div>
      </div>
    </>
  );
};
