import React, { MouseEvent } from 'react';
import Button from 'shared/buttons/Button';
import CheckboxInput from 'shared/inputs/CheckboxInput';
import TextInput from 'shared/inputs/TextInput';
import SelectWidget from 'shared/widgets/SelectWidget';

const convertValueEditMode = (
  value: string,
  type: string,
  name: string,
  info: string,
  validValues: any[],
  isDisabled: boolean,
  onCellSelectChange: any,
  onCellChange: any
) => {
  const onSelectChange = (selectedElement: any) => {
    onCellSelectChange(name, selectedElement);
  };

  const handlePropagationPrevent = (event: MouseEvent<HTMLSelectElement | HTMLInputElement>) => {
    event.stopPropagation();
  };

  switch (type) {
    case 'select':
      return (
        <SelectWidget
          key={value}
          selectOptions={validValues}
          onChange={onSelectChange}
          value={validValues.find((validValue) => validValue.value === value)?.value}
          hideLabel={true}
          inlineSelect={true}
          isDisabled={isDisabled}
        />
      );
    case 'bool':
      return <CheckboxInput name={name} checked={!!(value || false)} disabled={isDisabled} onChange={onCellChange} />;
    default:
      return (
        <TextInput
          extraClass="m-0 p-0"
          name={name}
          value={value}
          infoText={info}
          disabled={isDisabled}
          onChange={onCellChange}
          onClick={handlePropagationPrevent}
        />
      );
  }
};

const isValidDate = (date: any) => {
  return date instanceof Date && !isNaN(date as any);
};

const copyTextToClipboard = (event: MouseEvent<HTMLElement>) => {
  const text = (event.target as any).id;
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    document.body.removeChild(textArea);
    alert(successful ? 'Copied to clipboard!' : 'Something went wrong...');
  } catch (err) {
    document.body.removeChild(textArea);
  }
};

const addCopyIcon = (value: string) => {
  return (
    <span>
      <i onClick={copyTextToClipboard} className="fa fa-files-o clickable" aria-hidden="true" id={value} /> &nbsp;
      {value}
    </span>
  );
};

const convertValue = (value: string, type: string, validValues: any[], copyable: boolean) => {
  const getSelectValueName = () => {
    let valueName = value;

    if (validValues) {
      validValues.some((option) => {
        if (type === 'select' && option.value === value) {
          valueName = option.label;
        } else if (option.id === value) {
          valueName = option.name;
          return true;
        }

        return false;
      });
    }

    return valueName;
  };

  const getDate = () => {
    const date: any = new Date(value);

    return (
      <div title={date.toString()}>
        {value && isValidDate(date) ? date.toJSON().slice(0, 10).replace(/-/g, '/') : '---'}
      </div>
    );
  };

  return (
    {
      bool: [true, 'true', 1, '1'].indexOf(value) >= 0 ? 'Yes' : 'No',
      date: getDate(),
      img: (
        <div
          className="bgImg"
          style={{ backgroundImage: 'url("' + value + '")', backgroundPosition: 'left', marginLeft: '10px' }}
        />
      ),
      select: getSelectValueName()
    }[type] || (copyable ? addCopyIcon(value) : value)
  );
};

export const getColumnCells = (
  columns: any,
  columnCells: any,
  editableColumns: any,
  rowData: any,
  isEditMode: boolean,
  onCellSelectChange: any,
  onCellChange: any
) => {
  const copiedColumns = Array.from(columns);
  const copiedCells = Array.from(columnCells);

  copiedColumns.forEach((column: any, index: number) => {
    const info = editableColumns && editableColumns[column.id];
    const cents_to_dollars = column.cents_to_dollars || false;
    const value = cents_to_dollars ? rowData[column.id] / 100 : rowData[column.id];
    const type = column.type;
    const copyable = column.copy;

    if (info) {
      copiedCells[index] = convertValueEditMode(
        value,
        type,
        column.id,
        info.description,
        info.validValues,
        !isEditMode,
        onCellSelectChange,
        onCellChange
      );
    } else {
      let cell: any = convertValue(value, type, info && info.validValues, copyable);

      if (cell === undefined || cell === null || cell === false) {
        cell = '---';
      }

      copiedCells[index] = cell;
    }
  });

  return copiedCells;
};

const createActionCells = (actions: any[]) => {
  const handleOnClick = (event: MouseEvent<HTMLElement>, actionEvent: () => void) => {
    event.stopPropagation();
    event.preventDefault();
    actionEvent();
  };

  return (
    <div className="rowActions">
      {actions.map((action, index) => {
        return (
          <Button
            key={index}
            extraClass={action.className ? action.className : 'rowAction bulk-action-btn secondary-btn'}
            buttonText={action.text}
            onClick={(event) => handleOnClick(event, action.onClick)}
            disabled={false}
          />
        );
      })}
    </div>
  );
};

export const getActionCells = (
  isDisabled: boolean,
  isEditMode: boolean,
  editText: any,
  onEdit: () => void,
  onSave: () => void,
  onCancel: () => void
) => {
  if (isDisabled) {
    return null;
  } else if (!isEditMode) {
    return createActionCells([
      {
        text: editText && editText > '' ? editText : 'Edit',
        onClick: onEdit
      }
    ]);
  } else {
    return createActionCells([
      {
        text: 'Save',
        onClick: onSave
      },
      {
        text: 'Cancel',
        onClick: onCancel,
        className: 'bulk-action-btn secondary-btn negative'
      }
    ]);
  }
};
