import React, { MouseEventHandler } from 'react';

export type ScreenDimmerProps = {
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
  opacity: number;
  zIndex: number;
};

export const ScreenDimmer = ({ onClick, opacity, zIndex }: ScreenDimmerProps) => {
  return (
    <div
      onClick={onClick}
      style={{
        opacity: opacity,
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '0px',
        top: '0px',
        zIndex: zIndex,
        background: '#ccc',
        cursor: 'pointer'
      }}
    />
  );
};
