(function (Highcharts) {
  var isAsyncChart = false;
  var loadedAsync = false;

  Highcharts.Renderer.prototype.symbols['everviz-navigator-handle'] = function (x, y, w, h, options) {
    var halfWidth = ((options && options.width) || 0) / 2,
      markerPosition = Math.round(halfWidth / 3) + 0.5,
      height = (options && options.height) || 0;
    return [
      ['M', -halfWidth - 1, 0.5],
      ['L', halfWidth, 0.5],
      ['L', halfWidth, height + 0.5],
      ['L', -halfWidth - 1, height + 0.5],
      ['L', -halfWidth - 1, 0.5],
      ['M', -markerPosition, 4],
      ['L', -markerPosition, height - 3],
      ['M', markerPosition - 1, 4],
      ['L', markerPosition - 1, height - 3]
    ];
  };

  function createHandle(chart) {
    return chart.renderer.symbol('everviz-navigator-handle', -7 / 2 - 1, 0, 7, 15, {
      width: 7,
      height: 15,
      symbols: ['everviz-navigator-handle', 'everviz-navigator-handle'],
      enabled: true,
      lineWidth: 1,
      backgroundColor: '${palette.neutralColor5}',
      borderColor: '${palette.neutralColor40}',
      inverted: chart.inverted
    });
  }

  function removeChartInteractivity(chart) {
    chart.update({
      chart: {
        animation: false
      },
      tooltip: {
        enabled: false
      }
    });
  }

  function translateXYHandle(handle, x, y, rotation) {
    handle.translateX = x;
    handle.translateY = y;
    handle.rotation = rotation;
    handle.element.setAttribute('transform', ['translate(', x, ', ' + y, ') rotate(', rotation, ')'].join(''));
  }

  function updateHandlePosition(handle, chart) {
    var midPoint;
    if (
      handle.evervizParentPlotBand.svgElem &&
      handle.evervizParentPlotBand.svgElem &&
      (!handle.evervizParentPlotBand.svgElem.pathArray || !handle.evervizParentPlotBand.svgElem.pathArray.length)
    ) {
      return;
    }

    if (handle.evervizParentPlotBand.yAxisType) {
      if (!chart.inverted)
        midPoint =
          Math.abs(
            handle.evervizParentPlotBand.svgElem.pathArray[0][1] - handle.evervizParentPlotBand.svgElem.pathArray[1][1]
          ) / 2;
      else
        midPoint =
          Math.abs(
            handle.evervizParentPlotBand.svgElem.pathArray[0][2] - handle.evervizParentPlotBand.svgElem.pathArray[1][2]
          ) / 2;
    } else {
      if (!chart.inverted)
        midPoint =
          Math.abs(
            handle.evervizParentPlotBand.svgElem.pathArray[0][2] - handle.evervizParentPlotBand.svgElem.pathArray[1][2]
          ) / 2;
      else
        midPoint =
          Math.abs(
            handle.evervizParentPlotBand.svgElem.pathArray[0][1] - handle.evervizParentPlotBand.svgElem.pathArray[1][1]
          ) / 2;
    }

    if (handle.evervizHandleType === 'left') {
      if (handle.evervizParentPlotBand.yAxisType) {
        if (chart.inverted)
          translateXYHandle(
            handle,
            handle.evervizParentPlotBand.svgElem.pathArray[0][1],
            handle.evervizParentPlotBand.svgElem.pathArray[0][2] + midPoint,
            0
          );
        else
          translateXYHandle(
            handle,
            handle.evervizParentPlotBand.svgElem.pathArray[0][1] + midPoint,
            handle.evervizParentPlotBand.svgElem.pathArray[0][2],
            90
          );
      } else {
        if (chart.inverted)
          translateXYHandle(
            handle,
            handle.evervizParentPlotBand.svgElem.pathArray[0][1] + midPoint + 7,
            handle.evervizParentPlotBand.svgElem.pathArray[0][2],
            90
          );
        else
          translateXYHandle(
            handle,
            handle.evervizParentPlotBand.svgElem.pathArray[0][1],
            handle.evervizParentPlotBand.svgElem.pathArray[0][2] + midPoint,
            0
          );
      }
    } else {
      if (handle.evervizParentPlotBand.yAxisType) {
        if (chart.inverted)
          translateXYHandle(
            handle,
            handle.evervizParentPlotBand.svgElem.pathArray[3][1],
            handle.evervizParentPlotBand.svgElem.pathArray[3][2] + midPoint,
            0
          );
        else
          translateXYHandle(
            handle,
            handle.evervizParentPlotBand.svgElem.pathArray[3][1] + midPoint,
            handle.evervizParentPlotBand.svgElem.pathArray[3][2],
            90
          );
      } else {
        if (chart.inverted)
          translateXYHandle(
            handle,
            handle.evervizParentPlotBand.svgElem.pathArray[3][1] + midPoint + 7,
            handle.evervizParentPlotBand.svgElem.pathArray[3][2],
            90
          );
        else
          translateXYHandle(
            handle,
            handle.evervizParentPlotBand.svgElem.pathArray[3][1],
            handle.evervizParentPlotBand.svgElem.pathArray[3][2] + midPoint,
            0
          );
      }
    }
  }

  function updateHandle(chart, option, plotBandIndex, type, index, isBand, xPos, axisType, yAxisType) {
    var midPoint;
    if (
      option.svgElem &&
      (!option.svgElem.pathArray ||
        (option.svgElem.pathArray && (!option.svgElem.pathArray || !option.svgElem.pathArray.length)))
    ) {
      return;
    }
    if (yAxisType) {
      if (!chart.inverted) midPoint = Math.abs(option.svgElem.pathArray[0][1] - option.svgElem.pathArray[1][1]) / 2;
      else midPoint = Math.abs(option.svgElem.pathArray[0][2] - option.svgElem.pathArray[1][2]) / 2;
    } else {
      if (!chart.inverted) midPoint = Math.abs(option.svgElem.pathArray[0][2] - option.svgElem.pathArray[1][2]) / 2;
      else midPoint = Math.abs(option.svgElem.pathArray[0][1] - option.svgElem.pathArray[1][1]) / 2;
    }

    option.handles[index].evervizHandleType = type;

    if (yAxisType) {
      if (chart.inverted)
        translateXYHandle(
          option.handles[index],
          option.svgElem.pathArray[xPos][1],
          option.svgElem.pathArray[xPos][2] + midPoint,
          0
        );
      else
        translateXYHandle(
          option.handles[index],
          option.svgElem.pathArray[xPos][1] + midPoint,
          option.svgElem.pathArray[xPos][2] - 15,
          90
        );
    } else {
      if (chart.inverted)
        translateXYHandle(
          option.handles[index],
          option.svgElem.pathArray[xPos][1] + midPoint,
          option.svgElem.pathArray[xPos][2] - 15,
          90
        );
      else
        translateXYHandle(
          option.handles[index],
          option.svgElem.pathArray[xPos][1],
          option.svgElem.pathArray[xPos][2] + midPoint,
          0
        );
    }

    option.handles[index].element.onmousedown = function (e) {
      chart.resizePlotBand = {
        option: option,
        handle: option.handles[index],
        type: type,
        index: plotBandIndex,
        isBand: isBand,
        axisType: axisType,
        yAxisType: yAxisType
      };
      removeChartInteractivity(chart);
      if (option.label) option.label.hide();

      if (!isBand) {
        //Click handle instead of plotband to select in editor
        if (window['EvervizWizard']) {
          window['EvervizWizard'].selectPlotBand(chart.resizePlotBand, axisType, isBand, option);
        } else if (window['Everviz'] && window['Everviz'].evervizEditor) {
          window['Everviz'].evervizEditor.selectPlotBand(chart.resizePlotBand, axisType, isBand, option);
        }
      }
    };
    option.handles[index].element.onmouseup = function (e) {
      if (chart.resizePlotBand) {
        if (chart.resizePlotBand.hasOwnProperty('newFrom')) {
          if (chart.resizePlotBand.isBand) {
            chart.resizePlotBand.option.options.from = chart.resizePlotBand.newFrom;
            chart.resizePlotBand.option.options.to = chart.resizePlotBand.newTo;
          } else {
            chart.resizePlotBand.option.options.value = chart.resizePlotBand.newFrom;
          }
          chart.resizePlotBand.option.render();
          if (window['EvervizWizard']) {
            window['EvervizWizard'].updatePlotBands(
              chart.resizePlotBand,
              {
                from: chart.resizePlotBand.newFrom,
                to: chart.resizePlotBand.newTo
              },
              chart.resizePlotBand.isBand
            );
          } else if (window['Everviz'] && window['Everviz'].evervizEditor) {
            window['Everviz'].evervizEditor.updatePlotBands(
              chart.resizePlotBand,
              {
                from: chart.resizePlotBand.newFrom,
                to: chart.resizePlotBand.newTo
              },
              chart.resizePlotBand.isBand
            );
          }
        }
        if (chart.resizePlotBand.option.label) chart.resizePlotBand.option.label.show();
        chart.resizePlotBand = false;
      }
    };
  }

  function getResizeLeftProps(chart, normalEvent, xyPos) {
    var newFrom = chart[chart.resizePlotBand.axisType][0].toValue(normalEvent[xyPos]);
    var newTo = chart.resizePlotBand.option.options.to;
    var position;
    if (chart.resizePlotBand.isBand)
      position = chart.resizePlotBand.option.axis.getPlotBandPath(
        chart[chart.resizePlotBand.axisType][0].toValue(normalEvent[xyPos]),
        chart.resizePlotBand.option.options.to,
        chart.resizePlotBand.option.options
      );
    else position = chart.resizePlotBand.option.axis.getPlotLinePath({ value: newFrom });
    return {
      position: position,
      newFrom: newFrom,
      newTo: newTo
    };
  }

  function getResizeRightProps(chart, normalEvent, xyPos) {
    var newFrom = chart.resizePlotBand.option.options.from;
    var newTo = chart[chart.resizePlotBand.axisType][0].toValue(normalEvent[xyPos]);
    var position = chart.resizePlotBand.option.axis.getPlotBandPath(
      chart.resizePlotBand.option.options.from,
      chart[chart.resizePlotBand.axisType][0].toValue(normalEvent[xyPos]),
      chart.resizePlotBand.option.options
    );
    return {
      position: position,
      newFrom: newFrom,
      newTo: newTo
    };
  }

  function getResizingProps(chart, normalEvent) {
    if (chart.resizePlotBand.type === 'left') {
      if (chart.resizePlotBand.yAxisType)
        return getResizeLeftProps(chart, normalEvent, chart.inverted ? 'chartX' : 'chartY');
      else return getResizeLeftProps(chart, normalEvent, chart.inverted ? 'chartY' : 'chartX');
    } else if (chart.resizePlotBand.type === 'right') {
      if (chart.resizePlotBand.yAxisType)
        return getResizeRightProps(chart, normalEvent, chart.inverted ? 'chartX' : 'chartY');
      else return getResizeRightProps(chart, normalEvent, chart.inverted ? 'chartY' : 'chartX');
    }
  }

  function getDraggedProps(chart, normalEvent, orientation, yPos, offset) {
    const newFrom =
        chart[chart.draggingPlotBand.axisType][0].toValue(normalEvent['chart' + orientation]) -
        chart.draggingPlotBand['offset' + orientation],
      newTo =
        chart[chart.draggingPlotBand.axisType][0].toValue(normalEvent['chart' + orientation]) +
        chart.draggingPlotBand.origDiff -
        chart.draggingPlotBand['offset' + orientation];

    chart.draggingPlotBand.newFrom = newFrom;
    chart.draggingPlotBand.newTo = newTo;

    var newPosition = chart.draggingPlotBand.option.axis.getPlotBandPath(
        newFrom,
        newTo,
        chart.draggingPlotBand.option.options
      ),
      position = newPosition
        .map(function (pos) {
          return pos.join(' ');
        })
        .join(' ');
    chart.draggingPlotBand.option.svgElem.attr({
      d: position
    });
    var handleTransform = {};
    var translateOrientation = 'translate' + orientation;
    chart.draggingPlotBand.option.handles.forEach(function (handle, index) {
      if (index) {
        if (orientation === 'X') translateXHandle(handle, newPosition[3][yPos] - offset);
        else translateYHandle(handle, newPosition[3][yPos] - offset);
      } else {
        if (orientation === 'X') translateXHandle(handle, newPosition[0][yPos] - offset);
        else translateYHandle(handle, newPosition[0][yPos] - offset);
      }
    });
  }

  function translateYHandle(handle, y) {
    translateXYHandle(handle, handle.translateX, y, handle.rotation);
  }
  function translateXHandle(handle, x) {
    translateXYHandle(handle, x, handle.translateY, handle.rotation);
  }

  function getCursorType(inverted, isYAxis) {
    if ((isYAxis && !inverted) || (!isYAxis && inverted)) return 'row-resize';
    else if ((isYAxis && inverted) || (!isYAxis && !inverted)) return 'col-resize';
  }

  function load(chart) {
    ['xAxis', 'yAxis'].forEach(function (axis) {
      const axisType = axis === 'yAxis';
      (chart[axis][0].plotLinesAndBands || []).forEach(function (option, plotBandIndex) {
        const isBand = Highcharts.defined(option.options.from) && Highcharts.defined(option.options.to);
        if (option.id === undefined) option.id = 'everviz-plotband-' + plotBandIndex;

        option.yAxisType = axisType;
        option.handles = [];
        var handleCount = [];
        if (isBand) handleCount = [0, 1];
        else handleCount = [0];

        handleCount.forEach(function (index) {
          option.handles[index] = createHandle(chart);
          option.handles[index].evervizParentPlotBand = option;
          chart.evervizPlotBandHandles.push(option.handles[index]);
          // zIndex = 6 for right handle, 7 for left.
          // Can't be 10, because of the tooltip in inverted chart #2908
          option.handles[index]
            .attr({
              zIndex: 7 - index
            })
            .addClass('highcharts-plotbands-handle ' + 'highcharts-plotbands-handle-' + ['left', 'right'][index])
            .add(chart.evervizPlotBands.group);
          option.handles[index]
            .attr({
              fill: '#f2f2f2',
              stroke: '#999999',
              'stroke-width': 1
            })
            .css({
              cursor: getCursorType(chart.inverted, axisType) //'col-resize'
            });

          if (!index) updateHandle(chart, option, plotBandIndex, 'left', index, isBand, 0, axis, axisType);
          else updateHandle(chart, option, plotBandIndex, 'right', index, isBand, 3, axis, axisType);
        });

        option.svgElem.element.addEventListener('click', function () {
          //Update config in editor now
          if (window['EvervizWizard']) {
            window['EvervizWizard'].selectPlotBand(chart.draggingPlotBand, axis, isBand, option);
          } else if (window['Everviz'] && window['Everviz'].evervizEditor) {
            window['Everviz'].evervizEditor.selectPlotBand(chart.draggingPlotBand, axis, isBand, option);
          }
        });

        if (isBand) {
          option.svgElem.element.onmousedown = function (e) {
            var normalizedEvent = chart.pointer.normalize(e);
            chart.draggingPlotBand = {
              option: option,
              origFrom: option.options.from,
              origTo: option.options.to,
              origDiff: Math.abs(option.options.to - option.options.from),
              offsetX: Math.abs(option.options.from - chart[axis][0].toValue(normalizedEvent.chartX)),
              offsetY: Math.abs(option.options.from - chart[axis][0].toValue(normalizedEvent.chartY)),
              index: plotBandIndex,
              axisType: axis,
              yAxisType: axisType,
              isBand: isBand
            };
            removeChartInteractivity(chart);
            if (option.label) option.label.hide();
          };

          option.svgElem.element.onmouseup = function (e) {
            //Now update config props
            if (chart.draggingPlotBand) {
              if (chart.draggingPlotBand.hasOwnProperty('newFrom')) {
                chart.draggingPlotBand.option.options.from = chart.draggingPlotBand.newFrom;
                chart.draggingPlotBand.option.options.to = chart.draggingPlotBand.newTo;
                chart.draggingPlotBand.option.render();
                //Update config in editor now
                if (window['EvervizWizard']) {
                  window['EvervizWizard'].updatePlotBands(
                    chart.draggingPlotBand,
                    {
                      from: chart.draggingPlotBand.newFrom,
                      to: chart.draggingPlotBand.newTo
                    },
                    isBand
                  );
                } else if (window['Everviz'] && window['Everviz'].evervizEditor) {
                  window['Everviz'].evervizEditor.updatePlotBands(
                    chart.draggingPlotBand,
                    {
                      from: chart.draggingPlotBand.newFrom,
                      to: chart.draggingPlotBand.newTo
                    },
                    isBand
                  );
                }
              }
              if (chart.draggingPlotBand.option.label) chart.draggingPlotBand.option.label.show();
              chart.draggingPlotBand = false;
            }
          };
        }
      });
    });
    chart.container.addEventListener('mousemove', function (e) {
      if (chart.resizePlotBand) {
        let normalizedEvent = chart.pointer.normalize(e),
          extremes = {
            left: chart.plotLeft,
            right: chart.plotLeft + chart.plotWidth
          };
        const normalEvent = chart.pointer.normalize(e);
        // Move line
        if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
          var props = getResizingProps(chart, normalEvent);
          if (chart.resizePlotBand.isBand) {
            chart.resizePlotBand.newFrom = props.newFrom;
            chart.resizePlotBand.newTo = props.newTo;
          } else chart.resizePlotBand.newFrom = props.newFrom;

          if (chart.resizePlotBand.yAxisType) {
            if (chart.inverted) translateXHandle(chart.resizePlotBand.handle, normalEvent.chartX);
            else translateYHandle(chart.resizePlotBand.handle, normalEvent.chartY);
          } else {
            if (chart.inverted) translateYHandle(chart.resizePlotBand.handle, normalEvent.chartY);
            else translateXHandle(chart.resizePlotBand.handle, normalEvent.chartX);
          }
          props.position = props.position
            .map(function (pos) {
              return pos.join(' ');
            })
            .join(' ');
          chart.resizePlotBand.option.svgElem.attr({
            d: props.position
          });
        }
      } else if (chart.draggingPlotBand) {
        const normalEvent = chart.pointer.normalize(e),
          extremes = {
            left: chart.plotLeft,
            right: chart.plotLeft + chart.plotWidth
          };

        if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
          if (chart.draggingPlotBand.yAxisType) {
            if (chart.inverted) getDraggedProps(chart, normalEvent, 'X', 1, 0);
            else getDraggedProps(chart, normalEvent, 'Y', 2, 0);
          } else {
            if (chart.inverted) getDraggedProps(chart, normalEvent, 'Y', 2, 0);
            else getDraggedProps(chart, normalEvent, 'X', 1, 0);
          }
        }
      }
    });
    chart.container.addEventListener('mouseup', function (e) {
      if (chart.resizePlotBand) {
        if (chart.resizePlotBand.isBand) {
          chart.resizePlotBand.option.options.from = chart.resizePlotBand.newFrom;
          chart.resizePlotBand.option.options.to = chart.resizePlotBand.newTo;
        } else chart.resizePlotBand.option.options.value = chart.resizePlotBand.newFrom;

        chart.resizePlotBand.option.render();

        if (window['EvervizWizard']) {
          window['EvervizWizard'].updatePlotBands(
            chart.resizePlotBand,
            {
              from: chart.resizePlotBand.newFrom,
              to: chart.resizePlotBand.newTo
            },
            chart.resizePlotBand.isBand
          );
        } else if (window['Everviz'] && window['Everviz'].evervizEditor) {
          window['Everviz'].evervizEditor.updatePlotBands(
            chart.resizePlotBand,
            {
              from: chart.resizePlotBand.newFrom,
              to: chart.resizePlotBand.newTo
            },
            chart.resizePlotBand.isBand
          );
        }

        chart.resizePlotBand = false;
      } else if (chart.draggingPlotBand) {
        chart.draggingPlotBand.option.options.from = chart.draggingPlotBand.newFrom;
        chart.draggingPlotBand.option.options.to = chart.draggingPlotBand.newTo;
        chart.draggingPlotBand.option.render();

        if (window['EvervizWizard']) {
          window['EvervizWizard'].updatePlotBands(
            chart.draggingPlotBand,
            {
              from: chart.draggingPlotBand.newFrom,
              to: chart.draggingPlotBand.newTo
            },
            chart.draggingPlotBand.isBand
          );
        } else if (window['Everviz'] && window['Everviz'].evervizEditor) {
          window['Everviz'].evervizEditor.updatePlotBands(
            chart.draggingPlotBand,
            {
              from: chart.draggingPlotBand.newFrom,
              to: chart.draggingPlotBand.newTo
            },
            chart.draggingPlotBand.isBand
          );
        }

        //Update config in editor now
        chart.draggingPlotBand = false;
      }
    });
    loadedAsync = true;
  }

  function checkDataAndLoad(chart) {
    if (chart && chart.data) {
      load(chart);
    } else if (!loadedAsync) {
      setTimeout(function () {
        checkDataAndLoad(chart);
      }, 1000);
    }
  }

  function loadEvent(chart) {
    if (chart.polar) return;
    if (window.location.pathname.indexOf('/share/') > -1) return;
    if (window.location.origin === 'https://app.everviz.com' && window.location.pathname === '/') return;
    if (window.location.origin === 'https://www.everviz.com') return;

    isAsyncChart =
      chart.options &&
      chart.options.data &&
      (chart.options.data.googleSpreadsheetWorksheet || chart.options.data.googleSpreadsheetRange);
    loadedAsync = false;

    chart.evervizPlotBands = {};
    chart.evervizPlotBandHandles = [];
    chart.evervizPlotBands.group = chart.renderer
      .g('plotband-handles-group')
      .attr({
        zIndex: 7
      })
      .add();

    if (isAsyncChart) {
      if (!chart.data) {
        loadedAsync = false;
        checkDataAndLoad(chart);
      }
      return;
    }

    load(chart);
  }

  Highcharts.addEvent(Highcharts.Chart, 'load', function (e) {
    loadEvent(this);
  });

  Highcharts.addEvent(Highcharts.Chart, 'render', function (e) {
    if (window.location.pathname.indexOf('/share/') > -1) return;
    if (window.location.origin === 'https://app.everviz.com' && window.location.pathname === '/') return;
    if (window.location.origin === 'https://www.everviz.com') return;
    const chart = this;
    if (this.polar) return;
    const allSeriesInvisible = (this.series || []).every((series) => !series.visible) || chart.currentResponsive;
    if (allSeriesInvisible) {
      // Hide all plothandles if all the series are invisible
      (this.evervizPlotBandHandles || []).forEach(function (handle) {
        handle.hide();
      });
      this.evervizPreviousHandlesInvisible = true;
    } else if (!allSeriesInvisible && this.evervizPreviousHandlesInvisible) {
      // Put the handles back again if series are visible again.
      loadEvent(chart);
      this.evervizPreviousHandlesInvisible = false;
    } else {
      (this.evervizPlotBandHandles || []).forEach(function (handle) {
        updateHandlePosition(handle, chart);
      });
    }
  });
})(Highcharts);
