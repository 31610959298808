import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

export const addSecondAxis = (aggregatedOptions: any) => {
  const aggregated = cloneDeep(aggregatedOptions);
  aggregated.series[0].yAxis = 1;
  if (isArray(aggregated.yAxis)) {
    aggregated.yAxis = [...aggregated.yAxis, { opposite: true, index: 1 }];
  } else if (isPlainObject(aggregated.yAxis)) {
    const existingYAxis = aggregated.yAxis;
    existingYAxis.index = existingYAxis.index || 0;
    aggregated.yAxis = [existingYAxis, { opposite: true, index: 1 }];
  } else {
    aggregated.yAxis = [{ index: 0 }, { opposite: true, index: 1 }];
  }
  return aggregated;
};
