import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import Modal from 'shared/modal/Modal';
import { createDivisionAction, setTeamMembersAction } from '../actions/teamMembersAction';
import InputWidget from 'shared/widgets/InputWidget';
import ErrorMessage from 'shared/message/ErrorMessage';
import CheckboxInput from 'shared/inputs/CheckboxInput';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';

const CreateSubteamModal = () => {
  const { team, divisions, user } = useSelector((state: RootState) => state.profile);
  const { allMembers, isSubteamCreateModalOpen } = useSelector((state: RootState) => state.teamMembersPage);
  const dispatch = useDispatch();

  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [subteamName, setSubteamName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubteamNameChange = (value: any) => {
    setSubteamName(value.val);
  };

  const handleErrorMessageReset = () => {
    setErrorMessage('');
  };

  const handleSubteamNameReset = () => {
    setSubteamName('');
  };

  const handleOnConfirm = () => {
    if (!subteamName) {
      setErrorMessage('A subteam name is mandatory.');
    } else {
      dispatch(
        createDivisionAction({
          divisions,
          subteamName,
          teamId: team?.id,
          selectedMembers,
          callback: handleSubteamNameReset
        })
      );
      handleErrorMessageReset();
      setSelectedMembers([]);
    }
  };

  const handleOnClose = () => {
    dispatch(setTeamMembersAction({ isSubteamCreateModalOpen: false }));
    handleErrorMessageReset();
    handleSubteamNameReset();
    setSelectedMembers([]);
  };

  const onMemberSelect = (event: React.ChangeEvent, data: any) => {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      setSelectedMembers((prev: any) => [...prev, data]);
    } else {
      setSelectedMembers((prev: any) => prev.filter((selectedMember: any) => selectedMember.id !== data.id));
    }
  };

  const formattedMembers = allMembers.data.filter((member: any) => member.id !== team.owner_user);

  return (
    <Modal
      title="Create a new subteam"
      width="w-7/12 max-w-xl"
      bg="bg-ev-yellow-1"
      isOpen={isSubteamCreateModalOpen}
      onClose={handleOnClose}
    >
      <ErrorMessage error={errorMessage} />
      <InputWidget value={subteamName} onChange={handleSubteamNameChange} placeholder="Name" label="Name:" />
      {formattedMembers.length > 0 && <p className="my-6">Select members to add them to your subteam.</p>}
      <div className="overflow-y-auto mt-1 max-h-[296px] w-full flex flex-col gap-y-1">
        {formattedMembers.map((member: any) => {
          const isCurrentUser = member.id === user.id;
          return (
            <div key={member.id} className="h-14 w-full p-4 flex items-center rounded bg-white">
              <CheckboxInput
                onChange={(event) => onMemberSelect(event, member)}
                checked={isCurrentUser ? isCurrentUser : undefined}
                disabled={isCurrentUser}
              />
              <span className="ml-2 text-base">{member.username}</span>
            </div>
          );
        })}
      </div>
      <hr />
      <div className="pt-6 flex gap-4 justify-between">
        <div className="flex gap-x-4">
          <PrimaryButton onClick={handleOnConfirm} text="Confirm" />
          <PrimaryButton onClick={handleOnClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
      </div>
    </Modal>
  );
};

export default CreateSubteamModal;
