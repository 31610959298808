import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const StackedArea: TemplateProps = {
  title: 'Stacked area',
  description: '',
  constructor: '',
  thumbnail: 'inebav.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal'
      }
    }
  },
  sampleSet: 'area',
  advancedParent: 'Stacked area',
  dataValidator: false,
  basic: {
    order: 14,
    thumbnail: 'stacked_area_graph.svg',
    icon: 'StackedArea'
  },
  parent: 'Stacked area',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter)
};

export default StackedArea;
