import { cloneDeep, get } from 'lodash';
import { OptionProps } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import * as React from 'react';
import Accordion, { AccordionProps } from 'shared/Accordion';
import { customSelectColors } from 'shared/inputs/CustomSelect';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import useToggle from 'shared/utils/useToggle';
import BuildInputs, { keepWhiteBgMap } from './BuildInputs';

const OpenContainer = (props: AccordionProps) => {
  return <div>{props.children}</div>;
};

interface AccordionWidgetProps extends GenericInputProps {
  accordionClasses?: string;
  open?: boolean;
  children?: React.ReactNode;
}

const AccordionWidget = (props: AccordionWidgetProps) => {
  const { option, open } = props;
  const [isActive, toggleIsActive] = useToggle(option?.custom?.defaultSelection ?? false);
  const styledOptions = option?.options?.map((field) => ({
    ...field,
    className: option?.inputClassName ?? (field?.type ?? '') in keepWhiteBgMap ? null : 'bg-ev-grey',
    inputClassName: (field?.type ?? '') in keepWhiteBgMap ? 'bg-ev-grey' : '',
    backgroundColor: field?.backgroundColor ?? customSelectColors.grey
  }));
  const section = { ...option, options: styledOptions };
  const Container = open ? OpenContainer : Accordion;

  // Used for unit chart to display number of icons used
  const getSubheader = (option: OptionProps | undefined) => {
    if (option?.isBasedOnSeriesData) {
      const { aggregatedOptions } = props;
      if (aggregatedOptions.series[0].data) {
        let val = aggregatedOptions.series[0].data;
        val = (val || []).map((d: any) => {
          return get(d, option?.id ?? '');
        });
        return (val ?? []).length;
      }
    }
    return '';
  };

  const subheader = getSubheader(option);

  section.options = section.options?.map((option) => {
    option.id = option.id?.replace('[n]', `[${props.index}]`);

    if (option.postFields) {
      option.postFields = option.postFields.map((post) => {
        const postOption = cloneDeep(post);
        postOption.options.map((option) => {
          option.id = option.id?.replace('[n]', `[${props.index}]`);
          return option;
        });
        return postOption;
      });
    }

    return option;
  });

  return (
    <Container
      headerText={props.label}
      smallHeaderText={true}
      selected={isActive}
      onClick={() => toggleIsActive()}
      childrenClasses="rounded mx-4 py-2 relative flex-col gap-2 mb-2.5"
      accordionClasses={`${props.accordionClasses || props.className || 'bg-white'}`}
      selectedChildren={subheader}
    >
      <BuildInputs
        section={section}
        parentPath={props.parentPath}
        index={props.index}
        isArraySelector={props.isArraySelector}
      />
      {props.children}
    </Container>
  );
};

export default AccordionWidget;
