import { connect } from 'react-redux';
import Customize from '../../../shared/editor/generic/Customize';
import {
  updateCustomizedPropAction,
  updateDataPropAction,
  toggleChartModalAction,
  getFontsAction,
  getLogosAction
} from '../../LayoutEditorPage/actions/layoutEditor.js';
import { checkPermission } from '../../../utils/profileHelper';
import {
  loadPaginatedAction,
  changeColumnOrderAction,
  processConditionalFormattingAction,
  addChartToTableAction,
  updateColumnHiddenValueAction
} from '../actions/tableEditor.js';
import en from '../meta/en.js';

const mapStateToProps = (state) => {
  return {
    checkPermission: (permission) => checkPermission(permission, state.profile),
    customizedOptions: state.projectConfig.customizedOptions,
    dataOptions: state.projectConfig.dataOptions,
    templateUsed: state.layoutEditorPage.templateUsed,
    customFonts: state.layoutEditorPage.customFonts,
    team: state.profile.team,
    division: state.profile.division,
    logos: state.layoutEditorPage.logos,
    isGSheet: state.tableEditorPage.isGSheet,
    paths: state.app.paths || {},
    lang: en
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCustomizedProp: (optionProps, val, extraModulesToLoad, index) => {
    return dispatch(updateCustomizedPropAction({ optionProps, val, extraModulesToLoad, index }));
  },
  loadPaginated: (parent, val) => dispatch(loadPaginatedAction({ parent, val })),
  updateDataProp: (dataOptions, option, index, val) =>
    dispatch(updateDataPropAction({ dataOptions, option, index, val })),
  toggleChartModal: (parent) => dispatch(toggleChartModalAction(parent)),
  getFonts: (team) => dispatch(getFontsAction({ team })),
  getLogos: (team) => dispatch(getLogosAction({ team })),
  changeColumnOrder: (from, to, updateData) => dispatch(changeColumnOrderAction({ from, to, updateData })),
  processConditionalFormatting: (index, rule, value, color) =>
    dispatch(processConditionalFormattingAction({ index, rule, value, color })),
  addChartToTable: (index) => dispatch(addChartToTableAction({ index })),
  updateColumnHiddenValue: (columns, value, index) => dispatch(updateColumnHiddenValueAction({ columns, value, index }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Customize);
