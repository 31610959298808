import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import ErrorMessage from '../../../shared/message/ErrorMessage';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import InputWidget from 'shared/widgets/InputWidget';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { Role } from 'pages/RolesPage/types/rolesModels';
import CustomSelect from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import { customSelectColors } from 'shared/inputs/CustomSelect';

interface Group {
  id: string;
  name: string;
}

interface Subscription {
  pay_per_user?: number;
}

interface TeamInvitationsFormProps {
  value: string;
  onSave: (event: React.MouseEvent<HTMLButtonElement>, teamGroupId?: string | null) => void;
  onChange: any;
  onClose: () => void;
  groups?: Group[];
  groupId?: string;
  inviteError?: string;
  unitPrice?: number;
  priceTotal?: number;
  subscription?: Subscription;
  teamName?: string;
  updateActiveGroup?: ({ val }: { val: string }) => void;
}

export const TeamInvitationsForm: React.FC<TeamInvitationsFormProps> = ({
  value,
  onSave,
  onChange,
  onClose,
  inviteError,
  unitPrice = 0,
  priceTotal = 0,
  subscription,
  teamName
}) => {
  const { groups } = useSelector((state: RootState) => state.profile);
  const [activeRole, setActiveRole] = useState<Role>(
    groups && groups.length > 0 ? { id: groups[0].id, name: groups[0].name } : { id: 1, name: '' }
  );

  const handleRoleChange = (selected: { value: string; label: string } | null) => {
    if (selected) {
      const selectedRole: Role = {
        id: Number(selected.value),
        name: selected.label
      };
      setActiveRole(selectedRole);
    }
  };

  return (
    <div>
      <ErrorMessage error={inviteError} />
      <div>
        {subscription && subscription.pay_per_user === 1 && (
          <>
            <div className="alert alert-danger">
              On removing members, please consider your credit will be processed on the next billing cycle and your
              account will be credited. If you have any questions please get in touch with us via sales@everviz.com
            </div>
            <div>
              <b>Current Price:</b> ${priceTotal}
            </div>
            <div>
              <b>New Price after invites:</b> ${priceTotal + unitPrice * (value.match(/@/g) || []).length}
            </div>
            <br />
          </>
        )}
        <p className="mb-4">
          This will invite new member(s) to: <b>{teamName}</b>.
        </p>
      </div>

      <InputWidget
        placeholder="email_1@example.com, email_2@example.com"
        value={value}
        onChange={onChange}
        label="Separated by ','"
        className="h-full w-full"
      />

      {activeRole && groups?.length > 0 && (
        <div className="mt-3">
          <p className="text-ev-navy-blue-4 text-sm font-bold mb-2">Choose a role:</p>
          <CustomSelect
            options={groups.map((g: Role) => ({ value: g.id, label: g.name }))}
            value={{ label: activeRole.name, value: String(activeRole.id) }}
            onChange={handleRoleChange}
            styles={editorStyles}
            backgroundColor={customSelectColors.white}
          />
        </div>
      )}
      <hr />
      <div className="mt-8 flex gap-4">
        <PrimaryButton onClick={(e) => onSave(e, activeRole.id.toString())} text="Confirm" />
        <PrimaryButton onClick={onClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
      </div>
    </div>
  );
};

export default TeamInvitationsForm;
