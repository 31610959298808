import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocationMapCustomizedAction } from '../../../../pages/ChartEditorPage/actions/locationMap';
import { ProjectConfigLocationMapProps } from '../../../../pages/Editor/reducers/locationMapConfigTypes';
import { getProjectConfig } from '../../../../redux/selectors/projectConfig';
import CheckboxWidget from '../../../widgets/CheckboxWidget';
import { SectionNode } from '../SectionNode';

export type InteractivitySectionProps = {
  isCompact?: boolean;
  showContainer?: boolean;
  openedByDefault?: boolean;
};

export const InteractivitySection = ({ isCompact, showContainer, openedByDefault }: InteractivitySectionProps) => {
  const { aggregatedOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);
  const dispatch = useDispatch();

  const setAction = (path: string, value: boolean) => {
    dispatch(
      updateLocationMapCustomizedAction({
        optionProps: path,
        val: value
      })
    );
  };

  return (
    <SectionNode
      showContainer={showContainer}
      sectionKey="Interactivity"
      headerText="Interactivity"
      openedByDefault={openedByDefault}
    >
      <CheckboxWidget
        value={aggregatedOptions.fullScreenControls.enabled}
        onChange={(e) => setAction('fullScreenControls.enabled', e.val as boolean)}
        label={'Fullscreen Control'}
      />
      <CheckboxWidget
        value={aggregatedOptions.scaleControls.enabled}
        onChange={(e) => setAction('scaleControls.enabled', e.val as boolean)}
        label={'Scale control'}
      />
      {!isCompact && (
        <>
          <CheckboxWidget
            value={aggregatedOptions.mapNavigation.enabled}
            onChange={(e) => setAction('mapNavigation.enabled', e.val as boolean)}
            label={'Map Navigation'}
          />
          <CheckboxWidget
            value={aggregatedOptions.geolocateControls.enabled}
            onChange={(e) => setAction('geolocateControls.enabled', e.val as boolean)}
            label={'Geo Location Control'}
          />
        </>
      )}
    </SectionNode>
  );
};
