/* eslint-disable no-use-before-define */
import { cloneDeep, isArray, isObject } from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getAttr, merge, setAttr, uuid } from '../../../editor/core/highcharts-editor';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import {
  setAction as setChartAction,
  setCustomizedOptionsAction,
  updateCustomizedAction
} from '../actions/chartEditor';
import { getChartConfig } from '../selectors/chartEditor';
import { updateAggregated } from './ChartEditor';
import {
  deleteAllProps,
  getHighlightValues,
  getPlotBandDefault,
  setPlotBandDefault,
  setPlotLineDefault,
  updateAnnotation
} from '../utils/chartEditorAnnotationsHelper';
import { getChart } from '../meta/highchartsHelper';

function* getAnnotations() {
  let config = yield select(getProjectConfig);
  let { editorChartIndex } = yield select(getChartConfig);
  const chart = getChart(editorChartIndex);
  let navigation = chart.navigationBindings;

  if (!navigation) return;

  let customizedOptions = merge({}, config.customizedOptions);

  let navChart = navigation.chart;

  customizedOptions.annotations = [];

  navChart.annotations.forEach(function (annotation) {
    if (
      annotation.userOptions &&
      (annotation.userOptions.type === 'crookedLine' || annotation.userOptions.type === 'elliottWave')
    ) {
      annotation.userOptions.typeOptions.line = merge({}, annotation.shapes[0].options);
    }

    if (annotation.labels && annotation.labels[0]) {
      const newAnnotation = merge(cloneDeep(annotation.userOptions), {
        x: annotation.labels[0].points[0].x,
        y: annotation.labels[0].points[0].y
      });

      merge(newAnnotation.labels[0].point, {
        x: annotation.labels[0].points[0].x,
        y: annotation.labels[0].points[0].y
      });

      newAnnotation.id = annotation.userOptions.id ?? uuid();
      customizedOptions.annotations.push(newAnnotation);
    } else if (annotation.shapes && annotation.shapes[0]) {
      const newAnnotation = merge(merge({}, annotation.userOptions), {
        x: annotation.shapes[0].points[0].x,
        y: annotation.shapes[0].points[0].y
      });

      newAnnotation.id = annotation.userOptions.id ?? uuid();
      customizedOptions.annotations.push(newAnnotation);
    }
  });

  // setTimeout(function() {
  customizedOptions.annotations.forEach(function (annotation) {
    if (annotation.labels && annotation.labels[0]) {
      merge(annotation.labels[0].point, {
        x: annotation.x,
        y: annotation.y
      });
    }
  });

  yield put(
    setProjectConfigAction({
      customizedOptions
    })
  );

  // }, 100);
}

export function* addAnnotation(params) {
  let { type, index, annotation } = params.data;

  const dimmer = document.querySelector('.highed-dimmer');
  if (dimmer) dimmer.click();

  let config = yield select(getProjectConfig);
  let customizedOptions = merge({}, config.customizedOptions);
  let plugins = merge({}, config.plugins);

  plugins.annotations = 1;

  // See if this annotation has already been added before
  // If so, we dont want to call the onChangeActiveAnnotation function below
  const id = annotation.userOptions.id;
  let isNew = (customizedOptions.annotations || []).every((d) => d.id !== id);

  yield call(getAnnotations);

  if (type === 'shape') {
    type = 'line';
  }

  if (!isNew) return;

  yield call(onChangeActiveAnnotation, {
    data: {
      selected: {
        val: index
      },
      type: `${type}s`
    }
  });

  yield put(
    setProjectConfigAction({
      plugins
    })
  );
  // customizePage.annotationAdded(type, index, annotation);
}

export function* onChangeActiveAnnotation(params) {
  let { selected, type } = params.data;

  let { editorChartIndex } = yield select(getChartConfig);
  const chart = getChart(editorChartIndex);

  let options = {
    annotationSection: {
      labels: false,
      highlights: false,
      lines: false
    }
  };

  if (selected) {
    options.annotationSection[type] = {
      ...selected,
      label: selected.label,
      value: selected.val
    };
  }

  if (type !== 'highlights' && selected) {
    chart.annotations.forEach((annotation) => annotation.setControlPointsVisibility(false));
    const currentAnnotation = chart.annotations[selected.val];

    chart.navigationBindings.activeAnnotation = currentAnnotation;
    currentAnnotation.setControlPointsVisibility(true);
    chart.currentAnnotation = currentAnnotation;
  }

  yield put(setChartAction(options));
}

export function* updateLabelAnnotation(params) {
  let { optionProps, val, index } = params.data;

  let chartConfig = yield select(getChartConfig);

  if (optionProps.annotations?.isText) {
    if (val.replace('\n', '<br/>') !== '') {
      val = val.replace('\n', '<br/>');
    }
  }

  let obj = {};
  obj.labels = [{}];
  setAttr(obj.labels[0], optionProps.id, val);

  let markerColor = optionProps.backgroundColor;

  if (optionProps?.annotations?.fixMarkers) {
    if (val) setAttr(obj.labels[0], 'borderColor', val);
    markerColor = val;
    optionProps.backgroundColor = val;
  }

  const chart = getChart(chartConfig.editorChartIndex);
  const uniqueId = Highcharts.uniqueKey();

  setAttr(obj.labels[0], 'uniqueId', uniqueId);
  Highcharts.evervizMarkerTypes.forEach((markerType) => {
    const predefinedOption = merge(merge({}, Highcharts.evervizMarkerDefaultSettings), {
      id: markerType.id,
      children: [
        {
          tagName: 'path',
          d: markerType.path
        }
      ]
    });

    chart.renderer.addMarker(
      uniqueId + '-' + markerType.id,
      merge(predefinedOption, {
        color: markerColor || 'black'
      })
    );
  });

  const currentAnnotation = chart.annotations[index || 0];

  // chartPreview.annotations.updateAnnotation(currentAnnotation, obj, 'label');

  updateAnnotation(currentAnnotation, obj, 'label');
  yield call(getAnnotations);
}

export function* updateLineAnnotation(params) {
  let { optionProps, val, index } = params.data;

  let { editorChartIndex } = yield select(getChartConfig);
  const chart = getChart(editorChartIndex);

  let obj = {};
  setAttr(obj, optionProps.id, val);
  const currentAnnotation = chart.annotations[index || 0];
  updateAnnotation(currentAnnotation, obj, 'line');
  yield call(getAnnotations);
}

export function* updateHighlightAnnotation(params) {
  let { optionProps, val, index } = params.data;

  let config = yield select(getProjectConfig);
  let { editorChartIndex } = yield select(getChartConfig);
  const chart = getChart(editorChartIndex);

  let customizedOptions = merge({}, config.customizedOptions);

  let vals = getHighlightValues(chart);

  const axis = vals[index].axis.coll;
  const type = 'value' in vals[index].options ? 'plotLines' : 'plotBands';
  const rawAxis = getAttr(customizedOptions, axis);
  const axisAttr = isArray(rawAxis) ? rawAxis[0] : rawAxis;
  const plotIndex = axisAttr[type].findIndex((val) => val.id === vals[index].options.id);

  // if (optionProps.isText) selectOptions[index].innerHTML = val;

  if (optionProps.annotations?.isOrientation) {
    // Change the axis type to the opposite axis: x->y, y->x
    const plotIndex = axisAttr[type].findIndex((val) => val.id === vals[index].options.id);
    const deletedElement = axisAttr[type].splice(plotIndex, 1)[0];
    const newAxisVal = val === 'horizontal' ? 'yAxis' : 'xAxis';
    let emit = false;
    let rawOppositeAxisAttr = getAttr(customizedOptions, newAxisVal);
    let oppositeAxisAttr;
    let rawOppositeChartAxis = newAxisVal === 'yAxis' ? chart.yAxis : chart.xAxis;
    let oppositeChartAxis = isArray(rawOppositeChartAxis) ? rawOppositeChartAxis[0] : rawOppositeChartAxis;

    deleteAllProps(deletedElement);
    type === 'plotBands'
      ? setPlotBandDefault(deletedElement, oppositeChartAxis)
      : setPlotLineDefault(deletedElement, oppositeChartAxis);

    if (!rawOppositeAxisAttr) {
      // Doesnt have an opposite axis, create one
      oppositeAxisAttr = {};
      emit = true;
    } else {
      oppositeAxisAttr = isArray(rawOppositeAxisAttr) ? rawOppositeAxisAttr[0] : rawOppositeAxisAttr;
    }

    if (!oppositeAxisAttr[type]) oppositeAxisAttr[type] = [];
    oppositeAxisAttr[type].push(deletedElement);

    if (emit) {
      customizedOptions[newAxisVal] = oppositeAxisAttr;
    }
    yield put(
      setCustomizedOptionsAction({
        customizedOptions
      })
    );

    return;
  } else if (optionProps.annotations?.isType) {
    // Remove the plotband object and add a plotline object instead
    const newAxisVal = val === 'line' ? 'plotLines' : 'plotBands';
    const deletedElement = axisAttr[type].splice(plotIndex, 1)[0];
    if (!axisAttr[newAxisVal]) axisAttr[newAxisVal] = [];
    deleteAllProps(deletedElement);

    if (val === 'line') setPlotLineDefault(deletedElement, vals[index].axis);
    else setPlotBandDefault(deletedElement, vals[index].axis);

    axisAttr[newAxisVal].push(deletedElement);
  } else {
    setAttr(axisAttr[type][plotIndex], optionProps.id, val);
  }

  yield put(
    setProjectConfigAction({
      customizedOptions
    })
  );

  yield call(updateAggregated, false);
}

export function* removeAnnotation(params) {
  let { type, index } = params.data;

  let { editorChartIndex } = yield select(getChartConfig);
  const chart = getChart(editorChartIndex);

  if (!chart.currentAnnotation) return;

  let navigation = chart.navigationBindings;
  navigation.activeAnnotation = false;
  navigation.chart.removeAnnotation(chart.currentAnnotation);

  yield call(getAnnotations);

  chart.currentAnnotation = null;
  let popupCloseBtn = document.querySelector('.highcharts-popup-close');
  if (popupCloseBtn) {
    popupCloseBtn.click();
  }

  if (index) {
    if (type === 'shape') {
      type = 'line';
    }
    yield call(onChangeActiveAnnotation, {
      data: {
        selected: {
          val: index - 1
        },
        type: `${type}s`
      }
    });
  } else {
    // Last option:
    yield call(onChangeActiveAnnotation, {
      data: {}
    });
  }
}

export function* removeHighlight(params) {
  let { index } = params.data;

  if (index < 0) return;

  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you would like to delete this?')) {
    let config = yield select(getProjectConfig);
    let customizedOptions = merge({}, config.customizedOptions);

    let { editorChartIndex } = yield select(getChartConfig);
    const chart = getChart(editorChartIndex);

    let vals = getHighlightValues(chart);
    let option = vals[index];

    const axisType = option.axis.coll;
    const type = 'value' in option.options ? 'plotLines' : 'plotBands';
    const axisAttr = getAttr(customizedOptions, axisType);
    const axis = isArray(axisAttr) ? axisAttr[0] : axisAttr;
    const axisIndex = axis[type].findIndex((band) => band.id === option.options.id);

    axis[type].splice(axisIndex, 1);

    yield put(
      setProjectConfigAction({
        customizedOptions
      })
    );

    yield call(updateAggregated, false);
    let newIndex = vals.length - 2;
    let selected;

    if (newIndex > 1) {
      selected = {
        val: newIndex
      };
    }

    yield call(onChangeActiveAnnotation, {
      data: {
        type: 'highlights',
        selected
      }
    });
  }
}

export function* addHighlight() {
  try {
    let config = yield select(getProjectConfig);
    let { editorChartIndex } = yield select(getChartConfig);
    const chart = getChart(editorChartIndex);

    let customizedOptions = config.customizedOptions;

    let vals = getHighlightValues(chart);

    const addPlotBand = (axis) => {
      const props = getPlotBandDefault(axis);
      const customizedXAxis = customizedOptions.xAxis;
      let plotBands = [];
      if (customizedXAxis) {
        plotBands =
          cloneDeep(isArray(customizedXAxis) ? customizedXAxis[0].plotBands : customizedXAxis.plotBands) || [];
      }

      plotBands.push({
        from: props.min,
        to: props.min + props.tenPercent,
        id: vals.length
      });
      return plotBands;
    };
    let plotBands = [];

    if (chart.xAxis && isArray(chart.xAxis)) plotBands = addPlotBand(chart.xAxis[0]);
    else if (chart.xAxis && isObject(chart.xAxis)) plotBands = addPlotBand(chart.xAxis);

    yield put(
      updateCustomizedAction({
        optionProps: { id: 'xAxis[0].plotBands', isOptionalArray: true },
        val: plotBands
      })
    );

    yield call(onChangeActiveAnnotation, {
      data: {
        selected: {
          val: plotBands.length - 1
        },
        type: 'highlights'
      }
    });
  } catch (error) {
    throw new Error(error);
  }
}

export function* updateAnnotations() {
  yield call(getAnnotations);
}

export function* showAnnotationInMenu(params) {
  try {
    let { type, index, annotation } = params.data;
    let { editorChartIndex } = yield select(getChartConfig);
    const chart = getChart(editorChartIndex);

    const list = document.querySelector('.list-dropdown.customize-dropdown-option\\.cat\\.annotations > div');
    if (!list?.classList.contains('selected')) list.click();

    if (type === 'shape') type = 'line';

    if (type === 'highlight') {
      let vals = getHighlightValues(chart);
      index = vals.findIndex((val) => val.options.id === annotation.options.id);
    }

    yield call(onChangeActiveAnnotation, {
      data: {
        selected: {
          val: index
        },
        type: `${type}s`
      }
    });
  } catch (error) {
    throw new Error(error);
  }
}

/** Watch functions */
export function* watchAddAnnotation() {
  yield takeEvery(actionTypes.chartEditor.addAnnotation, addAnnotation);
}
export function* watchRemoveAnnotation() {
  yield takeEvery(actionTypes.chartEditor.removeAnnotation, removeAnnotation);
}
export function* watchRemoveHighlight() {
  yield takeEvery(actionTypes.chartEditor.removeHighlight, removeHighlight);
}
export function* watchOnChangeActiveAnnotation() {
  yield takeEvery(actionTypes.chartEditor.onChangeActiveAnnotation, onChangeActiveAnnotation);
}
export function* watchUpdateLabelAnnotation() {
  yield takeEvery(actionTypes.chartEditor.updateLabelAnnotation, updateLabelAnnotation);
}
export function* watchUpdateLineAnnotation() {
  yield takeEvery(actionTypes.chartEditor.updateLineAnnotation, updateLineAnnotation);
}
export function* watchUpdateHighlightAnnotation() {
  yield takeEvery(actionTypes.chartEditor.updateHighlightAnnotation, updateHighlightAnnotation);
}
export function* watchUpdateAnnotations() {
  yield takeEvery(actionTypes.chartEditor.updateAnnotations, updateAnnotations);
}
export function* watchShowAnnotationInMenu() {
  yield takeEvery(actionTypes.chartEditor.showAnnotationInMenu, showAnnotationInMenu);
}
export function* watchAddHighlight() {
  yield takeEvery(actionTypes.chartEditor.addHighlight, addHighlight);
}

export default function* rootSaga() {
  yield all([
    watchAddAnnotation(),
    watchOnChangeActiveAnnotation(),
    watchUpdateLabelAnnotation(),
    watchUpdateLineAnnotation(),
    watchRemoveAnnotation(),
    watchRemoveHighlight(),
    watchUpdateAnnotations(),
    watchShowAnnotationInMenu(),
    watchAddHighlight(),
    watchUpdateHighlightAnnotation()
  ]);
}
