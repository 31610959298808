import Templates from 'pages/ChartEditorPage/meta/templates/Templates';

export const getDataForGoogleErrors = (error: string) => {
  switch (error) {
    case 'nonPublic':
      return {
        link: 'https://help.everviz.com/article/900-google-sheet-settings/',
        text: 'The sheet needs to be publically available.'
      };
    case 'badUrl':
      return {
        link: 'https://help.everviz.com/article/802-google-spreadsheet-import',
        text: 'Please enter a valid spreadsheet URL.'
      };
  }
};

export const shouldDisplayDataServerOptionForType = (type: keyof typeof Templates) => {
  return type === 'Line' || type === 'Bar' || type === 'Column' || type === 'Stock';
};

const fetchSheetData = async (googleSpreadsheetKey: string): Promise<any[]> => {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${googleSpreadsheetKey}?fields=sheets.properties&key=AIzaSyCchblEzIdk4rPKD6sbi72c4OseEdqmyPQ`;
  const response = await fetch(url);
  const data = await response.json();
  return data.sheets || [];
};

export const getSheetGid = async (googleSpreadsheetKey: string, sheetName: string): Promise<number> => {
  try {
    const sheets = await fetchSheetData(googleSpreadsheetKey);
    const sheet = sheets.find((sheet: any) => sheet.properties.title === sheetName);
    return sheet?.properties.sheetId || 0;
  } catch (error) {
    console.error('Error fetching sheet GID:', error);
    return 0;
  }
};

export const getSheetName = async (googleSpreadsheetKey: string, newGid: string): Promise<string> => {
  try {
    const sheets = await fetchSheetData(googleSpreadsheetKey);
    const sheet = sheets.find((sheet: any) => sheet.properties.sheetId.toString() === newGid);
    return sheet?.properties.title || '';
  } catch (error) {
    console.error('Error fetching sheet name:', error);
    return '';
  }
};
