import { ButtonColor, ButtonType } from '../types/ButtonModels';

export type LoaderProps = Record<string, { text: string | JSX.Element; icon?: string; check?: boolean }>;

export const getClassesForButtonColor = (color: ButtonColor): string => {
  switch (color) {
    case ButtonColor.DangerRed:
      return 'bg-ev-danger-red border-ev-danger-red text-white hover:bg-ev-red-1 hover:border-ev-red-1 active:bg-ev-dark-red active:border-ev-dark-red focus:shadow-dark';
    case ButtonColor.Red:
      return 'bg-ev-red border-ev-red text-white hover:bg-ev-red-1 hover:border-ev-red-1 active:bg-ev-dark-red active:border-ev-dark-red focus:shadow-dark';
    case ButtonColor.ErrorRed:
      return 'bg-ev-error border-ev-error text-white hover:bg-ev-red-1 hover:border-ev-red-1 active:bg-ev-dark-red active:border-ev-dark-red focus:shadow-dark';
    case ButtonColor.Yellow:
      return 'bg-ev-yellow-4 border-ev-yellow-4 text-black hover:bg-ev-yellow-3 hover:border-ev-yellow-3';
    case ButtonColor.NavyBlue:
      return 'bg-ev-navy-blue-2 border-ev-navy-blue-2 text-white hover:bg-ev-baby-blue-2 hover:border-ev-baby-blue-2 hover:text-ev-navy-blue-2 focus:shadow-dark active:bg-white active:text-ev-navy-blue-2 active:border-white';
    case ButtonColor.DarkBlue:
      return 'bg-ev-navy-blue border-ev-navy-blue text-white hover:bg-ev-baby-blue-2 hover:border-ev-baby-blue-2 hover:text-ev-navy-blue-2 focus:shadow-dark active:bg-white active:text-ev-navy-blue-2 active:border-white';
    case ButtonColor.DarkBlueBordered:
      return 'bg-white border-ev-navy-blue text-ev-navy-blue hover:bg-ev-baby-blue-2 focus:shadow-light active:bg-ev-navy-blue active:text-ev-baby-blue-2 active:border-ev-navy-blue';
    case ButtonColor.BabyBlue:
      return 'bg-ev-baby-blue border-ev-baby-blue text-ev-navy-blue-2 hover:bg-ev-navy-blue-2 hover:border-ev-navy-blue-2 hover:text-white focus:shadow-light active:bg-white active:text-ev-navy-blue-2 active:border-white';
    case ButtonColor.LightBlue:
      return 'bg-ev-baby-blue-2 border-ev-baby-blue-2 text-ev-navy-blue hover:bg-ev-navy-blue hover:border-ev-navy-blue hover:text-white focus:shadow-light active:bg-white active:text-ev-navy-blue-2 active:border-white';
    case ButtonColor.BabyBlue2:
      return 'bg-ev-baby-blue-2 border-ev-baby-blue-2 text-ev-navy-blue-2';
    case ButtonColor.White:
      return 'bg-white border-white text-ev-navy-blue-2 hover:bg-ev-baby-blue-2 hover:border-ev-baby-blue-2 focus:shadow-light active:bg-ev-navy-blue-2 active:text-white active:border-ev-navy-blue-2';
    case ButtonColor.WhiteBordered:
      return 'bg-white border-ev-navy-blue-2 text-ev-navy-blue-2 hover:bg-ev-baby-blue-2 hover:border-ev-navy-blue-2 hover:text-ev-navy-blue-2 active:bg-ev-navy-blue-2 active:text-ev-baby-blue-2 active:border-ev-navy-blue-2 focus:border-none focus:shadow-light';
    case ButtonColor.Grey:
      return 'bg-ev-grey border-ev-grey text-ev-navy-blue-2 hover:bg-ev-baby-blue-2 hover:border-ev-baby-blue-2 hover:text-ev-navy-blue-2';
    case ButtonColor.GreyBordered:
      return 'bg-ev-grey border-ev-navy-blue-2 text-ev-navy-blue-2 hover:bg-ev-baby-blue-2 focus:shadow-light focus:border-none active:bg-ev-navy-blue-2 active:text-ev-baby-blue-2 active:border-ev-navy-blue-2';
    case ButtonColor.Purple:
      return 'bg-ev-light-purple border-ev-light-purple text-ev-navy-blue-2 hover:bg-ev-baby-blue-2 hover:border-ev-baby-blue-2';
    case ButtonColor.Transparent:
      return 'bg-transparent border-transparent text-ev-navy-blue-2 hover:bg-ev-baby-blue-2 hover:border-ev-baby-blue-2 hover:text-ev-navy-blue-2 focus:shadow-light active:bg-ev-navy-blue-2 active:text-white active:border-ev-navy-blue-2';
    case ButtonColor.TransparentBordered:
      return 'bg-transparent border-ev-navy-blue-2 text-ev-navy-blue-2 hover:bg-ev-baby-blue-2 hover:border-ev-baby-blue-2 hover:text-ev-navy-blue-2 focus:shadow-light active:bg-ev-navy-blue-2 active:text-white active:border-ev-navy-blue-2';
    case ButtonColor.TransparentNavyBlue2:
      return 'bg-transparent border-transparent text-ev-navy-blue-2';
    default:
      return 'border-ev-red bg-ev-red text-white hover:bg-ev-dark-red hover:border-ev-dark-red';
  }
};

export const getClassesForButtonType = (type: ButtonType): string => {
  switch (type) {
    case ButtonType.Action:
      return 'py-2 px-4 border-2 font-bold transition-colors rounded-full whitespace-nowrap';
    case ButtonType.Info:
      return 'h-8 pb-1 px-2 ev-sm:px-4 pt-1 border-2 font-bold transition-colors rounded';
    case ButtonType.Icon:
      return 'icon-button group border-2 font-bold transition-colors text-sm';
    default:
      return 'py-2 px-4 border-2 font-bold transition-colors rounded-full whitespace-nowrap';
  }
};

export const getDisabledButtonClasses = (type: ButtonColor): string => {
  switch (type) {
    case ButtonColor.Disabled:
      return 'disabled:border-ev-grey-2 disabled:bg-ev-grey-2 disabled:text-white disabled:cursor-not-allowed hover:bg-ev-grey-2 active:bg-ev-grey-2';
    case ButtonColor.GreyDisabled:
      return 'disabled:border-ev-grey disabled:bg-ev-grey disabled:text-ev-grey-3 disabled:cursor-not-allowed hover:bg-ev-grey active:bg-ev-grey';
    default:
      return 'disabled:border-ev-grey-2 disabled:bg-ev-grey-2 disabled:text-white disabled:cursor-not-allowed hover:bg-ev-grey-2 active:bg-ev-grey-2';
  }
};

export const getLoaderText = (useLoader: LoaderProps): string | JSX.Element => {
  if (useLoader.loading.check) {
    return useLoader.loading.text;
  }
  if (useLoader.done?.check) return useLoader.done.text;
  return useLoader.default.text;
};
