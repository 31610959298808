(function (Highcharts) {
  if (!Highcharts) return;

  function handleBackgroundImage(Highcharts) {
    Highcharts.addEvent(Highcharts.Chart, 'load', function () {
      const backgroundImage = this?.userOptions?.everviz?.chart?.backgroundImage;
      if (backgroundImage) {
        const image = this.renderer
          .image(backgroundImage, 0, 0, this.containerBox.width, this.containerBox.width)
          .add();
        this.evervizBackgroundImage = image;
      }
    });

    Highcharts.addEvent(Highcharts.Chart, 'render', function () {
      if (this?.evervizBackgroundImage) {
        const container = this.container;
        const size = container.getBoundingClientRect();
        this?.evervizBackgroundImage.attr({
          width: size.width,
          height: size.height
        });
      }
    });
  }

  handleBackgroundImage(Highcharts);
})(window.Highcharts);
