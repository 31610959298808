import { find } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getBillingDetailsAction, setPlansPageAction } from '../actions/plansPage';
import { subscriptionPlans } from '../meta/subscriptionPlans';
import ContactUsModal from './ContactUsModal';
import PlanCheckout from './PlanCheckout';
import PlansTable from './PlansTable';
import SuccessScreen from './SuccessScreen';

type PlansSectionProps = {
  checkout: boolean;
  setPlan: (planCode: number) => void;
  editingPaymentMethod: boolean;
  closeEditing: () => void;
  openCheckout: (planCode: number) => void;
  closeCheckout: () => void;
};

export default function PlansSection(props: PlansSectionProps) {
  const dispatch = useDispatch();
  const { checkout, closeEditing, setPlan, openCheckout, closeCheckout } = props;
  const { team, subscription } = useSelector((state: RootState) => state.profile);
  const { activeSubscription, successScreenText, contactUsModalOpen } = useSelector(
    (state: RootState) => state.plansPage
  );
  const removePlansTable = [2, 3, 4, 5, 6, 7];
  const pendingPlan = find(subscriptionPlans, { id: subscription?.pending_plan });

  const closeSuccessScreen = () => {
    closeCheckout();
    closeEditing();
    dispatch(setPlansPageAction({ successScreenText: '' }));
  };

  const onBillingSave = () => {
    dispatch(getBillingDetailsAction({ team }));
  };

  const toggleContactUsModal = () => {
    dispatch(setPlansPageAction({ contactUsModalOpen: !contactUsModalOpen }));
  };

  return (
    <div id="Plans">
      <ContactUsModal isOpen={contactUsModalOpen} close={toggleContactUsModal} />
      {!checkout && !successScreenText && !removePlansTable.includes(subscription ? subscription.id : 1) && (
        <PlansTable
          pendingPlan={pendingPlan}
          showPlanCheckout={openCheckout}
          toggleContactUsModal={toggleContactUsModal}
        />
      )}
      {checkout && !successScreenText && (
        <PlanCheckout
          plan={activeSubscription || subscription}
          closeCheckout={closeCheckout}
          closeEditing={closeEditing}
          onBillingSave={onBillingSave}
          setPlan={setPlan}
          editingPaymentMethod={props.editingPaymentMethod}
        />
      )}

      {!checkout && successScreenText && (
        <SuccessScreen onClose={closeSuccessScreen} successScreenText={successScreenText} />
      )}
      {removePlansTable.includes(subscription ? subscription.id : 1) && (
        <div className="plans-table reach-out">
          If you would like to get in contact with us about your plan, you can reach out to us at sales@everviz.com
        </div>
      )}
    </div>
  );
}
