import { get } from 'lodash';
import { updateThemeConfigAction } from 'pages/CompanyThemeEditorPage/actions/ThemeEditorPage';
import { parseCompanyThemeRichtextOptions } from 'pages/CompanyThemeEditorPage/middleware/themeEditorHelper';
import React, { RefObject, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { onChange as onChangeTooltipText } from 'shared/widgets/utils/tooltipSelectorHelper';
import { extractRichtextValue } from '../utils/widgetHelper';
import './../../../../node_modules/react-quill/dist/quill.snow.css';
import { generateToolbarSettings, onChangeSelection, parseValue } from './richtextGroupHelper';
import { getUpdateCustomizeAction } from '../utils/widgetHelper';
import { formats } from 'shared/meta/widgets/richtextgroup/richtextGroup';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';
import { stripHtml } from 'utils/helper';

type ModalProps = {
  aggregatedOptions: any;
  option: any;
  index: number | string;
};

const RichtextGroupModalContent = ({ aggregatedOptions, option, index: detailIndex }: ModalProps) => {
  const projectConfig = useSelector<any, any>((state: RootState) => state.projectConfig);
  const { isCompanyThemeEditor, provider, type } = projectConfig;
  const chart = useChart();

  const dispatch = useDispatch();

  const quillRef = useRef() as RefObject<ReactQuill>;
  const [inlineSettings, setInlineSettings] = useState({});

  const updateCustomizedProp = (
    type: string,
    optionProps: object,
    val: any,
    extraModulesToLoad?: any,
    index?: number | null,
    skipEmit?: boolean,
    isText?: boolean
  ) => {
    if (isCompanyThemeEditor) {
      return dispatch(
        updateThemeConfigAction({
          optionProps: isText ? optionProps : parseCompanyThemeRichtextOptions(optionProps),
          val,
          extraModulesToLoad,
          index
        })
      );
    } else {
      return dispatch(
        getUpdateCustomizeAction(provider, type)({ optionProps, val, extraModulesToLoad, index, skipEmit })
      );
    }
  };

  const onChangeInternalSelection = (range: any, source: any, quill: any) => {
    if (range) {
      onChangeSelection(range, source, quill, inlineSettings, setInlineSettings);
    }
  };

  const onChangeStyle = (key: string, value: any, isOptionalArray?: boolean) => {
    const id = option.id.replace('.text', '');
    const index = option.richtext?.isArray ? 0 : null;
    const isTooltipHelper = option.richtext?.skipUseHTML;
    if (isTooltipHelper) return;
    updateCustomizedProp(type, { ...option, id: `${id}.${key}`, isOptionalArray }, value, null, index);
  };

  // Called when changing text
  const onChangeText = (value: string, _change: any, source: any) => {
    const val = parseValue(value).replace('●', '<span style="color: {point.color}">●</span>');

    if (source === 'user') {
      const index = option.richtext?.isArray ? 0 : null;
      const isTooltipHelper = option.richtext?.skipUseHTML;
      if (isTooltipHelper) {
        onChangeTooltipText(option, { val }, projectConfig, detailIndex, dispatch);
      } else {
        updateCustomizedProp(type, { ...option, id: option.id }, val, null, index, !isTooltipHelper, true);
        updateCustomizedProp(type, { ...option, id: option.id.replace('.text', '.useHTML') }, true, null, index, false);
      }
    }
  };

  const isAll = detailIndex === 'all';
  const options = extractRichtextValue(provider, type, option, chart, aggregatedOptions, isAll ? 0 : detailIndex);

  const { toolbar, toolbarOptions } = generateToolbarSettings({
    provider,
    type,
    settings: option,
    id: 'toolbar-74e6df45-2835-4dde-b418-324b2e28b770',
    options,
    ref: quillRef,
    onChange: onChangeStyle,
    inlineSettings,
    setInlineSettings,
    aggregatedOptions,
    fields: ['bold', 'underline', 'italic', 'fontSize', 'align', 'color', 'font'],
    className: 'px-0',
    noHighchartOptions: option?.richtext?.noHighchartOptions
  });

  const chartBackgroundColor =
    option?.id === 'tooltip' ? 'white' : aggregatedOptions?.chart?.backgroundColor ?? 'white';

  let text = get(options, option?.richtext?.rawKey) ?? '';
  if (!text) text = stripHtml(options?.text ?? '');
  if (option?.richtext?.removeWatermark) {
    text = text.replace(' © <a href="http://www.naturalearthdata.com">Natural Earth</a>', '');
  }

  return (
    <>
      <div className="toolbar-container">{toolbar}</div>

      <div className="richtext-editor">
        <ReactQuill
          theme="snow"
          defaultValue={text}
          modules={toolbarOptions}
          formats={formats}
          onChange={onChangeText}
          onChangeSelection={onChangeInternalSelection}
          ref={quillRef}
          style={{
            ...(options?.style ?? {}),
            ...{
              backgroundColor: chartBackgroundColor
            }
          }}
        />
      </div>
    </>
  );
};

export default RichtextGroupModalContent;
