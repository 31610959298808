import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { EvervizChart } from 'shared/types/chart';

// Used in middleware/helper files
export const getChart = (editorChartIndex: number) => {
  const charts = Highcharts.charts as EvervizChart[];
  return charts.find((chart) => chart?.index === editorChartIndex);
};

export const useChart = () => {
  const { editorChartIndex } = useSelector((state: RootState) => state.chartEditorPage);
  const charts = Highcharts.charts as EvervizChart[];
  return charts[editorChartIndex];
};
