import { TemplateProps } from '../../types';

const Inverted: TemplateProps = {
  title: 'Inverted area',
  description: '',
  constructor: '',
  thumbnail: 'yqenid.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      inverted: true,
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'area',
  advancedParent: 'Area',
  dataValidator: false,
  thumbnail_url: 'https://app.everviz.com/static/thumbnails/yqenid.svg',
  name: 'Inverted area'
};

export default Inverted;
