import { format } from 'date-fns';
import { getAdjustmentsAction } from 'pages/SubscriptionPage/actions/plansPage';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import Table from 'shared/table/Table';
import { adjustmentsTableColumns } from '../meta/adjustmentsTable';
import { getAdjustments } from '../utils/getAdjustments';

const BillingHistorySection = () => {
  const dispatch = useDispatch();
  const { adjustments } = useSelector((state: RootState) => state.plansPage);
  const { team } = useSelector((state: RootState) => state.profile);
  const [isLatestShown, setIsLatestShown] = useState(true);

  useEffect(() => {
    dispatch(getAdjustmentsAction({ team, adjustments }));
  }, []);

  const fetchedAdjustments = getAdjustments({ team, adjustments }) ?? [];
  const formattedAdjustments = fetchedAdjustments.map((adjustment: any) => ({
    ...adjustment,
    created: format(new Date(adjustment.created), 'dd. MMM yyyy').toLocaleLowerCase(),
    total: `$ ${adjustment.total}`,
    state: adjustment.state === 'invoiced' ? 'Paid' : 'Unpaid'
  }));

  return (
    <div>
      <Table
        columns={adjustmentsTableColumns}
        rows={isLatestShown ? formattedAdjustments.slice(0, 5) : formattedAdjustments}
        dataReturned={!!formattedAdjustments}
      />
      {formattedAdjustments.length > 5 && (
        <div className="w-full flex justify-center">
          <PrimaryButton
            buttonColor={ButtonColor.DarkBlueBordered}
            className="mt-6 w-32 font-medium overflow-x-auto"
            text={isLatestShown ? 'See all' : 'See latest'}
            onClick={() => setIsLatestShown(!isLatestShown)}
          />
        </div>
      )}
    </div>
  );
};

export default BillingHistorySection;
