import * as React from 'react';
import { DivMouseHandler } from 'shared/types/commonPropTypes';

import Close from 'static/icons/close.svg';

const PasteTooltip = ({ onClick }: { onClick: DivMouseHandler }) => {
  return (
    <div className="absolute top-6 left-2 bg-ev-navy-blue-2 p-4 rounded text-base font-medium text-white flex z-[2001]">
      <span className="pr-4">Drag and drop or paste your data directly into the datasheet on the right.</span>
      <div className="text-ev-baby-blue-2 flex w-3 h-3 cursor-pointer" onClick={onClick}>
        <Close />
      </div>
    </div>
  );
};

export default PasteTooltip;
