import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAction } from 'redux/actions/projectConfig';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { RootState } from 'redux/store';
import useToggle from 'shared/utils/useToggle';
import InputWidget from 'shared/widgets/InputWidget';
import { stripHtml } from 'utils/helper';

import type { RefObject } from 'react';

import { useEventListener } from 'usehooks-ts';
import { onSave } from '../publishHelper';

type EventType = 'mousedown' | 'mouseup' | 'touchstart' | 'touchend' | 'focusin' | 'focusout';

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T> | RefObject<T>[],
  handler: (event: MouseEvent | TouchEvent | FocusEvent) => void,
  eventType: EventType = 'mousedown',
  eventListenerOptions: AddEventListenerOptions = {}
): void {
  useEventListener(
    eventType,
    (event) => {
      const target = event.target as Node;

      // Do nothing if the target is not connected element with document
      if (!target || !target.isConnected) {
        return;
      }

      const isOutside = Array.isArray(ref)
        ? ref.filter((r) => Boolean(r.current)).every((r) => r.current && !r.current.contains(target))
        : ref.current && !ref.current.contains(target);

      if (isOutside) {
        handler(event);
      }
    },
    undefined,
    eventListenerOptions
  );
}

type ProjectTitleProps = {
  onSave?: () => void;
  className?: string;
};

export default function ProjectTitle({ className }: ProjectTitleProps) {
  const dispatch = useDispatch();
  const projectConfig: RootState['projectConfig'] = useSelector(getProjectConfig);
  const { projectName, type, saved } = projectConfig;

  const { inPackagesMode } = useSelector((state: RootState) => state.projectConfig);
  const { isThemeEditor } = useSelector((state: RootState) => state.chartEditorPage);
  const [value, setValue] = useState(projectName);
  const [editing, toggleEditing] = useToggle(false);
  const titleValue = stripHtml(value);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editing === false) {
      dispatch(
        setAction({
          projectName: titleValue || 'Untitled project',
          changeMade: true
        })
      );

      if (titleValue && titleValue !== projectName && saved) {
        onSave(type, isThemeEditor, dispatch);
      }
    }
  }, [editing]);

  useEffect(() => {
    setValue(projectName);
  }, [projectName]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') toggleEditing();
  };

  useOnClickOutside(ref, () => editing && toggleEditing());

  return (
    <div ref={ref} className={'flex flex-row ' + className}>
      {!editing && (
        <div
          className="
            overflow-hidden whitespace-nowrap pr-1 text-ellipsis text-base text-ev-dark-purple font-bold self-center rounded px-2 
            hover:border-solid hover:border-2 hover:min-h-[40px] hover:p-[7px] hover:pr-[28px] hover:pb-[2px]
            focus:shadow-light
          "
          onClick={() => !inPackagesMode && toggleEditing()}
        >
          {titleValue}
        </div>
      )}
      {editing && !inPackagesMode && (
        <InputWidget
          value={titleValue}
          onChange={({ val }) => setValue(val as string)}
          onKeyDown={onKeyDown}
          className="w-full"
        />
      )}
    </div>
  );
}
