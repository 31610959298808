export default {
  series: 1,
  data: 'Category;Series 1\nA;313\nB;256\nC;35\nD;11',
  seriesMapping: [
    {
      x: 0,
      name: 0,
      y: 1,
    },
  ],
  assignDataFields: [
    {
      name: 'A',
      y: 'B',
    },
  ],
};
