export const usersTableColumns = [
  {
    id: 'username',
    title: 'Display name'
  },
  {
    id: 'email',
    title: 'Email'
  },
  {
    id: 'group_id',
    title: 'Role',
    type: 'select'
  }
];

export const invitationsTableColumns = [
  {
    id: 'email',
    title: 'Email'
  },
  {
    id: 'created',
    title: 'Date sent',
    type: 'date'
  },
  {
    id: 'expire_time',
    title: 'Expires',
    type: 'date'
  }
];
