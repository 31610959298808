import ChooseTheme from 'shared/wizard/containers/ChooseTheme';
import PublishExport from 'shared/wizard/containers/PublishExport';
import WizardCustomize from 'shared/wizard/containers/WizardCustomize';
import TemplatesSelector from './../../components/TemplatesSelector';

export default [
  {
    header: 'Layout type',
    stepText: 'Layout type',
    element: TemplatesSelector,
    urlParam: 'type'
  },
  {
    header: 'Design',
    stepText: 'Design',
    element: ChooseTheme,
    tag: 'design',
    urlParam: 'themes'
  },
  {
    header: 'Text',
    stepText: 'Text',
    element: WizardCustomize,
    tag: 'text',
    urlParam: 'customize'
  },
  {
    header: 'Publish and export',
    stepText: 'Publish and export',
    element: PublishExport,
    nextButton: {
      defaultText: 'Done'
    },
    tag: 'publish-and-export',
    urlParam: 'publish'
  }
];
