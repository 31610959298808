import { connect } from 'react-redux';
import { checkPermission } from '../../../utils/profileHelper';
import { setAction } from '../../LayoutEditorPage/actions/layoutEditor.js';
import {
  mergeCellsAction,
  removeDataStyleAction,
  resetDataAction,
  setAction as setTableAction,
  setBordersAllEdgesAction,
  setBorderStyleAction,
  setCellSettingAction,
  setDataStyleAction,
  showContextMenuAction,
  toggleDataStyleAction,
  updateDataAction
} from '../actions/tableEditor.js';
import RichTextEditorToolbar from '../components/RichTextEditorToolbar';

const mapStateToProps = (state) => {
  return {
    charts: state.projectsPage.charts || [],
    team: state.profile.team,
    division: state.profile.division,
    paths: state.app.paths || {},
    bufferDataOptions: state.tableEditorPage.bufferDataOptions,
    cellSettings: state.tableEditorPage.cellSettings,
    dataOptions: state.projectConfig.dataOptions,
    customizedOptions: state.projectConfig.customizedOptions,
    isGSheet: state.tableEditorPage.isGSheet,
    checkPermission: (permission) => checkPermission(permission, state.profile),
    activeCellColumn: state.tableEditorPage.activeCellColumn,
    activeCellRow: state.tableEditorPage.activeCellRow,
    activeBufferCell: state.tableEditorPage.activeBufferCell,
    activeSelectedBufferCell: state.tableEditorPage.activeSelectedBufferCell,
    inSelectMode: state.tableEditorPage.inSelectMode
  };
};

const mapDispatchToProps = (dispatch) => ({
  set: (params) => dispatch(setAction(params)),
  setTableAction: (params) => dispatch(setTableAction(params)),
  setCellSetting: (actionType, action, cellSettings, dataOptions) =>
    dispatch(setCellSettingAction({ actionType, action, cellSettings, dataOptions })),
  updateData: (dataOptions) => dispatch(updateDataAction({ dataOptions })),
  resetData: (dataOptions, customizedOptions) => dispatch(resetDataAction({ dataOptions, customizedOptions })),
  mergeCells: (dataOptions, activeBufferCell, activeSelectedBufferCell) =>
    dispatch(mergeCellsAction({ dataOptions, activeBufferCell, activeSelectedBufferCell })),
  setDataStyle: (dataOptions, activeBufferCell, activeSelectedBufferCell, props, value) =>
    dispatch(setDataStyleAction({ dataOptions, activeBufferCell, activeSelectedBufferCell, props, value })),
  removeDataStyle: (dataOptions, activeBufferCell, activeSelectedBufferCell, props) =>
    dispatch(removeDataStyleAction({ dataOptions, activeBufferCell, activeSelectedBufferCell, props })),
  toggleDataStyle: (dataOptions, activeBufferCell, activeSelectedBufferCell, props, value) =>
    dispatch(toggleDataStyleAction({ dataOptions, activeBufferCell, activeSelectedBufferCell, props, value })),
  setBordersAllEdges: (dataOptions, activeBufferCell, activeSelectedBufferCell, type) =>
    dispatch(setBordersAllEdgesAction({ dataOptions, activeBufferCell, activeSelectedBufferCell, type })),
  setBorderStyle: (dataOptions, activeBufferCell, activeSelectedBufferCell, props, value) =>
    dispatch(setBorderStyleAction({ dataOptions, activeBufferCell, activeSelectedBufferCell, props, value })),
  showContextMenu: (x, y, column, row) => dispatch(showContextMenuAction({ x, y, column, row }))
});

export default connect(mapStateToProps, mapDispatchToProps)(RichTextEditorToolbar);
