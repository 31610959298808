import { TrashIcon } from '@heroicons/react/outline';
import paths from 'paths';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import Modal from 'shared/modal/Modal';
import { projectsPlaceholders } from 'shared/utils/projectsPlaceholders';
import {
  postTeamChartDuplicateUsingTeamidAndChartid,
  postTeamChartmetaUsingTeamidAndChartid,
  postTeamCompanythemeDuplicateUsingTeamidAndThemeid,
  postTeamCompanythememetaUsingTeamidAndThemeid
} from '../../../api/cloudApiGenerated';
import Card from '../../../shared/Card';
import Icon from '../../../shared/Icon';
import ThemeModal from './ThemeModal';

export type ThemeOptionProps = {
  id: number;
  name?: string;
  thumbnail_url?: string;
  chart_type?: number;
  href?: string;
};

type ThemeProps = {
  theme: ThemeOptionProps;
  isDeleted?: boolean;
  isDefaultTheme?: boolean;
  refresh?: () => void;
  showPaidPlanModal: () => void;
  hasPermission?: () => void;
  checkHasPermission?: (e: any) => void;
  isCompanyTheme?: boolean;
  children?: ReactNode;
};

export default function Theme(props: ThemeProps) {
  const history = useHistory();
  const { team } = useSelector((state: RootState) => state.profile);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    theme,
    isDeleted,
    isDefaultTheme,
    refresh,
    showPaidPlanModal,
    hasPermission,
    checkHasPermission,
    isCompanyTheme
  } = props;
  const placeholder =
    theme.chart_type && projectsPlaceholders[theme.chart_type as keyof typeof projectsPlaceholders].icon;

  const toggleDeleteTheme = () => {
    if (isCompanyTheme) {
      return postTeamCompanythememetaUsingTeamidAndThemeid(team.id, theme.id, {
        deleted: !isDeleted
      });
    }
    return postTeamChartmetaUsingTeamidAndChartid(team.id, theme.id, {
      deleted: !isDeleted
    });
  };

  const showModal = () => {
    if (!hasPermission) showPaidPlanModal();
    else setIsModalOpen(true);
  };

  const onClick = () => {
    if (!hasPermission) showPaidPlanModal();
    else {
      toggleDeleteTheme()
        .then(() => {
          if (refresh) refresh();
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  const onShowDeleteModal = () => {
    if (!hasPermission) showPaidPlanModal();
    else setShowDeleteModal(true);
  };

  const onCancel = () => setShowDeleteModal(false);

  const onCloneTheme = () => {
    const { isCompanyTheme } = props;
    if (!hasPermission) showPaidPlanModal();
    else {
      let duplicate = postTeamChartDuplicateUsingTeamidAndChartid;
      if (isCompanyTheme) duplicate = postTeamCompanythemeDuplicateUsingTeamidAndThemeid;

      duplicate(team.id, theme.id, {
        is_theme: true
      })
        .then(() => {
          if (refresh) refresh();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const toggleModal = (toggle: boolean) => setIsModalOpen(toggle);

  const onViewCompanyTheme = () => {
    if (!hasPermission) showPaidPlanModal();
    else history.push(paths.themePreview.replace(':themeid', theme.id.toString()));
  };

  const onEditCompanyTheme = () => {
    if (!hasPermission) showPaidPlanModal();
    else history.push(theme.href ?? '');
  };

  return (
    <Card
      title={theme.name}
      href={({ ...ps }) => (
        <Link {...ps} onClick={checkHasPermission} to={theme.href ?? ''}>
          {ps.children}
        </Link>
      )}
      onClick={checkHasPermission}
      thumbnail={theme.thumbnail_url}
      placeholder={placeholder}
    >
      {theme && (
        <ThemeModal
          theme={theme}
          refreshThemes={refresh}
          isOpen={isModalOpen}
          toggleModal={toggleModal}
          isCompanyTheme={isCompanyTheme ?? false}
        />
      )}

      <Modal
        isOpen={showDeleteModal}
        onClose={onCancel}
        title={isDeleted ? 'Recover theme' : 'Delete theme'}
        width={'w-5/12 max-w-xl'}
        bg={'bg-ev-yellow-1'}
      >
        <p>Are you sure you want to {isDeleted ? 'recover' : 'delete'} this theme?</p>

        <div className="pt-6 flex gap-2">
          <PrimaryButton onClick={onClick} text="Confirm" />
          <PrimaryButton onClick={onCancel} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
      </Modal>

      <div>
        {props.children}
        <div className="flex justify-between mt-4">
          <div className="space-x-4">
            {isCompanyTheme && !isDeleted && (
              <Icon
                className="text-lg fa fa-eye text-ev-navy-blue-2"
                title="Preview"
                onClick={onViewCompanyTheme}
                iconComponent={undefined}
              />
            )}
            {!isDefaultTheme && !isDeleted && (
              <Icon
                className="text-lg fa fa-cog text-ev-navy-blue-2"
                title="Settings"
                onClick={showModal}
                iconComponent={undefined}
              />
            )}
            {!isDefaultTheme && !isDeleted && (
              <Icon
                className="text-lg fa fa-clone text-ev-navy-blue-2"
                title="Duplicate"
                onClick={onCloneTheme}
                iconComponent={undefined}
              />
            )}

            {isCompanyTheme && !isDeleted && (
              <Icon
                className="text-lg fa fa-pen text-ev-navy-blue-2"
                title="Edit"
                onClick={onEditCompanyTheme}
                iconComponent={undefined}
              />
            )}
          </div>
          <div className="space-x-4">
            {!isDefaultTheme && (
              <Icon
                iconComponent={
                  <span onClick={onShowDeleteModal}>
                    <TrashIcon className="h-6 w-6 self-center cursor-pointer hover:text-ev-red text-ev-navy-blue-2" />
                  </span>
                }
                onClick={null}
                title={isDeleted ? 'Recover' : 'Delete'}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
