import { TemplateProps } from '../../types';

const StepLine: TemplateProps = {
  title: 'Step line',
  description: '',
  constructor: '',
  thumbnail: 'akeduw.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'line',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      line: {
        step: 'left'
      }
    }
  },
  sampleSet: 'line',
  dataValidator: false,
  advancedParent: 'Line',
  advanced: {
    name: 'Step'
  }
};

export default StepLine;
