import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectsPlaceholders } from 'shared/utils/projectsPlaceholders';
import {
  postTeamChartEmbedUsingTeamidAndChartid,
  postTeamTableEmbedUsingTeamidAndTableid
} from '../../../api/cloudApiGenerated';
import { checkPermission } from '../../../utils/profileHelper';
import { addChartToGridAction } from '../actions/layoutEditor.js';

export default function Chart(props) {
  const dispatch = useDispatch();
  const team = useSelector((state) => state.profile.team);
  const selectedSort = useSelector((state) => state.projectsPage.selectSortValue);
  const { parentToAttachChartTo } = useSelector((state) => state.layoutEditorPage);
  const { dataOptions } = useSelector((state) => state.projectConfig);

  const publishChart = async () => {
    await (props.chart.chart_type === 5
      ? postTeamTableEmbedUsingTeamidAndTableid
      : postTeamChartEmbedUsingTeamidAndChartid)(team.id, props.chart.id);
  };

  const useChart = () => {
    dispatch(
      addChartToGridAction({
        chart: props.chart,
        parentToAttachChartTo,
        dataOptions
      })
    );
  };

  const onClick =
    props.chart.has_published === 0 && checkPermission('publish')
      ? async () => {
          await publishChart();
          useChart();
        }
      : useChart;

  const usePlaceholder = (e) => {
    const placeholderIcon = projectsPlaceholders[props.chart.chart_type].icon;
    e.target.src = `https://app.everviz.com/${placeholderIcon}`;
    e.target.className = 'object-scale-down object-center h-full w-full p-2';
  };

  const thumbnailURL = props.chart.thumbnail_url;

  const templateTimeSinceLastChange = () => {
    const [created, edited] = [props.chart.creation_time, props.chart.last_updated].map((x) =>
      formatDistanceToNow(new Date(x))
    );

    const lastAction = [
      { text: 'edited', pick: edited && edited !== created, value: edited },
      { text: 'created', pick: Boolean(created), value: created }
    ].find((x) => x.pick === true);

    return selectedSort.id === 0
      ? `${lastAction.value} ago\n • ${props.chart.username}`
      : `created ${created} ago\n • ${props.chart.username}`;
  };

  const notPublished = props.chart.has_published === 0 || props.chart.has_published === null;
  return (
    <div
      className={`
      project-card space-y-2
      ${dataOptions.some((x) => x.chart_id === props.chart.id) ? 'blur-[2px] brightness-50 pointer-events-none' : ''}`}
    >
      <figure className="group rounded-md relative h-24 cursor-pointer overflow-hidden">
        <img
          onClick={onClick}
          className="lazy"
          draggable="false"
          src={thumbnailURL}
          onError={(e) => usePlaceholder(e)}
        />
      </figure>

      <p>{props.chart.name}</p>
      <span className="text-gray-500 text-xs">{templateTimeSinceLastChange()}</span>

      {props.chart.has_published === 1 && (
        <button className="bulk-action-btn ml-0" onClick={onClick}>
          Use
        </button>
      )}
      {notPublished && checkPermission('publish') && (
        <button
          className="bulk-action-btn ml-0 bg-yellow-400 text-yellow-900 \
          hover:bg-yellow-300 hover:text-yellow-900"
          onClick={onClick}
        >
          Publish and use
        </button>
      )}
    </div>
  );
}
