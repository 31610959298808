/* eslint-disable @typescript-eslint/no-empty-function */
import { get, isArray } from 'lodash';
import React from 'react';
import Accordion from 'shared/Accordion';
import IconButton from 'shared/buttons/IconButton';
import { GenericInputProps, InputChangeParams } from 'shared/types/commonPropTypes';
import { richtextStyles } from 'shared/utils/selectStylesHelper';
import { fontSizes } from '../../pages/LayoutEditorPage/utils/storyHelper';
import ColorWidget from './ColorWidget';
import HorizontalAlignWidget from './HorizontalAlignWidget';
import SelectWidget from './SelectWidget';
import { getOptionalArrayValue } from 'pages/ChartEditorPage/utils/chartEditorCustomizeHelper';

export type WindowProps = Window &
  typeof globalThis & {
    everviz: {
      responsiveFontOptions: any;
      disconnectResizeObservers: () => void;
    };
    Highcharts?: any;
  };

export default function FontStyler(props: GenericInputProps) {
  const { option, value, onChange, className, userAggregatedOptions } = props;

  const fields = option?.custom?.fields ?? {
    bold: 1,
    italic: 1,
    color: 1,
    fontSize: 1,
    align: 1
  };

  const getAliasKey = (key: string) => {
    if (!option?.aliasKey || !key) return '';
    return isArray(option.aliasKey)
      ? option.aliasKey.map((element) => `${element}.${key}`)
      : `${option.aliasKey}.${key}`;
  };

  const getAggregatedLabels = () => {
    const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);
    const style = {
      bold: (fields.bold && extractValue('', 'fontWeight')) || '',
      italic: (fields.italic && extractValue('', 'fontStyle')) || '',
      textSize: (fields.fontSize && extractFontSizeValue('12', 'fontSize')) || '',
      textAlign: (fields.align && extractValue('', 'textAlign')) || '',
      color: (fields.color && extractValue('', 'color')) || ''
    };

    return (Object.keys(style) as Array<keyof typeof style>)
      .map((key) => {
        return capitalizeFirstLetter(
          ((style[key] === 'unset' || style[key] === '' ? '' : style[key]) ?? '').toString()
        );
      })
      .filter((d) => d !== '')
      .join(', ');
  };

  const onFontSizeChange = (params: InputChangeParams) => {
    const { val } = params;
    const { everviz } = window as WindowProps;

    if (everviz?.responsiveFontOptions) {
      everviz.responsiveFontOptions = {};
    }

    onChange({
      val: val + 'px',
      optional: {
        isOptionalArray: option?.isOptionalArray,
        id: `${option?.id}.fontSize`,
        aliasKey: getAliasKey('fontSize')
      }
    });
  };

  const onToggleProp = (key: string, activeValue: string) => {
    const { option } = props;
    const id = `${option?.id}.${key}`;
    const prevVal = get(value, key);

    onChange({
      val: prevVal === activeValue ? 'unset' : activeValue,
      optional: {
        isOptionalArray: option?.isOptionalArray,
        id,
        aliasKey: getAliasKey(key)
      }
    });
  };

  const onChangeColor = (params: InputChangeParams) => {
    onChange({
      val: params.val,
      optional: {
        isOptionalArray: option?.isOptionalArray,
        id: `${option?.id}.color`,
        aliasKey: getAliasKey('color')
      }
    });
  };

  const extractValue = (defaultVal: string, id: string) => {
    return get(value, id) ?? defaultVal;
  };

  const extractFontSizeValue = (defaultVal: string, id: string) => {
    const useDynamicFonts = userAggregatedOptions?.everviz?.text?.dynamicFonts;
    if (useDynamicFonts) {
      if (option?.isOptionalArray) {
        return (
          getOptionalArrayValue(userAggregatedOptions, {
            ...option,
            id: `${option?.id}.${id}`
          }) ?? defaultVal
        );
      }
      return get(userAggregatedOptions, `${option?.id}.${id}`) ?? defaultVal;
    }
    return get(value, id) ?? defaultVal;
  };

  const onChangeAlign = (params: InputChangeParams) => {
    onChange({
      val: params.val,
      optional: {
        isOptionalArray: option?.isOptionalArray,
        id: `${option?.id}.textAlign`,
        aliasKey: getAliasKey('textAlign')
      }
    });
  };

  return (
    <Accordion
      key={'key'}
      useInternalSelect={true}
      headerText={props.label}
      smallHeaderText={true}
      selectedChildren={getAggregatedLabels()}
      defaultSelection={false}
      accordionClasses={className ?? 'bg-white'}
      childrenClasses={'relative flex flex-col rounded mx-4 py-2'}
    >
      <div className="flex flex-row flex-wrap items-center gap-1">
        {fields.bold && (
          <IconButton
            icon="bold"
            className="w-8 h-8 rounded "
            onClick={() => onToggleProp('fontWeight', 'bold')}
            active={extractValue('', 'fontWeight') === 'bold'}
          />
        )}
        {fields.italic && (
          <IconButton
            icon="italic"
            className="w-8 h-8 rounded"
            onClick={() => onToggleProp('fontStyle', 'italic')}
            active={extractValue('', 'fontStyle') === 'italic'}
          />
        )}

        {fields.fontSize && (
          <SelectWidget
            selectOptions={fontSizes}
            onChange={onFontSizeChange}
            value={parseInt(extractFontSizeValue('12', 'fontSize'), 10).toString()}
            customStyles={richtextStyles}
            inlineSelect={true}
          />
        )}

        {fields.color && <ColorWidget value={extractValue('', 'color')} onChange={onChangeColor} />}

        {fields.align && (
          <HorizontalAlignWidget
            className={'inline-block '}
            onChange={onChangeAlign}
            value={extractValue('', 'textAlign')}
          />
        )}
      </div>
    </Accordion>
  );
}
