import React from 'react';
import { useDispatch } from 'react-redux';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { setTeamInvitationsAction } from '../actions/teamInvitationsAction';
import { HeaderActionsProps } from '../types/teamPageModels';
import { setTeamMembersAction } from '../actions/teamMembersAction';
import { checkPermission } from 'utils/profileHelper';

const HeaderActions = ({ hasPermission, usersMax, membersLength }: HeaderActionsProps) => {
  const dispatch = useDispatch();

  const openMembersInvitationModal = () => {
    if (usersMax <= membersLength) {
      dispatch(
        setTeamInvitationsAction({
          isUsersLimitModalOpen: true
        })
      );
    } else {
      dispatch(
        setTeamInvitationsAction({
          modalIsOpen: true
        })
      );
    }
  };

  const openNewSubteamModal = () => {
    dispatch(
      setTeamMembersAction({
        isSubteamCreateModalOpen: true
      })
    );
  };

  const buttonClassName = 'h-10 m-2 ev-md:m-0';

  return (
    <div className="mt-4 ev-md:mt-0 ev-md:flex gap-x-4 items-center">
      <PrimaryButton
        className={buttonClassName}
        text="Invite members"
        buttonColor={ButtonColor.NavyBlue}
        disabled={!hasPermission}
        onClick={openMembersInvitationModal}
      />
      <PrimaryButton
        className={buttonClassName}
        text="Create subteam"
        buttonColor={ButtonColor.WhiteBordered}
        disabled={!checkPermission('subteam_management')}
        onClick={openNewSubteamModal}
      />
    </div>
  );
};

export default HeaderActions;
