import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './../shared/tooltip/Tooltip';

const Icon = ({ title, className = '', showToolTip = true, onClick, iconComponent, ...rest }) => {
  const icon = iconComponent ? (
    iconComponent
  ) : (
    <i className={`${className} ${onClick ? 'clickable' : ''}`} title={title} onClick={onClick} />
  );

  if (!showToolTip) {
    return React.cloneElement(icon, { ...rest });
  }

  return (
    <Tooltip
      tippyProps={{
        content: title,
        placement: 'bottom'
      }}
    >
      {icon}
    </Tooltip>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  iconComponent: PropTypes.any,
  title: PropTypes.string,
  showToolTip: PropTypes.bool,
  onClick: PropTypes.func
};

export default Icon;
