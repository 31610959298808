export default {
  series: 2,
  data: 'Key;Point\nus-ak;14.73\nus-al;27.73\nus-ar;26.92\nus-az;3.5\nus-ca;-29.99\nus-co;-4.91\nus-ct;-13.64\nus-dc;-86.78\nus-de;-11.37\nus-fl;1.19\nus-ga;5.1\nus-hi;-32.18\nus-ia;9.41\nus-id;32.18\nus-il;-16.89\nus-in;19.01\nus-ks;20.42\nus-ky;29.84\nus-la;19.64\nus-ma;-27.2\nus-md;-26.42\nus-me;-2.96\nus-mi;0.22\nus-mn;-1.51\nus-mo;18.51\nus-ms;17.8\nus-mt;20.23\nus-nc;3.66\nus-nd;35.73\nus-ne;25.05\nus-nh;-0.37\nus-nj;-13.98\nus-nm;-8.21\nus-nv;-2.42\nus-ny;-22.49\nus-oh;8.07\nus-ok;36.39\nus-or;-10.98\nus-pa;0.72\nus-ri;-15.51\nus-sc;14.27\nus-sd;29.79\nus-tn;26.01\nus-tx;8.98\nus-ut;17.89\nus-va;-5.32\nus-vt;-26.41\nus-wa;-15.71\nus-wi;0.76\nus-wv;41.68\nus-wy;46.3',
  seriesMapping: [
    {
      'hc-key': 0,
      name: 0,
      value: 1,
      x: 1
    },
    {
      'hc-key': 0,
      value: 1,
      x: 1
    }
  ],
  assignDataFields: [
    {
      labels: 'A',
      value: 'B'
    },
    {
      labels: 'A',
      value: 'B'
    }
  ],
  seriesOptions: [
    {
      type: 'map'
    },
    {
      type: 'mapline',
      showInLegend: false,
      enableMouseTracking: false,
      includeInDataExport: false
    }
  ],
  customizedOptions: {
    colorAxis: [
      {
        dataClasses: [
          {
            from: -86.78,
            to: 0,
            color: '#FF495E',
            name: 'Red'
          },
          {
            from: 0,
            to: 46.3,
            color: '#28277E',
            name: 'Blue'
          }
        ]
      }
    ]
  },
  seriesTemplate: ['map', 'mapline']
};
