import React from 'react';
import AlertMessage from './AlertMessage';

interface ErrorMessageProps {
  error?: any;
  dismissable?: boolean;
  errorBoxClass?: string;
}
export default function ErrorMessage({ error = '', dismissable = true, errorBoxClass }: ErrorMessageProps) {
  return (
    <div className={`error-box ${error && errorBoxClass ? errorBoxClass : ' '}`}>
      {error && (
        <AlertMessage type="danger" text={error.toString()} tag="Error" dismissable={dismissable} extraClass="mb-4" />
      )}
    </div>
  );
}
