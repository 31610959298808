import { TemplateProps } from '../../types';

const ThreeDPie: TemplateProps = {
  title: '3D Pie',
  description: '',
  constructor: '',
  thumbnail: 'erifer.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0
      },
      polar: false
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        depth: 35,
        cursor: 'pointer'
      },
      series: {
        dataLabels: {
          enabled: true
        }
      }
    }
  },
  sampleSet: 'pie',
  dataValidator: false,
  advancedParent: 'Pie',
  advanced: {
    name: '3D'
  }
};

export default ThreeDPie;
