import React from 'react';
import { useSelector } from 'react-redux';
import { selectEditorMapRef } from '../../../redux/reducers/locationMap/instanceReducer';
import IconButton from '../../buttons/IconButton';

export const LocationMapNavigationControls = () => {
  const editorMapInstance = useSelector(selectEditorMapRef);

  return (
    <>
      <IconButton
        icon={'fa fa-solid fa-plus text-lg'}
        tooltipText="Zoom in"
        onClick={() => editorMapInstance?.mapRef?.getMap()?.zoomIn()}
      />
      <IconButton
        icon={'fa fa-solid fa-minus text-lg'}
        tooltipText="Zoom out"
        onClick={() => editorMapInstance?.mapRef?.getMap()?.zoomOut()}
      />
      <IconButton
        icon={'fa fa-solid fa-compass text-lg'}
        tooltipText="Reset orientation to north"
        onClick={() => editorMapInstance?.mapRef?.getMap()?.easeTo({ bearing: 0 })}
      />
      <IconButton
        icon={'fa fa-solid fa-angle-90 text-lg'}
        tooltipText="Reset map angle"
        onClick={() => editorMapInstance?.mapRef?.getMap()?.easeTo({ pitch: 0 })}
      />
    </>
  );
};
export default function LocationMapBottomBar() {
  return (
    <div className="w-full h-full flex justify-center">
      <div
        className="relative flex content-center gap-x-3 items-center pl-8 pr-8 border-b-r bg-white "
        style={{ borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px' }}
      >
        <div className="absolute w-8 h-8 top-0 -left-8 bg-white" />
        <div className="absolute w-8 h-8 top-0 -left-8 bg-ev-grey" style={{ borderTopRightRadius: '30px' }} />
        <LocationMapNavigationControls />
        <div className="absolute w-8 h-8 top-0 -right-8 bg-white" />
        <div className="absolute w-8 h-8 top-0 -right-8 bg-ev-grey" style={{ borderTopLeftRadius: '30px' }} />
      </div>
    </div>
  );
}
