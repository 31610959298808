import React from 'react';
import PropTypes from 'prop-types';
import {} from '../../../api/cloudApiGenerated';

class Chart extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.aggregatedOptions.layout &&
      this.props.aggregatedOptions.layout &&
      nextProps.aggregatedOptions.layout.type !== this.props.aggregatedOptions.layout.type
    ) {
      return true;
    }
    return nextProps.id !== this.props.id;
  }

  onChange(event) {
    this.props.onChange(event, this.props.option);
  }

  loadLink(link) {
    const script = document.createElement('script');
    script.src = window.hcconfig.frontend.hostname + '/inject/' + link;
    script.defer = true;

    document.head.appendChild(script);
  }

  render() {
    let contextMenu = [
      {
        icon: 'trash',
        onClick: this.props.onRemove,
        text: 'Remove',
      },
    ];

    if (this.props.option.hash) {
      this.loadLink(this.props.option.hash);
    }
    return (
      <div
        className={'item ' + (this.props.type === 'link' ? ' link-item' : '') + (this.props.extraClass || ' ')}
        key={this.props.id}
      >
        <div className={'context-menu ' + this.props.buttonPos}>
          <ul>
            {contextMenu.map((menuOption, i) => {
              const onClick = () => {
                menuOption.onClick(this.props.id);
              };
              return (
                <li key={i} onClick={onClick}>
                  {' '}
                  <i className={'fa fa-' + menuOption.icon} title={menuOption.text} />{' '}
                </li>
              );
            })}
          </ul>
        </div>

        {this.props.type === 'img' && (
          <div className="option-container">
            <div
              className="chart-img"
              draggable="false"
              style={{ height: '100%' }}
              id={((x) => (x.chart_type === 5 ? 'everviz-table-' : 'highcharts-') + x.hash)(this.props.option)}
            ></div>
          </div>
        )}
      </div>
    );
  }
}

Chart.propTypes = {
  option: PropTypes.object,
  type: PropTypes.string,
  onRemove: PropTypes.func,
  id: PropTypes.number,
};

export default Chart;
