import React from 'react';
import PrimaryButton from '../../buttons//PrimaryButton.tsx';
import SecondaryModal from '../../modal/SecondaryModal';

export default function WizardModal(props) {
  const { isOpen, isMap, close } = props;
  const options = [
    'Select a type of chart',
    'Add your own data',
    'Select a design theme',
    'Add text and annotations',
    'Publish and export your chart'
  ];

  return (
    <SecondaryModal
      isOpen={isOpen}
      toggle={close}
      title={'Five steps to make a ' + (isMap ? 'map' : 'chart')}
      id="ev-wizard-modal"
    >
      {options.map((option, index) => {
        return (
          <div className="flex mb-2" key={`wizard_option_${index}`}>
            <div className="bg-ev-light-orange text-black font-bold rounded-full w-6 h-6 flex justify-center items-center mr-2 pt-[2px] wizard-circle ">
              <div>{index + 1}</div>
            </div>
            <div className="pt-[4px]">{option.replace('chart', isMap ? 'map' : 'chart')}</div>
          </div>
        );
      })}

      <div className="my-4">
        You can always go back and forth on the steps by using&nbsp;
        <span className="rounded-full bg-white text-ev-navy-blue-2 border mt-[-1px] px-2 py-1 text-2xs font-bold">
          Back
        </span>
        &nbsp;and&nbsp;
        <span className="rounded-full bg-ev-navy-blue-2 border mt-[-1px] text-white px-2 py-1 text-2xs font-bold">
          Next
        </span>
      </div>

      <div className="pt-2">
        <PrimaryButton text="Got it!" onClick={close} />
      </div>
    </SecondaryModal>
  );
}
