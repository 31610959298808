import paths from 'paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import Modal from 'shared/modal/Modal';
import StarIcon from 'static/icons/star.svg';

type PaidPlanModalProps = {
  isOpen: boolean;
  close: () => void;
};

export default function PaidPlanModal(props: PaidPlanModalProps) {
  const { isOpen, close } = props;
  const { paidPlanType, cancelText, featureText } = useSelector((state: RootState) => state.app);
  const history = useHistory();

  const handleOnClick = () => {
    history.push(paths.profileSubscription);
    close();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      title={
        <div className="flex gap-2 items-center">
          <StarIcon width={17} height={16} />
          Premium feature
        </div>
      }
      width="w-5/12 max-w-xl"
      bg="bg-ev-yellow-1"
    >
      <div className="hc-premium-text">
        <p>
          Upgrade to a {paidPlanType ?? 'starter'} plan to unlock this premium {featureText ?? 'feature'} and more!
        </p>
      </div>

      <hr />
      <div className="flex gap-4">
        <PrimaryButton onClick={handleOnClick} text="Upgrade now" />
        <PrimaryButton onClick={close} buttonColor={ButtonColor.BabyBlue2} text={cancelText} />
      </div>
    </Modal>
  );
}
