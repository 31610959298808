import ChooseTheme from 'shared/wizard/containers/ChooseTheme';
import ChooseTemplate from 'shared/wizard/containers/ChooseTemplate';
import ImportData from 'shared/wizard/containers/ImportData';
import PublishExport from 'shared/wizard/containers/PublishExport';
import WizardCustomize from 'shared/wizard/containers/WizardCustomize';

export default [
  {
    header: 'Chart type',
    stepText: 'Type',
    permission: 'templates',
    element: ChooseTemplate,
    tag: 'type',
    urlParam: 'type',
    cb: 'next'
  },
  {
    header: 'Data',
    stepText: 'Data',
    element: ImportData,
    tag: 'data',
    urlParam: 'data'
  },
  {
    header: 'Design',
    stepText: 'Design',
    permission: 'themes',
    element: ChooseTheme,
    tag: 'design',
    urlParam: 'themes'
  },
  {
    header: 'Customize',
    stepText: 'Customize',
    permission: 'customize',
    element: WizardCustomize,
    tag: 'text',
    urlParam: 'customize'
  },
  {
    header: 'Publish & Export',
    stepText: 'Publish & Export',
    element: PublishExport,
    nextButton: {
      defaultText: 'Done'
    },
    tag: 'publish-and-export',
    urlParam: 'publish'
  }
];
