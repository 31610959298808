import { cloneDeep, isFunction } from 'lodash';
import { on } from '../../../editor/core/highed.dom';
import store from '../../../redux/store';
import inlineEditorOptions from './../../../editor/meta/highed.inline.editor.options';
import { setAction } from './../../../redux/actions/projectConfig';
import { setAction as setChartAction } from './../../ChartEditorPage/actions/chartEditor';

let dialogTimeout = false;
const HOVER_TIMEOUT = 2500;

export default (CustomizeOptions, chart) => {
  const container = chart?.container;

  // Hovers
  const hoverOptions = inlineEditorOptions.hover;
  Object.keys(hoverOptions).forEach(function (key) {
    const element = container.querySelector(hoverOptions[key].source);
    on(element, 'mouseover', function () {
      if (dialogTimeout) clearTimeout(dialogTimeout);
      store.dispatch(
        setChartAction({
          hideElementButtonOptions: {
            ...hoverOptions[key],
            box: element
          }
        })
      );

      dialogTimeout = setTimeout(() => destroy(), HOVER_TIMEOUT);
    });
  });

  // Clicks
  CustomizeOptions['option.cat.text'][0].options.forEach((option) => {
    if (!option.inline?.source) return;

    const isSourceAFunction = isFunction(option.inline?.source);
    const elements = isSourceAFunction
      ? option.inline.source(container)
      : container.querySelectorAll(option.inline?.source);

    if (!elements || !(elements ?? []).length) return;

    elements.forEach((element) => {
      on(element, 'click', () => {
        const dispatchedOption = cloneDeep(option);
        if (option.inline?.isAxis) {
          const axis = chart.axes.find((axis) => axis.axisTitle?.element === element);
          const index = axis?.userOptions?.index ?? 0;
          dispatchedOption.id = dispatchedOption.id.replace('[0]', `[${index}]`);
          dispatchedOption.inline.highchartsId.forEach((id, propIndex) => {
            dispatchedOption.inline.highchartsId[propIndex] = id.replace('[0]', `[${index}]`);
          });
        }

        store.dispatch(
          setAction({
            richtextGroupOptions: {
              option: dispatchedOption,
              box: element
            }
          })
        );
      });
    });
  });

  const destroy = () => {
    store.dispatch(
      setChartAction({
        hideElementButtonOptions: null
      })
    );
  };

  return {
    destroy
  };
};
