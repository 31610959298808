import * as React from 'react';

type GeneratingVideoProps = {
  userEmail: string;
};

const GeneratingVideo = (props: GeneratingVideoProps) => {
  return (
    <div className="text-ev-navy-blue text-sm font-medium mt-2">
      We are processing your request. <br />
      <br />
      You will receive an email shortly at {props.userEmail} with a link to your video.
    </div>
  );
};

export default GeneratingVideo;
