import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from 'redux/store';

import { login, postAccount } from 'api/cloudApiGenerated';
import config from 'config';
import { setAction as setChartAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { BillingForm } from 'pages/SubscriptionPage/types/plansModels';
import PrimaryButton from 'shared/buttons//PrimaryButton';
import NavButton from 'shared/buttons/NavButton';
import LinkButton from 'shared/buttons/TextButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import TextInput from 'shared/inputs/TextInput';
import Modal from 'shared/modal/Modal';
import { SignupModalMode } from 'shared/types/signupModels';
import { onLoggedIn } from 'utils/profileHelper';
import { useCaptcha } from 'utils/signupHelper';
import {
  generateFormForWindowMode,
  getHeaderForWindowModal,
  getTextInputForWindowMode,
  validateSignupForm
} from './utils/signupModalHelper';

type SignupModalProps = {
  headerText: string;
  cb?: (shouldRedirect?: boolean) => void;
};

const SignupModal = (props: SignupModalProps) => {
  const dispatch = useDispatch();
  const paths = useSelector((state: RootState) => state.app.paths);
  const signupModalOpen = useSelector((state: RootState) => state.chartEditorPage.signupModalOpen);
  const chartConfig = useSelector((state: RootState) => state.chartEditorPage.editorChartConfig);
  const [windowMode, setWindowMode] = useState(SignupModalMode.Login);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [form, setForm] = useState<BillingForm>(generateFormForWindowMode(windowMode));
  const isLoginMode = windowMode === SignupModalMode.Login;
  const isSignupMode = windowMode === SignupModalMode.Signup;

  useEffect(() => {
    setForm(generateFormForWindowMode(windowMode));
    setError('');
  }, [windowMode]);

  const closeSignUpModal = () => {
    dispatch(setChartAction({ signupModalOpen: false }));
    setError('');
  };

  const updateState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    const updatedForm = form;
    updatedForm[field].error = '';
    updatedForm[field].value = event.target.value;
    setForm({ ...updatedForm, ...form });
  };

  const onLoginSubmit = () => {
    setSaving(true);
    login(form.email.value, form.password.value)
      .then((data) => {
        if (props.cb) onLoggedIn(false, data, props.cb(false));
        else onLoggedIn(false, data);
        dispatch(setChartAction({ signupModalOpen: false }));
      })
      .catch(() => {
        setError('Oops! The email or password is incorrect.');
        setSaving(false);
      });
  };

  const onSignupSubmit = () => {
    const valid = validateSignupForm(form, (newForm) => setForm({ ...newForm }));

    if (valid) {
      setSaving(true);

      const action = 'login';
      useCaptcha(config, action)
        .then(async (token) => {
          await postAccount({
            username: form.username.value,
            email: form.email.value,
            password: form.password.value,
            captchaResponse: token,
            action
          });
          onLoginSubmit();
        })
        .catch((error) => setError(error));
    } else {
      setError('Form validation failed.');
    }
  };

  const onSubmit = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    if (isLoginMode) onLoginSubmit();
    else if (isSignupMode) onSignupSubmit();
  };

  const toggleWindowChange = () => {
    if (isLoginMode) setWindowMode(SignupModalMode.Signup);
    else setWindowMode(SignupModalMode.Login);
  };

  const onGoogleLogin = () => {
    localStorage.setItem('chartConfig', JSON.stringify(chartConfig));
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') onSubmit(e);
  };

  const mainText = getTextInputForWindowMode(windowMode).mainText;
  const header = getHeaderForWindowModal(windowMode, props.headerText);

  return (
    <Modal
      isOpen={signupModalOpen}
      onClose={closeSignUpModal}
      title={header}
      width="w-5/12 max-w-xl"
      bg="bg-ev-yellow-1"
      error={error}
    >
      <div className="pb-6 text-base">
        {Boolean(mainText) && <span className="font-bold pb-4">{mainText}&nbsp;</span>}
        <span>{getTextInputForWindowMode(windowMode).additionalText}&nbsp;</span>
        <LinkButton
          className="font-normal"
          text={getTextInputForWindowMode(windowMode).link}
          onClick={toggleWindowChange}
          thin={true}
        />
      </div>
      <NavButton
        link={paths.oauthSignup}
        text={getTextInputForWindowMode(windowMode).externalGoogleLink}
        onClick={onGoogleLogin}
      />
      <div className="mt-6">
        {Object.entries(form).map(([key, field], i) => {
          return (
            <TextInput
              key={key}
              name={key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState(e)}
              label={field.label}
              labelClass="font-bold"
              placeholder={field.placeholder}
              value={field.value}
              type={field.type || ''}
              autoFocus={i === 0}
              error={field.error}
              inputClassName={'highed-field-input'}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
            />
          );
        })}
      </div>

      <p className="text-ev-navy-blue text-sm pb-6">
        {isSignupMode && (
          <>
            By clicking Sign up, you agree to our&nbsp;
            <a href="https://www.everviz.com/terms" target="_blank" rel="noreferrer">
              Terms
            </a>
            ,&nbsp;
            <a href="https://www.everviz.com/privacy" target="_blank" rel="noreferrer">
              Privacy
            </a>
            &nbsp;and&nbsp;
            <a href="https://www.everviz.com/cookies" target="_blank" rel="noreferrer">
              Cookie Policy
            </a>
          </>
        )}
        {isLoginMode && <NavLink to={paths.forgottenPassword}>Forgot password?</NavLink>}
      </p>
      <div className="flex items-center">
        <PrimaryButton
          className="w-52"
          buttonColor={ButtonColor.NavyBlue}
          text={windowMode}
          disabled={saving}
          onClick={onSubmit}
        />
        {saving && <i className="fa fa-spinner fa-spin fa-2x mx-2" />}
      </div>
    </Modal>
  );
};

export default SignupModal;
