import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import VizCard, { VizCardSize } from 'pages/VizPickerPage/components/VizCard';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import ChartIcon from 'shared/wizard/Icons/ChartIcon';
import LayoutIcon from 'shared/wizard/Icons/LayoutIcon';
import MapIcon from 'shared/wizard/Icons/MapIcon';
import TableIcon from 'shared/wizard/Icons/TableIcon';
import { checkCreatePermission } from 'utils/profileHelper';

const CreateNewProjectTile = () => {
  const paths = useSelector((state: RootState) => state.app.paths);
  const team = useSelector((state: RootState) => state.profile.team);
  const projectsPage = useSelector((state: RootState) => state.projectsPage);
  const commonDisabledText = 'Access denied';
  const displayUnlocked = projectsPage?.charts?.length === 2;

  return (
    <div className="flex flex-col items-center bg-ev-yellow-5 border-ev-light-orange border rounded pb-4">
      <p className="text-ev-navy-blue-2 font-medium text-lg my-4">Create a new project:</p>
      <div className="grid grid-cols-2 gap-4 mb-4 px-6 w-full">
        <VizCard
          size={VizCardSize.Regular}
          path={paths.newChart}
          title="Chart"
          icon={<ChartIcon />}
          disabled={!checkCreatePermission('chart')}
          disabledText={commonDisabledText}
        />
        <VizCard
          size={VizCardSize.Regular}
          path={paths.newChart + '?maps'}
          title="Map"
          icon={<MapIcon />}
          disabled={!checkCreatePermission('chart')}
          disabledText={commonDisabledText}
        />
        <VizCard
          size={VizCardSize.Regular}
          path={team ? paths.createTable : '/new'}
          title="Table"
          icon={<TableIcon />}
          disabled={!team || !checkCreatePermission('table')}
          disabledText={commonDisabledText}
        />
        <VizCard
          size={VizCardSize.Regular}
          path={team ? paths.createLayout : '/new'}
          title="Layout"
          icon={<LayoutIcon />}
          disabled={!team || !(projectsPage?.charts?.length >= 2 && checkCreatePermission('layout'))}
          disabledText={projectsPage.charts.length < 2 ? 'Create 2 projects to unlock.' : commonDisabledText}
          displayUnlocked={displayUnlocked}
        />
      </div>
      <PrimaryButton
        text={'Use an example'}
        link="https://www.everviz.com/chart-examples"
        buttonColor={ButtonColor.WhiteBordered}
        className="no-underline"
      />
    </div>
  );
};

export default CreateNewProjectTile;
