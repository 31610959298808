/* Highcharts plugin to handle text symbols */
(function(H) {
  function symbolWrap(proceed, symbol, x, y, w, h, options) {

    if (symbol && symbol.indexOf('symbol:') === 0) {
      var text = symbol.split(':')[1],
        svgElem = this.text(unescape(text), x, y)
        .css({
          fontFamily: 'FontAwesome',
        });
      if (svgElem.renderer.isVML) {
        svgElem.fillSetter = function(value, key, element) {
          element.style.color = H.Color(value).get('rgb');
        };
      }
      return svgElem;
    }
    return proceed.apply(this, [].slice.call(arguments, 1));
  }

  H.wrap(H.SVGRenderer.prototype, 'symbol', symbolWrap);

}(Highcharts));
