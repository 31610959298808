import { Chart } from 'highcharts';
import { OptionProps } from '../meta/CustomizeOptions';

export const filteredBy = function (group: OptionProps, chart?: Chart) {
  if (!chart) return [...(group.defaultFilterValue as [])];
  return [
    ...new Set([
      chart.userOptions && chart.userOptions.chart ? chart.userOptions.chart.type : 'line',
      ...((chart?.userOptions?.series ?? []).map((serie) => serie.type || 'line') || [])
    ])
  ];
};

export const defaultTypeList = [
  'scatter',
  'line',
  'spline',
  'areaspline',
  'area',
  'bar',
  'bubble',
  'areasplinerange',
  'boxplot',
  'arearange',
  'column',
  'waterfall',
  'columnrange',
  'errorbar',
  'dumbbell'
];

export const annotationsList = [
  ...defaultTypeList,
  'gauge',
  'heatmap',
  'polygon',
  'solidgauge',
  'map',
  'mapline',
  'tilemap',
  'wordcloud'
];
