import { setAction, setDataAction } from 'pages/ChartEditorPage/actions/chartEditor';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Accordion from 'shared/Accordion';
import { InputChangeParams } from 'shared/types/commonPropTypes';
import useToggle from 'shared/utils/useToggle';
import TextWidget from 'shared/widgets/TextWidget';

const WordcloudInput = () => {
  const dispatch = useDispatch();
  const [isOpen, toggleOpen] = useToggle(true);
  const { dataTextValue } = useSelector((state: RootState) => state.chartEditorPage);
  const { aggregatedOptions } = useSelector((state: RootState) => state.projectConfig);

  React.useEffect(() => {
    dispatch(
      setAction({
        dataTextValue: {
          text: (aggregatedOptions?.data as any)?.text?.text || dataTextValue.text || ''
        }
      })
    );
  }, []);

  const onChange = (params: InputChangeParams) => {
    dispatch(
      setAction({
        dataTextValue: {
          ...dataTextValue,
          text: params.val
        }
      })
    );
    setTimeout(() => dispatch(setDataAction({ dataType: 'text', skipNotification: true })), 1000);
  };
  return (
    <Accordion headerText="Add your text" selected={isOpen} onClick={() => toggleOpen()}>
      <TextWidget onChange={onChange} value={dataTextValue.text} rows={10} />
    </Accordion>
  );
};

export default WordcloudInput;
