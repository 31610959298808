import { all, put, takeLatest, call } from 'redux-saga/effects';
import { acceptInvitationAction } from '../actions/redeemInvitePage';
import ajax from '../../../utils/ajax';
import actionTypes from '../../../redux/actions/action-types';
import {
  postTeamAcceptInvitationNewaccountUsingToken,
  postTeamAcceptInvitationUsingToken
} from '../../../api/cloudApiGenerated';

function* acceptInvitation(action) {
  const { token, form, isNewUser, history, loggedIn, paths, onLoggedIn } = action.data;

  try {
    if (token) {
      const result = isNewUser
        ? yield call(postTeamAcceptInvitationNewaccountUsingToken, token, {
            username: form.name.value,
            password: form.password.value
          })
        : yield call(postTeamAcceptInvitationUsingToken, token);

      if (isNewUser && result?.token) {
        ajax.assignToken(result?.token);
        yield call(history.replace, paths.profileCharts);
        yield call(onLoggedIn);
        yield put(acceptInvitationAction({ accepted: true, error: null }));
      } else if (result?.ok) {
        const redirectPath = loggedIn ? paths.profileCharts : paths.login;
        yield call(history.push, { pathname: redirectPath, state: { overrideRedirect: true } });
        if (loggedIn) {
          yield call(onLoggedIn);
        }
        yield put(acceptInvitationAction({ accepted: true, error: null }));
      }
    }
  } catch (error) {
    const errorMessage = (error.message.join && error.message.join(' ')) || error.message;
    yield put(acceptInvitationAction({ accepted: false, error: errorMessage }));
  }
}

/** Watch functions */

export function* watchAcceptInvitation() {
  yield takeLatest(actionTypes.redeemInvite.acceptInvitation, acceptInvitation);
}

export default function* rootSaga() {
  yield all([watchAcceptInvitation()]);
}
