export default {
  series: 3,
  data: 'Country;Value\nus-ak;14\nus-al;4\nus-ar;10\nus-az;35\nus-ca;5\nus-co;4\nus-ct;20\nus-dc;10\nus-de;30\nus-fl;40\nus-ga;55\nus-hi;20\nus-ia;5\nus-id;30\nus-il;50\nus-in;40\nus-ks;44\nus-ky;50\nus-la;2\nus-ma;20\nus-md;100\nus-me;10\nus-mi;30\nus-mn;100\nus-mo;18\nus-ms;17\nus-mt;20\nus-nc;36\nus-nd;35\nus-ne;25\nus-nh;37\nus-nj;13\nus-nm;100\nus-nv;24\nus-ny;22\nus-oh;80\nus-ok;10\nus-or;11\nus-pa;72\nus-ri;40\nus-sc;14\nus-sd;29\nus-tn;50\nus-tx;40\nus-ut;70\nus-va;53\nus-vt;26\nus-wa;80\nus-wi;76\nus-wv;20\nus-wy;46',
  seriesMapping: [
    {
      'hc-key': 0,
      value: 1,
      x: 1,
      name: 1,
      z: 1
    },
    {
      'hc-key': 0,
      z: 1,
      x: 1,
      name: 1
    },
    {
      'hc-key': 0,
      value: 1,
      x: 1
    }
  ],
  assignDataFields: [
    {
      labels: 'A',
      value: 'B'
    },
    {
      labels: 'A',
      value: 'B'
    },
    {
      labels: 'A',
      value: 'B'
    }
  ],
  seriesOptions: [
    {
      joinBy: 0,
      type: 'map'
    },
    {
      joinBy: 'hc-key',
      type: 'mapbubble',
      includeInDataExport: false
    },
    {
      type: 'mapline',
      includeInDataExport: false,
      color: 'silver',
      showInLegend: false,
      enableMouseTracking: false
    }
  ],
  seriesTemplate: ['map', 'mapbubble', 'mapline']
};
