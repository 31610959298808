import { TemplateProps } from '../types';

const Choropleth: TemplateProps = {
  title: 'Choropleth',
  description: [
    'Choropleth Maps display divided geographical areas or regions that are coloured in relation to a data variable.',
    'This provides a way to visualise values over a geographical area, which can show variation across the displayed location.',
    'The Choropleth template displays a color axis represented by a gradient'
  ],
  constructor: 'Map',
  thumbnail: 'mapchoropleth.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {},
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    plotOptions: {
      series: {
        lineWidth: 1
      }
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },
    colorAxis: {
      type: 'logarithmic',
      minColor: '#EEEEFF',
      maxColor: '#000022',
      stops: [
        [0, '#EFEFFF'],
        [0.67, '#FF495E'],
        [1, '#000022']
      ]
    }
  },
  dataValidator: false,
  sampleSet: 'choropleth',
  isMap: true,
  advancedParent: 'Choropleth',
  defaultDataType: 'choropleth',
  basic: {
    order: 1,
    thumbnail: 'choropleth_map.svg',
    icon: 'Choropleth'
  }
};

export default Choropleth;
