import actionTypes from '../../../redux/actions/action-types';

export const fitMapToBoundingBoxAction = (data) => ({
  type: actionTypes.locationMap.fitMapToBoundingBox,
  data
});

export const fitMapToFeaturesAction = (data) => ({
  type: actionTypes.locationMap.fitMapToFeatures,
  data
});

export const addAnimationKeyFrameAction = (data) => ({
  type: actionTypes.locationMap.addAnimationKeyFrame,
  data
});

export const removeAnimationKeyFrameAction = (data) => ({
  type: actionTypes.locationMap.removeAnimationKeyFrame,
  data
});

export const updateAnimationKeyFrameAction = (data) => ({
  type: actionTypes.locationMap.updateAnimationKeyFrame,
  data
});

export const updateViewStateAction = (data) => ({
  type: actionTypes.locationMap.updateViewState,
  data
});

export const toggleViewBoxAction = (data) => ({
  type: actionTypes.locationMap.toggleViewBox,
  data
});

export const inlineUpdateLocationMapMarkerAction = (data) => ({
  type: actionTypes.locationMap.inlineUpdateMarker,
  data
});

export const UpdateLocationMapMarkerAction = (data) => ({
  type: actionTypes.locationMap.updateLocationMapMarker,
  data
});

export const updateStyledMapLayersAction = (data) => ({
  type: actionTypes.locationMap.updateStyledMapLayers,
  data
});

export const updateReferencePointResolutionAction = (data) => ({
  type: actionTypes.locationMap.updateReferencePointResolution,
  data
});

export const resetStyledMapLayersAction = (data) => ({
  type: actionTypes.locationMap.resetStyledMapLayers,
  data
});

export const updateLocationMapCustomizedAction = (data) => ({
  type: actionTypes.locationMap.updateCustomized,
  data
});

export const beginAddMarkerWorkflowAction = (data) => ({
  type: actionTypes.locationMap.beginAddMarkerWorkflow,
  data
});

export const endAddMarkerWorkflowAction = (data) => ({
  type: actionTypes.locationMap.endAddMarkerWorkflow,
  data
});

export const addLocationMapMarkerAction = (data) => ({
  type: actionTypes.locationMap.addLocationMapMarker,
  data
});

export const removeLocationMapMarkerAction = (data) => ({
  type: actionTypes.locationMap.removeLocationMapMarker,
  data
});

export const focusLocationMapMarkerAction = (data) => ({
  type: actionTypes.locationMap.focusLocationMapMarker,
  data
});
