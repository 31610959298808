import * as React from 'react';

type ExternalButtonProps = {
  text: string;
  link: string;
  className?: string;
  target?: string;
  rel?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const NavButton = (props: ExternalButtonProps) => {
  return (
    <a
      onClick={props.onClick}
      href={props.link}
      className={`relative flex items-center no-underline font-bold text-base text-ev-navy-blue bg-ev-light-orange hover:bg-ev-baby-blue-2 active:bg-ev-navy-blue-2 active:text-white rounded py-2 pl-6 ${props.className}`}
      target={props.target}
      rel={props.rel}
    >
      {props.text}
      <i className="absolute right-6 fa fa-chevron-right" />
    </a>
  );
};

export default NavButton;
