import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '../inputs/RadioGroup';
import ErrorMessage from '../message/ErrorMessage';
import { postTeam } from '../../api/cloudApiGenerated';
import Modal from 'shared/modal/Modal';
import InputWidget from 'shared/widgets/InputWidget';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';

class TeamModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.createNewTeam = this.createNewTeam.bind(this);

    this.state = {
      name: '',
      options: [
        { name: '0', value: 'Public' },
        { name: '1', value: 'Private' }
      ],
      selectValue: '0',
      newTeamError: ''
    };
  }

  onSearchInputChange(value) {
    this.setState({
      name: value.val
    });
  }

  createNewTeam() {
    if (this.state.name === '') {
      this.setState({ newTeamError: 'A team name is mandatory.' });
      return;
    }
    postTeam({ name: this.state.name, private: this.state.selectValue }).then(() => {
      this.props.reloadTeams(true);
      this.props.close();
    });
  }

  onChange(element) {
    this.setState({ selectValue: element });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.close}
        title={'Create new team'}
        width={'w-5/12 max-w-xl'}
        bg={'bg-ev-yellow-1'}
      >
        <ErrorMessage error={this.state.newTeamError} />
        <p className="">
          {' '}
          This will create a new organization with a new subscription. Any charts you create in this team cannot be
          transferred over to another team. If this does not sound like what you need, you may be looking to create a
          new subteam instead.
        </p>

        <div className="py-4">
          <InputWidget label="Name:" placeholder="Name" onChange={this.onSearchInputChange} value={this.state.name} />
        </div>
        <div className="radio-container">
          <RadioGroup
            options={this.state.options}
            groupName="private"
            checked={this.state.selectValue}
            onSelect={this.onChange}
          />
        </div>
        <hr />
        <div className="pt-6 flex gap-2">
          <PrimaryButton onClick={this.createNewTeam} text="Confirm" />
          <PrimaryButton onClick={this.props.close} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
      </Modal>
    );
  }
}

TeamModal.propTypes = {
  isOpen: PropTypes.bool,
  parentSelector: PropTypes.func,
  close: PropTypes.func,
  reloadTeams: PropTypes.func
};

export default TeamModal;
