import InputWidget from './InputWidget';
import ColorWidget from './ColorWidget';
import LinkWidget from './LinkWidget';
import FontPickerWidget from './FontPickerWidget';
import AlignWidget from './AlignWidget';
import HorizontalAlignWidget from './HorizontalAlignWidget';
import ChartArrayWidget from './../../containers/widgets/ChartArrayWidget';
import SelectWidget from './SelectWidget';
import FourInputWidget from './FourInputWidget';
import ColumnSelector from './../../containers/widgets/ColumnSelector';
import ToggleWidget from './ToggleWidget';
import TableChart from './../../containers/widgets/TableChart';
import TextWidget from './TextWidget';
import CheckboxWidget from './CheckboxWidget';
import RangeWidget from './RangeWidget';
import Notice from './Notice';
import DependencySelectWidget from './DependencySelectWidget';
import RichtextGroupWidget from './richtextgroup/RichtextGroupWidget';
import ArrayWidget from './ArrayWidget';
import TooltipWidget from './TooltipWidget';
import ColorStopsWidget from './ColorStopsWidget';
import HighlightsWidget from './HighlightsWidget';
import DropdownInputWidget from './DropdownInputWidget';
import IconWidget from './IconWidget';
import { WidgetType } from './../../pages/ChartEditorPage/meta/CustomizeOptions';
import PlacementWidget from './PlacementWidget';
import RichtextWidget from './richtextgroup/RichtextWidget';
import NumberInputWidget from './NumberInputWidget';
import CategoryStopsWidget from './CategoryStopsWidget';
import AccordionWidget from './AccordionWidget';
import PlacementAlignWidget from './PlacementAlignWidget';
import SeparatorWidget from './SeparatorWidget';
import AdvancedFontStyler from './AdvancedFontStyler';
import FontStyler from './FontStyler';
import ActionWidget from './ActionWidget';
import DynamicAccordionWidget from './DynamicAccordionWidget';
import NewIconWidget from './NewIconWidget';

const fieldsMap: Record<WidgetType, React.ElementType> = {
  default: InputWidget,
  string: InputWidget,
  number: NumberInputWidget,
  fontpicker: FontPickerWidget,
  link: LinkWidget,
  select: SelectWidget,
  positioner: AlignWidget,
  hpositioner: HorizontalAlignWidget,
  fourinputs: FourInputWidget,
  tablechart: TableChart,
  columnselector: ColumnSelector,
  chartarray: ChartArrayWidget,
  toggle: ToggleWidget,
  color: ColorWidget,
  'highcharts.colorstring': ColorWidget,
  'select<string>': SelectWidget,
  text: TextWidget,
  checkbox: CheckboxWidget,
  boolean: CheckboxWidget,
  range: RangeWidget,
  notice: Notice,
  dependencyselect: DependencySelectWidget,
  richtextgroup: RichtextGroupWidget,
  tooltiphelper: TooltipWidget,
  colorstops: ColorStopsWidget,
  colorcategories: CategoryStopsWidget,
  highlights: HighlightsWidget,
  dropdowninput: DropdownInputWidget,
  icon: IconWidget,
  newicon: NewIconWidget,
  accordion: AccordionWidget,
  placementalignwidget: PlacementAlignWidget,
  separator: SeparatorWidget,
  fontstyler: FontStyler,
  action: ActionWidget,
  dynamicaccordion: DynamicAccordionWidget,
  placementwidget: PlacementWidget,
  richtextwidget: RichtextWidget,
  'highcharts.cssobject': AdvancedFontStyler,

  'array<color>': ArrayWidget,
  'array<string>': ArrayWidget,
  'array<number>': ArrayWidget,
  'array<icon>': ArrayWidget,
  'array<accordion>': ArrayWidget,
  'array<checkbox>': ArrayWidget,
  array: ArrayWidget
};

export default fieldsMap;
