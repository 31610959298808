import actionTypes from '../../../redux/actions/action-types';

export const getChartsAction = (data) => ({
  type: actionTypes.projectsPage.getCharts,
  data
});

export const pageUpdateAction = (data) => ({
  type: actionTypes.projectsPage.pageUpdate,
  data
});

export const getTagsAction = (data) => ({
  type: actionTypes.projectsPage.getTags,
  data
});

export const setSelectFilterAction = (data) => ({
  type: actionTypes.projectsPage.setSelectFilter,
  data
});

export const getCreatorsAction = (data) => ({
  type: actionTypes.projectsPage.getCreators,
  data
});

export const setAction = (data) => ({
  type: actionTypes.projectsPage.set,
  data
});

export const deleteChartAction = (data) => ({
  type: actionTypes.projectsPage.deleteChart,
  data
});

export const recoverChartAction = (data) => ({
  type: actionTypes.projectsPage.recoverChart,
  data
});

export const convertToThemeAction = (data) => ({
  type: actionTypes.projectsPage.convertToTheme,
  data
});

export const duplicateToThemeAction = (data) => ({
  type: actionTypes.projectsPage.duplicateToTheme,
  data
});

export const duplicateChartAction = (data) => ({
  type: actionTypes.projectsPage.duplicateChart,
  data
});

export const SetChartMetaAction = (data) => ({
  type: actionTypes.projectsPage.setChartMeta,
  data
});

export const loadDataAction = (data) => ({
  type: actionTypes.projectConfig.loadData,
  data
});

export const deleteTagAction = (data) => ({
  type: actionTypes.projectsPage.deleteTag,
  data
});
