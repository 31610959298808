import initialState from '../../../redux/reducers/initial-state';
import actionTypes from '../../../redux/actions/action-types';

const tableEditorReducer = (state = initialState.tableEditorPage, action) => {
  switch (action.type) {
    case actionTypes.tableEditor.set:
      return {
        ...state,
        ...action.data
      };
    case actionTypes.tableEditor.updatePageTemplate:
      return {
        ...state,
        //pages: action.data.pages,
        customizedOptions: action.data.customizedOptions,
        templateOptions: action.data.templateOptions,
        dataOptions: action.data.dataOptions,
        templateUsed: action.data.type
      };
    case actionTypes.tableEditor.toggleChartModal:
      return {
        ...state,
        isChartModalOpen: !state.isChartModalOpen,
        parentToAttachChartTo: action.data
      };

    case actionTypes.tableEditor.openExportModal:
      return {
        ...state,
        showExportModal: true
      };

    case actionTypes.tableEditor.closeExportModal:
      return {
        ...state,
        showExportModal: false
      };
    case actionTypes.tableEditor.openPublishModal:
      return {
        ...state,
        showPublishPopup: true
      };
    case actionTypes.tableEditor.closePublishModal:
      return {
        ...state,
        showPublishPopup: false
      };
    case actionTypes.tableEditor.resetTableState:
      return initialState.tableEditorPage;
  }
  return state;
};

export default tableEditorReducer;
