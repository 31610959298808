import en from 'editor/en';
import { cloneDeep } from 'lodash';
import { redrawProjectAction } from 'pages/ChartEditorPage/actions/chartEditor';
import CustomizeOptions from 'pages/ChartEditorPage/meta/wizard/CustomizeOptions';
import LocationMapCustomizeOptions from 'pages/ChartEditorPage/meta/wizard/locationMap/CustomizeOptions';
import { parseCustomizeOptions } from 'pages/ChartEditorPage/utils/customizeHelper';
import LayoutCustomizeOptions from 'pages/LayoutEditorPage/meta/wizard/CustomizeOptions.js';
import TableCustomizeOptions from 'pages/TableEditorPage/meta/wizard/CustomizeOptions.js';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CustomizeSection from 'shared/editor/CustomizeSection';
import WizardProjectPreview from '../data/WizardProjectPreview';
import { CustomizeLocationMapSection } from '../editor/locationMap/CustomizeMapSection';
import WizardContainer from './WizardContainer';
import WizardLeftContainer from './WizardLeftContainer';
import WizardRightContainer from './WizardRightContainer';

export default function WizardCustomize() {
  const dispatch = useDispatch();
  const { type } = useSelector((state: RootState) => state.projectConfig);
  const { chartEditorPage, projectConfig, layoutEditorPage } = useSelector((state: RootState) => state);
  const { provider } = projectConfig;

  const options = {
    chart: provider && provider === 'locationMap' ? LocationMapCustomizeOptions : CustomizeOptions,
    table: TableCustomizeOptions,
    layout: LayoutCustomizeOptions
  };

  let customizeOptions = cloneDeep(options[type as keyof typeof options]);

  const isChart = type === 'chart';
  const isLocationMap = provider === 'locationMap';

  useEffect(() => {
    if (type === 'chart') dispatch(redrawProjectAction({ noAnimation: true }));
  }, []);

  if (isChart) {
    customizeOptions = parseCustomizeOptions(customizeOptions, chartEditorPage, projectConfig, layoutEditorPage);
  }
  return (
    <WizardContainer>
      <WizardLeftContainer
        text={
          isLocationMap ? 'Add text to your map' : 'Add text, text labels, highlight content and lines to your chart.'
        }
        header={'Add text'}
        useWhiteBackground={true}
      >
        {isLocationMap ? (
          <CustomizeLocationMapSection />
        ) : (
          <CustomizeSection customizeOptions={customizeOptions} lang={en} defaultTab={'option.cat.text'} />
        )}
      </WizardLeftContainer>

      <WizardRightContainer showToolbar={false}>
        <WizardProjectPreview showBottomBar={true} />
      </WizardRightContainer>
    </WizardContainer>
  );
}
