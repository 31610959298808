import React from 'react';
import { UnknownParamsFunction } from 'shared/types/commonPropTypes';

type PlusIconProps = {
  onClick: UnknownParamsFunction;
  className: string;
};

export default (props: PlusIconProps) => {
  return (
    <div
      className={`
          highed-field-array-add text-ev-navy-blue-2 bg-ev-grey top-0 fa fa-plus 
          text-stroke-1px border-4 border-solid border-white drop-shadow-color-widget-shadow
          hover:border-ev-baby-blue-2 ${props.className}
          `}
      onClick={props.onClick}
    />
  );
};
