import { isObj } from 'editor/core/highcharts-editor';

function forEachCell(startColumn, startRow, endColumn, endRow, fn) {
  for (let i = startRow; i <= endRow; i++) {
    for (let x = startColumn; x <= endColumn; x++) {
      fn(x, i);
    }
  }
}

export const getExtentCells = (activeBufferCell, activeSelectedBufferCell, dataOptions) => {
  let cells = {
    lowCell: [],
    highCell: []
  };

  if (activeBufferCell && activeSelectedBufferCell) {
    if (activeBufferCell[0] > activeSelectedBufferCell[0]) {
      cells.lowCell[0] = activeSelectedBufferCell[0]; //: activeBufferCell[0]
      cells.highCell[0] = activeBufferCell[0]; //: activeBufferCell[0]
    } else {
      cells.lowCell[0] = activeBufferCell[0];
      cells.highCell[0] = activeSelectedBufferCell[0];
    }

    if (activeBufferCell[1] > activeSelectedBufferCell[1]) {
      cells.lowCell[1] = activeSelectedBufferCell[1];
      cells.highCell[1] = activeBufferCell[1];
    } else {
      cells.lowCell[1] = activeBufferCell[1];
      cells.highCell[1] = activeSelectedBufferCell[1];
    }
  }

  let finding = true;

  while (finding) {
    finding = false;
    forEachCell(cells.lowCell[0], cells.lowCell[1], cells.highCell[0], cells.highCell[1], (x, i) => {
      const val = dataOptions[i][x];

      if (isObj(val)) {
        if ('rowSpan' in val) {
          if (val.rowSpan - 1 + i > cells.highCell[1]) {
            finding = true;
            cells.highCell[1] = val.rowSpan - 1 + i;
          }
        }

        if ('colSpan' in val) {
          if (val.colSpan - 1 + x > cells.highCell[0]) {
            finding = true;
            cells.highCell[0] = val.colSpan - 1 + x;
          }
        }

        if ('mergedCell' in val) {
          if (val.parentCell[0] < cells.lowCell[0]) {
            finding = true;
            cells.lowCell[0] = val.parentCell[0];
          }
          if (val.parentCell[1] < cells.lowCell[1]) {
            finding = true;
            cells.lowCell[1] = val.parentCell[1];
          }

          if (val.parentCell[0] > cells.highCell[0]) {
            finding = true;
            cells.highCell[0] = val.parentCell[0];
          }
          if (val.parentCell[1] > cells.highCell[1]) {
            finding = true;
            cells.highCell[1] = val.parentCell[1];
          }
        }
      }
    });
  }
  return cells;
};

export const getColumnsLength = (bufferDataOptions) => (bufferDataOptions ? bufferDataOptions[0].length : 0);
