import { LabelAnchor } from '@visual-elements/location-map';
import React from 'react';
import Accordian from 'shared/Accordion';
import IconButton from '../../buttons/IconButton';
import { ButtonColor } from '../../buttons/types/ButtonModels';

export type LabelPlacementWidgetProps = {
  anchorOptions: LabelAnchor[];
  anchor: LabelAnchor;
  onChange: (e: LabelAnchor) => void;
  className?: string;
};

const anchorDisplayName: Record<LabelAnchor, string> = {
  'bottom-left': 'Bottom left',
  'center-bottom-left': 'Bottom left',
  'bottom-right': 'Bottom right',
  'center-bottom-right': 'Bottom right',
  bottom: 'Bottom',
  'center-bottom': 'Bottom',
  center: 'Center',
  manual: 'Custom',
  left: 'Left',
  'center-left': 'Left',
  right: 'Right',
  'center-right': 'Right',
  top: 'Top',
  'center-top': 'Top',
  'center-top-left': 'Top Left',
  'top-left': 'Top Left',
  'center-top-right': 'Top right',
  'top-right': 'Top right'
};

const anchorIconName: Record<LabelAnchor, string> = {
  'bottom-left': 'arrow-down-left',
  'center-bottom-left': 'arrow-down-left',
  'bottom-right': 'arrow-down-right',
  'center-bottom-right': 'arrow-down-right',
  bottom: 'arrow-down',
  'center-bottom': 'arrow-down',
  center: 'arrow-down-left-and-arrow-up-right-to-center',
  left: 'arrow-left',
  'center-left': 'arrow-left',
  right: 'arrow-right',
  'center-right': 'arrow-right',
  top: 'arrow-up',
  'center-top': 'arrow-up',
  'top-left': 'arrow-up-left',
  'center-top-left': 'arrow-up-left',
  'top-right': 'arrow-up-right',
  'center-top-right': 'arrow-up-right',
  manual: 'TODO'
};

function getAnchorGrid(anchors: LabelAnchor[]): LabelAnchor[] {
  return [
    anchors.includes('center-top-left') ? 'center-top-left' : 'top-left',
    anchors.includes('center-top') ? 'center-top' : 'top',
    anchors.includes('center-top-right') ? 'center-top-right' : 'top-right',
    anchors.includes('center-left') ? 'center-left' : 'left',
    'center',
    anchors.includes('center-right') ? 'center-right' : 'right',
    anchors.includes('center-bottom-left') ? 'center-bottom-left' : 'bottom-left',
    anchors.includes('center-bottom') ? 'center-bottom' : 'bottom',
    anchors.includes('center-bottom-right') ? 'center-bottom-right' : 'bottom-right'
  ];
}

export default function LabelPlacementWidget({
  anchorOptions,
  anchor,
  className,
  onChange
}: LabelPlacementWidgetProps) {
  const iconClassName = 'w-6 h-6 mx-1.5 rounded';

  const anchorGrid = getAnchorGrid(anchorOptions);
  return (
    <Accordian
      key={'key'}
      defaultSelection={false}
      headerText={'Placement'}
      smallHeaderText={true}
      selectedChildren={`${anchorDisplayName[anchor]}`}
      useInternalSelect={true}
      accordionClasses={className ?? 'bg-white'}
    >
      <div className="pb-3 flex justify-center">
        <div className="grid grid-cols-3 grid-rows-3 gap-3">
          {anchorGrid.map((x, index) => {
            return (
              <IconButton
                key={index}
                icon={anchorIconName[x]}
                className={iconClassName}
                disabled={!anchorOptions.includes(x)}
                active={x === anchor}
                onClick={() => onChange(x)}
                buttonColor={ButtonColor.Grey}
              />
            );
          })}
        </div>
      </div>
    </Accordian>
  );
}
