import actionTypes from '../../../redux/actions/action-types';

export const getTeamKeysAction = (data) => ({
  type: actionTypes.apiPage.getTeamKeys,
  data,
});

export const updateKeyAction = (data) => ({
  type: actionTypes.apiPage.updateKey,
  data,
});

export const deleteKeysAction = (data) => ({
  type: actionTypes.apiPage.deleteKeys,
  data,
});

export const setTeamKeyAction = (data) => ({
  type: actionTypes.apiPage.setTeamKey,
  data,
});

export const setAction = (data) => ({
  type: actionTypes.apiPage.set,
  data,
});
