import classNames from 'classnames';
import * as React from 'react';
import Tooltip from 'shared/tooltip/Tooltip';

type FieldContainerProps = {
  children: React.ReactNode;
  className?: string;
  value?: string | React.ReactNode;
  label?: string | React.ReactNode;
  id?: string;
  onClick?: () => void;
  childrenClass?: string;
  labelClass?: string;
  disabled?: boolean;
  disabledText?: string;
  innerRef?: React.LegacyRef<HTMLDivElement>;
};

const FieldContainer = (props: FieldContainerProps) => {
  const { label, value, disabled, disabledText, innerRef } = props;
  const containerClasses = classNames(
    'relative w-full min-h-[2.5rem] py-2 pl-4 flex rounded items-center grow-0 ',
    props.className,
    {
      'bg-white': !props.className,
      'active:pointer-events-none focus:pointer-events-none opacity-60': disabled,
      // if the value is a complex structure we want it to be "sticked" to the props.children
      'pr-9': typeof value !== 'string',
      'pr-12': typeof value === 'string'
    }
  );

  const childrenClasses = classNames(
    {
      'absolute top-2 right-3': !props.childrenClass
    },
    props.childrenClass
  );

  const fieldContainer = (
    <div id={props?.id} className={containerClasses} onClick={props?.onClick} ref={innerRef}>
      <div className="flex w-full justify-between flex-wrap gap-1 items-center">
        {label && <span className={`font-bold ${props?.labelClass ?? ''}`}>{label}</span>}
        {value && <div className="text-ev-dark-purple font-bold">{value}</div>}
      </div>
      <div className={childrenClasses}>{props.children}</div>
    </div>
  );

  if (disabled) {
    return <Tooltip tippyProps={{ content: disabledText, placement: 'bottom' }}>{fieldContainer}</Tooltip>;
  }
  return fieldContainer;
};

export default FieldContainer;
