import { TemplateTypeProps } from '../../types';
import Area from './Area';
import Arearange from './Arearange';
import AreaWithLabels from './AreaWithLabels';
import Inverted from './Inverted';
import InvertedWithLabels from './InvertedWithLabels';
import NegativeColor from './NegativeColor';
import StepLine from './StepLine';

const AreaTemplate: TemplateTypeProps = {
  templates: {
    Area,
    Arearange,
    'Area with labels': AreaWithLabels,
    'Inverted area': Inverted,
    'Inverted with labels': InvertedWithLabels,
    'Negative color': NegativeColor,
    'Step line': StepLine
  }
};

export default AreaTemplate;
