import { connect } from 'react-redux';
import Buttons from '../components/Buttons';
import { setAction, toggleChartModalAction, addToGridAction } from '../actions/layoutEditor.js';
import { checkPermission } from '../../../utils/profileHelper';

const mapStateToProps = (state) => {
  return {
    pages: state.layoutEditorPage.pages || [],
    checkPermission: (permission) => checkPermission(permission, state.profile),
    resolution: state.layoutEditorPage.resolution,
    team: state.profile.team,
    division: state.profile.division,
    paths: state.app.paths || {},
    pages: state.layoutEditorPage.pages || [],
    storyId: state.layoutEditorPage.storyId,
    settings: state.layoutEditorPage.settings,
    templateUsed: state.layoutEditorPage.templateUsed
  };
};

const mapDispatchToProps = (dispatch) => ({
  set: (params) => dispatch(setAction(params)),
  toggleChartModal: (parent) => dispatch(toggleChartModalAction(parent)),
  addToGrid: (pages, settings, type, section) => dispatch(addToGridAction({ pages, settings, type, section }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);
