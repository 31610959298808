import { download } from 'editor/core/highcharts-editor';
import { getMapCodeValueIndex } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Accordion from 'shared/Accordion';
import CopyButton from 'shared/inputs/CopyButton';
import CustomSelect, { SelectItem } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import TextWidget from 'shared/widgets/TextWidget';
import { getCSV, getEmbeddableHTML, getEmbeddableJSON, getEmbeddableSVG, stringifyFn } from '../utils/exportHelper';

const Options = [
  {
    label: 'HTML',
    value: 'html'
  },
  {
    label: 'JSON',
    value: 'json'
  },
  {
    label: 'SVG',
    value: 'svg'
  },
  {
    label: 'Data ( , )',
    value: 'csv'
  },
  {
    label: 'Data ( ; )',
    value: 'csv-semi'
  }
];

const labelMap: Record<string, string> = {
  'Data ( , )': 'Data',
  'Data ( ; )': 'Data'
};

const optionMap = {
  highcharts: ['html', 'json', 'svg', 'csv', 'csv-semi'],
  locationMap: ['html', 'csv', 'csv-semi']
};

const ExportOptions = () => {
  const projectConfig = useSelector((state: RootState) => state.projectConfig);
  const chartConfig = useSelector((state: RootState) => state.chartEditorPage);
  const packagesPage = useSelector((state: RootState) => state.packagesPage);
  const { subscription, team } = useSelector((state: RootState) => state.profile);
  const [options, setOptions] = useState(Options);
  const [selectValue, setSelectValue] = useState({
    label: 'HTML',
    value: 'html'
  });
  const { seriesAssigns, provider } = projectConfig;
  const { initedEditor } = chartConfig;
  const [textValue, setTextValue] = useState('');
  const noPermissionText = 'Exporting is only available to members on a paid plan.';
  const { mapValueIndex } = getMapCodeValueIndex(seriesAssigns);

  useEffect(() => {
    const selectedOptions = optionMap[provider];
    setOptions(Options.filter((opt) => selectedOptions.includes(opt.value)));
  }, []);

  useEffect(() => {
    if (initedEditor) setTextValue(getEmbeddableHTML(projectConfig, chartConfig, packagesPage, team));
  }, [initedEditor]);

  const getText = (value: string) => {
    switch (value) {
      case 'html':
        return getEmbeddableHTML(projectConfig, chartConfig, packagesPage, team);
      case 'json':
        return stringifyFn(getEmbeddableJSON(projectConfig), null);
      case 'svg':
        return getEmbeddableSVG(chartConfig);
      case 'csv':
        return getCSV(projectConfig.dataOptions, ',', chartConfig.isMap, mapValueIndex);
      case 'csv-semi':
        return getCSV(projectConfig.dataOptions, ';', chartConfig.isMap, mapValueIndex);
      default:
        return getEmbeddableHTML(projectConfig, chartConfig, packagesPage, team);
    }
  };

  const onDownload = (type: string) => {
    switch (type) {
      case 'html':
        return download('chart.html', textValue, 'text/html');
      case 'json':
        return download('chart.json', textValue, 'application/json');
      case 'svg':
        return download('chart.svg', textValue, 'image/svg+xml');
      case 'csv':
      case 'csv-semi':
        return download('data.csv', textValue, 'application/csv');
      default:
        return download('data.csv', textValue, 'application/csv');
    }
  };

  const onChangeSelect = (newValue: SelectItem | null) => {
    setSelectValue(newValue as SelectItem);
    const text = getText(newValue?.value ?? '') ?? '';
    setTextValue(text.toString());
  };

  const onDownloadClick = () => {
    onDownload(selectValue?.value);
  };

  const noPermission = !subscription || subscription?.id <= 1;
  const parsedText = noPermission ? noPermissionText : textValue;

  return (
    <Accordion headerText="Export options" useInternalSelect={true} defaultSelection={false}>
      <div className="flex gap-2 flex-col">
        <div className="flex gap-2">
          <CustomSelect
            options={options}
            value={selectValue as SelectItem}
            onChange={onChangeSelect}
            label={'Type'}
            styles={{ ...editorStyles }}
            containerClasses={'w-full'}
          />

          <CopyButton
            value={parsedText}
            noText={true}
            states={{
              Ready: { icon: 'download', text: 'Download' },
              Pending: { text: 'Downloading' },
              Done: { text: 'Downloaded' }
            }}
            useTooltip={true}
            onClick={onDownloadClick}
            locked={noPermission}
          />
          <CopyButton value={parsedText} noText={true} useTooltip={true} locked={noPermission} />
        </div>

        <div className="pb-4">
          <div className={'text-ev-navy-blue font-bold mb-2'}>{`${
            labelMap[selectValue?.label] ?? selectValue?.label
          } preview`}</div>
          <div className="h-[160px]">
            <TextWidget
              value={parsedText}
              className={'h-full w-full'}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={() => {}}
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </Accordion>
  );
};

export default ExportOptions;
