import classNames from 'classnames';
import React from 'react';
import PageDimmer from 'shared/wizard/components/PageDimmer';

type SideContainerProps = {
  isOpen: boolean;
  header: string;
  children: React.ReactNode;
  onClose: () => void;
};

const SideContainer = ({ isOpen, onClose, header, children }: SideContainerProps) => {
  const classes = classNames(
    'space-y-2 fixed right-0 z-[3] bg-white w-full ev-sm:w-96 transition-all overflow-auto overflow-x-hidden p-6 ev-scrollbar',
    {
      'top-[66px] h-[calc(100vh-66px)]': isOpen,
      'top-[-700px] max-h-0 h-0': !isOpen
    }
  );

  const handleOnClose = () => {
    onClose();
  };

  return (
    <>
      {isOpen && <PageDimmer onClick={handleOnClose} className={'opacity-0 z-[2]'} />}
      <div className="ev-scrollbar">
        <div className={classes}>
          <div className="pb-2 w-full flex justify-between">
            <h4 className="text-black font-bold">{header}</h4>
            <span onClick={handleOnClose} className="cursor-pointer text-black underline">
              Close
            </span>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default SideContainer;
