export default {
  series: 1,
  data: 'A;B;C\na;0;100\nb;100;200\nc;20;50\nd;30;50',
  assignDataFields: [
    {
      labels: 'A',
      low: 'B',
      high: 'C',
    },
  ],
  seriesMapping: [
    {
      x: 0,
      low: 1,
      high: 2,
    },
  ],
};
