import { TemplateProps } from '../../types';

const StepLine: TemplateProps = {
  title: 'Step line',
  description: '',
  constructor: '',
  thumbnail: 'abutix.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      area: {
        step: 'left'
      }
    }
  },
  sampleSet: 'area',
  advancedParent: 'Area',
  dataValidator: false,
  thumbnail_url: 'https://app.everviz.com/static/thumbnails/abutix.svg',
  name: 'Step line'
};

export default StepLine;
