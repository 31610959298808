import classNames from 'classnames';
import * as React from 'react';
import { SelectItem } from 'shared/inputs/CustomSelect';
import InputError from 'shared/message/InputError';
import { InputChangeHandler } from 'shared/types/commonPropTypes';
import { BillingField } from '../types/plansModels';

type CheckoutFieldProps = {
  field: BillingField;
  onChange?: InputChangeHandler | React.ChangeEventHandler<HTMLSelectElement>;
  select?: boolean;
  selectOptions?: SelectItem[];
  recurlyContainer?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  type?: React.HTMLInputTypeAttribute;
};

const CheckoutField = (props: CheckoutFieldProps) => {
  const { field } = props;
  const { error } = field;
  const inputClasses = classNames('highed-field-input', {
    'highed-field-input--error': error
  });
  const recurlyDivClasses = classNames('recurly-field', {
    'has-error': error
  });
  const labelClasses = classNames('font-bold mb-2 h-5', {
    'text-ev-navy-blue': !error,
    'text-ev-error': error
  });

  if (props.recurlyContainer) {
    return (
      <div className="mt-2">
        <div className={labelClasses}>{field.label}</div>
        <div data-recurly={field.name} className={recurlyDivClasses} />
        <InputError error={error} />
      </div>
    );
  }

  if (props.select) {
    return (
      <div className="mt-2">
        <div className={labelClasses}>{field.label}</div>
        <select
          className={inputClasses}
          data-recurly={'country'}
          name={field.name}
          onChange={props.onChange as React.ChangeEventHandler<HTMLSelectElement>}
          value={field.value || ''}
        >
          {props.selectOptions?.map((item, i) => {
            return (
              <option key={i} value={item.value}>
                {' '}
                {item.label}{' '}
              </option>
            );
          })}
        </select>
        <InputError error={error} />
      </div>
    );
  }

  return (
    <div className="mt-2">
      <div className={labelClasses}>{field.label}</div>
      <input
        className={inputClasses}
        data-recurly={field.name}
        value={(field.value ?? '') as string}
        placeholder={field.placeholder}
        name={field.name}
        onChange={props.onChange as InputChangeHandler}
        onKeyDown={props.onKeyDown}
        readOnly={props.readOnly}
        type={props.type}
      />
      <InputError error={error} />
    </div>
  );
};

export default CheckoutField;
