import React from 'react';
import BorderAllEdgesIcon from './BorderAllEdgesIcon';
import BorderAllIcon from './BorderAllIcon';
import BorderBottomIcon from './BorderBottomIcon';
import BorderClearIcon from './BorderClearIcon';
import BorderLeftIcon from './BorderLeftIcon';
import BorderRightIcon from './BorderRightIcon';
import BorderTopIcon from './BorderTopIcon';
import FillIcon from './FillIcon';
import BorderStyleIcon from './BorderStyleIcon';
import BorderStyleMenu from './BorderStyleMenu';
import ColorPickerModal from 'shared/modal/ColorPickerModal';

export default class BorderIconsToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.fillClick = this.fillClick.bind(this);
    this.styleClick = this.styleClick.bind(this);
    this.onColorChange = this.onColorChange.bind(this);

    this.state = {
      fillColor: '#000000',
      showStyles: false,
      showColorPickerModal: false,
      position: { xPos: 0, yPos: 0 }
    };
  }

  componentDidMount() {
    document.querySelector('#icon-toolbar').focus();
  }

  onBlur() {
    if (!this.state.showColorPickerModal) {
      this.props.closeBorderIconsToolbar();
    }
  }

  fillClick(e) {
    this.setState({
      position: { xPos: e.clientX, yPos: e.clientY },
      showColorPickerModal: true
    });
  }

  onColorChange(color) {
    this.props.setBorderStyle(
      this.props.dataOptions,
      this.props.activeBufferCell,
      this.props.activeSelectedBufferCell,
      {
        Color: color
      }
    );
    this.setState({
      fillColor: color
    });
  }

  styleClick() {
    this.setState({
      showStyles: !this.state.showStyles
    });
  }

  render() {
    return (
      <div id="icon-toolbar" onBlur={this.onBlur} tabIndex={0}>
        <div className="border-icons">
          <span className="inline-block" onClick={this.props.setBordersAllEdgesClick} data-type="edges">
            <BorderAllEdgesIcon />
          </span>
          <span className="inline-block" onClick={this.props.setBordersAllEdgesClick} data-type="all">
            <BorderAllIcon />
          </span>
          <span className="inline-block" onClick={this.props.setBordersAllEdgesClick} data-type="bottom">
            <BorderBottomIcon />
          </span>
          <span className="inline-block" onClick={this.props.setBordersAllEdgesClick} data-type="left">
            <BorderLeftIcon />
          </span>
          <span className="inline-block" onClick={this.props.setBordersAllEdgesClick} data-type="right">
            <BorderRightIcon />
          </span>
          <span className="inline-block" onClick={this.props.setBordersAllEdgesClick} data-type="top">
            <BorderTopIcon />
          </span>
          <span className="inline-block" onClick={this.props.setBordersAllEdgesClick} data-type="clear">
            <BorderClearIcon />
          </span>
        </div>
        <div className="border-separator" />
        <div className="border-extra-icons">
          <span onClick={this.styleClick}>
            <BorderStyleIcon />
            <BorderStyleMenu
              showStyles={this.state.showStyles}
              styleClick={this.styleClick}
              dataOptions={this.props.dataOptions}
              activeBufferCell={this.props.activeBufferCell}
              activeSelectedBufferCell={this.props.activeSelectedBufferCell}
              setBorderStyle={this.props.setBorderStyle}
            />
          </span>
          <span onClick={this.fillClick}>
            <FillIcon />
          </span>
        </div>
        {this.state.showColorPickerModal && (
          <ColorPickerModal
            value={this.state.fillColor}
            isOpen={this.state.showColorPickerModal}
            onClose={() => this.setState({ showColorPickerModal: false })}
            onChange={this.onColorChange}
            showGradientColor={false}
            colors={[]}
            position={this.state.position}
          />
        )}
      </div>
    );
  }
}
