import { redrawProjectAction } from 'pages/ChartEditorPage/actions/chartEditor';
import TableComponent from 'pages/TableEditorPage/components/TableComponent';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor, ButtonType } from '../../../shared/buttons/types/ButtonModels';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import { switchProjectTypeAction } from '../actions/ThemeEditorPage';
import HighchartsPreview from '../../../shared/wizard/data/HighchartsPreview';
import SelectType from './SelectType';

export default function Preview() {
  const { view } = useSelector((state: RootState) => state.companyThemeEditorPage);
  const dispatch = useDispatch();

  const switchView = (view: string) => {
    dispatch(switchProjectTypeAction({ view }));
  };

  useEffect(() => {
    if (view === 'Charts' || view === 'Maps') {
      dispatch(redrawProjectAction({ noAnimation: false }));
    }
  }, [view]);

  return (
    <div className="px-4 pb-4 pt-4 bg-white">
      <div className="pb-2">
        <div className="text-left">
          <h5 className="text-ev-navy-blue-2 font-bold text-base mt-0">Quick preview</h5>
        </div>
        <div className="flex gap gap-1 justify-end flex-col ev-md:flex-row ev-md:h-10">
          <div className="flex gap gap-1 justify-end mr-auto">
            {['Charts', 'Maps', 'Tables'].map((projectType) => {
              return (
                <div className="mt-auto" key={projectType}>
                  <PrimaryButton
                    buttonColor={view === projectType ? ButtonColor.NavyBlue : ButtonColor.White}
                    buttonType={ButtonType.Info}
                    text={projectType}
                    onClick={() => switchView(projectType)}
                    className={`text-sm font-medium ${view === projectType ? '' : 'underline'}`}
                  />
                </div>
              );
            })}
          </div>
          <SelectType />
        </div>
      </div>

      <div className={'chart-container master-theme-editor border-2'}>
        {(view === 'Charts' || view === 'Maps') && <HighchartsPreview />}
        {view === 'Tables' && (
          <div className="story table-editor">
            <TableComponent />
          </div>
        )}
      </div>
    </div>
  );
}
