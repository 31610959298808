import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export type EditorLabelProps = {
  className?: string;
};
export default function EditorLabel({ className }: EditorLabelProps) {
  const { showWizard, isCompanyThemeEditor, templateMeta, type } = useSelector(
    (state: RootState) => state.projectConfig
  );
  const { isMap, isThemeEditor } = useSelector((state: RootState) => state.chartEditorPage);

  const typeMap: Record<string, string> = {
    layout: 'Layout',
    table: 'Table'
  };

  const templateTitle = templateMeta?.length > 0 ? (templateMeta as any)?.[0]?.templateTitle : '';
  const isChart = type === 'chart';

  let editorType;
  if (templateTitle) editorType = 'Chart';
  if (isCompanyThemeEditor) editorType = 'Company theme';
  else {
    if (isChart && isMap && !isThemeEditor) editorType = 'Map';
    else if (isChart && isThemeEditor) editorType = 'Theme';
    else if (type in typeMap) {
      editorType = typeMap[type];
    }
  }

  const chartType = templateTitle && editorType ? `${templateTitle} ${editorType.toLowerCase()}` : '';
  const prettyChartType = [...new Set(chartType.split(' '))].join(' ');

  return (
    <div className={'text-ev-dark-purple font-bold justify-start align-bottom w-max flex ' + className}>
      {editorType && (
        <div className="text-base mx-2">
          {editorType} {showWizard ? 'wizard' : 'editor'}
        </div>
      )}
      {chartType && (editorType === 'Map' || editorType === 'Chart') && (
        <div className="text-base mx-2">
          <span className="border-l border-ev-light-purple h-7" />
          <span className="mx-2">{prettyChartType}</span>
        </div>
      )}
    </div>
  );
}
