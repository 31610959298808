import { uuid } from 'editor/core/highcharts-editor';
import { cloneDeep, isArray, isFunction } from 'lodash';
import { toCSV, toMapCSV } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import { getChart } from 'pages/ChartEditorPage/meta/highchartsHelper';
import { RootState } from 'redux/store';
import { LocationMapExportMappingOptions, getLocationMapPropsForExporting } from './locationMapMapper';
import { getPlugin } from 'shared/editor/meta/plugins';

type ExportProjectConfigProps = Partial<RootState['projectConfig']>;
type ExportChartConfigProps = Partial<RootState['chartEditorPage']>;
type ExportPackageConfigProps = Partial<RootState['packagesPage']>;

export const stringifyFn = (obj: any, tabs: any) => {
  return JSON.stringify(
    obj,
    function (key, value) {
      if (isFunction(value)) {
        return value.toString();
      }

      return value;
    },
    tabs
  );
};

/** Get embeddable SVG
 *  @returns {string} - the result from `Highcharts.Chart.getSVG()`
 */
export const getEmbeddableSVG = (chartConfig: ExportChartConfigProps) => {
  const chart = getChart(chartConfig.editorChartIndex ?? 0);
  return isFunction(chart?.getSVG) ? chart?.getSVG() : '';
};

/** Get embeddable JSON
 *  This returns the merged chart, with both customized options
 *  and options set indirectly through templates.
 *  @returns {object} - the chart object
 */
export const getEmbeddableJSON = (projectConfig: ExportProjectConfigProps) => {
  const { aggregatedOptions } = projectConfig;

  const r: any = cloneDeep(aggregatedOptions);

  // This should be part of the series
  if (r && isArray(r.series)) {
    r.series = r.series.map(function (s: any) {
      delete s.data;
      return s;
    });
  }

  return r;
};

const getCDNScript = (cdnIncludes: string[], isHC: boolean, windowObjectName: string) => `
var files = ${JSON.stringify(cdnIncludes)},
loaded = 0;

if (typeof window["${windowObjectName}"] === "undefined") {
  window.${windowObjectName} = {
    ondone: [cl],
    hasWrapped: false,
    hasLoaded: false
  };
  include(files[0]);
} else {
  if (window.${windowObjectName}.hasLoaded) {
    cl();
  } else {
    window.${windowObjectName}.ondone.push(cl);
  }
}

function isScriptAlreadyIncluded(src) {
  var scripts = document.getElementsByTagName("script");
  for (var i = 0; i < scripts.length; i++) {
    if (scripts[i].hasAttribute("src")) {
      if ((scripts[i].getAttribute("src") || "").indexOf(src) >= 0 
      ${
        isHC
          ? '|| (scripts[i].getAttribute("src") === "http://code.highcharts.com/highcharts.js" && src === "https://code.highcharts.com/stock/highstock.js")'
          : ''
      }
      ) {
        return true;
      }
    }
  }
  return false;
}

function check() {
  if (loaded === files.length) {
    for (var i = 0; i < window.${windowObjectName}.ondone.length; i++) {
      try {
        window.${windowObjectName}.ondone[i]();
      } catch (e) {
        console.error(e);
      }
    }
  }
  window.${windowObjectName}.hasLoaded = true;
}

function include(script) {
  function next() {
    ++loaded;
    if (loaded < files.length) {
      include(files[loaded]);
    }
    check();
  }
  if (isScriptAlreadyIncluded(script)) {
    return next();
  }
  var sc = document.createElement("script");
  sc.src = script;
  sc.type = "text/javascript";
  sc.onload = function() {
    next();
  };
  document.head.appendChild(sc);
}

function each(a, fn) {
  if (typeof a.forEach !== "undefined") {
    a.forEach(fn);
  } else {
    for (var i = 0; i < a.length; i++) {
      if (fn) {
        fn(a[i]);
      }
    }
  }
}

var inc = {},
  incl = [];
each(document.querySelectorAll("script"), function(t) {
  inc[t.src.substr(0, t.src.indexOf("?"))] = 1;
});
`;

const getCustomFontsScript = (cssModules: ExportProjectConfigProps['cssModules']) =>
  `
    var cssModules = ${JSON.stringify(cssModules)};
    cssModules.forEach(function(cssModule) {
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = cssModule;
      document.head.appendChild(link);
    });
`;

/** Get embeddable JavaScript for package charts
 *  @param id {string} - the ID of the node to attach the chart to
 *  @returns {string} - a string containing JavaScript to reproduce the chart
 */
export const getEmbeddablePackageChartJS = (
  projectConfig: ExportProjectConfigProps,
  _chartConfig: ExportChartConfigProps,
  id: string,
  packagesPage: ExportPackageConfigProps,
  team: { id: number }
) => {
  const { themeData, packageProject } = packagesPage;
  const { cssModules } = projectConfig;

  const cdnIncludes = [packageProject.script_src];
  const cdnIncludesScript = getCDNScript(cdnIncludes, true, 'EvervizPackagesModule');
  const chartConstr = packageProject.constr;
  const modules = cloneDeep(cssModules ?? []);
  modules.push('https://app.everviz.com/static/fonts/gordita/style.css');

  return `<div id="everviz-package-${id}"></div>
  <script>
    (function(){ 
      ${cdnIncludesScript}

      ${getCustomFontsScript(modules)}

      function cl() {
        if(typeof window["${chartConstr}"] !== "undefined"){
          var options = { 
            teamId: ${team.id},
            dimensions: {
              type: 'auto'
            }
          };

          ${themeData ? `options.theme = stringifyFn(getEmbeddableJSON(${themeData}), null)` : ''}

          const element = document.querySelector('#everviz-package-${id}');

          if (element){
            const region = element.getAttribute('data-region');
            const constituency = element.getAttribute('data-constituency');
            if (region) options.region = region;
            if (constituency) options.constituencyId = constituency;
          }      

          new ${chartConstr}("everviz-package-${id}", options);
        }
      }
    })();
  </script>`;
};

/** Get embeddable JavaScript
 *  @param id {string} - the ID of the node to attach the chart to
 *  @returns {string} - a string containing JavaScript to reproduce the chart
 */
export const getEmbeddableHCJavaScript = (
  projectConfig: ExportProjectConfigProps,
  chartConfig: ExportChartConfigProps,
  id: string
) => {
  const { constr, customCode, customCodeStr } = chartConfig;
  const { customizedOptions, cssModules, aggregatedOptions, plugins } = projectConfig;
  let pluginsScripts: string[] = [];

  const cdnIncludes = [
    'https://code.highcharts.com/stock/highstock.js',
    'https://code.highcharts.com/highcharts-more.js',
    'https://code.highcharts.com/highcharts-3d.js',
    'https://code.highcharts.com/modules/data.js',
    'https://code.highcharts.com/modules/exporting.js',
    'https://code.highcharts.com/modules/funnel.js',
    'https://code.highcharts.com/modules/annotations.js',
    'https://code.highcharts.com/stock/modules/annotations-advanced.js',
    'https://code.highcharts.com/stock/modules/stock-tools.js',
    'https://code.highcharts.com/modules/accessibility.js',
    'https://code.highcharts.com/modules/solid-gauge.js'
  ];

  if (aggregatedOptions?.colorAxis) {
    cdnIncludes.push('https://code.highcharts.com/modules/coloraxis.js');
  }

  const pluginsName = Object.entries(plugins ?? {})
    .filter(([, value]) => value === true)
    .map(([key]) => key);

  pluginsName.forEach((key) => {
    const option = getPlugin(key);
    if (option && option.scripts) {
      pluginsScripts = [...pluginsScripts, ...option.scripts];
    }
  });

  pluginsScripts.forEach((script) =>
    cdnIncludes.push(script.startsWith('modules/') ? `https://code.highcharts.com/${script}` : script)
  );

  const cdnIncludesScript = getCDNScript(cdnIncludes, true, 'HighchartsEditor');

  id = id || '';

  const chartConstr = constr ?? 'Chart';
  return `<div id="${id}"></div>
  <script>
    (function(){ 
      ${cdnIncludesScript}

      ${getCustomFontsScript(cssModules)}

      function cl() {
        if(typeof window["Highcharts"] !== "undefined"){
          ${!customizedOptions.lang ? '' : `Highcharts.setOptions({lang: ${JSON.stringify(customizedOptions.lang)} });`}
          var options=${stringifyFn(getEmbeddableJSON(projectConfig), null)};
          ${isFunction(customCode) ? customCodeStr : ''}
          new Highcharts.${chartConstr}("${id}", options);
        }
      }
    })();
  </script>`;
};

export const getLocationMapHTML = (projectConfig: ExportProjectConfigProps, _: ExportChartConfigProps, id: string) => {
  const cdnIncludes = [
    `${window.hcconfig.resourcesOrigin.frontend}/resources/location-map/bundle/EvervizLocationMap.v${projectConfig.aggregatedOptions.version}.iife.js`
  ];

  const cdnIncludesScript = getCDNScript(cdnIncludes, false, 'EvervizLocationMapModule');

  id = id || '';
  if (projectConfig.provider !== 'locationMap') {
    throw new Error('Cannot export to location map if provider is not location map');
  }

  if (!projectConfig.locationMapOptions) throw new Error('Location map options must be defined');

  const mappingOptions: LocationMapExportMappingOptions = {
    resolution: {
      type: 'fill'
    }
  };

  const options = getLocationMapPropsForExporting(getEmbeddableJSON(projectConfig), mappingOptions);

  return `<div id="${id}" style="height: 500px;"></div>
  <script>
    (function(){ 
      ${cdnIncludesScript}

      ${getCustomFontsScript(projectConfig.cssModules)}

      function cl() {
        if(typeof window["EvervizLocationMap"] !== "undefined"){
          var options=${stringifyFn(options, null)};
          new EvervizLocationMap("${id}", options);
        }
      }
    })();
  </script>`;
};

const getHTMLMap = {
  highcharts: getEmbeddableHCJavaScript,
  locationMap: getLocationMapHTML,
  packages: getEmbeddablePackageChartJS
};

/** Get embeddable HTML
 *  @returns {string} - a string of embeddable HTML
 */
export const getEmbeddableHTML = (
  projectConfig: ExportProjectConfigProps,
  chartConfig: ExportChartConfigProps,
  packagesPage: ExportPackageConfigProps,
  team: { id: number }
) => {
  const id = 'everviz-' + uuid();
  let provider = projectConfig.provider as any; //TODO fix this so packages is included here too.
  if (projectConfig.inPackagesMode) provider = 'packages';
  const getHTML = getHTMLMap[(provider ?? 'highcharts') as keyof typeof getHTMLMap];
  return getHTML(projectConfig, chartConfig, id, packagesPage, team);
};

/** Download CSV
 *  @param data {array} - the chart data
 *  @param data {string} - optional delimiter (default: ',')
 *  @param data {boolean} - If chart is map, need to do different csv parsing.
 *  @returns {null}
 */
export const getCSV = (dataOptions: any, delimiter?: string, isMap?: boolean, mapValueIndex?: number) => {
  const csv = isMap ? toMapCSV(dataOptions, mapValueIndex, delimiter ?? ',') : toCSV(dataOptions, delimiter ?? ',');
  return csv;
};
