type ProjectionCRS = {
  label: string;
  value: string;
};

export const customProjectionCRS: ProjectionCRS[] = [
  { label: '(Default) WGS 84 Web Mercator', value: 'EPSG:3857' },
  { label: 'WGS 84 (Latitude/Longitude)', value: 'EPSG:4326' },
  { label: 'NAD83 (North American Datum)', value: 'EPSG:4269' },
  { label: 'NAD83 / UTM zone 10N', value: 'EPSG:26910' },
  { label: 'NAD83 / UTM zone 11N', value: 'EPSG:26911' },
  { label: 'ETRS89 / UTM zone 32N', value: 'EPSG:25832' },
  { label: 'ETRS89 / UTM zone 33N', value: 'EPSG:25833' },
  { label: 'DHDN / 3-degree Gauss-Krüger zone 3', value: 'EPSG:31493' },
  { label: 'DHDN / 3-degree Gauss-Krüger zone 4', value: 'EPSG:31494' },
  { label: 'Amersfoort / RD New', value: 'EPSG:28992' },
  { label: 'NAD27 (North American Datum 1927)', value: 'EPSG:4267' },
  { label: 'British National Grid', value: 'EPSG:27700' },
  { label: 'ED50 / UTM zone 32N', value: 'EPSG:23032' },
  { label: 'ED50 / UTM zone 33N', value: 'EPSG:23033' },
  { label: 'Tokyo / Japan Plane Rectangular CS I', value: 'EPSG:30161' },
  { label: 'Tokyo / Japan Plane Rectangular CS II', value: 'EPSG:30162' },
  { label: 'China Geodetic Coordinate System 2000', value: 'EPSG:4490' },
  { label: 'Hong Kong 1980 Grid', value: 'EPSG:2326' },
  { label: 'NZGD2000 / New Zealand Transverse Mercator 2000', value: 'EPSG:2193' },
  { label: 'Australian Geodetic Datum 1966 (AGD66) / AMG zone 56', value: 'EPSG:20256' },
  { label: 'GDA94 / MGA zone 56', value: 'EPSG:28356' },
  { label: 'SAD69 / UTM zone 21S', value: 'EPSG:29191' },
  { label: 'SIRGAS 2000 / UTM zone 25S', value: 'EPSG:31985' },
  { label: 'NAD83(CSRS) / UTM zone 20N', value: 'EPSG:2950' },
  { label: 'RD / Netherlands', value: 'EPSG:28992' },
  { label: 'Swiss CH1903+ / LV95', value: 'EPSG:2056' },
  { label: 'Belgium Lambert 72', value: 'EPSG:31370' },
  { label: 'Mexico ITRF2008 / LCC', value: 'EPSG:6367' },
  { label: 'South African Hartebeesthoek94 / Lo29', value: 'EPSG:2048' },
  { label: 'Egypt 1907 / Red Belt', value: 'EPSG:22992' },
  { label: 'Pulkovo 1942 / Gauss-Krüger zone 5', value: 'EPSG:28405' },
  { label: 'S-JTSK (Ferro) / Krovak', value: 'EPSG:2065' },
  { label: 'ETRS89 / TM35FIN (Finland)', value: 'EPSG:3067' },
  { label: 'Norway 1994 / UTM zone 33N', value: 'EPSG:25833' },
  { label: 'Sweden SWEREF99 TM', value: 'EPSG:3006' },
  { label: 'Iceland ISN93 / Lambert 93', value: 'EPSG:3057' },
  { label: 'ED50 / TM33 (Europe)', value: 'EPSG:23033' },
  { label: 'Luxembourg 1930 / Gauss', value: 'EPSG:2169' },
  { label: 'Portugal Datum 73 / Portuguese Grid', value: 'EPSG:27493' },
  { label: 'Italy Gauss-Boaga West', value: 'EPSG:3003' },
  { label: 'Argentina POSGAR 94 / UTM zone 20S', value: 'EPSG:22196' },
  { label: 'Brazil SIRGAS 2000 / UTM zone 23S', value: 'EPSG:31983' },
  { label: 'Russia Pulkovo 1995 / Gauss-Krüger zone 6', value: 'EPSG:20006' },
  { label: 'South Korea (Korean 1985 / West Belt)', value: 'EPSG:2096' },
  { label: 'Uruguay SIRGAS 2000 / UTM 22S', value: 'EPSG:5382' },
  { label: 'Indonesia Batavia / TM3 zone 54.1', value: 'EPSG:2307' },
  { label: 'Vietnam VN-2000 / UTM zone 48N', value: 'EPSG:3405' },
  { label: 'Philippines Luzon 1911 / Philippine Zone III', value: 'EPSG:25392' },
  { label: 'Fiji 1986 / Fiji Map Grid', value: 'EPSG:3460' },
  { label: 'Peru 1979 / UTM zone 18S', value: 'EPSG:24818' },
  { label: 'Panama Colon 1911 / Panama Polyconic', value: 'EPSG:5456' }
];
