import React from 'react';
import Modal from 'react-modal';

class CMSModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.close();
  }

  render() {
    const { open } = this.props;

    return (
      <Modal
        className="hc-modal-content hc-viz-picker-modal"
        overlayClassName="hc-modal-overlay"
        isOpen={open}
        onRequestClose={this.closeModal}
        appElement={document.querySelector('.page-container')}
        contentLabel=""
      >
        <div className="viz-picker-modal-header">
          Welcome to everviz
          <div className="viz-picker-modal-close" onClick={this.closeModal}>
            <i className="fa fa-times" />
          </div>
        </div>
        <div className="viz-picker-modal-text-container">
          <div className="viz-picker-modal-text-header">
            You are now ready to edit <br /> your data visualization!
          </div>
          <div className="viz-picker-modal-text">
            After publishing use the refresh button in the Wordpress <br /> container to see your updated project.
          </div>
        </div>
      </Modal>
    );
  }
}

export default CMSModal;
