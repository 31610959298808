import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import ProfilePageSectionContainer from './ProfilePageSectionContainer';
import ProfilePageSectionDescription from './ProfilePageSectionDescription';

type ProfilePageContainerProps = {
  className?: string;
  children?: ReactNode;
  header?: string | ReactNode;
  headerContent?: string | ReactNode;
  title: string;
  description?: string | ReactNode;
};
export default function ProfilePageContainer({
  className,
  children,
  header,
  headerContent,
  title,
  description
}: ProfilePageContainerProps) {
  const headerContainerClasses = classNames('pt-8 ev-sm:pt-14 ev-md:pt-16 pb-4 ev-md:pb-6', {
    'ev-md:flex justify-between': headerContent
  });

  return (
    <div className={`mb-24 ${className}`}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <ProfilePageSectionContainer>
        {header && (
          <div className={headerContainerClasses}>
            <h1 className="pl-0 font-bold m-0 flex justify-between items-end flex-wrap">{header}</h1>
            {headerContent}
          </div>
        )}

        {description && <ProfilePageSectionDescription>{description}</ProfilePageSectionDescription>}
      </ProfilePageSectionContainer>

      {children}
    </div>
  );
}
