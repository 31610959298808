import { TemplateTypeProps } from './../types';
import AreaStock from './AreaStock';
import Candlestick from './Candlestick';
import Column from './Column';
import OHLC from './OHLC';
import Stock from './Stock';

const StockTemplate: TemplateTypeProps = {
  templates: {
    'Area stock': AreaStock,
    Stock,
    Candlestick: Candlestick,
    Column,
    OHLC
  },
  description: ['Stock charts are used to display financial data.'],
  samples: []
};

export default StockTemplate;
