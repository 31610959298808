import React from 'react';
import { Range, getTrackBackground } from 'react-range';

export default (props) => {
  let { value, option } = props;
  const onChange = (e) => {
    props.onChange({ val: e[0] });
  };

  if (value === 'null') value = 0;
  const val = [Math.max(value || 0, option.custom.minValue)];

  return (
    <div className="range-slider">
      <Range
        step={option.custom.step}
        min={option.custom.minValue}
        max={option.custom.maxValue}
        values={val}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                backgroundColor: 'grey',
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: val,
                  colors: ['#548BF4', '#ccc'],
                  min: option.custom.minValue,
                  max: option.custom.maxValue
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '20px',
              width: '20px',
              backgroundColor: '#FFF',
              display: 'flex',
              borderRadius: '100px',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div
              style={{
                height: '4px',
                width: '2px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC'
              }}
            />
          </div>
        )}
      />
    </div>
  );
};
