import {
  dashStyleOptions,
  yAxisTitleAlignValue,
  axisTypeOptions,
  alignValue,
  yAxisTitleAlignValueWizard,
  colorAxisTypeOptions
} from './highed.format.options';

export default {
  'highcharts.cursorvalue': {
    values: [
      {
        label: 'Alias',
        value: 'alias'
      },
      {
        label: 'Scroll',
        value: 'all-scroll'
      },
      {
        label: 'Auto',
        value: 'auto'
      },
      {
        label: 'Cell',
        value: 'cell'
      },
      {
        label: 'Column resize',
        value: 'col-resize'
      },
      {
        label: 'Context menu',
        value: 'context-menu'
      },
      {
        label: 'Copy',
        value: 'copy'
      },
      {
        label: 'Crosshair',
        value: 'crosshair'
      },
      {
        label: 'Default',
        value: 'default'
      },
      {
        label: 'Right arrow',
        value: 'e-resize'
      },
      {
        label: 'Arrow',
        value: 'ew-resize'
      },
      {
        label: 'Grab',
        value: 'grab'
      },
      {
        label: 'Grabbing',
        value: 'grabbing'
      },
      {
        label: 'Help',
        value: 'help'
      },
      {
        label: 'Move',
        value: 'move'
      },
      {
        label: 'No drop',
        value: 'no-drop'
      },
      {
        label: 'None',
        value: 'none'
      },
      {
        label: 'Not allowed',
        value: 'not-allowed'
      },
      {
        label: 'Pointer',
        value: 'pointer'
      },
      {
        label: 'Progress',
        value: 'progress'
      },
      {
        label: 'Row resize',
        value: 'row-resize'
      },
      {
        label: 'Text',
        value: 'text'
      },
      {
        label: 'Vertical text',
        value: 'vertical-text'
      },
      {
        label: 'Wait',
        value: 'wait'
      },
      {
        label: 'Zoom in',
        value: 'zoom-in'
      },
      {
        label: 'Zoom out',
        value: 'zoom-out'
      }
    ],
    type: 'options'
  },
  'highcharts.dashstylevalue': {
    values: dashStyleOptions,
    type: 'select'
  },
  'highcharts.axistitlealignvalue': {
    values: yAxisTitleAlignValue,
    type: 'select'
  },
  'highcharts.axistypevalue': {
    values: [...axisTypeOptions, { label: 'Heatmap', value: 'heatmap' }],
    type: 'select'
  },
  'highcharts.axisextremestriggervalue': {
    values: [
      {
        label: 'Navigator',
        value: 'navigator'
      },
      {
        label: 'Pan',
        value: 'pan'
      },
      {
        label: 'Range selector button',
        value: 'rangeSelectorButton'
      },
      {
        label: 'Range selector input',
        value: 'rangeSelectorInput'
      },
      {
        label: 'Scroll bar',
        value: 'scrollbar'
      },
      {
        label: 'Traverse up button',
        value: 'traverseUpButton'
      },
      {
        label: 'Zoom',
        value: 'zoom'
      }
    ],
    type: 'select'
  },
  'highcharts.datalabelsoverflowvalue': {
    values: [
      {
        label: 'Allow',
        value: 'allow'
      },
      {
        label: 'Justify',
        value: 'justify'
      }
    ],
    type: 'select'
  },
  'highcharts.seriesstatevalue': {
    values: [
      {
        label: 'Hover',
        value: 'hover'
      },
      {
        label: 'Inactive',
        value: 'inactive'
      },
      {
        label: 'Normal',
        value: 'normal'
      },
      {
        label: 'Select',
        value: 'select'
      }
    ],
    type: 'select'
  },
  'highcharts.alignvalue': {
    values: alignValue,
    type: 'select'
  },
  'highcharts.verticalalignvalue': {
    values: yAxisTitleAlignValueWizard,
    type: 'select'
  },
  'highcharts.tooltipshapevalue': {
    values: [
      {
        label: 'Callout',
        value: 'callout'
      },
      {
        label: 'Circle',
        value: 'circle'
      },
      {
        label: 'Square',
        value: 'square'
      }
    ],
    type: 'select'
  },
  'highcharts.coloraxistypevalue': {
    values: colorAxisTypeOptions,
    type: 'select'
  },
  'highcharts.exportingmimetypevalue': {
    values: [
      {
        label: 'PNG',
        value: 'image/png'
      },
      {
        label: 'JPEG',
        value: 'image/jpeg'
      },
      {
        label: 'SVG',
        value: 'image/svg+xml'
      }
    ],
    type: 'select'
  },
  'highcharts.buttonrelativetovalue': {
    values: [
      {
        label: 'Plot Box',
        value: 'plotBox'
      },
      {
        label: 'Spacing Box',
        value: 'spacingBox'
      }
    ],
    type: 'select'
  },
  'highcharts.rangeselectorbuttontypevalue': {
    values: [
      {
        label: '',
        value: ''
      },
      {
        label: 'Millisecond',
        value: 'millisecond'
      },
      {
        label: 'Second',
        value: 'second'
      },
      {
        label: 'Minute',
        value: 'minute'
      },
      {
        label: 'Hour',
        value: 'hour'
      },
      {
        label: 'Day',
        value: 'day'
      },
      {
        label: 'Week',
        value: 'week'
      },
      {
        label: 'Month',
        value: 'month'
      },
      {
        label: 'Year',
        value: 'year'
      },
      {
        label: 'Year to date',
        value: 'ytd'
      },
      {
        label: 'All',
        value: 'all'
      }
    ],
    type: 'select'
  },
  'highcharts.colorstring': {
    type: 'color'
  },
  'highcharts.colortype': {
    type: 'color'
  },
  'array<Highcharts.ColorString>': {
    type: 'array<color>'
  },
  boolean: {
    type: 'checkbox'
  }
};
