export type PositionProps = {
  xPos: number;
  yPos: number;
};

const PIXEL_BIAS = 20;

export const calculateModalPosition = (
  setPosition: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
  position: PositionProps,
  className: string,
  transition = false
) => {
  const elementsWithClass = document.getElementsByClassName(className);
  setTimeout(() => {
    if (elementsWithClass.length > 0) {
      const containerHeight = elementsWithClass[0].clientHeight;
      let yPos: number;
      if (position.yPos + containerHeight > window.innerHeight) {
        yPos = position.yPos + (window.innerHeight - (position.yPos + containerHeight)) - PIXEL_BIAS;
      } else {
        yPos = position.yPos;
      }

      setPosition((prevStyle) => ({
        ...prevStyle,
        left: `${position.xPos}px`,
        top: `${yPos}px`,
        transition: transition ? 'top 0.3s ease, left 0.3s ease' : ''
      }));
    }
  }, 10);
};
