import { PlanFeature, SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';

type PlansCardsDataType = {
  [SubscriptionPlans in string]: {
    description: string;
    features: PlanFeature[];
  };
};

export const plansCardsData: PlansCardsDataType = {
  [SubscriptionPlans.Free]: {
    description: 'Try everviz for free',
    features: [
      { text: 'Limited number of chart types' },
      { text: '<b>1</b> user' },
      { text: 'Up to <b>5</b> projects' },
      { text: 'Community support', link: 'https://www.everviz.com/support-options' },
      { text: '"Powered by everviz" attribution' },
      { text: 'Publish Online' }
    ]
  },
  [SubscriptionPlans.Starter]: {
    description: 'For businesses just getting started',
    features: [
      { text: 'All our standard features', anchorLink: '#standard-features' },
      { text: '<b>5</b> users' },
      { text: 'Up to <b>100</b> projects' },
      { text: 'Email support', link: 'https://www.everviz.com/support-options' },
      { text: 'No &lsquo;everviz&rsquo; attribution' },
      { text: 'Publish Online, PDF/Print or Social Media' },
      { text: 'Download as:<br />SVG, PNG, PDF, JPEG' }
    ]
  },
  [SubscriptionPlans.Business]: {
    description: 'For businesses investing in branded visualizations',
    features: [
      { text: 'All our standard features', anchorLink: '#standard-features' },
      { text: '<b>15</b> users' },
      { text: 'Up to <b>500</b> projects' },
      { text: 'Email support', link: 'https://www.everviz.com/support-options' },
      { text: 'No &lsquo;everviz&rsquo; attribution' },
      { text: 'Publish Online, PDF/Print or Social Media' },
      { text: 'Download as:<br />SVG, PNG, PDF, JPEG, GIF, MP4, WebP, HTML, JSON' },
      { text: 'Create custom themes', anchorLink: '#branding' }
    ]
  },
  [SubscriptionPlans.ProTrial]: {
    description: '14 Days Trial of PRO Subscription',
    features: [
      { text: '<b>10</b> users' },
      { text: 'Up to <b>500</b> projects' },
      { text: '1 company theme', anchorLink: '#branding' },
      { text: 'Publishing options: Online, PDF/Print or Social Media' },
      { text: 'Download options: SVG, PNG, PDF, JPEG, GIF, MP4, WebP' },
      { text: 'No attribution' },
      { text: 'Email support', link: 'https://www.everviz.com/support-options' }
    ]
  },
  [SubscriptionPlans.Pro]: {
    description: 'An empowering solution for media professionals and marketing teams',
    features: [
      { text: '<b>10</b> users' },
      { text: 'Up to <b>500</b> projects' },
      { text: '1 company theme', anchorLink: '#branding' },
      { text: 'Publishing options: Online, PDF/Print or Social Media' },
      { text: 'Download options: SVG, PNG, PDF, JPEG, GIF, MP4, WebP' },
      { text: 'No attribution' },
      { text: 'Email support', link: 'https://www.everviz.com/support-options' }
    ]
  },
  [SubscriptionPlans.Enterprise]: {
    description: 'Contact us for a tailored quote, crafting personalized value for your needs',
    features: [
      { text: 'Number of users as required' },
      { text: 'Unlimited projects' },
      { text: 'Unlimited company themes', anchorLink: '#branding' },
      { text: 'Expanded publishing options: Live Video and Self hosting' },
      { text: 'Expanded download options: HTML, JSON, MOV w/ Alpha' },
      { text: 'Access to data connectors for finance, election and more' },
      { text: 'No attribution' },
      { text: 'Customer Success Manager and prioritized support', link: 'https://www.everviz.com/support-options' },
      { text: 'Service Level Agreement (SLA)' },
      { text: 'Single Sign-On (SSO)' }
    ]
  }
};
