import classNames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import CloseIcon from 'static/icons/close.svg';

type ModalProps = {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: (event?: React.MouseEvent | React.KeyboardEvent) => void;
  width?: string;
  bg?: string;
  title?: string | React.ReactNode;
  headerSubtext?: string;
  bodyClass?: string;
  allowOverflow?: boolean;
  error?: string;
  id?: string;
  overlayClass?: string;
  showCloseButton?: boolean;
};

// Hacky fix for purging css
// ev-sm:w-7/12

export default function Modal(props: ModalProps) {
  const { showCloseButton = true } = props;

  const modalClasses = classNames('pointer-events-auto z-10 mx-10 pb-6 rounded-xl shadow-xl overflow-auto', props.bg, {
    ['w-7/12']: !props.width,
    ['bg-white']: !props.bg,
    [`ev-sm:${props.width}`]: props.width
  });

  const bodyClasses = classNames('modal__container max-h-[75vh]', props.bodyClass, {
    ['p-8']: !props.bodyClass,
    ['overflow-y-auto']: !props.allowOverflow
  });

  const overlayClasses = classNames('fixed inset-0 w-screen h-screen', props.overlayClass);

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick={true}
      appElement={document.querySelector('.page-container') as HTMLElement}
      overlayClassName={overlayClasses}
      className={'h-full pointer-events-none flex items-center justify-center'}
      closeTimeoutMS={150}
      id={props.id}
    >
      <div className={modalClasses} id="modal-content">
        {props.title && (
          <div className="bg-ev-navy-blue-2 rounded-t-xl p-8 pb-6 items-center text-white text-base flex justify-between">
            <div>
              <span className="font-bold text-base pt-1">{props.title}</span>
              {props.headerSubtext && props.headerSubtext}
            </div>

            {showCloseButton && (
              <SvgIconButton
                Icon={CloseIcon}
                onClick={props.onClose}
                buttonColor={ButtonColor.NavyBlue}
                width={16}
                height={16}
              />
            )}
          </div>
        )}

        <div className={bodyClasses}>
          {props.children}
          {props.error && (
            <div className="pt-2 font-bold text-ev-error">
              <span>
                <i className="pr-2 fa fa-info-circle" />
              </span>
              {props.error}
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
