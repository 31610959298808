import { TemplateProps } from '../../types';

const Donut3DWithLegend: TemplateProps = {
  title: '3D Donut w/ legend',
  description: '',
  constructor: '',
  thumbnail: 'oriwyb.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0
      },
      polar: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        depth: 35,
        cursor: 'pointer',
        showInLegend: true,
        innerSize: '60%'
      },
      series: {
        dataLabels: {
          enabled: false
        }
      }
    }
  },
  sampleSet: 'pie',
  dataValidator: false,
  advanced: {
    name: '3D + legend'
  },
  advancedParent: 'Donut'
};

export default Donut3DWithLegend;
