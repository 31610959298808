import React, { ReactNode } from 'react';
import ArrowHeadLeft from 'static/icons/arrow-head-left.svg';
import ArrowHeadRight from 'static/icons/arrow-head-right.svg';

type TablePaginationProps = {
  pageCount: number;
  currentPage: number;
  onSelectPage: (selectedPage: number) => void;
};

type TablePaginationIconProps = {
  className: string;
  icon: ReactNode;
  onClick: () => void;
};

const TablePaginationIcon = ({ className, icon: Icon, onClick }: TablePaginationIconProps) => (
  <a
    className={`${className} cursor-pointer h-[26px] w-[26px] flex justify-center items-center border border-ev-navy-blue rounded-full bg-white`}
    onClick={onClick}
  >
    {Icon}
  </a>
);

const TablePagination = ({ pageCount, currentPage, onSelectPage }: TablePaginationProps) => (
  <div className="flex items-center">
    {currentPage !== 1 && (
      <TablePaginationIcon className="mr-2" icon={<ArrowHeadLeft />} onClick={() => onSelectPage(currentPage - 1)} />
    )}
    <span className="font-bold">
      {currentPage}/{pageCount}
    </span>
    {pageCount !== currentPage && (
      <TablePaginationIcon className="ml-2" icon={<ArrowHeadRight />} onClick={() => onSelectPage(currentPage + 1)} />
    )}
  </div>
);

export default TablePagination;
