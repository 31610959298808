import initialState from '../../../redux/reducers/initial-state';
import actionTypes from '../../../redux/actions/action-types';

const themesPageReducer = (state = initialState.themesPage, action) => {
  switch (action.type) {
    case actionTypes.themesPage.set:
      return {
        ...state,
        ...action.data,
      };
  }
  return state;
};

export default themesPageReducer;
