import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import { getFormattedDivisions } from 'redux/selectors/team';
import { updateUsersGroupAction } from './actions/teamMembersAction';
import { checkPermission, loadGroups } from 'utils/profileHelper';
import UsersSection from './components/UsersSection';
import InvitationsSection from './components/InvitationsSection';
import UnsavedChangesBar from 'shared/UnsavedChangesBar';
import UsersDeleteModal from './components/UsersDeleteModal';
import UsersLimitModal from './components/UsersLimitModal';
import { UsersSectionRefType } from './types/teamPageModels';
import HeaderActions from './components/HeaderActions';
import UsersSubteamAddModal from './components/UsersSubteamAddModal';
import UserSubteamRemoveModal from './components/UserSubteamRemoveModal';
import EditSubteamModal from './components/EditSubteamModal';
import { differenceWith, isEqual } from 'lodash';

const TeamPage = () => {
  const { user, team, subscription, groups, division, divisions } = useSelector((state: RootState) => state.profile);
  const { allMembers, members, subteamMembers, activeDivision, unsavedChanges, membersQuery } = useSelector(
    (state: RootState) => state.teamMembersPage
  );

  const [formattedDivisions, setFormattedDivisions] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);

  const usersSectionRef = useRef<UsersSectionRefType>(null);
  const dispatch = useDispatch();

  const ownerId = (team as any)?.owner_user ?? '';
  const isSubteam = !!activeDivision?.value;

  const handleFormattedDivisionsSet = () => {
    setFormattedDivisions(getFormattedDivisions({ divisions }));
  };

  useEffect(() => {
    loadGroups(team);
  }, [team]);

  useEffect(() => {
    handleFormattedDivisionsSet();
  }, [divisions]);

  useEffect(() => {
    if (user && team) {
      setHasPermission(checkPermission('team_management'));
    }
  }, [user, team]);

  const onUsersSectionChangesReset = () => {
    if (usersSectionRef?.current?.resetUsersTableRows) {
      usersSectionRef.current.resetUsersTableRows();
    }
  };

  const onUsersSectionChangesSave = () => {
    if (usersSectionRef?.current?.usersTableRows) {
      const tempMembers = isSubteam ? subteamMembers.data : members.data;
      const formattedMembers = tempMembers.map((member: any) => ({
        ...member,
        group_id: member.id === ownerId ? 'Owner' : member.group_id
      }));
      const changedRows = differenceWith(usersSectionRef.current.usersTableRows, formattedMembers, isEqual);

      dispatch(
        updateUsersGroupAction({
          team,
          divisions,
          rows: changedRows,
          activeDivisionId: activeDivision.value,
          membersQuery,
          page: isSubteam ? subteamMembers.page : members.page,
          pageSize: isSubteam ? subteamMembers.pageSize : members.pageSize
        })
      );
    }
  };

  return (
    <>
      <ProfilePageContainer
        title="Team"
        header="Team"
        className="min-h-[calc(100vh_-_230px)]"
        headerContent={
          <HeaderActions
            hasPermission={hasPermission}
            usersMax={subscription?.users_max}
            membersLength={members?.length ?? 0}
          />
        }
        description={`Collaborate with your co-workers on projects and design-templates for consistent branding. Invite
        collaborators to ${team?.name} below`}
      >
        <EditSubteamModal />
        <UsersDeleteModal />
        <UsersLimitModal />
        <UsersSubteamAddModal />
        <UserSubteamRemoveModal />
        <UsersSection
          ref={usersSectionRef}
          team={team}
          groups={groups}
          hasPermission={hasPermission}
          formattedDivisions={formattedDivisions}
          activeDivision={activeDivision}
          allMembers={allMembers}
          members={members}
          subteamMembers={subteamMembers}
          unsavedChanges={unsavedChanges}
        />
        {hasPermission && (
          <InvitationsSection
            team={team}
            subscription={subscription}
            groups={groups}
            hasPermission={hasPermission}
            division={division}
          />
        )}
      </ProfilePageContainer>
      {unsavedChanges && (
        <UnsavedChangesBar onChangesReset={onUsersSectionChangesReset} onChangesSave={onUsersSectionChangesSave} />
      )}
    </>
  );
};

export default TeamPage;
