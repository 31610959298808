export default {
  series: 6,
  data: 'series;name;value\nSeries 1;Germany;767.1\nSeries 1;Croatia;20.7\nSeries 1;Belgium;97.2\nSeries 1;Czech Republic;111.7\nSeries 1;Netherlands;158.1\nSeries 1;Spain;241.6\nSeries 1;Ukraine;249.1\nSeries 1;Poland;298.1\nSeries 1;Slovakia;34.7\nSeries 1;Ireland;34.6\nSeries 2;Senegal;8.2\nSeries 2;Cameroon;9.2\nSeries 2;Zimbabwe;13.1\nSeries 2;Ghana;14.1\nSeries 2;Kenya;14.1\nSeries 2;Sudan;17.3\nSeries 2;Nigeria;93.9\nSeries 5;Australia;409.4\nSeries 5;New Zealand;34.1\nSeries 5;Papua New Guinea;7.1\nSeries 4;Costa Rica;7.6\nSeries 4;Honduras;8.4\nSeries 4;Jamaica;8.3\nSeries 4;Panama;10.2\nSeries 6;Trinidad and Tobago;34\nSeries 6;Ecuador;43\nSeries 6;Brazil;501.1\nSeries 6;Argentina;199\nSeries 6;Venezuela;195.2\nSeries 3;Nepal;6.5\nSeries 3;Georgia;6.5\nSeries 3;Brunei Darussalam;7.4\nSeries 3;Kyrgyzstan;7.4\nSeries 3;Afghanistan;7.9\nSeries 3;Myanmar;9.1\nSeries 3;Israel;64.8\nSeries 3;Russia;1766.4\nSeries 3;Iran;618.2\nSeries 3;Korea;610.1',
  seriesMapping: [
    {
      name: 0,
      value: 1
    },
    {
      name: 2,
      value: 3
    },
    {
      name: 4,
      value: 5
    },
    {
      name: 6,
      value: 7
    },
    {
      name: 8,
      value: 9
    },
    {
      name: 10,
      value: 11
    }
  ],
  assignDataFields: [
    {
      series: 'A',
      name: 'B',
      value: 'C'
    }
  ]
};
