import classNames from 'classnames';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';
import { removeHighchartsStyling } from 'pages/ChartEditorPage/utils/removeHighchartsStyling';
import { setResolutionAction } from 'pages/LayoutEditorPage/actions/layoutEditor';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import ResolutionSection, { Dimensions } from 'shared/editor/generic/ResolutionSection';
import { onSave, onSaveAndPublish } from 'shared/editor/publishHelper';
import PermissionModal from 'shared/modal/PermissionModal';
import useToggle from 'shared/utils/useToggle';
import { checkCreatePermission, checkPermission } from 'utils/profileHelper';
import PrimaryButton from '../../buttons/PrimaryButton';
import { DynamicResolutionContainer } from '../../editor/generic/DynamicResolutionContainer';
import PublishSummary from '../components/PublishSummary';
import UnpublishModal from '../components/UnpublishModal';
import UnsavedChangesModal from '../components/UnsavedChangesModal';
import ChannelSection from '../components/publishChannel/ChannelSection';
import WizardProjectPreview from '../data/WizardProjectPreview';
import WizardContainer from './WizardContainer';
import WizardLeftContainer from './WizardLeftContainer';
import WizardRightContainer from './WizardRightContainer';

type PublishExportProps = {
  closeWizard: () => void;
};

export default function PublishExport(props: PublishExportProps) {
  const dispatch = useDispatch();
  const channelSectionRef = useRef<HTMLDivElement>(null);
  const chartPreviewParentRef = useRef<HTMLDivElement>(null);
  const [showPermissionModal, toggleShowPermissionModal] = useToggle();

  const { resolution } = useSelector((state: RootState) => state.layoutEditorPage);
  const { published, type } = useSelector((state: RootState) => state.projectConfig);
  const chart = useChart();
  const hasPublishPermission = checkPermission('publish');
  const hasCreatePermission = checkCreatePermission(type);

  const isChart = type === 'chart';

  useEffect(() => {
    // If the chart fails, Highcharts will add a display: none to the container
    // This function then stops Handsontable from displaying the data table.
    removeHighchartsStyling(chart);
  }, [chart]);

  useEffect(() => {
    if (published) channelSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [published]);

  const handlePublish = () => {
    if (!hasPublishPermission || !hasCreatePermission) {
      toggleShowPermissionModal(true);
    } else {
      onSaveAndPublish(type, dispatch);
    }
  };

  const handleSave = () => {
    if (!hasCreatePermission) {
      toggleShowPermissionModal(true);
    } else {
      onSave(type, false, dispatch);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setResolutionAction({ height: null, width: null }));
    };
  }, []);

  const setResolutionHandler = (res: Dimensions) => dispatch(setResolutionAction(res));

  const containerClassName = classNames('ev-sm-max:h-full transition-all', {
    'ev-sm:flex': isChart
  });

  return (
    <WizardContainer>
      <UnsavedChangesModal type={type} />
      <UnpublishModal />
      {showPermissionModal && <PermissionModal isOpen={showPermissionModal} onClose={toggleShowPermissionModal} />}

      <WizardLeftContainer header="Publish & Export" useWhiteBackground={true} contentClass="ev-md:pr-2">
        <>
          <PublishSummary onPublish={handlePublish} onSave={handleSave} />
          <div ref={channelSectionRef} />
          <ChannelSection onPublish={handlePublish} />
        </>
      </WizardLeftContainer>

      <WizardRightContainer isMap={true}>
        <div ref={chartPreviewParentRef} className="relative ev-sm-max:h-full ev-sm:max-h-[80%] ev-sm:overflow-auto ">
          <DynamicResolutionContainer
            parentRef={chartPreviewParentRef}
            wantedResolution={resolution}
            className={containerClassName}
          >
            <WizardProjectPreview />
          </DynamicResolutionContainer>
        </div>
        <div className="mt-2 ev-sm-max:hidden">
          <div className="flex justify-between items-center mb-2">
            <p className="text-ev-navy-blue font-medium text-sm m-0">
              For more adjustments and advanced features, continue in our editor.
            </p>
            {<ResolutionSection hideManual={true} setResolution={setResolutionHandler} />}
          </div>
          <PrimaryButton
            text="Continue in Editor"
            onClick={props.closeWizard}
            buttonColor={ButtonColor.Yellow}
            className="w-48"
          />
        </div>
      </WizardRightContainer>
    </WizardContainer>
  );
}
