import React, { ReactNode } from 'react';
import ProfilePageSectionDescription from './ProfilePageSectionDescription';
import Subheader from './Subheader';
import classNames from 'classnames';

type ProfilePageWhiteSectionContainerProps = {
  id?: string;
  className?: string;
  children?: ReactNode;
  header?: string;
  headerNode?: ReactNode;
  description?: string;
};
export default function ProfilePageWhiteSectionContainer(props: ProfilePageWhiteSectionContainerProps) {
  const containerClasses = classNames(`p-4 mb-6 px-8 ev-sm:px-20 ${props.className}`, {
    'bg-white': props.id !== 'plans-container'
  });

  return (
    <div id={props.id} className={containerClasses}>
      {props.header && <Subheader>{props.header}</Subheader>}
      {props.headerNode && <>{props.headerNode}</>}
      {props.description && <ProfilePageSectionDescription>{props.description}</ProfilePageSectionDescription>}
      {props.children}
    </div>
  );
}
