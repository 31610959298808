import { snakeCase } from 'lodash';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import { UpdateChartFunction } from 'shared/types/commonPropTypes';
import { langMap } from 'shared/utils/LangMap';
import { generateQueryString } from 'shared/utils/editorHelper';
import Accordion from '../Accordion';
import BuildInputs from '../widgets/BuildInputs';

type CustomizeSectionProps = {
  customizeOptions: Record<string, any>;
  lang: any;
  defaultTab: string;
  baseColor?: string;
  container?: React.ElementType;
  editorConfig?: any;
  overrideOnChange?: UpdateChartFunction;
};

export default function CustomizeSection(props: CustomizeSectionProps) {
  const { search } = useLocation();
  const { customizeOptions, editorConfig, defaultTab, baseColor, lang } = props;
  const { activeBasicTab, tab, showWizard, type } = useSelector((state: RootState) => state.projectConfig);
  const { isMap, chosenWizardTemplate } = useSelector((state: RootState) => state.chartEditorPage);
  const typeParam = new URLSearchParams(search).get('type');
  const templateTitle = (chosenWizardTemplate as any).title;
  const templateKey = templateTitle ? snakeCase(templateTitle) : null;
  const template = typeParam !== templateKey ? templateKey : typeParam;
  const Container = props.container ?? Accordion;
  const dispatch = useDispatch();

  useEffect(() => {
    changeActiveBasicTab(defaultTab);
    return () => {
      switchActiveBasicTab('');
    };
  }, []);

  useEffect(() => {
    if (type) {
      const lang = langMap[type];
      const contextualKey = snakeCase(lang.entries[activeBasicTab]);
      const queryString = generateQueryString(qs, tab, type, showWizard, isMap, template, contextualKey);
      window.history.replaceState(null, '', window.location.pathname + queryString);
      if ((window as any).Beacon) (window as any).Beacon('suggest');
    }
  }, [activeBasicTab]);

  const changeActiveBasicTab = (tab: string) => dispatch(setAction({ activeBasicTab: tab }));
  const switchActiveBasicTab = (tab: string) => {
    if (tab === activeBasicTab) tab = '';
    changeActiveBasicTab(tab);
  };

  useEffect(() => {
    // During the resize event from the data tab, Highcharts removes all the text from the chart
    // due to the responsive rules set up by us, this means we cannot pull the correct values.
    // Close the tab so we have time for the resize event to complete before pulling.
    if (tab === 'data' && activeBasicTab === 'option.cat.text') {
      changeActiveBasicTab('');
    }
  }, [tab]);

  return (
    <>
      {Object.entries(customizeOptions || {}).map(([key, option]) => {
        if (isMap && option[0].mapDisabled) return <React.Fragment key={key} />;
        const childrenClass = `relative flex flex-col rounded mx-6 py-2 pb-6 ${option[0]?.childrenClass ?? ''}`;
        return (
          <Container
            accordionClasses="list-dropdown bg-ev-grey"
            childrenClasses={childrenClass}
            key={key}
            selected={activeBasicTab === key}
            onClick={() => switchActiveBasicTab(key)}
            headerText={lang.entries[key]}
            useKey={key}
          >
            {option.map((section: any) => (
              <BuildInputs
                section={section}
                parentKey={key}
                key={key}
                editorConfig={editorConfig?.customizer?.availableSettings}
                classNameOverride={baseColor}
                overrideOnChange={props.overrideOnChange}
              />
            ))}
          </Container>
        );
      })}
    </>
  );
}
