import { cloneDeep, get, set } from 'lodash';
import { getSeriesType } from './seriesHelper';
import { HTMLDOMElement, SVGAttributes, SeriesOptions, SeriesOptionsType } from 'highcharts';
import { getHighchartsResponsiveConfig, handleFontResizeActions } from 'pages/ChartEditorPage/utils/chartEditorHelper';
import { RootState } from 'redux/store';
import { merge } from 'editor/core/highcharts-editor';

export const setupHighchartsEvents = () => {
  Highcharts.addEvent(Highcharts.Chart, 'load', function () {
    // eslint-disable-next-line no-invalid-this, @typescript-eslint/no-empty-function
    if (this && this.credits) this.credits.on('click', function () {});
  });
};

// Pulls aggregatedOptions and makes some last minute changes to the config mostly changes that
// are specific for loading in the editor, like the responsive rules for when the chart is in the data step
export const getChartConfig = (
  projectConfig: RootState['projectConfig'],
  chartConfig: RootState['chartEditorPage']
) => {
  const { aggregatedOptions, templateDataSettings, animation, noRedraw } = projectConfig;
  const { inlineEditor, isMap } = chartConfig;
  const options = cloneDeep(aggregatedOptions);
  const seriesTypes = getSeriesType(options);

  //@ts-expect-error ts-2339
  if (inlineEditor) inlineEditor.destroy();
  const existingAnimation = get(options, 'plotOptions.series.animation.duration');

  if (animation && !isMap) {
    if (!existingAnimation) set(options, 'plotOptions.series.animation.duration', 800);
  } else set(options, 'plotOptions.series.animation', false);

  if (options.data && !options.data.complete) {
    options.data.complete = function (chartOptions: Highcharts.Options) {
      //const templateDataSettings = config.templateDataSettings;
      const keys = Object.keys(templateDataSettings || {});
      keys.forEach(function (key: string) {
        const serie = chartOptions?.series?.[key as any] as SeriesOptions & { data: [] };
        (serie?.data || []).forEach(function (d: SeriesOptionsType, i: number) {
          if (templateDataSettings[key].data && templateDataSettings[key].data[i]) {
            merge(d, templateDataSettings[key].data[i], false, {});
          }
        });
      });

      if (options?.chart?.type === 'packedbubble') {
        (chartOptions?.series ?? []).forEach((series: any) => {
          if (series?.data) {
            series.data = series.data.filter((point: { value: any }) => point.value);
          }
        });
      }
      return chartOptions;
    };
  }

  if (!options.exporting) options.exporting = {};
  options.exporting.url = 'https://api.everviz.com/export';

  const AST = Highcharts.AST as any;
  AST.filterUserAttributes = (a: SVGAttributes) => a;
  AST.allowedTags.indexOf = () => '';
  AST.setElementHTML = (el: HTMLElement, html: string) => (el.innerHTML = html);

  const wizardSmallScreenOption = getHighchartsResponsiveConfig(seriesTypes, chartConfig, options);
  merge(options, wizardSmallScreenOption, false, {});

  Highcharts.charts.forEach((chart) => {
    const dataTableElement = chart?.dataTableDiv as HTMLDOMElement;
    if (dataTableElement) dataTableElement.remove();
  });

  handleFontResizeActions(options, noRedraw);

  if (!options.chart) options.chart = {};
  options.chart.reflow = false;

  return options;
};
