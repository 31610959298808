import paths from 'paths';
import { MenuItem } from 'shared/types/menuItemsModels';
import Cloud from 'static/icons/cloud-solid.svg';
import Code from 'static/icons/code-solid.svg';
import Comments from 'static/icons/comments-solid.svg';
import Gear from 'static/icons/gear-solid.svg';
import Lock from 'static/icons/lock-solid.svg';
import Paintbrush from 'static/icons/paintbrush-solid.svg';
import PenToSquare from 'static/icons/pen-to-square-solid.svg';
import Projects from 'static/icons/projects.svg';
import Packages from 'static/icons/packages.svg';
import Question from 'static/icons/question-solid.svg';
import Reply from 'static/icons/reply-solid.svg';
import User from 'static/icons/users-solid.svg';
import { checkPermission } from 'utils/profileHelper';
import config from '../../config';

export const getMenuItems = (profile: any) => {
  const tabs: Record<string, MenuItem> = {};
  tabs[paths.profileCharts] = {
    name: 'Projects',
    icon: 'bar-chart',
    svg: Projects
  };

  if (profile?.subscription?.team_features && profile?.subscription?.team_features.includes(3)) {
    tabs[paths.packages] = {
      name: 'Packages',
      icon: 'question',
      svg: Packages
    };
  }

  if (config.billing.enabled && checkPermission('team_management', profile)) {
    tabs[paths.profileSubscription] = {
      name: 'Subscription',
      icon: 'cloud',
      svg: Cloud
    };
  }
  if (checkPermission('view_users', profile)) {
    tabs[paths.profileTeamMembers] = {
      name: 'Members',
      icon: 'users',
      svg: User
    };
  }

  if (checkPermission('admin', profile)) {
    tabs[paths.profileTeamGroups] = {
      name: 'Roles',
      icon: 'lock',
      svg: Lock
    };
  }

  if (checkPermission('theme_management', profile)) {
    tabs[paths.profileTeamThemes] = {
      name: 'Themes',
      icon: 'paint-brush',
      svg: Paintbrush
    };
  }

  if (checkPermission('customise_editor', profile)) {
    tabs[paths.profileCustomiseEditor] = {
      name: 'Customize editor',
      icon: 'edit',
      svg: PenToSquare
    };
  }

  if (!config.billing.enabled || checkPermission('api_key_management', profile)) {
    tabs[paths.profileApi] = {
      name: 'API',
      icon: 'code',
      svg: Code
    };
  }

  tabs[paths.profileWebhooks] = {
    name: 'Webhooks',
    icon: 'reply',
    svg: Reply
  };

  tabs[paths.profileSettings] = {
    name: 'Settings',
    icon: 'cog',
    svg: Gear
  };

  tabs['forum'] = {
    name: 'Forum',
    icon: 'comments',
    link: 'https://forum.everviz.com/',
    svg: Comments
  };

  tabs['knowledgebase'] = {
    name: 'Knowledge base',
    icon: 'question',
    link: 'https://help.everviz.com/',
    svg: Question
  };

  return tabs;
};
