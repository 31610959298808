import classNames from 'classnames';
import Handsontable from 'handsontable';
import { Action } from 'redux';
import {
  addDataGridSeriesAction,
  clearColumnAction,
  deleteDataGridSeriesAction,
  editDataGridAction as editChartDataGridAction,
  sortDataGridAction
} from 'pages/ChartEditorPage/actions/chartEditor';
import store from 'redux/store';
import { setDataAction as setChartDataAction } from '../../../pages/ChartEditorPage/actions/chartEditor';
import { setDataAction as setTableDataAction } from '../../../pages/TableEditorPage/actions/tableEditor';
import { SetDataFunction } from './dragAndDropHelper';

export type CoordProps = {
  col: number;
  row: number;
};

export const setDataMap: { [key: string]: SetDataFunction } = {
  chart: setChartDataAction,
  table: setTableDataAction
};

export type parsedDataDictType = { [key: string]: number[] };

const isMac = window.navigator.platform.indexOf('Mac') > -1;

export const hotContextMenuConfig: Handsontable.plugins.ContextMenu.Settings = {
  items: {
    sort_asc: {
      name: '<div class="context-menu-item"><img src="/static/icons/ascending.svg"/><span>Sort ascending</span></div>',
      callback(this, _key, selection) {
        const { type } = store.getState().projectConfig;
        const index = selection[0].start.col;
        store.dispatch(sortDataGridAction({ type, column: index, sortOrder: 'asc' }));
      },
      disabled(this) {
        const { isMap } = store.getState().chartEditorPage;
        const selection = this.getSelected();
        if (selection) return (isMap && selection[0][1] === 0) || selection[0][0] >= 0;
      }
    },
    sort_desc: {
      name: '<div class="context-menu-item"><img src="/static/icons/descending.svg"/><span>Sort descending</span></div>',
      callback(this, _key, selection) {
        const { type } = store.getState().projectConfig;
        const index = selection[0].start.col;
        store.dispatch(sortDataGridAction({ type, column: index, sortOrder: 'desc' }));
      },
      disabled(this) {
        const { isMap } = store.getState().chartEditorPage;
        const selection = this.getSelected();
        if (selection) return (isMap && selection[0][1] === 0) || selection[0][0] >= 0;
      }
    },
    row_above: {
      name: '<div class="context-menu-item"><img src="/static/icons/add.svg"/><span>Add row above</span></div>',
      callback(this, _key, selection) {
        const index = selection[0].start.row;
        const { type } = store.getState().projectConfig;
        this.alter('insert_row', index);
        store.dispatch(addDataGridSeriesAction({ type, index, amount: 1, source: 'ContextMenu.rowAbove' }));
      },
      disabled(this) {
        const { isMap } = store.getState().chartEditorPage;
        const selection = this.getSelected();
        return isMap || (selection && selection[0][0] === -1);
      }
    },
    row_below: {
      name: '<div class="context-menu-item"><img src="/static/icons/add.svg"/><span>Add row below</span></div>',
      callback(this, _key, selection) {
        const { type } = store.getState().projectConfig;
        const index = selection[0].start.row + 1;
        this.alter('insert_row', index);
        store.dispatch(addDataGridSeriesAction({ type, index, amount: 1, source: 'ContextMenu.rowBelow' }));
      },
      disabled(this) {
        const { isMap } = store.getState().chartEditorPage;
        const selection = this.getSelected();
        return isMap || (selection && selection[0][0] === -1);
      }
    },
    separator1: {
      name: '<div class="context-menu-separator"></div>'
    },
    remove_col: {
      name: '<div class="context-menu-item"><img src="/static/icons/delete.svg"/><span>Delete column</span></div>',
      callback(this, _key, selection) {
        const { type } = store.getState().projectConfig;
        const startIndex = selection[0].start.col;
        const endIndex = selection[0].end.col;
        this.alter('remove_col', startIndex);
        store.dispatch(
          deleteDataGridSeriesAction({
            type,
            index: startIndex,
            amount: endIndex - startIndex + 1,
            source: 'ContextMenu.removeColumn'
          })
        );
      },
      disabled(this) {
        const { isMap } = store.getState().chartEditorPage;
        return isMap;
      }
    },
    clear_column: {
      name: '<div class="context-menu-item"><img src="/static/icons/clear.svg"/><span>Clear column</span></div>',
      callback(this, _key, selection) {
        const { type } = store.getState().projectConfig;
        const startIndex = selection[0].start.col;
        const endIndex = selection[0].end.col;
        for (let i = startIndex; i <= endIndex; i++) {
          this.alter('remove_col', startIndex);
        }

        store.dispatch(
          clearColumnAction({
            type,
            index: startIndex,
            amount: endIndex - startIndex + 1
          })
        );
      },
      disabled(this) {
        const { isMap } = store.getState().chartEditorPage;
        const selection = this.getSelected();
        if (selection) return isMap && selection[0][1] === 0;
      }
    },
    remove_row: {
      name: '<div class="context-menu-item"><img src="/static/icons/delete.svg"/><span>Delete row</span></div>',
      callback(this, _key, selection) {
        const { type } = store.getState().projectConfig;
        const startIndex = selection[0].start.row;
        const endIndex = selection[0].end.row;
        this.alter('remove_row', startIndex);

        store.dispatch(
          deleteDataGridSeriesAction({
            type,
            index: startIndex,
            amount: endIndex - startIndex + 1,
            source: 'ContextMenu.removeRow'
          })
        );
      },
      disabled(this) {
        const selection = this.getSelected();
        const { isMap } = store.getState().chartEditorPage;
        return isMap || (selection && selection[0][0] === -1);
      }
    },
    clear_row: {
      name: '<div class="context-menu-item"><img src="/static/icons/clear.svg"/><span>Clear row</span></div>',
      callback(this, _key, selection) {
        const { type } = store.getState().projectConfig;
        const startIndex = selection[0].start.row;
        const endIndex = selection[0].end.row;
        this.alter('remove_row', startIndex);
        store.dispatch(
          deleteDataGridSeriesAction({
            type,
            index: startIndex,
            amount: endIndex - startIndex + 1,
            source: 'ContextMenu.removeRow'
          })
        );
      },
      disabled(this) {
        const selection = this.getSelected();
        const { isMap } = store.getState().chartEditorPage;
        if (selection) return isMap || selection[0][1] >= 0;
      }
    },
    separator2: {
      name: '<div class="context-menu-separator"></div>'
    },
    col_left: {
      name: '<div class="context-menu-item"><img src="/static/icons/add.svg"/><span>Add column left</span></div>',
      callback(this, _key, selection) {
        const index = selection[0].start.col;
        const { type } = store.getState().projectConfig;
        this.alter('insert_col', index);
        store.dispatch(addDataGridSeriesAction({ type, index, amount: 1, source: 'ContextMenu.columnLeft' }));
      },
      disabled(this) {
        const selection = this.getSelected();
        if (selection) return selection[0][1] === 0;
      }
    },
    col_right: {
      name: '<div class="context-menu-item"><img src="/static/icons/add.svg"/><span>Add column right</span></div>',
      callback(this, _key, selection) {
        const { type } = store.getState().projectConfig;
        const index = selection[0].end.col;
        this.alter('insert_col', index + 1);
        store.dispatch(
          addDataGridSeriesAction({ type, index: index + 1, amount: 1, source: 'ContextMenu.columnRight' })
        );
      }
    },
    separator3: {
      name: '<div class="context-menu-separator"></div>'
    },
    cut: {
      name: () => {
        return `<div class="context-menu-item"><img src="/static/icons/cut.svg"/><span>Cut</span>
        <span class="help-text ml-auto mr-1 text-ev-dark-purple">Use ${
          isMac ? '<i class="fa-solid fa-command"></i>' : 'Ctrl'
        }+X</span></div>`;
      },
      callback(this, _key, selection) {
        const formattedSelection = selection[0];
        const formattedSelectionStart = formattedSelection.start;
        const startRow = formattedSelectionStart.row;
        const endRow = formattedSelection.end.row;
        const startColumn = formattedSelectionStart.col;
        const endColumn = formattedSelection.end.col;

        const storeState = store.getState();
        const { seriesAssigns, type } = storeState.projectConfig;

        const tempData: Handsontable.CellChange[] = [];

        Array.from({ length: endRow - startRow + 1 }, (_rowElement, rowIndex) => {
          Array.from({ length: endColumn - startColumn + 1 }, (_columnElement, columnIndex) => {
            tempData.push([startRow + rowIndex, startColumn + columnIndex, null, null]);
          });
        });

        updateDataGrid(type, tempData, seriesAssigns, type === 'table');
      }
    },
    copy: {
      name: () => {
        return `<div class="context-menu-item"><img src="/static/icons/copy.svg"/><span>Copy</span>
        <span class="help-text ml-auto mr-1 text-ev-dark-purple">Use ${
          isMac ? '<i class="fa-solid fa-command"></i>' : 'Ctrl'
        }+C</span></div>`;
      },
      callback(this, _key, selection) {
        const formattedSelection = selection[0];
        const formattedSelectionStart = formattedSelection.start;
        const formattedSelectionEnd = formattedSelection.end;

        const selectedData = this.getSourceData(
          formattedSelectionStart.row,
          formattedSelectionStart.col,
          formattedSelectionEnd.row,
          formattedSelectionEnd.col
        );

        navigator.clipboard.writeText(JSON.stringify(selectedData));
      }
    },
    paste: {
      name: () => {
        return `<div class="context-menu-item"><img src="/static/icons/paste.svg"/><span>Paste</span>
        <span class="help-text ml-auto mr-1 text-ev-dark-purple">Use ${
          isMac ? '<i class="fa-solid fa-command"></i>' : 'Ctrl'
        }+V</span></div>`;
      },
      async callback(this, _key, selection) {
        const formattedSelection = selection[0];
        const formattedSelectionStart = formattedSelection.start;
        const startRow = formattedSelectionStart.row;
        const startColumn = formattedSelectionStart.col;

        const storeState = store.getState();
        const { seriesAssigns, type } = storeState.projectConfig;

        const copiedValue = await navigator.clipboard.readText().then((text) => text);
        const formattedCopiedValue = JSON.parse(copiedValue);

        if (Array.isArray(formattedCopiedValue) && formattedCopiedValue.length > 0) {
          const tempData: Handsontable.CellChange[] = [];
          const endColumn = startColumn + formattedCopiedValue[0].length - 1;

          formattedCopiedValue.forEach((formattedValue, rowIndex) => {
            Array.from({ length: endColumn - startColumn + 1 }, (_columnElement, columnIndex) => {
              tempData.push([startRow + rowIndex, startColumn + columnIndex, null, formattedValue[columnIndex]]);
            });
          });

          updateDataGrid(type, tempData, seriesAssigns, type === 'table');
        }
      }
    },
    separator4: {
      name: '<div class="context-menu-separator"></div>'
    },
    undo: {
      name: '<div class="context-menu-item"><img src="/static/icons/undo.svg"/><span>Undo</span></div>',
      disabled(this) {
        const selection = this.getSelected();
        if (selection) return selection[0][0] < 0;
      }
    },
    redo: {
      name: '<div class="context-menu-item"><img src="/static/icons/redo.svg"/><span>Redo</span></div>',
      disabled(this) {
        const selection = this.getSelected();
        if (selection) return selection[0][0] < 0;
      }
    }
  }
};

const updateDataGrid = (type: string, changes: Handsontable.CellChange[], seriesAssigns: any[], isTable: boolean) => {
  store.dispatch(editData[type]({ changes, seriesAssigns, isTable }));
};

export const editData: Record<
  string,
  (data: { changes: Handsontable.CellChange[]; seriesAssigns: any[]; isTable?: boolean }) => Action
> = {
  chart: editChartDataGridAction,
  table: editChartDataGridAction
};

export const cellsConfig = (
  row: number,
  col: number,
  isMap: boolean,
  categories: number[],
  series: number[],
  isPointMapTypes: boolean,
  activeColumns?: number[],
  activeRows?: number[],
  mapCodeErrors?: number[],
  mapCodeIndex?: number
) => {
  let active = activeColumns?.includes(col);
  if (activeRows && activeRows.length > 0) {
    active = activeRows.includes(row);
  }

  const error = isMap && col === mapCodeIndex && mapCodeErrors?.includes(row - 1);
  const cellsClasses = classNames({
    'font-bold': !error && row === 0,
    'bg-ev-baby-blue-3': !error && series.includes(col) && !active, // Default highlighting of series
    'bg-ev-baby-blue': !error && (!isMap || isPointMapTypes) && !active && categories.includes(col), // default categories highlighting (Charts & PointMap, Mapbox)
    'bg-ev-grey': !error && isMap && !isPointMapTypes && !active && categories.includes(col), // default categories highlighting (Maps excl PointMap, Mapbox)
    'bg-ev-baby-blue-2': !error && active, // Highlighting when hovered
    'border-2 border-ev-red bg-ev-light-red': error // For maps, if the user has entered non compatible values
  });

  return {
    readOnly: isMap && !isPointMapTypes && col === mapCodeIndex,
    className: cellsClasses
  };
};

export const afterGetColHeader = (col: number, TH: HTMLElement) => {
  if (TH.querySelector('button') || col === -1) {
    return;
  }

  const btn = document.createElement('button');
  btn.innerHTML = '<i class="fa fa-chevron-down cursor-pointer"/>';

  TH.firstChild?.appendChild(btn);
};

const getSelectedSection = (hot: Handsontable) => {
  const selected_array = hot.getSelected();
  return selected_array ? selected_array[0] : null;
};

export const beforeOnCellMouseDown = (e: MouseEvent, coords: CoordProps, hot: Handsontable) => {
  if (coords.row >= 0) {
    return;
  }

  const tagName = (e.target as HTMLElement).tagName;
  if (tagName === 'I' || tagName === 'BUTTON') {
    // Show right click dropdown
    if (!hot) return;
    let selected: number[] | null = getSelectedSection(hot);

    if (!selected || (selected && selected[0] > 0 && selected[2] > 0)) {
      hot.selectColumns(coords.col, coords.col);
      selected = getSelectedSection(hot);
    } else {
      const left = Math.min(selected[1], selected[3]);
      const right = Math.max(selected[1], selected[3]);
      const isSelected = coords.col >= left && coords.col <= right;
      if (!isSelected) {
        hot.selectColumns(coords.col, coords.col);
      }
    }

    const contextMenu = hot.getPlugin('ContextMenu') as Handsontable.plugins.ContextMenu;
    contextMenu.close();
    contextMenu.open(e);
    e.stopImmediatePropagation();
  }
};

// This forms a dict for 'dependencywheel', 'sankey', 'packedbubble' charts to map hovered tooltips with table data
export const mapParsedDataToDictionary = (
  selectedChart: string,
  parsedData: Handsontable.RowObject[]
): parsedDataDictType => {
  interface DictionaryItem {
    sumWeight: number;
    indices: number[];
  }
  const parsedDataDict: parsedDataDictType = {};
  const nodeDict: Map<string, DictionaryItem> = new Map();

  const addLinkToParsedDataDict = (key: string, index: number) => {
    const existingKeyIndices = parsedDataDict[key];
    parsedDataDict[key] = existingKeyIndices ? [...existingKeyIndices, index] : [index];
  };

  const addNodeToNodeDict = (key: string, weight: number, index: number) => {
    const existingEntry = nodeDict.get(key);
    const sumWeight = existingEntry ? existingEntry.sumWeight + weight : weight;
    const indices = existingEntry ? [...existingEntry.indices, index] : [index];
    if (key) nodeDict.set(key, { sumWeight, indices });
  };

  const addNodeDictToParsedDataDict = () => {
    for (const [key, { sumWeight, indices }] of nodeDict) {
      parsedDataDict[`${key}: ${sumWeight}`] = indices;
    }
  };

  if (['dependencywheel', 'sankey'].includes(selectedChart)) {
    for (let i = 1; i < parsedData.length; i++) {
      const [from, to, weight] = parsedData[i] as string[];
      if (from && to) {
        if (weight) {
          const oneToOnekey = `Series 1${from} → ${to}: ${weight}`;
          addLinkToParsedDataDict(oneToOnekey, i);
        }

        const fromKey = `Series 1${from}`;
        const toKey = `Series 1${to}`;
        const parsedWeight = weight ? parseFloat(weight) : 0;
        addNodeToNodeDict(fromKey, parsedWeight, i);
        addNodeToNodeDict(toKey, parsedWeight, i);
      }
    }

    addNodeDictToParsedDataDict();
  } else if (selectedChart === 'packedbubble') {
    for (let i = 0; i < parsedData.length; i++) {
      const [series] = parsedData[i] as string[];
      addLinkToParsedDataDict(series, i);
    }
  }

  return parsedDataDict;
};

export const getHoveredRowIndices = (selectedChart: string, dataDict: { [key: string]: number[] }) => {
  if (['dependencywheel', 'packedbubble', 'sankey'].includes(selectedChart)) {
    const highchartsTooltip = document.querySelector('.highcharts-tooltip');

    if (highchartsTooltip && highchartsTooltip.textContent) {
      const textContent = highchartsTooltip.textContent.toString().replace(/\u200B/g, '');
      let cleanedTextContent = textContent;

      if (selectedChart === 'packedbubble') {
        const regex = /Series\s+\d+(?=\s*Value:)/i;
        const match = cleanedTextContent.match(regex);
        cleanedTextContent = match ? match[0].trim() : '';
      }

      if (dataDict[cleanedTextContent]) {
        return dataDict[cleanedTextContent];
      }
    }
  } else {
    const selector =
      selectedChart === 'timeline' || selectedChart === 'treemap'
        ? 'svg.highcharts-root'
        : 'svg.highcharts-root g.highcharts-series';
    const highchartsRoot = document.querySelector(selector);
    const colorElements = highchartsRoot?.querySelectorAll(
      'path[class*="highcharts-color-"], g[class*="highcharts-color-"]'
    );

    if (colorElements && colorElements.length > 0) {
      const className = colorElements[0].getAttribute('class');

      if (className) {
        const match = className.match(/highcharts-color-(\d+)/);
        if (match) {
          return [parseInt(match[1], 10) + 1];
        }
      }
    }
  }

  return [];
};
