import actionTypes from '../../../redux/actions/action-types';

export const setPlansPageAction = (data) => ({
  type: actionTypes.plansPage.set,
  data
});

export const updatePlanAction = (data) => ({
  type: actionTypes.plansPage.updatePlan,
  data
});

export const getAdjustmentsAction = (data) => ({
  type: actionTypes.plansPage.getAdjustments,
  data
});

export const getBillingDetailsAction = (data) => ({
  type: actionTypes.plansPage.getBillingDetails,
  data
});

export const downloadInvoiceAction = (data) => ({
  type: actionTypes.plansPage.downloadInvoice,
  data
});
