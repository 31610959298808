import actionTypes from '../../../redux/actions/action-types';

export const setTeamInvitationsAction = (data) => ({
  type: actionTypes.teamInvitationsPage.set,
  data
});

export const getInvitationsAction = (data) => ({
  type: actionTypes.teamInvitationsPage.getInvitations,
  data
});

export const deleteInvitationAction = (data) => ({
  type: actionTypes.teamInvitationsPage.deleteInvitation,
  data
});

export const deleteInvitationsAction = (data) => ({
  type: actionTypes.teamInvitationsPage.deleteInvitations,
  data
});

export const setInvitationsAction = (data) => ({
  type: actionTypes.teamInvitationsPage.setInvitations,
  data
});

export const resendInvitationsAction = (data) => ({
  type: actionTypes.teamInvitationsPage.resendInvitations,
  data
});

export const getPlanDetailsAction = (data) => ({
  type: actionTypes.teamInvitationsPage.getPlanDetails,
  data
});
