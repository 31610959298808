export default (type, data, config, headers = [], responsive) => {
  return {
    chart: {
      type: 'bar',
      width: responsive ? null : 250,
    },
    plotOptions: {
      series: {
        tooltip: {
          enabled: config.showTooltip ? true : false,
        },
      },
      bar: {
        stacking: undefined,
        groupPadding: -1,
        negativeColor: config.negativeChartColor || '#DD495E',
      },
    },
    xAxis: {
      gridLineWidth: 0,
      borderWidth: 0,
    },

    yAxis: {
      gridLineWidth: 0,
      borderWidth: 0,
      tickPositions: [Math.min(config.min, 0), config.max],
      endOnTick: true,
      startOnTick: true,
      plotBands: [
        {
          color: '#e8e8e8',
          from: config.min,
          to: config.max,
          borderWidth: 0,
        },
        {
          color: 'black',
          borderWidth: 0,
          borderColor: 'black',
          from: 0,
          to: 0,
          zIndex: 10,
        },
      ],
    },

    tooltip: {
      enabled: config.showTooltip ? true : false,
      headerFormat: '<b>' + config.columnName + '</b><br/>',
      pointFormat: '<b>' + (headers[0] || '') + ':</b> {point.y}',
    },

    series: [
      {
        data: data.map((d) => parseFloat(typeof d === 'string' ? d.replace(/ /g, '').replace(/,/g, '.') : d)),
        color: config.chartColors ? config.chartColors[0] : '#235A61',
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    ],
  };
};
