import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Funnel: TemplateProps = {
  title: 'Funnel',
  description: '',
  constructor: '',
  thumbnail: 'exumeq.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'funnel'
    },
    plotOptions: {
      series: {
        datalabels: {
          color: '#000000'
        },
        dataLabels: {
          softConnector: true
        },
        neckWidth: '20%',
        neckHeight: '35%'
      }
    },
    'series[0]': {
      width: '64%'
    }
  },
  sampleSet: 'funnel',
  advancedParent: 'Funnel',
  dataValidator: false,
  basic: {
    order: 26,
    thumbnail: 'funnel_chart.svg',
    icon: 'Funnel'
  },
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter)
};

export default Funnel;
