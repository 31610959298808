import { TemplateTypeProps } from './../types';
import Categories from './Categories';
import Choropleth from './Choropleth';
import Honeycomb from './Honeycomb';
import MapBubble from './MapBubble';
import Patternfill from './Patternfill';
import PointMap from './PointMap';
import TileMapCircle from './TilemapCircle';
import Location from './Location';

const MapTemplate: TemplateTypeProps = {
  description: '',
  icon: '',
  sampleData: [],
  templates: {
    Choropleth,
    Categories,
    'Location map': Location,
    Bubble: MapBubble,
    'Tilemap circle': TileMapCircle,
    Honeycomb,
    'Pattern fill': Patternfill,
    'Point map': PointMap
  }
};

export default MapTemplate;
