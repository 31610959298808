import { isNull, isNum, isStr } from 'editor/core/highcharts-editor';
import { checkSections } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';

export const stripHtml = (html) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

//Used for wordcloud
export const convertData = (rows, quoteStrings, section) => {
  let data = [];

  function addData(column, arr) {
    if (quoteStrings && !isNum(column) && isStr(column)) {
      column = '"' + column.replace(/\"/g, '"') + '"';
    }

    if (isNull(column)) {
      column = parseFloat(column);
    }

    arr.push(column);
  }

  rows.forEach(function (row) {
    var rarr = [],
      hasData = false;

    if (section) {
      //Add in label data first
      //addData(row.columns[section[0].labelColumn].value(), rarr);
    }

    row.forEach(function (col, index) {
      if (section && !checkSections(section, index)) return;

      var v = col;

      if (v || v === 0) {
        hasData = true;
      }

      addData(v, rarr);
    });

    if (hasData) {
      data.push(rarr);
    }
  });

  return data;
};

export const isTileMap = (options) => {
  return (
    options.options?.series &&
    (options.options.series || []).some(function (s) {
      return s.type === 'tilemap';
    })
  );
};
