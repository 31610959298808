export const useCaptcha = (config, action) =>
  new Promise((resolve, reject) => {
    if (config.captcha.enabled) {
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise.execute(config.captcha.provider.siteKey, { action }).then((token) => {
          return resolve(token);
        });
      });
    } else resolve();
  });
