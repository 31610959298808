import React, { useEffect } from 'react';
import Modal from 'shared/modal/Modal';
import Link from 'shared/nav/Link';

type ContactUsModalProps = {
  isOpen: boolean;
  close?: () => void;
};

const ContactUsModal = ({ isOpen, close }: ContactUsModalProps) => {
  const onClose = () => {
    if (close) {
      close();
    }
  };

  useEffect(() => {
    const windowRef = window as any;

    if (windowRef.hbspt && isOpen) {
      windowRef.hbspt.forms.create({
        region: 'eu1',
        portalId: '6876929',
        formId: 'bd0e8915-5c1b-435f-b377-6232310a36f8',
        target: '#hubspot-contactus-form'
      });
    }
  }, [isOpen]);

  return (
    <Modal
      bodyClass="contact-us-modal"
      title="Contact us"
      width="w-[616px] max-w-xl"
      bg="bg-ev-yellow-1"
      isOpen={isOpen ?? false}
      onClose={onClose}
    >
      <div id="hubspot-contactus-form" />
      <span className="inline-block mt-6">
        Everviz needs the contact information you provide to us to contact you about our products and services. You may
        unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy
        practices and commitment to protecting your privacy, please review our{' '}
        <Link link="https://www.everviz.com/privacy" text="Privacy Policy by clicking here" />.
      </span>
    </Modal>
  );
};

export default ContactUsModal;
