export const SIDEBAR_NAMES = {
  QUICK_EXPORT: 'quickExport',
  RECENT_EXPORT: 'recentExportOpen',
  ANNOUNCEMENT: 'announcementOpen'
};

export const SIDEBAR_HEADERS = {
  QUICK_EXPORT: 'Quick Export',
  RECENT_EXPORT: 'Recent Export',
  ANNOUNCEMENT: 'Announcements'
};
