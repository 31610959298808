import React from 'react';
import PropTypes from 'prop-types';

class TeamSelector extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.toggle = this.toggle.bind(this);
    this.toggleSubteam = this.toggleSubteam.bind(this);
    this.close = this.close.bind(this);
    this.onSubTeamBlur = this.onSubTeamBlur.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.subClose = this.subClose.bind(this);
    this.showModal = this.showModal.bind(this);

    this.state = {
      closed: true,
      divisions: props.divisions,
      subClosed: true
    };
  }
  /*
    componentWillReceiveProps(newProps) {
      this.setState({
          children: newProps.children,
          divisions: newProps.divisions
        });
    }
*/
  toggle() {
    const inProfilePage = location.pathname.match('/profile');
    if (inProfilePage) {
      this.setState({ closed: !this.state.closed });
    }
  }

  toggleSubteam() {
    const inProfilePage = location.pathname.match('/profile');
    if (inProfilePage) {
      this.setState({ subClosed: !this.state.subClosed });
    }
  }

  close() {
    this.setState({ closed: true });
  }

  subClose() {
    this.setState({ subClosed: true });
  }

  onBlur(event) {
    // If clicked element is a child then ignore, else close dropdown.
    if (!event.currentTarget.contains(event.relatedTarget)) this.close();
  }

  onSubTeamBlur(event) {
    // If clicked element is a child then ignore, else close dropdown.
    if (!event.currentTarget.contains(event.relatedTarget)) this.subClose();
  }

  showModal() {
    if (location.pathname.match('/profile')) this.props.showModal();
  }

  render() {
    const children = this.props.children || [];
    const className = this.props.headerClassName + ' ' || '';
    const stateClassName = this.state.closed ? 'is-closed' : 'is-open';
    const subTeamStateClassName = this.state.subClosed ? 'is-closed' : 'is-open';
    const inProfilePage = location.pathname.match('/profile');

    return (
      <div className="content-centered">
        <div
          className={
            'hc-dropdown ' + stateClassName + ' ' + this.props.extraClassName + (!inProfilePage ? ' disabled' : '')
          }
          onBlur={this.onBlur}
          tabIndex={0}
        >
          {this.props.checkPermission('subteam_management') && (
            <a
              className={'plus-container highed-template-tooltip' + (!inProfilePage ? ' disabled' : '')}
              onClick={this.showModal}
            >
              <span className="clickable plus-inner-container">
                <i className="fa fa-plus" />
              </span>
              <div className={'highed-tooltip-text' + (!inProfilePage ? ' hidden' : '')}>Add a subteam</div>
            </a>
          )}

          <a onClick={this.toggle} className={'hc-dropdown-link ' + className}>
            <div className="team-viewer">
              <p className="big-team-name">{this.props.team.name}</p>
            </div>
            <i className="menu-icon fa fa-caret-down" />
          </a>

          <ul className="hc-dropdown-menu">
            {children.map((child, i) => {
              const func = () => {
                this.props.setTeam(child);
                this.toggle();
              };
              return (
                <li key={i} className={this.props.team.id === child.id ? 'active' : ''}>
                  <div>
                    <div onClick={func}> {child.name}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <div
          className={
            'subteam-dropdown hc-dropdown ' +
            subTeamStateClassName +
            ' ' +
            this.props.extraClassName +
            (!inProfilePage ? ' disabled' : '')
          }
          onBlur={this.onSubTeamBlur}
          tabIndex={0}
        >
          <a onClick={this.toggleSubteam} className={'hc-dropdown-link ' + className}>
            <div className="team-viewer">
              <p className="big-team-name">{this.props.division ? this.props.division.name : 'All subteams'}</p>
            </div>
            <i className="menu-icon fa fa-caret-down" />
          </a>

          <ul className="hc-dropdown-menu">
            {(this.props.divisions || []).map((child, i) => {
              const func = () => {
                this.props.setDivision(child);
                this.toggleSubteam();
              };
              return (
                <li key={i} className={this.props.division.id === child.id ? 'active' : ''}>
                  <div>
                    <div onClick={func}>{child.name}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

TeamSelector.propTypes = {
  children: PropTypes.array,
  headerClassName: PropTypes.string,
  extraClassName: PropTypes.string,
  setTeam: PropTypes.func,
  showModal: PropTypes.func,
  team: PropTypes.object,
  division: PropTypes.object,
  divisions: PropTypes.array,
  setDivision: PropTypes.func,
  checkPermission: PropTypes.func
};

export default TeamSelector;
