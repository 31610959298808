import { formatDistanceToNowStrict } from 'date-fns';
import paths from 'paths';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Tooltip from 'shared/tooltip/Tooltip';
import { projectsPlaceholders } from 'shared/utils/projectsPlaceholders';
import useToggle from 'shared/utils/useToggle';
import BinIcon from 'static/icons/bin.svg';
import CogIcon from 'static/icons/cog.svg';
import EyeIcon from 'static/icons/eye.svg';
import CopyIcon from 'static/icons/copy.svg';
import DownloadIcon from 'static/icons/download.svg';
import { checkPermission } from 'utils/profileHelper';
import Card from '../../../shared/Card';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import { ButtonColor } from '../../../shared/buttons/types/ButtonModels';
import Modal from '../../../shared/modal/Modal';
import {
  convertToThemeAction,
  deleteChartAction,
  duplicateChartAction,
  duplicateToThemeAction,
  recoverChartAction
} from '../actions/projectsPage';
import EditChartModal from './EditProjectModal';
import Tags from './Tags';
import { setAction as setProjectConfigAction } from 'redux/actions/projectConfig';
import { setAction } from '../../ChartEditorPage/actions/chartEditor';
import { setAction as setLayoutAction } from '../../LayoutEditorPage/actions/layoutEditor';

type ProjectCardProps = {
  chart: any;
  deleted: boolean;
  highlighted: boolean;
};

interface LastAction {
  text: string;
  pick: boolean;
  value: string;
}

export default function ProjectCard(props: ProjectCardProps) {
  const { chart, deleted, highlighted } = props;
  const dispatch = useDispatch();

  const { selectSortValue } = useSelector((state: RootState) => state.projectsPage);
  const { team, division, divisions } = useSelector((state: RootState) => state.profile);
  const [settingsModal, toggleSettingsModal] = useToggle(false);
  const [deleteModal, toggleDeleteModal] = useToggle(false);

  const isPublished = props.chart.has_published;
  const placeholder = projectsPlaceholders[props.chart.chart_type as number];
  const previewPath = paths.chartShare.replace(':id', chart.hash);
  const thumbnailURL = chart.thumbnail_url;

  const goToChartEditor = () => {
    if (checkPermission('create_chart')) {
      if (chart.chart_type === 5) {
        return paths.editTable.replace(':tableid', chart.id);
      } else if (chart.chart_type === 4) {
        return paths.editLayout.replace(':storyid', chart.id);
      } else {
        return paths.editChartLatest.replace(':chartid', chart.id);
      }
    }
  };

  const templateTimeSinceLastChange = () => {
    const [created, edited] = [chart.creation_time, chart.last_updated].map((x) =>
      formatDistanceToNowStrict(new Date(x))
    );

    const lastAction: LastAction | undefined = [
      { text: 'modified', pick: chart.creation_time !== chart.last_updated, value: edited },
      { text: 'created', pick: Boolean(created), value: created }
    ].find((x) => x.pick === true);

    if (selectSortValue.id === 1) {
      const createdText = created.includes('seconds') ? 'just now' : `${created} ago`;
      return `created ${createdText} by ${chart.creator}`;
    }

    const actionText = lastAction?.text || '';
    const actionValue =
      lastAction?.value && lastAction?.value.includes('seconds')
        ? 'just now'
        : lastAction?.value
        ? `${lastAction.value} ago`
        : '';

    return `${actionText} ${actionValue} by ${chart.username}`;
  };

  const convertToTheme = () => dispatch(convertToThemeAction({ team, chart }));
  const duplicateToTheme = () => dispatch(duplicateToThemeAction({ team, chart }));
  const duplicateChart = () => dispatch(duplicateChartAction({ team, chart }));
  const deleteChart = () => dispatch(deleteChartAction({ team, chart }));
  const recoverChart = () => dispatch(recoverChartAction({ team, chart }));
  const chartType = placeholder.name === 'map' ? 'chart' : placeholder.name;

  const openExportMenu = () => {
    dispatch(setProjectConfigAction({ type: chartType }));
    if (placeholder.name === 'chart' || placeholder.name === 'map') {
      dispatch(setAction({ chartId: chart.id }));
    } else {
      dispatch(setLayoutAction({ storyId: chart.id }));
    }
  };

  return (
    <>
      {!deleted && (
        <Card
          title={chart.name}
          thumbnail={thumbnailURL}
          hideHoverDescriptor={true}
          highlighted={highlighted}
          href={({ ...ps }) => (
            <a {...ps} href={goToChartEditor()}>
              {ps.children}
            </a>
          )}
          placeholder={placeholder}
        >
          <div className="flex flex-col justify-between h-full">
            <div>
              <span className="text-gray-500 text-xs">{templateTimeSinceLastChange()}</span>
              <Tags tags={chart.tags} chartType={chart.chart_type} />
            </div>
            <div className="flex mx-1 mt-3 h-6 items-center gap-4">
              <Tooltip
                tippyProps={{
                  content: isPublished ? 'Preview' : 'This project can be viewed after publishing.',
                  placement: 'bottom'
                }}
              >
                <a href={isPublished ? previewPath : undefined} title="Preview">
                  <EyeIcon
                    width={20}
                    height={20}
                    className={isPublished ? 'text-ev-navy-blue-2 cursor-pointer' : 'text-ev-grey-3'}
                  />
                </a>
              </Tooltip>

              <Tooltip
                tippyProps={{
                  content: 'Duplicate',
                  placement: 'bottom'
                }}
              >
                <a onClick={() => duplicateChart()} title="Duplicate">
                  <CopyIcon width={18} height={18} className="cursor-pointer text-ev-navy-blue-2" />
                </a>
              </Tooltip>

              <Tooltip
                tippyProps={{
                  content: 'Quick export',
                  placement: 'bottom'
                }}
              >
                <a onClick={() => openExportMenu()} title="Quick export">
                  <DownloadIcon width={18} height={18} className="cursor-pointer text-ev-navy-blue-2" />
                </a>
              </Tooltip>

              <Tooltip
                tippyProps={{
                  content: 'Settings',
                  placement: 'bottom'
                }}
              >
                <a onClick={() => toggleSettingsModal()} title="Settings">
                  <CogIcon width={18} height={18} className="cursor-pointer text-ev-navy-blue-2" />
                </a>
              </Tooltip>

              <Tooltip
                tippyProps={{
                  content: 'Delete',
                  placement: 'bottom'
                }}
              >
                <a onClick={() => toggleDeleteModal()} title="Delete" className="ml-auto">
                  <BinIcon width={18} height={18} className="cursor-pointer hover:text-ev-red text-ev-navy-blue-2" />
                </a>
              </Tooltip>
            </div>
          </div>

          <EditChartModal
            thumbnailURL={thumbnailURL}
            placeholder={placeholder}
            isOpen={settingsModal}
            toggle={() => toggleSettingsModal()}
            type={projectsPlaceholders[chart.chart_type].name}
            duplicateChart={duplicateChart}
            convertToTheme={convertToTheme}
            duplicateToTheme={duplicateToTheme}
            divisions={divisions}
            division={division}
            editChart={goToChartEditor}
            chart={chart}
            team={team}
            appliedTags={chart.tags}
          />

          <Modal
            isOpen={deleteModal}
            onClose={() => toggleDeleteModal()}
            title="Delete project"
            width="w-7/12 max-w-xl"
            bg="bg-ev-yellow-1"
            bodyClass="px-8 py-6"
          >
            <p className="mb-8">
              Are you sure you want to delete this project? Doing so will delete the publish codes you have generated
              for this.
            </p>
            <hr />
            <div className="pt-6 flex gap-2">
              <PrimaryButton
                onClick={() => {
                  deleteChart();
                  toggleDeleteModal();
                }}
                text="Confirm"
              />
              <PrimaryButton onClick={() => toggleDeleteModal()} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
            </div>
          </Modal>
        </Card>
      )}

      {props.deleted && (
        <Card
          title={props.chart.name}
          thumbnail={thumbnailURL}
          href={({ ...ps }) => (
            <a {...ps} href={goToChartEditor()}>
              {ps.children}
            </a>
          )}
          placeholder={placeholder}
        >
          <Tags tags={chart.tags} chartType={chart.chart_type} />

          <div>
            <Tooltip
              tippyProps={{
                content: 'Restore',
                placement: 'bottom'
              }}
            >
              <a
                onClick={() => recoverChart()}
                className="text-base group cursor-pointer no-underline hover:underline text-gray-900 float-right"
              >
                Restore
                <i className="text-lg text-gray-500 ml-2 self-center cursor-pointer group-hover:text-ev-blue fa fa-trash-restore" />
              </a>
            </Tooltip>
          </div>
        </Card>
      )}
    </>
  );
}
