import classNames from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import IconButton from '../../buttons/IconButton';
import IconInput from '../../inputs/IconInput';
import ResizeHorizontalIcon from '../../wizard/Icons/ResizeHorizontalIcon';
import ResizeVerticalIcon from '../../wizard/Icons/ResizeVerticalIcon';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';
import { EvervizChart } from 'shared/types/chart';

export type Dimensions<T = number> = {
  width: T | null;
  height: T | null;
};

type ResolutionSectionProps = {
  setResolution: (dimensions: Dimensions) => void;
  defaultWidth?: number;
  defaultHeight?: number;
  hideManual?: boolean;
  className?: string;
  showPreviewText?: boolean;
};

const dimensions = {
  mobile: { width: 300, height: 500 },
  screen: { width: null, height: null }
};

const ResolutionSection = (props: ResolutionSectionProps) => {
  const [selectedDimensions, setSelectedDimensions] = useState<Dimensions>(dimensions.screen);
  const chart = useChart() as EvervizChart;
  const setResolution = (resolution: Dimensions) => {
    props.setResolution(resolution);
    setSelectedDimensions(resolution);
    chart.reflow();
  };

  const onWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedDimensions({ ...selectedDimensions, width: Number(e.target?.value) || props.defaultWidth || null });
    props.setResolution({
      width: Number(e.target.value) || props.defaultWidth || selectedDimensions.width,
      height: selectedDimensions.height || null
    });
    chart.reflow();
  };
  const onHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedDimensions({ ...selectedDimensions, height: Number(e.target.value) || props.defaultHeight || null });
    props.setResolution({
      width: selectedDimensions.width || null,
      height: Number(e.target.value) || props.defaultHeight || selectedDimensions.height
    });
    chart.reflow();
  };

  const classes = classNames('text-[#757575] text-sm content-centered highed-transition ml-auto hidden ev-md:flex', {
    'right-0': props.hideManual,
    'right-8 relative': !props.hideManual
  });
  return (
    <div className={classes}>
      {props.showPreviewText && <span className="font-bold text-ev-navy-blue">Preview:</span>}{' '}
      <IconButton
        icon={'laptop'}
        iconClass="fad inverted"
        onClick={() => setResolution(dimensions.screen)}
        className="rounded ml-2 h-6 w-6"
        active={selectedDimensions === dimensions.screen}
      />
      <IconButton
        icon={'mobile'}
        iconClass="fad inverted"
        onClick={() => setResolution(dimensions.mobile)}
        className="rounded ml-2 h-6 w-6"
        active={selectedDimensions === dimensions.mobile}
      />
      {!props.hideManual && (
        <div className="">
          <div className="flex">
            <IconInput
              className="mx-2"
              customIcon={<ResizeHorizontalIcon />}
              value={selectedDimensions.width ?? ''}
              onChange={onWidthChange}
            />
            <IconInput
              customIcon={<ResizeVerticalIcon />}
              value={selectedDimensions.height ?? ''}
              onChange={onHeightChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResolutionSection;
