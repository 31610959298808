import classNames from 'classnames';
import { cloneDeep, get, isObject, set } from 'lodash';
import { OptionProps, WidgetType } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'shared/buttons/IconButton';
import { GenericInputProps, InputChangeParams } from 'shared/types/commonPropTypes';
import { highlightEnterMap, highlightLeaveMap } from 'shared/wizard/utils/seriesHelper';
import fieldsMap from './FieldsMap';
import PlusIcon from './PlusIcon';
import SortableContainer from './SortableContainer';
import SortableItem from './SortableItem';
import { capitalizeFirstLetter } from 'editor/core/highcharts-editor';
import { RootState } from 'redux/store';

type ParsedValueProp = string | { name: string };

const EmptyContainer = (props: { children: ReactNode; values?: []; id?: string | number }) => <>{props.children}</>;

const ArrayWidget = (props: GenericInputProps & { subtype?: string }) => {
  const { value, option } = props;

  const showAsRows = option?.custom?.showAsRows;
  const openIfOne = option?.custom?.openIfOne;
  const type = option?.type;
  let subtype = props?.subtype ?? 'color';
  const dispatch = useDispatch();
  const { provider } = useSelector((state: RootState) => state.projectConfig);

  if (type && type.indexOf('array') === 0) {
    subtype = type.substr(6, type.length - 7);
  }

  const getValue = () => {
    const { option } = props;

    if (option?.isBasedOnSeriesData) {
      const { aggregatedOptions } = props;
      if (aggregatedOptions.series[0].data) {
        let val = aggregatedOptions.series[0].data;
        val = (val || []).map((d: any) => {
          return get(d, option?.id ?? '');
        });
        return val;
      }
    }
    return value;
  };

  const onChange = ({ index, val }: InputChangeParams) => {
    let arrayValue = cloneDeep(getValue());
    if (!arrayValue) {
      arrayValue = Array(Number(index ?? 0) + 1).fill(null);
    }
    if (option?.rawKey) set(arrayValue[index ?? 0], option.rawKey, val);
    else arrayValue[index as number] = val as unknown;

    props.onChange({ val: arrayValue });
  };

  const addOption = () => {
    const newValue = cloneDeep(value ?? []);
    (newValue as string[]).push(option?.custom?.defaultItemValue ?? '');
    props.onChange({ val: newValue });
  };

  const deleteOption = (index: number) => {
    const newValue = (value as string[]).filter((_, i) => i !== index);
    props.onChange({ val: newValue });
  };

  const parsedValue = getValue();
  const InputType = (fieldsMap[subtype as WidgetType] || fieldsMap.default) as React.ElementType;
  const onlyOneSection = (parsedValue ?? []).length === 1;
  const displayInternalLabel = subtype === 'string' || (subtype !== 'string' && !(parsedValue ?? []).length);
  const displayFlex = !['icon', 'accordion'].includes(subtype);

  const className = classNames('color-row', {
    'content-centered flex-col': showAsRows,
    'flex-row w-full max-w-full': !showAsRows,
    flex: displayFlex
  });

  const getLabel = (option: OptionProps | undefined, index: number) => {
    return displayInternalLabel ? null : `${option?.title ?? 'Series'} ${index + 1}`;
  };

  const getParsedNameLabel = (label: string) => capitalizeFirstLetter(label).replaceAll('-', ' ');

  const getContainers = (option: OptionProps | undefined) => {
    const isSortable = option?.custom?.isSortable;
    return {
      RootContainer: isSortable ? SortableContainer : EmptyContainer,
      ItemContainer: isSortable ? SortableItem : EmptyContainer
    };
  };

  const onMouseEnter = (index: number) => {
    if (option?.custom?.highlightSeries) highlightEnterMap[provider](dispatch, [index], [index]);
  };

  const onMouseLeave = () => {
    if (option?.custom?.highlightSeries) highlightLeaveMap[provider](dispatch);
  };

  const { RootContainer, ItemContainer } = getContainers(option);

  return (
    <div className={`highed-field-table flex ${showAsRows ? '' : 'flex-col gap-y-2'}`}>
      {displayInternalLabel && <div className="text-ev-navy-blue font-bold mt-2">{option?.title ?? 'Series'}</div>}

      <RootContainer value={parsedValue} onChange={props.onChange}>
        {(parsedValue || []).map((val: ParsedValueProp, index: number) => {
          const parentPath = (option?.rawKey ?? '').replace('[n]', `[${index}]`);
          const useNameValueAsLabel = isObject(val) && option?.custom?.useNameValueAsLabel;
          const label = useNameValueAsLabel ? getParsedNameLabel(val.name ?? '') : getLabel(option, index);
          const value = useNameValueAsLabel ? get(val, option?.rawKey ?? '') : val;

          return (
            <ItemContainer id={index + 1} key={`array_${index}`}>
              <div className={className} onMouseEnter={() => onMouseEnter(index)} onMouseLeave={onMouseLeave}>
                <InputType
                  {...props}
                  value={value}
                  index={index}
                  isArraySelector={true}
                  onChange={onChange}
                  label={label}
                  parentPath={parentPath}
                  open={openIfOne && onlyOneSection}
                  className={`${props.className ?? ''}${displayInternalLabel ? ' w-full' : ''}`}
                />
                {!option?.isBasedOnSeriesData && !option?.custom?.hideDeleteButtons && showAsRows && (
                  <IconButton onClick={() => deleteOption(index)} icon="fa fa-trash-can" className="my-1" />
                )}

                {!option?.isBasedOnSeriesData && !option?.custom?.hideDeleteButtons && !showAsRows && (
                  <IconButton icon={'trash'} onClick={() => deleteOption(index)} className="rounded-sm ml-2 p-2 px-3" />
                )}
              </div>
            </ItemContainer>
          );
        })}
      </RootContainer>

      {!option?.isBasedOnSeriesData && !option?.custom?.hideAddButton && (
        <PlusIcon onClick={addOption} className={showAsRows ? '' : 'mx-auto mb-4'} />
      )}
    </div>
  );
};

export default ArrayWidget;
