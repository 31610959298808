import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { PolygonMode } from './PolygonContent';
import { showPaidPlanModal } from 'shared/utils/paidPlanHelper';
import { postTeamProviderDataserverUsingTeamidAndProvidertype } from 'api/cloudApiGenerated';

import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import TextInput from 'shared/inputs/TextInput';
import ErrorMessage from './ErrorMessage';

type PolygonTokenProps = {
  setMode: React.Dispatch<React.SetStateAction<PolygonMode>>;
  setAccountLinked: React.Dispatch<React.SetStateAction<boolean>>;
  onModalClose: () => void;
  setErrorsComponent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

type ErrorProps = {
  response: {
    message: string;
  };
};

const PolygonToken = (props: PolygonTokenProps) => {
  const { setMode, setAccountLinked, setErrorsComponent } = props;
  const initialState = { apiKey: '' };
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const { team } = useSelector((state: RootState) => state.profile);
  const [status, setStatus] = useState({
    loading: false,
    done: false
  });
  const { loading, done } = status;

  const setLoadingStatus = (loading: boolean) => {
    setStatus({
      loading,
      done: !loading
    });
  };

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = ev.target;
    setForm((prev) => ({ ...prev, [name]: ev.target.value }));
  };

  const validateForm = () => {
    let isValid = true;
    setErrors(initialState);
    Object.entries(form).forEach(([key, value]) => {
      if (!value.length) {
        setErrors((prev) => ({ ...prev, [key]: 'This field cannot be empty.' }));
        isValid = false;
      }
    });
    return isValid;
  };

  const handleSubmit = async () => {
    if (!team) {
      props.onModalClose();
      showPaidPlanModal('higher');
      return;
    }

    setErrorsComponent(null);
    setLoadingStatus(true);
    if (validateForm()) {
      try {
        await postTeamProviderDataserverUsingTeamidAndProvidertype(team.id, 'polygon', form);
        setLoadingStatus(false);
        setTimeout(() => {
          setMode(PolygonMode.Stocks);
          setAccountLinked(true);
          setTimeout(() => setStatus({ loading: false, done: false }), 1000);
        }, 1000);
      } catch (e) {
        setErrorsComponent(<ErrorMessage text={(e as ErrorProps).response.message} />);
        setAccountLinked(false);
        setStatus({
          loading: false,
          done: false
        });
      }
    }
  };

  return (
    <>
      <TextInput
        type="password"
        placeholder="API Key"
        inputClassName="highed-field-input"
        onChange={onChange}
        name="apiKey"
        value={form.apiKey}
        label="API Key"
        labelClass="font-bold text-base"
        extraClass="pr-0"
        error={errors.apiKey}
      />

      <hr />

      <div className="flex gap-4 mt-8">
        <PrimaryButton
          useLoader={{
            default: {
              text: <span className="mr-1">Save</span>
            },
            loading: {
              text: <span className="mr-1">Save</span>,
              check: loading
            },
            done: {
              icon: 'circle-check',
              text: 'Linked',
              check: done
            }
          }}
          className="mr-2 text-sm"
          onClick={handleSubmit}
          buttonColor={ButtonColor.NavyBlue}
          showSubscriptionLock={!team}
        />

        <PrimaryButton text="Cancel" onClick={props.onModalClose} buttonColor={ButtonColor.BabyBlue} />
      </div>
    </>
  );
};

export default PolygonToken;
