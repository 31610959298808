import { merge } from 'editor/core/highcharts-editor';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import EmojiModal from 'shared/widgets/EmojiModal';
import { setAction } from './../../../redux/actions/projectConfig';
import RichtextGroupModal from './../../../shared/widgets/richtextgroup/RichtextGroupModal';
import HideElementButton from './HideElementButton';

const GlobalModal = () => {
  const {
    richtextGroupOptions,
    emojiOptions,
    themeOptions,
    customizedOptions,
    aggregatedOptions: agg,
    type
  } = useSelector((state: RootState) => state.projectConfig);

  const dispatch = useDispatch();

  let aggregatedOptions = agg as any;
  if (type !== 'chart') {
    aggregatedOptions = merge(cloneDeep(themeOptions.options), cloneDeep(customizedOptions), null, {});
  }

  const set = (params: any) => dispatch(setAction(params));

  const closeDimmer = () => {
    set({
      richtextGroupOptions: false,
      emojiOptions: false
    });
  };

  return (
    <>
      {(richtextGroupOptions || emojiOptions) && <div className="dimmer opacity-0" onClick={closeDimmer} />}
      <RichtextGroupModal aggregatedOptions={aggregatedOptions} />
      <EmojiModal />
      <HideElementButton />
      <div className="highed-annotation-help-container bg-ev-navy-blue-2 text-white z-[10002]">
        <div className="highed-annotation-help-text text-white">
          Click anywhere on the chart to add an annotation to it.
        </div>
      </div>
    </>
  );
};

export default GlobalModal;
