export default {
  customFonts: [],
  logos: [],
  activePage: 0,
  pages: [],
  resolution: {
    width: null,
    height: null
  },
  storyId: null,
  showPublishPopup: false,
  loadedPage: [
    [
      {
        style: {
          width: '100%',
          height: '100%'
        },
        settings: {
          buttons: ['add_chart', 'add_container']
        },
        data: []
      }
    ]
  ],
  settings: {
    charts: {
      type: 'img'
    }
  },
  themes: [],
  activeSectionIndex: false,
  isChartModalOpen: false,
  editModalOpen: false,
  editContainerModalOpen: false,
  activeGroupId: false,
  activeContainerId: false,
  isSettingsOpen: false,
  activeComponentName: '',
  chartsUsed: [],
  cmsModalOpen: false,
  uploadingData: false
};
