import { TemplateProps } from '../../types';

const LogarithmicLine: TemplateProps = {
  title: 'Logarithmic line',
  description: '',
  constructor: '',
  thumbnail: 'abywon.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'line',
      polar: false
    },
    yAxis: {
      type: 'logarithmic',
      minorTickInterval: 'auto',
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'line',
  advancedParent: 'Line',
  dataValidator: false,
  advanced: {
    name: 'Logarithmic'
  }
};

export default LogarithmicLine;
