import { compareAsc, format } from 'date-fns';
import { resetStateAction as resetChartState } from 'pages/ChartEditorPage/actions/chartEditor';
import {
  loadCompanyThemeAction,
  resetThemePageSettingsAction,
  switchProjectTypeAction
} from 'pages/CompanyThemeEditorPage/actions/ThemeEditorPage';
import { resetStateAction } from 'pages/LayoutEditorPage/actions/layoutEditor';
import { resetTableStateAction } from 'pages/TableEditorPage/actions/tableEditor';
import TableComponent from 'pages/TableEditorPage/components/TableComponent';
import RichTextEditorToolbar from 'pages/TableEditorPage/containers/RichTextEditorToolbar';
import paths from 'paths';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetProjectConfigAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import { ButtonColor, ButtonType } from '../../shared/buttons/types/ButtonModels';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import TextButton from '../../shared/buttons/TextButton';
import {
  chartTypes,
  createPreviewCharts,
  createPreviewMaps,
  disableStockTools,
  mapTypes
} from './utils/previewChartsHelper';

type PreviewChartsProps = {
  match: {
    params: {
      themeid: string;
    };
  };
};

type ThemeMetaProps = {
  name?: string;
  id?: string;
  isevervizTheme: boolean;
  creator?: string;
  username?: string;
  last_version_time?: Date;
  creation_time?: Date;
};

export default function PreviewCharts(props: PreviewChartsProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectConfig = useSelector((state: RootState) => state.projectConfig);
  const { view, options } = useSelector((state: RootState) => state.companyThemeEditorPage);
  const { themeOptions, seriesAssigns } = projectConfig;
  const themeMeta: ThemeMetaProps = projectConfig.themeMeta;
  useEffect(() => {
    const params = props.match.params;
    disableStockTools();
    dispatch(
      loadCompanyThemeAction({
        themeId: parseInt(params.themeid),
        skipEmit: true,
        cb: (themeOptions: any) => {
          createPreviewCharts(themeOptions);
        }
      })
    );

    return () => {
      dispatch(resetStateAction({}));
      dispatch(resetChartState());
      dispatch(resetProjectConfigAction({}));
      dispatch(resetThemePageSettingsAction({}));
      dispatch(resetTableStateAction());
    };
  }, []);

  useEffect(() => {
    if (view === 'Charts') createPreviewCharts(themeOptions);
    else if (view === 'Maps') createPreviewMaps(themeOptions, options, seriesAssigns);
  }, [view]);

  const sendToThemePage = () => {
    const newPath = paths.profileTeamThemes;
    history.push(newPath);
  };

  const sendToEditPage = (chartId: string) => {
    const newPath = paths.editCompanyTheme.replace(':themeid', chartId);
    history.push(newPath);
  };

  const switchView = (view: string) => {
    dispatch(switchProjectTypeAction({ view, skipLoadTemplate: true }));
  };

  const getTimeLabel = (themeMeta: ThemeMetaProps) => {
    if (!themeMeta.creation_time || !themeMeta.last_version_time) return <></>;

    const creationTimeDate = new Date(themeMeta.creation_time);
    const lastVersionTimeDate = new Date(themeMeta.last_version_time);
    const creationTime = format(creationTimeDate, 'dd/MM/yyyy');
    const lastVersionTime = format(lastVersionTimeDate, 'dd/MM/yyyy');

    return (
      <>
        Created <span title={creationTimeDate.toUTCString()}>{creationTime}</span>, by {themeMeta.creator}.{' '}
        {compareAsc(creationTimeDate, lastVersionTimeDate) === -1 ? (
          <>
            Last edit: <span title={lastVersionTimeDate.toUTCString()}>{lastVersionTime}</span> by {themeMeta.username}.
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <div className="grid gap-x-4 mx-8 grid-cols-4 md:grid-cols-8 md:gap-x-6 md:mx-20 lg:grid-cols-12 lg:mx-20">
      <Helmet>
        <title> everviz </title>
      </Helmet>
      <RichTextEditorToolbar className="hidden" />
      <div className="col-span-4 mt-16 md:col-span-8 lg:col-span-12">
        <h1 className="text-ev-navy-blue-2 font-bold">{themeMeta?.name ?? ''} preview</h1>
      </div>
      <div className="col-span-4 mt-6 md:col-span-8 lg:col-span-12">
        <span className="text-ev-navy-blue">{getTimeLabel(themeMeta)}</span>
      </div>
      <div className="col-span-4 mb-4 pt-10 md:col-span-8 lg:col-span-12">
        <div className="float-left flex gap-1">
          {['Charts', 'Maps', 'Tables'].map((projectType) => {
            return (
              <PrimaryButton
                key={projectType}
                buttonColor={view === projectType ? ButtonColor.NavyBlue : ButtonColor.White}
                buttonType={ButtonType.Info}
                text={projectType}
                onClick={() => switchView(projectType)}
                className={`text-sm font-medium ${view === projectType ? '' : 'underline'}`}
              />
            );
          })}
        </div>

        <div className="float-right">
          <PrimaryButton text="Edit theme" onClick={() => sendToEditPage(themeMeta?.id ?? '')} />
        </div>
      </div>
      {view === 'Charts' &&
        chartTypes.map((type, i) => {
          return <div key={`chart_${i}`} id={`highcharts-${type}`} className="col-span-4 pb-6" />;
        })}
      {view === 'Maps' &&
        mapTypes.map((type, i) => {
          return <div key={`chart_${i}`} id={`highcharts-${type}`} className="col-span-4 pb-6" />;
        })}
      {view === 'Tables' && (
        <div className="story table-editor col-span-4 mb-4 pt-10 md:col-span-8 lg:col-span-12">
          <TableComponent />
        </div>
      )}
      <div className="col-span-4 pt-2 pb-20 md:col-span-8 lg:col-span-12">
        <div className="float-left">
          <TextButton text="<- Go to theme page" onClick={() => sendToThemePage()} />
        </div>
      </div>
    </div>
  );
}
