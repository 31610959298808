export default {
  series: 1,
  data: 'Header;Label;Description\nJan;Kickoff;Meet the team in Barcelona\nFeb;Alpha release;Demo MVP\nMar;Beta release;Most important customer feedback in place\nApr;Marketing campaign;Facebook marketing campaign\nMay;Release candidate;\nJun;Launch and <B>party!</B>;Training for resellers',
  seriesMapping: [
    {
      label: 2,
      y: 0,
      name: 1,
      x: 1,
      description: 3,
    },
  ],
  assignDataFields: [
    {
      label: 'B',
      y: 'A',
      name: 'A',
      description: 'C',
    },
  ],
};
