/* eslint-disable react/jsx-no-bind */

import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { checkLoggedIn, logout, onLoggedIn } from '../utils/profileHelper'; // Remove this when fully converted

import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProfileNavigation from 'shared/nav/ProfileNavigation';
import NotFound from '../pages/NotFoundPage/NotFound';
import ErrorBoundary from '../shared/ErrorBoundary';
import WizardLoading from '../shared/editor/generic/WizardLoading';
import Loading from '../shared/loader/Loading';
import CookieConsent from '../shared/message/CookieConsent';
import PaidPlanModal from '../shared/message/PaidPlanModal';
import TopBar from './../shared/nav/TopBar';
import ProfileRoutes from './ProfileRoutes';
import PublicRoutes from './PublicRoutes';
import TrialExpiredModal from 'shared/message/TrialExpiredModal';
import PremiumFeatureModal from '../shared/message/PremiumFeatureModal';
import CreateSubteamModal from 'pages/TeamPage/components/CreateSubteamModal';

window.Quill = Quill;
class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.setNavActions = this.setNavActions.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.updateNavActions = this.updateNavActions.bind(this);
    this.showAnnouncements = this.showAnnouncements.bind(this);
    this.openNewSubteamModal = this.openNewSubteamModal.bind(this);

    if (typeof NodeList.prototype.forEach !== 'function') {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }

    this.state = {
      navActions: {},
      navOpen: false,
      isCookieConsentVisible: !localStorage.getItem('gdpr-consent')
    };

    // TODO: Remove references to refs
    this.topBarElement = React.createRef();
    this.navigationElement = React.createRef();
    checkLoggedIn().then((response) => {
      if (response.loggedIn) {
        onLoggedIn();
      } else {
        this.props.set({ loading: false });
      }
    });
  }

  setNavActions(newNavActions) {
    let navActions = this.state.navActions;
    navActions[location.pathname] = newNavActions;
    this.setState({ navActions });
  }

  updateNavActions(newNavAction) {
    let navActions = this.state.navActions;
    navActions[location.pathname].unshift(newNavAction);
    this.setState({ navActions });
  }

  toggleMenu() {
    if (this.navigationElement) this.navigationElement.toggleMenu();
    else {
      setTimeout(
        function (self) {
          self.navigationElement.toggleMenu();
        },
        500,
        this
      );
    }
  }

  closeMenu() {
    this.setState({ navOpen: false });
  }

  showAnnouncements() {
    this.topBarElement.current.showNotifications();
  }

  openNewSubteamModal() {
    this.props.openNewSubteamModal();
  }

  render() {
    const { team, paths } = this.props;

    return (
      <Router>
        <PaidPlanModal paths={paths} isOpen={this.props.isPaidPlanModalOpen} close={this.props.closePaidPlan} />
        <TrialExpiredModal />
        <PremiumFeatureModal isOpen={this.props.isPremiumFeatureModalOpen} close={this.props.closePremiumFeature} />

        <CreateSubteamModal />

        <TopBar
          wrappedComponentRef={(ref) => (this.topBarElement = ref)}
          navActions={this.state.navActions}
          logout={logout}
          toggleMenu={this.toggleMenu}
          closeMenu={this.closeMenu}
          navOpen={this.state.navOpen}
          showDivisionModal={this.openNewSubteamModal}
        />

        <div
          className={
            'page-container container-fluid ' + (this.state.navOpen ? 'active ' : '') + (!team ? ' not-logged-in' : '')
          }
        >
          <Sentry.ErrorBoundary
            showDialog={true}
            fallback={ErrorBoundary}
            dialogOptions={{
              subtitle: 'We have already been notified!',
              subtitle2: 'Help us out by telling us what happened.'
            }}
          >
            <Suspense fallback={<Loading text="Loading team..." />}>
              <Switch>
                {PublicRoutes({
                  ...this.props,
                  navActions: this.state.navActions,
                  setNavActions: this.setNavActions,
                  updateNavActions: this.updateNavActions,
                  toggleMenu: this.toggleMenu
                })}

                <ProfileRoutes
                  {...this.props}
                  openNewDivisionModal={this.openNewSubteamModal}
                  setNavActions={this.setNavActions}
                  toggleMenu={this.toggleMenu}
                />

                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </Sentry.ErrorBoundary>
        </div>

        {team && <ProfileNavigation />}

        <WizardLoading wizardLoadingText={this.props.wizardLoadingText} />

        <CookieConsent />
      </Router>
    );
  }
}

export default App;
