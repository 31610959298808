import evervizTheme from '../../../editor/meta/highed.meta.everviz.theme';
import CustomCodeDefault from './../../../editor/meta/highed.meta.customcode.default';

export default {
  chartData: {},
  customCode: false,
  customCodeStr: CustomCodeDefault.join('\n'),
  editorError: '',
  defaultTheme: evervizTheme,
  chosenWizardTemplate: false,
  annotationSection: {
    labels: false,
    highlights: false,
    lines: false
  },
  countryValue: {
    label: 'United States of America',
    value: 'countries/us/us-all.js'
  },
  categoryValue: {
    label: 'Standard',
    value: 'countries/us/us-all.js'
  },
  editorConfig: { features: '' },
  editorChartConfig: {},
  showGeoJSONModal: false,
  geoJSONMapData: null,
  showPublishModal: false,
  useInlineEditor: true,
  inlineEditor: false,
  isThemeEditor: false,
  chartId: null,
  chartVersion: null,
  unsavedChangesText:
    'Leaving this page will result in unsaved changes being deleted. Are you sure you want to continue?',
  division: null,
  localTeam: null,
  referenced: 0,
  pollingCounter: 0,
  signupModalOpen: false,
  cmsModalOpen: false,
  selectedPlan: {
    id: 1,
    name: 'Free'
  },
  isMap: false,
  dataTextValue: {
    text: ''
  },
  passwordProtected: {
    enabled: false,
    password: ''
  },
  initedEditor: false,
  mappedData: [],
  inspector: false,
  hideElementButtonOptions: null,
  constr: 'Chart',
  editorChartIndex: 0
};
