import { TemplateProps } from '../../types';

const HorizontalColumnRange: TemplateProps = {
  title: 'Horizontal columnrange',
  description: '',
  constructor: '',
  thumbnail: 'iqagel.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'columnrange',
      inverted: true,
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'bar',
  advancedParent: 'Bar',
  defaultDataType: 'columnrange',
  dataValidator: false
};

export default HorizontalColumnRange;
