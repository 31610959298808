import config from '../config';

const builtIns = {
  recurly: (config, cb) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.recurly.com/v4/recurly.js';
    script.onload = () => {
      recurly.configure({
        publicKey: config.publicKey,
        fields: {
          all: {
            style: {
              fontFamily: 'Red Hat Text',
              fontColor: '#656565',
              fontSize: '15px',
              fontWeight: '800',
              placeholder: {
                color: '#656565 !important',
                fontWeight: '300',
                opacity: '0.8'
              }
            }
          },
          number: {
            selector: '[data-recurly="number"]',
            style: {
              placeholder: {
                content: 'Credit card number'
              }
            }
          },
          month: {
            selector: '[data-recurly="month"]',
            style: {
              placeholder: {
                content: 'MM'
              }
            }
          },
          year: {
            selector: '[data-recurly="year"]',
            style: {
              placeholder: {
                content: 'YYYY'
              }
            }
          },
          cvv: {
            selector: '[data-recurly="cvv"]',
            style: {
              placeholder: {
                content: 'CVV'
              }
            }
          }
        }
      });
      if (cb) cb();
    };
    document.querySelector('head').appendChild(script);

    return (form) => {
      return new Promise((resolve, reject) => {
        recurly.token(form, (error, token) => {
          return error ? reject(error) : resolve(token.id);
        });
      });
    };
  }
};

const setupProvider = (cb) => {
  const paymentConfig = config.billing.provider;
  payment.strategy[paymentConfig.name](paymentConfig, cb);
};

const payment = {
  /**
   * Send information to the payment provider
   * @param {function} strategy - The payment provider strategy to use
   * @param {binary} form - The form to push
   * @return {promise}
   */
  saveInfo: (strategy, form) => {
    return strategy && strategy(form);
  },

  setup3dSecure: (three_d_token, token_id, cb) => {
    function setupRecurly3dSecure() {
      let risk = window.recurly.Risk();
      let threeDSecure = risk.ThreeDSecure({
        actionTokenId: three_d_token
      });

      threeDSecure.on('token', function (token) {
        let container = document.querySelector('#recurly-3dsecure');
        container.style.display = 'none';

        if (cb) cb(token.id, token_id);
      });

      threeDSecure.on('error', function () {
        let container = document.querySelector('#recurly-3dsecure');
        container.innerHTML = '';
        container.style.display = 'none';
      });

      let container = document.querySelector('#recurly-3dsecure');
      container.innerHTML = '';

      container.style.display = 'block';
      threeDSecure.attach(container);
    }

    if (!window.recurly) {
      setupProvider(() => {
        setupRecurly3dSecure();
      });
      return;
    }
    setupRecurly3dSecure();
  },
  /* Namespace for built-in strategies */
  strategy: builtIns
};

export default payment;
