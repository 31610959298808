import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import Model from './RichTextEditorModel';

var alignStyle = Quill.import('attributors/style/align');
Quill.register(alignStyle, true);

let Link = Quill.import('formats/link');
class MyLink extends Link {
  static create(value) {
    const node = super.create(value);
    if (value.indexOf('https://') === -1 && value.indexOf('http://') === -1) {
      node.setAttribute('href', 'https://' + value);
    }
    return node;
  }
}

Quill.register(MyLink, true);

export default class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.attachQuill = this.attachQuill.bind(this);

    this.state = {
      text: '',
      modules: {
        toolbar: {
          container: '#toolbar',
        },
        keyboard: {
          bindings: {
            enter: {
              key: 13,
              handler: () => {
                this.props.finishAndGoDown();
                return false;
              },
            },
          },
        },
        clipboard: {
          newLines: false,
        },
      },
      formats: [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'color',
        'link',
        'image',
        'align',
        'script',
      ],
    };
  }

  handleChange(value) {
    if (this.props.handleCEUpdate) {
      this.setState({ text: value });
      this.props.handleCEUpdate(this.props.isChart, this.props.row, this.props.column, value);
    }
  }

  componentDidMount() {
    this.setState({
      text: this.props.text,
    });

    //this.editor.focus();

    setTimeout(() => {
      if (Model.editor) {
        this.attachQuill();
        const editor = Model.editor.getEditor();
        var text = editor.getText().replace('\n', '');

        delete editor.getModule('keyboard').bindings['9'];
        if (this.props.keySet) {
          editor.setText(text + this.props.keySet);
          editor.setSelection(text.length + 1 || 0, 0, 'user');
          editor.root.addEventListener('blur', () => {
            this.props.resetKey();
          });
        } else {
          editor.setSelection(text.length || 0, 0, 'user');
        }
      }
    }, 0);
  }

  attachQuill() {}

  render() {
    return (
      <div className="quill-editor-container" data-row={this.props.row} data-column={this.props.column}>
        <ReactQuill
          theme="snow"
          onKeyDown={this.props.keyPress}
          value={this.state.text}
          onChange={this.handleChange}
          modules={this.state.modules}
          formats={this.state.formats}
          style={this.props.cellStyle}
          ref={(element) => {
            Model.editor = element;
          }}
        />
      </div>
    );
  }
}
