export const redPinIcon = {
  id: 'red_blue',
  name: 'Red Pin',
  keywords: ['pin', 'red', 'marker'],
  skins: [
    {
      src: 'static/icons/mapbox/pin_red.png',
      unified:
        '<svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6673 33.5876C5.61414 27.5345 5.61414 17.7204 11.6673 11.6673C17.7204 5.61416 27.5344 5.61416 33.5876 11.6673C39.6407 17.7204 39.6407 27.5345 33.5876 33.5876L22.6274 44.5478L11.6673 33.5876Z" fill="#BC094A" stroke="white"/><circle cx="23" cy="20" r="6" fill="white"/></svg>'
    }
  ]
};

export default [
  {
    id: 'pin_blue',
    name: 'Blue Pin',
    keywords: ['pin', 'blue', 'marker'],
    skins: [
      {
        src: 'static/icons/mapbox/pin_blue.png',
        unified:
          '<svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6673 33.5876C5.61414 27.5345 5.61414 17.7204 11.6673 11.6673C17.7204 5.61416 27.5344 5.61416 33.5876 11.6673C39.6407 17.7204 39.6407 27.5345 33.5876 33.5876L22.6274 44.5478L11.6673 33.5876Z" fill="#172B4C" stroke="white"/> <circle cx="23" cy="21" r="6" fill="white"/></svg>'
      }
    ]
  },
  redPinIcon,
  {
    id: 'pin_white',
    name: 'White Pin',
    keywords: ['pin', 'white', 'marker'],
    skins: [
      {
        src: 'static/icons/mapbox/pin_white.png',
        unified:
          '<svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6673 33.5876C5.61414 27.5345 5.61414 17.7204 11.6673 11.6673C17.7204 5.61416 27.5344 5.61416 33.5876 11.6673C39.6407 17.7204 39.6407 27.5345 33.5876 33.5876L22.6274 44.5478L11.6673 33.5876Z" fill="white" stroke="#162A4D"/><circle cx="23" cy="21" r="6" fill="#162A4D"/></svg>'
      }
    ]
  }
];
