import { put, takeLatest, all, call } from 'redux-saga/effects';
import { setAction, getTeamKeysAction } from '../actions/apiPage';
import actionTypes from '../../../redux/actions/action-types';

import {
  getTeamKeysWithTeamid,
  delTeamKeyUsingTeamidAndKeyid,
  postTeamKeysGenerateUsingTeamid,
  postTeamKeyUsingTeamidAndKeyid,
} from '../../../api/cloudApiGenerated';

export function* getTeamKeys(params) {
  let { team, pageSize, page } = params.data;

  try {
    let data = yield call(getTeamKeysWithTeamid, team.id, page, pageSize);
    yield put(
      setAction({
        keys: data.data,
        pageCount: data.pageCount,
        page: data.page,
        dataReturned: true,
      })
    );
  } catch (e) {
    yield put(
      setAction({
        errorMessage: e.response.message.join(' '),
        dataReturned: true,
      })
    );
  }
}

export function* setTeamKey(params) {
  let { groupId, name, team, page, pageSize } = params.data;

  if (groupId === '' && name === '') {
    yield put(
      setAction({
        error: 'Please fill in the name of the key and the group it is associated with to generate an API key',
      })
    );
    return;
  }

  try {
    yield call(postTeamKeysGenerateUsingTeamid, team.id, { name, group: groupId });
    yield put(
      getTeamKeysAction({
        team,
        page,
        pageSize,
      })
    );

    yield put(
      setAction({
        groupId: '',
        name: '',
      })
    );
  } catch (e) {
    yield put(
      setAction({
        error: e.response.message.join(' '),
      })
    );
  }
}

export function* updateKey(params) {
  let { team, row, page, pageSize } = params.data;

  try {
    let data = yield call(postTeamKeyUsingTeamidAndKeyid, team.id, row.id, { name: row.name, group: row.group_id });

    yield put(
      getTeamKeysAction({
        team,
        page,
        pageSize,
      })
    );
  } catch (e) {
    yield put(
      setAction({
        error: 'setting access group of member: ' + error.message.join(' '),
      })
    );
  }
}

export function* deleteKeys(params) {
  let { keys, team, page, pageSize } = params.data;
  let promises = [];

  keys.forEach((key) => {
    if (key) {
      promises.push(call(delTeamKeyUsingTeamidAndKeyid, team.id, key.id));
    }
  });

  try {
    let results = yield all(promises);
    yield put(
      getTeamWebhooksAction({
        id: team.id,
      })
    );

    yield put(
      getTeamKeysAction({
        team,
        page,
        pageSize,
      })
    );
  } catch (e) {}
}

/** Watch functions */

export function* watchGetTeamKeys() {
  yield takeLatest(actionTypes.apiPage.getTeamKeys, getTeamKeys);
}

export function* watchSetTeamKey() {
  yield takeLatest(actionTypes.apiPage.setTeamKey, setTeamKey);
}

export function* watchDeleteKeys() {
  yield takeLatest(actionTypes.apiPage.deleteKeys, deleteKeys);
}

export function* watchUpdateKey() {
  yield takeLatest(actionTypes.apiPage.updateKey, updateKey);
}

export default function* rootSaga() {
  yield all([watchGetTeamKeys(), watchSetTeamKey(), watchDeleteKeys(), watchUpdateKey()]);
}
