import qs from 'qs';
import { snackBar } from 'editor/editors/highed.init';
import { saveChartAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { setTeamMembersAction } from 'pages/TeamPage/actions/teamMembersAction';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { handleGoogleTagManagerEvent } from 'shared/utils/googleTagManagerHelper';
import {
  delTeamUsingTeamid,
  getAccountAnnouncements,
  getDivisionsWithTeamid,
  getProfile,
  getTeamBillingPermissionsWithTeamid,
  getTeamMessagesAllWithTeamid,
  getTeamRolesWithTeamid,
  getTeamSubscriptionActiveWithTeamid,
  getTeams,
  getUserPermissionsWithTeamid,
  logout,
  getTeamExportsWithTeamid,
  delTeamExportsUsingTeamidAndExportid
} from '../api/cloudApiGenerated';
import { pageUpdateAction, getChartsAction } from '../pages/ProjectsPage/actions/projectsPage';
import { getRecentExportAction } from 'redux/actions/profile';
import actionTypes from '../redux/actions/action-types';
import { setAction as setAppAction } from '../redux/actions/app';
import { getGroupsAction, getSubscriptionAction, setAction } from '../redux/actions/profile';
import { getProfileConfig } from './../redux/selectors/profile';

export function* checkLocalStorage(teams, user, getLast) {
  const storedTeam = JSON.parse(localStorage.getItem('team')) || {};
  const hasTeam = (teams || []).some((e) => e.id === storedTeam.id);

  if (localStorage.getItem('team') !== null && !getLast) {
    // When refreshing the page or logged in to account whilst logged in to another already
    if (hasTeam) {
      return JSON.parse(localStorage.getItem('team'));
    }
    localStorage.removeItem('team');
    return yield call(checkLocalStorage, teams, user, getLast);
  } else if (teams.length !== 0) {
    // When first logging in
    let team = getLast ? teams[teams.length - 1] : teams[0];
    localStorage.setItem('team', JSON.stringify(team));
    return team;
  }
  // No team
  yield put(setAction({ user, team: {}, teams: [] }));

  yield put(
    setAppAction({
      loading: false
    })
  );

  return '';
}

export function* checkLocalStorageForDivisions(divisions, user, getLast) {
  const storedDivision = JSON.parse(localStorage.getItem('division')) || {};
  const hasDivision = (divisions || []).some((e) => e.id === storedDivision.id);
  if (localStorage.getItem('division') !== null && !getLast) {
    // When refreshing the page or logged in to account whilst logged in to another already
    if (hasDivision) {
      return JSON.parse(localStorage.getItem('division'));
    }
    localStorage.removeItem('division');
    return yield call(checkLocalStorageForDivisions, divisions, user, getLast);
  } else if (divisions.length !== 0) {
    // When first logging in
    let division = getLast ? divisions[divisions.length - 1] : divisions[0];
    localStorage.setItem('division', JSON.stringify(division));
    return division;
  }
  return divisions[0];
}

export function* getUserProfile(params) {
  const { config, getLast, announcements, callback, noLoading, newUser, saveChartAfterLogin, history, search } =
    params.data;
  localStorage.removeItem('editorTeam');

  if (!noLoading) {
    yield put(
      setAppAction({
        loading: true
      })
    );
  }

  try {
    let result = [];

    result[0] = yield call(getProfile);
    result[1] = yield call(getTeams, 1, 1000);

    let user = result[0],
      teams = result[1];

    if (teams) {
      teams.forEach((team) => {
        if (!team.beta_features) {
          team.beta_features = [];
          return;
        }
        team.beta_features = team.beta_features.split(',').map((d) => parseInt(d, 10));
      });
    }

    let team = {};

    team = yield call(checkLocalStorage, teams, user, getLast);

    if (newUser && team) {
      // User is a new Google oauth user, tag it for analytics
      handleGoogleTagManagerEvent({
        event: 'signup',
        signupMethod: 'google'
      });
    }

    if (team === '') return;

    const promises = [call(getUserPermissionsWithTeamid, team.id)];
    if (config.billing.enabled) promises.push(call(getTeamBillingPermissionsWithTeamid, team.id));
    promises.push(call(getTeamMessagesAllWithTeamid, team.id));

    let results;
    results = yield all(promises);

    let unreadMessages = [];

    let data = yield call(getDivisionsWithTeamid, team.id);
    let divisions = [];
    if (
      data.length === 0 ||
      (results[0] && (results[0].all_subteams || (team && user && team.owner_user === user.id)))
    ) {
      divisions = [{ id: null, name: 'All subteams' }];
    }

    divisions = divisions.concat(data);

    let division = yield call(checkLocalStorageForDivisions, divisions, user);
    yield put(
      setAction({
        divisions,
        division
      })
    );

    let tempAnnouncements = announcements ?? [];
    if (!announcements) {
      tempAnnouncements = yield call(getAccountAnnouncements, user.id);
    }

    let messagesIndex = 2;
    if (!config.billing.enabled) {
      messagesIndex = 1;
    }

    results[messagesIndex].data.forEach(function (e) {
      if (!e.read) unreadMessages.push(e);
    });

    let messages = results[messagesIndex].data.filter((x) => {
      return unreadMessages.findIndex((y) => y.id === x.id) === -1;
    });

    team.plan_id = results[1].plan_id;
    team.charts_max = results[1].charts_max;

    yield put(
      setAction({
        user,
        teams,
        team,
        permissions: results[0],
        billingPermissions: results[1].permissions,
        messages,
        unreadMessages,
        announcements: tempAnnouncements,
        chartsCreated: results[1].charts_created
      })
    );

    yield put(
      setAppAction({
        loading: false
      })
    );
    yield put(getGroupsAction({ team }));
    yield put(getSubscriptionAction({ team, config }));

    // Side-effect, redirect if coming from Discourse
    _authenticateDiscourse(user.id, search);

    if (callback) {
      return callback(user, team);
    }
    if (saveChartAfterLogin) {
      const chartConfig = JSON.parse(localStorage.getItem('chartConfig'));
      if (chartConfig) {
        yield put(saveChartAction({ reload: true, body: chartConfig, history }));
        localStorage.removeItem('chartConfig');
      }
    }
  } catch (e) {
    yield put(setAppAction({ loading: false }));
    yield put(setAction({ user: undefined }));
  }
}

export function* getSubscription(params) {
  let { team, config } = params.data;

  if (!config.billing.enabled) return;

  try {
    let subscription = yield call(getTeamSubscriptionActiveWithTeamid, team.id);
    yield put(
      setAction({
        subscription
      })
    );

    yield put(
      setAppAction({
        error: false
      })
    );
  } catch (error) {
    yield put(
      setAppAction({
        error: "Couldn't load your team's subscription"
      })
    );
    throw new Error(error);
  }
}

export function* setTeam(params) {
  let { team, loadDivisionCallback, config, pathname } = params.data;
  const profile = yield select(getProfileConfig);
  const promises = [call(getUserPermissionsWithTeamid, team.id)];
  if (config.billing.enabled) promises.push(call(getTeamBillingPermissionsWithTeamid, team.id));
  promises.push(call(getTeamMessagesAllWithTeamid, team.id));

  try {
    let results = yield all(promises);

    let data = yield call(getDivisionsWithTeamid, team.id);

    let user = profile.user,
      divisions = [];

    if (
      data.length === 0 ||
      (results[0] && (results[0].all_subteams || (team && user && team.owner_user === user.id)))
    ) {
      divisions = [{ id: null, name: 'All subteams' }];
    }

    let messagesIndex = 2;
    if (!config.billing.enabled) {
      messagesIndex = 1;
    }

    divisions = divisions.concat(data);

    let unreadMessages = [];

    results[messagesIndex].data.forEach(function (e) {
      if (!e.read) unreadMessages.push(e);
    });

    let messages = results[messagesIndex].data.filter((x) => {
      return unreadMessages.findIndex((y) => y.id === x.id) === -1;
    });

    team.plan_id = results[1].plan_id;
    team.charts_max = results[1].charts_max;

    let subscription;
    try {
      subscription = yield call(getTeamSubscriptionActiveWithTeamid, team.id);
    } catch (error) {
      yield put(
        setAppAction({
          error: "Couldn't load your team's subscription"
        })
      );
      throw new Error(error);
    }

    yield put(
      setAction({
        permissions: results[0],
        billingPermissions: results[1].permissions,
        messages,
        unreadMessages,
        divisions,
        subscription,
        division: divisions[0],
        team,
        chartsCreated: results[1].charts_created
      })
    );

    if (pathname === '/profile/members') {
      yield put(
        setTeamMembersAction({
          activeDivision: { val: null, label: 'All subteams' }
        })
      );
    }

    if (loadDivisionCallback) loadDivisionCallback();

    localStorage.setItem('team', JSON.stringify(team));
  } catch (e) {
    throw new Error(e);
  }

  snackBar('Changed team');
}

export function* getGroups(params) {
  let { team } = params.data;

  try {
    let data = yield call(getTeamRolesWithTeamid, team.id);
    yield put(
      setAction({
        groups: data.data
      })
    );
  } catch (error) {
    yield put(
      setAppAction({
        error: 'loading groups: ' + error.message.join(' ')
      })
    );
  }
}

export function* deleteTeam(params) {
  const { team } = params.data;
  yield call(delTeamUsingTeamid, team.id);
}

export function* logoutUser() {
  yield call(logout);
  yield put(
    setAction({
      subscription: null
    })
  );
}

export function* reloadDivisions(params) {
  const { team, profile, checkPermission } = params.data;

  yield put(
    setAppAction({
      loading: true
    })
  );
  localStorage.removeItem('division');

  try {
    let data = yield call(getDivisionsWithTeamid, team.id);
    let divisions = [];
    if (data.length === 0 || checkPermission('all_subteams', profile)) {
      divisions = [{ id: null, name: 'All subteams' }];
    }
    divisions = divisions.concat(data);
    localStorage.setItem('division', JSON.stringify(divisions[divisions.length - 1]));
    yield put(
      setAction({
        divisions,
        division: divisions[divisions.length - 1]
      })
    );
    yield put(
      setAppAction({
        loading: false
      })
    );
  } catch (e) {
    throw new Error(e);
  }
}

// Handle a redirect to Discourse if it is detected that the user came from the forum
// when attempting to sign in.
const _authenticateDiscourse = (user_id, search) => {
  let query = qs.parse(search, { ignoreQueryPrefix: true });

  // Coming from Google, unpack the b64 encoded options
  if ('state' in query) {
    query = JSON.parse(atob(query.state));
  }

  if ('sso' in query) {
    const q = qs.stringify({ uid: user_id, ...query }, { addQueryPrefix: true });
    const { hostname, port } = window.hcconfig.backend;
    const endpoint = hostname + (port ? `:${port}` : '');
    location.href = `${endpoint}/discourse/sso${q}`;
  }
};

export function* updateChartCount() {
  const config = yield select(getProfileConfig);
  yield put(
    setAction({
      chartsCreated: config.chartsCreated + 1
    })
  );
}

export function* setSubteams() {
  yield put(pageUpdateAction({ page: 1 }));
}

export function* getRecentExportHistory() {
  const { recentExports, team } = yield select((state) => state.profile);
  const recentExportsCopy = [...recentExports];
  const response = yield call(getTeamExportsWithTeamid, team.id);
  yield put(
    setAction({
      previousExports: recentExportsCopy,
      recentExports: response
    })
  );
}

export function* deleteRecentExport(params) {
  const { teamId, exportId } = params.data;
  try {
    yield call(delTeamExportsUsingTeamidAndExportid, teamId, exportId);
    yield put(getRecentExportAction());
    yield put(getChartsAction());
  } catch (error) {
    yield put(setAction({ error: 'deleting recent export: ' + error.toString() }));
  }
}

/** Watch functions */
export function* watchGetProfile() {
  yield takeLatest(actionTypes.profile.getProfile, getUserProfile);
}

export function* watchSetTeam() {
  yield takeLatest(actionTypes.profile.setTeam, setTeam);
}

export function* watchDeleteTeam() {
  yield takeLatest(actionTypes.profile.deleteTeam, deleteTeam);
}

export function* watchGetGroups() {
  yield takeLatest(actionTypes.profile.getGroups, getGroups);
}

export function* watchGetSubscription() {
  yield takeLatest(actionTypes.profile.getSubscription, getSubscription);
}

export function* watchLogout() {
  yield takeLatest(actionTypes.profile.logout, logoutUser);
}

export function* watchReloadDivisions() {
  yield takeLatest(actionTypes.profile.reloadDivisions, reloadDivisions);
}
export function* watchUpdateChartCount() {
  yield takeLatest(actionTypes.profile.updateChartCount, updateChartCount);
}
export function* watchSetSubteams() {
  yield takeLatest(actionTypes.profile.setSubteams, setSubteams);
}

export function* watchGetRecentExportHistory() {
  yield takeLatest(actionTypes.profile.getRecentExportHistory, getRecentExportHistory);
}
export function* watchDeleteRecentExport() {
  yield takeLatest(actionTypes.profile.deleteRecentExport, deleteRecentExport);
}
// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchGetProfile(),
    watchSetTeam(),
    watchDeleteTeam(),
    watchGetGroups(),
    watchGetSubscription(),
    watchLogout(),
    watchReloadDivisions(),
    watchUpdateChartCount(),
    watchSetSubteams(),
    watchGetRecentExportHistory(),
    watchDeleteRecentExport()
  ]);
}
