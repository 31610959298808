import { connect } from 'react-redux';
import ColumnSelector from '../../shared/widgets/ColumnSelector';
import {
  changeColumnOrderAction,
  processConditionalFormattingAction,
  updateCustomizedArrayPropAction
} from '../../pages/TableEditorPage/actions/tableEditor.js';

const mapStateToProps = (state) => {
  return {
    isGSheet: state.tableEditorPage.isGSheet,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeColumnOrder: ( from, to, updateData) =>
  dispatch(changeColumnOrderAction({ from, to, updateData })),
  processConditionalFormatting: (index, rule, value, color) =>
    dispatch(processConditionalFormattingAction({ index, rule, value, color })),
  updateCustomizedArrayProp: (parent, index, val, moveObj) =>
    dispatch(updateCustomizedArrayPropAction({ parent, index, val, moveObj })),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ColumnSelector);
