import { TemplateProps } from '../../types';

const Donut3D: TemplateProps = {
  title: '3D Donut',
  description: '',
  constructor: '',
  thumbnail: 'ehuvoh.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0
      },
      polar: false
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        depth: 35,
        cursor: 'pointer',
        innerSize: '60%'
      },
      series: {
        dataLabels: {
          enabled: true
        }
      }
    }
  },
  sampleSet: 'pie',
  dataValidator: false,
  advanced: {
    name: '3D'
  },
  advancedParent: 'Donut'
};

export default Donut3D;
