import React from 'react';
import Chart from './DraggableComponent';
import SelectInput from '../../../shared/inputs/SelectInput';
import Buttons from '../containers/Buttons';
import { getTemplateOptions } from '../meta/config/templates';
import Timer from './Timer';
import LayoutTab from './LayoutTab';

class Carousel extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.carouselBack = this.carouselBack.bind(this);
    this.carouselForward = this.carouselForward.bind(this);
    this.stopCarouselAuto = this.stopCarouselAuto.bind(this);
    this.makeCarouselAuto = this.makeCarouselAuto.bind(this);
    this.getNextChartFromCarousel = this.getNextChartFromCarousel.bind(this);
    this.toggleCarousel = this.toggleCarousel.bind(this);
    this.getDuration = this.getDuration.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.changePage = this.changePage.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);

    this.state = {
      activeCarouselIndex: 0,
      intervalAction: false,
      animState: 'stop'
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataOptions.length > this.props.dataOptions.length) {
      //Data option has been deleted from the customize section. Revert carousel back to 0
      this.setState({
        activeCarouselIndex: 0
      });
    }

    if (prevProps.dataOptions.length < this.props.dataOptions.length) {
      this.setState({
        activeCarouselIndex: this.props.dataOptions.length - 1
      });
    }

    if (prevProps.aggregatedOptions.layout.auto !== this.props.aggregatedOptions.layout.auto) {
      if (this.props.aggregatedOptions.layout.auto) this.makeCarouselAuto();
      else this.stopCarouselAuto();
    }
  }

  stopCarouselAuto() {
    if (this.state.intervalAction) {
      clearTimeout(this.state.intervalAction);
      this.setState({
        animState: 'stop'
      });
    }
  }

  getNextChartFromCarousel() {
    let nextVal = this.state.activeCarouselIndex + 1;
    if (nextVal >= this.props.dataOptions.length) nextVal = 0;
    this.setState({
      activeCarouselIndex: nextVal,
      animState: 'play'
    });
  }

  makeCarouselAuto() {
    this.stopCarouselAuto();
    this.getNextChartFromCarousel();
    this.setState({
      intervalAction: setTimeout(this.makeCarouselAuto, this.getDuration())
    });
  }

  carouselBack() {
    if (this.state.activeCarouselIndex <= 0) return;
    this.setState({
      activeCarouselIndex: this.state.activeCarouselIndex - 1
    });
  }

  carouselForward() {
    let nextVal = this.state.activeCarouselIndex + 1;
    if (nextVal >= this.props.dataOptions.length) return;
    this.setState({
      activeCarouselIndex: this.state.activeCarouselIndex + 1
    });
  }

  toggleCarousel() {
    const currentAnimState = this.state.animState;

    this.stopCarouselAuto();
    if (currentAnimState === 'stop') {
      this.makeCarouselAuto();
    }
    this.setState({
      animState: currentAnimState === 'stop' ? 'play' : 'stop'
    });
  }

  getDuration() {
    if (this.props.aggregatedOptions.layout.animDuration) {
      return this.props.aggregatedOptions.layout.animDuration * 1000;
    } else return 5000;
  }

  changePage(e) {
    this.setState({
      activeCarouselIndex: parseInt(e.currentTarget.getAttribute('data-value'))
    });
  }

  onRemove() {
    this.props.onRemove(this.state.activeCarouselIndex);
    this.setState({
      activeCarouselIndex: 0
    });
  }

  onDropdownChange(e) {
    this.setState({
      activeCarouselIndex: e.target.value
    });
  }

  render() {
    const { activeCarouselIndex, animState } = this.state;
    const { dataOptions, templateOptions, templateUsed, aggregatedOptions } = this.props;
    const setting = getTemplateOptions(templateUsed);

    const section = dataOptions[activeCarouselIndex || 0];
    const isAuto = aggregatedOptions.layout && aggregatedOptions.layout.auto;
    const style = (aggregatedOptions && aggregatedOptions.layout && aggregatedOptions.layout.style) || {};
    const usingCarousel = templateUsed === 'carousel';
    const usingDropdown = templateUsed === 'dropdown';
    const usingTabs = templateUsed === 'tabs';

    return (
      <span className="carousel-container">
        {usingDropdown && (
          <div className="tabs-container dropdown-container">
            <SelectInput
              name="select-dropdown"
              onChange={this.onDropdownChange}
              label={''}
              extraClass=""
              options={dataOptions.map((d, index) => {
                return {
                  value: index,
                  text: d.name || 'Tab ' + (index + 1)
                };
              })}
              value={activeCarouselIndex}
            />
          </div>
        )}

        {usingTabs && (
          <LayoutTab dataOptions={dataOptions} activeCarouselIndex={activeCarouselIndex} changePage={this.changePage} />
        )}

        {usingTabs && (
          <div className="tabs-container">
            {dataOptions.map((data, index) => {
              let buttonStyle = { ...style };
              const active = index === activeCarouselIndex;

              if (active) {
                delete buttonStyle.color;
                delete buttonStyle.backgroundColor;
                if (style.activeButtonColor) {
                  buttonStyle.backgroundColor = style.activeButtonColor;
                }
                if (style.activeColor) {
                  buttonStyle.color = style.activeColor;
                }
              }

              let linkButtonStyle = index === 0 || index === 1 ? 'border-l-1 ' : 'border-l-0 ';

              if (!data.hash) return <span />;
              return (
                <button
                  style={buttonStyle}
                  key={index}
                  className={
                    'ev-sm-max:hidden ev-md:text-sm text-xs btn link-button ' +
                    linkButtonStyle +
                    (active ? 'active ' : ' ') +
                    (index + 1)
                  }
                  data-value={index}
                  onClick={this.changePage}
                >
                  {data.name || 'Tab ' + (index + 1)}
                </button>
              );
            })}
          </div>
        )}

        <div
          className={
            'section ' + (setting && setting[activeCarouselIndex] && setting[activeCarouselIndex].settings.classes)
          }
          style={templateOptions?.[0]?.style}
        >
          <Timer duration={this.getDuration()} animState={this.state.animState} />

          {section && section.hash && (
            <Chart
              type={'img'}
              onRemove={this.onRemove}
              option={section}
              id={activeCarouselIndex}
              carouselMode={true}
              aggregatedOptions={aggregatedOptions}
            />
          )}

          {!section || (!section.chart_id && <Buttons section={section} sectionIndex={activeCarouselIndex} />)}
        </div>

        {usingCarousel && (
          <div
            className={
              'page-buttons-container ' +
              (aggregatedOptions && aggregatedOptions.layout ? aggregatedOptions.layout.buttonPosition : '')
            }
          >
            <div className="page-button-container left" style={style} onClick={this.carouselBack}>
              <div className="page-buttons page-left-button" style={style}>
                <i className="fa fa-chevron-left" />
              </div>
            </div>
            <div className="page-button-container right" style={style} onClick={this.carouselForward}>
              <div className="page-buttons page-right-button" style={style}>
                <i className="fa fa-chevron-right" />
              </div>
            </div>
          </div>
        )}

        {isAuto && (
          <div
            className={
              'page-button-container pause-play ' +
              (aggregatedOptions && aggregatedOptions.layout ? aggregatedOptions.layout.buttonPosition : '')
            }
          >
            <div className="page-buttons" onClick={this.toggleCarousel}>
              <i className={'fa fa-' + (animState === 'stop' ? 'play' : 'pause')} />
            </div>
          </div>
        )}
      </span>
    );
  }
}

Carousel.propTypes = {};

export default Carousel;
