import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openPaidPlanModalAction } from 'redux/actions/app';
import { RootState } from 'redux/store';
import { Dimensions } from 'shared/editor/generic/ResolutionSection';
import { getUpdateCustomizeAction } from 'shared/widgets/utils/widgetHelper';

export type ExportDimensionsProps = {
  dimensions: Dimensions;
  showSubscriptionLock: boolean;
  showPaidPlanModal: () => void;
  setResolution: (dimensions: Dimensions) => void;
};

export type WrapperProps = {
  onPublish?: () => void;
  setResolution: (dimensions: Dimensions) => void;
};

export default function withExportDimensions<P extends WrapperProps>(
  WrappedComponent: React.ComponentType<P & ExportDimensionsProps>
) {
  const ComponentWithExportDimensions = (props: P) => {
    const { setResolution } = props;
    const dispatch = useDispatch();
    const { resolution } = useSelector((state: RootState) => state.layoutEditorPage);
    const { provider, type } = useSelector((state: RootState) => state.projectConfig);
    const { team } = useSelector((state: RootState) => state.profile);
    const showSubscriptionLock = !team || (team && team.plan_id && team.plan_id <= 1);

    useEffect(() => {
      const updateCustomizedAction = getUpdateCustomizeAction(provider, type);

      dispatch(
        updateCustomizedAction({
          optionProps: 'exporting.sourceWidth',
          val: resolution.width
        })
      );
      dispatch(
        updateCustomizedAction({
          optionProps: 'exporting.sourceHeight',
          val: resolution.height
        })
      );
    }, [resolution]);

    useEffect(() => {
      return () => {
        setResolution({
          width: null,
          height: null
        });
      };
    }, []);

    const showPaidPlanModal = () => dispatch(openPaidPlanModalAction({ paidPlanType: 'higher' }));

    return (
      <WrappedComponent
        {...props}
        dimensions={resolution}
        showSubscriptionLock={showSubscriptionLock}
        showPaidPlanModal={showPaidPlanModal}
      />
    );
  };
  return ComponentWithExportDimensions;
}
