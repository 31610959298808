import { Highcharts } from 'index';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react';
import { HighchartsReactProps } from '../../types/HighchartsReact';

// React currently throws a warning when using `useLayoutEffect` on the server.
// To get around it, we can conditionally `useEffect` on the server (no-op) and
// `useLayoutEffect` in the browser. We need `useLayoutEffect` to ensure the
// `Highcharts` ref is available in the layout phase. This makes it available
// in a parent component's `componentDidMount`.
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const HighchartsReact = memo(
  forwardRef(function HighchartsReact(props: HighchartsReactProps, ref) {
    const containerRef = useRef();
    const chartRef = useRef();
    const constructorType = useRef(props.constructorType);

    useIsomorphicLayoutEffect(() => {
      function createChart() {
        const constructorType = props.constructorType || 'chart';
        if (!Highcharts[constructorType]) {
          console.warn('The "constructorType" property is incorrect or some ' + 'required module is not imported.');
        } else if (!props.options) {
          console.warn('The "options" property was not passed.');
        } else {
          // Create a chart
          try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-expect-error
            chartRef.current = new Highcharts[constructorType](containerRef.current, props.options, props.callback);
            Highcharts.error = function (code, stopLoading) {
              if (stopLoading) {
                //@ts-expect-error code
                throw new Error(code);
              }
            };
          } catch (code) {
            //@ts-expect-error chartRef
            if (props.onError) props.onError(chartRef.current, code);
          }
        }
      }

      if (!chartRef.current) createChart();
      else {
        if (props.allowChartUpdate !== false) {
          // Reacreate chart on Highcharts or constructor type change
          if (props.constructorType !== constructorType.current) {
            constructorType.current = props.constructorType;
            createChart();
          } else {
            createChart();
          }
        }
      }
    }, [props.options, props.constructorType]);

    // Destroy the chart on unmount
    useIsomorphicLayoutEffect(() => {
      return () => {
        if (chartRef.current) {
          (chartRef.current as any) = null;
        }
      };
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        get chart() {
          return chartRef.current;
        },
        container: containerRef
      }),
      []
    );

    // Create container for the chart
    return <div {...props.containerProps} ref={containerRef as any} />;
  })
);

export default HighchartsReact;
