import { debounce } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ControlProps, OptionProps, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import MapApi, { LocatorResult } from '../../../editor/core/highed.mapapi';
import { fitMapToBoundingBoxAction } from '../../../pages/ChartEditorPage/actions/locationMap';
import LoaderIcon from '../../loader/LoaderIcon';
import { searchInputStyles } from '../../utils/selectStylesHelper';

const loadOptions = (query: string, callback: (option: LocatorResult[]) => void) => {
  // TODO Handle error. Show error in dropdown?
  MapApi().getLatLong(query, (latlongResults: LocatorResult[] | false) => {
    if (latlongResults) callback(latlongResults);
  });
};

const Control = ({ children, ...props }: ControlProps<LocatorResult>): React.ReactNode => (
  <components.Control {...props} className="highed-field-input">
    <i className="fa-solid fa-magnifying-glass pr-1" /> {children}
  </components.Control>
);

const LoadingIndicator = () => <LoaderIcon loading={true} iconClass="text-xl" />;

const debouncedLoadOptions = debounce(loadOptions, 650);

export type LocationMapSearchProps = {
  className: string;
};
export const LocationMapSearch = (props: LocationMapSearchProps) => {
  const dispatch = useDispatch();

  const addPoint = (location: LocatorResult) => {
    if (location.bbox) {
      dispatch(
        fitMapToBoundingBoxAction({ bbox: [location.bbox[2], location.bbox[0], location.bbox[3], location.bbox[1]] })
      );
    }
  };

  const Option = (props: OptionProps<LocatorResult>) => (
    <components.Option
      {...props}
      innerProps={{
        onClick: (ev) => {
          if (props.innerProps.onClick) props.innerProps.onClick(ev);
          addPoint(props.data);
        }
      }}
    >
      <div className={`text-base font-normal text ${props.isSelected ? 'text-ev-dark-purple' : 'text-ev-navy-blue-2'}`}>
        {props.data.value}
      </div>
    </components.Option>
  );

  return (
    <div className={props.className}>
      <AsyncSelect
        loadOptions={debouncedLoadOptions}
        cacheOptions={true}
        styles={searchInputStyles}
        placeholder="Search for a place" // TODO Placeholder should revert the location or keep the searched location
        components={{ LoadingIndicator, Option, Control }}
      />
    </div>
  );
};
