import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../shared/message/ErrorMessage';
import {
  getShowChartWithUuidAndVersion,
  getTeamChartWithTeamidAndChartid,
  getShowChartWithUuid,
  postTeamChartUsingTeamid,
  getChartAccesstoWithUuid,
} from '../../api/cloudApiGenerated';

class EditChartRedirect extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.loadChart = this.loadChart.bind(this);
    this.editChart = this.editChart.bind(this);

    this.state = {
      id: props.match.params.id,
      version: props.match.params.version && parseInt(props.match.params.version, 10),
      loadedChart: false,
      username: '',
      chartId: '',
      teamId: '',
      loadedEditChartBtn: false,
    };
    this.loadChart(props);
  }

  componentWillReceiveProps(newProps) {
    if (!this.state.teamId && newProps.team) {
      this.setState({ teamId: newProps.team.id });
    }
  }
  componentDidUpdate() {
    const chart = this.state.chart;
    if (chart) {
    }
  }

  editChart() {
    const id = this.state.id;
    if (isNaN(id)) {
      let newPath = this.props.paths.editChartLatest.replace(':chartid', this.state.chartId);
      this.props.history.push(newPath);
    }
  }

  loadChart(props) {
    const id = this.state.id;
    const version = this.state.version;
    let getChart;
    let params = [];

    if (!isNaN(Number(id))) {
      if (props.team === undefined) return;
      getChart = getTeamChartWithTeamidAndChartid;
      params = [props.team.id, id];
    } else {
      getChart = version ? getShowChartWithUuidAndVersion : getShowChartWithUuid;
      params = [id, version];
    }
    getChart
      .apply(this, params)
      .then((chart) => {
        if (chart && chart.data) {
          if (chart.team_owner === 9999998) {
            let newPath = this.props.paths.chartShare.replace(':id', chart.uuid);
            this.props.history.push(newPath);
            return;
          }

          getChartAccesstoWithUuid(chart.uuid).then((data) => {
            if (data.access) {
              localStorage.setItem('editorTeam', JSON.stringify({ id: data.teamID, name: data.teamName }));
              const editPath = (c) => {
                const id = c.chart_id;
                switch (c.chart_type) {
                  // layout
                  case 4:
                    return this.props.paths.editLayout.replace(':storyid', id);
                  // table
                  case 5:
                    return this.props.paths.editTable.replace(':tableid', id);
                  // charts, maps
                  default:
                    return this.props.paths.editChartLatest.replace(':chartid', id);
                }
              };
              const newPath = editPath(chart);
              this.props.history.push(newPath);
            } else {
              this.setState({
                error: 'You do not have permission to access this chart.',
              });
            }
          });
        } else {
          this.setState({
            error: 'No chart configuration was found for the requested chart.',
          });
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  render() {
    return (
      <section className="col-xs-12 col-lg-6" style={{ float: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
        <ErrorMessage error={this.state.error} />
      </section>
    );
  }
}

EditChartRedirect.propTypes = {
  match: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool,
  team: PropTypes.object,
  setNavActions: PropTypes.func,
};

export default EditChartRedirect;
