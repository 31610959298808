import { isObject } from 'lodash';
import { toCSV } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { customStyles } from 'shared/utils/selectStylesHelper';
import GeoJSONModal from '../../../containers/wizard/editor/GeoJSONModal';
import snackBar from '../../../editor/core-ui/highed.snackbar';
import { download } from '../../../editor/core/highcharts-editor';
import readLocalFile from '../../../editor/core/highed.fileupload';
import { getMap as getMapSamples } from '../../../editor/core/highed.sampleman';
import { setMapAction, showGeoJSONModalAction } from '../../../pages/ChartEditorPage/actions/chartEditor.js';
import IconButton from '../../buttons/IconButton';
import CustomSelect, { SelectItem } from '../../inputs/CustomSelect';
import DownloadIcon from '../Icons/DownloadIcon';
import PlusIcon from '../Icons/PlusIcon';
import { formatGeoJSONMapData } from '../utils/mapDataHelper';

export default function MapAdvancedTemplateSelector() {
  const dispatch = useDispatch();
  const tilemaps = getMapSamples('Tilemap');

  const {
    chosenWizardTemplate,
    countryValue: storedCountryValue,
    categoryValue: storedCategoryValue,
    showGeoJSONModal
  } = useSelector((state: RootState) => state.chartEditorPage);
  const { aggregatedOptions } = useSelector((state: RootState) => state.projectConfig);
  const [typeValue, changeTypeValue] = useState(storedCountryValue);
  const [categoryValue, changeCategoryValue] = useState(storedCategoryValue);

  const template = chosenWizardTemplate as any;
  const isTilemap = template?.title && ['Tilemap circle', 'Honeycomb'].indexOf(template.title) > -1;
  const isPointMap = template?.title && ['Point map'].indexOf(template.title) > -1;
  const map = (aggregatedOptions?.chart as any)?.map;
  const tilemapOptions = Object.keys(tilemaps).map((key) => ({
    value: key,
    label: tilemaps[key].title
  }));

  const dispatchMap = (val: SelectItem, isCountrySection: boolean, skipMapping: boolean) => {
    dispatch(
      setMapAction({
        map: val.value
          ? {
              map: val.value.replace('.js', '')
            }
          : false,
        val,
        isTilemap,
        isCountrySection,
        skipMapping
      })
    );
  };

  useEffect(() => {
    const type = map + '.js';
    if (typeValue?.value !== type) dispatchMap(typeValue, true, true);
  }, []);
  useEffect(() => changeTypeValue(storedCountryValue), [storedCountryValue]);
  useEffect(() => changeCategoryValue(storedCategoryValue), [storedCategoryValue]);

  const typeKeys = Object.keys(Highcharts.mapDataIndex.Countries);
  const typeOptions = typeKeys.map((key) => ({
    label: key,
    value: (Highcharts as any).mapDataIndex.Countries[key]
  }));

  const categoryKeys = Object.keys((Highcharts as any).mapDataIndex.Subcategories[typeValue.label] || {});
  const categoryOptions = categoryKeys.map((key) => ({
    label: key,
    value: (Highcharts as any).mapDataIndex.Subcategories[typeValue.label][key]
  }));
  const hasCategories = categoryOptions.length > 0;

  const onChangeTypeValue = (val: SelectItem | null) => {
    if (val) {
      changeTypeValue(val);
      dispatchMap(val, true, true);
    }
  };

  const onChangeCategoryValue = (val: SelectItem | null) => {
    if (val) {
      changeCategoryValue(val);
      dispatchMap(val, false, true);
    }
  };

  const uploadGeoJSON = (e: React.MouseEvent) => {
    e.stopPropagation();
    readLocalFile({
      type: 'text',
      accept: '.geojson',
      success: (geoJSONMapData: any) => {
        if (geoJSONMapData.size > 2000000) {
          snackBar('File size cannot exceed 2mb', '', () => null);
          return;
        }

        if (!geoJSONMapData.data) {
          snackBar('Uploaded invalid data (GeoJSON)', '', () => null);
          return;
        }

        const formattedGeoJSONMapData = formatGeoJSONMapData(geoJSONMapData.data);

        if (formattedGeoJSONMapData) {
          dispatch(
            showGeoJSONModalAction({
              geoJSONMapData: { ...geoJSONMapData, data: formattedGeoJSONMapData }
            })
          );
        } else {
          snackBar('There was a problem uploading your data (GeoJSON)', '', () => null);
        }
      }
    });
  };

  const downloadDataset = () => {
    let mapHC = map;
    const code = 'name';

    if (isPointMap || isTilemap || isObject(mapHC)) {
      // Is geoJSON or tilemap
      const csv = aggregatedOptions.data.csv;
      download('data.csv', csv, 'application/csv');
      return;
    }

    mapHC = (Highcharts as any).maps[mapHC];

    const points = mapHC.features
      .map((point: any) => {
        return [point.properties[code], null];
      })
      .filter((d: any) => d[0] !== undefined);

    const csv = '"code";"value"\n' + toCSV(points, ';');
    download('data.csv', csv, 'application/csv');
  };

  return (
    <>
      {showGeoJSONModal && <GeoJSONModal />}
      <div>
        <div className="flex">
          <CustomSelect
            styles={customStyles}
            label={'Country:'}
            options={isTilemap ? tilemapOptions : typeOptions}
            onChange={onChangeTypeValue}
            value={typeValue}
            containerClasses={'w-full'}
            searchable={true}
          />

          {(isTilemap || !hasCategories) && (
            <IconButton
              icon={<DownloadIcon />}
              className="w-10 h-10 rounded ml-1 content-centered"
              onClick={downloadDataset}
              tooltipText={'Download dataset'}
            />
          )}

          {!isTilemap && (
            <IconButton
              icon={<PlusIcon />}
              onClick={uploadGeoJSON}
              className="w-10 h-10 rounded ml-1 content-centered"
              tooltipText={'Upload a new map (GeoJSON)'}
              id={'upload-geo-json'}
            />
          )}
        </div>

        <div className="pt-2">
          <div className="flex">
            {!isTilemap && hasCategories && (
              <>
                <CustomSelect
                  label={'Category:'}
                  styles={customStyles}
                  options={categoryOptions}
                  onChange={onChangeCategoryValue}
                  value={categoryValue}
                  containerClasses={'w-full'}
                  searchable={true}
                />
              </>
            )}

            {!isTilemap && hasCategories && (
              <IconButton
                icon={<DownloadIcon />}
                className="w-10 h-10 rounded ml-1 content-centered"
                onClick={downloadDataset}
                tooltipText={'Download dataset'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
