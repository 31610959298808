import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import validateEmail from 'shared/utils/validateEmail';
import { postAccountOrganisation } from '../../api/cloudApiGenerated';
import TextInput from '../../shared/inputs/TextInput';

class SingleSignOn extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
    this.updateState = this.updateState.bind(this);
    this.state = {
      email: '',
      password: '',
      error: ''
    };
  }

  onClick(event) {
    event.preventDefault();

    const emailValid = validateEmail(this.state.email);
    if (!emailValid) {
      this.setState({
        error: 'Please enter a valid email address'
      });
      return;
    }

    postAccountOrganisation({
      email: this.state.email
    }).then((data) => {
      if (data && data.status) {
        this.setState({
          error: ''
        });
        window.location = this.props.paths.ssoSignin + `/${data.domain}`;
      } else {
        this.setState({
          error: 'This email is not associated with an SSO account'
        });
      }
    });
  }

  updateState(event) {
    const field = event.target.name;
    let state = this.state;
    state[field] = event.target.value;
    return this.setState(state);
  }

  render() {
    return (
      <div className="dark-layout login-page col-xs-12">
        <div className="text-center">
          <h2>Single Sign On</h2>
          <div>
            <p>Your organization uses Single Sign On.</p>
            <p>Please sign in using your Single Sign On credentials</p>
          </div>
        </div>

        <div className="center-form">
          <TextInput
            label=""
            inlineLabel="Email:"
            name="email"
            type="email"
            value={this.state.email}
            placeholder="Work email"
            onChange={this.updateState}
          />

          <p className="center">
            <NavLink to={this.props.paths.login}>Sign in without Single Sign On</NavLink>
          </p>
        </div>

        <div className="btn-page-div">
          <a className="bulk-action bulk-action-btn clickable" onClick={this.onClick}>
            Sign in
          </a>
        </div>
        <div className="center validation-failed">{this.state.error}</div>
      </div>
    );
  }
}

SingleSignOn.propTypes = {
  paths: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  redirect: PropTypes.string.isRequired,
  onLoggedIn: PropTypes.func.isRequired
};

export default SingleSignOn;
