import React, { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import useWindowDimensions from './useWindowDimensions';

type Resolution = {
  height: number;
  width: number;
};

type ResolutionContainerProps = {
  children: React.ReactNode;
  parentRef: React.RefObject<HTMLDivElement>;
  wantedResolution?: Resolution;
  className?: string;
};

function calculateNewResolution(newResolution?: Resolution, maxResolution?: Resolution): Resolution | undefined {
  if (!maxResolution || !newResolution) return;
  if (newResolution.height > maxResolution.height || newResolution.width > maxResolution.width) {
    const ratio = Math.min(maxResolution.width / newResolution.width, maxResolution.height / newResolution.height);
    return {
      height: Math.min(maxResolution.height, newResolution.height * ratio),
      width: Math.min(maxResolution.width, newResolution.width * ratio)
    };
  }
  return newResolution;
}

export const DynamicResolutionContainer = ({
  children,
  wantedResolution,
  className,
  parentRef
}: ResolutionContainerProps) => {
  const dimensions = useWindowDimensions();
  const [resolution, setResolution] = useState<Resolution>();
  const [maxResolution, setMaxResolution] = useState({ width: 0, height: 0 });

  useResizeObserver({
    ref: parentRef,
    onResize: () => {
      setResolutionFromParent(parentRef.current);
    }
  });

  const setResolutionFromParent = (ref: HTMLDivElement | null) => {
    if (ref) {
      const bBox = ref.getBoundingClientRect();
      setMaxResolution({ width: bBox.width, height: bBox.height });
    }
  };

  useEffect(() => {
    setResolutionFromParent(parentRef.current);
  }, []);

  useEffect(() => {
    setResolutionFromParent(parentRef.current);
  }, [dimensions]);

  useEffect(() => {
    if (parentRef.current && wantedResolution) {
      setResolution(
        calculateNewResolution({ height: wantedResolution.height, width: wantedResolution.width }, maxResolution)
      );
    }
  }, [parentRef, maxResolution, wantedResolution]);

  return (
    <div
      style={
        resolution && {
          minHeight: resolution.height,
          minWidth: resolution.width,
          maxHeight: resolution.height,
          maxWidth: resolution.width
        }
      }
      className={className}
    >
      {children}
    </div>
  );
};
