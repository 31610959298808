import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import Subheader from 'shared/profile/Subheader';
import Table from 'shared/table/Table';
import { setTeamMembersAction, getDivisionMembersAction, getMembersAction } from '../actions/teamMembersAction';
import { useDispatch, useSelector } from 'react-redux';
import AddThinIcon from 'static/icons/add-thin.svg';
import PenIcon from 'static/icons/pen.svg';
import { isEqual } from 'lodash';
import { RootState } from 'redux/store';
import { usersTableColumns } from '../meta/tableColumnsData';
import { UsersSectionRefType, UsersSectionProps, EditableColumnsType } from '../types/teamPageModels';
import { setDivision } from 'utils/profileHelper';

const UsersSection = forwardRef<UsersSectionRefType, UsersSectionProps>(
  ({ team, groups, hasPermission, formattedDivisions, activeDivision, members, subteamMembers }, ref) => {
    const { division, divisions } = useSelector((state: RootState) => state.profile);
    const dispatch = useDispatch();

    const [usersTableRows, setUsersTableRows] = useState((members as any).data ?? []);
    const [editableColumns, setEditableColumns] = useState<EditableColumnsType>(null);
    const [membersSearchValue, setMembersSearchValue] = useState('');

    const ownerId = (team as any)?.owner_user ?? '';
    const isSubteam = !!activeDivision?.value;

    useEffect(() => {
      if (division && !isSubteam) {
        dispatch(
          setTeamMembersAction({
            activeDivision: { value: division.id, label: division.name }
          })
        );
      }
    }, [division]);

    useEffect(() => {
      if (team && division?.name) {
        if (isSubteam) {
          dispatch(
            getDivisionMembersAction({
              team,
              divisions,
              query: membersSearchValue,
              page: subteamMembers.page,
              pageSize: subteamMembers.pageSize,
              division: activeDivision.value
            })
          );
        } else {
          dispatch(
            getMembersAction({ team, query: membersSearchValue, page: members.page, pageSize: members.pageSize })
          );
        }
      }
    }, [team, division, isSubteam]);

    useEffect(() => {
      const tempMembers = isSubteam ? subteamMembers.data : members.data;
      const formattedMembers = tempMembers.map((member: any) => ({
        ...member,
        group_id: member.id === ownerId ? 'Owner' : member.group_id
      }));

      setUsersTableRows(formattedMembers);
    }, [members, subteamMembers, groups]);

    useEffect(() => {
      if (groups) {
        const formattedGroups = [
          ...groups.map((group: any) => ({
            value: hasPermission ? group.id : group.name,
            label: group.name
          })),
          { value: 'Owner', label: 'Owner', isDisabled: true }
        ];

        setEditableColumns({
          group_id: {
            validValues: formattedGroups
          }
        });
      }
    }, [groups]);

    useEffect(() => {
      const tempMembers = isSubteam ? subteamMembers.data : members.data;
      const formattedMembers = tempMembers.map((member: any) => ({
        ...member,
        group_id: member.id === ownerId ? 'Owner' : member.group_id
      }));

      if (Array.isArray(usersTableRows) && usersTableRows.length > 0) {
        if (!isEqual(formattedMembers, usersTableRows)) {
          dispatch(setTeamMembersAction({ unsavedChanges: true }));
        } else {
          dispatch(setTeamMembersAction({ unsavedChanges: false }));
        }
      }
    }, [usersTableRows]);

    const bulkActions = [
      {
        type: 'select',
        hasPermission,
        options: formattedDivisions,
        value: activeDivision,
        className: 'min-w-[200px]',
        onChange: (selectedDivision: any) => {
          const formattedSelectedDivision = { ...selectedDivision, value: parseInt(selectedDivision.value) };
          const selectedDivisionLabel = formattedSelectedDivision.label;

          if (selectedDivisionLabel !== activeDivision?.label) {
            if (selectedDivisionLabel === 'All subteams') {
              dispatch(
                getDivisionMembersAction({
                  team,
                  divisions,
                  division: activeDivision.value,
                  query: membersSearchValue,
                  page: 1,
                  pageSize: subteamMembers.pageSize
                })
              );
            } else {
              dispatch(getMembersAction({ team, query: membersSearchValue, page: 1, pageSize: members.pageSize }));
            }

            dispatch(
              setTeamMembersAction({
                activeDivision: formattedSelectedDivision,
                unsavedChanges: false
              })
            );
            setDivision({ id: formattedSelectedDivision.value, name: formattedSelectedDivision.label });
          }
        }
      },
      {
        text: 'Add member to subteam',
        type: 'icon-button',
        className: 'flex items-center gap-1 font-medium hover:text-ev-baby-blue-2',
        buttonIcon: (
          <div className="h-3.5 w-3.5">
            <AddThinIcon />
          </div>
        ),
        hasPermission,
        disabled: !isSubteam,
        onClick() {
          dispatch(setTeamMembersAction({ isUsersSubteamAddModalOpen: true }));
        }
      },
      {
        text: 'Edit subteam',
        type: 'icon-button',
        className: 'flex items-center gap-1 font-medium hover:text-ev-baby-blue-2',
        buttonIcon: (
          <div className="h-4 w-4">
            <PenIcon />
          </div>
        ),
        hasPermission,
        disabled: !isSubteam,
        onClick() {
          dispatch(
            setTeamMembersAction({
              editDivisionModalOpen: true
            })
          );
        }
      },
      {
        text: 'Search member',
        type: 'search',
        className: 'ml-auto',
        hasPermission,
        value: membersSearchValue,
        onClick() {
          dispatch(setTeamMembersAction({ membersQuery: membersSearchValue }));

          if (isSubteam) {
            dispatch(
              getDivisionMembersAction({
                team,
                divisions,
                query: membersSearchValue,
                page: subteamMembers.page,
                pageSize: subteamMembers.pageSize,
                division: activeDivision.value
              })
            );
          } else {
            dispatch(
              getMembersAction({ team, query: membersSearchValue, page: members.page, pageSize: members.pageSize })
            );
          }
        },
        onChange(value: string) {
          setMembersSearchValue(value);
        },
        reset() {
          setMembersSearchValue('');
          dispatch(setTeamMembersAction({ membersQuery: '' }));

          if (isSubteam) {
            dispatch(
              getDivisionMembersAction({
                team,
                divisions,
                query: '',
                page: subteamMembers.page,
                pageSize: subteamMembers.pageSize,
                division: activeDivision.value
              })
            );
          } else {
            dispatch(getMembersAction({ team, query: '', page: members.page, pageSize: members.pageSize }));
          }
        }
      }
    ];

    const onSelectPage = (updatedPage: number) => {
      if (isSubteam) {
        dispatch(
          getDivisionMembersAction({
            team,
            divisions,
            query: membersSearchValue,
            page: updatedPage,
            pageSize: subteamMembers.pageSize,
            division: activeDivision.value
          })
        );
      } else {
        dispatch(getMembersAction({ team, query: membersSearchValue, page: updatedPage, pageSize: members.pageSize }));
      }
    };

    const onRowDataChange = (data: any) => {
      setUsersTableRows((prev: any) => prev.map((user: any) => (user.id === data.id ? data : user)));
    };

    useImperativeHandle(ref, () => ({
      usersTableRows,
      resetUsersTableRows() {
        const tempMembers = isSubteam ? subteamMembers.data : members.data;
        const formattedMembers = tempMembers.map((member: any) => ({
          ...member,
          group_id: member.id === ownerId ? 'Owner' : member.group_id
        }));

        setUsersTableRows(formattedMembers);
      }
    }));

    const editActions = [
      {
        text: 'Remove from subteam',
        disabled: !isSubteam,
        disableId: ownerId,
        action: (rowData: any) => {
          dispatch(setTeamMembersAction({ isUserSubteamRemoveModalOpen: true, selectedUser: rowData }));
        }
      },
      {
        text: 'Delete member',
        disabled: !hasPermission,
        action: (rowData: any) => {
          dispatch(setTeamMembersAction({ isUsersDeleteModalOpen: true, selectedUser: rowData }));
        }
      }
    ];

    return (
      <ProfilePageSectionContainer>
        <Subheader>Members</Subheader>
        <Table
          columns={usersTableColumns}
          rows={usersTableRows}
          bulkActions={bulkActions}
          editableColumns={editableColumns}
          disabledRowsIds={[ownerId]}
          selectPage={onSelectPage}
          page={isSubteam ? subteamMembers.page : members.page}
          pageCount={isSubteam ? subteamMembers.pageCount : members.pageCount}
          onRowDataChange={onRowDataChange}
          pageSize={isSubteam ? subteamMembers.pageSize : members.pageSize}
          dataReturned={isSubteam ? !subteamMembers.loading : !members.loading}
          bulkActionsClassName="flex flex-wrap gap-2 ev-md:gap-4"
          hasEditPermission={hasPermission}
          editActions={editActions}
          editActionsDisableIds={[ownerId]}
        />
      </ProfilePageSectionContainer>
    );
  }
);

export default UsersSection;
