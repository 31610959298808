import React from 'react';
import { useSelector } from 'react-redux';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { RootState } from '../../../redux/store';
import { getStatus } from '../publishHelper';

export type ProjectLastSavedAtProps = {
  className?: string;
};
export default function ProjectLastSavedAt({ className }: ProjectLastSavedAtProps) {
  const projectConfig: RootState['projectConfig'] = useSelector(getProjectConfig);
  const { lastPublishTime, lastSaved } = projectConfig;

  return (
    <div className={'hidden lg:block py-2 pr-4 font-medium ' + className}>{getStatus(lastPublishTime, lastSaved)}</div>
  );
}
