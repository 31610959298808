import React, { useEffect } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

import ajax from '../../utils/ajax';
import Loading from '../../shared/loader/Loading';
import { UnknownParamsFunction } from 'shared/types/commonPropTypes';

type CallbackPageProps = {
  onLoggedIn: UnknownParamsFunction;
  paths: Record<string, string>;
};

const CallbackPage = (props: CallbackPageProps) => {
  const history = useHistory();

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (query.session) {
      ajax.assignToken(query.session);
      props.onLoggedIn(false, []);
      history.replace({
        pathname: props.paths.profileCharts
      });
      location.reload();
    } else if (query.code) {
      ajax
        .ajax({
          url: '/login/google/callback?code=' + query.code,
          type: 'get'
        })
        .then((result) => {
          if (result.token) {
            ajax.assignToken(result.token);
            props.onLoggedIn(result.announcements || [], result.new_user, history, result.user_id);

            history.replace({
              pathname: props.paths.profileCharts
            });
          } else {
            throw 'Did not get a login token';
          }
        });
    }
  }, []);
  return <Loading text="Loading account..." />;
};

export default CallbackPage;
