import React, { useEffect, useState } from 'react';
import TextButton from 'shared/buttons/TextButton';
import { ChartLiveDataProps } from 'shared/wizard/meta/LinkDataOptions';
import RefinitivAccount from './RefinitivAccount';
import DataServerStocks from './DataServerStocks';
import Loading from 'shared/loader/Loading';
import { getTeamProviderDataserverWithTeamidAndProvidertype } from 'api/cloudApiGenerated';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { LinkDataOption } from 'shared/wizard/meta/LinkDataOptions';
import { capitalize } from 'lodash';

export enum RefinitivMode {
  Account = 'Account',
  Stocks = 'Stocks'
}

type RefinitivContentProps = {
  setShouldDisplaySummary: React.Dispatch<React.SetStateAction<boolean>>;
  shouldSubmit: boolean;
  handleSubmit: (options: ChartLiveDataProps, dataType: string) => void;
  onModalClose: () => void;
  setErrorsComponent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const RefinitivContent = (props: RefinitivContentProps) => {
  const { handleSubmit, shouldSubmit, setErrorsComponent } = props;
  const [mode, setMode] = useState<RefinitivMode>(RefinitivMode.Account);
  const [accountLinked, setAccountLinked] = useState(false);
  const [gettingCredentials, setGettingCredentials] = useState(true);
  const { team } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (team?.id) {
      const getCredentials = async () =>
        await getTeamProviderDataserverWithTeamidAndProvidertype(team.id, LinkDataOption.Refinitiv);
      getCredentials()
        .then((res) => {
          if (res.team_setup) setAccountLinked(true);
          else setGettingCredentials(false);
        })
        .catch((e) => console.error(e));
    } else setGettingCredentials(false);
  }, []);

  useEffect(() => {
    props.setShouldDisplaySummary(mode !== RefinitivMode.Account);
  }, [mode]);

  useEffect(() => {
    if (accountLinked) {
      setMode(RefinitivMode.Stocks);
      setGettingCredentials(false);
    }
  }, [accountLinked]);

  return (
    <div className="flex flex-col mb-2">
      {accountLinked ? (
        <>
          <div className="flex justify-between">
            <p className="m-0">{capitalize(LinkDataOption.Refinitiv)} account linked to connector</p>
            <TextButton
              text={mode === RefinitivMode.Stocks ? 'Edit' : 'Close'}
              onClick={() => setMode(mode === RefinitivMode.Stocks ? RefinitivMode.Account : RefinitivMode.Stocks)}
            />
          </div>
          <hr />
        </>
      ) : (
        <span className="text-ev-navy-blue text-base mb-2">Link your account to connector</span>
      )}

      {gettingCredentials && <Loading />}

      {!gettingCredentials && mode === RefinitivMode.Account && (
        <RefinitivAccount
          setMode={setMode}
          setAccountLinked={setAccountLinked}
          onModalClose={props.onModalClose}
          setErrorsComponent={setErrorsComponent}
        />
      )}
      {!gettingCredentials && mode === RefinitivMode.Stocks && (
        <DataServerStocks
          handleSubmit={handleSubmit}
          shouldSubmit={shouldSubmit}
          setErrorsComponent={setErrorsComponent}
          provider={LinkDataOption.Refinitiv}
        />
      )}
    </div>
  );
};

export default RefinitivContent;
