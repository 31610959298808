import { TemplateProps } from '../../types';

const AreaWithLabels: TemplateProps = {
  title: 'Area with labels',
  description: '',
  constructor: '',
  thumbnail: 'atikon.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true
        }
      }
    }
  },
  sampleSet: 'area',
  advanced: {
    name: 'Area + label'
  },
  advancedParent: 'Area',
  dataValidator: false,
  thumbnail_url: 'https://app.everviz.com/static/thumbnails/atikon.svg',
  name: 'Area with labels'
};

export default AreaWithLabels;
