import { SeriesAssigns } from 'shared/types/commonPropTypes';
import evervizTheme from '../../../editor/meta/highed.meta.everviz.theme';
import DefaultData from '../../ChartEditorPage/meta/data/DefaultData';
import { TypeProps } from 'shared/types/chooseTheme';
import { ThemeProp } from 'shared/editor/generic/Themes';
import { ProjectConfigLocationMapProps } from './locationMapConfigTypes';

export type ProjectConfigHighchartsProps = {
  aggregatedOptions: any;
  rawImportedCSV: string;
  templateOptions: any[];
  themeOptions: any;
  themeMeta: any;
  customizedOptions: any;
  cssModules: string[];
  lastSaved: boolean;
  dataOptions: any[][];
  seriesAssigns: SeriesAssigns[];
  projectName: string;
  plugins: Record<string, 1 | boolean>;
  templateMeta: any[];
  saved: boolean;
  published: boolean;
  publishLoading: boolean;
  embedDetails: { iframeURL: string; injectCode?: string; iframeCode?: string };
  uuid: boolean;
  changeMade: boolean;
  useDefaultData: boolean;
  toggleInspired: boolean;
  showUnsavedChangesModal: boolean;
  showUnpublishModal: boolean;
  richtextGroupOptions:
    | {
        option: any;
        box: HTMLElement;
      }
    | boolean
    | null;
  emojiOptions:
    | {
        option: any;
        box: HTMLElement;
      }
    | boolean
    | null;
  templateDataSettings: any;
  tab: 'customize' | 'publish' | 'data' | 'themes' | 'type';
  pluginConfig: any;
  activeColumns: {
    columns: number[];
    series: number[];
  };
  lastPublishTime: boolean;
  isPastingActive: boolean;
  isThemeEditor: boolean;
  activeSection: string;
  activeBasicTab: string;
  urlParam: string;
  showWizard: boolean;
  loadingEditor: boolean;
  isCompanyThemeEditor: boolean;
  companyThemes: ThemeProp[];
  provider: 'highcharts';
  type: TypeProps;
  loadedTemplate: boolean;
  animation: boolean;
  noRedraw: boolean;
  dataGridColumnsUsed: any[];
  mapCodeErrors: any[];
  mapExtraColumns: any[];
  inPackagesMode: boolean;
};

export type ProjectConfigProps = ProjectConfigHighchartsProps | ProjectConfigLocationMapProps;

const store: ProjectConfigProps = {
  aggregatedOptions: {
    ...evervizTheme,
    stockTools: {
      gui: {
        enabled: false
      }
    },
    data: {
      csv: DefaultData.line.data,
      seriesMapping: DefaultData.line.seriesMapping,
      itemDelimiter: '',
      matchingKey: null,
      dateFormat: ''
    }
  },
  rawImportedCSV: '',
  templateOptions: [],
  themeOptions: { options: { ...evervizTheme }, id: null },
  themeMeta: {
    isevervizTheme: true
  },
  customizedOptions: {
    title: {
      enabled: true
    }
  },
  cssModules: [],
  lastSaved: false,
  dataOptions: [],
  dataGridColumnsUsed: [],
  seriesAssigns: [],
  projectName: '',
  plugins: {},
  templateMeta: [],
  saved: false,
  published: false,
  publishLoading: false,
  embedDetails: { iframeURL: '' },
  uuid: false,
  changeMade: true,
  useDefaultData: false,
  toggleInspired: false,
  showUnsavedChangesModal: false,
  showUnpublishModal: false,
  richtextGroupOptions: null,
  emojiOptions: null,
  templateDataSettings: {},
  tab: 'customize',
  pluginConfig: {},
  activeColumns: {
    columns: [],
    series: []
  },
  lastPublishTime: false,
  isPastingActive: false,
  isThemeEditor: false,
  activeSection: 'basic',
  activeBasicTab: '',
  urlParam: '',
  showWizard: false,
  loadingEditor: true,
  isCompanyThemeEditor: false,
  companyThemes: [],
  provider: 'highcharts',
  type: 'chart',
  loadedTemplate: true,
  animation: true,
  mapCodeErrors: [],
  mapExtraColumns: [],
  noRedraw: false,
  inPackagesMode: false
};

export default store;
