import React from 'react';

export default function ChartIcon(props) {
  return (
    <svg viewBox="0 0 74 64" xmlns="http://www.w3.org/2000/svg">
      <path
        className="group-hover:fill-[#fff] group-active:fill-[#AEC8FA]"
        d="M4.625 0C7.17887 0 9.25 2.04714 9.25 4.57143V52.5714C9.25 53.8286 10.2848 54.8571 11.5625 54.8571H69.375C71.9332 54.8571 74 56.9 74 59.4286C74 61.9571 71.9332 64 69.375 64H11.5625C5.17711 64 0 58.8857 0 52.5714V4.57143C0 2.04714 2.07113 0 4.625 0Z"
        fill="#AEC8FA"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m37 45.714c2.5582 0 4.625-2.0428 4.625-4.5714v-22.857c0-2.5286-2.0668-4.5715-4.625-4.5715s-4.625 2.0429-4.625 4.5715v22.857c0 2.5286 2.0668 4.5714 4.625 4.5714z"
        fill="#28277E"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m64.75 45.714c2.5582 0 4.625-2.0428 4.625-4.5714v-32c0-2.5243-2.0668-4.5714-4.625-4.5714s-4.625 2.0471-4.625 4.5714v32c0 2.5286 2.0668 4.5714 4.625 4.5714z"
        fill="#28277E"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m27.75 32c0-2.5286-2.0668-4.5714-4.625-4.5714s-4.625 2.0428-4.625 4.5714v9.1428c0 2.5286 2.0668 4.5715 4.625 4.5715s4.625-2.0429 4.625-4.5715v-9.1428z"
        fill="#28277E"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m55.5 27.429c0-2.5286-2.0668-4.5715-4.625-4.5715s-4.625 2.0429-4.625 4.5715v13.714c0 2.5286 2.0668 4.5715 4.625 4.5715s4.625-2.0429 4.625-4.5715v-13.714z"
        fill="#28277E"
      />
    </svg>
  );
}
