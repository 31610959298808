import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanById } from 'pages/SubscriptionPage/utils/plansHelper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAction, showSignupModalAction } from 'redux/actions/projectConfig';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { RootState } from 'redux/store';
import IconButton from 'shared/buttons/IconButton';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import { getPublishStatus, getStatus, onPublish, onSave } from 'shared/editor/publishHelper';
import PermissionModal from 'shared/modal/PermissionModal';
import { useIsMobile } from 'shared/utils/mediaHelper';
import { showPaidPlanModal } from 'shared/utils/paidPlanHelper';
import useToggle from 'shared/utils/useToggle';
import InputWidget from 'shared/widgets/InputWidget';
import PageDimmer from 'shared/wizard/components/PageDimmer';
import { hideElementHideButton } from 'shared/wizard/editor/HideElementButton';
import { stripHtml } from 'utils/helper';
import { checkCreatePermission, checkPermission, checkPlanPermission } from 'utils/profileHelper';
import { getAggregatedOptions as getLayoutAggregatedOptions } from '../../LayoutEditorPage/selectors/layoutEditor.js';
import { getAggregatedOptions as getTableAggregatedOptions } from '../../TableEditorPage/selectors/tableEditor';

type EditorPublishProps = {
  onSave?: () => void;
};

export default function EditorPublish(props: EditorPublishProps) {
  const dispatch = useDispatch();
  const projectConfig: RootState['projectConfig'] = useSelector(getProjectConfig);
  const {
    saved,
    published,
    publishLoading,
    changeMade,
    projectName,
    aggregatedOptions,
    lastPublishTime,
    lastSaved,
    isPastingActive,
    isCompanyThemeEditor
  } = projectConfig;

  const { type, inPackagesMode } = useSelector((state: RootState) => state.projectConfig);
  const { isThemeEditor, chosenWizardTemplate } = useSelector((state: RootState) => state.chartEditorPage);
  const profile = useSelector((state: RootState) => state.profile);
  const { user, team, subscription } = profile;
  const [value, setValue] = useState(projectName);
  const [editing, toggleEditing] = useToggle(false);
  const [open, toggleOpen] = useToggle(false);
  const [showPermissionModal, toggleShowPermissionModal] = useToggle();
  const isMobile = useIsMobile();
  const { buttonText, light } = getPublishStatus(published, saved, changeMade, lastPublishTime, lastSaved);
  const hasThemePlanPermission = checkPlanPermission('custom_themes', profile) || false;
  const titleValue = stripHtml(value);

  const currentPlan = getPlanById(subscription?.id);
  const isFreePlan = currentPlan === SubscriptionPlans.Free;
  const anonymous = !user && !team;
  const hasPublishPermission = anonymous || checkPermission('publish');
  const hasBillingSetup = window.hcconfig.billing.enabled;
  const hasChartTypeSavePermission =
    !(isFreePlan && hasBillingSetup && (chosenWizardTemplate as any)?.locked) && checkCreatePermission(type);

  const getSavePermission = () => {
    if (isThemeEditor || isCompanyThemeEditor) {
      return !anonymous && checkPermission('theme_management') && hasThemePlanPermission;
    }
    return !hasChartTypeSavePermission || anonymous || checkPermission('create_chart');
  };
  const hasSavePermission = getSavePermission();
  const inEditPhoneMode = editing && isMobile;

  let aggregated = aggregatedOptions;
  if (type === 'layout') aggregated = getLayoutAggregatedOptions(projectConfig);
  else if (type === 'table') aggregated = getTableAggregatedOptions(projectConfig);

  const showSignupModal = () => dispatch(showSignupModalAction());

  useEffect(() => {
    if (editing === false) {
      dispatch(
        setAction({
          projectName: titleValue || aggregated?.title?.text || 'Untitled project',
          changeMade: true
        })
      );
    }
  }, [editing]);

  useEffect(() => {
    setValue(projectName);
  }, [projectName]);

  const togglePublishSection = () => {
    toggleOpen();
    hideElementHideButton();
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') toggleEditing();
  };

  const handleSave = () => {
    if (!team) {
      showSignupModal();
      return;
    }
    if (!hasSavePermission) {
      showPaidPlanModal('business');
      return;
    }

    if (!hasChartTypeSavePermission) {
      showPaidPlanModal('starter', undefined, 'chart type');
      return;
    }

    if (props.onSave) {
      props.onSave();
      return;
    }
    if (!open && !isThemeEditor) togglePublishSection();
    if (saved && !changeMade) return;
    onSave(type, isThemeEditor, dispatch);
  };

  const handlePublish = () => {
    if (!team) {
      showSignupModal();
      return;
    }
    if (!hasPublishPermission || !hasChartTypeSavePermission) {
      toggleShowPermissionModal(true);
      return;
    }
    if (!open) togglePublishSection();
    onPublish(type, dispatch);
  };

  return (
    <>
      {open && <PageDimmer onClick={() => toggleOpen()} className={'opacity-0 z-[2]'} />}
      <div
        className={`flex justify-between w-full px-8 h-16 ev-sm:h-20 bg-ev-light-purple ${
          isPastingActive ? '' : 'z-[2]'
        }`}
      >
        <div className={`flex items-center ev-sm:w-2/5 ${inEditPhoneMode ? 'w-full' : 'w-[45%]'}`}>
          {!editing && (
            <div
              className="overflow-hidden whitespace-nowrap pr-1 text-ellipsis text-2xl italic underline text-ev-navy-blue-2 rounded border-2 border-transparent hover:bg-ev-baby-blue-2 focus:shadow-light"
              onClick={() => !inPackagesMode && toggleEditing()}
            >
              {titleValue}
            </div>
          )}
          {editing && !inPackagesMode && (
            <InputWidget
              value={titleValue}
              onChange={({ val }) => setValue(val as string)}
              onKeyDown={onKeyDown}
              className={`${inEditPhoneMode ? 'w-full' : 'w-72'}`}
            />
          )}
          {!inPackagesMode && (
            <IconButton
              icon={editing ? 'turn-down-left' : 'pen'}
              onClick={() => toggleEditing()}
              className="shrink-0 ml-2 h-8 w-8 rounded"
              buttonColor={ButtonColor.Transparent}
            />
          )}
        </div>
        <div className="content-centered">
          {!inPackagesMode && (
            <div className="hidden lg:block py-2 pr-4 font-medium">{getStatus(lastPublishTime, lastSaved)}</div>
          )}

          {!inEditPhoneMode && !inPackagesMode && (
            <PrimaryButton
              onClick={handleSave}
              text={saved && !changeMade ? 'Saved' : 'Save'}
              buttonColor={ButtonColor.White}
              buttonType={ButtonType.Info}
              disabled={saved && !changeMade}
              className="mr-2 text-sm"
              showSubscriptionLock={!hasSavePermission || !hasChartTypeSavePermission}
              tooltip={!hasSavePermission ? 'This feature is not available on your plan' : ''}
            />
          )}

          {!isThemeEditor && !isCompanyThemeEditor && !inEditPhoneMode && !inPackagesMode && (
            <>
              <PrimaryButton
                onClick={handlePublish}
                useLoader={{
                  default: {
                    text: buttonText,
                    icon: 'circle-arrow-up'
                  },
                  loading: {
                    text: buttonText,
                    check: publishLoading
                  },
                  done: {
                    text: buttonText,
                    icon: 'rotate',
                    check: published
                  }
                }}
                buttonColor={light ? ButtonColor.White : ButtonColor.NavyBlue}
                buttonType={ButtonType.Info}
                className="text-sm"
                showPermissionLock={!hasPublishPermission || !hasChartTypeSavePermission}
                text={buttonText}
              />
            </>
          )}
          {showPermissionModal && <PermissionModal isOpen={showPermissionModal} onClose={toggleShowPermissionModal} />}
        </div>
      </div>
    </>
  );
}
