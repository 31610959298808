import { OptionProps } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import { annotationsList, filteredBy } from '../../../utils/annotationsHelper';

const option: OptionProps = {
  id: 'annotation.text.lines',
  pid: 'annotation.text.lines',
  defaultFilterValue: ['line'],
  subType: annotationsList,
  filteredBy: filteredBy,
  options: [
    {
      pid: 'label.type',
      type: 'select',
      id: 'markerEnd',
      default: 'line',
      values: [
        { label: 'Line', value: 'line' },
        { label: 'Arrow', value: 'arrow' }
      ]
    },
    {
      pid: 'label.size',
      id: 'strokeWidth',
      type: 'number',
      default: 1
    }
  ]
};

export default option;
