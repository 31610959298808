import React from 'react';

type IconInputProps = {
  value: string | number;
  onChange: React.ChangeEventHandler;
  className?: string;
  icon?: string;
  customIcon?: React.ReactNode;
};

const IconInput = (props: IconInputProps) => {
  return (
    <span
      className={`flex bg-white rounded p-0.5 text-ev-navy-blue-2 ${props.className} hover:bg-ev-baby-blue-2 active:bg-ev-navy-blue-2 active:text-white`}
    >
      {props.icon && <i className={`fad-${props.icon}`} />}
      {props.customIcon && <div className="content-centered mr-2">{props.customIcon}</div>}
      <input
        className="highed-field-input w-16 h-[26px] min-h-[26px] text-center p-0 bg-ev-grey"
        value={props.value ?? ''}
        onChange={props.onChange}
      />
    </span>
  );
};

export default IconInput;
