import * as React from 'react';
import TextInput from 'shared/inputs/TextInput';
import { ChartLiveDataProps, LinkDataOption, LinkDataTypes } from 'shared/wizard/meta/LinkDataOptions';

type URLOnlyContentProps = {
  activeOptionValue: LinkDataOption;
  handleSubmit: (options: ChartLiveDataProps, dataType: LinkDataTypes) => void;
  shouldSubmit: boolean;
};

const URLOnlyContent = (props: URLOnlyContentProps) => {
  const { activeOptionValue, handleSubmit, shouldSubmit } = props;
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (shouldSubmit) loadLiveData();
  }, [shouldSubmit]);

  const loadLiveData = () => {
    const options = {
      [activeOptionValue]: value
    };
    handleSubmit(options, 'live');
  };

  return (
    <TextInput
      type="text"
      placeholder="Enter link"
      inputClassName="highed-field-input"
      onChange={(ev) => setValue(ev.target.value)}
      name="url"
      value={value}
      label="Link"
      labelClass="font-bold text-base"
      extraClass="pr-0"
      id="ev-spreadsheet-input"
    />
  );
};

export default URLOnlyContent;
