import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import TextInput from '../../../shared/inputs/TextInput';
import { searchAction } from '../actions/tableEditor';

interface SearchProps {
  options: {
    style?: {
      borderColor?: string;
      color?: string;
    };
    placeholderText?: string;
    labelText?: string;
    noDataText?: string;
  };
}

export default function Search(props: SearchProps) {
  const { options } = props;
  const dispatch = useDispatch();
  const { dataOptions } = useSelector((state: RootState) => state.projectsPage);
  const { searchValue, bufferDataOptions } = useSelector((state: RootState) => state.tableEditorPage);
  const [show, setShow] = useState<boolean>(false);

  const changeAndSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(searchAction({ query: e.target.value, dataOptions }));
    setShow(Boolean(e.target.value.trim()));
  };

  const iconStyle = {
    color: options?.style?.borderColor ?? ''
  };

  const resultCount = bufferDataOptions.length - 1;
  const resultText = resultCount >= 1 ? `${resultCount} results found` : options.noDataText ?? 'No data';
  const labelText = options.labelText ?? 'Search in table';
  const textColor = options?.style?.color || '';

  const style = {
    ...options?.style,
    '--placeholder-color': textColor
  };

  return (
    <div className="search-field-container">
      <i
        className={`fas fa-magnifying-glass absolute z-[1] ${labelText ? 'top-10' : 'top-3.5'} left-[17px]`}
        style={iconStyle}
      />
      <TextInput
        name="search-tables"
        value={searchValue || ''}
        onChange={changeAndSearch}
        placeholder={options.placeholderText || 'Search in table'}
        label={labelText}
        inputClassName={'inline-component pl-6'}
        style={style}
        labelStyle={{ color: textColor }}
      />
      {show && <div className={`search-results ${labelText ? 'mt-9' : 'mt-2.5'}`}>{resultText}</div>}
    </div>
  );
}
