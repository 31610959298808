export enum CodeOption {
  HTML = 'HTML',
  JSON = 'JSON'
}

export const codeOptions = [
  {
    id: CodeOption.HTML,
    item: { label: CodeOption.HTML, value: 'text/html' }
  },
  {
    id: CodeOption.JSON,
    item: { label: CodeOption.JSON, value: 'application/json' }
  }
];
