import * as React from 'react';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import TextButton from 'shared/buttons/TextButton';
import SuccessBadge from 'static/icons/success_badge.svg';

type CheckoutTileProps = {
  children: React.ReactNode;
  title: string;
  completed: boolean;
  isEditable: boolean;
  editing: boolean;
  toggleEditing: () => void;
  submitButtonText: string;
  handleSubmit?: (ev: React.MouseEvent) => void;
  saving?: boolean;
};

const CheckoutTile = (props: CheckoutTileProps) => {
  const { completed, editing, isEditable } = props;
  return (
    <div className="flex flex-col relative bg-ev-grey rounded-lg px-6 py-4 shadow-ev-standard border border-ev-grey-3">
      <div className={`flex items-center ${completed && 'pb-2'}`}>
        {props.completed && <SuccessBadge className="mr-1" />}
        <p className="text-base font-bold text-ev-navy-blue m-0">{props.title}</p>
      </div>
      {isEditable && !editing && (
        <TextButton text="Edit" onClick={props.toggleEditing} className="absolute top-4 right-6 text-ev-navy-blue-2" />
      )}
      {!completed && !editing && (
        <PrimaryButton
          text="Add"
          onClick={(ev) => {
            ev.preventDefault();
            props.toggleEditing();
          }}
          buttonColor={ButtonColor.WhiteBordered}
          className="absolute top-2 right-6 ev-sm:w-36"
        />
      )}
      {props.children}
      {editing && props.handleSubmit && (
        <PrimaryButton
          text={props.submitButtonText}
          onClick={props.handleSubmit}
          buttonColor={ButtonColor.NavyBlue}
          className="self-end mt-8"
          useLoader={{
            default: { text: props.submitButtonText },
            loading: { text: props.submitButtonText, icon: 'rotate', check: props.saving }
          }}
        />
      )}
    </div>
  );
};

export default CheckoutTile;
