import { create } from 'zustand';
import produce, { Draft, enableMapSet } from 'immer';

enableMapSet();

interface Section {
  parentId: string | null;
}

const sections = new Map<string, Section>();
const tempSelectedSection: Set<string> = new Set<string>();

interface SectionState {
  selectedSections: Set<string>;
  addSection: (id: string, parentId?: string) => void;
  setSelectedSection: (id: string) => void;
  addOrRemoveSelectedSection: (id: string) => void;
  resetSectionStore: () => void;
}

export const useSectionStore = create<SectionState>()((set) => ({
  selectedSections: new Set(),
  addSection: (id: string, parentId?: string) =>
    set(
      produce((draft: Draft<SectionState>) => {
        if (sections.has(id)) return;
        sections.set(id, { parentId: parentId ?? null });
        if (tempSelectedSection.has(id)) {
          draft.selectedSections.clear();
          if (parentId) draft.selectedSections.add(parentId);
          draft.selectedSections.add(id);
          tempSelectedSection.delete(id);
        }
      })
    ),
  setSelectedSection: (id: string) =>
    set(
      produce((draft: Draft<SectionState>) => {
        const section = sections.get(id);
        if (!section) {
          tempSelectedSection.add(id);
        } else {
          draft.selectedSections.clear();
          if (section.parentId) draft.selectedSections.add(section.parentId);
          draft.selectedSections.add(id);
        }
      })
    ),
  addOrRemoveSelectedSection: (id: string) =>
    set(
      produce((draft: Draft<SectionState>) => {
        const section = sections.get(id);
        if (!section) tempSelectedSection.add(id);
        else {
          const sectionExisted = draft.selectedSections.delete(id);
          if (!sectionExisted) {
            draft.selectedSections.clear();
            if (section.parentId) draft.selectedSections.add(section.parentId);
            draft.selectedSections.add(id);
          }
        }
      })
    ),
  resetSectionStore: () =>
    set(
      produce((draft: Draft<SectionState>) => {
        sections.clear();
        tempSelectedSection.clear();
        draft.selectedSections.clear();
      })
    )
}));
