import { OptionProps } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import { annotationsList, filteredBy } from '../../utils/annotationsHelper';

const option: OptionProps = {
  id: 'annotation.text.labels',
  pid: 'annotation.text.labels',
  defaultFilterValue: ['line'],
  subType: annotationsList,
  filteredBy: filteredBy,
  options: [
    {
      pid: 'label.text',
      annotations: {
        isText: true
      },
      id: 'format',
      default: 'Insert Text'
    },
    {
      pid: 'label.type',
      id: 'shape',
      annotations: {
        isType: true
      },
      type: 'select',
      default: 'callout',
      values: [
        { value: 'evervizCallout', label: 'Callout' },
        { value: 'evervizConnector', label: 'Connector' },
        { value: 'evervizCircle', label: 'Circle' }
      ]
    },
    {
      pid: 'label.size',
      id: 'style.fontSize',
      type: 'number',
      default: 11
    },
    {
      pid: 'label.color',
      id: 'style.color',
      type: 'color',
      default: '#ffffff'
    },
    {
      pid: 'label.background.color',
      id: 'backgroundColor',
      annotations: {
        fixMarkers: true
      },
      type: 'color',
      default: '#000000bf'
    }
  ]
};

export default option;
