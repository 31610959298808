import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Button from '../../shared/buttons/Button';
import TeamModal from '../../shared/team/TeamModal';

class NoTeam extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.closeNewTeamModal = this.closeNewTeamModal.bind(this);
    this.openNewTeamModal = this.openNewTeamModal.bind(this);

    this.state = {
      showLogoutModal: false,
      showNewTeamModal: false,
    };
  }

  openNewTeamModal() {
    this.props.toggleMenu();
    this.setState({ showNewTeamModal: true });
  }

  closeNewTeamModal() {
    this.setState({ showNewTeamModal: false });
  }

  render() {
    const parentSelector = () => document.querySelector('.page-container');
    return (
      <div className="col-sm-12 text-center">
        <TeamModal
          isOpen={this.state.showNewTeamModal}
          parentSelector={parentSelector}
          close={this.closeNewTeamModal}
          reloadTeams={this.props.reloadTeams}
        />
        <h1> You have no teams </h1>
        <p>You need to have a team to continue.</p>
        <p>
          <Button buttonClass="btn-primary" buttonText="Create Team" onClick={this.openNewTeamModal} />
        </p>
      </div>
    );
  }
}

NoTeam.propTypes = {
  paths: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  reloadTeams: PropTypes.func.isRequired,
};

export default NoTeam;
