import classNames from 'classnames';
import React from 'react';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';

type OptionSelectorProps = {
  updateSelectedPanel: React.MouseEventHandler;
  panelOptions: any;
  activePanel: any;
};

export default function OptionSelector(props: OptionSelectorProps) {
  const { panelOptions, activePanel, updateSelectedPanel } = props;
  return (
    <div className="flex flex-row gap-1 ev-sm:w-initial pl-6 ev-sm:pr-6 ev-sm:pl-8">
      {(panelOptions || []).map((panel: any) => {
        const light = activePanel !== panel.id;
        const className = classNames('mt-2 cursor-pointer text-base text-center rounded-none py-2', {
          'mb-[4.55px]': light
        });
        return (
          <div key={'panel_' + panel.id} onClick={updateSelectedPanel} id={panel.id} className={className}>
            <PrimaryButton
              onClick={updateSelectedPanel}
              text={panel.name}
              id={panel.id}
              buttonColor={light ? ButtonColor.White : ButtonColor.NavyBlue}
              buttonType={ButtonType.Info}
              className={`text-nowrap text-sm ${light ? 'font-medium underline' : ''}`}
              chevron={!light}
            />
          </div>
        );
      })}
    </div>
  );
}
