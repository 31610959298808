import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUnpublishModalAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import TextButton from 'shared/buttons/TextButton';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import { getPublishStatus, getStatus } from 'shared/editor/publishHelper';
import { checkCreatePermission, checkPermission } from 'utils/profileHelper';

type PublishSummaryProps = {
  onPublish: () => void;
  onSave: () => void;
};

const PublishSummary = (props: PublishSummaryProps) => {
  const dispatch = useDispatch();
  const { saved, published, publishLoading, changeMade, lastPublishTime, lastSaved, type, inPackagesMode } =
    useSelector((state: RootState) => state.projectConfig);
  const { status, text, buttonText, light } = getPublishStatus(
    published,
    saved,
    changeMade,
    lastPublishTime,
    lastSaved
  );
  const onUnpublish = () => dispatch(toggleUnpublishModalAction(true));
  const hasPublishPermission = checkPermission('publish');
  const hasCreatePermission = checkCreatePermission(type);

  return (
    <div className="bg-ev-light-purple p-6 rounded">
      <p className="font-bold text-ev-navy-blue">Status: {status}</p>
      <div className="text-xs block ev-sm:hidden font-medium text-ev-navy-blue-2 pb-2">
        {getStatus(lastPublishTime, lastSaved)}
      </div>
      <div>{text}</div>
      <div className="flex justify-between mt-4">
        {!inPackagesMode && (
          <PrimaryButton
            onClick={() => props.onPublish()}
            text={buttonText}
            useLoader={{
              default: {
                text: buttonText,
                icon: 'circle-arrow-up'
              },
              loading: {
                text: buttonText,
                check: publishLoading
              },
              done: {
                text: buttonText,
                icon: 'rotate',
                check: published
              }
            }}
            buttonColor={light ? ButtonColor.White : ButtonColor.NavyBlue}
            buttonType={ButtonType.Info}
            className="text-sm"
            showPermissionLock={!hasPublishPermission || !hasCreatePermission}
          />
        )}

        {!published && (!saved || changeMade) && (
          <TextButton onClick={props.onSave} text="Save draft" className="pt-2" />
        )}

        {!published && saved && !changeMade && (
          <div className="text-sm font-medium pt-2">
            <i className="fas fa-check-circle" /> Saved
          </div>
        )}
        {!!published && !inPackagesMode && <TextButton onClick={onUnpublish} text="Unpublish" className="pt-2" />}
      </div>
    </div>
  );
};

export default PublishSummary;
