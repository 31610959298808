import classNames from 'classnames';
import { BillingForm, SubscriptionPlan, SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanDescription, isSubmitDisabled } from 'pages/SubscriptionPage/utils/billingHelpers';
import { getPlanById } from 'pages/SubscriptionPage/utils/plansHelper';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';

type PlanSummaryProps = {
  plan: SubscriptionPlan;
  billingForm: BillingForm;
  billingData: any;
  billingError: string;
  handleSubmit: () => void;
  isLoggedIn: boolean;
};

type StickyPosition = 'top' | 'bottom' | 'none';

const PlanSummary = (props: PlanSummaryProps) => {
  const { plan, billingForm, billingData, isLoggedIn } = props;
  const { savingPlan } = useSelector((state: RootState) => state.plansPage);
  const [submitDisabled, setSubmitDisabled] = React.useState(isSubmitDisabled(billingForm, billingData, isLoggedIn));
  const [startingPlan, setStartingPlan] = React.useState(false);
  const [sticky, setSticky] = React.useState<StickyPosition>('none');
  const [showPlanDetails, setShowPlanDetails] = React.useState(false);

  const price = `$${(plan.price as number).toLocaleString()}`;
  const planName = getPlanById(plan.id) as SubscriptionPlans;

  const togglePlanDetails = () => {
    setShowPlanDetails(!showPlanDetails);
  };

  React.useEffect(() => {
    setSubmitDisabled(isSubmitDisabled(billingForm, billingData, isLoggedIn));
  }, [billingForm, billingData]);

  React.useEffect(() => {
    setStartingPlan(savingPlan);
  }, [savingPlan]);

  React.useEffect(() => {
    const handleScroll = () => {
      const offsetTop = Number(document.getElementById('plan-summary')?.getBoundingClientRect().top);
      const sidePanelHeight = Number(document.getElementById('plan-summary-block')?.getBoundingClientRect().height);
      const offsetBottom = Number(document.getElementById('recurly-form-blocks')?.getBoundingClientRect().bottom);
      const formHeight = Number(document.getElementById('recurly-form-blocks')?.getBoundingClientRect().height);
      if (offsetTop <= 80 && sidePanelHeight < formHeight) {
        if (offsetBottom <= sidePanelHeight + 80) setSticky('bottom');
        else setSticky('top');
      } else setSticky('none');
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const classes = classNames(
    'bg-ev-grey rounded-lg flex flex-col ev-lg:w-[300px] h-fit py-4 shadow-ev-standard border border-ev-grey-3',
    {
      ['ev-lg:fixed ev-lg:top-20']: sticky === 'top',
      ['ev-lg:absolute ev-lg:bottom-0']: sticky === 'bottom'
    }
  );

  return (
    <div className="relative flex shrink-0 ev-lg:w-[300px]" id="plan-summary">
      <div className={classes} id="plan-summary-block">
        <p className="font-bold mx-6 mb-2 text-ev-navy-blue">{plan.name} plan</p>

        <p className="mx-6 mb-6">
          {price} USD {plan.interval_length === 12 ? 'yearly' : 'monthly'}
        </p>

        <div>
          <div className="border-y" onClick={togglePlanDetails}>
            <div className="flex justify-between items-center mx-6 cursor-pointer">
              <span>Plan details</span>
              <i className={`fas fa-chevron-${showPlanDetails ? 'up' : 'down'}`} />
            </div>
          </div>

          {showPlanDetails && (
            <ul className="mx-6 my-2 font-medium text-base text-ev-navy-blue">
              {getPlanDescription(planName)?.map((property) => (
                <li key={`${planName}-${property.slice(3)}`}>{property}</li>
              ))}
            </ul>
          )}
        </div>

        <div className="border-y">
          <div className="flex mx-6 my-2 justify-between">
            Subtotal <span>{price}</span>
          </div>
        </div>

        <div className="flex mx-6 my-2 justify-between">
          Billed now <span>{price}</span>
        </div>

        <PrimaryButton
          text="Start plan"
          onClick={props.handleSubmit}
          className="w-40 mx-auto my-6"
          disabled={submitDisabled}
          useLoader={{
            default: { text: 'Start plan' },
            loading: { text: 'Starting plan', icon: 'rotate', check: startingPlan }
          }}
        />

        {props.billingError && (
          <div className="mb-2 mx-6 font-bold text-ev-error">
            <span>
              <i className="pr-2 fa fa-info-circle" />
            </span>
            {props.billingError}
          </div>
        )}

        {submitDisabled && (
          <p className="text-sm mx-6">
            You need to confirm your billing cycle, add a business address, and add a payment method before you can
            start this plan.
          </p>
        )}
      </div>
    </div>
  );
};

export default PlanSummary;
