import { TemplateProps } from '../../types';

const Column: TemplateProps = {
  title: 'Column',
  description: '',
  constructor: '',
  thumbnail: 'ovobiq.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column',
      polar: false,
      inverted: false
    },
    yAxis: {
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'column',
  dataValidator: false,
  advancedParent: 'Column',
  basic: {
    order: 2,
    thumbnail: 'column_graph.svg',
    icon: 'Column'
  },
  advanced: {
    name: 'Standard'
  },
  parent: 'Column'
};

export default Column;
