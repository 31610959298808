import React from 'react';

export default () => {
  return (
    <div className="highed-transition highed-annotation-container tools-container">
      <span className="highed-template-tooltip annotation-buttons annotation-button">
        <i className="fas fa-marker" aria-hidden="true" />
        <span className="highed-tooltip-text">Annotations</span>
      </span>
      <span className="highed-template-tooltip annotation-buttons  highcharts-label-annotation  highed-svg-icon">
        <i className="fa fa-undefined" aria-hidden="true" />
        <span className="highed-tooltip-text">Label</span>
        <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/label.svg" />
      </span>
      <span className="highed-template-tooltip annotation-buttons  highcharts-segment  highed-svg-icon">
        <i className="fa fa-undefined" aria-hidden="true" />
        <span className="highed-tooltip-text">Line</span>
        <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/segment.svg" />
      </span>

      <span className="fas fa-ellipsis-h">
        <div className="highed-annotation-submenu">
          <span className="highed-template-tooltip annotation-buttons highcharts-segment-icon ">
            <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/segment.svg" />
          </span>
          <span className="highed-template-tooltip annotation-buttons highcharts-arrow-segment-icon ">
            <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/arrow-segment.svg" />
          </span>
          <span className="highed-template-tooltip annotation-buttons highcharts-ray-icon ">
            <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/ray.svg" />
          </span>
          <span className="highed-template-tooltip annotation-buttons highcharts-arrow-ray-icon ">
            <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/arrow-ray.svg" />
          </span>
          <span className="highed-template-tooltip annotation-buttons highcharts-infinity-line-icon ">
            <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/line.svg" />
          </span>
          <span className="highed-template-tooltip annotation-buttons highcharts-arrow-infinity-line-icon ">
            <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/arrow-line.svg" />
          </span>
          <span className="highed-template-tooltip annotation-buttons highcharts-horizontal-line-icon ">
            <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/horizontal-line.svg" />
          </span>
          <span className="highed-template-tooltip annotation-buttons highcharts-vertical-line-icon ">
            <img src="https://code.highcharts.com/7.1.2/gfx/stock-icons/vertical-line.svg" />
          </span>
        </div>
      </span>
      <span className="highed-template-tooltip annotation-buttons times undefined ">
        <i className="fa fa-times" aria-hidden="true" />
        <span className="highed-tooltip-text">Close</span>
      </span>
      <span className="highed-template-tooltip annotation-buttons times undefined ">
        <i className="fa fa-times" aria-hidden="true" />
        <span className="highed-tooltip-text">Close</span>
      </span>
    </div>
  );
};
