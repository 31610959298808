import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { showPaidPlanModal } from 'shared/utils/paidPlanHelper';

export const getFreemiumContextButtonOptions = () => {
  const getTextContent = (text: string) =>
    `<div style="display: flex"><img src="/static/icons/star.svg" height="14" width="14" /><span style="margin-left: 4px">${text}</span></div>`;

  const onclick = () => {
    showPaidPlanModal(SubscriptionPlans.Pro.toLowerCase());
  };

  return {
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            {
              text: getTextContent('Download PNG image'),
              onclick
            },
            {
              text: getTextContent('Download JPEG image'),
              onclick
            },
            {
              text: getTextContent('Download PDF document'),
              onclick
            },
            {
              text: getTextContent('Download SVG vector image'),
              onclick
            },
            'separator',
            {
              text: getTextContent('Download CSV'),
              onclick
            },
            {
              text: getTextContent('Download XLS'),
              onclick
            },
            'viewData'
          ]
        }
      }
    }
  };
};
