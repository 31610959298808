import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

export default function ExportMenu(props) {
  const { options, aggregatedOptions } = props;
  const { cssModules, dataOptions } = useSelector((state) => state.projectConfig);
  const [hovered, setHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [symbol, setSymbol] = useState(true);
  const contextButtonSymbol = options?.buttons?.contextButton?.symbol;
  const menuRef = useRef(null);
  const itemClass = 'hover:bg-gray-200 p-1 cursor-pointer text-xs w-full text-left';
  const buttonClass = `h-7 absolute top-2.5 right-2.5 w-fit pointer-events-auto hover:bg-gray-200 rounded-sm pl-0.5 pr-1 ${
    showMenu ? 'bg-violet-100 hover:bg-violet-100' : ''
  }`;

  useEffect(() => {
    if (contextButtonSymbol === undefined) {
      setSymbol(true);
    } else if (contextButtonSymbol === null) {
      setSymbol(false);
    } else if (contextButtonSymbol === 'menu') {
      setSymbol(true);
    }
  }, [contextButtonSymbol]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const onClickMenuIcon = () => setShowMenu((prev) => !prev);

  const download = (type) => {
    onClickMenuIcon();
    if (type === 'csv') {
      window.Everviz.downloadCSV({
        options: aggregatedOptions,
        dataOptions
      });
      return;
    }

    if (type === 'xls') {
      window.Everviz.downloadXLS({
        options: aggregatedOptions,
        dataOptions
      });
      return;
    }

    window.Everviz.downloadTableImage(
      {
        options: aggregatedOptions,
        cssModules,
        dataOptions
      },
      type
    );
  };
  return (
    <>
      <button
        className={buttonClass}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClickMenuIcon}
        aria-expanded={showMenu}
      >
        <div className="flex flex-row h-6 w-[calc(min-content + 26px)]">
          {symbol ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-full flex">
              <g className="" strokeLinecap="round" transform="">
                <title>{options?.lang?.contextButtonTitle ?? 'Table context menu'}</title>
                <rect
                  fill={showMenu ? '#E6E9FF' : hovered ? '#e6e6e6' : '#ffffff'}
                  className="highcharts-button-box"
                  x="0.5"
                  y="0.5"
                  width="24"
                  height="22"
                  rx="2"
                  ry="2"
                  stroke="none"
                  strokeWidth="1"
                />
                <path
                  fill="#666666"
                  d="M 6 6.5 L 20 6.5 M 6 11.5 L 20 11.5 M 6 16.5 L 20 16.5"
                  className="highcharts-button-symbol"
                  stroke="#666666"
                  strokeWidth="3"
                />
                <text
                  x="0"
                  y="15.5"
                  style={{ color: 'rgb(51, 51, 51)', fontWeight: 'normal', fill: 'rgb(51, 51, 51)' }}
                />
              </g>
            </svg>
          ) : (
            <div />
          )}
          {options?.buttons?.contextButton?.text && (
            <p
              className={`${showMenu ? 'text-black font-bold' : 'text-neutral-500'} text-sm ml-1 mb-0 self-center ${
                symbol ? 'pr-1.5' : 'pr-1'
              }`}
              style={{ font: 'caption' }}
            >
              {options?.buttons?.contextButton?.text}
            </p>
          )}
        </div>
      </button>
      {showMenu && (
        <>
          <div
            ref={menuRef}
            className="absolute top-10 right-2.5 bg-white z-50 rounded-sm pointer-events-auto shadow-ev-standard"
          >
            <div
              className="rounded-sm m-2 flex flex-col"
              style={{
                backgroundColor: options?.style?.backgroundColor ?? 'white',
                color: options?.style?.color ?? 'evNavyBlue2'
              }}
            >
              <button onClick={() => download('png')} className={itemClass}>
                {options?.lang?.downloadPNG ?? 'Download PNG image'}
              </button>
              <button
                onClick={() => download('jpeg')}
                className={itemClass}
                aria-label={options?.lang?.downloadJPEG ?? 'Download JPEG image'}
              >
                {options?.lang?.downloadJPEG ?? 'Download JPEG image'}
              </button>
              <button
                onClick={() => download('pdf')}
                className={itemClass}
                aria-label={options?.lang?.downloadPDF ?? 'Download PDF document'}
              >
                {options?.lang?.downloadPDF ?? 'Download PDF document'}
              </button>
              <button
                onClick={() => download('csv')}
                className={itemClass}
                aria-label={options?.lang?.downloadCSV ?? 'Download CSV'}
              >
                {options?.lang?.downloadCSV ?? 'Download CSV'}
              </button>
              <button
                onClick={() => download('xls')}
                className={itemClass}
                aria-label={options?.lang?.downloadXLS ?? 'Download XLS'}
              >
                {options?.lang?.downloadXLS ?? 'Download XLS'}
              </button>
            </div>
          </div>
          <div className="absolute w-full h-full top-0 left-0 z-1" onClick={() => setShowMenu(false)} />
        </>
      )}
    </>
  );
}
