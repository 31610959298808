import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';

const Pagination = ({ pageCount, currentPage, onSelectPage }) => {
  currentPage = parseInt(currentPage);

  const createLink = (text, index, active, disabled) => {
    let activeClass = active ? ' active' : '';
    let disabledClass = disabled ? ' disabled' : '';

    let onClick = (e) => {
      e.preventDefault();
      if (!active && !disabled) {
        if (text === '«') {
          onSelectPage(1);
        } else if (text === '‹') {
          onSelectPage(currentPage - 1);
        } else if (text === '›') {
          onSelectPage(currentPage + 1);
        } else if (text === '»') {
          onSelectPage(pageCount);
        } else if (index !== currentPage) {
          onSelectPage(index);
        }
      }
    };

    return (
      <li className={'page-item' + activeClass + disabledClass}>
        <a onClick={onClick} className="page-link" href="#" tabIndex={disabled ? '-1' : ''}>
          {text}
          {active && <span className="sr-only">(current)</span>}
        </a>
      </li>
    );
  };

  const back = [createLink('«', 0, false, currentPage === 1), createLink('‹', 0, false, currentPage === 1)];

  const nearby = paginate(9, currentPage - 1, range(1, pageCount + 1)).map((page) =>
    createLink(page, page, page === currentPage)
  );

  const forward = [
    createLink('›', 0, false, currentPage === pageCount),
    createLink('»', 0, false, currentPage === pageCount),
  ];

  const paginationLinks = [...back, ...nearby, ...forward];
  return (
    <nav aria-label="...">
      <ul className="pagination">{paginationLinks}</ul>
    </nav>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onSelectPage: PropTypes.func.isRequired,
};

const paginate = (size, current, pages) => {
  // Assume size is odd
  const edge = Math.floor(size / 2);
  const aux = (pivot) => {
    // Early return when size > pages
    // and base case when pivot is extended outside pages
    if (size > pages.length || current + pivot < 0 || current + pivot > pages.length) {
      return pages;
    }

    const start = current + pivot - edge;
    if (start < 0) {
      return aux(pivot + 1);
    }

    const stop = current + pivot + edge + 1;
    if (stop > pages.length) {
      return aux(pivot - 1);
    }

    return pages.slice(start, stop);
  };
  return aux(0);
};

export default Pagination;
