export default {
  series: 1,
  data: 'Category;Series 1 (low);Series 1 (q1);Series 1 (median);Series 1 (q3);Series 1 (high)\n0;760;801;848;895;965\n1;733;853;939;980;1080\n2;714;762;817;870;918',
  seriesMapping: [
    {
      x: 0,
      low: 1,
      high: 2,
      median: 3,
      q1: 4,
      q3: 5,
    },
  ],
  assignDataFields: [
    {
      labels: 'A',
      low: 'B',
      high: 'C',
      median: 'D',
      q1: 'E',
      q3: 'F',
    },
  ],
};
