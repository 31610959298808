import { TemplateProps } from './../types';

const Column: TemplateProps = {
  title: 'Column',
  description: '',
  constructor: 'StockChart',
  thumbnail: 'ogywen.svg',
  icon: '',
  sampleSets: ['line-series-dates'],
  validator: false,
  config: {
    chart: {
      type: 'column',
      polar: false
    },
    rangeSelector: {
      enabled: true
    }
  },
  advancedParent: 'Stock',
  sampleSet: 'column',
  dataValidator: false
};

export default Column;
