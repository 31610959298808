/* eslint-disable no-template-curly-in-string */
export const axisLabelFormatOptions = [
  { label: '{value}', value: '{value}' },
  { label: '$12 (${value})', value: '${value}' },
  { label: '12 USD ({value} USD)', value: '{value} USD' },
  { label: '12.00 ({value:.2f})', value: '{value:.2f}' },
  { label: '14-12-2020 ({value:%d-%m-%Y})', value: '{value:%d-%m-%Y}' },
  { label: '12-14-2020 ({value:%m-%d-%Y})', value: '{value:%m-%d-%Y}' },
  { label: '2020-12-14 ({value:%Y-%m-%d})', value: '{value:%Y-%m-%d}' }
];
export const yAxisTitleAlignValue = [
  { label: 'Top', value: 'high' },
  { label: 'Middle', value: 'middle' },
  { label: 'Bottom', value: 'low' }
];
export const xAxisTitleAlignValue = [
  { label: 'Left', value: 'low' },
  { label: 'Right', value: 'high' },
  { label: 'Middle', value: 'middle' }
];
export const dataLabelFormatOptions = [
  // { label: 'Value', value: '{point.value}' },
  { label: 'Y Value', value: '{point.y}' },
  { label: 'X Value', value: '{point.x}' },
  { label: 'Point name', value: '{point.name}' },
  { label: 'Series name', value: '{series.name}' },
  { label: 'Color', value: '{point.color}' },
  { label: 'Extra', value: '{point.extra}' }
];

export const markerPointOptions = [
  { label: 'None', value: null },
  { label: 'Circle', value: 'circle' },
  { label: 'Square', value: 'square' },
  { label: 'Diamond', value: 'diamond' },
  { label: 'Triangle', value: 'triangle' },
  { label: 'Triangle down', value: 'triangle-down' }
];

export const seriesTypeListOptions = [
  { label: 'Line', value: 'line' },
  { label: 'Spline', value: 'spline' },
  { label: 'Scatter', value: 'scatter' },
  { label: 'Bar', value: 'bar' },
  { label: 'Column', value: 'column' },
  { label: 'Area', value: 'area' },
  { label: 'Area spline', value: 'areaspline' },
  { label: 'Area range', value: 'arearange' },
  { label: 'Area spline range', value: 'areasplinerange' },
  { label: 'Column range', value: 'columnrange' },
  { label: 'Error bar', value: 'errorbar' },
  { label: 'Funnel', value: 'funnel' }
];

// Used in wizard, convert rest to new method when moving to new editor

export const orientationOptions = [
  // { label: 'Value', value: '{point.value}' },
  { label: 'Vertical', value: 'vertical' },
  { label: 'Horizontal', value: 'horizontal' }
];
export const dashStyleOptions = [
  { label: 'Solid', value: 'Solid' },
  { label: 'Short dash', value: 'ShortDash' },
  { label: 'Short dot', value: 'ShortDot' },
  { label: 'Short dash dot', value: 'ShortDashDot' },
  { label: 'Short dash dot dot', value: 'ShortDashDotDot' },
  { label: 'Dot', value: 'Dot' },
  { label: 'Dash', value: 'Dash' },
  { label: 'Long dash', value: 'LongDash' },
  { label: 'Dash dot', value: 'DashDot' },
  { label: 'Long dash dot', value: 'LongDashDot' },
  { label: 'Long dash dot dot', value: 'LongDashDotDot' }
];

export const yAxisTitleAlignValueWizard = [
  { label: 'Top', value: 'high' },
  { label: 'Middle', value: 'middle' },
  { label: 'Bottom', value: 'bottom' }
];

export const alignValue = [
  { label: 'Center', value: 'center' },
  { label: 'Left', value: 'left' },
  { label: 'Right', value: 'right' }
];

export const axisTypeOptions = [
  {
    label: 'Linear',
    value: 'linear'
  },
  {
    label: 'Logarithmic',
    value: 'logarithmic'
  },
  {
    label: 'Date time',
    value: 'datetime'
  },
  {
    label: 'Category',
    value: 'category'
  }
];

export const colorAxisTypeOptions = [
  {
    label: 'Logarithmic',
    value: 'logarithmic'
  },
  {
    label: 'Linear',
    value: 'linear'
  }
];

export const markerPointRefersTo = {
  all: {
    null: {
      set: {
        'series[n].marker.symbol': null,
        'series[n].marker.enabled': false
      }
    },
    circle: {
      set: {
        'series[n].marker.symbol': 'circle',
        'series[n].marker.enabled': true
      },
      get: [
        {
          'series[n].marker.symbol': 'circle',
          'series[n].marker.enabled': true
        },
        {
          'series[n].marker.symbol': undefined,
          'series[n].marker.enabled': undefined
        }
      ]
    },
    square: {
      set: {
        'series[n].marker.symbol': 'square',
        'series[n].marker.enabled': true
      }
    },
    diamond: {
      set: {
        'series[n].marker.symbol': 'diamond',
        'series[n].marker.enabled': true
      }
    },
    triangle: {
      set: {
        'series[n].marker.symbol': 'triangle',
        'series[n].marker.enabled': true
      }
    },
    'triangle-down': {
      set: {
        'series[n].marker.symbol': 'triangle-down',
        'series[n].marker.enabled': true
      }
    }
  }
};
