import { getAllInCatToArray, getMostPopular } from 'editor/core/highed.templateman';
import { cloneDeep, isArray, set } from 'lodash';
import { snackBar } from 'editor/editors/highed.init';
import { defaultChartOptions, defaultMapOptions } from 'pages/ChartEditorPage/meta/DefaultOptions';
import { addBlankSeries } from 'pages/ChartEditorPage/middleware/ChartEditorData';
import { setTemplate } from 'pages/ChartEditorPage/middleware/ChartEditorTemplates';
import { parseCSV } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import { updateBufferData } from 'pages/TableEditorPage/middleware/tableEditorData';
import { defaultData as defaultTableData, defaultTableOptions } from 'pages/TableEditorPage/reducers/init';
import { getTableSpecificConfig } from 'pages/TableEditorPage/selectors/tableEditor';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { generateEditorSettings } from 'shared/utils/editorHelper';
import {
  getTeamCompanythemeWithTeamid,
  getTeamCompanythemeWithTeamidAndThemeid,
  postTeamCompanythemeUsingTeamid,
  postTeamCompanythemeUsingTeamidAndThemeid
} from '../../../api/cloudApiGenerated';
import { loadModules, merge } from '../../../editor/core/highcharts-editor';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { getAppConfig, getProfileConfig } from '../../../redux/selectors/profile';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { setAction as setChartAction } from '../../ChartEditorPage/actions/chartEditor';
import { updateAggregated } from '../../ChartEditorPage/middleware/ChartEditor';
import { setAction } from '../actions/ThemeEditorPage';
import {
  createChartDefault,
  createDefaults,
  createMapDefault,
  createTableDefault,
  loadChartData,
  loadMapData
} from './themeEditorHelper';

// Called when saving a company theme
export function* saveCompanyTheme(params) {
  const { team, division, history, cb } = params.data;
  const { paths } = yield select(getAppConfig);
  const { options, themeId } = yield select((state) => state.companyThemeEditorPage);
  const { cssModules, projectName: name } = yield select(getProjectConfig);
  const project = cloneDeep(options); //merge(merge({}, themeOptions), merge({}, customizedOptions))

  delete project.table.all.data;
  delete project.map.all.data;
  delete project.chart.all.data;
  project.cssModules = cssModules;

  const teamId = team.id;
  const body = { data: JSON.stringify(project), name };

  snackBar('Saving theme');

  let saveTheme;
  let parameters = [];

  if (!themeId) {
    if (division && division.id !== null) body.division_id = division.id;
    saveTheme = postTeamCompanythemeUsingTeamid;
    parameters = [teamId, body];
  } else {
    saveTheme = postTeamCompanythemeUsingTeamidAndThemeid;
    parameters = [teamId, themeId, body];
  }

  try {
    const data = yield call(saveTheme, ...parameters);
    snackBar('Theme saved');

    cb(data.theme_id);

    if (!themeId) {
      let newPath = paths.themePreview.replace(':themeid', data.theme_id);
      history.push(newPath);
    }
  } catch (error) {
    yield put(
      setAction({
        error: 'saving theme: ' + error.message || error
      })
    );
  }
}

// Used in editor wizards to get the teams active company theme
export function* loadActiveCompanyTheme(type) {
  const { team } = yield select(getProfileConfig);
  try {
    let data = [];
    if (team) data = yield call(getTeamCompanythemeWithTeamid, team.id);

    if (!data.length) {
      yield put(
        setAction({
          options: false
        })
      );
      return false;
    }

    yield call(useCompanyTheme, data[0], data[0].id, true, type);
    return true;
  } catch (error) {
    setAction({
      error: 'loading theme: ' + error.message || error
    });
  }
}

// Used in the company theme editor to load up an already created company theme.
export function* loadCompanyTheme(params) {
  const { themeId, skipEmit, cb } = params.data;
  const { team } = yield select(getProfileConfig);
  try {
    const data = yield call(getTeamCompanythemeWithTeamidAndThemeid, team.id, themeId);
    const themeOptions = yield call(useCompanyTheme, data, themeId, skipEmit, true);
    if (data?.name) {
      yield put(
        setProjectConfigAction({
          projectName: data.name
        })
      );
    }
    if (cb) cb(themeOptions);
  } catch (error) {
    console.log(error);
    setAction({
      error: 'loading theme: ' + error.message || error
    });
  }
}

// Used to set up initial company theme options. Will configure the correct configuration for the type and then assign theme to it.
// 1) Used when loading a company theme (Wizard or in the company theme editor)
// 2) Clicking to use a company theme from themes card
export function* useCompanyTheme(data, themeId, skipEmit, type, useDefaultData = false) {
  try {
    const { chartType } = yield select((state) => state.companyThemeEditorPage);

    if (Object.keys(data).length === 0) {
      return false;
    }

    let project = JSON.parse(data.data);

    //Set up default data
    if (useDefaultData) {
      project.map.all.data = loadMapData(chartType.value);
      project.chart.all.data = loadChartData(chartType.value);
      project.table.all.data = defaultTableData;
    }

    loadModules(project.cssModules ?? [], null, null, true);
    // Create highchart configs for different types
    let { chartDefault, mapDefault, tableDefault, layoutDefault } = yield call(createDefaults, project);
    let defaultOptions = chartDefault;
    if (type === 'map') defaultOptions = mapDefault;
    else if (type === 'table') defaultOptions = { options: tableDefault };
    else if (type === 'layout') defaultOptions = { options: layoutDefault };

    const themeMeta = {
      id: data.id,
      name: data.name
    };

    yield all([
      put(
        setProjectConfigAction({
          themeMeta,
          themeOptions: defaultOptions
        })
      ),
      put(
        setAction({
          name: data.name,
          options: project,
          themeId
        })
      )
    ]);

    if (skipEmit) return defaultOptions;

    yield call(updateAggregated, true);
    return defaultOptions;
  } catch (error) {
    console.log(error);
    setAction({
      error: 'loading theme: ' + error.message || error
    });
  }
}

export function* initCompanyThemeEditor(params) {
  const { query, urlParams } = params.data;

  const hasChartId = urlParams?.chartid && parseInt(urlParams.chartid, 10);
  const hasThemeId = urlParams?.themeid && parseInt(urlParams.themeid, 10);
  const hasUUID = query?.uuid && query.uuid > '';
  const isNew =
    (location.pathname.match('/create') && !hasChartId && !hasThemeId) ||
    (location.pathname.match('/create') && hasUUID);
  const isMap = query && 'maps' in query;

  const profileConfig = yield select(getProfileConfig);
  const { division, team, user } = profileConfig;
  const anonymous = !user && !team;

  if (hasThemeId) {
    yield call(loadCompanyTheme, { data: { themeId: parseInt(hasThemeId, 10), skipEmit: true } });
  }

  const { editorConfig } = yield generateEditorSettings(team, division, anonymous, false, false);

  const { options } = yield select((state) => state.companyThemeEditorPage);

  loadModules(options.cssModules ?? [], null, null, true);

  yield all([
    put(setProjectConfigAction({ isCompanyThemeEditor: true })),
    put(
      setChartAction({
        isMap,
        editorConfig: editorConfig,
        loadingEditor: !isNew,
        initedEditor: true,
        chartId: urlParams?.chartid && parseInt(urlParams?.chartid, 10),
        chartVersion: urlParams?.chartversion && parseInt(urlParams?.chartversion, 10),
        useInlineEditor: false
      })
    )
  ]);

  yield call(switchToHighchartType);
}

function* loadTemplate(isMap, type) {
  if (isMap) {
    const mapTypes = getAllInCatToArray('Map');
    const map = mapTypes.filter((map) => map.defaultDataType === (type ?? 'choropleth'));
    yield call(setTemplate, { data: { template: map[0] } });
    return;
  }
  const chartTypes = getMostPopular();
  const chartType = type ?? 'line';

  const chart = chartTypes.filter((chart) => {
    return chart.sampleSet === chartType; //chart.defaultDataType === chartType || chart.config?.chart?.type === chartType;
  });
  yield call(setTemplate, { data: { template: chart[0] } });
}

export function* switchToTableType() {
  try {
    const { defaultDataOptions } = yield select(getTableSpecificConfig);
    const { options } = yield select((state) => state.companyThemeEditorPage);
    let customizedOptions = cloneDeep(defaultTableOptions);

    const tableDefault = createTableDefault(options);

    let projectConfigOptions = {};
    projectConfigOptions.themeOptions = { options: tableDefault };

    customizedOptions.data = defaultTableData;

    projectConfigOptions.dataOptions = cloneDeep(defaultDataOptions);
    projectConfigOptions.customizedOptions = customizedOptions;
    projectConfigOptions.type = 'table';

    yield put(setProjectConfigAction(projectConfigOptions));

    yield call(updateBufferData, {
      dataOptions: projectConfigOptions.dataOptions
    });
  } catch (e) {
    console.log('e', e);
  }
}

export function* switchToHighchartType(isMap, skipLoadTemplate) {
  let { options, chartType } = yield select((state) => state.companyThemeEditorPage);

  yield all([
    put(
      setChartAction({
        isMap: isMap ?? false,
        constr: isMap ? 'Map' : 'Chart'
      })
    ),
    put(
      setProjectConfigAction({
        type: 'chart'
      })
    )
  ]);

  if (!skipLoadTemplate) yield call(loadTemplate, isMap, chartType.value);

  let { mapDefault, chartDefault } = yield call(createDefaults, options);
  let themeOptions = isMap ? mapDefault : chartDefault;
  if (!themeOptions.series) {
    themeOptions = yield call(addBlankSeries, {
      data: {
        index: 0,
        skipEmit: true,
        customizedOptions: themeOptions
      }
    });
  }
  let defaultCustomizeOptions = merge({}, defaultChartOptions);
  delete defaultCustomizeOptions.credits;
  if (isMap) {
    defaultCustomizeOptions = merge(defaultCustomizeOptions, defaultMapOptions);
    defaultCustomizeOptions.data = loadMapData(chartType.value);
  } else {
    defaultCustomizeOptions.data = loadChartData(chartType.value);
  }

  const data = isMap ? loadMapData(chartType.value).csv : loadChartData(chartType.value).csv;
  yield put(
    setProjectConfigAction({
      themeOptions,
      dataOptions: parseCSV(data),
      customizedOptions: defaultCustomizeOptions
    })
  );

  yield call(updateAggregated, true);
}

//Change from table/maps/charts
export function* switchProjectType(params) {
  const { view, skipLoadTemplate } = params.data;
  const { defaultMapTypeSelection, defaultChartTypeSelection } = yield select((state) => state.companyThemeEditorPage);
  const isMap = view === 'Maps';

  yield put(
    setAction({
      chartType: cloneDeep(isMap ? defaultMapTypeSelection : defaultChartTypeSelection)
    })
  );

  if (view === 'Charts') yield call(switchToHighchartType, false, skipLoadTemplate);
  else if (view === 'Maps') yield call(switchToHighchartType, true, skipLoadTemplate);
  else if (view === 'Tables') yield call(switchToTableType);

  yield put(
    setAction({
      view
    })
  );
}

// Called when changing a property on the left menu
function* updateThemeConfig(params) {
  const { optionProps, val } = params.data;
  const { options: oldOptions } = yield select((state) => state.companyThemeEditorPage);
  const { type } = yield select((state) => state.projectConfig);
  const { isMap } = yield select((state) => state.chartEditorPage);
  const options = cloneDeep(oldOptions);

  if (isArray(optionProps.aliasKey)) {
    optionProps.aliasKey.forEach((id) => {
      set(options, `${id}`, val);
    });
  } else set(options, `${optionProps.aliasKey}`, val);

  yield put(
    setAction({
      options
    })
  );

  if (type === 'chart') {
    const chartThemeOptions = isMap ? yield call(createMapDefault, options) : createChartDefault(options);
    yield put(setProjectConfigAction({ themeOptions: chartThemeOptions }));
    yield call(updateAggregated, false);
  } else {
    const tableThemeOptions = createTableDefault(options);
    yield put(setProjectConfigAction({ themeOptions: { options: tableThemeOptions } }));
    yield call(updateBufferData, {});
  }
}

// Change project type dropdown
export function* changeType(params) {
  try {
    const { chartType } = params.data;
    const { isMap } = yield select((state) => state.chartEditorPage);
    const { customizedOptions: customized } = yield select((state) => state.projectConfig);
    const customizedOptions = cloneDeep(customized);

    customizedOptions.data = isMap ? loadMapData(chartType.value).csv : loadChartData(chartType.value).csv;
    yield all([
      put(
        setProjectConfigAction({
          customizedOptions
        })
      ),
      put(
        setAction({
          chartType
        })
      )
    ]);

    yield call(loadTemplate, isMap, chartType.value);
  } catch (e) {
    console.log(e);
  }
}

export function* useCompanyThemeOptions() {
  const { isMap } = yield select((state) => state.chartEditorPage);
  const { options } = yield select((state) => state.companyThemeEditorPage);
  let themeOptions = false;
  if (!options) return;
  if (isMap) {
    themeOptions = yield call(createMapDefault, options);
  } else themeOptions = createChartDefault(options);

  const config = yield select(getProjectConfig);
  let customizedOptions = merge({}, config.customizedOptions);

  if (themeOptions) {
    const themeDataClasses = themeOptions?.colorAxis?.dataClasses ?? [];
    const customizedDataClasses = customizedOptions?.colorAxis?.dataClasses ?? [];

    if (isMap && themeDataClasses.length > 0 && customizedDataClasses.length > 0) {
      customizedOptions.colorAxis = customizedOptions.colorAxis || {};
      customizedOptions.colorAxis.dataClasses = cloneDeep(themeDataClasses);
    }

    yield put(
      setProjectConfigAction({
        customizedOptions,
        themeOptions
      })
    );
  }
}

/** Watch functions */
export function* watchSaveCompanyTheme() {
  yield takeLatest(actionTypes.companyThemeEditor.saveCompanyTheme, saveCompanyTheme);
}
export function* watchLoadCompanyTheme() {
  yield takeLatest(actionTypes.companyThemeEditor.loadCompanyTheme, loadCompanyTheme);
}
export function* watchSwitchProjectType() {
  yield takeLatest(actionTypes.companyThemeEditor.switchProjectType, switchProjectType);
}
export function* watchInitCompanyThemeEditor() {
  yield takeLatest(actionTypes.companyThemeEditor.initCompanyThemeEditor, initCompanyThemeEditor);
}
export function* watchUpdateThemeConfig() {
  yield takeLatest(actionTypes.companyThemeEditor.updateThemeConfig, updateThemeConfig);
}
export function* watchChangeType() {
  yield takeLatest(actionTypes.companyThemeEditor.changeType, changeType);
}

export default function* rootSaga() {
  yield all([
    watchSaveCompanyTheme(),
    watchLoadCompanyTheme(),
    watchSwitchProjectType(),
    watchInitCompanyThemeEditor(),
    watchUpdateThemeConfig(),
    watchChangeType()
  ]);
}
