export enum SignupModalMode {
  Login = 'Log in',
  Signup = 'Sign up for 14-day trial'
}
export enum SignupModalHeader {
  Login = 'Log in',
  Signup = 'Sign up for a 14-day trial'
}

export interface SignupModalTexts {
  mainText?: string;
  additionalText: string;
  link: string;
  externalGoogleLink: string;
}
