import { TemplateProps } from '../../types';

const ColumnrangeWithLabels: TemplateProps = {
  title: 'Columnrange with labels',
  description: '',
  constructor: '',
  thumbnail: 'ojykiw.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'columnrange',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true
        }
      }
    }
  },
  sampleSet: 'column',
  advanced: {
    name: 'Columnrange + label'
  },
  dataValidator: false,
  advancedParent: 'Column',
  defaultDataType: 'columnrange'
};

export default ColumnrangeWithLabels;
