import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUnpublishModalAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import CopyInput from 'shared/inputs/CopyInput';
import EmbedOptions from '../EmbedOptions';
import PrivatePassword from '../PrivatePassword';
import withExportDimensions, { ExportDimensionsProps, WrapperProps } from './withExportDimensions';

const getShareLink = (inPackagesMode: boolean, uuid: any) => {
  return `https://api.everviz.com/share/${inPackagesMode ? 'package/' : ''}${uuid}`;
};

const OnlineChannel = (props: WrapperProps & ExportDimensionsProps) => {
  const { onPublish } = props;
  const dispatch = useDispatch();
  const { uuid, published, publishLoading, lastPublishTime, saved, type, inPackagesMode } = useSelector(
    (state: RootState) => state.projectConfig
  );

  const { packageProject } = useSelector((state: RootState) => state.packagesPage);
  const shareLinkValue = getShareLink(inPackagesMode, inPackagesMode ? packageProject.uuid : uuid);
  const onUnpublish = () => dispatch(toggleUnpublishModalAction(true));

  return (
    <>
      {/* {isLocationMap && <DimensionSection />} */}
      <p className="text-sm mb-4 text-black">Publish project to generate public link and embed codes for your CMS.</p>
      {onPublish && !!published && !inPackagesMode && (
        <PrimaryButton
          text="Unpublish"
          onClick={onUnpublish}
          buttonColor={ButtonColor.ErrorRed}
          buttonType={ButtonType.Info}
        />
      )}
      {onPublish && !published && !inPackagesMode && (
        <PrimaryButton
          text="Publish now"
          useLoader={{
            default: {
              text: 'Publish',
              icon: 'circle-arrow-up'
            },
            loading: {
              text: 'Publish',
              check: publishLoading
            }
          }}
          onClick={props.onPublish}
          buttonColor={ButtonColor.NavyBlue}
          buttonType={ButtonType.Info}
        />
      )}
      <div className={`mt-6 ${!published && !inPackagesMode ? 'opacity-60 pointer-events-none' : ''}`}>
        <p className="text-ev-navy-blue-2 font-bold">Public link:</p>
        <CopyInput value={published || inPackagesMode ? shareLinkValue : ''} tabIndex={saved ? 0 : -1} />

        <p className="text-ev-navy-blue-2 font-bold py-2">Embed to your page:</p>
        <EmbedOptions
          active={published || inPackagesMode}
          lastPublishTime={lastPublishTime ? String(lastPublishTime) : ''}
        />
        {type === 'chart' && !inPackagesMode && (
          <div className="mt-6">
            <PrivatePassword active={published} type={type} />
          </div>
        )}
      </div>
    </>
  );
};

export default withExportDimensions(OnlineChannel);
