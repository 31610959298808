import { getTeamThemeWithTeamidAndThemeid } from '../../../api/cloudApiGenerated';
import { getDefaultThemes } from 'shared/utils/themeHelper';
import { merge, uuid } from 'editor/core/highcharts-editor';
import { cloneDeep, isArray } from 'lodash';
import {
  createColorAxisDataClasses,
  createColorAxisStops,
  getMapValues
} from 'pages/CompanyThemeEditorPage/middleware/themeEditorHelper';
import { loadCSS } from 'pages/LayoutEditorPage/utils/storyHelper';

export const extractColorList = (data) => {
  const colors = [];
  if (Array.isArray(data)) {
    for (const item of data) {
      // Check if the item is an array with at least 2 elements or an object with a "color" property
      if (Array.isArray(item) && item.length >= 2) {
        colors.push(item[1]);
      } else if (typeof item === 'object' && item.color) {
        colors.push(item.color);
      }
    }
  }
  return colors;
};

export const getThemeColors = (themeDetails) => {
  if (themeDetails.colorAxis) {
    const dataClasses = themeDetails.colorAxis?.dataClasses || themeDetails.colorAxis[0]?.dataClasses;
    const stops = themeDetails.colorAxis?.stops || themeDetails.colorAxis[0]?.stops;
    if (dataClasses) {
      return extractColorList(dataClasses);
    } else if (stops && stops.length > 0) {
      return extractColorList(stops);
    }
  } else if (themeDetails.colors) {
    return themeDetails.colors;
  }
};

export const updateColorAxis = (mapColorAxis, config, themeDetails) => {
  const themeColors = getThemeColors(themeDetails);
  themeDetails.colors = themeColors;

  if (mapColorAxis?.dataClasses) {
    const values = getMapValues(config.seriesAssigns, config.dataOptions);
    values.shift();
    themeDetails.colorAxis = createColorAxisDataClasses(themeColors, values);
  } else if (mapColorAxis?.stops) {
    themeDetails.colorAxis = createColorAxisStops(themeColors);
  } else {
    delete themeDetails.colorAxis;
    return;
  }
};

export const getThemeDetails = (teamId, themeId, provider) =>
  new Promise((resolve) => {
    const defaultThemes = getDefaultThemes('chart', provider);
    const isDefaultTheme = defaultThemes.filter((element) => element.id === themeId);
    if (isDefaultTheme.length > 0) {
      return resolve({
        id: isDefaultTheme[0].id,
        name: isDefaultTheme[0].name,
        options: isDefaultTheme[0].template,
        customCode: (isDefaultTheme[0].customCode ?? []).join(''),
        plugins: isDefaultTheme[0].plugins
      });
    } else {
      return getTeamThemeWithTeamidAndThemeid(teamId, themeId).then((actualTheme) => {
        let unpacked = JSON.parse(actualTheme.data);

        if (unpacked.template && Array.isArray(unpacked.template)) {
          unpacked.templateOptions = {}; // Could cause problems for other charts but should really be done this way

          unpacked.template.forEach((template) => {
            unpacked.templateOptions = merge(unpacked.templateOptions, template || {});
          });

          unpacked.options = merge(unpacked.templateOptions, unpacked.options);
        } else unpacked.options = merge(unpacked.template || {}, unpacked.options); //Legacy

        let stockConstr = false;

        if (unpacked && unpacked.settings && unpacked.settings.constructor && isArray(unpacked.settings.constructor)) {
          if (
            unpacked.settings.constructor.some(function (a) {
              return a === 'StockChart';
            })
          ) {
            stockConstr = true;
          }
        }

        if (unpacked.theme && unpacked.theme.options) {
          unpacked.options = merge(merge({}, unpacked.theme.options), unpacked.options);
        }

        return resolve({
          id: actualTheme.chart_id,
          name: actualTheme.name,
          options: unpacked.options,
          customCode: unpacked.customCode,
          plugins: unpacked.settings ? unpacked.settings.plugins : null,
          stockConstr
        });
      });
    }
  });

export function processThemeDetails(themeDetails, isMap, config, provider) {
  let themeMeta = {};
  let themeOptions = {};

  const isLocationMap = provider === 'locationMap';
  const isHCMap = isMap && !isLocationMap;

  if (Object.keys(themeDetails).length === 0) {
    return { themeMeta, themeOptions };
  }

  if (themeDetails?.options && (themeDetails.id || themeDetails.isevervizTheme)) {
    // Assume that this uses the new format
    themeMeta = {
      id: themeDetails.id || uuid(),
      name: themeDetails.name || themeDetails.id,
      plugins: themeDetails.plugins,
      isevervizTheme: themeDetails.isevervizTheme
    };

    if (isHCMap) {
      if (themeDetails.options.series && isArray(themeDetails.options.series)) {
        themeDetails.options.series.forEach(function (serie) {
          serie.data = [];
        });
      }
      if (themeDetails.options.chart && themeDetails.options.chart.map) {
        delete themeDetails.options.chart.map;
      }

      const isEvervizTheme = themeDetails.isevervizTheme === true || themeDetails.name === 'everviz';

      if (!isEvervizTheme) {
        const mapColorAxis = cloneDeep(config.aggregatedOptions?.colorAxis);
        updateColorAxis(mapColorAxis, config, themeDetails.options);
      }
    }

    if (!isMap && themeDetails.options.colorAxis) {
      delete themeDetails.options.colorAxis;
    }

    themeOptions = merge({}, themeDetails.options);

    if (isHCMap) {
      if (!themeOptions.chart || (themeOptions.chart && !themeOptions.chart.type)) {
        if (themeOptions.series && isArray(themeOptions.series) && themeOptions.series.length > 0) {
          const allEqual = (arr) => arr.every((v) => v.type === arr[0].type);
          const series = themeOptions.series;
          if (allEqual(series)) {
            if (!themeOptions.chart) themeOptions.chart = {};
            themeOptions.chart.type = series[0].type;
          }
        }
      }
    }
  } else {
    themeMeta = {
      id: uuid(),
      name: 'Untitled Theme'
    };

    if (isMap) {
      if (themeDetails.series && isArray(themeDetails.series)) {
        themeDetails.series.forEach(function (serie) {
          serie.data = [];
        });
      }
    }

    themeOptions = merge({}, themeDetails);
  }

  return { themeMeta, themeOptions };
}

export function handleCssModules(themeDetails, plugins, cssModules) {
  if (themeDetails?.plugins?.cssModules) {
    themeDetails.plugins.cssModules.forEach((module) => {
      loadCSS(module, true);

      if (!cssModules.includes(module)) {
        cssModules.push(module);
      }
    });
  }

  if (cssModules.length) {
    plugins.cssModules = cssModules;
  }
}

export function handleCustomizedOptions(customizedOptions, provider) {
  const isLocationMap = provider === 'locationMap';

  let newCustomizedOptions = isLocationMap
    ? cloneDeep(customizedOptions)
    : {
        series: cloneDeep(customizedOptions.series || []),
        data: cloneDeep(customizedOptions.data || {}),
        everviz: cloneDeep(customizedOptions.everviz || {})
      };

  (newCustomizedOptions?.series ?? []).forEach((series) => {
    if (series.yAxis) delete series.yAxis;
  });

  if (customizedOptions?.chart?.map) {
    if (!newCustomizedOptions.chart) newCustomizedOptions.chart = {};
    newCustomizedOptions.chart.map = customizedOptions.chart.map;
  }

  return newCustomizedOptions;
}

export function updateHighchartsOptions(themeOptions) {
  if (themeOptions?.lang) {
    Highcharts.setOptions({
      lang: themeOptions.lang
    });
  }
}
