import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getMenuItems } from 'shared/utils/menuItemsHelper';
import useToggle from 'shared/utils/useToggle';
import MenuIcon from 'static/icons/bars.svg';
import XIcon from 'static/icons/xmark-large-solid.svg';
import Logo from 'static/images/logo.everviz.svg';
import NavigationTab from './NavigationTab';

export default function ProfileNavigation() {
  const [toggled, changeToggled] = useToggle();
  const profile = useSelector((state: RootState) => state.profile);
  const menuItems = getMenuItems(profile);

  const toggleMenu = () => changeToggled(!toggled);

  const iconClasses = 'w-6 h-6 text-gray-100';
  const width = toggled ? 'w-80' : '';

  return (
    <nav className="profile-nav ev-sm-max:hidden">
      <div
        className={`profile-nav-bar fixed top-0 left-0 h-full w-14 z-[2000] bg-ev-navy-blue transition-all ${width}`}
      >
        <div className={`flex items-center h-16 transition-all ${width}`}>
          <div
            className="content-centered text-white w-14 h-16 text-2xl cursor-pointer hover:text-gray-100 hover:transition hover:transform"
            onClick={toggleMenu}
          >
            {toggled ? <XIcon className={iconClasses} /> : <MenuIcon className={iconClasses} />}
          </div>
          {toggled && <Logo className="text-ev-light-purple mb-2 ml-8" />}
        </div>
        <NavigationTab changeToggled={changeToggled} toggled={toggled} tabs={menuItems} />
      </div>
    </nav>
  );
}
