import classNames from 'classnames';
import React from 'react';

export default function InlineOptionsContainer({
  children,
  version = 'default'
}: {
  children: React.ReactNode;
  version?: 'default' | 'transparent';
}) {
  const classes = classNames('w-full h-[46px] border-none mb-[5px] relative ', {
    'bg-white shadow-ev-standard': version === 'default'
  });
  return <div className={classes}>{children}</div>;
}
