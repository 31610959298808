import { cloneDeep, get, isArray, isFunction, isNil, isObject } from 'lodash';
import { merge } from '../../../editor/core/highcharts-editor';

import { updateCustomizedAction } from '../../../pages/ChartEditorPage/actions/chartEditor';
import { updateLocationMapCustomizedAction } from '../../../pages/ChartEditorPage/actions/locationMap';
import { updateCustomizedPropAction } from '../../../pages/LayoutEditorPage/actions/layoutEditor';

const createSeriesOptions = (chart, aggregatedOptions, index) => {
  return {
    color:
      chart?.series?.[index]?.color ||
      chart?.colors?.[index] ||
      aggregatedOptions?.series?.[index]?.color ||
      aggregatedOptions?.colors?.[index],
    label: chart?.series?.[index]?.name || aggregatedOptions?.series?.[index]?.name, // series[optionsTitle]
    value: index
  };
};

export const getSeries = (option, aggregatedOptions, chart) => {
  const controlledBy = option.controlledBy;
  const controlOptions = get(aggregatedOptions, controlledBy.options);

  let opt = controlOptions.map((_, i) => {
    return createSeriesOptions(chart, aggregatedOptions, i);
  });

  if (controlledBy.preValues) {
    opt.unshift(...controlledBy.preValues);
  }

  return opt;
};

export const extractOptionalArrayVal = (options, property) => {
  const isArray = (property?.rootPath ?? '').includes('[');
  let val = get(options, property.id ?? '');
  if (isNil(val)) {
    if (isArray) {
      val = get(options, property.id?.replace('[0]', '') ?? '');
    } else {
      val = get(options, property.id?.replace(property?.rootPath ?? '', `${property.rootPath}[0]`) ?? '');
    }
  }
  return val;
};

const compareReferToValue = (aggregatedOptions, props) => {
  return Object.keys(props).every((prop) => {
    const propVal = get(aggregatedOptions, prop);
    if (isArray(propVal) && isArray(props[prop]) && propVal.length === props[prop].length) return true;
    else if (propVal === props[prop]) return true;

    return false;
  });
};

export const extractRefersToVal = (chart, aggregatedOptions, group) => {
  const types = [
    ...new Set([
      chart?.userOptions && chart.userOptions.chart ? chart.userOptions.chart.type : 'line',
      ...((chart?.userOptions &&
        chart.userOptions.series &&
        chart.userOptions.series.map((serie) => serie.type || 'line')) ||
        [])
    ])
  ];

  let type = 'all';
  let val;

  types.some((chartType) => {
    if (group.refersTo[chartType]) {
      type = chartType;
      return true;
    }
  });

  Object.keys(group.refersTo[type]).some((key) => {
    const referToOption = group.refersTo[type][key];
    let props = referToOption.set;

    if (referToOption.get) props = referToOption.get;
    const isOptionAnArray = isArray(props);
    let isThisVal = false;

    if (isOptionAnArray) isThisVal = props.some((condition) => compareReferToValue(aggregatedOptions, condition));
    else isThisVal = compareReferToValue(aggregatedOptions, props);

    if (isThisVal) {
      val = key;
    }
    return isThisVal;
  });

  return val;
};

export const handleRefersToChange = ({ option, chart, val, update, projectType }) => {
  const types = [
    ...new Set([
      chart.userOptions && chart.userOptions.chart ? chart.userOptions.chart.type : 'line',
      ...((chart.userOptions &&
        chart.userOptions.series &&
        chart.userOptions.series.map((serie) => serie.type || 'line')) ||
        [])
    ])
  ];

  let type = 'all';

  types.some((chartType) => {
    if (option.refersTo[chartType]) {
      type = chartType;
      return true;
    }
  });

  const refersTo = option.refersTo[type][val];
  if (refersTo) {
    Object.keys(refersTo.set).forEach((id) => {
      const propVal = get(chart.options, id);
      let newVal = refersTo.set[id];
      if (isArray(propVal) && isObject(propVal[0])) {
        // Used for second yAxis
        newVal.forEach((v, index) => (v = merge(v, propVal[index] || {})));
      }
      update(projectType, id, newVal);
    });
  }
};

export const getIndexFromPath = (path) => {
  let index = path.split('[');
  return parseInt(index[index.length - 1], 10);
};

export const getFilteredValue = (option, chart, index) => {
  if (option.filteredBy && isFunction(option.filteredBy)) {
    return option.filteredBy(option, chart, index);
  } else if (option.filteredBy) return option.filteredBy;

  return option.defaultFilterValue;
};

export const getNestedValue = (_, aggregatedOptions, property) => {
  let val = get(aggregatedOptions, `${property.id}`);
  // eslint-disable-next-line array-callback-return
  if (isNil(val)) {
    property.nestedValue.some((nestedValue) => {
      val = get(aggregatedOptions, nestedValue);
      if (!isNil(val)) return true;
    });
  }
  return val;
};

const extractTableValue = (options, chart, aggregatedOptions) => {
  return {
    ...(get(aggregatedOptions, options.id.replace('.text', '')) ?? {})
  };
};

const extractChartValue = (options, chart) => {
  let index = null;
  if (options.richtext?.isArray) index = 0;

  const isPropArray = isArray(options.inline?.highchartsId);
  // if (options.default) settings = options.default;
  if (isPropArray) {
    // For style/title that are in different places. (credits)
    // index 0 = where style can be found in the chart object.
    // index 1 = where text can be found in the chart object.
    return {
      style: {
        ...get(chart, options.inline?.highchartsId[0], index)
      },
      text: get(chart, options.inline?.highchartsId[1], index)
    };
  }

  return { ...get(chart, options.inline?.highchartsId, index), chart: null };
};

const getTooltipValue = (chart, aggregatedOptions, index = 0) => {
  if (chart && chart.series && chart?.series?.[index]?.tooltipOptions) {
    const tooltipOptions = chart.series[index].tooltipOptions;
    return { pointFormat: tooltipOptions.pointFormat ?? '', headerFormat: tooltipOptions.headerFormat ?? '' };
  }
  if (aggregatedOptions?.tooltip) {
    const tooltipOptions = aggregatedOptions?.tooltip;
    return { pointFormat: tooltipOptions.pointFormat ?? '', headerFormat: tooltipOptions.headerFormat ?? '' };
  }
  return { pointFormat: '', headerFormat: '' };
};

const extractValueMap = {
  highcharts: {
    table: extractTableValue,
    layout: extractTableValue,
    chart: extractChartValue
  },
  locationMap: {
    chart: extractTableValue
  }
};

const extractSectionValueMap = {
  tooltip: getTooltipValue
};

export const extractRichtextValue = (provider, type, options, chart, aggregatedOptions, index) => {
  let settings = {
    text: '',
    style: {}
  };

  if (!options) return settings;

  settings = extractValueMap[provider][type](options, chart, aggregatedOptions) ?? {};
  const extractText = extractSectionValueMap[options.richtext?.rawId ?? ''];

  if (extractText) settings = { ...settings, ...extractText(chart, aggregatedOptions, index) };
  if (!settings.style) settings.style = {};
  settings = cloneDeep(settings);

  delete settings.style.width;
  delete settings.style.height;
  return settings ?? {};
};

export const getParsedNumberInputValue = (value, suffix) => (suffix ? parseInt(value, 10) : value);

export const checkIfNumberInputValueValidToChange = (value, isMinusSymbol, isMinValueValid, minValue) => {
  let isValid = true;

  // Number validation, allows: numbers | negative numbers | decimal numbers (dot)
  if (!/^[-]?\d*\.?\d*$/.test(value)) {
    isValid = false;
  }

  // Prevent saving a minus symbol when the min value is queal or below zero
  if (isMinusSymbol && isMinValueValid && minValue <= 0) {
    isValid = false;
  }

  return isValid;
};

export const checkIfNumberInputValueValidToFormat = (value, isMinusSymbol) => {
  let isValid = true;

  // Prevent saving a minus sign
  if (isMinusSymbol) {
    isValid = false;
  }

  // Prevent saving a dot as a last character
  if (value.slice(-1) === '.') {
    isValid = false;
  }

  // Prevent saving 0.0 value to allow passing e.g. 0.05 to match number with two decimals
  if (value === '0.0' || value === '-0') {
    isValid = false;
  }

  return isValid;
};

export const getFormattedNumberInputValue = (value, initialValue) => {
  const parsedValue = parseFloat(value);
  const formattedInitialValue = typeof initialValue !== 'undefined' ? initialValue : '';
  return !Number.isNaN(parsedValue) ? Number(parsedValue.toFixed(2)) : formattedInitialValue;
};

export const updateCustomized = {
  locationMap: {
    chart: updateLocationMapCustomizedAction
  },
  highcharts: {
    chart: updateCustomizedAction,
    table: updateCustomizedPropAction,
    layout: updateCustomizedPropAction
  }
};

export const getUpdateCustomizeAction = (provider, type) => {
  return updateCustomized[provider][type];
};
