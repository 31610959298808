import { TemplateProps } from '../../types';

const StackedColumnPercent: TemplateProps = {
  title: 'Stacked column percent',
  description: '',
  constructor: '',
  thumbnail: 'ojixow.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column'
    },
    plotOptions: {
      series: {
        stacking: 'percent'
      }
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'column',
  dataValidator: false,
  advancedParent: 'Stacked column',
  advanced: {
    name: 'Percent'
  }
};

export default StackedColumnPercent;
