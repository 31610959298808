import DOMPurify from 'dompurify';
import { marked } from 'marked';
import * as React from 'react';
import Tooltip from 'shared/tooltip/Tooltip';

type LabelWithDescriptionProps = {
  label: string;
  description: string;
};

const LabelWithDescription = (props: LabelWithDescriptionProps) => {
  const markdown = (content: string) => ({ __html: DOMPurify.sanitize(marked.parse(content)) });
  const content = <div dangerouslySetInnerHTML={markdown(props.description)} />;

  return (
    <div>
      {props.label}
      <Tooltip tippyProps={{ content, allowHTML: true, placement: 'right' }}>
        <i className="fa-solid fa-circle-question ml-1 hover:text-ev-baby-blue-2" />
      </Tooltip>
    </div>
  );
};

export default LabelWithDescription;
