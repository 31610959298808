import Themes from 'shared/editor/generic/Themes';
import Customize from '../containers/Customize';
import PublishExport from 'shared/editor/generic/PublishExport';

export default [
  {
    id: 'themes',
    name: 'Themes',
    element: Themes
  },
  {
    id: 'customize',
    name: 'Customize',
    element: Customize
  },
  {
    id: 'publish',
    name: 'Publish & Export',
    element: PublishExport,
    permission: 'publish'
  }
];
