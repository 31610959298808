export default {
  series: 1,
  data: 'Series 1 (from);Series 1 (to);Series 1 (weight)\nA;D;94\nB;D;3\nC;D;0\nA;E;41\nB;E;40\nC;E;7\nA;F;17\nB;F;76\nC;F;1\nA;G;1\nB;G;18\nC;G;48',
  seriesMapping: [
    {
      from: 0,
      to: 1,
      weight: 2,
    },
  ],
  assignDataFields: [
    {
      from: 'A',
      to: 'B',
      weight: 'C',
    },
  ],
};
