import React from 'react';

export default function AdvancedTemplateSelector(props) {
  const { advancedTemplates, selectedOption, toggleAdvanced, selectTemplate, hasAdvanced, advancedActive } = props;

  let dropdownClass = !hasAdvanced ? 'empty' : '';

  if (advancedActive) {
    dropdownClass += ' bg-ev-navy-blue-2 rounded-b-none';
  }

  return (
    <div className={`advanced-template-dropdown ${dropdownClass}`}>
      <div className="advanced-template-dropdown-container" onClick={toggleAdvanced}>
        <div className={`float-left font-bold pl-6 ${advancedActive ? 'text-white' : ''}`}>Style</div>
        <div className="float-right">
          {hasAdvanced && <i className={`fa fa-chevron-${advancedActive ? 'up text-white' : 'down'} pl-2 pr-6`} />}
        </div>
        <div
          className={`float-right font-bold template-type ${!hasAdvanced ? 'pr-4' : ''} ${
            advancedActive ? 'text-ev-baby-blue-2' : ''
          }`}
        >
          {selectedOption.advanced ? selectedOption.advanced.name : selectedOption.title}
        </div>
      </div>

      {advancedActive && (
        <>
          <div className="dimmer" onClick={toggleAdvanced} />
          <div className="px-2 pb-6 bg-ev-navy-blue-2 rounded rounded-t-none">
            <div className="relative w-full bg-ev-navy-blue-2 text-white advanced-templates-container pt-2 dark">
              <div className="advanced-templates flex-wrap pl-4 pr-2">
                {Object.values(advancedTemplates).map((template, i) => {
                  return (
                    <div
                      onClick={() => selectTemplate(template, false)}
                      className={'advanced-template ' + (selectedOption.title === template.title ? 'active' : '')}
                      key={`advanced-template-${i}`}
                    >
                      <img
                        src={`${window.hcconfig.frontend.hostname}/static/thumbnails/${template.thumbnail}`}
                        className="mx-auto"
                      />
                      <div className="font-medium text-ev-navy-blue pt-2 text-center">
                        {template.advanced ? template.advanced.name : template.title}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
