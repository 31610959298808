import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../../shared/loader/Loading';
import ErrorMessage from '../../shared/message/ErrorMessage';

import { postAccountVerifyUsingToken } from '../../api/cloudApiGenerated';

class CallbackPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      error: '',
      verified: false,
    };
  }

  componentDidMount() {
    const token = this.props.match.params.token;

    postAccountVerifyUsingToken(token)
      .then((data) => {
        /*
       this.props.history.replace({
         pathname: this.props.paths.profileCharts
       });
       */
        this.setState({ verified: true });
      })
      .catch((data) => {
        this.setState({
          error: data.message,
        });
      });
  }

  render() {
    const invalidToken = this.state.error && this.state.error.length > 0;
    const message = this.state.error;

    return (
      <div className="col-xs-12 verify-page">
        {!invalidToken && !this.state.verified && <Loading text="Verifying account" />}
        {invalidToken && <ErrorMessage error={message} dismissable={false} />}

        {this.state.verified && (
          <div className="verify-account dark-layout">
            <div className="center account-verified">
              <h2>
                {' '}
                <i className="fa fa-check-circle" /> Your account has been verified
              </h2>
              <p>
                Congratulations! Your account has now been verified.
                {this.props.user === undefined && (
                  <span>
                    {' '}
                    Please <a href={this.props.paths.login}> sign in</a> to continue.
                  </span>
                )}
                {this.props.user !== undefined && (
                  <span>
                    {' '}
                    Continue to <a href={this.props.paths.profileCharts}>profile page.</a>
                  </span>
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CallbackPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  paths: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default CallbackPage;
