import paths from 'paths';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import CloseIcon from 'static/icons/close.svg';
import SuccessIcon from 'static/icons/success.svg';
import { useDispatch } from 'react-redux';
import { setPlansPageAction } from '../actions/plansPage';

type SuccessScreenProps = {
  onClose: () => void;
  successScreenText: string;
};

const SuccessScreen = (props: SuccessScreenProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(
        setPlansPageAction({
          successScreenText: ''
        })
      );
    };
  }, []);

  return (
    <div className="ev-md:max-w-[60%] m-auto relative p-4 bg-ev-grey shadow-ev-standard flex flex-col items-center">
      <SuccessIcon width={64} height={64} className="mt-8" />
      <p className="text-2xl text-ev-navy-blue text-center my-6 font-medium leading-normal">
        {props.successScreenText}
      </p>
      <NavLink to={paths.profileCharts} className="btn bulk-action-btn w-fit bg-ev-red">
        Continue to your projects
      </NavLink>
      <SvgIconButton
        Icon={CloseIcon}
        buttonColor={ButtonColor.TransparentBordered}
        width={16}
        height={16}
        onClick={props.onClose}
        buttonClasses="absolute top-4 right-6 rounded-full p-[10px]"
      />
    </div>
  );
};

export default SuccessScreen;
