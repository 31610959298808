import { TemplateProps } from '../../types';

const SpiderLine: TemplateProps = {
  title: 'Spider line',
  description: '',
  constructor: '',
  thumbnail: 'uqonaj.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'line',
      polar: true
    },
    xAxis: {
      tickmarkPlacement: 'on',
      lineWidth: 0
    },
    yAxis: {
      lineWidth: 0,
      gridLineInterpolation: 'polygon'
    }
  },
  sampleSet: 'polar',
  dataValidator: false,
  advancedParent: 'Spider'
};

export default SpiderLine;
