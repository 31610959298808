import { TemplateProps } from '../../types';

const NegativeColor: TemplateProps = {
  title: 'Negative color',
  description: '',
  constructor: '',
  thumbnail: 'ydypal.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        negativeColor: '#FF0000'
      }
    }
  },
  sampleSet: 'area',
  advancedParent: 'Area',
  dataValidator: false,
  defaultDataType: 'negative',
  thumbnail_url: 'https://app.everviz.com/static/thumbnails/ydypal.svg',
  name: 'Negative color'
};

export default NegativeColor;
