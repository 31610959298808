import React from 'react';

export default (props) => {
  const { value: inputValue } = props;

  const onChange = (e) => {
    props.onChange({ val: e.target.checked });
  };

  const value = inputValue ?? '';

  return (
    <div className="toggle-switch-container">
      <label className="toggle-switch-widget">
        <input type="checkbox" checked={value} onChange={onChange} />
        <span className="slider round" />
      </label>
    </div>
  );
};
