/* eslint-disable @typescript-eslint/no-explicit-any */
import { SIDEBAR_HEADERS, SIDEBAR_NAMES } from 'meta/sidebarConstants';
import QuickExport from 'pages/ProjectsPage/components/QuickExport';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import RecentExportList from 'shared/exports/RecentExportList';
import { setAction } from '../../pages/ChartEditorPage/actions/chartEditor';
import { setAction as setLayoutAction } from '../../pages/LayoutEditorPage/actions/layoutEditor';
import AnnouncementsList from './AnnouncementsList';
import { useHistory } from 'react-router-dom';
import { isInEditor } from 'shared/nav/TopBar';

type SidebarManagerProps = {
  activeSidebar: string;
  setActiveSidebar: any;
  data: any;
  onClose: any;
};

const SidebarManager = ({ activeSidebar, setActiveSidebar, data, onClose }: SidebarManagerProps) => {
  const { chartId } = useSelector((state: RootState) => state.chartEditorPage);
  const { storyId } = useSelector((state: RootState) => state.layoutEditorPage);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const inEditor = isInEditor(pathname);

  const handleCloseSidebar = () => {
    dispatch(setAction({ chartId: null, chartVersion: null }));
    dispatch(setLayoutAction({ storyId: null, chartVersion: null }));
    onClose();
  };

  useEffect(() => {
    if (inEditor) handleCloseSidebar();
  }, [inEditor]);

  useEffect(() => {
    if ((chartId || storyId) && !inEditor) {
      setActiveSidebar(SIDEBAR_NAMES.QUICK_EXPORT);
    }
  }, [chartId, storyId]);

  const sidebarComponents = {
    [SIDEBAR_NAMES.QUICK_EXPORT]: (
      <QuickExport
        isOpen={activeSidebar === SIDEBAR_NAMES.QUICK_EXPORT}
        onClose={handleCloseSidebar}
        header={SIDEBAR_HEADERS.QUICK_EXPORT}
      />
    ),
    [SIDEBAR_NAMES.RECENT_EXPORT]: (
      <RecentExportList
        isOpen={activeSidebar === SIDEBAR_NAMES.RECENT_EXPORT}
        onClose={handleCloseSidebar}
        exports={data}
        header={SIDEBAR_HEADERS.RECENT_EXPORT}
      />
    ),
    [SIDEBAR_NAMES.ANNOUNCEMENT]: (
      <AnnouncementsList
        isOpen={activeSidebar === SIDEBAR_NAMES.ANNOUNCEMENT}
        onClose={handleCloseSidebar}
        data={data}
        header={SIDEBAR_HEADERS.ANNOUNCEMENT}
      />
    )
  };

  return <div>{sidebarComponents[activeSidebar]}</div>;
};

export default SidebarManager;
