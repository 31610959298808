import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  getCMSLicenseAction,
  setAction,
  getTeamProfileAction,
  setUserSettingsAction,
  setTeamSettingsAction,
  generateLicenseAction,
  deleteTeamAction,
  verifyEmailAction
} from './actions/settingsPage';
import { checkPermission, onLoggedIn as reloadTeams } from 'utils/profileHelper';
import { InputChangeParams } from 'shared/types/commonPropTypes';
import TeamSettingsForm from './components/TeamSettingsForm';
import CMSSettingsForm from './components/CMSSettingsForm';
import UserSettingsForm from './components/UserSettingsForm';
import ErrorMessage from '../../shared/message/ErrorMessage';
import TeamModal from '../../shared/team/TeamModal';
import Modal from 'shared/modal/Modal';
import InputWidget from 'shared/widgets/InputWidget';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import Subheader from 'shared/profile/Subheader';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const {
    cmsLicenses,
    deleting,
    form,
    generatingLicense,
    saving,
    sending,
    showDeleteError,
    showDeleteModal,
    showNewTeamModal,
    teamSettings,
    userSettings
  } = useSelector((state: RootState) => state.settingsPage);
  const { user, team } = useSelector((state: RootState) => state.profile);

  const updateState = ({ val }: InputChangeParams) => {
    const field = 'teamName';
    const updatedForm = { ...form };
    updatedForm[field].value = val;
    updatedForm[field].error = false;
    dispatch(setAction({ form: updatedForm }));
  };

  const openNewTeamModal = () => {
    dispatch(setAction({ showNewTeamModal: true }));
  };

  const closeNewTeamModal = () => {
    dispatch(setAction({ showNewTeamModal: false }));
  };

  useEffect(() => {
    if (checkPermission('team_management')) {
      loadTeamSettings();
      dispatch(getCMSLicenseAction({ team }));
    }

    dispatch(
      setAction({
        userSettings: {
          username: user.username,
          email_verified: user.email_verified
        }
      })
    );
  }, [user, team]);

  const loadTeamSettings = () => {
    dispatch(getTeamProfileAction({ team }));
  };

  const updateUserSettings = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSettings(event, 'userSettings');
  };

  const updateTeamSettings = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSettings(event, 'teamSettings');
  };

  const updateSettings = (event: React.ChangeEvent<HTMLInputElement>, formType: string) => {
    const settings = formType === 'userSettings' ? { ...userSettings } : { ...teamSettings };
    settings[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    dispatch(setAction({ [formType]: settings }));
  };

  const handleSaveUserSettings = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(setUserSettingsAction({ userSettings }));
  };

  const handleSaveTeamSettings = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(setTeamSettingsAction({ team, teamSettings }));
  };

  const handleGenerateLicense = () => {
    dispatch(generateLicenseAction({ team }));
  };

  const handleDeleteTeam = () => {
    dispatch(deleteTeamAction({ team, form }));
  };

  const handleVerifyEmail = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(verifyEmailAction());
  };

  const toggleDeleteModal = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    event?.preventDefault();
    dispatch(setAction({ showDeleteModal: !showDeleteModal }));
  };

  const parentSelector = () => document.querySelector('.page-container');
  return (
    <ProfilePageContainer
      title="Settings"
      header="Settings"
      description={
        <>
          Use this page to change information about your account. You can also create a new team and link your account
          to Wordpress using our official plugin
        </>
      }
    >
      <TeamModal
        isOpen={showNewTeamModal}
        parentSelector={parentSelector}
        close={closeNewTeamModal}
        reloadTeams={reloadTeams}
      />

      <Modal
        title="Delete team"
        width="w-5/12 max-w-xl"
        bg="bg-ev-yellow-1"
        isOpen={showDeleteModal}
        onClose={toggleDeleteModal}
      >
        <ErrorMessage error={showDeleteError} />
        <InputWidget
          label="Please type in the name of the team to delete."
          value={form.teamName.value}
          onChange={updateState}
          placeholder={team?.name ?? 'Enter Team Name'}
        />
        <hr />
        <div className="pt-6 flex gap-2">
          <PrimaryButton onClick={handleDeleteTeam} text="Confirm" />
          <PrimaryButton onClick={toggleDeleteModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
      </Modal>

      <ProfilePageSectionContainer>
        {/* <ErrorMessage error={error} /> */}
        {userSettings && (
          <div>
            <Subheader>User settings</Subheader>
            <UserSettingsForm
              settings={userSettings}
              onChange={(e) => updateUserSettings(e)}
              onSave={handleSaveUserSettings}
              saving={saving}
              sending={sending}
              onClick={handleVerifyEmail}
            />
          </div>
        )}
      </ProfilePageSectionContainer>

      <ProfilePageSectionContainer>
        {checkPermission('team_management') && (
          <div>
            <Subheader>Team settings</Subheader>
            <TeamSettingsForm
              settings={teamSettings}
              onChange={(e) => updateTeamSettings(e)}
              onSave={handleSaveTeamSettings}
              onDelete={toggleDeleteModal}
              deleting={deleting}
              saving={saving}
              checkPermission={checkPermission}
              team={team}
              openNewTeamModal={openNewTeamModal}
            />
          </div>
        )}
      </ProfilePageSectionContainer>

      <ProfilePageSectionContainer>
        {checkPermission('team_management') && (
          <div>
            <Subheader>CMS settings</Subheader>
            <CMSSettingsForm
              generateLicense={handleGenerateLicense}
              generatingLicense={generatingLicense}
              checkPermission={checkPermission}
              cmsLicenses={cmsLicenses}
            />
          </div>
        )}
      </ProfilePageSectionContainer>
    </ProfilePageContainer>
  );
};

export default SettingsPage;
