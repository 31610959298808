import { merge } from 'editor/core/highcharts-editor';
import { Chart } from 'highcharts';
import { cloneDeep } from 'lodash';
import { defaultMapOptions } from 'pages/ChartEditorPage/meta/DefaultOptions';
import { parseCSV } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import { createHCAggregatedOptions } from 'pages/ChartEditorPage/utils/chartEditorHelper';
import { getMapValues, parseThemeOptions } from 'pages/CompanyThemeEditorPage/middleware/themeEditorHelper';
import { getAllInCatToArray } from '../../../editor/core/highed.templateman';
import defaultData from '../../ChartEditorPage/meta/data/DefaultData';

export const chartTypes = ['line', 'area', 'bar', 'column', 'pie', 'wordcloud', 'sankey', 'item', 'funnel'];
export const mapTypes = ['choropleth', 'categoriesMap', 'bubbleMap', 'tilemapCircle', 'honeycomb', 'pointMap'];

export const createPreviewCharts = (themeOptions: any) => {
  chartTypes.forEach((type) => {
    const data: any = defaultData[type as keyof typeof defaultData];
    const options: any = merge(cloneDeep(themeOptions ?? {}), cloneDeep({ data: { csv: data.data } }), null, {});

    if (!options.chart) options.chart = {};
    options.chart.type = type;

    options.data.seriesMapping = data?.seriesMapping ?? [];
    options.series = [];

    for (let i = 0; i < data.series; i++) {
      options.series.push({ data: [] });

      if (!data.seriesMapping) {
        options.data.seriesMapping.push({
          x: 0,
          y: i + 1
        });
      }
    }

    new (Highcharts as any).Chart(`highcharts-${type}`, options, function (chart: Chart) {
      chart.reflow();
    });
  });
};

export const createPreviewMaps = (themeOptions: any, rawOptions: any, seriesAssigns: any) => {
  const maps = getAllInCatToArray('Map');

  mapTypes.forEach((type) => {
    const data: any = defaultData[type as keyof typeof defaultData];
    const template = (maps || []).filter((templateType: any) => templateType.defaultDataType === type);
    const parsedData: any = parseCSV(data.data, null, null);
    const values = getMapValues(seriesAssigns, parsedData);
    values.shift();

    const theme = parseThemeOptions(
      cloneDeep(themeOptions) ?? {},
      cloneDeep(rawOptions ?? {}),
      cloneDeep(template[0].config),
      values
    );
    delete theme.data;

    const { aggregatedOptions }: { aggregatedOptions: any } = createHCAggregatedOptions({
      customizedOptions: cloneDeep({ data: { csv: data.data } }),
      templateOptions: [merge(cloneDeep(template[0].config), data.customizedOptions ?? {}, null, {})],
      themeOptions: merge(theme, defaultMapOptions, null, {}),
      pluginConfig: {}
    });

    const seriesOptions = data.seriesOptions;
    aggregatedOptions.data.seriesMapping = data?.seriesMapping ?? [];
    aggregatedOptions.series = (seriesOptions ?? []).map((_: any, index: number) =>
      cloneDeep(seriesOptions?.[index] ?? {})
    );
    new (Highcharts as any).mapChart(`highcharts-${type}`, aggregatedOptions, function (chart: Chart) {
      chart.reflow();
    });
  });
};

export const disableStockTools = () => {
  (Highcharts as any).setOptions({
    stockTools: {
      gui: {
        enabled: false
      }
    }
  });
};
