import { connect } from 'react-redux';
import TableChart from '../../shared/widgets/TableChart';
import {
  addChartToTableAction,
  updateColumnHiddenValueAction,
  updateCustomizedArrayPropAction
} from '../../pages/TableEditorPage/actions/tableEditor.js';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  addChartToTable: (index) => dispatch(addChartToTableAction({ index })),
  updateColumnHiddenValue: (columns, value, index) =>
    dispatch(updateColumnHiddenValueAction({ columns, value, index })),
  updateCustomizedArrayProp: (parent, index, val, moveObj) =>
    dispatch(updateCustomizedArrayPropAction({ parent, index, val, moveObj }))
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TableChart);
