import React, { useEffect, useState } from 'react';
import { ExportHistoryType } from 'shared/types/exportHistoryType';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import { snackBar } from 'editor/editors/highed.init';
import { getRecentExportAction } from 'redux/actions/profile';

type ExportProps = {
  toggleShowRecentExportModal: () => void;
  setShowExportNotification: (a: boolean) => void;
  showExportNotification: boolean;
};

export default function Export({
  toggleShowRecentExportModal,
  setShowExportNotification,
  showExportNotification
}: ExportProps) {
  const { recentExports, previousExports } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();
  const location = useLocation();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const filteredExports = previousExports.filter((exportItem: ExportHistoryType) => {
    const lastExportTime = new Date(exportItem.last_export_time).getTime();
    return exportItem.exportStatus === 'PENDING' && lastExportTime > Date.now() - 10 * 60 * 1000;
  });

  const isExportComplete = filteredExports.some((previousExport: ExportHistoryType) => {
    return recentExports.some((recentExport: ExportHistoryType) => {
      return recentExport.exportId === previousExport.exportId && recentExport.exportStatus !== 'PENDING';
    });
  });

  useEffect(() => {
    if (isExportComplete) {
      setShowSnackbar(true);
      setShowExportNotification(true);
      const timeoutId = setTimeout(() => {
        setShowSnackbar(false);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [isExportComplete]);

  useEffect(() => {
    const isPendingAndRecent = (exportItem: ExportHistoryType) =>
      exportItem.exportStatus === 'PENDING' &&
      new Date(exportItem.last_export_time).getTime() > Date.now() - 5 * 60 * 1000;

    const hasPendingExport = recentExports.some(isPendingAndRecent);

    if (hasPendingExport) {
      const intervalId = setInterval(() => {
        dispatch(getRecentExportAction());
      }, 20000);
      return () => clearInterval(intervalId);
    }
  }, [location.pathname, recentExports]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getRecentExportAction());
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const recentExportClickHandler = () => {
    setShowExportNotification(false);
    setShowExportNotification(false);
    toggleShowRecentExportModal();
  };

  return (
    <>
      <li className="group li-btn hidden-sm">
        <a
          className="btn highed-template-tooltip"
          onClick={() => {
            recentExportClickHandler();
          }}
        >
          <span>
            <i className="text-ev-navy-blue-2 group-hover:text-white fa fa-download no-margin" />
            {showExportNotification && (
              <div className="absolute top-2 right-2 rounded-full flex justify-center items-center w-4 h-4 bg-ev-red">
                <span className="text-white text-2xs font-bold">1</span>
              </div>
            )}
          </span>
          <span className="highed-tooltip-text">Exports</span>
        </a>
      </li>
      {showSnackbar && snackBar('Your video export finished successfully!')}
    </>
  );
}
