import { cloneDeep } from 'lodash';
import { resetStateAction as resetChartState } from 'pages/ChartEditorPage/actions/chartEditor';
import EditorPublish from 'pages/ChartEditorPage/components/EditorPublish';
import { getFontsAction, resetStateAction } from 'pages/LayoutEditorPage/actions/layoutEditor';
import { resetTableStateAction } from 'pages/TableEditorPage/actions/tableEditor';
import RichTextEditorToolbar from 'pages/TableEditorPage/containers/RichTextEditorToolbar';
import paths from 'paths';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetProjectConfigAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import TextButton from 'shared/buttons/TextButton';
import GlobalModal from 'shared/wizard/editor/GlobalModals';
import { useIsMobile } from '../../shared/utils/mediaHelper';
import {
  initCompanyThemeEditorAction,
  resetThemePageSettingsAction,
  saveCompanyThemeAction,
  setAction
} from './actions/ThemeEditorPage';
import LeftContainer from './components/LeftContainer';
import Preview from './components/Preview';
import RightContainer from './components/RightContainer';

type CompanyThemeEditorProps = {
  match: {
    params: {
      themeid?: string;
    };
  };
};

export default function ThemeEditor(props: CompanyThemeEditorProps) {
  const { match } = props;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const history = useHistory();
  const { name } = useSelector((state: RootState) => state.companyThemeEditorPage);
  const { team } = useSelector((state: RootState) => state.profile);
  const { aggregatedOptions, cssModules } = useSelector((state: RootState) => state.projectConfig);

  const saveAndExit = () => {
    dispatch(
      saveCompanyThemeAction({
        team,
        history,
        cb: () => {
          dispatch(setAction({ view: 'Charts' }));
        }
      })
    );
  };

  useEffect(() => {
    if (team) dispatch(getFontsAction({ team }));
    dispatch(initCompanyThemeEditorAction({ urlParams: props.match.params }));

    return () => {
      dispatch(resetProjectConfigAction());
      dispatch(resetThemePageSettingsAction());
      dispatch(resetStateAction({}));
      dispatch(resetTableStateAction());
      dispatch(resetChartState());
    };
  }, []);

  const editChartSpecificTheme = () => {
    const agg: any = cloneDeep(aggregatedOptions ?? {});
    if (agg.data) delete agg.data;
    if (agg.navigation) delete agg.navigation;

    const editLink = paths.newTheme;
    const options = {
      pathname: editLink,
      state: {
        defaultThemeDetails: {
          id: 'master-theme',
          name: 'Company theme',
          options: agg,
          plugins: {
            cssModules
          }
        }
      }
    };

    history.push(options);
  };

  return (
    <>
      {isMobile && <EditorPublish onSave={saveAndExit} />}
      <div className="grid gap-x-4 px-8 grid-cols-4 md:grid-cols-8 md:gap-x-6 md:px-20 lg:grid-cols-12 lg:px-20 h-[calc(100vh-146px)] overflow-auto">
        <Helmet>
          <title> everviz </title>
        </Helmet>

        <div className="col-span-4 mt-16 md:col-span-8 lg:col-span-12">
          <h1 className="text-ev-navy-blue-2 font-bold">
            {match?.params?.themeid ? `Editing ${name}` : 'Create a new company theme'}
          </h1>
        </div>
        <div className="col-span-4 mt-6 md:col-span-8 lg:col-span-12">
          <span className="text-ev-navy-blue">
            Define default fonts, colors and styles to apply the main design features of your brand to all project
            types.
            <br />
            For more specific template settings,&nbsp;
            <TextButton text="create a chart specific theme." onClick={editChartSpecificTheme} />
          </span>
        </div>

        <div className="col-span-4 mt-10 md:col-span-4 lg:col-span-4 text-left">
          <LeftContainer />
        </div>
        <div className="col-span-4 mt-10 md:col-span-4 lg:col-span-8">
          <RichTextEditorToolbar className="hidden" />
          <RightContainer>
            <div>
              <Preview />
            </div>
          </RightContainer>
        </div>

        <GlobalModal />
      </div>
    </>
  );
}
