import { TemplateTypeProps } from '../../types';
import Column from './Column';
import Column3D from './Column3D';
import Columnrange from './Columnrange';
import ColumnrangeWithLabels from './ColumnrangeWithLabels';
import ColumnWithLabel from './ColumnWithLabel';
import ErrorBar from './ErrorBar';
import Logarithmic from './Logarithmic';
import Multicolor from './Multicolor';
import NegativeColor from './NegativeColor';
import PackedColumns from './PackedColumns';

const ColumnTemplate: TemplateTypeProps = {
  templates: {
    Column,
    'Column 3D': Column3D,
    Columnrange,
    'Columnrange with labels': ColumnrangeWithLabels,
    'Error bar': ErrorBar,
    'With label': ColumnWithLabel,
    Logarithmic,
    'Multi color': Multicolor,
    'Negative color': NegativeColor,
    'Packed columns': PackedColumns
  },
  description: [],
  samples: []
};

export default ColumnTemplate;
