import { put, takeLatest, all, call } from 'redux-saga/effects';
import { setAction, getTeamWebhooksAction } from '../actions/teamWebhooksPage';
import actionTypes from '../../../redux/actions/action-types';

import {
  getWebhooks,
  getTeamWebhooksWithTeamid,
  delTeamWebhookUsingTeamidAndHookid,
  postTeamWebhooksUsingTeamid,
  postTeamWebhookUsingTeamidAndHookid,
} from '../../../api/cloudApiGenerated';

export function* getTeamWebhooks(params) {
  try {
    let data = yield call(getTeamWebhooksWithTeamid, params.data.id, 1, 100);
    yield put(
      setAction({
        webhooks: data.data,
      })
    );
  } catch (e) {
    yield put(
      setAction({
        errorMessage: e.response.message.join(' '),
      })
    );
  }
}

export function* setTeamWebhook(params) {
  let { team, form } = params.data;

  try {
    let data = yield call(postTeamWebhooksUsingTeamid, team.id, form);
    yield put(
      setAction({
        form: {
          title: '',
          url: '',
          type: -1,
          error: '',
        },
        modalIsOpen: false,
      })
    );

    yield put(
      getTeamWebhooksAction({
        id: team.id,
      })
    );
  } catch (error) {
    form.error = error.message;
    yield put(
      setAction({
        form,
      })
    );
  }
}

export function* getSupportedWebhooks() {
  let data = yield call(getWebhooks, 1, 100);
  yield put(
    setAction({
      supported: data.data,
    })
  );
}

export function* updateWebhook(params) {
  let { team, row } = params.data;

  try {
    let data = yield call(postTeamWebhookUsingTeamidAndHookid, team.id, row.id, row);
    yield put(
      getTeamWebhooksAction({
        id: team.id,
      })
    );
  } catch (error) {}
}

export function* deleteTeamWebhooks(params) {
  let { webhooks, team } = params.data;
  let promises = [];

  webhooks.forEach((webhook) => {
    if (webhook) {
      let webhookId = webhook.id;
      promises.push(call(delTeamWebhookUsingTeamidAndHookid, team.id, webhook.id));
    }
  });

  try {
    let results = yield all(promises);
    yield put(
      getTeamWebhooksAction({
        id: team.id,
      })
    );
  } catch (e) {}
}

/** Watch functions */
export function* watchGetSupportedWebhooks() {
  yield takeLatest(actionTypes.teamWebhooksPage.getSupportedWebhooks, getSupportedWebhooks);
}

export function* watchGetWebhooks() {
  yield takeLatest(actionTypes.teamWebhooksPage.getWebhooks, getTeamWebhooks);
}

export function* watchSetWebhooks() {
  yield takeLatest(actionTypes.teamWebhooksPage.setWebhook, setTeamWebhook);
}

export function* watchDeleteWebhooks() {
  yield takeLatest(actionTypes.teamWebhooksPage.deleteWebhooks, deleteTeamWebhooks);
}

export function* watchUpdateWebhook() {
  yield takeLatest(actionTypes.teamWebhooksPage.updateWebhook, updateWebhook);
}

export default function* rootSaga() {
  yield all([
    watchGetWebhooks(),
    watchGetSupportedWebhooks(),
    watchSetWebhooks(),
    watchDeleteWebhooks(),
    watchUpdateWebhook(),
  ]);
}
