import { getAggregatedOptions } from 'pages/TableEditorPage/selectors/tableEditor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentExportAction } from 'redux/actions/profile';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { Dimensions } from 'shared/editor/generic/ResolutionSection';
import { downloadChartImage, downloadLocationMapImage, downloadTableImage } from 'shared/editor/publishHelper';
import CustomSelect, { SelectItem } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import { ImageOption, imageOptions } from 'shared/wizard/meta/ImageOptions';
import { LocationMapExportMappingOptions, getLocationMapPropsForExporting } from '../../utils/locationMapMapper';
import ExportDimensions from './ExportDimensions';
import withExportDimensions, { ExportDimensionsProps } from './withExportDimensions';

const ImageChannel = (props: ExportDimensionsProps) => {
  const dispatch = useDispatch();
  const { setResolution, dimensions, showPaidPlanModal, showSubscriptionLock } = props;
  const projectConfig = useSelector((state: RootState) => state.projectConfig);
  const { dataOptions, cssModules, uuid, type, provider, aggregatedOptions, projectName } = projectConfig;

  const [imageOption, setImageOption] = useState(imageOptions);
  const { team, user } = useSelector((state: RootState) => state.profile);
  const { constr, chartId } = useSelector((state: RootState) => state.chartEditorPage);

  const [activeImageType, setActiveImageType] = React.useState<SelectItem>(imageOption[0].item);
  const { storyId } = useSelector((state: RootState) => state.layoutEditorPage);
  const [callRecentExport, shouldCallRecentExport] = useState(false);

  const handleDimensionsChange = () => {
    const newDimension = imageOptions.reduce((filtered, value) => {
      if (value.id === activeImageType.label) filtered = value.dimensions;
      return filtered;
    }, {} as Dimensions);
    setResolution(newDimension);
  };

  useEffect(() => {
    if (type === 'chart') setImageOption(imageOptions.filter((opt) => opt.provider.includes(provider)));
  }, [provider]);

  useEffect(() => {
    if (type === 'table' || type === 'layout') {
      setImageOption(imageOption.filter((opt) => opt.id === ImageOption.PNG));
    }
  }, [type]);

  useEffect(() => {
    handleDimensionsChange();
  }, [activeImageType]);

  useEffect(() => {
    dispatch(getRecentExportAction());
    shouldCallRecentExport(false);
  }, [callRecentExport]);

  const downloadImage = () => {
    const teamId = team?.id;
    if (type === 'chart') {
      switch (provider) {
        case 'highcharts':
          return downloadChartImage({
            constr,
            aggregatedOptions,
            type: activeImageType.value,
            exportedBy: user.id,
            uuid,
            teamId: team?.id,
            chartId,
            projectName,
            shouldCallRecentExport
          });
        case 'locationMap': {
          const mappingOptions: LocationMapExportMappingOptions = {
            resolution: {
              type: 'manual',
              width: dimensions.width ?? 1200,
              height: dimensions.height ?? 800
            },
            disableControls: true
          };
          const options = getLocationMapPropsForExporting(aggregatedOptions, mappingOptions);
          return downloadLocationMapImage(activeImageType.value, options, uuid, cssModules, teamId);
        }
      }
    } else {
      const tableAggregatedOptions = getAggregatedOptions(projectConfig);
      downloadTableImage({
        aggregatedOptions: tableAggregatedOptions,
        type,
        exportedBy: user.id,
        cssModules,
        dataOptions,
        uuid,
        teamId,
        tableId: storyId,
        projectName,
        shouldCallRecentExport
      });
    }
  };

  return (
    <div className="flex flex-col">
      <p className="text-sm mb-4 text-black">Select your preferred format to export your project as.</p>
      <CustomSelect
        label="Format"
        options={Object.values(imageOption).map((opt) => opt.item)}
        onChange={(selected) => setActiveImageType(selected as SelectItem)}
        styles={editorStyles}
        value={activeImageType}
      />
      <ExportDimensions dimensions={dimensions} setResolution={setResolution} />
      <PrimaryButton
        text="Export"
        onClick={showSubscriptionLock ? showPaidPlanModal : downloadImage}
        className="content-centered mt-4 px-6 h-10 text-base self-end"
        showSubscriptionLock={showSubscriptionLock}
      />
    </div>
  );
};

export default withExportDimensions(ImageChannel);
