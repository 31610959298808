import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocationMapCustomizedAction } from '../../../../pages/ChartEditorPage/actions/locationMap';
import { ProjectConfigLocationMapProps } from '../../../../pages/Editor/reducers/locationMapConfigTypes';
import { getProjectConfig } from '../../../../redux/selectors/projectConfig';
import InputWidget from '../../../widgets/InputWidget';
import TextWidget from '../../../widgets/TextWidget';
import { SectionNode } from '../SectionNode';

export type TextSectionProps = {
  isCompact?: boolean;
  openedByDefault: boolean;
  showContainer?: boolean;
};

export const TextSection = ({ isCompact, showContainer, openedByDefault }: TextSectionProps) => {
  const { aggregatedOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);
  const dispatch = useDispatch();

  const setAction = (path: string, text: string) => {
    dispatch(
      updateLocationMapCustomizedAction({
        optionProps: path,
        val: text
      })
    );
  };

  return (
    <SectionNode showContainer={showContainer} sectionKey="text" headerText="Text" openedByDefault={openedByDefault}>
      <TextWidget
        value={aggregatedOptions.title.text}
        onChange={(e) => setAction('title.text', e.val as string)}
        parentPath=""
        label={'Title'}
      />
      <TextWidget
        value={aggregatedOptions.subtitle.text}
        onChange={(e) => setAction('subtitle.text', e.val as string)}
        parentPath=""
        label={'Subtitle'}
      />
      <TextWidget
        value={aggregatedOptions.caption.text}
        onChange={(e) => setAction('caption.text', e.val as string)}
        parentPath=""
        label={'Caption'}
      />
      {!isCompact && (
        <InputWidget
          value={aggregatedOptions.customAttribution.text}
          onChange={(e) => setAction('customAttribution.text', e.val as string)}
          label={'Attribution'}
        />
      )}
    </SectionNode>
  );
};
