import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StarIcon from 'static/icons/star.svg';
import { openPaidPlanModalAction, openPremiumFeatureModalAction } from '../../../../redux/actions/app';
import { RootState } from '../../../../redux/store';
import { useEffect, useState } from 'react';
import { getAvailableChannelsByPaidFeature, getAvailableChannelsBySubscription } from '../../utils/channelHelper';
import { ChannelOptionsType } from '../../meta/ChannelOptions';

type LockedChannelResult = {
  isLocked: boolean;
  lockedBehind?: 'PLAN' | 'FEATURE';
};

type ChannelTileProps = {
  handleClick: () => void;
  isActive: boolean;
  channel: ChannelOptionsType;
};

const ChannelTile = (props: ChannelTileProps) => {
  const dispatch = useDispatch();
  const { subscription } = useSelector((state: RootState) => state.profile);
  const [channelAccessState, setChannelAccessState] = useState<LockedChannelResult>({ isLocked: false });
  const { inPackagesMode } = useSelector((state: RootState) => state.projectConfig);

  useEffect(() => {
    if (inPackagesMode) return;

    if (props.channel.lockedBehind === 'FEATURE') {
      if (!getAvailableChannelsByPaidFeature(subscription?.team_features).includes(props.channel.id)) {
        setChannelAccessState({ isLocked: true, lockedBehind: 'FEATURE' });
      } else setChannelAccessState({ isLocked: false });
    } else if (props.channel.lockedBehind === 'PLAN') {
      if (!getAvailableChannelsBySubscription(subscription?.id).includes(props.channel.id)) {
        setChannelAccessState({ isLocked: true, lockedBehind: 'PLAN' });
      } else setChannelAccessState({ isLocked: false });
    }
  }, [subscription, props.channel.id]);

  const onClick = () => {
    if (!channelAccessState.isLocked) props.handleClick();
    if (channelAccessState.lockedBehind === 'FEATURE') {
      return dispatch(openPremiumFeatureModalAction({ premiumFeatureName: props.channel.text }));
    } else if (channelAccessState.lockedBehind === 'PLAN') {
      return dispatch(openPaidPlanModalAction({ paidPlanType: 'higher' }));
    }
  };

  return (
    <div
      className={`${
        props.isActive ? 'bg-ev-navy-blue-2 text-white' : 'bg-white text-ev-navy-blue-2'
      } relative flex flex-col items-center shadow-ev-standard w-30 h-24 py-5 rounded transition-colors hover:bg-ev-baby-blue-2 hover:border-ev-baby-blue-2 hover:text-ev-navy-blue-2 active:bg-ev-navy-blue-2 active:border-ev-navy-blue-2 active:text-white focus:shadow-light cursor-pointer`}
      tabIndex={0}
      onClick={onClick}
    >
      <props.channel.icon className="w-8 h-8" />
      <p className="text-sm mt-2 mb-0 font-medium">{props.channel.text}</p>
      {channelAccessState.isLocked && (
        <div className="absolute bottom-[-8px] right-[-8px] bg-ev-navy-blue rounded-full p-1">
          <StarIcon width={17} height={16} />
        </div>
      )}
    </div>
  );
};

export default ChannelTile;
