export const projectsPlaceholders: Record<number, { icon: string; name: string }> = {
  1: {
    icon: '/static/images/donut.svg',
    name: 'chart'
  },
  2: {
    icon: '/static/images/donut.svg',
    name: 'chart'
  },
  3: {
    icon: '/static/images/map.svg',
    name: 'map'
  },
  4: {
    icon: '/static/images/layouts/smallmultiple.png',
    name: 'layout'
  },
  5: {
    icon: '/static/images/table.png',
    name: 'table'
  },
  6: {
    icon: '/static/images/map.svg',
    name: 'location map'
  }
};
