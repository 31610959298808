import DefaultData from './data/DefaultData';
import { LocationMapCustomizedOptions } from '../../Editor/reducers/locationMapConfigTypes';
import { credits } from 'shared/meta/constants';

export const defaultChartOptions = {
  loading: {
    labelStyle: {
      fontStyle: 'bold'
    }
  },
  plotOptions: {
    series: {
      states: {
        select: {
          color: '#EFFFEF',
          borderColor: 'black',
          dashStyle: 'solid'
        }
      },
      label: {
        enabled: false
      }
    }
  },
  credits,
  lang: {
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E']
  },
  series: [
    {
      data: [],
      test: 1
    }
  ],
  data: {
    csv: DefaultData.line.data,
    seriesMapping: DefaultData.line.seriesMapping
  },
  exporting: {
    useMultiLevelHeaders: false
  }
};

export const defaultMapOptions = {
  chart: {
    map: 'countries/us/us-all'
  },
  plotOptions: {
    series: {
      allowPointSelect: true //Maps
    }
  },
  data: {
    csv: DefaultData.choropleth.data,
    seriesMapping: DefaultData.choropleth.seriesMapping
  },
  exporting: {
    useMultiLevelHeaders: false
  }
};

export const defaultLocationMapCustomOptions: LocationMapCustomizedOptions = {
  version: '0'
};
