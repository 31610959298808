import actionTypes from '../../../redux/actions/action-types';

export const setAction = (data) => ({
  type: actionTypes.tableEditor.set,
  data
});
export const setDataAction = (data) => ({
  type: actionTypes.tableEditor.setData,
  data
});
export const filterDataAction = (data) => ({
  type: actionTypes.tableEditor.filterData,
  data
});
export const saveTableAction = (data) => ({
  type: actionTypes.tableEditor.saveTable,
  data
});
export const loadTableAction = (data) => ({
  type: actionTypes.tableEditor.loadTable,
  data
});
export const changeTitleTextAction = (data) => ({
  type: actionTypes.tableEditor.changeTitleText,
  data
});
export const updateCustomizedPropAction = (data) => ({
  type: actionTypes.tableEditor.updateCustomizedProp,
  data
});
export const updateDataPropAction = (data) => ({
  type: actionTypes.tableEditor.updateDataProp,
  data
});
export const publishTableAction = (data) => ({
  type: actionTypes.tableEditor.publishTable,
  data
});
export const assignThemeAction = (data) => ({
  type: actionTypes.tableEditor.assignTheme,
  data
});
export const loadPaginatedAction = (data) => ({
  type: actionTypes.tableEditor.loadPaginated,
  data
});
export const goToNextPageAction = (data) => ({
  type: actionTypes.tableEditor.goToNextPage,
  data
});
export const goToPreviousPageAction = (data) => ({
  type: actionTypes.tableEditor.goToPreviousPage,
  data
});
export const searchAction = (data) => ({
  type: actionTypes.tableEditor.search,
  data
});
export const deleteAssignRawOptionAction = (data) => ({
  type: actionTypes.tableEditor.deleteAssignRawOption,
  data
});
export const addAssignRawOptionAction = (data) => ({
  type: actionTypes.tableEditor.addAssignRawOption,
  data
});
export const updateAssignRawOptionAction = (data) => ({
  type: actionTypes.tableEditor.updateAssignRawOption,
  data
});
export const updateDataAction = (data) => ({
  type: actionTypes.tableEditor.updateData,
  data
});
export const changeColumnOrderAction = (data) => ({
  type: actionTypes.tableEditor.changeColumnOrder,
  data
});
export const processConditionalFormattingAction = (data) => ({
  type: actionTypes.tableEditor.processConditionalFormatting,
  data
});
export const changeRowOrderAction = (data) => ({
  type: actionTypes.tableEditor.changeRowOrder,
  data
});
export const deleteRowAction = (data) => ({
  type: actionTypes.tableEditor.deleteRow,
  data
});
export const deleteColumnAction = (data) => ({
  type: actionTypes.tableEditor.deleteColumn,
  data
});
export const addChartToTableAction = (data) => ({
  type: actionTypes.tableEditor.addChartToTable,
  data
});
export const updateCustomizedArrayPropAction = (data) => ({
  type: actionTypes.tableEditor.updateCustomizedArrayProp,
  data
});

export const updateChartHeaderAction = (data) => ({
  type: actionTypes.tableEditor.updateChartHeader,
  data
});
export const updateColumnHiddenValueAction = (data) => ({
  type: actionTypes.tableEditor.updateColumnHiddenValue,
  data
});

export const addRowAction = (data) => ({
  type: actionTypes.tableEditor.addRow,
  data
});
export const addColumnAction = (data) => ({
  type: actionTypes.tableEditor.addColumn,
  data
});
export const resetTableStateAction = (data) => ({
  type: actionTypes.tableEditor.resetTableState,
  data
});
export const setCellSettingAction = (data) => ({
  type: actionTypes.tableEditor.setCellSetting,
  data
});
export const mergeCellsAction = (data) => ({
  type: actionTypes.tableEditor.mergeCells,
  data
});
export const setDataStyleAction = (data) => ({
  type: actionTypes.tableEditor.setDataStyle,
  data
});
export const toggleDataStyleAction = (data) => ({
  type: actionTypes.tableEditor.toggleDataStyle,
  data
});
export const removeDataStyleAction = (data) => ({
  type: actionTypes.tableEditor.removeDataStyle,
  data
});
export const setBordersAllEdgesAction = (data) => ({
  type: actionTypes.tableEditor.setBordersAllEdges,
  data
});
export const deleteBulkCellValuesAction = (data) => ({
  type: actionTypes.tableEditor.deleteBulkCellValues,
  data
});
export const setBorderStyleAction = (data) => ({
  type: actionTypes.tableEditor.setBorderStyle,
  data
});
export const openExportModalAction = (data) => ({
  type: actionTypes.tableEditor.openExportModal,
  data
});
export const closeExportModalAction = (data) => ({
  type: actionTypes.tableEditor.closeExportModal,
  data
});
export const resetDataAction = (data) => ({
  type: actionTypes.tableEditor.resetData,
  data
});
export const showContextMenuAction = (data) => ({
  type: actionTypes.tableEditor.showContextMenu,
  data
});
export const hideContextMenuAction = (data) => ({
  type: actionTypes.tableEditor.hideContextMenu,
  data
});

export const redrawTableAction = (data) => ({
  type: actionTypes.tableEditor.redrawTable,
  data
});

export const initEditorAction = (data) => ({
  type: actionTypes.tableEditor.initEditor,
  data
});

export const editDataGridAction = (data) => ({
  type: actionTypes.tableEditor.editDataGrid,
  data
});
