import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';

import Picker from '@emoji-mart/react';
import icons from 'shared/meta/widgets/icons';
import { getRichtextBoxPosition } from './utils/richtextModalHelper';

const custom = [
  {
    id: 'icons',
    name: 'Icons',
    emojis: icons
  }
];

const EmojiModal = () => {
  const projectConfig = useSelector<any, any>((state: RootState) => state.projectConfig);
  const { emojiOptions } = projectConfig;

  const dispatch = useDispatch();
  const [position, setPosition] = useState({
    top: '200px',
    left: '200px'
  });

  const set = (params: any) => dispatch(setAction(params));

  useEffect(() => {
    if (emojiOptions) {
      const container = document.querySelector('.emoji-picker-modal');
      setPosition(getRichtextBoxPosition(emojiOptions.box, container));
    }
  }, [emojiOptions]);

  const onClose = () => {
    set({
      emojiOptions: false
    });
  };

  const onSelect = () => {
    onClose();
  };

  return (
    <>
      {emojiOptions && (
        <div className="absolute emoji-picker-modal" style={position}>
          <Picker
            custom={custom}
            categories={['icons']}
            previewPosition="none"
            skinTonePosition="none"
            dynamicWidth={true}
            onEmojiSelect={onSelect}
          />
        </div>
      )}
    </>
  );
};

export default EmojiModal;
