export default {
  chart: null,
  teamId: null,
  teamName: null,
  twitterUrl: null,
  facebookUrl: null,
  hasAccess: false,
  isPublished: false,
  loading: false,
  error: false
};
