import React, { useState, useEffect, RefObject, forwardRef } from 'react';
import classNames from 'classnames';
import Tooltip from 'shared/tooltip/Tooltip';
import { GenericInputProps, InputValue } from 'shared/types/commonPropTypes';

type InputProps = {
  placeholder?: string;
  ref?: RefObject<HTMLDivElement>;
  name?: string;
};

const InputWidget = forwardRef<HTMLInputElement, GenericInputProps & InputProps>((props, ref) => {
  const { option, isArraySelector, index, value, label, type, name, placeholder, className, onChange, onKeyDown } =
    props;
  const [inputValue, setInputValue] = useState<InputValue>(value);
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setInputValue(val);

    if (option && option.timeout) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        onChange({ val });
      }, option.timeout);
    } else if (isArraySelector) onChange({ val, index });
    else onChange({ val });
  };

  const isDisable = option?.shouldBeDisabled && option.shouldBeDisabled(option);

  const widgetClasses = classNames(className, {
    'mt-2 w-full': label,
    'opacity-60': isDisable
  });

  const inputClasses = classNames('highed-field-input placeholder:font-bold', option?.inputClassName, className, {
    'pointer-events-none': isDisable
  });

  const numberWidget = (
    <div className={widgetClasses} id={option?.id}>
      {option?.image && <img src={`https://app.everviz.com/static/editor/${option?.image}`} />}
      {label && <div className="text-ev-navy-blue-4 font-bold text-sm mb-2">{label}</div>}
      <input
        ref={ref}
        className={inputClasses}
        value={(inputValue ?? '') as string}
        onChange={(e) => handleChange(e)}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        disabled={isDisable}
        type={type}
        name={name}
      />
    </div>
  );

  if (isDisable) {
    return <Tooltip tippyProps={{ content: option.disabledText, placement: 'bottom' }}>{numberWidget}</Tooltip>;
  }
  return numberWidget;
});

export default InputWidget;
