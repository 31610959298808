import React from 'react';

export default function PlaceholderCard() {
  return (
    <div className="project-card">
      <div className="flex flex-col relative justify-between animate-pulse">
        <div className="!bg-gray-200 mb-2 z-10 rounded-md relative h-40" />
        <div className="!bg-gray-200 mb-8 z-10 rounded-md h-6" />
        <span className="!bg-gray-200 mb-4 z-10 rounded-full h-6 w-12" />
        <div className="!bg-gray-200 mb-4 z-10 rounded-md h-6" />
      </div>
    </div>
  );
}
