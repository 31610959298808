import { stockData } from './helper';

export default {
  series: 1,
  data: stockData,
  seriesMapping: [
    {
      x: 0,
      open: 1,
      high: 2,
      low: 3,
      close: 4
    }
  ],
  assignDataFields: [
    {
      labels: 'A',
      open: 'B',
      high: 'C',
      low: 'D',
      close: 'E'
    }
  ]
};
