import classnames from 'classnames';
import React, { ChangeEvent, useRef } from 'react';
import SearchIcon from 'static/icons/magnifying_glass.svg';
import TextInput from './TextInput';

type InputSearchProps = {
  label?: string;
  value: string;
  reset: () => void;
  onChange: (value: string) => void;
  placeholder: string;
  simple?: any;
  labelClass?: string;
  extraClass?: string;
  inputClassName?: string;
  className?: string;
  onClick?: () => void;
};

const InputSearch = (props: InputSearchProps) => {
  const { label, placeholder, value, reset, onChange, onClick } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onClick) {
        onClick();
      }
    }
  };

  const handleClick = () => {
    inputRef.current?.focus();
    if (onClick) {
      onClick();
    }
  };

  const containerClasses = classnames('space-x-4', 'flex', 'flex-grow', {
    'justify-center rounded-md shadow-inner': props.simple
  });

  return (
    <div className={containerClasses}>
      <div className={`flex self-center ${props.className}`}>
        <TextInput
          ref={inputRef}
          name="search"
          label={label}
          value={value}
          reset={reset}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          labelClass={`font-bold text-base text-ev-navy-blue ${props.labelClass}`}
          extraClass={`px-0 min-w-0 mb-0 ${props.extraClass}`}
          inputClassName={`rounded-none rounded-l-md h-10 highed-field-input font-medium hover:bg-ev-baby-blue-2 hover:border-ev-baby-blue-2 focus:border-none  hover:border-b cursor-pointer ring-ev-dark-purple border-b border-solid border-black border-opacity-10  ${props.inputClassName}`}
        />
        <button
          type="submit"
          className="bulk-action-btn p-0 rounded-l-none rounded-r-md min-w-0 ml-0 h-10 w-10 self-end"
          onClick={handleClick}
        >
          <SearchIcon className="mx-auto h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default InputSearch;
