import React from 'react';

export default function LayoutIcon(props) {
  return (
    <svg fill="none" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
      <line
        className="group-hover:stroke-[#fff] group-active:stroke-[#AEC8FA]"
        x1="37"
        x2="37"
        y1="2.3223"
        y2="72"
        stroke="#AEC8FA"
        strokeDasharray="5 3"
        strokeWidth="2"
      />
      <line
        className="group-hover:stroke-[#fff] group-active:stroke-[#AEC8FA]"
        x1="70.838"
        x2="1.1605"
        y1="37"
        y2="37"
        stroke="#AEC8FA"
        strokeDasharray="5 3"
        strokeWidth="2"
      />

      <path
        className="group-hover:fill-[#fff] group-active:fill-[#AEC8FA]"
        d="m11.612 9.29c0.6412 0 1.1613 0.54534 1.1613 1.2178v12.787c0 0.3349 0.2598 0.6089 0.5806 0.6089h14.516c0.6424 0 1.1613 0.5442 1.1613 1.2178s-0.5189 1.2177-1.1613 1.2177h-14.516c-1.6033 0-2.9032-1.3623-2.9032-3.0444v-12.787c0-0.67242 0.52-1.2178 1.1613-1.2178z"
        fill="#AEC8FA"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m19.741 21.469c0.6423 0 1.1613-0.5442 1.1613-1.2178v-6.0889c0-0.6736-0.519-1.2178-1.1613-1.2178-0.6424 0-1.1613 0.5442-1.1613 1.2178v6.0889c0 0.6736 0.5189 1.2178 1.1613 1.2178z"
        fill="#28277E"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m26.709 21.469c0.6423 0 1.1613-0.5442 1.1613-1.2178v-8.5244c0-0.6725-0.519-1.2178-1.1613-1.2178s-1.1613 0.5453-1.1613 1.2178v8.5244c0 0.6736 0.519 1.2178 1.1613 1.2178z"
        fill="#28277E"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m17.42 17.815c0-0.6735-0.5189-1.2177-1.1613-1.2177-0.6423 0-1.1612 0.5442-1.1612 1.2177v2.4356c0 0.6736 0.5189 1.2178 1.1612 1.2178 0.6424 0 1.1613-0.5442 1.1613-1.2178v-2.4356z"
        fill="#28277E"
      />

      <path
        className="group-active:fill-[#fff]"
        d="m24.388 16.598c0-0.6736-0.519-1.2178-1.1613-1.2178-0.6424 0-1.1613 0.5442-1.1613 1.2178v3.6533c0 0.6736 0.5189 1.2178 1.1613 1.2178 0.6423 0 1.1613-0.5442 1.1613-1.2178v-3.6533z"
        fill="#28277E"
      />
      <path
        className="group-hover:fill-[#fff] group-active:fill-[#AEC8FA]"
        d="m46.452 44.129c0.6413 0 1.1613 0.5453 1.1613 1.2178v12.787c0 0.3349 0.2598 0.6089 0.5806 0.6089h14.516c0.6423 0 1.1613 0.5442 1.1613 1.2177 0 0.6736-0.519 1.2178-1.1613 1.2178h-14.516c-1.6033 0-2.9032-1.3624-2.9032-3.0444v-12.787c0-0.6725 0.5201-1.2178 1.1613-1.2178z"
        fill="#AEC8FA"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m54.581 56.307c0.6424 0 1.1613-0.5442 1.1613-1.2178v-6.0889c0-0.6736-0.5189-1.2178-1.1613-1.2178-0.6423 0-1.1613 0.5442-1.1613 1.2178v6.0889c0 0.6736 0.519 1.2178 1.1613 1.2178z"
        fill="#28277E"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m61.549 56.307c0.6423 0 1.1612-0.5442 1.1612-1.2178v-8.5244c0-0.6725-0.5189-1.2178-1.1612-1.2178-0.6424 0-1.1613 0.5453-1.1613 1.2178v8.5244c0 0.6736 0.5189 1.2178 1.1613 1.2178z"
        fill="#28277E"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m52.258 52.654c0-0.6736-0.5189-1.2178-1.1613-1.2178-0.6423 0-1.1613 0.5442-1.1613 1.2178v2.4356c0 0.6736 0.519 1.2177 1.1613 1.2177 0.6424 0 1.1613-0.5441 1.1613-1.2177v-2.4356z"
        fill="#28277E"
      />
      <path
        className="group-active:fill-[#fff]"
        d="m59.226 51.436c0-0.6736-0.519-1.2177-1.1613-1.2177-0.6424 0-1.1613 0.5441-1.1613 1.2177v3.6534c0 0.6736 0.5189 1.2177 1.1613 1.2177 0.6423 0 1.1613-0.5441 1.1613-1.2177v-3.6534z"
        fill="#28277E"
      />

      <path
        className="group-active:fill-[#AEC8FA]"
        d="m12.096 49.506 0.7887 1.1818c0.3062 0.4618 0.7662 0.7963 1.2963 0.9491l2.1637 0.6254c0.6581 0.1782 1.0545 0.7491 1.0545 1.4v1.4509c0 0.4 0.2582 0.7636 0.5818 0.9091 0.3891 0.2109 0.5818 0.5745 0.5818 0.9745v1.4182c0 0.5673 0.5746 0.9745 1.12 0.8182 0.5891-0.1673 1.04-0.6655 1.1891-1.2255l0.1018-0.4072c0.1527-0.6146 0.5527-1.1419 1.1018-1.4582l0.2946-0.1673c0.5454-0.3091 0.88-0.8872 0.88-1.5163v-0.2982c0-0.4655-0.1855-0.9091-0.5128-1.2364l-0.1381-0.1382c-0.3273-0.3272-0.7746-0.5454-1.2364-0.5454h-1.6c-0.3709 0-0.7709-0.0727-1.1236-0.2727l-1.2546-0.72c-0.1563-0.0873-0.2763-0.2327-0.3309-0.4037-0.1163-0.349 0.04-0.7309 0.3673-0.8945l0.2182-0.1054c0.24-0.12 0.5164-0.1419 0.7418-0.0546l0.8764 0.28c0.2945 0.0982 0.6218-0.0145 0.7963-0.2727 0.1709-0.2582 0.1527-0.5964-0.0436-0.8327l-0.4946-0.5891c-0.3636-0.4364-0.36-1.0727 0.011-1.5018l0.5709-0.6684c0.32-0.3734 0.3709-0.9076 0.1272-1.3345l-0.4254-0.7426c3.0654 0.0153 5.7308 1.724 7.109 4.24l-1.4763 0.5891c-0.5709 0.2291-0.8655 0.8618-0.6728 1.4472l0.6328 1.8982c0.1163 0.3491 0.3891 0.6218 0.7381 0.7382l1.6691 0.5564c-0.6327 3.869-3.9927 6.789-8.0399 6.789-4.4982 0-8.1454-3.6145-8.1454-8.1454 0-0.9382 0.1702-1.8727 0.4822-2.7345z"
        fill="#28277E"
      />
      <path
        className="group-hover:fill-[#fff] group-active:fill-[#fff]"
        d="m20.222 44.87c0.2437 0.4269 0.1927 0.9614-0.1273 1.3345l-0.5709 0.6684c-0.3709 0.4291-0.3745 1.0654-0.0109 1.5018l0.4946 0.5891c0.1963 0.2363 0.2145 0.5745 0.0436 0.8327-0.1745 0.2582-0.5018 0.3709-0.7963 0.2727l-0.8764-0.28c-0.2255-0.0873-0.5018-0.0654-0.7418 0.0546l-0.2182 0.1054c-0.3273 0.1673-0.4836 0.5455-0.3673 0.8945 0.0546 0.171 0.1746 0.3164 0.3309 0.4037l1.2546 0.72c0.3527 0.2 0.7527 0.3054 1.1236 0.3054h1.6c0.4618 0 0.9091 0.1855 1.2363 0.5127l0.1382 0.1382c0.3273 0.3273 0.5128 0.7746 0.5128 1.2364v0.2982c0 0.6291-0.3346 1.2072-0.88 1.5163l-0.2946 0.1709c-0.5491 0.3127-0.9491 0.84-1.1018 1.4546l-0.1018 0.4072c-0.1491 0.56-0.6 1.0582-1.1891 1.2255-0.5454 0.1563-1.12-0.2509-1.12-0.8182v-1.4182c0-0.4-0.1927-0.7636-0.5818-0.9418-0.3236-0.1782-0.5818-0.5418-0.5818-0.9418v-1.4509c0-0.6509-0.3964-1.2218-1.0546-1.4l-2.1636-0.6254c-0.5302-0.1528-0.9901-0.4873-1.2963-0.9491l-0.7887-1.1818c1.1327-3.1367 4.1359-5.3782 7.6304-5.3782h0.0728l0.4254 0.7426zm6.6836 3.4974c0.6364 1.16 0.9964 2.4909 0.9964 3.9054 0 0.4509-0.0691 0.8909-0.1055 1.3237l-1.6691-0.5564c-0.3491-0.1164-0.6218-0.3891-0.7381-0.7382l-0.6328-1.8982c-0.1927-0.5818 0.1018-1.2181 0.6728-1.4472l1.4763-0.5891z"
        fill="#AEC8FA"
      />

      <rect
        className="group-hover:fill-[#fff]"
        x="45.289"
        y="11.612"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#AEC8FA"
      />
      <rect
        className="group-hover:fill-[#fff]"
        x="45.289"
        y="14.362"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#AEC8FA"
      />
      <rect
        className="group-active:fill-[#AEC8FA]"
        x="45.289"
        y="17.113"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#28277E"
      />
      <rect
        className="group-hover:fill-[#fff]"
        x="45.289"
        y="19.864"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#AEC8FA"
      />
      <rect
        className="group-active:fill-[#AEC8FA]"
        x="51.34"
        y="11.612"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#28277E"
      />
      <rect
        className="group-hover:fill-[#fff]"
        x="51.34"
        y="14.362"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#AEC8FA"
      />
      <rect
        className="group-hover:fill-[#fff]"
        x="51.34"
        y="17.113"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#AEC8FA"
      />
      <rect
        className="group-hover:fill-[#fff]"
        x="51.34"
        y="19.864"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#AEC8FA"
      />
      <rect
        className="group-hover:fill-[#fff]"
        x="57.393"
        y="11.612"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#AEC8FA"
      />
      <rect
        className="group-active:fill-[#AEC8FA]"
        x="57.393"
        y="14.362"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#28277E"
      />
      <rect
        className="group-hover:fill-[#fff]"
        x="57.393"
        y="17.113"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#AEC8FA"
      />
      <rect
        className="group-active:fill-[#AEC8FA]"
        x="57.393"
        y="19.864"
        width="5.5008"
        height="2.2003"
        rx="1.1002"
        fill="#28277E"
      />
      <rect
        className="group-active:stroke-[#AEC8FA]"
        x="1"
        y="1"
        width="70"
        height="70"
        stroke="#28277E"
        strokeWidth="2"
      />
    </svg>
  );
}
