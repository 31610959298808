import React from 'react';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import iconPicker from '../../editor/core-ui/fields/highed.iconpicker';
import FieldContainer from './FieldContainer';

export default (props: GenericInputProps) => {
  const { value, index } = props;

  const onClick = (e: React.MouseEvent) => {
    const { isArraySelector, index } = props;
    iconPicker(
      e.clientX,
      e.clientY,
      function (col: string) {
        if (isArraySelector) props.onChange({ val: 'symbol:' + col, index });
        else props.onChange({ val: 'symbol:' + col });
      },
      null
    );
  };

  const iconValue = unescape(((value as string) ?? '').replace('symbol:', '')) || '\uf111';

  return (
    <>
      <FieldContainer
        label={`Icon ${Number(index ?? 0) + 1}`}
        className={props.className}
        childrenClass="absolute right-2"
      >
        <IconButton
          icon={<div className="highed-icon-picker">{iconValue}</div>}
          onClick={onClick}
          className="rounded-sm ml-2 p-1 px-2"
          buttonColor={ButtonColor.Grey}
        />
      </FieldContainer>
    </>
  );
};
