import { OptionProps } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import {
  alignValue,
  dashStyleOptions,
  orientationOptions,
  yAxisTitleAlignValueWizard
} from '../../../../editor/meta/highed.format.options';
import { defaultTypeList, filteredBy } from '../../utils/annotationsHelper';

const option: OptionProps = {
  id: 'annotation.text.highlights',
  pid: 'annotation.text.highlights',
  mapDisabled: true,
  defaultFilterValue: ['line'],
  subType: defaultTypeList,
  filteredBy: filteredBy,
  options: [
    {
      pid: 'plotBands.label.text',
      id: 'label.text',
      annotations: {
        isText: true
      }
    },
    {
      pid: 'highlight.type',
      type: 'select',
      id: 'highlight.type',
      annotations: {
        isType: true
      },
      values: [
        { label: 'Range', value: 'range' },
        { label: 'Line', value: 'line' }
      ]
    },
    {
      pid: 'highlight.orientation',
      type: 'select',
      annotations: {
        isOrientation: true
      },
      id: 'highlight.orientation',
      values: orientationOptions
    },
    {
      pid: 'plotLines.dashStyle',
      type: 'select',
      id: 'dashStyle',
      values: dashStyleOptions
    },
    {
      pid: 'plotBands.label.style.color',
      type: 'color',
      id: 'label.style.color',
      default: '#000000'
    },
    {
      pid: 'plotBands.color',
      type: 'color',
      id: 'color',
      default: '#E6EBF5'
    },
    {
      pid: 'plotBands.label.verticalAlign',
      type: 'select',
      default: 'top',
      values: yAxisTitleAlignValueWizard,
      id: 'label.verticalAlign'
    },
    {
      pid: 'plotBands.label.align',
      type: 'select',
      default: 'center',
      id: 'label.align',
      values: alignValue
    }
  ]
};

export default option;
