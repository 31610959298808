import { cloneDeep, isArray, isEqual, isFunction, set, merge as twMerge } from 'lodash';
import { isObj, loadModules, merge } from '../../../editor/core/highcharts-editor';
import templates from '../../../editor/core/highed.template.plugins';
import { getFreemiumContextButtonOptions } from './contextButtonHelper';
import { getChart } from '../meta/highchartsHelper';
import { mergeCustomConfigWithDefault } from '@visual-elements/location-map';
import { merge as mergeLodash } from 'lodash';
const RESPONSIVE_AXIS_BLOCK_LIST = ['treemap', 'wordcloud'];

export function getTeam(localTeam, team) {
  return localTeam ?? team;
}

export function loadRelevantModules(projectData) {
  if (projectData.theme?.plugins?.cssModules) {
    loadModules(projectData.theme.plugins.cssModules, null, null, true);
  }

  let pluginConfig = {};
  if (projectData.settings?.plugins) {
    let keys = Object.keys(projectData.settings.plugins);
    (keys || []).forEach(function (key) {
      if (key !== 'cssModules') {
        if (templates[key]?.config) {
          pluginConfig = templates[key].config;
        }
        if (templates[key]?.modules) {
          loadModules(templates[key].modules, true);
        }
      }
    });
    if (projectData.settings.plugins.cssModules) loadModules(projectData.settings.plugins.cssModules, null, null, true);
  }
  return pluginConfig;
}

export function makeEmbedDetails(data) {
  return {
    injectCode: data.inject,
    iframeCode: data.iframe,
    iframeURL: data.iframeURL,
    shareUrl: 'https://api.everviz.com/share/' + data.uuid,
    socialLinks: 'https://api.everviz.com/share/' + data.uuid,
    uuid: data.uuid
  };
}

// Highcharts/Maps/Stock
export function createHCAggregatedOptions(config, chartConfig, stockTools, isFreePlan) {
  const customizedOptions = merge({}, config.customizedOptions) ?? {};
  const templateOptions = (config.templateOptions ?? []).map((c) => merge({}, c));
  const themeOptions = merge({}, config.themeOptions) ?? {};
  const chart = getChart(chartConfig.editorChartIndex);
  let aggregatedOptions = {};

  ['xAxis', 'yAxis'].forEach((key) => {
    if (customizedOptions && !isArray(customizedOptions[key]) && customizedOptions[key]) {
      customizedOptions[key] = [customizedOptions[key] || {}];
    }
  });

  let aggregatedTemplate = {};

  // Apply theme first
  if (themeOptions && Object.keys(themeOptions).length) {
    merge(aggregatedOptions, merge(merge({}, themeOptions)));
  }

  templateOptions.forEach(function (arr) {
    if (arr) {
      if (arr.yAxis && !isArray(arr.yAxis)) arr.yAxis = [arr.yAxis];
      if (arr.xAxis && !isArray(arr.xAxis)) arr.xAxis = [arr.xAxis];
      aggregatedTemplate = merge(aggregatedTemplate, arr);
    }
  });

  merge(aggregatedOptions, merge({}, config?.pluginConfig ?? {}));
  merge(aggregatedOptions, merge({}, aggregatedTemplate), null);

  ['xAxis', 'yAxis', 'colorAxis'].forEach((key) => {
    if (aggregatedOptions[key] && aggregatedTemplate[key] && isArray(aggregatedOptions[key])) {
      aggregatedOptions[key].forEach(function (obj, i) {
        if (i < aggregatedTemplate[key].length) {
          merge(obj, aggregatedTemplate[key][i]);
        }
      });
    }

    if (themeOptions && themeOptions[key]) {
      themeOptions[key] = isArray(themeOptions[key]) ? themeOptions[key] : [themeOptions[key]];
      if (isObj(aggregatedOptions[key])) aggregatedOptions[key] = [aggregatedOptions[key]];

      if (isArray(aggregatedOptions[key])) {
        aggregatedOptions[key].forEach(function (obj, i) {
          if (i < themeOptions[key].length) {
            merge(obj, themeOptions[key][i]);
          }
        });
      }
    }
  });

  aggregatedOptions.series = [];
  if (isArray(customizedOptions.series)) {
    customizedOptions.series.forEach(function (obj, i) {
      let mergeTarget = {};

      if (aggregatedTemplate && isArray(aggregatedTemplate.series)) {
        if (i < aggregatedTemplate.series.length) {
          mergeTarget = merge({}, aggregatedTemplate.series[i]);
        }
      }

      if (themeOptions && isArray(themeOptions.series)) {
        if (i < themeOptions.series.length) {
          mergeTarget = merge({}, themeOptions.series[i]);
        }
      }
      aggregatedOptions.series.push(merge(mergeTarget, obj));
    });
  }

  if (themeOptions && themeOptions.series) {
    if (aggregatedOptions.series) {
      aggregatedOptions.series.forEach(function (serie, i) {
        if (!serie.type && themeOptions.series[i] && themeOptions.series[i].type) {
          serie.type = themeOptions.series[i].type;
        }
      });
    }
  }

  if (customizedOptions?.chart?.map && aggregatedOptions?.chart?.map) {
    delete aggregatedOptions.chart.map;
  }

  merge(aggregatedOptions, merge({}, customizedOptions), null, {
    xAxis: 1,
    yAxis: 1,
    series: 1,
    colorAxis: 1
  });

  if (isArray(customizedOptions.series)) {
    if (!aggregatedOptions.series) aggregatedOptions.series = [];
    (customizedOptions.series || []).forEach((serie, i) => {
      if (aggregatedOptions.series[i]) {
        aggregatedOptions.series[i] = merge(merge({}, aggregatedOptions.series[i]), merge({}, serie));
      } else aggregatedOptions.series[i] = merge({}, customizedOptions.series[i]);
    });
  }

  ['yAxis', 'xAxis', 'colorAxis'].forEach((key) => {
    if (aggregatedOptions[key] && !isArray(aggregatedOptions[key])) {
      aggregatedOptions[key] = [aggregatedOptions[key]];
    }

    let customizedAxis = cloneDeep(customizedOptions[key]);
    if (customizedAxis && !isArray(customizedAxis)) {
      customizedAxis = [customizedAxis];
    }

    if (isArray(customizedAxis)) {
      if (!aggregatedOptions[key]) aggregatedOptions[key] = [];
      (customizedAxis || []).forEach((axis, i) => {
        if (aggregatedOptions[key][i]) {
          aggregatedOptions[key][i] = merge(merge({}, aggregatedOptions[key][i]), merge({}, axis));
          if (axis.dataClasses) {
            aggregatedOptions[key][i].dataClasses = axis.dataClasses.map((dataClass, dataClassIndex) => {
              return {
                ...(aggregatedOptions?.[key]?.[i]?.dataClasses?.[dataClassIndex] ?? {}),
                ...dataClass
              };
            });
          }
        } else aggregatedOptions[key][i] = merge({}, customizedAxis[i]);
      });
    }

    if (!aggregatedOptions[key] && customizedAxis) aggregatedOptions[key] = merge({}, customizedAxis);

    if (isArray(aggregatedOptions[key]) && aggregatedOptions[key].length === 1) {
      aggregatedOptions[key] = aggregatedOptions[key][0];
    }
  });

  if (stockTools) {
    aggregatedOptions = merge(aggregatedOptions, stockTools?.getStockToolsToolbarConfig() ?? {});
  }

  // Finally, do custom code
  let editorError = '';
  if (isFunction(chartConfig?.customCode)) {
    try {
      chartConfig?.customCode(aggregatedOptions, chart);
    } catch (e) {
      editorError = e;
      console.log('Error in Custom Code:', e);
    }
  }

  if (isFreePlan) {
    aggregatedOptions = merge(aggregatedOptions, getFreemiumContextButtonOptions());
  }

  return { aggregatedOptions, editorError };
}

// Location Map
export function createLMAggregatedOptions(config) {
  const customizedOptions = mergeLodash({}, config.customizedOptions) ?? {};
  const templateOptions = (config.templateOptions ?? []).map((c) => mergeLodash({}, c));
  const themeOptions = mergeLodash({}, config.themeOptions) ?? {};

  let aggregatedOptions = {};
  let aggregatedTemplate = {};

  // Apply theme first
  if (themeOptions && Object.keys(themeOptions).length) {
    mergeLodash(aggregatedOptions, mergeLodash(mergeLodash({}, themeOptions)));
  }

  templateOptions.forEach(function (arr) {
    if (arr) aggregatedTemplate = mergeLodash(aggregatedTemplate, arr);
  });

  merge(aggregatedOptions, merge({}, aggregatedTemplate), null);
  merge(aggregatedOptions, merge({}, customizedOptions));
  aggregatedOptions = mergeCustomConfigWithDefault(aggregatedOptions);

  return { aggregatedOptions, editorError: '' };
}

const shouldBlockAxis = (seriesTypes) => {
  return RESPONSIVE_AXIS_BLOCK_LIST.some((type) => seriesTypes.includes(type));
};

export const getHighchartsResponsiveConfig = (seriesTypes, chartEditorConfig, options) => {
  let wizardSmallScreenOption = {
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 350,
            maxHeight: 300
          },

          chartOptions: {
            title: {
              text: ''
            },
            legend: {
              enabled: false
            },
            exporting: {
              enabled: false
            },
            subtitle: {
              text: ''
            },
            caption: {
              text: ''
            },
            mapNavigation: {
              enabled: false
            },
            xAxis: {
              title: {
                text: ''
              }
            },
            yAxis: {
              title: {
                text: ''
              }
            }
          }
        }
      ]
    }
  };

  if (shouldBlockAxis(seriesTypes)) {
    // Bug in highcharts, treemap shows a blank chart if theres an x/yAxis option being set in the responsive rules.
    delete wizardSmallScreenOption.responsive.rules[0].chartOptions.yAxis;
    delete wizardSmallScreenOption.responsive.rules[0].chartOptions.xAxis;
  } else if (chartEditorConfig.isMap) {
    delete wizardSmallScreenOption.responsive.rules[0].chartOptions.legend;
  } else {
    // Check for x/yAxis arrays. If user has multiple axis, match this so we
    // dont hide the series attached to it when the chart goes small
    ['xAxis', 'yAxis'].forEach((axis) => {
      if (options?.[axis] && isArray(options[axis])) {
        const smallScreenAxisObj = cloneDeep(wizardSmallScreenOption.responsive.rules[0].chartOptions[axis]);
        wizardSmallScreenOption.responsive.rules[0].chartOptions[axis] = new Array(options[axis].length).fill(
          smallScreenAxisObj
        );
      }
    });
  }

  return wizardSmallScreenOption;
};

export const handleFontResizeActions = (options, noRedraw) => {
  const clonedOptions = cloneDeep(options);
  window.everviz.clonedOptions = clonedOptions;
  window.everviz.disconnectResizeObservers();

  const getResponsiveFontSizes = (options) => {
    const { getLocalAndActiveFontSizes } = window.everviz;
    const { local, active } = getLocalAndActiveFontSizes(options);
    const areFontSizesEqual = isEqual(active, local);
    if (areFontSizesEqual) return window.everviz.responsiveFontOptions || {};
    return {
      everviz: {
        text: {
          forceRender: true
        }
      }
    };
  };

  if (options.everviz?.text?.dynamicFonts) {
    twMerge(options, getResponsiveFontSizes(options));
  }

  if (noRedraw) {
    set(options, 'everviz.text.noRedraw', true);
  }
};
