import React, { useCallback, useEffect, useState } from 'react';

import Close from 'static/icons/close.svg';
import { useDispatch, useSelector } from 'react-redux';

import InputSearch from '../../../shared/inputs/InputSearch';

import { checkPermission } from '../../../utils/profileHelper';
import { setAction, setSelectFilterAction } from '../actions/projectsPage';
import { ModalDeleteTag } from './ModalDeleteTag';
import useToggle from 'shared/utils/useToggle';

import SelectWidget from '../../../shared/widgets/SelectWidget';
import SelectMultiWidget from '../../../shared/widgets/SelectMultiWidget';
import { selectCustomStyles } from 'shared/utils/selectStylesHelper';

export default function ProjectsViewSearch(props) {
  const dispatch = useDispatch();
  const { tags, creators, selectSortValue, selectCreatorValue, selectValue } = useSelector(
    (state) => state.projectsPage
  );
  const projectsPage = useSelector((state) => state.projectsPage);

  const [query, setQuery] = useState('');
  const [settingsModal, toggleSettingsModal] = useToggle(false);
  const [selectedTag, setSelectedTag] = useState();

  const selectOptions = [
    { value: 0, label: 'Most recent' },
    { value: 1, label: 'Date created' },
    { value: 2, label: 'Name' }
  ];

  useEffect(() => {
    if (projectsPage.query === '') setQuery('');
  }, [projectsPage.query]);

  const resetSearch = useCallback(() => {
    dispatch(setAction({ page: 1 }));
    setQuery('');
    dispatch(
      setSelectFilterAction({
        query: ''
      })
    );
  }, []);

  const reset = () => {
    dispatch(setAction({ page: 1 }));
    setQuery('');
    dispatch(
      setSelectFilterAction({
        query: '',
        selectValue: [],
        selectCreatorValue: [],
        selectSortValue: { value: 0, label: 'Most recent' }
      })
    );
  };

  useEffect(reset, []);

  const handleSelectChange = useCallback(
    (selectedOption, property) => {
      dispatch(setSelectFilterAction({ [property]: selectedOption }));
    },
    [dispatch]
  );

  const handleClick = useCallback((tag) => {
    toggleSettingsModal(true);
    setSelectedTag(tag);
  });

  const handleFilterAction = () => {
    if (checkPermission('view_charts')) {
      dispatch(
        setSelectFilterAction({
          query: query
        })
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleFilterAction();
  };

  const handleClickSearch = () => {
    handleFilterAction();
  };

  return (
    <>
      <div className="charts-search mb-4 ev-sm:mb-6 hidden ev-sm:flex rounded shadow-ev-standard">
        <form className="flex-col ev-sm:flex-row ev-md:flex flex-wrap w-full" onSubmit={(e) => onSubmit(e)}>
          <InputSearch
            label="Search by title"
            placeholder="Search by title"
            value={query}
            reset={resetSearch}
            onChange={setQuery}
            onClick={handleClickSearch}
            simple={props.simple}
            className="p-4"
          />
          {!props.simple && (
            <div className="p-4 flex flex-col ev-md:flex-row space-x-1">
              <div>
                <label htmlFor="select-creator" className="block font-bold text-base text-ev-navy-blue">
                  Filter by author
                </label>
                <SelectWidget
                  placeholder="Author"
                  selectOptions={creators}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, 'selectCreatorValue')}
                  value={selectCreatorValue}
                  customStyles={selectCustomStyles}
                  clearable
                />
              </div>

              <div>
                <label htmlFor="select-tags" className="block font-bold text-base text-ev-navy-blue">
                  Select tags
                </label>
                <SelectMultiWidget
                  label="Select tags"
                  selectOptions={tags}
                  onChange={(selectedOptions, actionMeta, option) => {
                    handleSelectChange(selectedOptions, 'selectValue', option);
                    setSelectedTag(actionMeta.option);
                  }}
                  value={selectValue}
                  onClick={handleClick}
                />
              </div>

              <div>
                <label htmlFor="select-sort" className="block font-bold text-base text-ev-navy-blue">
                  Sort by
                </label>
                <SelectWidget
                  selectOptions={selectOptions}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, 'selectSortValue')}
                  value={selectSortValue}
                  customStyles={selectCustomStyles}
                />
              </div>

              <button
                onClick={reset}
                className="flex justify-center items-center h-8 w-8 ml-2 mb-1 self-end focus:border focus:border-ev-dark-purple rounded-md"
              >
                <Close className="text-stale-900 hover:text-gray-700 h-3 w-3" />
              </button>
            </div>
          )}
        </form>
      </div>
      <ModalDeleteTag isOpen={settingsModal} onClose={() => toggleSettingsModal(false)} tag={selectedTag} />
    </>
  );
}
