import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../shared/inputs/TextInput';
import CheckboxInput from '../../../shared/inputs/CheckboxInput';
import Button from '../../../shared/buttons/Button';

const TeamSettingsForm = ({
  settings,
  onSave,
  onDelete,
  onChange,
  saving,
  deleting,
  checkPermission,
  openNewTeamModal
}) => {
  return (
    <form className="table-container">
      <div className="content team-settings">
        <TextInput name="name" label="Team name:" value={settings.name} onChange={onChange} />
      </div>
      <div className="content team-settings team-options">
        <CheckboxInput
          name="charts_private_by_default"
          label="Charts are private when created"
          checked={settings.charts_private_by_default ?? false}
          onChange={onChange}
          className="mt-1"
        />
        <CheckboxInput
          name="private"
          label="Private team (Do not show in lists)"
          checked={settings.private ?? false}
          onChange={onChange}
          className="mt-1"
        />
      </div>

      <div className="content team-settings create-team-button">
        <Button
          buttonClass="btn bulk-action bulk-action-btn inline"
          buttonText="Create new team"
          onClick={openNewTeamModal}
        />
      </div>

      <div className="table-btn-container">
        {checkPermission('team_management') && (
          <input
            type="submit"
            disabled={saving}
            value={saving ? 'Saving...' : 'Save'}
            className="bulk-action bulk-action-btn inline"
            onClick={onSave}
          />
        )}

        {checkPermission('team_management') && (
          <input
            type="submit"
            disabled={deleting}
            value={deleting ? 'Deleting...' : 'Delete team'}
            className="bulk-action bulk-action-btn inline danger"
            onClick={onDelete}
          />
        )}
      </div>
    </form>
  );
};

TeamSettingsForm.propTypes = {
  settings: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  deleting: PropTypes.bool,
  checkPermission: PropTypes.func,
  team: PropTypes.object,
  openNewTeamModal: PropTypes.func
};

export default TeamSettingsForm;
