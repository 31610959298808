import React from 'react';
import Accordian from 'shared/Accordion';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { isString } from 'lodash';
import { GenericInputProps } from 'shared/types/commonPropTypes';

export default function PlacementWidget(props: GenericInputProps) {
  const { option, value } = props;

  const placementOption = {
    top: 1,
    bottom: 1,
    left: 1,
    right: 1
  };

  const capitalizeFirstLetter = (string: string) => (string ?? '').charAt(0).toUpperCase() + (string ?? '').slice(1);
  const iconClassName = 'w-6 h-6 mx-1.5 rounded';
  const active = value && isString(value) ? value : option?.default && isString(option?.default) ? option?.default : '';

  return (
    <Accordian
      key={'key'}
      defaultSelection={false}
      headerText={props.label}
      smallHeaderText={true}
      selectedChildren={`${capitalizeFirstLetter(active)}`}
      useInternalSelect={true}
      accordionClasses={props.className ?? 'bg-white'}
    >
      <div className="pb-3">
        {placementOption.bottom && (
          <IconButton
            icon="arrow-down"
            className={iconClassName}
            buttonColor={ButtonColor.Grey}
            onClick={() => props.onChange({ val: 'bottom' })}
            active={active === 'bottom'}
          />
        )}
        {placementOption.top && (
          <IconButton
            icon="arrow-up"
            className={iconClassName}
            buttonColor={ButtonColor.Grey}
            onClick={() => props.onChange({ val: 'top' })}
            active={active === 'top'}
          />
        )}
        {placementOption.left && (
          <IconButton
            icon="arrow-left"
            className={iconClassName}
            buttonColor={ButtonColor.Grey}
            onClick={() => props.onChange({ val: 'left' })}
            active={active === 'left'}
          />
        )}
        {placementOption.right && (
          <IconButton
            icon="arrow-right"
            className={iconClassName}
            buttonColor={ButtonColor.Grey}
            onClick={() => props.onChange({ val: 'right' })}
            active={active === 'right'}
          />
        )}
      </div>
    </Accordian>
  );
}
