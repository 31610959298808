/* eslint-disable react/no-danger */
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { PlanFeature, PlanPeriod, SubscriptionPlan, SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import paths from 'paths';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Link from 'shared/nav/Link';
import { getPlanDetailsByNameAndPeriod } from '../utils/plansHelper';
import CurvedArrow from 'static/icons/curved_arrow.svg';

type PlanCardProps = {
  plan: SubscriptionPlans;
  period?: PlanPeriod;
  description: string;
  onClick: () => void;
  isActive: boolean;
  isHidden: boolean;
  pendingPlan?: SubscriptionPlan | undefined;
  isDowngraded: boolean;
  features: PlanFeature[];
};

const PlanCard = (props: PlanCardProps) => {
  const { isActive, plan, isDowngraded } = props;
  const { team } = useSelector((state: RootState) => state.profile);

  const isFreePlan = plan === SubscriptionPlans.Free;
  const isProTrialPlan = plan === SubscriptionPlans.ProTrial;
  const isEnterprisePlan = plan === SubscriptionPlans.Enterprise;
  const isLoggedIn = !!team;

  const divClasses = classNames(
    'flex items-center flex-col w-[316px] h-[889px] relative border border-ev-navy-blue rounded-[33px]'
  );

  const buttonClasses = classNames('content-centered tracking-wide w-fit px-4 h-10 rounded-[33px] font-bold', {
    'bg-ev-red cursor-pointer text-white hover:bg-ev-light-red hover:text-ev-navy-blue-2': isEnterprisePlan,
    'bg-ev-navy-blue-2 cursor-pointer text-white hover:bg-ev-baby-blue-2 hover:text-ev-navy-blue-2':
      !isActive && !isEnterprisePlan && !isDowngraded,
    'bg-ev-grey text-ev-grey-3 cursor-not-allowed': isActive || isDowngraded
  });

  const titleClasses = classNames('content-centered tracking-wide w-full h-24 rounded-t-[33px] font-bold text-3xl', {
    'bg-ev-light-red': isEnterprisePlan,
    'bg-ev-baby-blue-2': !isEnterprisePlan
  });

  const contentContainerClasses = classNames('w-full flex flex-col items-center');

  const generateButtonText = () => {
    if (isEnterprisePlan) return 'Contact us';
    else if (isActive) return 'Current plan';
    else if (isDowngraded) return 'Downgrade';
    else return 'Continue with Pro plan';
  };

  const generateButton = () => {
    if (isDowngraded) {
      return (
        <div className={`${buttonClasses} flex highed-template-tooltip`}>
          {generateButtonText()}
          <div className="highed-tooltip-text p-4 w-44">
            To downgrade your account, please reach out to sales@everviz.com
          </div>
        </div>
      );
    } else {
      return (
        <div className={buttonClasses} onClick={!isActive ? props.onClick : () => null}>
          {generateButtonText()}
        </div>
      );
    }
  };

  const generatePrice = (period: PlanPeriod) => {
    if (plan === SubscriptionPlans.Starter || plan === SubscriptionPlans.Business || plan === SubscriptionPlans.Pro) {
      return getPlanDetailsByNameAndPeriod(plan, period)?.price;
    } else if (isEnterprisePlan) {
      return 'Get a quote';
    } else {
      return '';
    }
  };

  const monthlyPrice = generatePrice(PlanPeriod.Monthly);
  const yearlyPrice = generatePrice(PlanPeriod.Yearly);

  if (!props.isHidden) {
    return (
      <div className={divClasses}>
        {isActive && (
          <div className="absolute content-centered rounded-t-[33px] h-7 w-full bg-ev-green text-white text-sm">
            Your current plan
          </div>
        )}
        <div className={contentContainerClasses}>
          <div className={titleClasses}>{plan}</div>

          {/* {pendingPlan && isActive && <p className="text-base font-medium">Pending: {pendingPlan?.name}</p>} */}
          <div className="relative flex items-center flex-col h-24 mb-2">
            {!isFreePlan && !isProTrialPlan && (
              <>
                {!isEnterprisePlan && (
                  <div className="absolute top-12 -right-[100px] rotate-3 bg-ev-red text-white text-sm rounded px-1 py-1 ev-sm-max:hidden">
                    2 months free!
                    <CurvedArrow className="absolute w-5 h-6 left-[11px] top-[18px]" />
                  </div>
                )}

                {typeof monthlyPrice === 'number' && (
                  <>
                    <p className="font-medium text-ev-navy-blue m-0 pt-4 pb-px">
                      <span className="text-xl">${monthlyPrice} </span>
                      <span className="text-sm">/ billed monthly</span>
                    </p>
                    <div className="font-medium text-sm">or</div>
                  </>
                )}

                {typeof yearlyPrice === 'number' && (
                  <p className="font-medium text-ev-navy-blue pt-px">
                    <span className="text-xl">${yearlyPrice.toLocaleString()} </span>
                    <span className="text-sm">/ billed yearly</span>
                  </p>
                )}

                {typeof monthlyPrice === 'string' && (
                  <p className="font-medium text-ev-dark-red text-xl pt-4">{monthlyPrice}</p>
                )}
              </>
            )}
          </div>

          <p className="mb-6 h-18 w-56 text-center text-base text-ev-navy-blue">{props.description}</p>

          {generateButton()}

          <ul className="list-none w-full px-4 pt-5">
            {props.features.map(({ text, link, anchorLink }: PlanFeature) => (
              <li key={text} className="w-full text-center p-2 border-b last:border-none border-b-ev-grey-3 text-base">
                {link && <Link text={text} link={link} />}

                {anchorLink && (
                  <Link
                    text={text}
                    link={`${isLoggedIn ? paths.profileSubscription : paths.subscription}${anchorLink}`}
                    anchorLink={!!anchorLink}
                  />
                )}

                {!link && !anchorLink && (
                  <div
                    className="text-ev-navy-blue text-base"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return null;
};

export default PlanCard;
