import { connect } from 'react-redux';
import AnalyticsPage from '../AnalyticsPage';
import { checkPermission, checkPlanPermission } from '../../../utils/profileHelper';

const mapStateToProps = (state) => {
  return {
    paths: state.app.paths || {},
    team: state.profile.team,
    division: state.profile.division,
    checkPermission: (permission) => checkPermission(permission, state.profile),
    checkPlanPermission: (permission) => checkPlanPermission(permission, state.profile)
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
