import { TemplateProps } from '../../types';

const StackedPercentage: TemplateProps = {
  title: 'Stacked percentage',
  description: '',
  constructor: '',
  thumbnail: 'iporos.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        stacking: 'percent'
      }
    }
  },
  sampleSet: 'area',
  advancedParent: 'Stacked area',
  dataValidator: false,
  advanced: {
    name: 'Standard'
  }
};

export default StackedPercentage;
