import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Button from '../../shared/buttons/Button';
import TextInput from '../../shared/inputs/TextInput';
import ErrorMessage from '../../shared/message/ErrorMessage';
import Captcha from '../../shared/Captcha';
import config from '../../config';
import { postAccountChangepasswordUsingToken } from './../../api/cloudApiGenerated';
/*
let captchaInstance;
const getCaptchaInstance = (e) => captchaInstance = e;
*/
class ResetPasswordPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.validateForm = this.validateForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateState = this.updateState.bind(this);

    const token = props.match.params.token;

    this.state = {
      token: token,
      error: false,
      success: false,
      captchaError: false,
      form: {
        password: {
          value: '',
          error: false,
        },
        passwordConfirmation: {
          value: '',
          error: false,
        },
      },
    };
  }

  validateForm() {
    let form = this.state.form;
    let valid = true;

    if (form.password.value.length < 8) {
      form.password.error = 'Your password needs to be at least 8 characters long';
      valid = false;
    } else {
      form.password.error = false;
    }

    if (form.password.value !== form.passwordConfirmation.value) {
      form.passwordConfirmation.error = 'The passwords you entered do not match';
      valid = false;
    } else {
      form.passwordConfirmation.error = false;
    }

    if (!valid) {
      this.setState({ form });
    }

    return valid;
  }

  onSubmit(event) {
    event.preventDefault();

    let form = this.state.form;
    const valid = this.validateForm();
    //let captcha = window[captchaInstance];
    let captchaResponse = true; //!config.captcha.enabled || captcha.getResponse();

    if (captchaResponse) {
      this.setState({
        captchaError: false,
      });
    } else {
      this.setState({
        captchaError: 'You need to validate the captcha',
      });
    }

    if (valid && captchaResponse) {
      postAccountChangepasswordUsingToken(this.state.token, {
        password: form.password.value,
        captchaResponse,
      })
        .then(() => {
          this.setState({ success: true });
        })
        .catch((error) => {
          this.setState({
            error: error.message.join(' '),
          });
        });
    }
  }

  updateState(event) {
    const field = event.target.name;
    let state = this.state;
    let form = state.form;
    form[field].value = event.target.value;
    form.password.error = false;
    form.passwordConfirmation.error = false;
    state.captchaError = false;
    return this.setState({ form });
  }

  render() {
    let state = this.state;
    let form = state.form;

    return (
      <div className="reset-password-page col-xs-8 col-sm-4 col-lg-5">
        <h1>Reset your password</h1>

        <section className="reset-password-section reset-password-form">
          <form role="form" method="post">
            <ErrorMessage error={state.error} />

            <TextInput
              label="Password:"
              name="password"
              type="password"
              disabled={this.state.success}
              value={form.password.value}
              placeholder="Enter password (minimum 8 characters)"
              onChange={this.updateState}
            />
            <ErrorMessage error={form.password.error} />

            <TextInput
              label="Password confirmation:"
              name="passwordConfirmation"
              type="password"
              disabled={this.state.success}
              value={form.passwordConfirmation.value}
              placeholder="e.g., ••••••••••"
              onChange={this.updateState}
            />
            <ErrorMessage error={form.passwordConfirmation.error} />

            {/*config.captcha.enabled &&
              <div className="form-group">
                <Captcha getInstance={getCaptchaInstance}/>
                <ErrorMessage error={this.state.captchaError} dismissable={false}/>
              </div>
            */}

            <div className="reset-button-container">
              <Button
                buttonText="Reset password"
                buttonClass="btn btn-success btn-lg"
                disabled={this.state.success}
                type="submit"
                onClick={this.onSubmit}
              />
            </div>

            {this.state.success && (
              <div>
                <div className="alert alert-success">
                  <strong>Success!</strong> Your password was successfully changed.
                </div>
                <NavLink
                  to={{ pathname: this.props.paths.login, state: { overrideRedirect: true } }}
                  className="bulk-action-btn"
                  role="button"
                >
                  Continue to sign in page
                </NavLink>
              </div>
            )}
          </form>
        </section>
      </div>
    );
  }
}

ResetPasswordPage.propTypes = {
  paths: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default ResetPasswordPage;
