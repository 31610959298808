import React from 'react';

type WizardContainerProps = {
  children: React.ReactNode;
};

export default function WizardContainer(props: WizardContainerProps) {
  return (
    <div className="wizard-container h-full ev-sm:h-[790px] flex ev-sm:flex-row flex-col text-ev-navy-blue-2 bg-white border-t-0 align-top rounded ev-sm:p-2 ev-sm:mb-16 max-w-[944px] ev-sm:mx-6 ev-md:mx-20 ev-lg:mx-auto">
      {props.children}
    </div>
  );
}
