import React from 'react';

export default function Placeholder() {
  return (
    <div className="project-card">
      <div className="flex flex-col relative justify-between animate-pulse">
        <div className="!bg-gray-200 mb-3 z-10 rounded-md relative h-24" />
        <div className="!bg-gray-200 mb-3 z-10 rounded-md h-4" />
        <div className="!bg-gray-200 mb-3 z-10 rounded-md h-3" />
        <span className="!bg-gray-200 ml-0 mb-2 z-10 bulk-action-btn rounded-[100px] h-6" />
      </div>
    </div>
  );
}
