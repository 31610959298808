import { format } from 'date-fns';
import React, { useState } from 'react';
import NavButton from 'shared/buttons/NavButton';
import Modal from 'shared/modal/Modal';

type AnnouncementProps = {
  publishDate: string;
  title: string;
  image?: string;
  description: string;
  link: string;
};

const Announcement = ({ publishDate, title, image, description, link }: AnnouncementProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Modal isOpen={isModalOpen} title={title} onClose={() => setIsModalOpen(false)}>
        <div>
          <img className="rounded" src={image} />
        </div>
      </Modal>
      <div className="rounded bg-ev-light-orange">
        <div className="z-10 relative p-6 shadow-ev-standard">
          {image && <img className="cursor-pointer mb-4 rounded" src={image} onClick={() => setIsModalOpen(true)} />}
          <span className="inline-block text-xs text-black font-medium">
            Published: {format(new Date(publishDate), 'do MMMM yyyy')}
          </span>
          <h4 className="mb-2 text-black font-bold">{title}</h4>
          <p className="m-0 text-base text-black font-normal">{description}</p>
        </div>
        <NavButton text="Read more" link={link} target="_blank" rel="noopener noreferrer" />
      </div>
    </>
  );
};

export default Announcement;
