export default {
  series: 2,
  data: 'state/region;population\nus-ak;735132\nus-al;4833722\nus-ar;2959373\nus-az;6626624\nus-ca;38332521\nus-co;5268367\nus-ct;3596080\nus-dc;646449\nus-de;925749\nus-fl;19552860\nus-ga;9992167\nus-hi;1404054\nus-ia;3090416\nus-id;1612136\nus-il;12882135\nus-in;6570902\nus-ks;2893957\nus-ky;4395295\nus-la;4625470\nus-ma;6692824\nus-md;5928814\nus-me;1328302\nus-mi;9895622\nus-mn;5420380\nus-mo;6044171\nus-ms;2991207\nus-mt;1015165\nus-nc;9848060\nus-nd;723393\nus-ne;1868516\nus-nh;1323459\nus-nj;8899339\nus-nm;2085287\nus-nv;2790136\nus-ny;19651127\nus-oh;11570808\nus-ok;3850568\nus-or;3930065\nus-pa;12773801\nus-ri;1051511\nus-sc;4774839\nus-sd;844877\nus-tn;6495978\nus-tx;26448193\nus-ut;2900872\nus-va;8260405\nus-vt;626630\nus-wa;6971406\nus-wi;5742713\nus-wv;1854304\nus-wy;582658',
  seriesMapping: [
    {
      'hc-key': 0,
      name: 0,
      value: 1,
      x: 1
    },
    {
      'hc-key': 0,
      value: 1,
      x: 1
    }
  ],
  assignDataFields: [
    {
      labels: 'A',
      value: 'B'
    },
    {
      labels: 'A',
      value: 'B'
    }
  ],
  seriesOptions: [
    {
      type: 'map'
    },
    {
      type: 'mapline',
      includeInDataExport: false
    }
  ],
  seriesTemplate: ['map', 'mapline']
};
