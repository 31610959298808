import { connect } from 'react-redux';
import App from '../routes/App';
import { checkLoggedIn, onLoggedIn } from '../utils/profileHelper'; // Remove this when fully converted
import { setAction } from './../redux/actions/app'; // Remove this when fully converted
import { updateChartCountAction } from './../redux/actions/profile'; // Remove this when fully converted
import { setTeamMembersAction } from 'pages/TeamPage/actions/teamMembersAction';

const mapStateToProps = (state) => {
  return {
    paths: state.app.paths || {},
    loading: state.app.loading,
    error: state.app.error,
    teams: state.profile.teams,
    subscription: state.profile.subscription,
    team: state.profile.team,
    divisions: state.profile.divisions,
    division: state.profile.division,
    messages: state.profile.messages || [],
    announcements: state.profile.announcements,
    billingPermissions: state.profile.billingPermissions,
    user: state.profile.user,
    unreadMessages: state.profile.unreadMessages,
    permissions: state.profile.permissions,
    isPaidPlanModalOpen: state.app.isPaidPlanModalOpen,
    isPremiumFeatureModalOpen: state.app.isPremiumFeatureModalOpen,
    uploadingData: state.layoutEditorPage.uploadingData,
    onLoggedIn: onLoggedIn,
    profile: state.profile,
    checkLoggedIn: checkLoggedIn
  };
};

const mapDispatchToProps = (dispatch) => ({
  set: (params) => dispatch(setAction(params)),
  closePaidPlan: () => dispatch(setAction({ isPaidPlanModalOpen: false })),
  closePremiumFeature: () => dispatch(setAction({ isPremiumFeatureModalOpen: false })),
  updateChartCount: () => dispatch(updateChartCountAction()),
  openNewSubteamModal: () =>
    dispatch(
      setTeamMembersAction({
        isSubteamCreateModalOpen: true
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(App);
