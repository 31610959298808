(function (Highcharts) {
  if (!window.everviz) window.everviz = {};
  let fontSizes = {};
  const textFields = ['title', 'subtitle', 'caption', 'credits', 'legend', 'rangeSelector'];

  // Can be an object or array
  const arrayTextFields = ['xAxis', 'yAxis', 'yAxisTick', 'xAxisTick'];

  const defaultFontSizePx = {
    title: 18,
    subtitle: 13,
    caption: 13,
    credits: 10,
    legend: 13,
    xAxis: 13,
    yAxis: 13,
    xAxisTick: 11,
    yAxisTick: 11,
    rangeSelector: 11
  };

  let previousSize = {
    width: null,
    height: null
  };

  let resizeTimeout = null;

  // Used inside everviz
  window.everviz.disconnectResizeObservers = () => {
    Highcharts.charts.forEach((chart) => {
      if (chart?.resizeObserver) chart.resizeObserver.disconnect();
    });
  };

  // Used in publish codes. Dont want to remove all of
  // the observers on a users page like the function above
  window.everviz.disconnectResizeObserver = (chart) => {
    if (chart?.resizeObserver) chart.resizeObserver.disconnect();
  };

  window.everviz.getLocalAndActiveFontSizes = (options) => {
    const localFontSizes = getFontSizes({
      options,
      xAxis: options?.xAxis ?? {},
      yAxis: options?.yAxis ?? {}
    });

    return {
      local: localFontSizes,
      active: fontSizes
    };
  };

  const getFontSizes = (chart) => {
    const sizes = {};
    textFields.forEach((field) => (sizes[field] = getFontSizeForTextField(chart, field)));
    arrayTextFields.forEach((field) => {
      const isFieldAnArray = Highcharts.isArray(chart?.options?.[field]);
      if (isFieldAnArray && chart?.options?.[field]?.length > 1) {
        sizes[field] = [];
        chart?.options?.[field].forEach((_, index) => {
          sizes[field][index] = getFontSizeForTextField(chart, field, index);
        });
      } else sizes[field] = getFontSizeForTextField(chart, field, 0);
    });
    return sizes;
  };

  const resizeFonts = (chart, fontSizes, updatableChartOption) => {
    let responsiveFontOptions;
    const width = chart.chartWidth;
    const height = updatableChartOption?.height ?? chart.chartHeight;
    const conversionRate = Math.log(height / 200) + Math.log(width / 100);
    const computeNewFontSize = (fieldValue) => `${conversionRate / (39 / fieldValue)}rem`;
    const computeNewPointSize = () => Math.round(conversionRate);

    const addAxisFont = (axisId, chartType) => ({
      title: {
        style: {
          fontSize: computeNewFontSize(fontSizes[axisId])
        }
      },
      labels: {
        style: {
          fontSize: computeNewFontSize(fontSizes[`${axisId}Tick`])
        },
        step: chartType === 'column' || chartType === 'heatmap' ? 1 : Math.round(width / 1000)
      }
    });

    responsiveFontOptions = {
      chart: { style: { fontSize: `${conversionRate / 3}rem` }, ...(updatableChartOption ?? {}) },
      title: { style: { fontSize: computeNewFontSize(fontSizes.title) } },
      subtitle: { style: { fontSize: computeNewFontSize(fontSizes.subtitle) } },
      caption: { style: { fontSize: computeNewFontSize(fontSizes.caption) } },
      credits: { style: { fontSize: computeNewFontSize(fontSizes.credits) } },
      legend: { itemStyle: { fontSize: computeNewFontSize(fontSizes.legend) } },
      rangeSelector: {
        labelStyle: { fontSize: computeNewFontSize(fontSizes.rangeSelector) }
      }
    };
    const isMap = chart?.options?.chart?.type === 'map';
    if (!isMap) {
      ['xAxis', 'yAxis'].forEach((axis) => {
        if (chart?.[axis]?.[0]?.visible && chart?.[axis]?.[0]?.options?.title) {
          const chartType = chart?.types?.[0];
          let axisOptions = addAxisFont(axis, chartType);

          // Filter out navigator objects from the axis list
          const filteredAxes = chart?.[axis]?.filter(
            (axisObj) => !axisObj?.userOptions?.className?.includes(`highcharts-navigator-${axis.toLowerCase()}`)
          );

          const isMoreThanOneAxis = filteredAxes.length > 1;
          if (isMoreThanOneAxis) responsiveFontOptions[axis] = new Array(fontSizes.xAxis.length).fill(axisOptions);
          else responsiveFontOptions[axis] = axisOptions;
        }
      });
    }

    if (chart.options.chart.type === 'map') {
      responsiveFontOptions.plotOptions = { mappoint: { marker: { radius: computeNewPointSize() } } };
    }

    // This resize logic is clashing with the responsive rules we have set up in the
    // data step of the wizard, causing some jumping behaviour in the chart. If theres
    // a responsive rule being used on the chart, skip this logic instead.
    if (!chart.currentResponsive) chart.update(responsiveFontOptions, true, true, false);

    /* Resetting the resizing action for the context button, to avoid the wrong scaling issues in HC.
    Ideally, it should be replaced in the future, and moved to the responsiveFontOptions, but for the moment
    HC api doesn't not provide the sufficient config options */
    const contextButton = document.querySelector('.highcharts-contextbutton');
    if (contextButton) contextButton.style.fontSize = '0.8em';

    if (!window.everviz) window.everviz = {};
    window.everviz.responsiveFontOptions = responsiveFontOptions;
    previousSize.width = width;
    previousSize.height = height;
  };

  const getStringValue = (chart, fieldId, index) => {
    const isAxisVisible = chart?.[fieldId]?.[index]?.visible;
    if ((fieldId === 'xAxis' || fieldId === 'yAxis') && !isAxisVisible) return '';
    switch (fieldId) {
      case 'title':
      case 'subtitle':
      case 'caption':
      case 'credits':
        return (
          window.everviz?.clonedOptions?.[fieldId]?.style?.fontSize || chart.options?.[fieldId]?.style?.fontSize || ''
        );
      case 'legend':
        return (
          window.everviz?.clonedOptions?.[fieldId]?.style?.fontSize ||
          chart.options?.[fieldId]?.itemStyle?.fontSize ||
          ''
        );
      case 'rangeSelector':
        return (
          window.everviz?.clonedOptions?.[fieldId]?.style?.fontSize ||
          chart.options?.[fieldId]?.labelStyle?.fontSize ||
          ''
        );
      case 'xAxis':
      case 'yAxis':
        return (
          window.everviz?.clonedOptions?.[fieldId]?.[index]?.title?.style?.fontSize ||
          window.everviz?.clonedOptions?.[fieldId]?.title?.style?.fontSize ||
          chart.options[fieldId]?.[index]?.title?.style?.fontSize ||
          ''
        );
      case 'xAxisTick':
      case 'yAxisTick':
        return (
          window.everviz?.clonedOptions?.[fieldId]?.[index]?.labels?.style?.fontSize ||
          window.everviz?.clonedOptions?.[fieldId]?.labels?.style?.fontSize ||
          chart.options[fieldId]?.[index]?.labels?.style?.fontSize ||
          ''
        );
    }
  };

  const getFontSizeForTextField = (chart, fieldId, index) => {
    let stringValue = getStringValue(chart, fieldId, index ?? 0);
    stringValue = Highcharts.isNumber(stringValue) ? stringValue.toString() : stringValue;
    return stringValue.includes('px') ? parseInt(stringValue) : defaultFontSizePx[fieldId];
  };

  const getHeightForDynamic = (chart) => {
    return chart?.fullscreen?.isOpen ? undefined : chart.chartHeight + (chart.evervizMinPlotHeight - chart.plotHeight);
  };

  const getElementHeight = (element) => {
    let dimensions;
    if (element?.getBBox) dimensions = element.getBBox();
    else if (element?.getBoundingClientRect) dimensions = element.getBoundingClientRect();
    return dimensions?.width && dimensions?.height ? dimensions.height : 0;
  };

  const getDecorationHeight = (chart) => {
    const titleHeight = getElementHeight(chart.title?.element);
    const subtitleHeight = getElementHeight(chart.subtitle?.element);
    const legendHeight = chart.legend?.box?.getBBox().height ?? 0;

    return {
      decorationHeight: titleHeight + subtitleHeight + legendHeight,
      offsetHeight: titleHeight + subtitleHeight
    };
  };

  const getHeightForScaled = (chart) => {
    const map = chart.container.querySelector('.highcharts-series-group');
    const mapBox = map.getBBox();
    const { decorationHeight } = getDecorationHeight(chart);
    const heightFromWidth = mapBox.height / mapBox.width;

    return parseInt(chart.chartWidth * heightFromWidth, 10) + decorationHeight;
  };

  const offsetMap = (y) => {
    const map = this.container.querySelector('.highcharts-series-group');
    map.setAttribute('transform', `translate(0, ${y})`);
  };

  const getResponsiveConfig = (chart) => {
    let newHeight = chart.evervizScaleToFit ? getHeightForScaled(chart) : getHeightForDynamic(chart);
    let config = { height: newHeight };

    if (chart.evervizScaleToFit) {
      config.margin = [5, 5, 5, 5];

      const { offsetHeight } = getDecorationHeight(chart);
      if (offsetHeight) {
        // Add some small margin to prevent map from touching text directly
        const PAD = 25;
        offsetMap((offsetHeight + PAD) / 2);
      }
    }

    return config;
  };

  const handleDynamicOptions = (chart, isStub) => {
    let responsiveConfig = {};
    const evervizDynamicHeight = chart.evervizDynamicHeight;

    if (evervizDynamicHeight && !window.inEverviz && !isStub) responsiveConfig = getResponsiveConfig(chart);

    const useDynamicFonts = chart.options?.everviz?.text?.dynamicFonts;
    const isSameSize = chart.chartWidth === previousSize.width && chart.chartHeight === previousSize.height;
    const forceRender = chart.options?.everviz?.text?.forceRender;
    const shouldRenderInEverviz = window.inEverviz && (!isSameSize || forceRender);
    const shouldRender = !window.inEverviz || shouldRenderInEverviz;

    if (shouldRender) {
      if (useDynamicFonts) resizeFonts(chart, fontSizes, evervizDynamicHeight ? responsiveConfig : {});
      else if (!useDynamicFonts && evervizDynamicHeight) chart.update({ chart: responsiveConfig });
    }
  };

  Highcharts.addEvent(Highcharts.Chart, 'load', function () {
    const templateMinPlotHeight = {
      packedbubble: 400
    };
    const hasHeight = this.userOptions && this.userOptions.chart && this.userOptions.chart.height;
    this.evervizDynamicHeight = !hasHeight;
    this.evervizScaleToFit = this.options.everviz?.animation?.scaleToFit;
    this.evervizMinPlotHeight = templateMinPlotHeight[this.userOptions?.chart?.type] || 300;
    const isStub = this.evervizScaleToFit && this.userOptions?.everviz?.stub;

    fontSizes = getFontSizes(this);
    handleDynamicOptions(this, isStub);

    if (typeof ResizeObserver === 'function') {
      const chart = this;

      chart.resizeObserver = new ResizeObserver(function () {
        const noRedraw = chart?.options?.everviz?.text?.noRedraw;
        const hasChart = chart?.chartHeight;

        if (hasChart && chart?.reflow) chart.reflow();
        // Highmaps doesnt play well with reflowing here. Call a final
        // redraw at the end of the resize to align everything properly
        if (resizeTimeout) clearTimeout(resizeTimeout);

        resizeTimeout = setTimeout(() => {
          if (hasChart && !noRedraw) chart.redraw();
        }, 10);

        handleDynamicOptions(chart, isStub);
      });

      chart.resizeObserver.observe(chart.renderTo);
    }
  });
})(window.Highcharts);
