import React from 'react';
import {
  DndContext,
  useSensors,
  useSensor,
  closestCenter,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

type SortableContainerProps = {
  value: [];
  onChange?: (options: { val: any }) => void;
  children: React.ReactNode;
};

export default (props: SortableContainerProps) => {
  const { value } = props;
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const { value } = props;

    if (active.id !== over?.id) {
      const newValue = arrayMove((value ?? []) as [], (active.id as number) - 1, (over?.id as number) - 1);
      if (props.onChange) {
        props.onChange({
          val: newValue
        });
      }
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter}>
      <SortableContext
        items={(value || []).map((_: unknown, index: number) => index + 1)}
        strategy={rectSortingStrategy}
      >
        <div className="flex flex-wrap">{props.children}</div>
      </SortableContext>
    </DndContext>
  );
};
