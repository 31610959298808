import DOMPurify from 'dompurify';
import React from 'react';
import { projectsPlaceholders } from './utils/projectsPlaceholders';

export default function Card(props) {
  const usePlaceholder = (e) => {
    e.target.src = `https://app.everviz.com/${props.placeholder?.icon ?? projectsPlaceholders[1].icon}`;
    e.target.className = 'object-scale-down object-center h-full w-full p-6';
  };
  return (
    <>
      <div
        className={`project-card font-medium ${props.className} ${
          props.highlighted ? 'z-[1000000] pointer-events-none' : ''
        }`}
      >
        <div className="flex flex-col relative space-y-2 h-full">
          <div>
            <figure className="group rounded-md relative h-40 cursor-pointer overflow-hidden">
              <props.href>
                <img className="lazy" draggable="false" src={props.thumbnail} onError={(e) => usePlaceholder(e)} />
                {props.hideHoverDescriptor && (
                  <span className="group-hover:opacity-100 opacity-0 transition transform px-4 py-1 mr-2 mb-2 absolute bottom-0 right-0 bg-opacity-80 bg-gray-900 text-2xs font-bold text-white rounded-full">
                    EDIT
                  </span>
                )}
              </props.href>
            </figure>

            <div className="h-14 mb-2">
              <props.href className="text-gray-800 text-lg no-underline hover:underline h-14 mb-2 line-clamp-2">
                <span
                  className="w-fit line-clamp-2"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.title)
                  }}
                  title={props.title}
                />
              </props.href>
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
}
