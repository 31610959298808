import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import TextButton from 'shared/buttons/TextButton';
import { SelectItem } from 'shared/inputs/CustomSelect';
import AddIcon from 'static/icons/add.svg';
import { addSeriesTypes } from '../utils/seriesHelper';
import useCategoriesAndSeries from '../utils/useCategoriesAndSeries';
import SeriesTab from './SeriesTab';

type SeriesTabProps = {
  setActiveColumns: (indexes: number[], series: number[]) => void;
  onMouseLeave: () => void;
  isAlternativeMapping: boolean;
  allowAddSeries: boolean;
  type: string;
  seriesTypes: string[];
};

const SeriesSection = (props: SeriesTabProps) => {
  const dispatch = useDispatch();
  const projectConfig = useSelector((state: RootState) => state.projectConfig);

  const showOnlyOneSeriesInData = projectConfig.templateMeta?.[0]?.showOnlyOneSeriesInData;
  const seriesAssigns = showOnlyOneSeriesInData ? [projectConfig.seriesAssigns[0]] : projectConfig.seriesAssigns;

  const [activeTab, setActiveTab] = useState(-1);
  const { series, columns } = useCategoriesAndSeries();

  const options: SelectItem[] = columns.reduce((options, value, index) => {
    options.push({ label: `Column ${value}`, value, index });
    return options;
  }, [] as SelectItem[]);

  const addSeries = () => dispatch(addSeriesTypes[props.type]({}));
  return (
    <>
      {seriesAssigns.map((s, i) => {
        if (props.isAlternativeMapping && i === 0) return null;

        return (
          <SeriesTab
            options={options}
            assignOptions={s}
            index={i}
            key={`series_${i}`}
            setActiveColumns={props.setActiveColumns}
            letter={series[i]}
            moreThanOneSeries={seriesAssigns.length > 1}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onMouseLeave={props.onMouseLeave}
            type={props.type}
            seriesTypes={props.seriesTypes}
          />
        );
      })}

      {props.allowAddSeries && (
        <div className="flex items-center justify-end pr-1 py-3">
          <TextButton text="Add series" onClick={addSeries} className="text-sm" />
          <SvgIconButton
            width={12}
            height={12}
            onClick={addSeries}
            buttonClasses="ml-2 p-2 rounded-full"
            Icon={AddIcon}
            buttonColor={ButtonColor.NavyBlue}
          />
        </div>
      )}
    </>
  );
};

export default SeriesSection;
