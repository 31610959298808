import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LockedIcon from 'static/icons/locked.svg';
import UnlockedIcon from 'static/icons/unlocked.svg';

export enum VizCardSize {
  Small = 'Small',
  Regular = 'Regular',
  Large = 'Large'
}

interface VizCardClasses {
  navLink: string;
  icon: string;
  text: string;
  disabledText: string;
}

type VizCardProps = {
  path: string;
  title: string;
  icon: React.ReactNode;
  size: VizCardSize;
  disabled?: boolean;
  iconClass?: string;
  disabledText?: string | React.ReactNode;
  displayUnlocked?: boolean;
};

const getClassesForCardSize = (size: VizCardSize): VizCardClasses => {
  switch (size) {
    case VizCardSize.Large:
      return {
        navLink: 'content-centered flex-col rounded-2xl text-2xl sm:w-44 sm:h-40 w-36 h-32 shadow-ev-standard',
        icon: 'sm:pb-4 sm:h-16 sm:w-14 w-12 pb-2',
        text: 'pt-2 text-base font-bold',
        disabledText: 'absolute left-2 right-2 bottom-8 italic text-lg text-center'
      };
    case VizCardSize.Regular:
      return {
        navLink: 'flex flex-col items-center p-4 rounded text-lg h-24 w-full shadow',
        icon: 'h-9 w-9',
        text: 'pt-2 text-sm font-medium',
        disabledText: 'absolute top-12 italic text-xs text-center'
      };
    default:
      return {} as VizCardClasses;
  }
};

export default function VizCard(props: VizCardProps) {
  const [displayOverLay, setDisplayOverLay] = useState(false);
  const [displayUnlocked, setDisplayUnlocked] = useState(false);

  // regular icon and title are not displayed for disabled onhover and for the unlocked animation
  const isDisplayed = props.disabled ? props.disabled && !displayOverLay : !displayUnlocked;

  const navLinkClasses = classNames(
    'group relative text-ev-navy-blue-2 no-underline',
    getClassesForCardSize(props.size).navLink,
    {
      'bg-white hover:bg-ev-baby-blue-2 active:bg-ev-navy-blue-2 active:text-white': !displayUnlocked && !props.disabled
    },
    { 'bg-ev-navy-blue-2': displayUnlocked },
    { 'bg-ev-yellow-6 border-dashed border-2 shadow-none border-grey hover:bg-ev-yellow-6': props.disabled }
  );
  const iconClasses = classNames(getClassesForCardSize(props.size).icon, props.iconClass);

  useEffect(() => {
    if (props.displayUnlocked) {
      const uiOptions = localStorage.getItem('uiOptions');
      const alreadyDisplayed = uiOptions && JSON.parse(uiOptions).unlockAnimationDisplayed;
      if (!alreadyDisplayed) {
        setDisplayUnlocked(true);
        setTimeout(() => setDisplayUnlocked(false), 5000);
        localStorage.setItem('uiOptions', JSON.stringify({ unlockAnimationDisplayed: true }));
      }
    }
  }, [props.displayUnlocked]);

  return (
    <NavLink
      className={navLinkClasses}
      to={props.disabled ? '#' : props.path}
      onMouseDown={() => setDisplayOverLay(false)}
      onMouseLeave={() => setDisplayOverLay(false)}
      onMouseOver={() => setDisplayOverLay(true)}
    >
      {isDisplayed && <div className={iconClasses}>{props.icon}</div>}
      {isDisplayed && <div className={getClassesForCardSize(props.size).text}>{props.title}</div>}

      {/* absolute elements for onhover/disabled/unlocked status */}
      {props.disabled && <LockedIcon className="absolute top-1 right-1/2 translate-x-1/2" />}
      {displayUnlocked && <UnlockedIcon className="absolute top-1" />}
      {displayUnlocked && (
        <div className="absolute top-12 w-4/5 text-sm text-center text-white italic">Layout unlocked</div>
      )}
      {displayOverLay && props.disabled && (
        <div className={getClassesForCardSize(props.size).disabledText}>{props.disabledText}</div>
      )}
      {!props.disabled && (
        <div
          className={`absolute top-2 left-2 rounded-full w-6 h-6 text-center ${
            displayOverLay ? 'bg-ev-baby-blue-3' : 'bg-transparent'
          }`}
        >
          <i className={`fa fa-plus text-[11px] ${displayOverLay ? 'text-ev-navy-blue-2' : 'text-transparent'}`} />
        </div>
      )}
    </NavLink>
  );
}
