/*

    en Language Pack for the Highcharts Editor

    This file was generated by tools/gen.localization.js from
    en.json, Thu Mar 15 2018 10:04:15 GMT+0100 (CET)

*/

export default {
  language: 'en',
  entries: {
    confirmNewChart: 'Are you sure you want to abandon the current chart and start over?',
    previewChart: 'Preview chart',
    newChart: 'New chart',
    saveProject: 'Save project',
    loadProject: 'Load project',
    exportPNG: 'Export as PNG',
    exportJPEG: 'Export as JPEG',
    exportSVG: 'Export as SVG',
    exportPDF: 'Export as PDF',
    loadCloud: 'Load from cloud',
    saveCloud: 'Save to cloud',
    help: 'Help',
    licenseInfo: 'License information',
    stepDone: 'Done',
    stepStart: 'Start',
    stepImport: 'Import',
    stepTemplates: 'Templates',
    stepCustomize: 'Customize',
    stepExport: 'Export',
    stepData: 'Data',
    doneCaption: 'Close & Generate Chart',
    dgDeleteRow: 'Really delete the selected rows?',
    dgWithSelected: 'With selection:',
    dgImportBtn: 'IMPORT',
    dgExportBtn: 'EXPORT DATA',
    dgNewBtn: 'Clear',
    dgAddRow: 'ADD ROW',
    dgDataImported: 'Data imported',
    dgDataImporting: 'Importing data',
    dgNewCol: 'New column',
    dgSortAsc: 'Sort ascending',
    dgSortDec: 'Sort descending',
    dgSortAscMonth: 'Sort as month names ascending',
    dgSortDecMonth: 'Sort as month names decending',
    dgDelCol: 'Delete column',
    dgDelColConfirm: 'Really delete the column?',
    dgInsColBefore: 'Insert column before',
    dgInsColAfter: 'Insert column after',
    customizeSimple: 'SIMPLE',
    customizeAdvanced: 'ADVANCED',
    customizeCustomCode: 'CUSTOM CODE',
    customizePreview: 'PREVIEW OPTIONS',

    'option.cat.data-labels': 'Data labels',
    'option.cat.annotations': 'Annotations',
    'option.cat.animation': 'Animation',

    'option.text.dataLabels.style.textOutline': 'Label outline',
    'option.text.dataLabels.color': 'Label style',
    'option.text.dataLabels.format': 'Label format',
    'option.text.dataLabels.decimals': 'Decimals shown',
    'option.text.dataLabels.align': 'Label alignment',

    'option.text.dataLabels.style': 'Text font',
    'option.text.dataLabels.backgroundColor': 'Background color',
    'option.text.dataLabels.borderColor': 'Border color',
    'option.text.dataLabels.borderWidth': 'Border size',
    'option.text.dataLabels.borderRadius': 'Corner radius',

    'option.text.plotOptions.series.label.enabled': 'Enable series labels',

    'option.text.accessibility.enabled': 'Enable accessibility',
    'option.text.accessibility.description': 'Description',
    'option.text.title.align': 'Align title',
    'option.text.subtitle.align': 'Align subtitle',
    'option.text.caption.align': 'Align caption',
    'option.util.empty': '',
    'option.cat.interactivity': 'Interactivity',
    'option.cat.accessibility': 'Accessibility',
    'option.cat.text': 'Text',
    'option.cat.title': 'Titles',
    'option.cat.chart': 'Chart',
    'option.cat.chartspecific': 'Chart type specific',
    'option.cat.markers': 'Markers',
    'option.cat.map-layers': 'Map layers',
    'option.subcat.zoom': 'Zoom',
    'option.subcat.general': 'General',
    'option.subcat.exporting': 'Exporting',
    'option.subcat.text': '',
    'option.choose.plotband.text': 'Choose type',
    'option.subcat.colorAndBorder': 'Borders and colors',
    'option.subcat.colors': 'Chart colors',
    'option.subcat.dimension': 'Dimensions',
    'option.subcat.chartprops': 'Chart properties',
    'option.subcat.chartspecific': 'Chart type specific',
    'option.subcat.title': 'Title',
    'option.subcat.appearance': 'Appearance',
    'option.subcat.tooltip': 'Tooltip',
    'option.subcat.credit': 'Credits',
    'option.subcat.titles': 'Main titles',
    'option.cat.general': 'General',
    'option.cat.dataLabels': 'Data labels',
    'option.cat.plotBands': 'Plot Bands',
    'option.subcat.size': 'Chart size',
    'option.subcat.interaction': 'Interaction',
    'option.cat.appearance': 'Appearance styles',
    'option.subcat.fonts': 'Fonts',
    'option.subcat.titlestyle': 'Title style',
    'option.subcat.seriescolors': 'Series colors',
    'option.subcat.chartarea': 'Chart area',
    'option.subcat.plotarea': 'Plot area',
    'option.cat.axes': 'Axes',
    'option.subcat.axessetup': 'Axes setup',
    'option.subcat.xaxis': 'Horizontal axis',
    'option.subcat.yaxis': 'Vertical axis',
    'option.subcat.secondaxis': 'Second axis',
    'option.cat.series': 'Data series',
    'option.cat.labels': 'Value labels',
    'option.subcat.labels': 'Value labels',
    'option.cat.legend': 'Legend',
    'option.subcat.placement': 'Placement',
    'option.subcat.legendappearance': 'Appearance',
    'option.cat.tooltip': 'Tooltip',
    'option.subcat.colorborder': 'Color and border',
    'option.cat.export': 'Context menu options',
    'option.cat.exporting': 'Exporting',
    'option.cat.localization': 'Localization',
    'option.subcat.numberformat': 'Number formatting',
    'option.subcat.exportbutton': 'Exporting menu',
    'option.subcat.zoombutton': 'Zoom button',
    'option.subcat.caption': 'Caption',
    'option.cat.credits': 'Credits',
    'option.series.label': 'Series labels',

    'option.text.text': 'Text',
    'option.text.style': 'Text style',
    'option.text.align': 'Text align',
    'option.text.margin': 'Text margin',
    'option.text.enabled': 'Text enabled',
    'option.text.href': 'Source link',
    'option.text.series.label.enabled': 'Series label',
    'option.text.series.label.style': 'Series label style',
    'option.text.title.text': 'Title',
    'option.text.subtitle.text': 'Subtitle',
    'option.text.caption.text': 'Caption',
    'option.text.credits.text': 'Source',
    'option.text.xAxis-title.text': 'X-axis',
    'option.text.xAxis.enabled': 'Visibility',
    'option.text.yAxis-title.text': 'Y-axis',
    'option.text.yAxis.enabled': 'Visibility',
    'option.text.yAxis.lineWidth': 'Line Width',
    'option.text.yAxis.lineColor': 'Line Color',
    'option.text.yAxis.tickWidth': 'Show Ticks',
    'option.text.yAxis.labels.style': 'Label Style',
    'option.text.yAxis.gridLineWidth': 'Gridline width',
    'option.text.yAxis.gridLineColor': 'Gridline color',
    'option.text.yAxis.gridLineDashStyle': 'Gridline Style',
    'option.text.yAxis.title.align': 'Alignment title',
    'option.text.yAxis.min': 'Min value',
    'option.text.yAxis.max': 'Max value',
    'option.text.xAxis.lineWidth': 'Line Width',
    'option.text.xAxis.lineColor': 'Line Color',
    'option.text.xAxis.tickWidth': 'Show Ticks',
    'option.text.xAxis.labels.style': 'Label Style',
    'option.text.xAxis.gridLineWidth': 'Gridline width',
    'option.text.xAxis.gridLineColor': 'Gridline color',
    'option.text.xAxis.gridLineDashStyle': 'Gridline Style',
    'option.text.xAxis.title.align': 'Alignment title',
    'option.text.chart.width': 'Chart width',
    'option.text.chart.height': 'Chart height',
    'option.text.mapNavigation.enabled': 'Enable map navigation',
    'option.text.mapNavigation.enableMouseWheelZoom': 'Enable mouse wheel zoom',
    'option.text.chart.zoomType': 'Allow zooming',
    'option.text.plotOptions.series.states.inactive.opacity': 'Series dimming',
    'option.text.chart.polar': 'Polar (radar) projection',
    'option.text.chart.style': 'Text font',
    'option.text.title.style': 'Main title style',
    'option.text.subtitle.style': 'Subtitle style',
    'option.text.colors': 'Click the corresponding colors to edit.',
    'option.text.chart.backgroundColor': 'Background color',
    'option.text.colorAxis.stops': 'Color stops',
    'option.text.plotOptions.series.minSize': 'Min size',
    'option.text.plotOptions.series.maxSize': 'Max size',
    'option.text.colorAxis.dataClasses': 'Color stops',
    'option.text.yAxis.stops': 'Color stops',
    'option.text.plotOptions.mappoint.marker.symbol': 'Point symbol',
    'option.text.plotOptions.mappoint.marker.radius': 'Point size',
    'option.text.plotOptions.series.color': 'Point color',
    'option.text.chart.spacing': 'Spacing (px)',
    'option.text.chart.borderWidth': 'Border size',
    'option.text.chart.borderRadius': 'Corner radius',
    'option.text.chart.borderColor': 'Border color',
    'option.text.chart.plotBackgroundColor': 'Plot area color',
    'option.text.everviz.chart.backgroundImage': 'Background image URL',
    'option.text.chart.plotBorderWidth': 'Plot area border size',
    'option.text.chart.plotBorderColor': 'Plot area border',
    'option.text.chart.plotBorderRadius': '',
    'option.text.chart.orientation': 'Direction',
    'option.text.chart.inverted': 'Inverted axes',
    'option.text.xAxis.title.style': 'X axis title',
    'option.text.xAxis.type': 'Type',
    'option.text.xAxis.placement': 'Placement',
    'option.text.xAxis.margin': 'Margin',
    'option.text.xAxis.direction': 'Direction',
    'option.text.xAxis.labels.format': 'Axis labels format',
    'option.text.yAxis.title.style': 'Y axis title style',
    'option.text.yAxis.type': 'Type',
    'option.text.yAxis.placement': 'Placement',
    'option.text.yAxis.secondAxis': 'Use second axis',
    'option.text.yAxis.direction': 'Direction',
    'option.text.yAxis.labels.format': 'Axis labels format',
    'option.text.yAxis.series': 'Assign series to Y axis',
    'option.text.series.type': 'Series type',
    'option.text.plotOptions.series.groupPadding': 'Bar padding',
    'option.text.series.color': 'Series color',
    'option.text.series.dumbbell.color': 'High color',
    'option.text.series.negativeColor': 'Negative values',
    'option.text.series.colorByPoint': 'Color by point',
    'option.text.series.borderWidth': 'Border size',
    'option.text.plotOptions.dumbbell.lowColor': 'Low color',
    'option.text.plotOptions.dumbbell.marker.radius': 'Point radius',

    'option.text.plotOptions.packedbubble.layoutAlgorithm.splitSeries': 'Grouped',

    'option.text.plotOptions.packedbubble.layouttype': 'Layout type',
    'option.text.plotOptions.packedbubble.minSize': 'Min size (%)',
    'option.text.plotOptions.packedbubble.maxSize': 'Max size (%)',
    'option.text.plotOptions.packedbubble.useSimulation': 'Show as spiral',

    'option.text.plotOptions.pie.dataLabels.distance': 'Label distance',
    'option.text.series.borderColor': 'Border color',
    'option.text.series.dashStyle': 'Border style',
    'option.text.series.borderDashStyle': 'Border style',
    'option.text.plotOptions.map.borderWidth': 'Outline size',
    'option.text.colorAxis.type': 'Axis type',
    'option.text.series.marker.enabled': 'Enable point markers',
    'option.text.series.marker.symbol': 'Point markers',
    'option.text.plotOptions.series.animation': 'Enable animation',
    'option.text.plotOptions.series.animation.duration': 'Animation duration (ms)',

    'option.text.play.animation': 'Play animation',

    'option.text.everviz.animation.loadOnPageLoad': 'Load chart on page load',
    'option.text.everviz.animation.scaleToFit': 'Scale to fit',
    'option.text.dataLabels.enabled': 'Visibility',
    'option.text.plotOptions.series.dataLabels.style': 'Text style',
    'option.text.legend.enabled': 'Visibility',
    'option.text.legend.layout': 'Layout',
    'option.text.legend.labelFormat': 'Label format',
    'option.text.textStyle.legendPlacement': 'Placement',
    'option.text.legend.x': 'Horizontal offset',
    'option.text.legend.y': 'Vertical offset',
    'option.text.legend.floating': 'Float on top of plot area',
    'option.text.legend.itemStyle': 'Text font',
    'option.text.legend.itemFontStyle': 'Text style',
    'option.text.legend.itemHiddenStyle': 'Text style hidden',
    'option.text.legend.backgroundColor': 'Background color',
    'option.text.legend.borderWidth': 'Border size',
    'option.text.legend.borderRadius': 'Corner radius',
    'option.text.legend.borderColor': 'Border color',
    'option.text.tooltip.enabled': 'Enable tooltip',
    'option.text.tooltip.shared': 'Show all series',
    'option.text.tooltip.valueDecimals': 'Decimals shown',
    'option.text.tooltip.backgroundColor': 'Background',
    'option.text.tooltip.valueSuffix': 'Value suffix',
    'option.text.tooltip.valuePrefix': 'Value prefix',
    'option.text.tooltip.borderWidth': 'Border size',
    'option.text.tooltip.borderRadius': 'Corner radius',
    'option.text.tooltip.borderColor': 'Border color',
    'option.text.exporting.enabled': 'Visibility',
    'option.text.exporting.buttons.contextButton.text': 'Button text',
    'option.text.exporting.buttons.contextButton.symbol': 'Button icon',

    'option.text.exporting.localization': 'Text descriptions',
    'option.text.exporting.sourceWidth': 'Export width (px)',
    'option.text.exporting.sourceHeight': 'Export height (px)',
    'option.text.exporting.scale': 'Scaling factor (x)',
    'option.text.exporting.offlineExporting': 'Offline exporting',
    'option.text.everviz.exporting.fps': 'FPS',
    'option.text.lang.decimalPoint': 'Decimal point',
    'option.text.lang.notice': '',
    'option.text.lang.thousandsSep': 'Thousands separator',
    'option.text.lang.contextButtonTitle': 'Context button tooltip',
    'option.text.lang.printChart': 'Print chart',
    'option.text.lang.viewFullscreen': 'View in full screen',
    'option.text.lang.downloadPNG': 'Download PNG image',
    'option.text.lang.downloadJPEG': 'Download JPEG image',
    'option.text.lang.downloadPDF': 'Download PDF document',
    'option.text.lang.downloadSVG': 'Download SVG vector image',
    'option.text.lang.downloadCSV': 'Download CSV',
    'option.text.lang.downloadXLS': 'Download XLS',
    'option.text.lang.viewData': 'View data table',
    'option.text.lang.resetZoom': 'Reset zoom button',
    'option.text.credits.enabled': 'Enable credits',
    'option.text.credits.href': 'Source Link',
    'option.text.caption.useHTML': 'Use HTML',
    'option.text.caption.style': 'Text Styles',
    'option.text.caption.margin': 'Margin',
    'option.text.data.marker.symbol': 'Icons',
    'option.text.points.layout': 'Layout',
    'option.text.plotOptions-series-innerSize': 'Inner size',
    'option.tooltip.plotOptions-series-innerSize': 'Inner size (%)',
    'option.text.plotOptions-series-rows': 'Rows',
    'option.text.plotOptions-wordcloud-maxFontSize': 'Max font size',
    'option.text.plotOptions-wordcloud-minFontSize': 'Min font size',
    'option.text.data.text.amount': 'Words displayed',
    'option.text.plotOptions.item.size': 'Project size (%)',
    'option.text.plotOptions.timeline.lineWidth': 'Line width',
    'option.text.plotOptions.timeline.dataLabels.distance': 'Label distance',
    'option.text.series': 'Assign series to YAxis',
    'option.text.data-labels': 'Apply to',

    'option.text.highlight.type': 'Type',
    'option.text.highlight.orientation': 'Orientation',
    'option.text.xAxis.plotBands.from': 'From',
    'option.text.xAxis.plotBands.to': 'To',
    'option.text.plotBands.color': 'Color',
    'option.text.plotBands.type': 'Type',
    'option.text.plotBands.label.text': 'Label',
    'option.text.plotBands.label.style.color': 'Label color',
    'option.text.plotBands.label.align': 'Horizontal align',
    'option.text.plotBands.label.verticalAlign': 'Vertical align',

    'option.text.plotLines.color': 'Color',
    'option.text.plotLines.type': 'Type',
    'option.text.plotLines.label.text': 'Label',
    'option.text.plotLines.label.style.color': 'Label color',
    'option.text.plotLines.label.align': 'Horizontal align',
    'option.text.plotLines.label.verticalAlign': 'Vertical align',
    'option.text.plotLines.dashStyle': 'Dash style',
    'option.text.plotLines.width': 'Line width',

    'option.text.tooltip.text.helper': 'Tooltip helper',

    'option.text.annotation.text.labels': 'Text labels',
    'option.text.annotation.text.highlights': 'Highlights',
    'option.text.annotation.text.lines': 'Lines',

    'option.text.label.type': 'Type',
    'option.text.label.size': 'Size',
    'option.text.label.text': 'Text',
    'option.text.label.color': 'Text color',
    'option.text.line.color': 'Line color',
    'option.text.label.background.color': 'Background',

    'option.text.map.geolocateControls.enabled': 'Location controls',
    'option.text.map.scaleControls.enabled': 'Scale controls',
    'option.text.map.fullScreenControls.enabled': 'Full screen controls',
    'option.text.map.miniMap.enabled': 'Mini map',
    // 'option.text.map.data.everviz.markers.text.placement': 'Placement',
    // 'option.text.map.data.everviz.markers.icon.template': 'Icon',
    // 'option.text.map.data.everviz.markers.label.template': 'Label',
    // 'option.text.map.data.everviz.markers.icon.size': 'Size',
    // 'option.text.map.data.everviz.markers.icon.color': 'Color',
    // 'option.text.map.style.layers.disabled': 'Enabled',
    // 'option.text.map.style.layers.color': 'Color',

    'option.text.everviz.text.dynamicFonts': 'Enable dynamic fonts'
  }
};
