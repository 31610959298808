import React from 'react';
import AlertMessage from 'shared/message/AlertMessage';

const LiveDataMessage = () => {
  return (
    <AlertMessage
      type="warning"
      text={
        <>
          <b>Read-only</b> — Data from a linked datasheet can not be edited.
        </>
      }
      dismissable={false}
      hideTag={false}
      extraClass="bg-ev-yellow-4 rounded py-2 px-4 mb-4 text-ev-navy-blue"
    />
  );
};

export default LiveDataMessage;
