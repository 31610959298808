import actionTypes from '../../../redux/actions/action-types';

export const getTeamWebhooksAction = (data) => ({
  type: actionTypes.teamWebhooksPage.getWebhooks,
  data,
});

export const setTeamWebhookAction = (data) => ({
  type: actionTypes.teamWebhooksPage.setWebhook,
  data,
});

export const getSupportedWebhooksAction = (data) => ({
  type: actionTypes.teamWebhooksPage.getSupportedWebhooks,
  data,
});

export const updateWebhookAction = (data) => ({
  type: actionTypes.teamWebhooksPage.updateWebhook,
  data,
});

export const deleteWebhooksAction = (data) => ({
  type: actionTypes.teamWebhooksPage.deleteWebhooks,
  data,
});

export const setAction = (data) => ({
  type: actionTypes.teamWebhooksPage.set,
  data,
});
