import classNames from 'classnames';
import React from 'react';

type LeftWizardProps = {
  header: string;
  children: React.ReactNode;
  text?: string;
  subheader?: string;
  useWhiteBackground?: boolean;
  contentClass?: string;
  extraElements?: React.ReactNode;
};

const WizardLeftContainer = (props: LeftWizardProps) => {
  const containerClasses = classNames('mx-6 mb-6 ev-sm:m-0 h-fit ev-sm:overflow-auto', {
    'bg-ev-grey rounded p-1': !props.useWhiteBackground
  });

  const contentClasses = classNames(props.contentClass, 'flex flex-col gap-2 ev-sm-max:overflow-auto', {
    'px-6 pb-4': !props.useWhiteBackground
  });

  return (
    <div className="ev-sm:flex flex-col relative ev-sm:w-80 ev-sm-max:max-h-[calc(100vh-500px)] ev-sm-max:overflow-auto shrink-0 text-left mx-2">
      <div className="p-6 flex flex-col">
        <h3 className="font-bold m-0 whitespace-nowrap">{props.header}</h3>
        {props.text && <p className="text-ev-navy-blue pt-2">{props.text}</p>}
      </div>
      {props.extraElements && <div className="mb-4">{props.extraElements}</div>}

      <div className={containerClasses}>
        <div className={contentClasses}>
          {props.subheader && <h4 className="font-bold text-ev-navy-blue-2 my-4">{props.subheader}</h4>}
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default WizardLeftContainer;
