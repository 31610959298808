import paths from 'paths';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import { openPaidPlanModalAction } from '../../redux/actions/app';
import { checkPermission, checkPlanPermission } from '../../utils/profileHelper';
import { getCompanyThemeAction, getTeamThemes as getTeamThemesAction, setAction } from './actions/themesPage';
import ChartSpecificThemesSection from './components/ChartSpecificThemesSection';
import CompanyThemesSection from './components/CompanyThemesSection';
import DefaultThemesSection from './components/DefaultThemesSection';

type ThemesPageProps = {
  deleted?: boolean;
};

export default function ThemesPage(props: ThemesPageProps) {
  const { deleted } = props;
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.profile);
  const history = useHistory();
  const { team, division } = profile;
  const { page: themeSetPage, pageSize, query } = useSelector((state: RootState) => state.themesPage);
  const themeManagementPerm = checkPermission('theme_management', profile);
  const hasPermission = checkPlanPermission('custom_themes', profile) || false;
  const isLocked = hasPermission !== undefined && !hasPermission;

  useEffect(() => {
    if (checkPermission('theme_management')) loadThemes();
    return () => {
      set({ dataReturned: false });
    };
  }, []);

  useEffect(() => {
    if (!themeManagementPerm) return;
    loadThemes();
  }, [team, division, deleted, themeManagementPerm]);

  const getTeamThemes = (page?: number) =>
    dispatch(
      getTeamThemesAction({
        team,
        division,
        query,
        deleted,
        paths,
        page,
        pageSize
      })
    );

  const set = (params: any) => dispatch(setAction(params));
  const getCompanyTheme = () => dispatch(getCompanyThemeAction({ team, deleted }));
  const showPaidPlanModal = () => dispatch(openPaidPlanModalAction({ paidPlanType: 'business' }));

  const loadThemes = (page?: number) => {
    getTeamThemes(page || themeSetPage);
    getCompanyTheme();
  };

  const checkHasPermission = (e: any) => {
    const hasPermission = checkPlanPermission('custom_themes', profile) || false;
    if (!hasPermission) {
      e.preventDefault();
      showPaidPlanModal();
    }
  };

  const toggleDeletedFilter = () => {
    if (isLocked) {
      showPaidPlanModal();
      return;
    }
    history.push(deleted ? paths.profileTeamThemes : paths.profileTeamThemes + '/deleted');
  };

  return (
    <ProfilePageContainer
      title="Themes"
      header={
        <>
          Themes
          <div className="float-right">
            <PrimaryButton
              showSubscriptionLock={isLocked}
              text={deleted ? ' View all themes' : ' View deleted themes'}
              onClick={toggleDeletedFilter}
              buttonColor={ButtonColor.WhiteBordered}
              className="text-base"
            />
          </div>
        </>
      }
      description={
        <>
          Themes allow you to brand your charts in a company style with ease. You can save a project as a chart specific
          theme, or set up a master template, which you can define as default when creating a new project in your team.
        </>
      }
    >
      <CompanyThemesSection
        showPaidPlanModal={showPaidPlanModal}
        hasPermission={hasPermission}
        checkHasPermission={checkHasPermission}
        deleted={deleted}
        getCompanyTheme={getCompanyTheme}
      />

      <ProfilePageSectionContainer>
        <ChartSpecificThemesSection
          loadThemes={loadThemes}
          hasPermission={hasPermission}
          checkHasPermission={checkHasPermission}
          showPaidPlanModal={showPaidPlanModal}
          deleted={deleted}
        />

        {!deleted && (
          <DefaultThemesSection
            loadThemes={loadThemes}
            hasPermission={hasPermission}
            showPaidPlanModal={showPaidPlanModal}
            checkHasPermission={checkHasPermission}
          />
        )}
      </ProfilePageSectionContainer>
    </ProfilePageContainer>
  );
}
