import { TemplateProps } from '../../types';

const NegativeColor: TemplateProps = {
  title: 'Negative color',
  description: '',
  constructor: '',
  thumbnail: 'uxyfys.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'line',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        negativeColor: '#FF0000'
      }
    }
  },
  sampleSet: 'line',
  advancedParent: 'Line',
  dataValidator: false,
  defaultDataType: 'negative'
};

export default NegativeColor;
