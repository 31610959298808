export function rowsToColumns(rows) {
  let row, rowsLength, col, colsLength, columns;

  if (rows) {
    columns = [];
    rowsLength = rows.length;
    for (row = 0; row < rowsLength; row++) {
      colsLength = rows[row].length;
      for (col = 0; col < colsLength; col++) {
        if (!columns[col]) {
          columns[col] = [];
        }
        columns[col][row] = rows[row][col];
      }
    }
  }
  return columns;
}

export function shuffleArray(arr, min, amount, moveTo) {
  let x = arr.splice(min, amount);
  let args = [moveTo, 0].concat(x);
  Array.prototype.splice.apply(arr, args);
}
