import * as React from 'react';
import { get } from 'lodash';

import AddIcon from 'static/icons/add.svg';
import BinIcon from 'static/icons/bin.svg';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import AccordionWidget from './AccordionWidget';
import AccordionButton from './AccordionButton';
import useToggle from 'shared/utils/useToggle';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomizedBulkAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { RootState } from 'redux/store';

type ClickAction = 'add' | 'delete';

const DynamicAccordionWidget = (props: GenericInputProps) => {
  const { customizedOptions } = useSelector((state: RootState) => state.projectConfig);
  const dispatch = useDispatch();
  const key = props.option?.onChangeKey as string;
  const [displayAccordion, toggleDisplayAccordion] = useToggle(Boolean(get(props.aggregatedOptions, key)));

  const handleClick = (action: ClickAction) => {
    let options;
    if (action === 'add' && props.option?.onOptionsChange) {
      options = props.option?.onOptionsChange(customizedOptions);
    } else if (action === 'delete' && props.option?.onHide) {
      options = props.option?.onHide(customizedOptions);
    }
    dispatch(updateCustomizedBulkAction({ options }));
    toggleDisplayAccordion();
  };

  return (
    <>
      {displayAccordion && (
        <AccordionWidget {...props}>
          <AccordionButton
            handleClick={() => handleClick('delete')}
            label="Delete the second axis"
            size={16}
            buttonIcon={BinIcon}
            buttonColor={ButtonColor.White}
            iconClasses="p-2 rounded-full mx-2"
          />
        </AccordionWidget>
      )}
      {!displayAccordion && (
        <AccordionButton
          handleClick={() => handleClick('add')}
          label="Add a second axis"
          size={12}
          buttonIcon={AddIcon}
          buttonColor={ButtonColor.NavyBlue}
          iconClasses="p-2 rounded-full mx-2"
        />
      )}
    </>
  );
};

export default DynamicAccordionWidget;
