/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { redrawProjectAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';
import { removeHighchartsStyling } from 'pages/ChartEditorPage/utils/removeHighchartsStyling';
import TableComponent from 'pages/TableEditorPage/components/TableComponent';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import IconButton from 'shared/buttons/IconButton';
import { useIsMobile } from 'shared/utils/mediaHelper';
import { hideElementHideButton } from 'shared/wizard/editor/HideElementButton';
import { resetChartDataTableView } from '../utils/chartPreviewHelper';
import HighchartsPreview from './HighchartsPreview';
import LocationMapPreview from './LocationMapPreview';

export type DataGridPreviewProps = {
  activeSeries: number[];
  isTable?: boolean;
  redrawOnRender?: boolean;
  large?: boolean;
  isPastingActive?: boolean;
  parent?: any;
  inEditor?: boolean;
  disableTransparentGrid?: boolean;
};

const packages = {
  afbilel2i: 'https://app-dev.everviz.com/election-2/seatdistribution.html',
  iJk5IjmRH: 'https://app-dev.everviz.com/election-2/map.html',
  ZNHDT29wN: 'https://app-dev.everviz.com/election-2/race.html',
  PpgclFHSw: 'https://app-dev.everviz.com/election-2/parliament-round.html',
  LjD4AOeWs: 'https://app-dev.everviz.com/election-2/parliament.html',
  H8JfDfVLh: 'https://app.everviz.com/embed/H8JfDfVLh'
};

const ChartPreview = ({
  activeSeries,
  isTable,
  redrawOnRender,
  large,
  isPastingActive,
  parent,
  inEditor
}: DataGridPreviewProps) => {
  const chartPreviewRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [previewSizes, setPreviewSizes] = useState({
    small: { width: 300, height: 250 },
    regular: { width: 400, height: 350 },
    minimized: { width: 24, height: 24 },
    large: { width: '100%', height: '100%' }
  });
  const [chartOpen, setChartOpen] = useState(true);
  const [chartSize, setChartSize] = useState(large ? previewSizes.large : previewSizes.small);
  const { tab, provider, uuid, inPackagesMode } = useSelector((state: RootState) => state.projectConfig);
  const { isMap, initedEditor } = useSelector<any, any>((state: RootState) => state.chartEditorPage);

  const chart = useChart();

  const isSmall = chartSize === previewSizes.small;
  const inDataTab = tab === 'data';
  const isDataTabOnMobile = isMobile && inDataTab;

  useEffect(() => setChartSize(large ? previewSizes.large : previewSizes.small), [large]);
  useEffect(() => {
    hideElementHideButton();
  }, [chartSize]);

  useEffect(() => {
    if (inPackagesMode) {
      if (uuid.toString() in packages) {
        const packageProject = packages[uuid.toString() as keyof typeof packages];

        const iframe = document.createElement('iframe');
        iframe.src = packageProject;
        iframe.allowFullscreen = true;
        iframe.style.transform = 'scale(0.8)';
        iframe.style.transformOrigin = '0 0';
        iframe.style.width = '125%';
        iframe.style.height = '700px';

        const container = document.getElementById(`everviz-${uuid}`);
        if (container) container.appendChild(iframe);
      }
    }
  }, [uuid]);

  useEffect(() => {
    if (isMap) return;
    if (chart?.series) {
      chart.series.forEach((s: any, i: number) => {
        if (activeSeries.length) {
          if (activeSeries.includes(i)) s.setState('hover');
          else s.setState('inactive');
        } else s.setState('normal');
      });
    }
  }, [activeSeries]);

  useEffect(() => {
    if (redrawOnRender) {
      if (tab === 'data' && provider !== 'locationMap') {
        resetChartDataTableView();

        if (parent?.current) {
          const smallWidth = parent.current.offsetWidth * 0.25;
          const regularWidth = parent.current.offsetWidth * 0.4;
          const small = { width: smallWidth, height: (250 / 300) * smallWidth };
          const regular = { width: regularWidth, height: (350 / 400) * regularWidth };

          setPreviewSizes({
            ...previewSizes,
            small,
            regular
          });

          setChartSize(small);
        }
      }
      hideElementHideButton();
      if (isMobile) dispatch(redrawProjectAction({}));
    }
  }, [tab, provider]);

  useEffect(() => {
    if (redrawOnRender) {
      dispatch(redrawProjectAction({}));
    }
  }, []);

  useEffect(() => {
    // If the chart fails, Highcharts will add a display: none to the container
    // This function then stops Handsontable from displaying the data table.
    removeHighchartsStyling(chart);
  }, [chart]);

  const containerClass = classNames('transition-all absolute bottom-[0px] right-[0px]', {
    'z-[100000]': isPastingActive,
    hidden: (!inEditor && isMobile) || (inEditor && isDataTabOnMobile),
    'transparent-grid': !isTable,
    'bg-white': isTable,
    'z-[1]': provider === 'highcharts'
  });

  return (
    <div className={containerClass} style={chartSize} ref={chartPreviewRef}>
      {inPackagesMode && <div id={`everviz-${uuid}`} className={`everviz-${uuid}`} />}

      {!inPackagesMode && (
        <>
          {provider === 'locationMap' && initedEditor && <LocationMapPreview />}
          {!inPackagesMode && !isTable && provider !== 'locationMap' && (
            <HighchartsPreview className=" w-full h-full" />
          )}

          {isTable && (
            <div className={`preview-table ${isSmall ? ' small' : ' regular'}`}>{chartOpen && <TableComponent />}</div>
          )}

          {!large && chartOpen && (
            <IconButton
              icon="arrows-up-down"
              iconClass="-rotate-45"
              className="absolute top-0 left-0 w-6 h-6 bg-ev-grey"
              onClick={() => {
                setChartSize(chartSize === previewSizes.small ? previewSizes.regular : previewSizes.small);
              }}
            />
          )}
          {!large && (
            <IconButton
              icon={chartOpen ? 'xmark' : 'eye'}
              className="absolute bottom-0 right-0 w-6 h-6 bg-ev-grey"
              onClick={() => {
                setChartOpen(!chartOpen);
                setChartSize(chartOpen ? previewSizes.minimized : previewSizes.small);
              }}
              active={chartOpen}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ChartPreview;
