import { TemplateProps } from './../types';

const AreaStock: TemplateProps = {
  title: 'Area stock',
  description: '',
  constructor: 'StockChart',
  thumbnail: 'ukaqor.svg',
  icon: '',
  sampleSets: ['line-series-dates'],
  validator: false,
  config: {
    chart: {
      type: 'area',
      polar: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        },
        lineWidth: 2
      }
    },
    rangeSelector: {
      enabled: true
    }
  },
  sampleSet: 'stock',
  dataValidator: false,
  advancedParent: 'Stock',
  defaultDataType: 'stock-line'
};

export default AreaStock;
