import actionTypes from 'redux/actions/action-types';

export const setAction = (data) => ({
  type: actionTypes.rolesPage.set,
  data
});

export const getPermissionsAction = () => ({
  type: actionTypes.rolesPage.getPermissions
});

export const getRolePermissionsAction = (data) => ({
  type: actionTypes.rolesPage.getRolePermissions,
  data
});

export const addRoleAction = (data) => ({
  type: actionTypes.rolesPage.addRole,
  data
});

export const updateRoleAction = (data) => ({
  type: actionTypes.rolesPage.updateRole,
  data
});

export const deleteRoleAction = (data) => ({
  type: actionTypes.rolesPage.deleteRole,
  data
});

export const setRolePermissionsAction = (data) => ({
  type: actionTypes.rolesPage.setRolePermissions,
  data
});
