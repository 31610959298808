import React from 'react';
import { GenericInputProps } from 'shared/types/commonPropTypes';

export default (props: GenericInputProps) => {
  const value = props.value || 'left';

  const onClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLTextAreaElement;
    props.onChange({ val: target.getAttribute('data-value') });
  };

  return (
    <div className={`${props.className} flex gap-1`}>
      <div
        onClick={onClick}
        data-value="left"
        className={
          'rounded inline-block w-8 h-8 pt-1 text-ev-navy-2 text-center hover:bg-ev-baby-blue-2 ' +
          (value === 'left' ? 'bg-ev-dark-blue border-ev-dark-blue text-white' : '')
        }
      >
        <i data-value="left" className="fa-solid fa-align-left" />
      </div>
      <div
        onClick={onClick}
        data-value="center"
        className={
          'rounded inline-block w-8 h-8 pt-1 text-ev-navy-2 text-center hover:bg-ev-baby-blue-2 ' +
          (value === 'center' ? 'bg-ev-dark-blue border-ev-dark-blue text-white' : '')
        }
      >
        <i data-value="center" className="fa fa-align-center" />
      </div>
      <div
        onClick={onClick}
        data-value="right"
        className={
          'rounded inline-block w-8 h-8 pt-1 text-ev-navy-2 text-center hover:bg-ev-baby-blue-2 ' +
          (value === 'right' ? 'bg-ev-dark-blue border-ev-dark-blue text-white' : '')
        }
      >
        <i data-value="right" className="fa fa-align-right" />
      </div>
    </div>
  );
};
