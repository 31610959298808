import classNames from 'classnames';
import React from 'react';

type LoadingProps = {
  text?: string;
  iconClass?: string;
  textClass?: string;
};

const Loading = ({ text, iconClass, textClass }: LoadingProps) => {
  if (!text) {
    text = 'Loading...';
  }

  const iconClasses = classNames('loading-icon fa fa-spinner fa-spin', iconClass);

  return (
    <span className="loading-widget">
      <i className={iconClasses} aria-hidden="true" />
      <span className={textClass}>{text}</span>
    </span>
  );
};

export default Loading;
