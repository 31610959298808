/* eslint-disable react/jsx-no-bind, react/jsx-key */

import React from 'react';
import { Route } from 'react-router-dom';
import { onGoogleLoggedIn, onLoggedIn } from '../utils/profileHelper'; // Remove this when fully converted

import EditChartRedirect from '../pages/EditChartRedirectPage/EditChartRedirect';
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import CallbackPage from '../pages/LoginCallbackPage/CallbackPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import InvitationPage from '../pages/RedeemInvitePage/RedeemInvitePage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import SignupPage from '../pages/SignupPage/SignupPage';
import SingleSignOn from '../pages/SingleSignOnPage/SingleSignOn';
import ThemeEditor from '../pages/ThemeEditorPage/ThemeEditor';
import VerifyEmailPage from '../pages/VerifyEmailPage/VerifyEmailPage';
import VizPicker from '../pages/VizPickerPage/VizPicker';
import { generateRoutes } from './routesHelper';

import Editor from '../pages/ChartEditorPage/Editor';
import ShowChartPage from '../pages/ShowChartPage/ShowChartPage.tsx';
import SubscriptionPage from '../pages/SubscriptionPage/SubscriptionPage';

export default function (props) {
  const {
    user,
    teams,
    team,
    subscription,
    paths,
    loading,
    permissions,
    billingPermissions,
    updateChartCount,
    setNavActions,
    updateNavActions,
    toggleMenu
  } = props;

  const checkPermission = (permission) => {
    // Kept for editor
    return permissions && (permissions[permission] || (user && team && user.id === team.owner_user));
  };

  const checkPlanPermission = (permission) => {
    return billingPermissions && billingPermissions[permission];
  };

  const anonymous = !user && !team;
  const loggedIn = typeof user === 'object';

  const editorRender = (props) => (
    <Editor
      setNavActions={setNavActions}
      updateNavActions={updateNavActions}
      user={user}
      anonymous={anonymous}
      onLoggedIn={onLoggedIn}
      checkPlanPermission={checkPlanPermission}
      subscription={subscription}
      team={team}
      updateChartCount={updateChartCount}
      {...props}
    />
  );
  const themeEditorRender = (props) => (
    <ThemeEditor
      paths={paths}
      setNavActions={setNavActions}
      user={user}
      team={team}
      checkPermission={checkPermission}
      {...props}
    />
  );

  const editorRoutes = generateRoutes(
    user,
    teams,
    team,
    toggleMenu,
    loading,
    [paths.newChart, paths.editChartLatest, paths.editChartVersion],
    editorRender,
    true,
    true,
    paths
  );

  const themeEditorRoutes = generateRoutes(
    user,
    teams,
    team,
    toggleMenu,
    loading,
    [paths.newTheme, paths.editTheme],
    themeEditorRender,
    true,
    false,
    paths
  );

  return [
    <Route
      exact={true}
      path={paths.login}
      render={(props) => <LoginPage redirect={paths.profileCharts} onLoggedIn={onLoggedIn} {...props} />}
    />,
    <Route
      exact={true}
      path={paths.sso}
      render={(props) => (
        <SingleSignOn redirect={paths.profileCharts} paths={paths} onLoggedIn={onLoggedIn} {...props} />
      )}
    />,
    <Route
      exact={true}
      path={paths.oauth}
      render={(props) => <CallbackPage paths={paths} onLoggedIn={onGoogleLoggedIn} {...props} />}
    />,
    <Route
      exact={true}
      path={paths.verify}
      render={(props) => <VerifyEmailPage public={true} user={user} paths={paths} {...props} />}
    />,

    <Route
      exact={true}
      path={paths.invitation}
      render={(props) => <InvitationPage loggedIn={loggedIn} onLoggedIn={onLoggedIn} {...props} />}
    />,

    <Route
      path={paths.signup}
      render={(props) => <SignupPage redirect={paths.profileCharts} paths={paths} onLoggedIn={onLoggedIn} {...props} />}
    />,
    <Route path={paths.forgottenPassword} render={(props) => <ForgotPasswordPage paths={paths} {...props} />} />,
    <Route path={paths.resetPassword} render={(props) => <ResetPasswordPage paths={paths} {...props} />} />,
    <Route path={paths.vizPicker} render={(props) => <VizPicker paths={paths} team={team} {...props} />} />,

    editorRoutes,
    themeEditorRoutes,
    <Route
      path={paths.chartShowVersion}
      render={(props) => (
        <ShowChartPage
          paths={paths}
          team={team}
          loggedIn={loggedIn}
          user={user}
          setNavActions={setNavActions}
          {...props}
        />
      )}
    />,
    <Route
      path={paths.editChartByHash}
      render={(props) => (
        <EditChartRedirect paths={paths} team={team} loggedIn={loggedIn} setNavActions={setNavActions} {...props} />
      )}
    />,
    <Route
      path={paths.chartShow}
      render={(props) => (
        <ShowChartPage
          paths={paths}
          team={team}
          loggedIn={loggedIn}
          user={user}
          setNavActions={setNavActions}
          {...props}
        />
      )}
    />,
    <Route
      path={paths.chartShare}
      render={(props) => (
        <ShowChartPage
          paths={paths}
          team={team}
          loggedIn={loggedIn}
          user={user}
          setNavActions={setNavActions}
          {...props}
        />
      )}
    />,
    <Route
      path={paths.packageShare}
      render={(props) => (
        <ShowChartPage
          paths={paths}
          team={team}
          loggedIn={loggedIn}
          user={user}
          setNavActions={setNavActions}
          {...props}
        />
      )}
    />,
    <Route path={paths.subscription} render={() => <SubscriptionPage />} />
  ];
}
