import React, { Component } from 'react';

export default class MergeIcon extends React.Component {
  render() {
    const { cellSettings } = this.props;
    return (
      <svg
        width="7.4083mm"
        height="6.8792mm"
        version="1.1"
        className="merge"
        viewBox="0 0 7.4083 6.8792"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-107.02 -147.38)">
          <g fill="#444" strokeWidth="0">
            <rect className="quill-icon" x="107.02" y="147.38" width="3.4439" height="1.026" />
            <rect className="quill-icon" x="110.98" y="153.23" width="3.4439" height="1.026" />
            <rect className="quill-icon" x="110.98" y="147.38" width="3.4439" height="1.026" />
            <rect className="quill-icon" x="107.02" y="153.23" width="3.4439" height="1.026" />
            <rect className="quill-icon" x="107.02" y="152.15" width="1.0766" height="2.1046" />
            <rect className="quill-icon" x="113.35" y="152.15" width="1.0766" height="2.1046" />
            <rect className="quill-icon" x="113.35" y="147.38" width="1.0766" height="2.1046" />
            <rect className="quill-icon" x="107.02" y="147.38" width="1.0766" height="2.1046" />
          </g>
          <g transform="translate(2.623 .38503)">
            <g transform="translate(.072193)">
              <rect
                className="quill-icon"
                x="104.59"
                y="149.84"
                width="1.7781"
                height="1.2532"
                fill="#444"
                strokeWidth="0"
              />
            </g>
            <path
              className="quill-icon"
              d="m106.37 149.84v-0.40122l1.1603 1.1603-1.1479 1.1478-0.0124-0.65377z"
              fill="#444"
            />
          </g>
          <g transform="matrix(-1 0 0 1 218.64 .38895)">
            <g transform="translate(.072193)">
              <rect
                className="quill-icon"
                x="104.59"
                y="149.84"
                width="1.7781"
                height="1.2532"
                fill="#444"
                strokeWidth="0"
              />
            </g>
            <path
              className="quill-icon"
              d="m106.37 149.84v-0.40122l1.1603 1.1603-1.1479 1.1478-0.0124-0.65377z"
              fill="#444"
            />
          </g>
        </g>
      </svg>
    );
  }
}
