import classNames from 'classnames';
import React from 'react';

type SubscriptionDetailsBlockProps = {
  isDark?: boolean;
  header: string;
  children: string | JSX.Element;
};

const HeaderedBlock = ({ isDark, header, children }: SubscriptionDetailsBlockProps) => {
  const divClasses = classNames('ev-scrollbar p-1 rounded shadow-ev-standard', {
    'bg-ev-light-purple': !isDark,
    'bg-ev-navy-blue text-white': isDark
  });

  return (
    <div className={divClasses}>
      <div className="overflow-y-auto h-[236px] max-h-[236px] p-5 flex flex-col">
        <span className="mb-4 color-ev-navy-blue text-base font-bold">{header}</span>
        {children}
      </div>
    </div>
  );
};

export default HeaderedBlock;
