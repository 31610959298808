import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../shared/table/Table';
import ErrorMessage from '../../shared/message/ErrorMessage';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import TextButton from 'shared/buttons/TextButton';
import { goToPage } from 'utils/profileHelper';

const columns = [
  {
    id: 'id',
    title: 'ID'
  },
  {
    id: 'group_id',
    title: 'Access group',
    type: 'select'
  },
  {
    id: 'name',
    title: 'Name'
  },
  {
    id: 'key',
    title: 'Key',
    copy: true
  }
];

class ApiPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.generateKey = this.generateKey.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadGroupPermissions = this.loadGroupPermissions.bind(this);
    this.loadKeys = this.loadKeys.bind(this);
    this.onSelectPageSize = this.onSelectPageSize.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onRowSave = this.onRowSave.bind(this);
  }

  componentDidMount() {
    this.loadKeys(this.props.team, this.props.page, this.props.pageSize);
  }

  componentDidUpdate(prevProps) {
    const isDifferentTeam = this.props.team && (!prevProps.team || prevProps.team.id !== this.props.team.id);
    if (isDifferentTeam) {
      this.loadKeys(this.props.team, this.props.page, this.props.pageSize);
    }
  }

  componentWillUnmount() {
    this.props.set({ dataReturned: false });
  }

  loadKeys(team, page, pageSize) {
    this.props.getTeamKeys(team, page, pageSize);
  }

  generateKey() {
    this.props.setTeamKey(this.props.team, this.props.groupId, this.props.name, this.props.page, this.props.pageSize);
  }

  onSearchInputChange(event) {
    this.props.set({
      name: event.target.value
    });
  }

  handleChange(value) {
    this.props.set({ selectValue: value });
  }

  loadGroupPermissions(group) {
    this.props.set({
      groupId: group.value
    });
  }

  onSelectPageSize(pageSize) {
    this.loadKeys(this.props.team, this.props.page, pageSize);
  }

  onSelectPage(page) {
    this.loadKeys(this.props.team, page, this.props.pageSize);
  }

  onRowSave(row) {
    this.props.updateKey(this.props.team, row, this.props.page, this.props.pageSize);
  }

  render() {
    const hasPermission = this.props.checkPlanPermission('api_access') || false;
    const keys = this.props.keys;
    const formattedGroups = this.props.groups?.map((group) => ({
      value: group.id,
      label: group.name
    }));

    const bulkActions = [
      {
        text: 'Delete selected',
        hasPermission,
        className: ' danger',
        onClick: (keys) => {
          this.props.deleteKeys(this.props.team, keys, this.props.page, this.props.pageSize);
        }
      },
      {
        text: 'New API key:',
        className: 'mb-0 ml-2.5 pl-2.5 inline-block border-l',
        type: 'input',
        placeholder: 'API name',
        value: this.props.name,
        onChange: (e) => {
          this.onSearchInputChange(e);
        }
      },
      {
        text: '',
        type: 'select',
        className: 'mx-2.5 w-44 inline-block ev-md:ml-24',
        options: formattedGroups || [],
        value: this.props.groupId,
        hasPermission,
        onChange: (selectedGroup) => {
          this.loadGroupPermissions(selectedGroup);
        }
      },
      {
        text: 'Generate API key',
        bypassCheck: true,
        hasPermission,
        onClick: (e) => {
          this.generateKey(e);
        }
      }
    ];

    let editableColumns;

    if (formattedGroups) {
      editableColumns = {
        group_id: {
          validValues: formattedGroups
        },
        name: {}
      };
    }

    return (
      <ProfilePageContainer
        title="API"
        header={<>API</>}
        description={
          <>
            {' '}
            You’ll need an API key if you want to programmatically create and modify charts hosted in the service.{' '}
            <TextButton
              onClick={() => goToPage('https://help.everviz.com/category/322-developer-api')}
              text="More information about this page can be found in our knowledge base."
            />
          </>
        }
      >
        <ProfilePageSectionContainer>
          <div className="api-page">
            <form>
              <div className="alert alert-warning">
                Your team id is: <strong>{this.props.team.id}</strong>
                {this.props.division && this.props.division.id !== null && (
                  <span>
                    , Your Division ID is: <strong>{this.props.division.id} </strong>
                  </span>
                )}
              </div>
              <ErrorMessage error={this.props.error} />
              <Table
                columns={columns}
                bulkActions={bulkActions}
                rows={keys}
                onRowSave={this.onRowSave}
                editableColumns={editableColumns}
                onSelectPage={this.onSelectPage}
                page={this.props.page}
                pageCount={this.props.pageCount}
                pageSize={this.props.pageSize}
                dataReturned={this.props.dataReturned}
                hasEditPermission={hasPermission}
                bulkActionsClassName="ev-md:flex ev-md:items-end"
                isActionCell={true}
              />
            </form>
          </div>
        </ProfilePageSectionContainer>
      </ProfilePageContainer>
    );
  }
}

ApiPage.propTypes = {
  team: PropTypes.object.isRequired,
  paths: PropTypes.object.isRequired,
  checkPermission: PropTypes.func,
  groups: PropTypes.array,
  loadGroups: PropTypes.func,
  division: PropTypes.object
};

export default ApiPage;
