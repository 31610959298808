export default {
  series: 3,
  data: 'Latitude;Longitude;Name\n40.7127281;-74.0060152;New York\n47.2868352;-120.212613;Washington\n31.168570000000003;-99.68300099546674;Texas',
  seriesMapping: [
    {
      'hc-key': 0,
      value: 1,
      x: 1
    },
    {
      lat: 0,
      lon: 1,
      name: 2,
      x: 0,
      y: 0,
      id: 2
    },
    {
      'hc-key': 0,
      value: 1,
      x: 1
    }
  ],
  assignDataFields: [
    {
      labels: 'A',
      value: 'B'
    },
    {
      latitude: 'A',
      longitude: 'B',
      name: 'C'
    },
    {
      labels: 'A',
      value: 'B'
    }
  ],
  seriesOptions: [
    {
      joinBy: 0,
      type: 'map'
    },
    {
      joinBy: 'hc-key',
      type: 'mappoint',
      includeInDataExport: false
    },
    {
      type: 'mapline',
      color: 'silver',
      showInLegend: false,
      enableMouseTracking: false,
      includeInDataExport: false
    }
  ],
  seriesTemplate: ['map', 'mappoint', 'mapline']
};
