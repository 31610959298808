import paths from 'paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import ProfilePageSectionDescription from 'shared/profile/ProfilePageSectionDescription';
import Subheader from 'shared/profile/Subheader';
import Pagination from './../../../shared/Pagination';
import { checkPermission } from '../../../utils/profileHelper';
import Theme from './../components/Theme';

type ChartSpecificThemesSectionProps = {
  deleted?: boolean;
  loadThemes: (index?: number) => void;
  hasPermission: () => void;
  checkHasPermission: (e: any) => void;
  showPaidPlanModal: () => void;
};

export default function ChartSpecificThemesSection(props: ChartSpecificThemesSectionProps) {
  const { loadThemes, deleted, checkHasPermission, hasPermission, showPaidPlanModal } = props;
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.profile);
  const { page: themeSetPage, pageCount, themes } = useSelector((state: RootState) => state.themesPage);
  const themeManagementPerm = checkPermission('theme_management', profile);
  const hasThemes = Object.keys(themes).length > 0;

  const onSelectPage = (page: number) => {
    if (themeManagementPerm) loadThemes(page);
  };

  const goToThemeEditor = () => history.push(paths.newTheme);

  return (
    <>
      <div className="flex">
        <Subheader>Chart specific themes</Subheader>
        <div className="content-centered ml-4">
          <IconButton
            icon="plus"
            className="shrink-0 ml-2 h-8 w-8 rounded-full"
            buttonColor={ButtonColor.BabyBlue2}
            tooltipText={'Set up a theme'}
            onClick={goToThemeEditor}
          />
        </div>
      </div>
      <ProfilePageSectionDescription>
        Create a theme for a specific project type using the full customisation options in the everviz editor. Company
        themes, if set, are automatically applied to chart specific themes.
      </ProfilePageSectionDescription>

      {hasThemes && (
        <>
          <div className="project-grid mb-16">
            {themes.map((theme: any) => (
              <Theme
                key={theme.id}
                theme={theme}
                refresh={loadThemes}
                isDeleted={deleted}
                hasPermission={hasPermission}
                checkHasPermission={checkHasPermission}
                showPaidPlanModal={showPaidPlanModal}
              />
            ))}
          </div>
          {pageCount > 1 && ( // Display pagination if more than one page
            <div className="col-xs-12">
              <Pagination pageCount={pageCount} currentPage={themeSetPage} onSelectPage={onSelectPage} />
            </div>
          )}
        </>
      )}
    </>
  );
}
