const templates = {
  vertical: {
    name: 'Vertical',
    img: '/static/images/layouts/vertical.png',
    id: 2,
    constr: 'basic',
    type: 'vertical',
    config: {
      title: {
        enabled: true
      }
    },
    layout: [
      {
        settings: {
          buttons: ['add_chart'],
          classes: 'section-borderRight'
        },
        style: {
          width: '50%',
          height: '100%',
          display: 'inline-block'
        },
        smallStyle: {
          width: '100%',
          height: '50%'
        }
      },
      {
        settings: {
          buttons: ['add_chart']
        },
        style: {
          width: '50%',
          height: '100%',
          display: 'inline-block'
        },
        smallStyle: {
          width: '100%',
          height: '50%'
        }
      }
    ]
  },
  horizontal: {
    name: 'Horizontal',
    img: '/static/images/layouts/horizontal.png',
    id: 3,
    constr: 'basic',
    type: 'horizontal',
    config: {
      title: {
        enabled: true
      }
    },
    layout: [
      {
        settings: {
          buttons: ['add_chart'],
          classes: 'section-borderBottom'
        },
        style: {
          width: '100%',
          height: '50%',
          display: 'block'
        }
      },
      {
        settings: {
          buttons: ['add_chart']
        },
        style: {
          width: '100%',
          height: '50%',
          display: 'block'
        }
      }
    ]
  },
  small_multiple: {
    name: 'Four',
    img: '/static/images/layouts/smallmultiple.png',
    id: 4,
    type: 'small_multiple',
    constr: 'basic',
    config: {
      title: {
        enabled: true
      }
    },
    layout: [
      {
        settings: {
          buttons: ['add_chart'],
          classes: 'section-borderRight section-borderBottom'
        },
        smallStyle: {
          width: '100%',
          height: '25%'
        },
        style: {
          width: '50%',
          height: '50%',
          display: 'inline-block'
        }
      },
      {
        settings: {
          buttons: ['add_chart'],
          classes: 'section-borderBottom'
        },
        smallStyle: {
          width: '100%',
          height: '25%'
        },
        style: {
          width: '50%',
          height: '50%',
          display: 'inline-block'
        }
      },
      {
        settings: {
          buttons: ['add_chart'],
          classes: 'section-borderRight'
        },
        smallStyle: {
          width: '100%',
          height: '25%'
        },
        style: {
          width: '50%',
          height: '50%',
          display: 'inline-block'
        }
      },
      {
        settings: {
          buttons: ['add_chart']
        },
        smallStyle: {
          width: '100%',
          height: '25%'
        },
        style: {
          width: '50%',
          height: '50%',
          display: 'inline-block'
        }
      }
    ]
  },
  three_vertical: {
    name: 'Three',
    img: '/static/images/layouts/three-vertical.png',
    id: 7,
    type: 'three_vertical',
    constr: 'basic',
    config: {
      title: {
        enabled: true
      }
    },
    layout: [
      {
        settings: {
          buttons: ['add_chart'],
          classes: 'section-borderRight'
        },
        style: {
          width: '33%',
          height: '100%',
          display: 'inline-block'
        },
        smallStyle: {
          width: '100%',
          height: '50%'
        }
      },
      {
        settings: {
          buttons: ['add_chart'],
          classes: 'section-borderRight'
        },
        style: {
          width: '33%',
          height: '100%',
          display: 'inline-block'
        },
        smallStyle: {
          width: '100%',
          height: '50%'
        }
      },
      {
        settings: {
          buttons: ['add_chart']
        },
        style: {
          width: '33%',
          height: '100%',
          display: 'inline-block'
        },
        smallStyle: {
          width: '100%',
          height: '50%'
        }
      }
    ]
  },
  slider: {
    name: 'Slider',
    img: '/static/images/layouts/slider.png',
    id: 8,
    type: 'slider',
    constr: 'slider',
    config: {
      title: {
        enabled: false
      }
    },
    layout: [
      {
        settings: {
          buttons: ['add']
        },
        style: {
          width: '100%',
          height: '100%'
        }
      },
      {
        settings: {
          buttons: ['add']
        },
        style: {
          width: '100%',
          height: '100%'
        }
      }
    ]
  },
  carousel: {
    name: 'Carousel',
    img: '/static/images/layouts/carousel.png',
    constr: 'carousel',
    id: 6,
    type: 'carousel',
    config: {
      title: {
        enabled: true
      }
    },
    layout: [
      {
        settings: {
          buttons: ['add_chart']
        },
        style: {
          width: '100%',
          height: '100%'
        }
      }
    ]
  },
  dropdown: {
    name: 'Dropdown',
    img: '/static/images/layouts/dropdown.png',
    constr: 'dropdown',
    id: 7,
    type: 'dropdown',
    config: {
      title: {
        enabled: false
      }
    },
    layout: [
      {
        settings: {
          buttons: ['add_chart']
        },
        style: {
          width: '100%',
          height: '100%',
          display: 'inline-block'
        }
      }
    ]
  },
  blank: {
    name: 'Single',
    img: '/static/images/layouts/blank.png',
    id: 1,
    constr: 'basic',
    type: 'blank',
    layout: [
      {
        settings: {
          buttons: ['add_chart']
        },
        style: {
          width: '100%',
          height: '100%'
        }
      }
    ],
    config: {
      title: {
        enabled: true
      }
    }
  },
  tabs: {
    name: 'Tabs',
    img: '/static/images/layouts/link.png',
    id: 5,
    type: 'tabs',
    constr: 'tabs',
    config: {
      title: {
        enabled: false
      }
    },
    layout: [
      {
        settings: {
          buttons: ['add_chart']
        },
        style: {
          width: '100%',
          height: '100%',
          display: 'inline-block'
        }
      }
    ]
  }
};

export function getTemplateOptions(type) {
  return templates[type] && templates[type].layout;
}

export default templates;
