import { rowsToColumns } from 'editor/core-ui/datatable/highed.datatable.utils';
import { cloneDeep } from 'lodash';
import { setDataAction as setTableAction } from 'pages/TableEditorPage/actions/tableEditor';
import { setData, updateBufferData } from 'pages/TableEditorPage/middleware/tableEditorData';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { getSeriesType } from 'shared/wizard/utils/seriesHelper.tsx';
import { getLetterIndex } from '../../../editor/core/highcharts-editor';
import actionTypes from '../../../redux/actions/action-types';
import {
  addDataSeriesAction,
  clearColumnAction,
  deleteColumnFromOptionsAction,
  deleteSeriesAction,
  handleDeletedColumnAction,
  movedColumnsAction,
  movedRowsAction,
  setAction as setProjectConfigAction,
  sortDataAction,
  updateDataAndConfigAction,
  updateDataAndConfigBulkAction,
  updateSeriesMappingAction
} from '../../../redux/actions/projectConfig';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { setDataAction } from '../actions/chartEditor';
import { getChartConfig } from '../selectors/chartEditor';
import { addBlankSeries, loadCSV, updateMapErrorFields } from './ChartEditorData';

export function* editDataGrid(params) {
  try {
    const { changes, isTable } = params.data;
    const { isMap } = yield select(getChartConfig);
    yield put(
      updateDataAndConfigBulkAction({
        changes,
        isMap,
        skipEmit: isTable
      })
    );

    if (isTable) yield fork(updateBufferData, {});
  } catch (error) {
    throw new Error(error);
  }
}

function* addDataGridSeries(params) {
  try {
    const { type, index, amount, source } = params.data;
    const isTable = type === 'table';
    yield put(addDataSeriesAction({ index, amount, source, skipEmit: isTable }));
    if (isTable) yield call(updateBufferData, {});
  } catch (error) {
    throw new Error(error);
  }
}

function* deleteDataGridSeries(params) {
  try {
    const { type, index, amount, source } = params.data;
    const isTable = type === 'table';
    // Delete from seriesAssigns and customize/aggregatedOptions series
    if (!source || source === 'ContextMenu.removeColumn') {
      yield put(handleDeletedColumnAction({ index, amount, key: isTable ? 'columns' : 'series' })); // Delete assignColumns
    }
    // 1) Delete from data.csv
    // 2) Update customizedOptions/aggregatedOptions
    // 3) update seriesMapping based on assignColumns
    yield put(deleteSeriesAction({ index, amount, source, skipEmit: isTable }));

    if (isTable) yield call(updateBufferData, {});
  } catch (error) {
    throw new Error(error);
  }
}

function* sortData(params) {
  try {
    const { type, column, sortOrder } = params.data;
    const { isMap } = yield select(getChartConfig);
    yield put(sortDataAction({ column, sortOrder, isMap }));
    if (type !== 'chart') yield call(updateBufferData, {});
  } catch (error) {
    throw new Error(error);
  }
}

function* updateChartCategories(params) {
  try {
    let { newCategories, newIndex } = params.data;
    const projectConfig = yield select(getProjectConfig);
    const { isMap } = yield select(getChartConfig);
    const newAssigns = projectConfig.seriesAssigns.map((item) => ({
      ...item,
      labels: { ...item.labels, value: newCategories, rawValue: [newIndex] }
    }));
    yield put(updateSeriesMappingAction({ newAssigns }));

    if (isMap) yield call(updateMapErrorFields);
  } catch (e) {
    throw new Error(e);
  }
}

function* addChartSeries() {
  try {
    const { isMap } = yield select(getChartConfig);
    const { dataOptions, aggregatedOptions, seriesAssigns } = yield select(getProjectConfig);
    const seriesTypes = getSeriesType(aggregatedOptions);
    const index = seriesAssigns.length;
    const newAssignOptions = [...seriesAssigns];
    yield call(addBlankSeries, {
      data: { index, seriesAssigns: newAssignOptions, type: seriesTypes[index - 1] }
    });
    const newProjectConfig = yield select(getProjectConfig);
    yield put(setProjectConfigAction({ seriesAssigns: [...newProjectConfig.seriesAssigns] }));

    const newOption = newAssignOptions[newAssignOptions.length - 1];
    let newFields = [];

    Object.keys(newOption).forEach((key) => {
      if (newOption[key].isData) {
        newFields.push(newOption[key].value);
      }
    });

    for (let letter of newFields) {
      if (getLetterIndex(letter) >= dataOptions[0].length) {
        // Series column being mapped does not exist in data.
        // Need to add empty data to stop it breaking in highcharts
        yield put(
          updateDataAndConfigAction({
            row: 0,
            column: getLetterIndex(letter),
            newValue: 'Header',
            isMap
          })
        );
      }
    }

    yield put(updateSeriesMappingAction({ newAssigns: [...newProjectConfig.seriesAssigns] }));
  } catch (e) {
    console.error(e);
  }
}

function* deleteChartSeries(params) {
  try {
    const { index } = params.data;
    const { isMap } = yield select(getChartConfig);
    const projectConfig = yield select(getProjectConfig);
    let seriesAssigns = cloneDeep(projectConfig.seriesAssigns);
    seriesAssigns.splice(index, 1);
    yield put(deleteColumnFromOptionsAction({ key: 'series', index }));
    yield put(updateSeriesMappingAction({ isMap, newAssigns: seriesAssigns }));
  } catch (error) {
    throw new Error(error);
  }
}

function* updateChartSeries(params) {
  try {
    const { newSeries, seriesIndex, newIndex, key } = params.data;
    const { isMap } = yield select(getChartConfig);
    const { dataOptions, seriesAssigns } = yield select(getProjectConfig);
    const newAssigns = cloneDeep(
      seriesAssigns.map((item, index) => {
        if (index === seriesIndex) {
          if (newSeries === '-1') return { ...item, [key]: { ...item[key], value: '', rawValue: null } };
          return { ...item, [key]: { ...item[key], value: newSeries, rawValue: [newIndex] } };
        }
        return item;
      })
    );

    if (newIndex > dataOptions[0].length - 1) {
      // Series column being mapped does not exist in data.
      // Need to add empty data to stop it breaking in highcharts
      yield put(
        updateDataAndConfigAction({
          row: 0,
          column: newIndex,
          newValue: 'Header',
          isMap
        })
      );
    }

    if (isMap) {
      newAssigns.forEach((assign) => {
        if (assign[key]) {
          assign[key].rawValue = [newIndex];
        }
      });
    }

    yield put(updateSeriesMappingAction({ newAssigns }));
  } catch (e) {
    throw new Error(e);
  }
}

function* transposeData(params) {
  const { type, callback } = params.data;
  try {
    const { dataOptions } = yield select(getProjectConfig);
    const transposedData = rowsToColumns(dataOptions);

    yield call(type === 'chart' ? loadCSV : setData, {
      data: {
        options: transposedData,
        dataType: 'csv'
      }
    });

    return callback(cloneDeep(transposedData));
  } catch (e) {
    throw new Error(e);
  }
}

function* moveCells(params) {
  const { type, moveType, movedCells, finalIndex } = params.data;
  const { isMap } = yield select(getChartConfig);

  const isTable = type === 'table';
  if (moveType === 'columns') {
    yield put(movedColumnsAction({ movedCells, finalIndex, isMap, skipEmit: isTable }));
  } else yield put(movedRowsAction({ movedCells, finalIndex, isMap, skipEmit: isTable }));

  if (isMap) yield call(updateMapErrorFields);

  if (isTable) yield call(updateBufferData, {});
}

function* clearColumn(params) {
  try {
    const { type, index, amount } = params.data;
    const isTable = type === 'table';

    yield put(clearColumnAction({ index, amount, skipEmit: isTable, key: isTable ? 'columns' : 'series' }));

    if (isTable) yield call(updateBufferData, {});
  } catch (error) {
    throw new Error(error);
  }
}

function* remap(params) {
  try {
    const { type, skipNotification, cb } = params.data;
    const isTable = type === 'table';

    const projectConfig = yield select(getProjectConfig);
    const { isMap } = yield select(getChartConfig);
    const dataOptions = cloneDeep(projectConfig.dataOptions);

    if (isMap) return;
    if (isTable) {
      yield all([
        put(
          setTableAction({
            options: dataOptions,
            dataType: 'csv',
            skipNotification,
            cb
          })
        ),
        call(updateBufferData, {})
      ]);
    } else {
      yield put(
        setDataAction({
          options: dataOptions,
          dataType: 'csv',
          skipNotification,
          cb: cb
        })
      );
    }
  } catch (error) {
    throw new Error(error);
  }
}

/** Watch functions */
export function* watchEditDataGrid() {
  yield takeEvery(actionTypes.chartEditor.editDataGrid, editDataGrid);
}
export function* watchAddDataGridSeries() {
  yield takeEvery(actionTypes.chartEditor.addDataGridSeries, addDataGridSeries);
}
export function* watchDeleteDataGridSeries() {
  yield takeEvery(actionTypes.chartEditor.deleteDataGridSeries, deleteDataGridSeries);
}
export function* watchSortData() {
  yield takeEvery(actionTypes.chartEditor.sortData, sortData);
}
export function* watchUpdateChartCategories() {
  yield takeEvery(actionTypes.chartEditor.updateChartCategories, updateChartCategories);
}

export function* watchUpdateChartSeries() {
  yield takeEvery(actionTypes.chartEditor.updateChartSeries, updateChartSeries);
}

export function* watchAddChartSeries() {
  yield takeEvery(actionTypes.chartEditor.addChartSeries, addChartSeries);
}

export function* watchDeleteChartSeries() {
  yield takeEvery(actionTypes.chartEditor.deleteChartSeries, deleteChartSeries);
}

export function* watchTransposeData() {
  yield takeEvery(actionTypes.chartEditor.transposeData, transposeData);
}

export function* watchMoveCells() {
  yield takeEvery(actionTypes.chartEditor.moveCells, moveCells);
}

export function* watchClearColumn() {
  yield takeEvery(actionTypes.chartEditor.clearColumn, clearColumn);
}

export function* watchRemap() {
  yield takeEvery(actionTypes.chartEditor.remap, remap);
}

export default function* rootSaga() {
  yield all([
    watchEditDataGrid(),
    watchAddDataGridSeries(),
    watchDeleteDataGridSeries(),
    watchSortData(),
    watchUpdateChartCategories(),
    watchUpdateChartSeries(),
    watchAddChartSeries(),
    watchDeleteChartSeries(),
    watchTransposeData(),
    watchMoveCells(),
    watchRemap(),
    watchClearColumn()
  ]);
}
