import { cloneDeep, merge } from 'lodash';
import { getSeriesType } from 'shared/wizard/utils/seriesHelper';

export const iconImports = [
  'Line',
  'Bar',
  'Column',
  'Pie',
  'Area',
  'StackedArea',
  'Sankey',
  'Scatter',
  'Unit',
  'WordCloud',
  'Donut',
  'StackedBar',
  'StackedColumn',
  'StackedArea',
  'DependencyWheel',
  'Parliament',
  'RadialBar',
  'Bubble',
  'PolarArea',
  'Waterfall',
  'Candlestick',
  'Boxplot',
  'WindRose',
  'SpiderArea',
  'Dumbbell',
  'Funnel',
  'OHLC',
  'SolidGauge',
  'Heatmap',
  'StreamGraph',
  'Timeline',
  'Treemap',
  'PackedBubble'
];
export const mapIconImports = [
  'Choropleth',
  'Categories',
  'BubbleMap',
  'Honeycomb',
  'PatternFill',
  'PointMap',
  'TilemapCircle'
];

const basicTypes = {
  all: {
    line: 1,
    spline: 1,
    bar: 1,
    column: 1,
    area: 1,
    scatter: 1,
    waterfall: 1,
    streamgraph: 1
  },
  single: {
    pie: 1,
    treemap: 1,
    item: 1,
    funnel: 1,
    pyramid: 1,
    solidgauge: 1
  }
};

const basicSingleSeriesTypes = {
  all: {
    line: 1,
    spline: 1,
    bar: 1,
    column: 1,
    pie: 1,
    area: 1,
    scatter: 1,
    item: 1,
    treemap: 1,
    waterfall: 1,
    streamgraph: 1,
    funnel: 1,
    pyramid: 1,
    solidgauge: 1
  }
};

export type TemplateChangeProps = {
  all: unknown;
  single?: unknown;
};

export const templateChangeTypes = {
  line: basicTypes,
  spline: basicTypes,
  bar: basicTypes,
  column: basicTypes,
  pie: basicSingleSeriesTypes,
  area: basicTypes,
  dependencywheel: {
    all: {
      sankey: 1
    }
  },
  scatter: basicTypes,
  item: basicSingleSeriesTypes,
  treemap: basicSingleSeriesTypes,
  sankey: {
    all: {
      dependencywheel: 1
    }
  },
  funnel: basicSingleSeriesTypes,
  solidgauge: basicSingleSeriesTypes,
  streamgraph: basicTypes,
  arearange: {
    all: {
      columnrange: 1
    }
  },
  columnrange: {
    all: {
      arearange: 1
    }
  }
};

export const getCheckTypes = (aggregatedOptions: any) => {
  const series = getSeriesType(aggregatedOptions) ?? [];
  const uniqueSeries = [...new Set(series ?? [])];
  const checkTypes: Record<string, number> = {};
  ((uniqueSeries ?? []) as string[]).forEach((serie) => {
    const type = (templateChangeTypes as Record<string, TemplateChangeProps>)[serie];
    if (type?.all) {
      merge(checkTypes, cloneDeep(type.all));
    }

    if (type?.single && series.length === 1) {
      merge(checkTypes, cloneDeep(type.single));
    }
  });

  return checkTypes;
};
