import { get, isNil, set } from 'lodash';
import { cloneDeep } from 'lodash';

/** Set an attribute
 *  @memberof ChartPreview
 *  @name options.set
 *  @param id {string} - the path of the attribute
 *  @param value {anything} - the value to set
 *  @param index {number} - used if the option is an array
 */
export function setOption(customizedOptions, id, value) {
  // We want to be able to set the customized options even if the chart
  // doesn't exist
  set(customizedOptions, id, value);

  if (id.indexOf('lang.') === 0 && window.Highcharts && customizedOptions.lang) {
    Highcharts.setOptions({
      lang: customizedOptions.lang
    });
  }
}

export function handleOptionalArrayCustomized(optionProps, newCustomizedOptions, val) {
  const id = optionProps.id || optionProps;
  const splitId = id.split('.');
  const rootId = splitId.shift();
  const isOptionPropAnArray = (rootId ?? '').includes('[');

  const arrKey = isOptionPropAnArray ? rootId : `${rootId}[0]`;
  const objKey = isOptionPropAnArray ? rootId.replace('[0]', '') : rootId;

  let checkedArrayValue = get(newCustomizedOptions, arrKey);
  let checkedObjValue = get(newCustomizedOptions, objKey);
  const doesExist = checkedArrayValue ?? checkedObjValue;
  const isAnArray = doesExist && checkedArrayValue; // If option in customizedOptions is an array

  if (doesExist) {
    if (isAnArray) {
      if (isOptionPropAnArray) setOption(newCustomizedOptions, id, val);
      else setOption(newCustomizedOptions, `${rootId}[0].${splitId.join('.')}`, val);
    } else {
      if (isOptionPropAnArray) setOption(newCustomizedOptions, id.replace('[0]', ''), val);
      else setOption(newCustomizedOptions, id, val);
    }
  } else setOption(newCustomizedOptions, id, val);
}

export function handleYAxisMinChange(aggregatedOptions, selection, index) {
  const aggregated = cloneDeep(aggregatedOptions);
  const yAxis = Array.isArray(aggregated.yAxis) ? aggregated.yAxis[index ?? 0] : aggregated.yAxis || {};
  const isMinExists = yAxis.min != null;
  yAxis.min = selection;
  if (yAxis.max != null) {
    if (!isMinExists && yAxis.max < selection) yAxis.min = yAxis.max;
    else yAxis.max = Math.max(yAxis.max, selection);
  }
  return aggregated;
}

export function handleYAxisMaxChange(aggregatedOptions, selection, index) {
  const aggregated = cloneDeep(aggregatedOptions);
  const yAxis = Array.isArray(aggregated.yAxis) ? aggregated.yAxis[index ?? 0] : aggregated.yAxis || {};
  const isMaxExists = yAxis.max != null;
  yAxis.max = selection;
  if (yAxis.min != null) {
    if (!isMaxExists && yAxis.min > selection) yAxis.max = yAxis.min;
    else yAxis.min = Math.min(yAxis.min, selection);
  }
  return aggregated;
}

export function getOptionalArrayValue(userAggregatedOptions, option) {
  const isArray = (option?.id ?? '').includes('[');
  let val = get(userAggregatedOptions, option.id ?? '');
  if (isNil(val)) {
    if (isArray) val = get(userAggregatedOptions, option.id?.replace('[0]', '') ?? '');
    else val = get(userAggregatedOptions, option.id?.replace(option?.id ?? '', `${option.id}[0]`) ?? '');
  }
  return val;
}
