import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Accordion from 'shared/Accordion';
import ListOption from 'shared/wizard/components/ListOption';
import { setPageTemplateAction } from '../actions/layoutEditor';

import templates from '../meta/config/templates';

type TemplatesComponentProp = {
  inWizard: boolean;
};

export default (props: TemplatesComponentProp) => {
  const dispatch = useDispatch();
  const [activeTemplate, setActiveTemplate] = useState<unknown>();
  const [activeId, setActiveId] = useState<number>();
  const { dataOptions, customizedOptions, showWizard } = useSelector((state: RootState) => state.projectConfig);
  const { templateUsed } = useSelector((state: RootState) => state.layoutEditorPage);
  const { inWizard } = props;

  const selectTemplate = (template: { id: number }) => {
    setActiveTemplate(template);
    setActiveId(template.id);

    dispatch(setPageTemplateAction({ template, customizedOptions, dataOptions }));
  };

  useEffect(() => {
    if (templateUsed && !activeTemplate) {
      setActiveTemplate((templates as Record<string, any>)[templateUsed]);
      setActiveId((templates as Record<string, any>)[templateUsed].id);
    } else if (!activeTemplate && templates && showWizard) {
      selectTemplate(templates.vertical);
    }
  }, []);

  const Container = (props: { children: React.ReactNode }) =>
    inWizard ? (
      <div className="grid grid-cols-2 gap-2">{props.children}</div>
    ) : (
      <Accordion headerText="Layout types" useInternalSelect={true} childrenClasses="flex flex-wrap">
        {props.children}
      </Accordion>
    );

  return (
    <Container>
      {Object.values(templates).map((template, i) => {
        return (
          <ListOption
            key={`template_${i}`}
            className="basis-1/3"
            selected={activeId === template.id}
            onClick={() => selectTemplate(template)}
            onDoubleClick={() => selectTemplate(template)}
            title={template.name}
            titleClassName={'m'}
          >
            <div>
              <img src={template.img} className={activeId === template.id ? 'ring ring-ev-navy-blue-2' : ''} />
            </div>
          </ListOption>
        );
      })}
    </Container>
  );
};
