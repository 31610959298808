import { TemplateProps } from '../../types';

const InvertedWithLabels: TemplateProps = {
  title: 'Inverted with labels',
  description: '',
  constructor: '',
  thumbnail: 'acemyq.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      inverted: true,
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true
        }
      }
    }
  },
  sampleSet: 'area',
  advancedParent: 'Area',
  advanced: {
    name: 'Inverted + label'
  },
  dataValidator: false,
  thumbnail_url: 'https://app.everviz.com/static/thumbnails/acemyq.svg',
  name: 'Inverted with labels'
};

export default InvertedWithLabels;
