import actionTypes from '../../../redux/actions/action-types';
import initialState from '../../../redux/reducers/initial-state';

const teamGroupsPageReducer = (state = initialState.rolesPage, action) => {
  switch (action.type) {
    case actionTypes.rolesPage.set:
      return {
        ...state,
        ...action.data
      };
  }
  return state;
};

export default teamGroupsPageReducer;
