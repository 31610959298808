import { BillingForm } from '../types/plansModels';

export const billingFormConfig = (user: any, billingData: any, isLoggedIn = false): BillingForm => ({
  first_name: {
    name: 'first_name',
    value: '',
    error: '',
    label: 'Cardholder',
    placeholder: 'First name'
  },
  last_name: {
    name: 'last_name',
    value: '',
    error: '',
    placeholder: 'Last name'
  },
  username: {
    name: 'username',
    label: 'Full name',
    value: isLoggedIn ? user?.username : '',
    error: ''
  },
  email: {
    name: 'email',
    label: isLoggedIn ? 'Billing contact e-mail' : 'E-mail address',
    value: isLoggedIn ? user?.email : '',
    error: ''
  },
  password: {
    name: 'password',
    label: 'Password',
    value: '',
    error: ''
  },
  confirm_password: {
    name: 'confirm_password',
    label: 'Confirm password',
    value: '',
    error: ''
  },
  address1: {
    name: 'address1',
    label: 'Street',
    value: isLoggedIn ? billingData?.address1 : '',
    error: ''
  },
  address2: {
    name: 'address2',
    label: 'City',
    value: isLoggedIn ? billingData?.address2 : '',
    error: ''
  },
  postal_code: {
    name: 'postal_code',
    label: 'Postal code',
    value: isLoggedIn ? billingData?.postal_code : '',
    error: ''
  },
  country: {
    name: 'country',
    label: 'Country',
    value: isLoggedIn ? billingData?.country : '',
    error: ''
  },
  number: {
    name: 'number',
    label: 'Card number',
    value: '',
    error: ''
  },
  cvv: {
    name: 'cvv',
    label: 'CVV',
    value: '',
    error: ''
  },
  month: {
    name: 'month',
    label: 'Expiration month',
    value: '',
    error: ''
  },
  year: {
    name: 'year',
    label: 'Expiration year',
    value: '',
    error: ''
  },
  vat_number: {
    name: 'vat_number',
    label: 'Vat number',
    value: '',
    error: ''
  },
  three_d_secure_token: {
    name: 'three_d_secure_token',
    value: '',
    error: ''
  }
});
