import React, { useState, ChangeEvent, FormEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { validateSignupForm } from 'shared/modal/utils/signupModalHelper';
import { login, postAccount } from '../../api/cloudApiGenerated';
import Button from '../../shared/buttons/Button';
import TextInput from '../../shared/inputs/TextInput';
import ErrorMessage from '../../shared/message/ErrorMessage';
import { useCaptcha } from '../../utils/signupHelper';
import config from './../../config';
import { handleGoogleTagManagerEvent, handleGoogleTagManagerPurchaseEvent } from 'shared/utils/googleTagManagerHelper';
import { subscriptionPlans } from 'pages/SubscriptionPage/meta/subscriptionPlans';
import { BillingForm } from 'pages/SubscriptionPage/types/plansModels';
import paths from 'paths';

type SignUpPageProps = {
  redirect: string;
  onLoggedIn: (getLast: boolean, announcements?: any) => void;
};

const SignupPage = (props: SignUpPageProps) => {
  const { redirect, onLoggedIn } = props;

  const history = useHistory();
  const [isSignedIn] = useState(false);
  const [hasAccount] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [form, setForm] = useState<BillingForm>({
    username: { name: 'username', value: '', error: '' },
    email: { name: 'email', value: '', error: '' },
    password: { name: 'password', value: '', error: '' },
    confirm_password: { name: 'confirm_password', value: '', error: '' }
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const valid = validateSignupForm(form, (newForm) => setForm({ ...newForm }));
    const action = 'login';

    useCaptcha(config, action).then((token) => {
      if (valid) {
        postAccount({
          username: form.username.value,
          email: form.email.value,
          password: form.password.value,
          captchaResponse: token,
          action
        })
          .then(() => {
            const planDetails = subscriptionPlans[1];
            handleGoogleTagManagerPurchaseEvent('purchase', planDetails);
            handleGoogleTagManagerEvent({
              event: 'signup',
              signupMethod: 'email'
            });

            login(form.email.value, form.password.value).then((data: any) => {
              onLoggedIn(false, data?.announcements);
              history.push({
                pathname: redirect
              });
            });
          })
          .catch((error) => {
            setError((error.message.join && error.message.join(' ')) || error.message);
            throw new Error(error);
          });
      }
    });
  };

  const updateState = (event: ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    setForm((prevForm) => ({
      ...prevForm,
      [field]: { name: field, value: event.target.value, error: '' }
    }));
  };

  return (
    <div className="dark-layout signup-page">
      <div className="floating-login-button hidden-sm hidden-md hidden-lg">
        <NavLink to={paths.login}>Login</NavLink>
      </div>
      <div className="page-inner-div">
        <section className="page-section clearfix">
          <div className="page-form clearfix">
            {isSignedIn && (
              <p>You can {hasAccount ? 'only add a Google Login' : 'add both login types'} to your existing account.</p>
            )}
            <form role="form" onSubmit={onSubmit}>
              <div className="page-div">
                <div className="sharing-text hidden-sm hidden-md hidden-lg">
                  Sharing your data through attractive, interactive charts/maps is now simpler than ever
                </div>
                <h3 className="hidden-xs my-8 font-bold"> Sign up for a 14-day free trial </h3>
                <div className="hidden-sm hidden-md hidden-lg"> Sign up for a 14-day free trial </div>
                <ErrorMessage error={error} dismissable={false} />

                <TextInput
                  label="Full Name"
                  name="username"
                  value={form.username.value}
                  error={form.username.error as string}
                  placeholder={hasAccount ? '' : 'Name'}
                  disabled={hasAccount}
                  onChange={updateState}
                  labelClass={' hidden-sm font-bold text-ev-navy-blue'}
                  inputClassName="highed-field-input"
                />

                <TextInput
                  label="Email address"
                  name="email"
                  type="email"
                  value={form.email.value}
                  error={form.email.error as string}
                  placeholder={hasAccount ? '' : 'Email'}
                  disabled={hasAccount}
                  onChange={updateState}
                  labelClass={' hidden-sm font-bold mt-2 text-ev-navy-blue'}
                  inputClassName="highed-field-input"
                />

                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  value={form.password.value}
                  error={form.password.error as string}
                  placeholder={hasAccount ? '' : 'Enter password (minimum 8 characters)'}
                  disabled={hasAccount}
                  onChange={updateState}
                  labelClass={' hidden-sm font-bold mt-2 text-ev-navy-blue'}
                  inputClassName="highed-field-input"
                />

                <TextInput
                  label="Confirm password"
                  name="confirm_password"
                  type="password"
                  value={form.confirm_password.value}
                  error={form.confirm_password.error as string}
                  placeholder={hasAccount ? '' : 'Type your password again'}
                  disabled={hasAccount}
                  onChange={updateState}
                  labelClass={' hidden-sm font-bold mt-2 text-ev-navy-blue'}
                  inputClassName="highed-field-input"
                />

                <div className="btn-page-div">
                  <Button
                    buttonText={isSignedIn ? 'Add new sign in' : 'Start for free'}
                    buttonClass={
                      'hidden-xs px-8 btn bulk-action bulk-action-btn' + (hasAccount ? ' disabled="disabled"' : '')
                    }
                    type="submit"
                    onClick={() => onSubmit}
                  />
                  <Button
                    buttonText={isSignedIn ? 'Add New Sign in' : 'Start Free Trial'}
                    buttonClass={
                      'hidden-sm signup-button hidden-md hidden-lg btn bulk-action bulk-action-btn' +
                      (hasAccount ? ' disabled="disabled"' : '')
                    }
                    type="submit"
                    onClick={() => onSubmit}
                  />
                </div>

                <div className="mb-4">
                  {isSignedIn && (
                    <p className="alternative">
                      Dont want to add a sign in? <NavLink to={paths.frontPage}>Go to frontpage.</NavLink>
                    </p>
                  )}
                  {!isSignedIn && (
                    <p className="alternative hidden-xs text-center">
                      Already have an account?{' '}
                      <NavLink className={'font-medium'} to={paths.login}>
                        Sign in
                      </NavLink>
                    </p>
                  )}
                </div>

                <div className="flex items-center px-8 pb-4">
                  <hr className="flex-grow border border-ev-navy-blue" />
                  <span className="mx-2 text-ev-navy-blue text-base">OR</span>
                  <hr className="flex-grow border border-ev-navy-blue" />
                </div>

                <div className="hidden-xs flex items-center justify-center pb-8">
                  <a className="btn-google rounded" href={paths.oauthSignup}>
                    <img alt="google_auth" src="/static/images/btn_google_dark_normal_ios.svg" />
                    <span>Sign up free with Google</span>
                  </a>
                </div>

                <div className="form-fields hidden-sm hidden-md hidden-lg">
                  Or sign up with <a href={paths.oauthSignup}> Google Authentication</a>
                </div>

                <div className="text-center text-xs text-gray-500 mx-14 mb-10">
                  This site is protected by reCAPTCHA and the{' '}
                  <a href="https://policies.google.com/privacy">Google Privacy Policy</a> and{' '}
                  <a href="https://policies.google.com/terms">Terms of Service</a> apply. By Clicking{' '}
                  <span className="hidden-xs">Sign up</span>
                  <span className="hidden-sm hidden-md hidden-lg">Start Free Trial</span>, you agree to our <br />
                  {<a href="https://www.everviz.com/terms">Terms of Service</a>} and{' '}
                  {<a href="https://www.everviz.com/privacy">Privacy Policy</a>}
                </div>
              </div>
            </form>
          </div>
        </section>
        <div className="getting-started-iframe hidden-sm hidden-md hidden-lg">
          Check this video how to get started in 3 simple steps:
        </div>
        <iframe
          className="hidden-sm hidden-md hidden-lg w-full"
          src="https://www.youtube.com/embed/lflZysaJDJM"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        />
      </div>
    </div>
  );
};

export default SignupPage;
