/* eslint-disable react/no-danger */
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setAction } from 'redux/actions/projectConfig';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import FieldContainer from './FieldContainer';

export default (props: GenericInputProps) => {
  const { option } = props;
  // const { icons } = useSelector((state: RootState) => state.projectConfig); // TODO
  const ref = useRef(null);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      setAction({
        emojiOptions: {
          option: option,
          box: ref.current
        }
      })
    );
  };

  // const icon = icons[value as any];

  return (
    <FieldContainer label={'Icon'} className={props.className} childrenClass="absolute right-2" innerRef={ref}>
      <IconButton
        icon={
          <div className="highed-icon-picker">
            {/* <div dangerouslySetInnerHTML={{ __html: icon.data.src }} style={{ height: '16px', width: '16px' }} /> */}
          </div>
        }
        className="rounded-sm ml-2 p-1 px-2"
        buttonColor={ButtonColor.Grey}
        onClick={onClick}
      />
    </FieldContainer>
  );
};
