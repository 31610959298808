import { Chart } from 'highcharts';

type ExtendedChartType = Chart & { isDataTableVisible?: boolean; hideData: () => void };

export const resetChartDataTableView = () => {
  Highcharts.charts.forEach((chart) => {
    if ((chart as ExtendedChartType)?.isDataTableVisible) {
      (chart as ExtendedChartType).hideData();
    }
  });
};

export const getOriginalExportSize = (aggregatedOptions: any) => {
  const exporting = aggregatedOptions?.exporting ?? {};
  return {
    width: exporting.sourceWidth ?? null,
    height: exporting.sourceHeight ?? null
  };
};
