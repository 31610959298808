import { all, call, put, takeLatest } from 'redux-saga/effects';
// @ts-expect-error ts-2307
import {
  getDivisionThemesAllWithDivisionid,
  getDivisionThemesDeletedWithDivisionid,
  getDivisionThemesSearchWithDivisionid,
  getTeamCompanythemesDeletedWithTeamid,
  getTeamCompanythemesWithTeamid,
  getTeamThemesAllWithTeamid,
  getTeamThemesDeletedWithTeamid,
  getTeamThemesSearchWithTeamid,
  postTeamChartmetaUsingTeamidAndChartid,
  postTeamCompanythememetaUsingTeamidAndThemeid
} from '../../../api/cloudApiGenerated';
import paths from '../../../paths';
import { snackBar } from 'editor/editors/highed.init';
import actionTypes from '../../../redux/actions/action-types';
import { setAction } from '../actions/themesPage';

export function* getTeamThemes(params) {
  let { team, division, query, deleted, paths, page, pageSize } = params.data;
  let getThemesAjax = function () {
    if (deleted) {
      if (division && division.id !== null) return getDivisionThemesDeletedWithDivisionid(division.id, page, pageSize);
      else return getTeamThemesDeletedWithTeamid(team.id, page, pageSize);
    } else {
      let searched = query && query.length > 0;
      if (division && division.id !== null) {
        return searched
          ? getDivisionThemesSearchWithDivisionid(division.id, query, page, pageSize)
          : getDivisionThemesAllWithDivisionid(division.id, page, pageSize);
      } else {
        return searched
          ? getTeamThemesSearchWithTeamid(team.id, query, page, pageSize)
          : getTeamThemesAllWithTeamid(team.id, page, pageSize);
      }
    }
  };

  try {
    let data = yield call(getThemesAjax);

    data.data = data.data.map((theme) => {
      const editLink = paths.editTheme.replace(':themeid', theme.id);
      const editLayout = paths.editLayout.replace(':storyid', theme.id);
      const editTable = paths.editTable.replace(':tableid', theme.id);

      let link = editLink;
      if (theme.chart_type === 4) link = editLayout;
      else if (theme.chart_type === 5) link = editTable;
      theme.href = link;

      return theme;
    });

    yield put(
      setAction({
        page: page,
        pageSize: pageSize,
        pageCount: data.pageCount,
        themes: data.data,
        dataReturned: true
      })
    );
  } catch (error) {
    yield put(
      setAction({
        error: 'loading themes: ' + error.message || error.toString()
      })
    );
  }
}

export function* getCompanyTheme(params) {
  let { team, deleted } = params.data;
  try {
    let req = getTeamCompanythemesWithTeamid;
    if (deleted) req = getTeamCompanythemesDeletedWithTeamid;

    let data = yield call(req, team.id);

    (data ?? []).forEach((d) => {
      d.href = paths.editCompanyTheme.replace(':themeid', d.id);
    });

    yield put(
      setAction({
        companyThemes: data
      })
    );
  } catch (error) {
    console.log(error);
    yield put(
      setAction({
        error: 'loading company theme: ' + error.message || error.toString()
      })
    );
  }
}

export function* updateThemeData(params) {
  const { team, theme, isCompanyTheme, formData, refreshThemes, handleModalClose } = params.data;

  const updateTheme = () => {
    if (isCompanyTheme) {
      return postTeamCompanythememetaUsingTeamidAndThemeid(team.id, theme.id, { name: formData.themeName });
    } else {
      return postTeamChartmetaUsingTeamidAndChartid(team.id, theme.id, {
        name: formData.themeName,
        division: formData.divisionId
      });
    }
  };

  try {
    yield call(updateTheme);
    if (refreshThemes) refreshThemes();
    snackBar('Details updated');
    handleModalClose();
  } catch (error) {
    console.warn(error);
  }
}

/** Watch functions */
export function* watchGetTeamThemes() {
  yield takeLatest(actionTypes.themesPage.getTeamThemes, getTeamThemes);
}

export function* watchGetCompanyTheme() {
  yield takeLatest(actionTypes.themesPage.getCompanyTheme, getCompanyTheme);
}

export function* watchUpdateThemeData() {
  yield takeLatest(actionTypes.themesPage.updateThemeData, updateThemeData);
}
// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchGetTeamThemes(), watchGetCompanyTheme(), watchUpdateThemeData()]);
}
