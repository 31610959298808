import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import SvgIconButton from './buttons/SvgIconButton';
import ChevronDown from 'static/icons/arrow_down.svg';
import ChevronUp from 'static/icons/arrow_up.svg';
import { DivMouseHandler } from './types/commonPropTypes';
import { ButtonColor } from './buttons/types/ButtonModels';
import { AccordionType, getClassesForAccordionType } from './utils/accordionHelper';

export type AccordionProps = {
  headerText: string | React.ReactNode;
  children: React.ReactNode;
  selected?: boolean;
  onClick?: DivMouseHandler;
  selectedChildren?: string;
  accordionClasses?: string;
  childrenClasses?: string;
  accordionType?: AccordionType;
  useInternalSelect?: boolean;
  useKey?: string;
  onMouseEnter?: DivMouseHandler;
  onMouseLeave?: DivMouseHandler;
  defaultSelection?: boolean;
  smallHeaderText?: boolean;
};

const Accordion = (props: AccordionProps) => {
  const [isSelected, changeIsSelected] = useState(props.defaultSelection ?? true);
  useEffect(() => changeIsSelected(isSelected), [isSelected]);
  const toggleIsSelected = () => changeIsSelected(!isSelected);

  const { useInternalSelect } = props;
  const selected = useInternalSelect ? isSelected : props.selected;
  const childrenDivClasses = classNames('option-properties', props.childrenClasses, {
    'rounded mx-6 py-2 relative flex-col': !props.childrenClasses,
    flex: selected
  });
  const accordionClasses = classNames('flex flex-col rounded', props.accordionClasses, {
    [`customize-dropdown-${props.useKey}`]: props.useKey,
    'bg-ev-grey': !props.accordionClasses
  });

  const containerClasses = classNames(
    'rounded clickable relative font-bold text-base',
    getClassesForAccordionType(props.accordionType || AccordionType.Regular),
    { selected }
  );

  return (
    <div
      className={accordionClasses}
      key={props.useKey}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className={containerClasses} onClick={useInternalSelect ? toggleIsSelected : props.onClick}>
        <div className="flex w-full justify-between flex-wrap items-center gap-1">
          <span className={`text-ev-navy-blue-2 ${props.smallHeaderText ? 'text-sm' : 'text-base'}`}>
            {props.headerText}
          </span>
          {props.selectedChildren && <span className="text-ev-dark-purple text-sm">{props.selectedChildren}</span>}
        </div>
        <SvgIconButton
          Icon={selected ? ChevronUp : ChevronDown}
          width={14}
          height={9}
          buttonClasses="absolute top-3 right-4 h-4"
          buttonColor={ButtonColor.Transparent}
          onClick={() => null}
        />
      </div>

      {selected && <div className={childrenDivClasses}>{props.children}</div>}
    </div>
  );
};

export default Accordion;
