export default {
  error: false,
  permissions: [],
  roleId: undefined,
  rolePermissionsDb: [],
  rolePermissions: [],
  modalConfig: '',
  newRoleName: '',
  unsavedChanges: false
};
