import { cloneDeep, merge, isString } from 'lodash';
import api from 'utils/ajax';
import { ImageMimeType } from '../meta/ImageOptions';
import { VideoMimeType } from '../meta/VideoOptions';
import { ProviderTypes } from 'pages/ChartEditorPage/meta/templates/types';
import { isGeoJsonMap } from 'pages/ChartEditorPage/utils/geoJsonHelper';
import { WindowProps } from 'shared/widgets/FontStyler';
import store from 'redux/store';

type DownloadVideoProps = {
  constr: string;
  setGeneratingVideo: (value: boolean) => void;
  aggregatedOptions: any;
  type: VideoMimeType | ImageMimeType;
  uuid: string | boolean;
  user: {
    email: string;
  };
  loop: '-1' | '0'; // For gifs - FFMPEG prop (-1 = no loop, 0 = loop)
  teamId?: string | undefined;
  chartId?: string | null;
  projectName: string;
  exportedBy: string;
  provider: ProviderTypes;
  shouldCallRecentExport?: (value: boolean) => void;
};

export const downloadVideo = async ({
  constr,
  setGeneratingVideo,
  aggregatedOptions,
  type,
  exportedBy,
  uuid,
  user,
  loop,
  teamId,
  chartId,
  projectName,
  shouldCallRecentExport,
  provider
}: DownloadVideoProps) => {
  const { themeData, packageProject } = store.getState().packagesPage;

  let chartType = constr;
  const isPackage = packageProject.project_uuid;

  if (chartType === 'Chart') chartType = 'chart';

  let projectType = 'chart';
  if (isPackage) projectType = 'package';
  else if (provider === 'locationMap') {
    chartType = 'locationMap';
    projectType = 'locationMap';
  }

  setGeneratingVideo(true);

  const options = cloneDeep(aggregatedOptions);
  const isCustomGeoJson = options?.chart?.map && isString(options.chart.map) && isGeoJsonMap(options.chart.map);

  if (isCustomGeoJson) {
    options.chart.map = (window as WindowProps)?.Highcharts?.maps?.[options.chart.map];
  }

  if (options.everviz?.text?.dynamicFonts) {
    merge(options, {
      exporting: {
        chartOptions: {
          ...(options?.exporting?.chartOptions ?? {}),
          //@ts-expect-error ts-2339
          ...(window?.everviz?.responsiveFontOptions ?? {})
        }
      }
    });
  }

  const getFormattedFileName = (name: string) => {
    let words = name.split(' ');
    words = words.length > 3 ? words.slice(0, 3) : words;
    return words.join('-');
  };

  const fileName = getFormattedFileName(projectName);
  const { hostname } = (window as any).hcconfig.backend;
  const baseUrl = `${hostname}`;

  const exportUrl = `${baseUrl}/video/export`;
  api.ajax({
    type: 'post',
    url: exportUrl,
    data: {
      options: JSON.stringify(options),
      themeData: JSON.stringify(themeData),
      type,
      constr: chartType,
      exportedBy,
      projectType,
      cssModules: JSON.stringify((window as any)?.Everviz?.externalCSS || []),
      pluginConfig: JSON.stringify({}),
      email: user.email,
      uuid: isPackage ? packageProject.uuid : uuid,
      project_uuid: isPackage ? packageProject.project_uuid : null,
      filename: fileName,
      teamId: teamId,
      chartId: chartId,
      loop
    }
  });

  setTimeout(() => {
    setGeneratingVideo(false);
    if (shouldCallRecentExport) shouldCallRecentExport(true);
  }, 13000);
};
