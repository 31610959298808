import { DateFormatType } from 'shared/types/dateFormatType';

export const dateFormatOptions: DateFormatType[] = [
  { label: 'Auto', value: '' },
  { label: 'YYYY MM DD', value: 'YYYY/mm/dd', regex: /^([0-9]{4})[-./]([0-9]{1,2})[-./]([0-9]{1,2})$/ },
  { label: 'DD MM YYYY', value: 'dd/mm/YYYY', regex: /^([0-9]{1,2})[-./]([0-9]{1,2})[-./]([0-9]{4})$/ },
  { label: 'MM DD YYYY', value: 'mm/dd/YYYY', regex: /^([0-9]{1,2})[-./]([0-9]{1,2})[-./]([0-9]{4})$/ },
  { label: 'DD MM YY', value: 'dd/mm/YY', regex: /^([0-9]{1,2})[-./]([0-9]{1,2})[-./]([0-9]{2})$/ },
  { label: 'MM DD YY', value: 'mm/dd/YY', regex: /^([0-9]{1,2})[-./]([0-9]{1,2})[-./]([0-9]{2})$/ }
];
