import React from 'react';
import Modal from '../../../shared/modal/Modal';
import ProjectsView from '../../ProjectsPage/components/ProjectsView';
import LayoutChart from './LayoutsPageChart';
import Placeholder from './LayoutsPageChartPlaceholder';
import useInput from '../../../shared/utils/useInput';
import useToggle from '../../../shared/utils/useToggle';

const ChartModal = (props) => {
  const image = useInput('');
  const [showAddImageMenu, toggleShowAddImageMenu] = useToggle();

  const addImageMenu = (
    <div className="flex bg-white py-4 px-2 mb-8 rounded-md shadow-inner justify-center items-center space-x-2">
      <input type="text" {...image} placeholder="Add an image" />
      <button className="bulk-action-btn" onClick={() => props.addImageToGrid(image.value)}>
        Use
      </button>
    </div>
  );

  const modalViewSwitcher = (
    <p>
      {props.showImageInput && showAddImageMenu ? 'Attach an image' : 'Select a chart'}
      {props.showImageInput && (
        <button className="ml-2 text-xs text-ev-blue hover:underline" onClick={toggleShowAddImageMenu}>
          {showAddImageMenu ? 'or add a chart' : 'or add an image'}
        </button>
      )}
    </p>
  );

  return (
    <div className={props.isOpen ? 'dimmer translucent' : ''}>
      <Modal
        title="Add project"
        isOpen={props.isOpen}
        onClose={props.close}
        bg="bg-ev-yellow-1"
        allowOverflow={true}
        bodyClass="overflow-auto p-8"
      >
        {modalViewSwitcher}
        {showAddImageMenu ? addImageMenu : <ProjectsView simple={true} with={LayoutChart} placeholder={Placeholder} />}
      </Modal>
    </div>
  );
};

export default ChartModal;
