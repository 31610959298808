export enum AccordionType {
  Compact = 'Compact',
  Regular = 'Regular'
}

export const getClassesForAccordionType = (type: AccordionType): string => {
  switch (type) {
    case AccordionType.Compact:
      return 'flex items-center pr-4 pl-0 py-0 text-base group-hover:bg-ev-baby-blue-2 group-hover:text-ev-navy-blue-2 group-active:bg-ev-navy-blue-2 group-active:text-white';
    case AccordionType.Regular:
      return 'flex items-center min-h-[2.5rem] py-2 pl-4 pr-12 text-base hover:bg-ev-baby-blue-2 hover:text-ev-navy-blue-2';
  }
};
