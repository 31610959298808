import { TemplateProps } from '../../types';

const DonutWithLegend: TemplateProps = {
  title: 'Donut w/ legend',
  description: '',
  constructor: '',
  thumbnail: 'arutag.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'pie',
      polar: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: true,
        showInLegend: true,
        innerSize: '60%',
        dataLabels: {
          enabled: false
        }
      }
    }
  },
  sampleSet: 'pie',
  dataValidator: false,
  advancedParent: 'Donut',
  advanced: {
    name: 'Donut + legend'
  }
};

export default DonutWithLegend;
