import classNames from 'classnames';
import React from 'react';
import StarIcon from 'static/icons/star.svg';

type UpsellCardProps = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  title?: string;
  text?: string;
};

export default function UpsellCard(props: UpsellCardProps) {
  const containerClassName = classNames(
    'project-card cursor-pointer py-6 px-4 group hover:bg-ev-baby-blue-2 active:bg-ev-navy-blue-2 focus:border focus:border-ev-dark-purple',
    {
      'border-dashed border border-ev-light-orange shadow-none': props.disabled
    },
    props.className
  );

  return (
    <div className={containerClassName} onClick={props.onClick}>
      <div className={'flex flex-col relative justify-between'}>
        <div className={'rounded-t-md relative cursor-pointer overflow-hidden h-10'}>
          {props.disabled && (
            <StarIcon
              height={40}
              width={40}
              className="absolute top-0 right-1/2 translate-x-1/2 group-active:text-white"
            />
          )}
          {!props.disabled && (
            <div className={'rounded-full w-8 h-8 text-center bg-ev-navy-blue-2 mx-auto group-active:bg-white'}>
              <i className={'fa fa-plus text-base relative top-[4px] text-white group-active:text-ev-navy-blue-2'} />
            </div>
          )}
        </div>

        <div className="rounded-b-md text-center">
          <span className="text-2xs font-bold theme-text text-ev-dark-purple group-active:text-ev-baby-blue-2">
            {props.title}
          </span>

          <span className={'font-bold block text-xs italic underline group-active:text-white'}>
            {props.disabled && 'Upgrade to unlock'}
            {!props.disabled && props.text}{' '}
            <span>
              <i className="fa fa-arrow-up-right-from-square" />
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
