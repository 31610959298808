export default {
  error: false,
  allOptions: null,
  hideDefaultThemes: false,
  //data templates themes customize publish export help-cloud
  features: [
    {
      templates: {
        id: 0,
        checked: false,
        text: 'Templates tab'
      },
      themes: {
        id: 1,
        checked: false,
        text: 'Themes tab'
      },
      customize: {
        id: 2,
        checked: false,
        text: 'Customize chart tab'
      }
    },
    {
      publish: {
        id: 3,
        checked: false,
        text: 'Publish tab'
      },
      export: {
        id: 4,
        checked: false,
        text: 'Export tab'
      }
    }
  ],
  editorFeatures: {
    Advanced: {
      id: 0,
      checked: false,
      text: 'Advanced'
    },
    CustomCode: {
      id: 1,
      checked: false,
      text: 'Custom code'
    },
    PreviewOptions: {
      id: 2,
      checked: false,
      text: 'Preview options'
    }
  },
  userOptions: [],
  showDeleteFontModal: false,
  showDeleteLogoModal: false,
  showFontModal: false,
  fonts: [],
  logos: [],
  selectedFont: null,
  selectedLogo: null
};
