import React from 'react';
import PropTypes from 'prop-types';
import Templates from './Templates';
import PanelContainer from 'shared/editor/generic/PanelContainer';

class Layout extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <PanelContainer active={this.props.active} className="pt-6">
        <Templates inWizard={false} />
      </PanelContainer>
    );
  }
}

Layout.propTypes = {
  updateStyle: PropTypes.func,
  active: PropTypes.bool
};

export default Layout;
