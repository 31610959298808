export const getRichtextBoxPosition = (box: HTMLElement, container: Element | null) => {
  const bounding = box.getBoundingClientRect();
  const offsetY = 66; // TODO: remove magic numbers
  let offsetX = 0; // TODO: remove magic numbers
  const navBar = document.querySelector('.navigation-container-vertical');

  if (navBar) {
    const navSize = navBar.getBoundingClientRect();
    offsetX = navSize.width;
  }
  let left = bounding.left - offsetX,
    top = bounding.top - offsetY;

  if (container) {
    const containerSize = container.getBoundingClientRect();
    if (left + containerSize.width > document.body.clientWidth - containerSize.width) {
      left = document.body.clientWidth - containerSize.width - 10 - offsetX;
    }

    if (top + containerSize.height > document.body.clientHeight - containerSize.height) {
      top = document.body.clientHeight - containerSize.height - 10 - offsetY;
    }
  }
  return { top: `${top}px`, left: `${left}px` };
};
