import { TemplateProps } from '../../types';

const Logarithmic: TemplateProps = {
  title: 'Logarithmic',
  description: '',
  constructor: '',
  thumbnail: 'imykus.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column',
      inverted: true,
      polar: false
    },
    yAxis: {
      type: 'logarithmic',
      minorTickInterval: 'auto',
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'bar',
  advancedParent: 'Bar',
  dataValidator: false
};

export default Logarithmic;
