import React from 'react';
import ChevronRight from 'static/icons/chevron-right.svg';
import ChevronLeft from 'static/icons/chevron-left.svg';

type LayoutTabProp = {
  dataOptions: any;
  activeCarouselIndex: number;
  changePage: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function LayoutTab({ dataOptions, activeCarouselIndex, changePage }: LayoutTabProp) {
  const tabData = dataOptions[activeCarouselIndex];
  const tabArrowClass = 'w-5 h-5';

  return (
    <div className={'ev-sm:hidden flex justify-center items-center text-ev-navy-blue'}>
      {dataOptions.length > 1 && activeCarouselIndex !== 0 && (
        <button data-value={activeCarouselIndex - 1} onClick={changePage} className={tabArrowClass}>
          <ChevronLeft />
        </button>
      )}
      <div className={'ev-sm:hidden m-2 px-3 py-1.5 border rounded border-ev-navy-blue'}>
        {tabData?.name || 'Tab ' + (activeCarouselIndex + 1)}
      </div>
      {dataOptions.length > 1 && activeCarouselIndex !== dataOptions.length - 1 && (
        <button data-value={activeCarouselIndex + 1} onClick={changePage} className={tabArrowClass}>
          <ChevronRight />
        </button>
      )}
    </div>
  );
}
