import { FunctionComponent, SVGAttributes } from 'react';
import CodeBracket from 'static/icons/code-bracket.svg';
import LiveVideo from 'static/icons/live-video.svg';
import Online from 'static/icons/online.svg';
import PDFPrint from 'static/icons/pdf-print.svg';
import SocialMedia from 'static/icons/social-media.svg';
import VideoCamera from 'static/icons/video-camera.svg';

export enum ChannelOption {
  Online = 'Online',
  Social = 'Social',
  Image = 'Image',
  Video = 'Video',
  LiveVideo = 'LiveVideo',
  Code = 'Code'
}

export type ChannelOptionsType = {
  id: ChannelOption;
  icon: FunctionComponent<SVGAttributes<SVGElement>>;
  text: string;
  lockedBehind: 'PLAN' | 'FEATURE';
};

export const ChannelOptions: ChannelOptionsType[] = [
  {
    id: ChannelOption.Online,
    icon: Online,
    text: 'Online',
    lockedBehind: 'PLAN'
  },
  {
    id: ChannelOption.Social,
    icon: SocialMedia,
    text: 'Social',
    lockedBehind: 'PLAN'
  },
  {
    id: ChannelOption.Image,
    icon: PDFPrint,
    text: 'PDF/print',
    lockedBehind: 'PLAN'
  },
  {
    id: ChannelOption.Code,
    icon: CodeBracket,
    text: 'Code',
    lockedBehind: 'PLAN'
  },
  {
    id: ChannelOption.Video,
    icon: VideoCamera,
    text: 'Video',
    lockedBehind: 'PLAN'
  },
  {
    id: ChannelOption.LiveVideo,
    icon: LiveVideo,
    text: 'Live video',
    lockedBehind: 'FEATURE'
  }
];
