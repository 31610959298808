/* eslint-disable max-lines */
import { Chart } from 'highcharts';
import { cloneDeep } from 'lodash';
import { customAxisDashStyleOptions } from 'pages/CompanyThemeEditorPage/utils/customizeEditorHelper';
import { default as getTemplateType } from '../../../editor/meta/highed.basic.template.type';
import {
  axisLabelFormatOptions,
  axisTypeOptions,
  colorAxisTypeOptions,
  dashStyleOptions,
  dataLabelFormatOptions,
  markerPointOptions,
  markerPointRefersTo,
  seriesTypeListOptions,
  yAxisTitleAlignValue
} from '../../../editor/meta/highed.format.options';
import { handleYAxisMaxChange, handleYAxisMinChange } from '../utils/chartEditorCustomizeHelper';
import { UnknownParamsFunction } from './../../../shared/types/commonPropTypes';
import { FourInputProps } from 'shared/widgets/FourInputWidget';
import highlights from './annotations/AnnotationsHighlights';
import labels from './annotations/AnnotationsLabels';
import lines from './annotations/AnnotationsLines';
import { addSecondAxis } from './addSecondAxisHelper';
import { WindowProps } from 'shared/widgets/FontStyler';
import { isTrialPlan } from '../utils/customizeHelper';

export const checkSeriesAxisCompat = (aggregatedOptions: any) => {
  let type = 'line';
  const options = [
    'pie',
    'timeline',
    'wordcloud',
    'sankey',
    'dependencywheel',
    'parliament',
    'item',
    'funnel',
    'packedbubble',
    'treemap'
  ];

  if (aggregatedOptions?.chart?.type) {
    type = aggregatedOptions.chart.type;
  }
  return !options.includes(type);
};

export type SelectProps = { label: unknown; value: unknown };
export type WidgetType =
  | 'default'
  | 'string'
  | 'number'
  | 'fontpicker'
  | 'fontstyler'
  | 'link'
  | 'select'
  | 'positioner'
  | 'hpositioner'
  | 'fourinputs'
  | 'tablechart'
  | 'columnselector'
  | 'chartarray'
  | 'toggle'
  | 'color'
  | 'highcharts.colorstring'
  | 'select<string>'
  | 'text'
  | 'checkbox'
  | 'boolean'
  | 'range'
  | 'notice'
  | 'dependencyselect'
  | 'richtextgroup'
  | 'tooltiphelper'
  | 'colorstops'
  | 'colorcategories'
  | 'highlights'
  | 'dropdowninput'
  | 'icon'
  | 'placementalignwidget'
  | 'array<color>'
  | 'array<string>'
  | 'array<number>'
  | 'array<icon>'
  | 'array<accordion>'
  | 'array<checkbox>'
  | 'array'
  | 'separator'
  | 'action'
  | 'highcharts.cssobject'
  | 'accordion'
  | 'dynamicaccordion'
  | 'placementwidget'
  | 'richtextwidget'
  | 'newicon';

export type PostFieldsProps = {
  options: OptionProps[];
  optionNeeded?: (boolean | string)[] | ((aggregatedOptions: any, value: any) => boolean);
  className?: string;
};

export type OptionProps = {
  /** Highcharts path to property split up by a period */
  id?: string;
  /** id used for getting localization label in en.js */
  pid?: string;
  /** Type of input used */
  type?: WidgetType | 'number' | 'subheader' | 'subtext';
  /** Default value to use for this input */
  default?: unknown;
  /** Override text for id */
  text?: string;
  /** Class name to add to input */
  className?: string;
  /** Temp fix for margin in children classes in customize section. Only used in marker */
  childrenClass?: string;
  /** Background coloring of the input */
  backgroundColor?: string;
  /** Text coloring of the label */
  labelClasses?: string;
  /** Temp fix for coloring input inside customize section. Only used in accordionWidget */
  inputClassName?: string;
  /** Build up default value through an array. Ex: chart.style.fontFamily -> title.style.fontFamily. */
  nestedValue?: string[];
  /** Remove this option inside map editor */
  mapDisabled?: boolean;
  /** Should the field be disabled */
  shouldBeDisabled?: (aggregatedOptions: any) => boolean;
  /** Should the field be disabled */
  disabledText?: string;
  /** Determines whether this property should be shown */
  blacklist?: UnknownParamsFunction | unknown[];
  /** Used in dependency select widget for choosing which inputs to show */
  filteredOptions?: OptionProps[];
  /** Used to get the property to blacklist on. If function, return the filteredBy value ex: ("line"). If string, return the property to search for in aggregatedOptions ex: ("chart.type") */
  filteredBy?: string | ((group: OptionProps, chart?: Chart) => any[]);
  /** If it is a subheader. Only display text, no widget. */
  isSubheader?: boolean;
  /** Image used alongside field (Border and BorderRadius fields) */
  image?: string;
  /** Which template type to show this field on (used with filteredBy) */
  subType?: string[];
  /** Property can be accessed either through an array or object. Need to check both. (XAxis/YAxis/Color Axis) */
  isOptionalArray?: boolean;
  /** Template type specific default value */
  subTypedefault?: unknown;
  /** Select options for use with the select widget type */
  values?: SelectProps[];
  subTypeValues?: Record<string, SelectProps[]>;
  /** Used for item/parliament chart. Icon picker hooks into data */
  isBasedOnSeriesData?: boolean;
  /** When updating/extracting value for marker richtext */
  isBasedOnStyle?: boolean;
  /** When extracting value, use customizedOptions instead of aggregatedOptions */
  useCustomizedOptions?: boolean;
  /** Set additional fields when changing the value of this input */
  refersTo?: unknown;
  /** Default filterBy type to use */
  defaultFilterValue?: string | string[];
  /** Hide label, only show input widget */
  hideLabel?: boolean;
  /** Chart type this is valid for */
  projectType?: ('map' | 'chart' | 'table')[];
  /** Map type this is valid for */
  templateType?: string[];
  /** Used in customize editor screen to lay these out properly */
  group?: number;
  /** Data label section, the raw key for the property so we can switch out plotOptions/series */
  rawKey?: string;
  /** Is data label decimal value */
  isDecimalValue?: boolean;
  /** Add this to the end of the value user has entered in */
  suffix?: string;
  isBasedOnData?: {
    /** Wordcloud, this field relies on the data */
    type: string;
  };
  controlledBy?: {
    // Fields in the options prop in this object are controlled by this input (ex: Series selector)
    options?: string;
    type?: string;
    optionsTitle?: string;
    /** Default option to pick */
    default?: unknown;
    /** Values to add at the top of the select list (ex: All series) */
    preValues?: SelectProps[];
  };
  /** Inline editor specific properties */
  inline?: {
    /** Used for inline editing. For locating where to find prop inside highcharts chart object */
    highchartsId?: string | string[];
    /** Used for inline editing. For locating where to find prop inside highcharts chart svg */
    source?: string | ((container: HTMLElement) => NodeListOf<Element> | []);
    /** Used for axis props. We search Highcharts chart for axis index to use this instead of the one in the id prop. */
    isAxis?: boolean;
  };
  /** Richtext specific properties */
  richtext?: {
    /** For locating where to find prop default text inside highcharts chart object */
    highchartsDefault?: string;
    /** For locating where to find prop default styling inside highcharts chart object */
    highchartsStyleDefault?: string;
    /** The root prop to change */
    rawId?: string;
    /** Hacky fix to remove watermark from highcharts credits field*/
    removeWatermark?: boolean;
    /** Dont set the useHTML highcharts prop for this (Tooltip helper)*/
    skipUseHTML?: boolean;
    noHighchartOptions?: boolean;
    /** Key for the property to change without the root property added */
    rawKey?: string;
  };
  /** FourInputWidget specific properties */
  fourInputs?: FourInputProps;
  /** Used for range picker */
  custom?: {
    /** Initial value used for state reset */
    initialValue?: string | number | null;
    /** Default value for Single item in array widget */
    defaultItemValue?: string;
    /** Min value of number picker */
    minValue?: number;
    /** Max value of number picker */
    maxValue?: number;
    /** Steps to increment up in */
    step?: number;
    /** Font picker simple mode */
    simple?: boolean;
    /** Hide add button for array widget */
    hideAddButton?: boolean;
    /** Hide delete buttons for array widget */
    hideDeleteButtons?: boolean;
    /** Display array widget as rows (Default columns) */
    showAsRows?: boolean;
    /** Can array be sorted. (Only works for color widget right now) */
    isSortable?: boolean;
    /** Show gradient tab in color picker */
    showGradientColorTab?: boolean;
    /** If using an array accordian and only one object exists, display it all out in open. */
    openIfOne?: boolean;
    /** If using an array accordian, whether to use the options name value as a label rather than a fixed title */
    useNameValueAsLabel?: boolean;
    /** Used to highlight a series in the chart */
    highlightSeries?: boolean;
    fields?: {
      bold?: number;
      italic?: number;
      align?: number;
      color?: number;
      fontSize?: number;
    };
    placement?: {
      topLeft?: number;
      bottomLeft?: number;
      topCenter?: number;
      bottomCenter?: number;
      topRight?: number;
      bottomRight?: number;
    };
    displayUndoButton?: boolean;
    defaultSelection?: boolean;
  };
  /** Annotations specific properties */
  annotations?: {
    /** Used for annotations */
    isType?: boolean;
    /** Used for annotations */
    isText?: boolean;
    isOrientation?: boolean;
    fixMarkers?: boolean;
  };
  options?: OptionProps[];
  postFields?: PostFieldsProps[];
  isPagination?: boolean;
  pathLink?: string;
  linkText?: string;
  useLogos?: boolean;
  basedOnColumns?: boolean;
  timeout?: number;
  /** Used in the advanced section for the label */
  title?: string;
  /** A callback to modify aggregatedOptions */
  onOptionsChange?: (...params: any) => any;
  /** A callback to modify aggregatedOptions when hiding dynamic accordion */
  onHide?: (aggregatedOptions: any) => any;
  /** A key used in dynamic accordion to locate a specific part of aggregatedOptions */
  onChangeKey?: string;
  /** Used in advanced section */
  types?: {
    array?: string;
  };
  rootPath?: string;
  /** A boolean to control displaying separator line in DependencySelectWidget*/
  showSeparatorLine?: boolean;
  /** A description used for fields in the advanced section */
  description?: string;
  /** Used in the company theme editor for setting a different key from the id */
  aliasKey?: string[];
  /** Plugins that should be toggled if the setting is clicked */
  plugins?: string[];
};

const options: Record<string, OptionProps[]> = {
  'option.cat.text': [
    {
      id: 'text-category',
      pid: 'text.category',
      text: 'option.subcat.text',

      options: [
        {
          id: 'title.text',
          pid: 'title.text',
          type: 'richtextgroup',
          default: {
            text: 'Chart title'
          },
          richtext: {
            rawId: 'title',
            rawKey: 'text'
          },
          inline: {
            highchartsId: 'title.alignOptions',
            source: '.highcharts-container .highcharts-title'
          },
          className: 'title-text',
          nestedValue: ['chart.style.fontFamily'] // Currently only used in fontpicker widget
        },
        {
          id: 'subtitle.text',
          richtext: {
            rawId: 'subtitle',
            rawKey: 'text'
          },
          pid: 'subtitle.text',
          type: 'richtextgroup',
          inline: {
            highchartsId: 'subtitle.alignOptions',
            source: '.highcharts-container .highcharts-subtitle'
          },
          className: 'subtitle-text',
          nestedValue: ['chart.style.fontFamily']
        },
        {
          id: 'caption.text',
          richtext: {
            rawId: 'caption',
            rawKey: 'text'
          },
          pid: 'caption.text',
          type: 'richtextgroup',
          inline: {
            highchartsId: 'caption.alignOptions',
            source: '.highcharts-container .highcharts-caption'
          },
          className: 'caption-text',
          nestedValue: ['chart.style.fontFamily']
        },
        {
          id: 'credits.text',
          pid: 'credits.text',
          type: 'richtextgroup',
          inline: {
            highchartsId: ['credits.styles', 'credits.textStr'],
            source: '.highcharts-container .highcharts-credits'
          },
          richtext: {
            highchartsStyleDefault: 'credits.styles',
            rawId: 'credits',
            removeWatermark: true,
            rawKey: 'text'
          },
          className: 'credits-text',
          nestedValue: ['chart.style.fontFamily'],
          shouldBeDisabled: () => isTrialPlan(),
          disabledText: 'Upgrade your plan to enable this field.'
        },
        {
          id: 'credits.href',
          pid: 'href',
          default: 'https://app.everviz.com',
          shouldBeDisabled: () => isTrialPlan(),
          disabledText: 'Upgrade your plan to enable this field.'
        },
        {
          id: 'xAxis[0].title.text',
          pid: 'xAxis-title.text',
          type: 'richtextgroup',
          inline: {
            highchartsId: ['xAxis[0].axisTitle.styles', 'xAxis[0].axisTitle.textStr'],
            source: '.highcharts-container .highcharts-xaxis .highcharts-axis-title',
            isAxis: true
          },
          mapDisabled: true,
          blacklist: checkSeriesAxisCompat,
          className: 'xAxis-title-text',
          nestedValue: ['chart.style.fontFamily'],
          richtext: {
            rawId: 'xAxis.title',
            highchartsDefault: 'axisTitle',
            highchartsStyleDefault: 'xAxis.axisTitle.styles',
            rawKey: 'text'
          },
          isOptionalArray: true
        },
        {
          id: 'yAxis[0].title.text',
          pid: 'yAxis-title.text',
          type: 'richtextgroup',
          richtext: {
            highchartsDefault: 'axisTitle',
            highchartsStyleDefault: 'yAxis.axisTitle.styles',
            rawId: 'yAxis.title',
            rawKey: 'text'
          },
          mapDisabled: true,
          blacklist: checkSeriesAxisCompat,
          inline: {
            highchartsId: ['yAxis[0].axisTitle.styles', 'yAxis[0].axisTitle.textStr'],
            source: '.highcharts-container .highcharts-yaxis .highcharts-axis-title',
            isAxis: true
          },
          className: 'yAxis-title-text',
          nestedValue: ['chart.style.fontFamily'],
          isOptionalArray: true
        }
      ]
    }
  ],
  'option.cat.appearance': [
    {
      text: 'option.util.empty',

      options: [
        {
          id: 'chart.style',
          pid: 'chart.style',
          type: 'fontpicker',
          custom: {
            simple: true
          },
          default: {
            fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
            fontSize: '12px'
          }
        },
        {
          id: 'everviz.text.dynamicFonts',
          pid: 'everviz.text.dynamicFonts',
          type: 'checkbox',
          default: false
        },
        {
          type: 'separator',
          mapDisabled: true
        },
        {
          pid: 'option.subcat.colors',
          isSubheader: true,
          type: 'subheader',
          default: 'option.subcat.colors',
          mapDisabled: true
        },
        {
          id: 'colors',
          pid: 'colors',
          type: 'array<color>',
          custom: {
            showAsRows: true,
            isSortable: true,
            defaultItemValue: '#000000',
            showGradientColorTab: true
          },
          mapDisabled: true,
          filteredBy: 'chart.type',
          blacklist: ['heatmap', 'solidgauge'],
          default: [
            '#7cb5ec',
            '#434348',
            '#90ed7d',
            '#f7a35c',
            '#8085e9',
            '#f15c80',
            '#e4d354',
            '#2b908f',
            '#f45b5b',
            '#91e8e1'
          ]
        },
        {
          type: 'separator'
        },
        {
          id: 'chart.backgroundColor',
          pid: 'chart.backgroundColor',
          type: 'color',
          default: '#FFFFFF',
          custom: {
            showGradientColorTab: true
          }
        },
        {
          id: 'chart.borderColor',
          pid: 'chart.borderColor',
          type: 'color',
          default: '#335cad',
          custom: {
            showGradientColorTab: true
          }
        },
        {
          id: 'chart.borderWidth',
          custom: {
            minValue: 0
          },
          pid: 'chart.borderWidth',
          type: 'number',
          default: '0'
        },
        {
          id: 'chart.borderRadius',
          custom: {
            minValue: 0
          },
          pid: 'chart.borderRadius',
          type: 'number',
          default: '0'
        },
        {
          type: 'separator'
        },
        {
          id: 'chart.plotBackgroundColor',
          pid: 'chart.plotBackgroundColor',
          type: 'color',
          custom: {
            showGradientColorTab: true
          }
        },
        {
          id: 'chart.plotBorderColor',
          pid: 'chart.plotBorderColor',
          type: 'color',
          default: '#cccccc',
          custom: {
            showGradientColorTab: true
          }
        },
        {
          id: 'chart.plotBorderWidth',
          pid: 'chart.plotBorderWidth',
          type: 'number',
          default: '0'
        },
        {
          type: 'separator'
        },
        {
          id: 'everviz.chart.backgroundImage',
          pid: 'everviz.chart.backgroundImage',
          type: 'string',
          plugins: ['background-image']
        },
        {
          type: 'separator'
        },
        {
          pid: 'chart.spacing',
          id: 'chart.spacing',
          type: 'fourinputs',
          default: [10, 10, 15, 10],
          fourInputs: {
            options: ['chart.spacingTop', 'chart.spacingRight', 'chart.spacingBottom', 'chart.spacingLeft']
          }
        },
        {
          id: 'chart.width',
          custom: {
            initialValue: null,
            minValue: 0,
            maxValue: 5000,
            step: 10,
            displayUndoButton: true
          },
          pid: 'chart.width',
          type: 'number'
        },
        {
          id: 'chart.height',
          custom: {
            initialValue: null,
            minValue: 0,
            maxValue: 5000,
            step: 10,
            displayUndoButton: true
          },
          pid: 'chart.height',
          type: 'number'
        }
      ]
    }
  ],
  'option.cat.chartspecific': [
    {
      text: 'option.util.empty',

      options: [
        {
          mapDisabled: true,
          type: 'array<accordion>',
          pid: 'option.util.empty',
          text: 'option.util.empty',
          id: 'series',
          rawKey: 'series[n]',
          blacklist: [
            'dependencywheel',
            'ohlc',
            'sankey',
            'patternfill',
            'funnel',
            'streamgraph',
            'gauge',
            'pyramid',
            'packedbubble',
            'candlestick'
          ],
          filteredBy: getTemplateType,
          custom: {
            hideAddButton: true,
            hideDeleteButtons: true,
            openIfOne: true,
            highlightSeries: true
          },
          options: [
            {
              id: 'type',
              pid: 'series.type',
              type: 'dependencyselect',
              showSeparatorLine: false,
              controlledBy: {
                options: 'series',
                optionsTitle: 'type',
                type: 'chartType'
              },
              defaultFilterValue: 'line',
              filteredBy: getTemplateType,
              values: seriesTypeListOptions,
              subTypedefault: {},

              options: [
                {
                  id: 'colorAxis[0].stops',
                  pid: 'colorAxis.stops',
                  type: 'colorstops',
                  isOptionalArray: true,

                  subType: ['heatmap'],
                  subTypedefault: {
                    heatmap: [
                      [0, '#EFEFFF'],
                      [0.67, '#4444FF'],
                      [1, '#000022']
                    ]
                  }
                },
                {
                  id: 'yAxis[0].stops',
                  pid: 'colorAxis.stops',
                  type: 'colorstops',
                  subType: ['solidgauge'],
                  subTypedefault: {
                    solidgauge: [
                      [0, '#EFEFFF'],
                      [0.67, '#4444FF'],
                      [1, '#000022']
                    ]
                  },
                  isOptionalArray: true
                },
                {
                  id: 'chart.inverted',
                  pid: 'chart.orientation',
                  filteredBy: getTemplateType,
                  type: 'select',
                  default: { label: 'Horizontal', value: false },
                  subType: ['timeline', 'dumbbell'],
                  subTypeValues: {
                    timeline: [
                      { label: 'Vertical', value: true },
                      { label: 'Horizontal', value: false }
                    ],
                    dumbbell: [
                      { label: 'Vertical', value: false },
                      { label: 'Horizontal', value: true }
                    ]
                  },
                  subTypedefault: {
                    timeline: false,
                    dumbbell: false
                  }
                },
                {
                  id: 'plotOptions.timeline.lineWidth',
                  pid: 'plotOptions.timeline.lineWidth',
                  type: 'number',
                  default: 1,
                  subType: ['timeline'],
                  subTypedefault: {
                    timeline: 1
                  }
                },
                {
                  id: 'plotOptions.timeline.dataLabels.distance',
                  pid: 'plotOptions.timeline.dataLabels.distance',
                  type: 'number',
                  default: 100,
                  subType: ['timeline'],
                  subTypedefault: {
                    timeline: 1
                  }
                },
                {
                  id: 'plotOptions.dumbbell.marker.radius',
                  pid: 'plotOptions.dumbbell.marker.radius',
                  type: 'number',
                  default: 1,
                  custom: {
                    minValue: 0,
                    maxValue: 10,
                    step: 1
                  },
                  subType: ['dumbbell'],
                  subTypedefault: {
                    dumbbell: 1
                  }
                },
                {
                  id: 'plotOptions.dumbbell.lowColor',
                  pid: 'plotOptions.dumbbell.lowColor',
                  type: 'color',
                  default: '',
                  subType: ['dumbbell'],
                  subTypedefault: {
                    dumbbell: ''
                  }
                },
                {
                  id: 'plotOptions.pie.dataLabels.distance',
                  pid: 'plotOptions.pie.dataLabels.distance',
                  type: 'number',
                  default: 30,
                  subType: ['pie']
                },
                {
                  id: 'series[n].dashStyle',
                  pid: 'series.dashStyle',
                  type: 'select<string>',
                  default: 'Solid',
                  values: dashStyleOptions,
                  subType: [
                    'areasplinerange',
                    'polygon',
                    'areaspline',
                    'spline',
                    'scatter',
                    'area',
                    'bubble',
                    'arearange',
                    'waterfall',
                    'pie',
                    'line'
                  ]
                },
                {
                  id: 'series[n].borderDashStyle',
                  pid: 'series.borderDashStyle',
                  type: 'select<string>',
                  default: 'Solid',
                  values: dashStyleOptions,
                  subType: ['treemap']
                },
                {
                  id: 'series[n].color',
                  pid: 'series.dumbbell.color',
                  type: 'color',
                  subType: ['dumbbell']
                },
                {
                  id: 'series[n].color',
                  pid: 'series.color',
                  type: 'color',
                  subType: [
                    'boxplot',
                    'column',
                    'waterfall',
                    'columnrange',
                    'area',
                    'scatter',
                    'bar',
                    'arearange',
                    'bubble',
                    'errorbar',
                    'spline',
                    'polygon',
                    'line',
                    'gauge',
                    'areaspline',
                    'areasplinerange'
                  ],
                  subTypedefault: {
                    errorbar: '#000000'
                  }
                },
                {
                  id: 'series[n].negativeColor',
                  pid: 'series.negativeColor',
                  type: 'color',
                  subType: [
                    'gauge',
                    'arearange',
                    'areasplinerange',
                    'line',
                    'errorbar',
                    'boxplot',
                    'areaspline',
                    'spline',
                    'bar',
                    'scatter',
                    'polygon',
                    'bubble',
                    'area',
                    'column'
                  ]
                },
                {
                  id: 'series[n].marker.symbol',
                  pid: 'series.marker.symbol',
                  type: 'select<string>',
                  values: markerPointOptions,
                  subType: ['bubble', 'line', 'scatter', 'spline', 'area', 'areaspline'],
                  subTypedefault: {},
                  refersTo: markerPointRefersTo
                },
                {
                  id: 'series[n].colorByPoint',
                  pid: 'series.colorByPoint',
                  type: 'checkbox',
                  default: false,
                  subType: ['column', 'errorbar', 'columnrange', 'boxplot', 'bar', 'waterfall'],
                  subTypedefault: {
                    column: false,
                    errorbar: false,
                    columnrange: false,
                    boxplot: false,
                    bar: false,
                    waterfall: false
                  }
                },
                {
                  id: 'series[n].borderWidth',
                  pid: 'series.borderWidth',
                  type: 'number',
                  default: 1,
                  subType: ['column', 'bar', 'pie', 'heatmap', 'treemap'],
                  subTypedefault: {
                    treemap: 0
                  }
                },
                {
                  id: 'series[n].borderColor',
                  pid: 'series.borderColor',
                  type: 'color',
                  subType: ['column', 'bar', 'pie', 'heatmap', 'treemap']
                },
                {
                  id: 'plotOptions.series.groupPadding',
                  pid: 'plotOptions.series.groupPadding',
                  type: 'number',
                  default: 0.2,
                  subType: ['column', 'bar']
                },
                {
                  id: 'marker.symbol',
                  pid: 'data.marker.symbol',
                  type: 'accordion',
                  subType: ['item'],
                  isBasedOnSeriesData: true,
                  custom: {
                    defaultSelection: true
                  },
                  options: [
                    {
                      id: 'marker.symbol',
                      pid: 'data.marker.symbol',
                      type: 'array<icon>',
                      isBasedOnSeriesData: true,
                      className: 'bg-ev-grey',
                      subType: ['item'],
                      subTypedefault: {
                        item: []
                      }
                    }
                  ]
                },
                {
                  id: 'plotOptions.item.size',
                  pid: 'plotOptions.item.size',
                  type: 'number',
                  default: 100,
                  suffix: '%',
                  subType: ['item'],
                  subTypedefault: {
                    item: '100%'
                  }
                },
                {
                  id: 'points.layout',
                  pid: 'points.layout',
                  type: 'select<string>',
                  refersTo: {
                    all: {
                      Rectangle: {
                        set: {
                          'plotOptions.series.startAngle': null,
                          'plotOptions.series.endAngle': null,
                          'plotOptions.series.center': ['50%', '50%'],
                          'plotOptions.series.size': '100%'
                        }
                      },
                      Parliament: {
                        set: {
                          'plotOptions.series.startAngle': -100,
                          'plotOptions.series.endAngle': 100,
                          'plotOptions.series.center': ['50%', '60%'],
                          'plotOptions.series.size': '100%'
                        }
                      },
                      Circle: {
                        set: {
                          'plotOptions.series.startAngle': 0,
                          'plotOptions.series.endAngle': 360,
                          'plotOptions.series.center': ['50%', '50%'],
                          'plotOptions.series.size': '100%'
                        }
                      }
                    }
                  },
                  default: 'Rectangle',
                  values: [
                    {
                      label: 'Rectangle',
                      value: 'Rectangle'
                    },
                    {
                      label: 'Parliament',
                      value: 'Parliament'
                    },
                    {
                      label: 'Circle',
                      value: 'Circle'
                    }
                  ],
                  subType: ['item'],
                  subTypedefault: {
                    item: 'auto'
                  }
                },
                {
                  id: 'plotOptions.series.innerSize',
                  pid: 'plotOptions-series-innerSize',
                  type: 'number',
                  suffix: '%',
                  subType: ['item'],
                  subTypedefault: {
                    item: 0
                  },
                  custom: {
                    minValue: 0
                  }
                },
                {
                  id: 'plotOptions.series.rows',
                  pid: 'plotOptions-series-rows',
                  type: 'number',
                  subType: ['item'],
                  subTypedefault: {
                    item: 0
                  },
                  custom: {
                    minValue: 0
                  }
                },
                {
                  id: 'plotOptions.wordcloud.minFontSize',
                  pid: 'plotOptions-wordcloud-minFontSize',
                  type: 'number',
                  subType: ['wordcloud'],
                  subTypedefault: {
                    wordcloud: 1
                  }
                },
                {
                  id: 'plotOptions.wordcloud.maxFontSize',
                  pid: 'plotOptions-wordcloud-maxFontSize',
                  type: 'number',
                  subType: ['wordcloud'],
                  subTypedefault: {
                    wordcloud: 25
                  }
                },
                {
                  id: 'data.text.amount',
                  pid: 'data.text.amount',
                  isBasedOnData: {
                    type: 'text'
                  },
                  type: 'number',
                  subType: ['wordcloud'],
                  subTypedefault: {
                    wordcloud: null
                  }
                }
              ],

              subType: [
                'scatter',
                'line',
                'spline',
                'funnel',
                'areaspline',
                'area',
                'bar',
                'areasplinerange',
                'arearange',
                'column',
                'columnrange'
              ]
            }
          ]
        },
        {
          id: 'plotOptions.series.dashStyle',
          pid: 'series.dashStyle',
          type: 'select<string>',
          default: 'Solid',
          projectType: ['map'],
          values: dashStyleOptions,
          templateType: ['Choropleth', 'Categories']
        },
        {
          id: 'plotOptions.map.borderWidth',
          pid: 'plotOptions.map.borderWidth',
          type: 'number',
          default: 0,
          projectType: ['map'],
          templateType: ['Choropleth', 'Categories']
        },
        {
          id: 'colorAxis[0].type',
          pid: 'colorAxis.type',
          type: 'select<string>',
          default: 'Solid',
          projectType: ['map'],
          values: colorAxisTypeOptions,
          templateType: ['Choropleth', 'Bubble'],
          isOptionalArray: true
        },
        {
          id: 'colorAxis[0].stops',
          pid: 'colorAxis.stops',
          type: 'colorstops',
          projectType: ['map'],
          templateType: ['Choropleth', 'Bubble'],
          default: [
            [0, '#EFEFFF'],
            [0.67, '#4444FF'],
            [1, '#000022']
          ],
          isOptionalArray: true
        },
        {
          id: 'plotOptions.mappoint.marker.symbol',
          pid: 'plotOptions.mappoint.marker.symbol',
          type: 'select<string>',
          projectType: ['map'],
          default: 'circle',
          templateType: ['Point map'],
          values: markerPointOptions
        },
        {
          id: 'plotOptions.mappoint.marker.radius',
          pid: 'plotOptions.mappoint.marker.radius',
          type: 'number',
          projectType: ['map'],
          templateType: ['Point map'],
          default: 4,
          shouldBeDisabled: (aggregatedOptions) => aggregatedOptions?.everviz?.text?.dynamicFonts,
          disabledText: 'To enable the field, please disable dynamic fonts in the Appearance styles.'
        },
        {
          id: 'plotOptions.series.color',
          pid: 'plotOptions.series.color',
          type: 'color',
          projectType: ['map'],
          templateType: ['Point map'],
          default: '#0d233a',
          custom: {
            showGradientColorTab: true
          }
        },
        {
          id: 'plotOptions.series.minSize',
          pid: 'plotOptions.series.minSize',
          type: 'number',
          projectType: ['map'],
          templateType: ['Bubble'],
          default: 4
        },
        {
          id: 'plotOptions.series.maxSize',
          pid: 'plotOptions.series.maxSize',
          type: 'number',
          projectType: ['map'],
          templateType: ['Bubble'],
          default: 60
        },
        {
          id: 'colorAxis[0].dataClasses',
          pid: 'colorAxis.dataClasses',
          type: 'colorcategories',
          isOptionalArray: true,
          projectType: ['map'],
          templateType: ['Categories', 'Honeycomb', 'Tilemap circle'],
          default: [
            {
              from: -100,
              to: 100,
              color: '#C40401'
            }
          ]
        },
        {
          id: 'plotOptions.packedbubble.minSize',
          pid: 'plotOptions.packedbubble.minSize',
          default: '10%',
          type: 'number',
          custom: {
            minValue: 1
          },
          suffix: '%',
          subType: ['packedbubble'],
          filteredBy: getTemplateType
        },
        {
          id: 'plotOptions.packedbubble.maxSize',
          pid: 'plotOptions.packedbubble.maxSize',
          default: '50%',
          type: 'number',
          custom: {
            minValue: 1
          },
          suffix: '%',
          subType: ['packedbubble'],
          filteredBy: getTemplateType
        },
        {
          id: 'plotOptions.packedbubble.layouttype',
          pid: 'plotOptions.packedbubble.layouttype',
          subType: ['packedbubble'],
          filteredBy: getTemplateType,
          type: 'select<string>',
          refersTo: {
            all: {
              Packed: {
                set: {
                  'plotOptions.packedbubble.layoutAlgorithm.splitSeries': false,
                  'plotOptions.packedbubble.useSimulation': true,
                  'plotOptions.series.lineWidth': 0
                }
              },
              Grouped: {
                set: {
                  'plotOptions.packedbubble.layoutAlgorithm.splitSeries': true,
                  'plotOptions.packedbubble.useSimulation': true,
                  'plotOptions.series.lineWidth': 3
                }
              },
              Spiral: {
                set: {
                  'plotOptions.packedbubble.layoutAlgorithm.splitSeries': false,
                  'plotOptions.packedbubble.useSimulation': false,
                  'plotOptions.series.lineWidth': 0
                }
              }
            }
          },
          values: [
            { label: 'Packed', value: 'Packed' },
            { label: 'Grouped', value: 'Grouped' },
            { label: 'Spiral', value: 'Spiral' }
          ]
        }
      ]
    }
  ],

  'option.cat.axes': [
    {
      mapDisabled: true,
      options: [
        {
          mapDisabled: true,
          type: 'accordion',
          text: 'option.subcat.xaxis',
          pid: 'option.subcat.xaxis',
          options: [
            {
              id: 'xAxis[0].labels.format',
              pid: 'xAxis.labels.format',
              type: 'dropdowninput',
              values: axisLabelFormatOptions,
              default: '{value}',
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].visible',
              pid: 'xAxis.enabled',
              type: 'checkbox',
              default: true,
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].type',
              pid: 'xAxis.type',
              type: 'select<string>',
              default: 'linear',
              values: axisTypeOptions,
              isOptionalArray: true
              // values: '["linear", "logarithmic", "datetime", "category"]',
            },
            {
              id: 'xAxis[0].lineWidth',
              pid: 'xAxis.lineWidth',
              type: 'number',
              default: '1',
              isOptionalArray: true,
              custom: {
                minValue: 0,
                maxValue: 10
              }
            },
            {
              id: 'xAxis[0].lineColor',
              pid: 'xAxis.lineColor',
              type: 'color',
              default: '#333333',
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].tickWidth',
              pid: 'xAxis.tickWidth',
              type: 'checkbox',
              default: true,
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].labels.style',
              type: 'fontstyler',
              pid: 'xAxis.labels.style',
              isOptionalArray: true,
              default: { color: '#666666', cursor: 'pointer', fontSize: '11px' },
              custom: {
                fields: {
                  bold: 1,
                  italic: 1,
                  color: 1,
                  fontSize: 1
                }
              }
            },
            {
              id: 'xAxis[0].gridLineDashStyle',
              pid: 'xAxis.gridLineDashStyle',
              type: 'select<string>',
              values: customAxisDashStyleOptions,
              default: 'None',
              backgroundColor: 'white',
              onOptionsChange: (aggregatedOptions, selection) => {
                const aggregated = cloneDeep(aggregatedOptions);
                if (!aggregated.xAxis) aggregated.xAxis = [];
                const xAxis = aggregated.xAxis[0] || {};

                if (selection.value === 'None') {
                  xAxis.gridLineWidth = 0;
                } else {
                  xAxis.gridLineDashStyle = selection.value;
                  xAxis.gridLineWidth = xAxis.gridLineWidth || 1;
                }

                return aggregated;
              }
            },
            {
              id: 'xAxis[0].gridLineWidth',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'xAxis.gridLineWidth',
              type: 'number',
              default: '0',
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].gridLineColor',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'xAxis.gridLineColor',
              type: 'color',
              default: '#e6e6e6',
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].opposite',
              pid: 'xAxis.placement',
              type: 'select<string>',
              values: [
                { label: 'Bottom', value: false },
                { label: 'Top', value: true }
              ],
              default: false,
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].reversed',
              pid: 'xAxis.direction',
              type: 'select<string>',
              values: [
                { label: 'Ascending', value: false },
                { label: 'Descending', value: true }
              ],
              default: false,
              isOptionalArray: true
            }
          ],
          blacklist: checkSeriesAxisCompat
        },
        {
          text: 'option.subcat.yaxis',
          pid: 'option.subcat.yaxis',
          type: 'accordion',
          options: [
            {
              id: 'yAxis[0].labels.format',
              pid: 'yAxis.labels.format',
              values: axisLabelFormatOptions,
              type: 'dropdowninput',
              default: '{value}',
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].visible',
              pid: 'yAxis.enabled',
              type: 'checkbox',
              default: true,
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].type',
              pid: 'yAxis.type',
              type: 'select<string>',
              default: 'linear',
              values: axisTypeOptions,
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].lineWidth',
              pid: 'yAxis.lineWidth',
              type: 'number',
              default: '0',
              isOptionalArray: true,
              custom: {
                minValue: 0,
                maxValue: 10
              }
            },
            {
              id: 'yAxis[0].lineColor',
              pid: 'yAxis.lineColor',
              type: 'color',
              default: '#333333',
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].tickWidth',
              pid: 'yAxis.tickWidth',
              type: 'checkbox',
              default: false,
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].labels.style',
              type: 'fontstyler',
              pid: 'yAxis.labels.style',
              isOptionalArray: true,
              default: { color: '#666666', cursor: 'pointer', fontSize: '11px' },
              custom: {
                fields: {
                  bold: 1,
                  italic: 1,
                  color: 1,
                  fontSize: 1
                }
              }
            },
            {
              id: 'yAxis[0].gridLineDashStyle',
              pid: 'yAxis.gridLineDashStyle',
              type: 'select<string>',
              values: customAxisDashStyleOptions,
              default: 'Solid',
              backgroundColor: 'white',
              onOptionsChange: (aggregatedOptions, selection) => {
                const aggregated = cloneDeep(aggregatedOptions);
                if (!aggregated.yAxis) aggregated.yAxis = [];
                const yAxis = aggregated.yAxis[0] || {};

                if (selection.value === 'None') {
                  yAxis.gridLineWidth = 0;
                } else {
                  yAxis.gridLineDashStyle = selection.value;
                  yAxis.gridLineWidth = yAxis.gridLineWidth || 1;
                }

                return aggregated;
              }
            },
            {
              id: 'yAxis[0].gridLineWidth',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'yAxis.gridLineWidth',
              type: 'number',
              default: '1',
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].gridLineColor',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'yAxis.gridLineColor',
              type: 'color',
              default: '#e6e6e6',
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].opposite',
              pid: 'yAxis.placement',
              type: 'select<string>',
              values: [
                { label: 'Left', value: false },
                { label: 'Right', value: true }
              ],
              default: false,
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].reversed',
              pid: 'yAxis.direction',
              type: 'select<string>',
              values: [
                { label: 'Ascending', value: false },
                { label: 'Descending', value: true }
              ],
              default: false,
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].min',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'yAxis.min',
              type: 'number',
              default: '',
              isOptionalArray: true,
              custom: {
                displayUndoButton: true
              },
              onOptionsChange: handleYAxisMinChange
            },
            {
              id: 'yAxis[0].max',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'yAxis.max',
              type: 'number',
              default: '',
              isOptionalArray: true,
              custom: {
                displayUndoButton: true
              },
              onOptionsChange: handleYAxisMaxChange
            }
          ],
          blacklist: checkSeriesAxisCompat
        },
        {
          text: 'option.subcat.secondaxis',
          pid: 'option.subcat.secondaxis',
          type: 'dynamicaccordion',
          onChangeKey: 'yAxis[1]',
          onOptionsChange: addSecondAxis,
          onHide: (aggregatedOptions: any) => {
            (window as WindowProps).everviz.responsiveFontOptions = {};

            const aggregated = cloneDeep(aggregatedOptions);
            delete aggregated.yAxis[1];
            aggregated.series.forEach((s: any) => delete s.yAxis);
            return aggregated;
          },
          options: [
            {
              id: 'yAxis[1].labels.format',
              pid: 'yAxis.labels.format',
              values: axisLabelFormatOptions,
              type: 'dropdowninput',
              default: '{value}'
            },
            {
              id: 'yAxis[1].visible',
              pid: 'yAxis.enabled',
              type: 'checkbox',
              default: true
            },
            {
              id: 'yAxis[1].type',
              pid: 'yAxis.type',
              type: 'select<string>',
              default: 'linear',
              values: axisTypeOptions
            },
            {
              id: 'yAxis[1].title.align',
              pid: 'yAxis.title.align',
              type: 'select<string>',
              values: yAxisTitleAlignValue,
              default: 'middle'
            },
            {
              id: 'yAxis[1].lineWidth',
              pid: 'yAxis.lineWidth',
              type: 'number',
              default: '0',
              custom: {
                minValue: 0,
                maxValue: 10
              }
            },
            {
              id: 'yAxis[1].lineColor',
              pid: 'yAxis.lineColor',
              type: 'color',
              default: '#333333'
            },
            {
              id: 'yAxis[1].tickWidth',
              pid: 'yAxis.tickWidth',
              type: 'checkbox',
              default: false
            },
            {
              id: 'yAxis[1].labels.style',
              type: 'fontstyler',
              pid: 'yAxis.labels.style',
              default: { color: '#666666', cursor: 'pointer', fontSize: '11px' },
              custom: {
                fields: {
                  bold: 1,
                  italic: 1,
                  color: 1,
                  fontSize: 1
                }
              }
            },
            {
              id: 'yAxis[1].gridLineDashStyle',
              pid: 'yAxis.gridLineDashStyle',
              type: 'select<string>',
              values: customAxisDashStyleOptions,
              default: 'None',
              backgroundColor: 'white',
              onOptionsChange: (aggregatedOptions, selection) => {
                const aggregated = cloneDeep(aggregatedOptions);
                const yAxis = aggregated?.yAxis?.find((item: any) => item.opposite === true);

                if (selection.value === 'None') {
                  yAxis.gridLineWidth = 0;
                } else {
                  yAxis.gridLineDashStyle = selection.value;
                  yAxis.gridLineWidth = yAxis.gridLineWidth || 1;
                }

                return aggregated;
              }
            },
            {
              id: 'yAxis[1].gridLineWidth',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'yAxis.gridLineWidth',
              type: 'number',
              default: '1'
            },
            {
              id: 'yAxis[1].gridLineColor',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'yAxis.gridLineColor',
              type: 'color',
              default: '#e6e6e6'
            },
            {
              id: 'yAxis[1].opposite',
              pid: 'yAxis.placement',
              type: 'select<string>',
              values: [
                { label: 'Left', value: false },
                { label: 'Right', value: true }
              ],
              default: 'left'
            },
            {
              id: 'yAxis[1].reversed',
              pid: 'yAxis.direction',
              type: 'select<string>',
              values: [
                { label: 'Ascending', value: true },
                { label: 'Descending', value: false }
              ],
              default: 'desc'
            },
            {
              id: 'yAxis[1].min',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'yAxis.min',
              type: 'number',
              default: '',
              custom: {
                displayUndoButton: true
              },
              onOptionsChange: (aggregatedOptions, selection) => {
                return handleYAxisMinChange(aggregatedOptions, selection, 1);
              }
            },
            {
              id: 'yAxis[1].max',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud', 'unit', 'pie'],
              pid: 'yAxis.max',
              type: 'number',
              default: '',
              custom: {
                displayUndoButton: true
              },
              onOptionsChange: (aggregatedOptions, selection) => {
                return handleYAxisMaxChange(aggregatedOptions, selection, 1);
              }
            },
            {
              id: 'series',
              pid: 'yAxis.series',
              text: 'yAxis.series',
              type: 'dependencyselect',
              showSeparatorLine: false,
              onOptionsChange: (aggregatedOptions, selection) => {
                const aggregated = cloneDeep(aggregatedOptions);
                aggregated.series.forEach((s: any) => delete s.yAxis);
                aggregated.series[selection.value].yAxis = 1;
                return aggregated;
              },
              controlledBy: {
                options: 'series',
                type: 'dataLabels',
                optionsTitle: 'name',
                default: 'A'
              }
            }
          ],
          blacklist: checkSeriesAxisCompat
        }
      ]
    }
  ],
  'option.cat.tooltip': [
    {
      text: 'option.util.empty',

      options: [
        {
          id: 'series',
          pid: 'data-labels',
          text: 'option.cat.series',
          type: 'dependencyselect',
          showSeparatorLine: true,
          controlledBy: {
            options: 'series',
            type: 'tooltip',
            optionsTitle: 'name',
            default: 'all',
            preValues: [
              {
                value: 'all',
                label: 'All Series'
              }
            ]
          },
          options: [
            {
              id: 'tooltip.enabled',
              pid: 'tooltip.enabled',
              type: 'checkbox',
              rawKey: 'enabled',
              default: true
            },
            {
              id: 'tooltip.shared',
              pid: 'tooltip.shared',
              rawKey: 'shared',
              type: 'checkbox',
              default: false,
              mapDisabled: true,
              refersTo: {
                all: {
                  true: {
                    set: {
                      'tooltip.shared': true
                    }
                  },
                  false: {
                    set: {
                      'tooltip.split': false,
                      'tooltip.shared': false
                    }
                  }
                }
              }
            },
            {
              id: 'tooltip.valueDecimals',
              pid: 'tooltip.valueDecimals',
              type: 'number',
              filteredBy: 'chart.type',
              blacklist: ['timeline', 'sankey', 'dependencywheel', 'wordcloud'],
              default: '',
              rawKey: 'valueDecimals'
            },
            {
              type: 'separator'
            },
            {
              id: 'tooltip',
              text: 'option.util.empty',
              type: 'tooltiphelper',
              blacklist: ['item'],
              filteredBy: 'chart.type',
              nestedValue: ['chart.style.fontFamily'],
              inline: {
                highchartsId: 'tooltip.options'
              },
              richtext: {
                rawId: 'tooltip',
                skipUseHTML: true,
                noHighchartOptions: true
              },
              rawKey: 'tooltip'
            },
            {
              type: 'separator'
            },
            {
              id: 'tooltip.backgroundColor',
              pid: 'tooltip.backgroundColor',
              type: 'color',
              default: 'rgba(247,247,247,0.85)',
              custom: {
                showGradientColorTab: true
              },
              rawKey: 'backgroundColor'
            },
            {
              id: 'tooltip.borderColor',
              pid: 'tooltip.borderColor',
              type: 'color',
              rawKey: 'borderColor'
            },
            {
              id: 'tooltip.borderWidth',
              custom: {
                minValue: 0
              },
              pid: 'tooltip.borderWidth',
              type: 'number',
              default: 0,
              rawKey: 'borderWidth'
            },
            {
              id: 'tooltip.borderRadius',
              custom: {
                minValue: 0
              },
              pid: 'tooltip.borderRadius',
              type: 'number',
              default: '3',
              rawKey: 'borderRadius'
            }
          ]
        }
      ]
    }
  ],
  'option.cat.legend': [
    {
      text: 'option.subcat.general',

      group: 1,
      options: [
        {
          id: 'legend.enabled',
          pid: 'legend.enabled',
          type: 'checkbox',
          default: true,
          refersTo: {
            all: {
              true: {
                set: {
                  'legend.enabled': true
                }
              },
              false: {
                set: {
                  'legend.enabled': false
                }
              }
            },
            pie: {
              true: {
                set: {
                  'legend.enabled': true,
                  'plotOptions.pie.showInLegend': true
                }
              },
              false: {
                set: {
                  'legend.enabled': false,
                  'plotOptions.pie.showInLegend': false
                }
              }
            }
          }
        },
        {
          id: 'legend.layout',
          pid: 'legend.layout',
          type: 'select<string>',
          default: 'horizontal',
          values: [
            {
              label: 'Horizontal',
              value: 'horizontal'
            },
            {
              label: 'Vertical',
              value: 'vertical'
            }
          ]
          // values: '["horizontal", "vertical"]',
        }
      ]
    },
    {
      text: 'option.subcat.placement',

      group: 1,
      options: [
        {
          id: 'legend.align',
          pid: 'textStyle.legendPlacement',
          type: 'placementalignwidget',
          default: 'topLeft'
        },
        {
          id: 'legend.floating',
          pid: 'legend.floating',
          type: 'checkbox',
          default: false
        },
        {
          type: 'separator'
        }
      ]
    },
    {
      text: 'option.subcat.legendappearance',

      group: 1,
      options: [
        {
          id: 'legend.itemStyle',
          type: 'fontpicker',
          pid: 'legend.itemStyle'
        },
        {
          id: 'legend.itemStyle',
          type: 'fontstyler',
          pid: 'legend.itemFontStyle',
          default: { color: '#333333', cursor: 'pointer', fontSize: '12px', fontWeight: 'bold' },
          custom: {
            fields: {
              bold: 1,
              italic: 1,
              color: 1,
              fontSize: 1
            }
          }
        },
        {
          type: 'separator'
        },
        {
          id: 'legend.backgroundColor',
          pid: 'legend.backgroundColor',
          type: 'color',
          custom: {
            showGradientColorTab: true
          }
        },
        {
          id: 'legend.borderColor',
          pid: 'legend.borderColor',
          type: 'color',
          default: '#999999',
          custom: {
            showGradientColorTab: true
          }
        },
        {
          id: 'legend.borderWidth',
          pid: 'legend.borderWidth',
          type: 'number',
          default: '0'
        },
        {
          id: 'legend.borderRadius',
          pid: 'legend.borderRadius',
          type: 'number',
          default: '0'
        }
      ]
    }
  ],
  'option.cat.animation': [
    {
      text: 'option.util.empty',

      options: [
        {
          id: 'plotOptions.series.animation.duration',
          pid: 'plotOptions.series.animation.duration',
          type: 'number',
          default: 1000,
          useCustomizedOptions: true,
          blacklist: ['packedbubble'],
          filteredBy: 'chart.type',
          custom: {
            minValue: 0,
            maxValue: 9000
          }
        },
        {
          id: 'everviz.animation.loadOnPageLoad',
          pid: 'everviz.animation.loadOnPageLoad',
          type: 'checkbox',
          default: false,
          blacklist: ['packedbubble'],
          filteredBy: 'chart.type'
        },
        {
          id: 'everviz.animation.scaleToFit',
          pid: 'everviz.animation.scaleToFit',
          type: 'checkbox',
          default: false,
          projectType: ['map'],
          templateType: ['Choropleth', 'Categories', 'Bubble', 'Point map', 'Pattern fill']
        },
        {
          id: 'play.animation',
          pid: 'play.animation',
          type: 'action',
          blacklist: ['packedbubble'],
          filteredBy: 'chart.type'
        }
      ]
    }
  ],
  'option.cat.data-labels': [
    {
      text: 'option.util.empty',
      options: [
        {
          id: 'series',
          pid: 'data-labels',
          text: 'option.cat.series',
          blacklist: ['dependencywheel', 'parliament', 'item'],
          filteredBy: getTemplateType,
          type: 'dependencyselect',
          showSeparatorLine: true,
          controlledBy: {
            options: 'series',
            type: 'dataLabels',
            optionsTitle: 'name',
            default: 'all',
            preValues: [
              {
                value: 'all',
                label: 'All Series'
              }
            ]
          },
          options: [
            {
              id: 'plotOptions.series.dataLabels.enabled',
              pid: 'dataLabels.enabled',
              type: 'checkbox',

              default: false,
              rawKey: 'enabled'
            },
            {
              id: 'plotOptions.series.dataLabels.format',
              pid: 'dataLabels.format',
              values: dataLabelFormatOptions,
              type: 'dropdowninput',
              rawKey: 'format'
            },
            {
              id: 'plotOptions.series.dataLabels.format',
              pid: 'dataLabels.decimals',
              type: 'number',
              rawKey: 'decimalValue',
              isDecimalValue: true
            },
            {
              id: 'plotOptions.series.dataLabels.style.textOutline',
              pid: 'dataLabels.style.textOutline',
              type: 'number',

              default: '1',
              rawKey: 'style.textOutline',
              suffix: ' contrast'
            },
            {
              type: 'separator'
            },
            {
              id: 'plotOptions.series.dataLabels.style',
              type: 'fontpicker',
              pid: 'dataLabels.style',
              default: { fontSize: '12px', fontWeight: 'bold' },
              rawKey: 'style'
            },
            {
              id: 'plotOptions.series.dataLabels.style',
              type: 'fontstyler',
              pid: 'dataLabels.color',
              default: { color: '#333333', cursor: 'pointer', fontSize: '12px', fontWeight: 'bold' },
              custom: {
                fields: {
                  bold: 1,
                  italic: 1,
                  color: 1,
                  fontSize: 1
                }
              },
              rawKey: 'style'
            },
            {
              type: 'separator'
            },
            {
              id: 'plotOptions.series.dataLabels.backgroundColor',
              pid: 'dataLabels.backgroundColor',
              type: 'color',
              rawKey: 'backgroundColor'
            },
            {
              id: 'plotOptions.series.dataLabels.borderColor',
              pid: 'dataLabels.borderColor',
              type: 'color',
              rawKey: 'borderColor'
            },
            {
              id: 'plotOptions.series.dataLabels.borderWidth',
              pid: 'dataLabels.borderWidth',
              custom: {
                minValue: 0
              },
              type: 'number',

              default: '0',
              rawKey: 'borderWidth'
            },
            {
              id: 'plotOptions.series.dataLabels.borderRadius',
              pid: 'dataLabels.borderRadius',
              custom: {
                minValue: 0
              },
              type: 'number',

              default: '0',
              rawKey: 'borderRadius'
            }
          ]
        },
        {
          type: 'separator',
          mapDisabled: true,
          blacklist: ['dependencywheel', 'parliament', 'item'],
          filteredBy: getTemplateType
        },
        {
          id: 'plotOptions.series.label.enabled',
          pid: 'plotOptions.series.label.enabled',
          type: 'checkbox',
          mapDisabled: true,
          blacklist: ['dependencywheel', 'parliament', 'item'],
          filteredBy: getTemplateType,
          plugins: ['series-label'],
          default: false
        }
      ]
    }
  ],
  'option.cat.annotations': [
    {
      text: 'option.util.empty',
      mapDisabled: true,
      options: [
        {
          id: 'annotation.text.labels',
          pid: 'highlights',
          type: 'highlights',
          blacklist: [
            'dependencywheel',
            'sankey',
            'funnel',
            'streamgraph',
            'gauge',
            'pyramid',
            'packedbubble',
            'pie',
            'item',
            'wordcloud',
            'heatmap',
            'treemap',
            'timeline'
          ],
          filteredBy: getTemplateType,
          options: [labels, highlights, lines]
        }
      ]
    }
  ],
  'option.cat.export': [
    {
      text: 'option.util.empty',

      options: [
        {
          id: 'exporting.enabled',
          pid: 'exporting.enabled',
          type: 'checkbox',
          default: true,
          aliasKey: ['all.exporting.buttons.contextButton.enabled'],
          postFields: [
            {
              optionNeeded: [true],
              className: 'context-button-text',
              options: [
                {
                  id: 'exporting.buttons.contextButton.text',
                  pid: 'exporting.buttons.contextButton.text',
                  type: 'string',
                  inputClassName: 'bg-white',
                  aliasKey: ['all.exporting.buttons.contextButton.text']
                }
              ]
            },
            {
              optionNeeded: [true],
              className: 'bg-white',
              options: [
                {
                  id: 'exporting.buttons.contextButton.symbol',
                  pid: 'exporting.buttons.contextButton.symbol',
                  type: 'checkbox',

                  aliasKey: ['all.exporting.buttons.contextButton.symbol'],
                  refersTo: {
                    all: {
                      true: {
                        set: {
                          'exporting.buttons.contextButton.symbol': 'menu'
                        }
                      },
                      false: {
                        set: {
                          'exporting.buttons.contextButton.symbol': null
                        }
                      }
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          id: 'exporting.sourceWidth',
          custom: {
            minValue: 0,
            maxValue: 2000,
            step: 10
          },
          pid: 'exporting.sourceWidth',
          type: 'number'
        },
        {
          id: 'exporting.sourceHeight',
          custom: {
            minValue: 0,
            maxValue: 2000,
            step: 10
          },
          pid: 'exporting.sourceHeight',
          type: 'number'
        },
        {
          id: 'exporting.scale',
          custom: {
            minValue: 0.5,
            maxValue: 3.5,
            step: 0.5
          },
          default: 2,
          pid: 'exporting.scale',
          type: 'number'
        },
        {
          id: 'everviz.exporting.fps',
          type: 'select<string>',
          values: [
            { label: '50P (Pal)', value: 50 },
            { label: '60i (NTSC)', value: '60i' },
            { label: '60FPS (Web/Mobile)', value: 60 },
            { label: '25FPS (Web/Mobile)', value: 25 }
          ],
          default: { label: '60FPS (Web/Mobile)', value: 60 },
          pid: 'everviz.exporting.fps'
        },
        {
          id: 'exporting.localization',
          pid: 'exporting.localization',
          type: 'accordion',

          options: [
            {
              id: 'lang.contextButtonTitle',
              pid: 'lang.contextButtonTitle',
              type: 'string',
              default: 'Chart context menu'
            },
            {
              id: 'lang.viewFullscreen',
              pid: 'lang.viewFullscreen',
              type: 'string',
              default: 'View in full screen'
            },
            {
              id: 'lang.printChart',
              pid: 'lang.printChart',
              type: 'string',
              default: 'Print chart'
            },
            {
              id: 'lang.downloadPNG',
              pid: 'lang.downloadPNG',
              type: 'string',
              default: 'Download PNG image'
            },
            {
              id: 'lang.downloadJPEG',
              pid: 'lang.downloadJPEG',
              type: 'string',
              default: 'Download JPEG image'
            },
            {
              id: 'lang.downloadPDF',
              pid: 'lang.downloadPDF',
              type: 'string',
              default: 'Download PDF document'
            },
            {
              id: 'lang.downloadSVG',
              pid: 'lang.downloadSVG',
              type: 'string',
              default: 'Download SVG vector image'
            },
            {
              id: 'lang.downloadCSV',
              pid: 'lang.downloadCSV',
              type: 'string',
              default: 'Download CSV'
            },
            {
              id: 'lang.downloadXLS',
              pid: 'lang.downloadXLS',
              type: 'string',
              default: 'Download XLS'
            },
            {
              id: 'lang.viewData',
              pid: 'lang.viewData',
              type: 'string',
              default: 'View data table'
            }
          ]
        }
      ]
    }
  ],
  'option.cat.localization': [
    {
      text: 'option.subcat.numberformat',

      group: 1,
      options: [
        {
          id: 'lang.decimalPoint',
          pid: 'lang.decimalPoint',
          type: 'string',
          default: '.'
        },
        {
          id: 'lang.thousandsSep',
          pid: 'lang.thousandsSep',
          type: 'string',
          default: ' '
        },
        {
          id: 'lang.notice',
          pid: 'lang.notice',
          type: 'notice',
          default: 'For more localization options, go to '
        }
      ]
    }
  ],
  'option.cat.accessibility': [
    {
      text: 'option.util.empty',

      options: [
        {
          id: 'accessibility.enabled',
          pid: 'accessibility.enabled',
          type: 'checkbox',
          default: true
        },
        {
          id: 'accessibility.description',
          pid: 'accessibility.description',
          type: 'text',
          default: undefined
        }
      ]
    }
  ]
};

export default options;
