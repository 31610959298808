import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { snackBar } from 'editor/editors/highed.init';
import { setAction, getEditorConfigAction, getFontsAction } from '../actions/customiseEditorPage';
import actionTypes from '../../../redux/actions/action-types';
import CustomizeOptions from '../../ChartEditorPage/meta/CustomizeOptions';
import {
  getEditorDivisionWithDivisionid,
  getEditorTeamWithTeamid,
  postEditorDivisionUsingDivisionid,
  postEditorTeamUsingTeamid,
  getTeamFontsWithTeamid,
  getTeamLogosWithTeamid,
  postTeamDeleteLogoUsingTeamidAndLogoid,
  postTeamDeleteFontUsingTeamidAndFontid,
  postTeamFontUsingTeamid,
  postTeamFontUsingTeamidAndFontid
} from '../../../api/cloudApiGenerated';
import { getProfileConfig } from 'redux/selectors/profile';

export function* getEditorConfig(params) {
  const { team, division, editorFeatures, features } = params.data;

  var getConfig = () => {
    if (!division || division.id === null) return getEditorTeamWithTeamid(team.id);
    else return getEditorDivisionWithDivisionid(division.id);
  };

  try {
    const config = yield call(getConfig);

    if (config.length > 0) {
      const details = JSON.parse(config[0].details);
      if (details.customizer) {
        editorFeatures['Advanced'].checked = !details.customizer.noAdvanced;
        editorFeatures['CustomCode'].checked = !details.customizer.noCustomCode;
        editorFeatures['PreviewOptions'].checked = !details.customizer.noPreview;
      }

      if (config[0].hide_default_themes) {
        yield put(
          setAction({
            hideDefaultThemes: config[0].hide_default_themes
          })
        );
      }

      const userTabs = (details.features || '').split(' ');

      features.forEach((feature) => {
        Object.keys(feature).forEach((key) => {
          userTabs.forEach((userTab) => {
            if (key === userTab) feature[key].checked = true;
          });
        });
      });

      if (details.customizer) {
        yield put(
          setAction({
            userOptions: details.customizer.availableSettings
          })
        );
      }
    } else {
      editorFeatures['Advanced'].checked = true;
      editorFeatures['CustomCode'].checked = true;
      editorFeatures['PreviewOptions'].checked = true;

      features[0].customize.checked = true;
      features[0].templates.checked = true;
      features[0].themes.checked = true;
      features[1].export.checked = true;
      features[1].publish.checked = true;

      yield put(
        setAction({
          userOptions: [],
          editorFeatures
        })
      );
    }
  } catch (error) {
    yield put(
      setAction({
        error: 'retrieving config: ' + error.toString()
      })
    );
  }
}

export function* getFonts(params) {
  const { team } = params.data;

  let getFont = () => {
    return getTeamFontsWithTeamid(team.id);
  };

  const fonts = yield call(getFont);
  yield put(
    setAction({
      fonts,
      selectedFont: fonts && fonts.length > 0 ? fonts[0] : null
    })
  );
}

export function* setUserEditorConfig(params) {
  let { results, editorFeatures, features, hideDefaultThemes, division, team } = params.data;

  const finalEditorConfig = {
    customizer: {
      availableSettings: results,
      noCustomCode: !editorFeatures['CustomCode'].checked,
      noAdvanced: !editorFeatures['Advanced'].checked,
      noPreview: !editorFeatures['PreviewOptions'].checked
    }
  };

  let postConfig = () => {
    return !division || division.id === null
      ? postEditorTeamUsingTeamid(team.id, body)
      : postEditorDivisionUsingDivisionid(division.id, body);
  };

  let featureList = '';

  features.forEach((feature) => {
    Object.keys(feature).forEach((f) => {
      if (feature[f].checked) featureList += ' ' + f;
    });
  });

  finalEditorConfig.features = featureList;

  const body = { details: JSON.stringify(finalEditorConfig), hide_default_themes: hideDefaultThemes };

  try {
    yield call(postConfig);
    snackBar('Editor customised');
  } catch (error) {
    yield put(
      setAction({
        error: 'setting config: ' + error.toString()
      })
    );
  }
}

export function* getUserEditorConfig(params) {
  const { team, division, editorFeatures, features } = params.data;

  yield put(
    setAction({
      allOptions: {
        options: CustomizeOptions
      } // simpleOptions,
    })
  );
  yield put(getEditorConfigAction({ team, division, editorFeatures, features }));
}

export function* deleteFont(params) {
  const { team, selectedFont } = params.data;

  try {
    yield call(postTeamDeleteFontUsingTeamidAndFontid, team.id, selectedFont.id);
    snackBar('Font deleted');

    yield put(getFontsAction({ team }));
    yield put(setAction({ showDeleteFontModal: false }));
  } catch (e) {
    console.log(e);
  }
}

export function* getLogos(params) {
  const { team } = params.data;

  const logos = yield call(getTeamLogosWithTeamid, team.id);
  yield put(
    setAction({
      logos,
      selectedLogo: logos && logos.length > 0 ? logos[0] : null
    })
  );
}

export function* deleteLogo(params) {
  const { team, selectedLogo } = params.data;

  try {
    yield call(postTeamDeleteLogoUsingTeamidAndLogoid, team.id, selectedLogo.id);
    snackBar('Logo deleted');

    yield put(getFontsAction({ team }));
    yield put(setAction({ showDeleteLogoModal: false }));
  } catch (e) {
    console.log(e);
  }
}

export function* editCustomFont(params) {
  const { files, name, selectedFont, cb } = params.data;
  const { team } = yield select(getProfileConfig);
  const formData = new FormData();

  formData.append('font', files[0]);
  formData.append('name', name);

  yield call(postTeamFontUsingTeamidAndFontid, team.id, selectedFont.id, formData);
  cb();
}

export function* addCustomFont(params) {
  const { files, name, cb } = params.data;
  const { team } = yield select(getProfileConfig);

  const formData = new FormData();

  formData.append('font', files[0]);
  formData.append('name', name.replace(' - ', '-'));

  yield call(postTeamFontUsingTeamid, team.id, formData);
  cb();
}

/** Watch functions */
export function* watchGetEditorConfig() {
  yield takeLatest(actionTypes.customiseEditorPage.getEditorConfig, getEditorConfig);
}
export function* watchSetUserEditorConfig() {
  yield takeLatest(actionTypes.customiseEditorPage.setUserEditorConfig, setUserEditorConfig);
}
export function* watchGetUserEditorConfig() {
  yield takeLatest(actionTypes.customiseEditorPage.getUserEditorConfig, getUserEditorConfig);
}
export function* watchGetFonts() {
  yield takeLatest(actionTypes.customiseEditorPage.getFonts, getFonts);
}
export function* watchDeleteFont() {
  yield takeLatest(actionTypes.customiseEditorPage.deleteFont, deleteFont);
}
export function* watchGetLogos() {
  yield takeLatest(actionTypes.customiseEditorPage.getLogos, getLogos);
}
export function* watchDeleteLogo() {
  yield takeLatest(actionTypes.customiseEditorPage.deleteLogo, deleteLogo);
}
export function* watchAddCustomFont() {
  yield takeLatest(actionTypes.customiseEditorPage.addCustomFont, addCustomFont);
}
export function* watchEditCustomFont() {
  yield takeLatest(actionTypes.customiseEditorPage.editCustomFont, editCustomFont);
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchGetEditorConfig(),
    watchGetUserEditorConfig(),
    watchSetUserEditorConfig(),
    watchGetFonts(),
    watchDeleteFont(),
    watchGetLogos(),
    watchDeleteLogo(),
    watchAddCustomFont(),
    watchEditCustomFont()
  ]);
}
