import React from 'react';

const types = ['radio-tabs', 'radio-list', 'radio-inline'];
const defaultType = types[0];

const RadioGroup = ({
  options,
  groupName,
  checked,
  onSelect,
  extraClass = '',
  type = defaultType,
  tabIndex = 0,
  selectedClassName
}) => {
  if (types.indexOf(type) < 0) {
    type = defaultType;
  }

  if (extraClass && extraClass.length) {
    extraClass = ' ' + extraClass;
  }

  const onChange = (event) => {
    onSelect(event.target.value);
  };

  return (
    <>
      {options.map((option, index) => {
        let id, value, activeElement;
        if (typeof option === 'object') {
          id = option.name;
          value = option.value;
          activeElement = option.activeElement;
        } else {
          id = value = option;
        }
        const selected = id === checked;
        return (
          <React.Fragment key={index}>
            <label
              key={index}
              htmlFor={id}
              className={`group leading-radio flex ev-radio-group ${
                selected && selectedClassName ? selectedClassName : extraClass
              }`}
            >
              <input
                id={id}
                type="radio"
                name={groupName}
                value={id}
                onChange={onChange}
                checked={id === checked}
                tabIndex={tabIndex}
                className={`
              appearance-none bg-white m-0 font-inherit text-ev-navy-blue-2 border-2 border-ev-navy-blue-2 rounded-[50%] 
              translate-y-[-0.075em] grid place-content-center w-6 h-6
              focus:shadow-none focus:shadow-transparent focus:ring-offset-0 focus:ring-transparent 
              group-focus:bg-ev-baby-blue-2
              group-focus-within:shadow-none group-focus-within:ring-transparent group-focus-within:ring-offset-0 group-focus-within:border-ev-navy-blue-2
              hover:bg-ev-baby-blue-2
              before:w-[16px] before:h-[16px] before:border-2 before:border-white before:rounded-[50%] before:shadow-radio
              hover:before:border-ev-baby-blue-2
              `}
              />
              <div className="pl-3">{value}</div>
            </label>
            {selected ? activeElement : null}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default RadioGroup;
