import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Dumbbell: TemplateProps = {
  title: 'Dumbbell',
  description: '',
  constructor: '',
  thumbnail: 'dumbell.png',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'dumbbell'
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    }
  },
  plugins: ['dumbbell'],
  sampleSet: 'dumbbell',
  advancedParent: 'Dumbbell',
  dataValidator: false,
  basic: {
    order: 25,
    thumbnail: 'dumbbell_chart.svg',
    icon: 'Dumbbell'
  },
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter)
};

export default Dumbbell;
