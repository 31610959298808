import React from 'react';
import Pagination from './Pagination';
import Search from './Search';

type TableExtrasProps = {
  aggregatedOptions: any;
  isBottom: boolean;
};

const TableExtras = ({ aggregatedOptions, isBottom }: TableExtrasProps) => {
  const canvasStyle = aggregatedOptions.canvas ? aggregatedOptions.canvas.style : null;
  const extraContainerStyle = {
    backgroundColor: canvasStyle?.backgroundColor ?? ''
  };

  return (
    <div style={extraContainerStyle}>
      <div
        className={`extras ml-2.5 mr-[11px] ${isBottom ? 'mb-2.5' : 'mt-2.5'}`}
        style={aggregatedOptions?.table?.extra?.style ?? {}}
      >
        {aggregatedOptions?.table?.searchable?.enabled && <Search options={aggregatedOptions.table.searchable} />}
        {aggregatedOptions?.table?.pagination?.enabled && <Pagination aggregatedOptions={aggregatedOptions} />}
      </div>
    </div>
  );
};

export default TableExtras;
