import { TemplateTypeProps } from '../../types';
import StackedArea from './StackedArea';
import StackedPercentage from './StackedPercentage';
import StackedWithLabels from './StackedWithLabels';

const StackedAreaTemplate: TemplateTypeProps = {
  description: '',
  icon: '',
  sampleData: [],
  templates: {
    'Stacked percentage': StackedPercentage,
    'Stacked area': StackedArea,
    'Stacked with labels': StackedWithLabels
  }
};

export default StackedAreaTemplate;
