import * as React from 'react';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import countries from 'utils/countries';
import { BillingForm } from '../types/plansModels';
import CheckoutField from './CheckoutField';

type EditPaymentFormProps = {
  billingForm: BillingForm;
  handleChange: React.ChangeEventHandler;
  handleSubmit: (event: React.MouseEvent, token_id?: string) => void;
  saving: boolean;
  billingError: string;
};

const EditPaymentForm = (props: EditPaymentFormProps) => {
  const { billingForm, handleChange } = props;
  return (
    <div className="flex flex-col relative bg-ev-grey rounded-lg px-6 py-4 shadow-ev-standard border border-ev-grey-3">
      <p className="font-base font-bold">Billing address</p>
      <CheckoutField field={billingForm.address1} onChange={handleChange} />
      <CheckoutField field={billingForm.address2} onChange={handleChange} />
      <div className="grid grid-cols-2 gap-2">
        <CheckoutField field={billingForm.postal_code} onChange={handleChange} />
        <CheckoutField field={billingForm.country} onChange={handleChange} select={true} selectOptions={countries} />
      </div>
      <hr className="mt-10 border-ev-navy-blue-2 border-[2px]" />
      <p className="font-base font-bold">Payment method</p>
      <CheckoutField field={billingForm.number} onChange={handleChange} recurlyContainer={true} />
      <div className="grid grid-cols-3 gap-2">
        <CheckoutField field={billingForm.month} onChange={handleChange} recurlyContainer={true} />
        <CheckoutField field={billingForm.year} onChange={handleChange} recurlyContainer={true} />
        <CheckoutField field={billingForm.cvv} onChange={handleChange} recurlyContainer={true} />
      </div>
      <div className="grid grid-cols-2 gap-2">
        <CheckoutField field={billingForm.first_name} onChange={handleChange} />
        <CheckoutField field={billingForm.last_name} onChange={handleChange} />
      </div>
      <PrimaryButton
        text="Update payment method"
        onClick={props.handleSubmit}
        buttonColor={ButtonColor.NavyBlue}
        className="self-end mt-8"
        useLoader={{
          default: { text: 'Update payment method' },
          loading: { text: 'Updating payment method', icon: 'rotate', check: props.saving }
        }}
      />
      {props.billingError && (
        <div className="mt-4 self-end font-bold text-ev-error">
          {props.billingError}
          <span>
            <i className="pl-2 fa fa-info-circle" />
          </span>
        </div>
      )}
    </div>
  );
};

export default EditPaymentForm;
