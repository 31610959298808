import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { XIcon } from '@heroicons/react/outline';
import TextInput from '../../../shared/inputs/TextInput';
import PrimaryButton from '../../../shared/buttons//PrimaryButton.tsx';
import { useSelector, useDispatch } from 'react-redux';
import { setAction } from '../actions/projectsPage';
import { postFeedbackTeamUserUsingTeamid } from '../../../api/cloudApiGenerated';
import qs from 'qs';
import CheckboxInput from '../../../shared/inputs/CheckboxInput';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';

export default function FeedbackModal(props) {
  const dispatch = useDispatch();

  const [selected, changeSelected] = useState();
  const [comment, changeComment] = useState();
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [style, setStyle] = useState({});
  const [done, setDone] = useState(false);
  const { team, user } = useSelector((state) => state.profile);

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const inWizard = 'wizard' in query;

  useEffect(() => changeSelected(selected), [selected]);
  useEffect(() => setLoading(loading), [loading]);
  useEffect(() => setDone(done), [done]);
  useEffect(() => setStyle(style), [style]);
  useEffect(() => setCheckbox(checkbox), [checkbox]);
  useEffect(() => changeComment(comment), [comment]);
  useEffect(() => {
    if (props.isOpen) {
      const firstProject = document.querySelector('.project-grid .project-card');
      if (firstProject) {
        const bbox = firstProject.getBoundingClientRect();
        if (bbox) {
          setStyle({
            left: bbox.width + bbox.left
          });
        }
      }
    }
    dispatch(
      setAction({
        highlightFirstProject: props.isOpen
      })
    );
  }, [props.isOpen]);

  const toggleCheckbox = () => setCheckbox(!checkbox);

  const sendFeedback = async (showComment) => {
    if (loading) return;
    if (showComment) setLoading(true);

    try {
      await postFeedbackTeamUserUsingTeamid(team.id, {
        rating: selected,
        feedback: showComment && comment ? comment : null,
        user_id: user.id,
        new_user: inWizard,
        contact: checkbox,
        report: true
      });

      if (showComment) {
        setTimeout(() => {
          setLoading(false);
          setDone(true);
        }, 500);
      }
      setTimeout(() => closeForm(), showComment ? 1000 : 200);
    } catch (e) {
      setLoading(false);
      closeForm();
    }
  };

  const closeForm = async (sendEmpty) => {
    if (sendEmpty) {
      await postFeedbackTeamUserUsingTeamid(team.id, {
        user_id: user.id,
        report: false,
        new_user: inWizard
      });
    }
    dispatch(
      setAction({
        isFeedbackFormActive: false
      })
    );
  };

  const Card = (props) => (
    <button
      className={`
        text-ev-navy-blue-2 h-[112px] w-[112px] rounded cursor-pointer group
        ${selected === props.id ? 'bg-ev-navy-blue-2 text-white' : 'bg-white hover:bg-ev-baby-blue-2 '}
      `}
      onClick={() => changeSelected(props.id)}
    >
      <i
        className={`fad ${props.inverted ? 'inverted' : ''} text-3xl pt-2 px-8 fa-${props.icon}
        `}
      ></i>
      <div className="center font-medium mt-4 text-sm leading-4	">{props.text}</div>
    </button>
  );

  const text = {
    1: "That's great! Anything else you'd like to add?",
    2: 'There is always room for improvement. Do you have any suggestions?',
    3: 'Sorry to hear that. Could you tell us what we might need to improve?'
  };

  let className = selected ? 'max-h-[542px]' : 'max-h-[222px] rounded-b-none';

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={closeForm}
      shouldCloseOnOverlayClick
      appElement={document.querySelector('.page-container')}
      overlayClassName={'fixed inset-0 w-screen h-screen'}
      className={'h-full pointer-events-none flex items-center justify-center'}
      closeTimeoutMS={150}
    >
      <div
        className={`pointer-events-auto z-10 mx-10 pb-6 rounded-xl w-[434px] ${className} 
        transition-all overflow-hidden shadow-xl bg-ev-navy-blue-2
        absolute
      `}
        style={style}
      >
        <div className="bg-ev-navy-blue-2 rounded-t-xl rounded-b-none p-8 pb-2 items-center text-white text-xl flex justify-between">
          <div>
            <span className="font-bold text-lg pt-1">Was it easy to create this project?</span>
          </div>
          <button onClick={closeForm} className="text-mono text-lg font-semibold text-ev-baby-blue-2">
            <XIcon className="h-8 w-8 p-1 text-ev-baby-blue-2" />
          </button>
        </div>
        <div>
          <div className="flex gap-4 pt-4 justify-center pb-8">
            <Card
              icon="party-horn"
              text={
                <>
                  Yes it was
                  <br />
                  easy!
                </>
              }
              inverted={true}
              id={1}
            />
            <Card
              icon="thumbs-up"
              text={
                <>
                  It was
                  <br />
                  okay
                </>
              }
              id={2}
            />
            <Card
              icon="thumbs-down"
              text={
                <>
                  No, it was
                  <br />
                  difficult
                </>
              }
              id={3}
            />
          </div>

          {selected && (
            <div
              className={`
                mx-auto w-0 h-0 border-l-[15px] border-r-[15px] border-t-[15px] border-t-ev-navy-blue-2 top-[14px] relative 
                border-l-transparent border-r-transparent border-solid"></div>
              `}
            ></div>
          )}

          <div className="bg-ev-yellow-1 w-[434px] h-[295px] pt-6 px-8 text-ev-navy-blue-2">
            <div className="font-normal text-sm h-[37px]">{text[selected]}</div>
            <div className="font-bold mt-6 text-xs">
              <label>Add a comment (Optional)</label>
              <div>
                <TextInput
                  name="search"
                  value={comment}
                  onChange={(e) => changeComment(e.target.value)}
                  extraClass={'w-full'}
                  inputClassName={'highed-field-input'}
                />
              </div>
            </div>

            <div>
              <CheckboxInput
                name={'contact'}
                label={<span>Yes, everviz can contact me about this comment.</span>}
                onChange={toggleCheckbox}
                checked={checkbox}
                extraClass={'pt-4'}
                small={true}
              />
            </div>

            <div className="flex gap-4 pt-6 pb-8">
              <PrimaryButton
                onClick={() => sendFeedback(comment)}
                useLoader={{
                  default: {
                    text: 'Send comment',
                    icon: 'paper-plane'
                  },
                  loading: {
                    text: 'Sending',
                    check: loading
                  },
                  done: {
                    icon: 'circle-check',
                    text: 'Thank you!',
                    check: done
                  }
                }}
              />
              <PrimaryButton onClick={() => sendFeedback()} text="No comment" buttonColor={ButtonColor.Transparent} />
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
