import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../shared/inputs/TextInput';
import AlertMessage from '../../../shared/message/AlertMessage';

const UserSettingsForm = ({ settings, onSave, onChange, saving, sending, onClick }) => {
  return (
    <form className="table-container">
      <div className="content">
        <div>
          <TextInput
            name="username"
            inlineLabel={'Display name:'}
            label=""
            style={{ display: 'inline' }}
            value={settings.username}
            onChange={onChange}
          />
          <input
            type="submit"
            disabled={saving}
            value={saving ? 'Saving...' : 'Save'}
            className="bulk-action bulk-action-btn inline"
            onClick={onSave}
          />
          {!settings.email_verified && !sending && (
            <input
              type="submit"
              value={'Send verification'}
              className="bulk-action bulk-action-btn inline"
              onClick={onClick}
            />
          )}

          {/*(sending) &&
          <AlertMessage
            type="success"
            tag="Success"
            text="Email Sent"
           />
          */}
        </div>
      </div>
    </form>
  );
};

UserSettingsForm.propTypes = {
  settings: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  sending: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default UserSettingsForm;
