import { nanoid } from '@reduxjs/toolkit';
import {
  AnimationKeyFrame,
  AnimationViewState,
  LocationMapContainerRef,
  LocationMapEntryPoint
} from '@visual-elements/location-map';
import { Map } from '@visual-elements/maplibre-gl';
import classNames from 'classnames';
import { AnimationPlaybackControls, animate, motion } from 'framer-motion';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAnimationKeyFrameAction,
  removeAnimationKeyFrameAction,
  updateAnimationKeyFrameAction
} from '../../../../pages/ChartEditorPage/actions/locationMap';
import { ProjectConfigLocationMapProps } from '../../../../pages/Editor/reducers/locationMapConfigTypes';
import {
  pauseAnimation,
  playAnimation,
  selectAnimationPlaybackState,
  setCurrentKeyframe,
  stepCurrentKeyFrame
} from '../../../../redux/reducers/locationMap/animationReducer';
import { selectEditorMapRef } from '../../../../redux/reducers/locationMap/instanceReducer';
import { getProjectConfig } from '../../../../redux/selectors/projectConfig';
import keyframeTransition from '../../../../static/icons/templates/keyframe_transition.svg';
import IconButton from '../../../buttons/IconButton';
import SvgIconButton from '../../../buttons/SvgIconButton';
import TextButton from '../../../buttons/TextButton';
import { ButtonColor, ButtonType } from '../../../buttons/types/ButtonModels';
import {
  getClassesForButtonColor,
  getClassesForButtonType,
  getDisabledButtonClasses
} from '../../../buttons/utils/buttonHelper';
import NumberInputWidget from '../../../widgets/NumberInputWidget';
import SelectWidget from '../../../widgets/SelectWidget';
import { mapToLocationMapPropsPreview } from '../../../wizard/utils/locationMapMapper';

type KeyframeTransitionPopupProps = {
  index: number;
  keyframe: AnimationKeyFrame;
};

const KeyframeTransitionPopup = ({ index, keyframe }: KeyframeTransitionPopupProps) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const togglePopup = () => setIsOpen(!isOpen);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      isOpen &&
      !popupRef.current?.contains(event.target as HTMLElement) &&
      !buttonRef.current?.contains(event.target as HTMLElement)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isOpen]);

  useLayoutEffect(() => {
    if (isOpen && buttonRef.current && popupRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const popupWidth = popupRef.current?.offsetWidth || 0;
      const popupHeight = popupRef.current?.offsetHeight || 0;

      const left = buttonRect.left + (buttonRect.width - popupWidth) / 2;
      const top = buttonRect.top - popupHeight - 8;

      popupRef.current.style.left = `${left}px`;
      popupRef.current.style.top = `${top}px`;
    }
  }, [isOpen, buttonRef]);

  return (
    <>
      <div ref={buttonRef}>
        <SvgIconButton
          width={30}
          height={30}
          onClick={togglePopup}
          buttonColor={ButtonColor.Transparent}
          Icon={keyframeTransition}
        />
      </div>
      {isOpen && (
        <div className="bg-white rounded-lg shadow-md p-4 fixed z-10" ref={popupRef}>
          <IconButton
            className="absolute right-0 top-0 w-8 h-8"
            icon={'xmark'}
            buttonColor={ButtonColor.Transparent}
            iconClass="content-centered"
            tooltipText={'Close'}
            onClick={togglePopup}
          />
          <div className="popup-content pt-2">
            <div className="flex flex-col gap-4 p-2 pb-4">
              <h4 className="pb-1 text-center">Transition Options</h4>
              <div onClick={(e) => e.stopPropagation()}>
                <SelectWidget
                  label={'Easing'}
                  className="bg-ev-grey"
                  backgroundColor="bg-ev-grey"
                  onChange={(e) =>
                    dispatch(
                      updateAnimationKeyFrameAction({
                        index: index + 1,
                        easing: e.val
                      })
                    )
                  }
                  selectOptions={[
                    { label: 'None', value: 'none' },
                    { label: 'In', value: 'in' },
                    { label: 'In and out', value: 'in&out' },
                    { label: 'Out', value: 'out' }
                  ]}
                  value={{
                    label: keyframe.easing,
                    value: keyframe.easing
                  }}
                />
              </div>
              <NumberInputWidget
                label={'Duration'}
                className="bg-ev-grey"
                backgroundColor="bg-ev-grey"
                onChange={(e) =>
                  dispatch(
                    updateAnimationKeyFrameAction({
                      index: index + 1,
                      duration: e.val
                    })
                  )
                }
                value={keyframe.duration}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

type KeyframePreviewProps = {
  children: React.ReactNode;
  onRemove: () => void;
  onEdit: () => void;
  onSelect: () => void;
  isCurrentKeyframe: boolean;
};
const KeyframePreview = ({ children, isCurrentKeyframe, onEdit, onRemove, onSelect }: KeyframePreviewProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const keyframeClasses = classNames(
    'h-full aspect-[5/3] relative rounded-2xl border-ev-navy-blue border border-solid',
    {
      'border-ev-light-blue border-2': isCurrentKeyframe
    }
  );

  return (
    <div className={keyframeClasses} onMouseOver={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      {children}
      {(isHovering || isCurrentKeyframe) && (
        <IconButton
          className="absolute right-0 top-0 w-6 h-6 aspect"
          icon={'trash'}
          buttonColor={ButtonColor.Transparent}
          iconClass="content-centered"
          tooltipText={'Delete keyframe'}
          onClick={onRemove}
        />
      )}
      {isHovering && !isCurrentKeyframe && (
        <IconButton
          className="rounded-full w-12 h-12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          icon={'location-pen'}
          iconClass="content-centered font text-xl"
          tooltipText={'Edit keyframe'}
          onClick={onSelect}
        />
      )}
      {isCurrentKeyframe && (
        <IconButton
          className="rounded-full w-12 h-12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          icon={'camera-viewfinder'}
          iconClass="content-centered font text-xl"
          tooltipText={'Set keyframe to current view'}
          onClick={onEdit}
        />
      )}
    </div>
  );
};

type AddKeyFrameButtonProps = {
  isDisabled: boolean;
  onClick: () => void;
};

const AddKeyframeButton = ({ isDisabled, onClick }: AddKeyFrameButtonProps) => {
  const [mouseDown, setMouseDown] = useState(false);

  const buttonClasses = classNames(getClassesForButtonType(ButtonType.Action), {
    [getClassesForButtonColor(ButtonColor.TransparentBordered)]: !isDisabled,
    [getDisabledButtonClasses(ButtonColor.Disabled)]: isDisabled
  });

  const iconContainerClasses = classNames('content-centered w-6 h-6 rounded-full', {
    ['bg-ev-grey']: mouseDown || isDisabled,
    ['bg-ev-navy-blue-2']: !mouseDown
  });
  const iconClasses = classNames('fa fa-plus text-xs', {
    ['text-ev-navy-blue-2']: mouseDown,
    ['text-ev-grey']: !mouseDown
  });

  return (
    <button
      className={buttonClasses + ' rounded-2xl flex flex-col gap-2 justify-center items-center ml-9'}
      disabled={isDisabled}
      onClick={onClick}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
    >
      <div className={iconContainerClasses}>
        <i className={iconClasses} />
      </div>
      <div className="underline font-bold">Add current</div>
    </button>
  );
};

export type LocationMapAnimationTimelineProps = {
  editable: boolean;
  className?: string;
};

const maximumKeyframes = 10;

export const LocationMapAnimationTimeline = ({ className, editable }: LocationMapAnimationTimelineProps) => {
  const { aggregatedOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);
  const { isPlaying, currentKeyFrame } = useSelector(selectAnimationPlaybackState);
  const editorMapInstance = useSelector(selectEditorMapRef);
  const [isOpen, setIsOpen] = useState(false);

  const locationMapRefs = useRef<LocationMapContainerRef[]>([]);
  const ids = useRef<string[]>(
    Array(maximumKeyframes)
      .fill('')
      .map(() => nanoid(6))
  );

  const locationMap = editorMapInstance?.mapRef;
  const map = locationMap?.getMap();
  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>(null);
  const keyFramesAmount = useRef(0);

  const keyFrames = aggregatedOptions.animation.keyFrames;

  const keyFrameViewStates = useMemo(() => {
    const kf: (AnimationViewState & { id: string })[] = [];
    if (aggregatedOptions.animation.enabled) {
      kf.push({ ...aggregatedOptions.animation.initialViewState, id: ids.current[kf.length] });

      if (keyFrames) {
        kf.push(...(keyFrames.map((x) => ({ ...x, id: ids.current[kf.length] })) ?? []));
      }
    }

    kf.forEach((x, index) => {
      const map = locationMapRefs.current[index]?.mapRef?.getMap();
      if (map) {
        const { height, width } = map.getContainer().getBoundingClientRect();

        const heightScale = height / aggregatedOptions.viewState.referenceHeight;
        const widthScale = width / aggregatedOptions.viewState.referenceWidth;
        const calculatedZoomLevel = x.zoom + Math.log2(Math.min(heightScale, widthScale));
        map.jumpTo(
          {
            center: x.center,
            bearing: x.bearing,
            pitch: x.pitch,
            zoom: calculatedZoomLevel
          },
          { initiatedProgrammatically: true }
        );
      }
    });
    return kf;
  }, [aggregatedOptions.animation]);

  useEffect(() => {
    let controls: AnimationPlaybackControls | undefined = undefined;
    if (keyFrames.length > keyFramesAmount.current) {
      if (containerRef.current) {
        controls = animate(containerRef.current.scrollLeft, containerRef.current.scrollWidth, {
          onUpdate: (v) => {
            if (containerRef.current) {
              containerRef.current.scrollLeft = v;
            }
          },
          duration: 0.3
        });
      }
    }
    keyFramesAmount.current = keyFrames.length;
    return () => {
      controls?.stop();
    };
  }, [keyFrames]);

  const callbackRef = useCallback((ref: LocationMapContainerRef) => {
    if (ref) {
      locationMapRefs.current.push(ref);
    }
  }, []);

  const getZoom = (mapInstance: Map) => {
    const { height, width } = mapInstance.getContainer().getBoundingClientRect();
    const heightScale = height / aggregatedOptions.viewState.referenceHeight;
    const widthScale = width / aggregatedOptions.viewState.referenceWidth;
    return mapInstance.getZoom() - Math.log2(Math.min(heightScale, widthScale));
  };

  const buttonIsDisabled = keyFrames.length > maximumKeyframes;

  return (
    <motion.div
      className={'flex bg-white z-10 p-2 min-h-[42px] ' + className}
      layout={true}
      transition={{ duration: 0.2 }}
    >
      {isOpen && editable ? (
        <div className="relative flex p-2 w-full justify-center items-center bottom-0">
          <motion.div
            layout={true}
            className="content-centered self-center absolute -top-12 flex flex-column gap-2 bg-white p-3 rounded-t-lg"
          >
            <IconButton
              icon={'backward-step'}
              onClick={() => dispatch(stepCurrentKeyFrame({ direction: 'backward' }))}
              className="rounded-full w-8 h-8 shrink-0"
              buttonColor={ButtonColor.NavyBlue}
              iconClass="content-centered"
            />
            {isPlaying ? (
              <IconButton
                icon={'pause'}
                onClick={() => dispatch(pauseAnimation())}
                className="rounded-full w-10 h-10 shrink-0"
                buttonColor={ButtonColor.NavyBlue}
                iconClass="content-centered"
              />
            ) : (
              <IconButton
                icon={'play'}
                onClick={() => dispatch(playAnimation())}
                className="rounded-full w-10 h-10 shrink-0"
                buttonColor={ButtonColor.NavyBlue}
                iconClass="content-centered"
              />
            )}
            <IconButton
              icon={'forward-step'}
              onClick={() => dispatch(stepCurrentKeyFrame({ direction: 'forward' }))}
              className="rounded-full w-8 h-8 shrink-0"
              buttonColor={ButtonColor.NavyBlue}
              iconClass="content-centered"
            />
          </motion.div>
          <motion.div
            layout={true}
            className="flex flex-row h-36 w-full items-center pl-4 pr-4 pt-2 pb-2 gap-4 ev-scrollbar"
          >
            <div
              ref={containerRef}
              className={'w-full h-full flex flex-row items-center overflow-x-auto pt-2 pb-2 gap-1'}
            >
              {keyFrameViewStates.map((animation, index) => {
                return (
                  <>
                    <KeyframePreview
                      onSelect={() => dispatch(setCurrentKeyframe({ index: index }))}
                      onEdit={() =>
                        map &&
                        dispatch(
                          updateAnimationKeyFrameAction({
                            index: index,
                            viewState: {
                              bearing: map.getBearing(),
                              zoom: getZoom(map),
                              pitch: map.getPitch(),
                              center: map.getCenter().toArray()
                            }
                          })
                        )
                      }
                      onRemove={() => dispatch(removeAnimationKeyFrameAction({ index: index }))}
                      key={`preview-${animation.id}`}
                      isCurrentKeyframe={index === currentKeyFrame}
                    >
                      <LocationMapEntryPoint
                        ref={callbackRef}
                        {...mapToLocationMapPropsPreview(aggregatedOptions, {
                          viewState: {
                            override: true,
                            value: {
                              bearing: animation.bearing,
                              center: animation.center,
                              pitch: animation.pitch,
                              zoom: animation.zoom
                            }
                          },
                          zoom: {
                            override: true,
                            value: animation.zoom
                          },
                          border: '16px'
                        })}
                      />
                    </KeyframePreview>
                    {index !== keyFrameViewStates.length - 1 && keyFrameViewStates.length !== 1 && (
                      <KeyframeTransitionPopup
                        key={`transition-${animation.id}`}
                        index={index}
                        keyframe={keyFrames[index]}
                      />
                    )}
                  </>
                );
              })}
              <AddKeyframeButton
                isDisabled={buttonIsDisabled}
                onClick={() => {
                  if (map) {
                    dispatch(
                      addAnimationKeyFrameAction({
                        bearing: map.getBearing(),
                        zoom: getZoom(map),
                        pitch: map.getPitch(),
                        center: map.getCenter().toArray()
                      })
                    );
                  }
                }}
              />
            </div>
          </motion.div>
          <IconButton
            icon={'caret-down'}
            onClick={() => setIsOpen(false)}
            className="rounded-full w-7 h-7 shrink-0 absolute right-0 top-0"
            buttonColor={ButtonColor.Transparent}
            iconClass="content-centered"
          />
        </div>
      ) : (
        <motion.div layout={true} className="flex w-full relative justify-between">
          {editable && <TextButton className="self-end" text="Edit Animation" onClick={() => setIsOpen(true)} />}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {isPlaying ? (
              <IconButton
                icon={'pause'}
                onClick={() => dispatch(pauseAnimation())}
                className="rounded-full w-10 h-10 shrink-0 relative -top-5"
                buttonColor={ButtonColor.NavyBlue}
                iconClass="content-centered"
              />
            ) : (
              <IconButton
                icon={'play'}
                onClick={() => dispatch(playAnimation())}
                className="rounded-full w-10 h-10 shrink-0 relative -top-5"
                buttonColor={ButtonColor.NavyBlue}
                iconClass="content-centered"
              />
            )}
          </div>
          {editable && (
            <IconButton
              icon={'caret-up'}
              onClick={() => setIsOpen(true)}
              className="rounded-full w-7 h-7 shrink-0"
              buttonColor={ButtonColor.Transparent}
              iconClass="content-centered"
            />
          )}
        </motion.div>
      )}
    </motion.div>
  );
};
