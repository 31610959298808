import React from 'react';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import CheckboxInput from '../inputs/CheckboxInput';
import FieldContainer from './FieldContainer';

const CheckboxWidget = (props: GenericInputProps) => {
  const { value } = props;
  const checked = value === 'true' || value === true;
  const fieldValue = checked ? 'On' : 'Off';

  const onHandleChange = (val: boolean) => {
    if (props.isArraySelector) props.onChange({ val, index: props.index });
    else props.onChange({ val });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onHandleChange(e.target.checked);
  const handleContainerClick = () => onHandleChange(!checked);

  // Without this, both of the event functions above get called when toggling the checkbox
  const handleCheckboxClick = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();

  return (
    <FieldContainer
      value={fieldValue}
      label={props.label}
      labelClass="text-ev-navy-blue-4"
      className={`${props.className ?? 'bg-white'} cursor-pointer`}
      id={props.option?.id}
      onClick={handleContainerClick}
    >
      <CheckboxInput
        checked={checked}
        onChange={handleChange}
        name={props.label}
        onClick={handleCheckboxClick}
        labelAlign="left"
        labelClass="text-sm font-bold"
        className="justify-between p-0 ml-3 h-6 w-6"
      />
    </FieldContainer>
  );
};

export default CheckboxWidget;
