export default {
  page: 1,
  pageSize: 10,
  pageCount: 1,
  themes: [],
  dropdownActions: [
    {
      value: '',
      label: 'Enable'
    },
    {
      value: '',
      label: 'Disable'
    },
    {
      value: '',
      label: 'Change owner'
    }
  ],
  errorMessage: null,
  themeManagementPerm: null,
  dataReturned: false,
  defaultThemes: null,
  deleted: null,
  query: '',
  companyThemes: false
};
