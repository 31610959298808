import * as React from 'react';
import countries from 'utils/countries';
import { BillingForm } from '../types/plansModels';
import CheckoutField from './CheckoutField';
import CheckoutTile from './CheckoutTile';

type BillingInfoProps = {
  editing: boolean;
  toggleEditing: () => void;
  billingForm: BillingForm;
  billingData: any;
  handleChange: React.ChangeEventHandler;
  handleSubmit: (event?: React.MouseEvent) => void;
  address: any;
  saving: boolean;
  isLoggedIn: boolean;
};

const BillingInformation = (props: BillingInfoProps) => {
  const { billingForm, handleChange, billingData, isLoggedIn, address } = props;
  const hasPaymentData = billingData?.card_type && billingData?.last_four_digits;
  const [cardType, setCardType] = React.useState('');
  const editing = isLoggedIn ? props.editing : true;

  React.useEffect(() => {
    if (billingData?.card_type === 'American Express') setCardType('amex');
    else setCardType(billingData?.card_type?.toLowerCase());
  }, [billingData?.card_type]);

  return (
    <CheckoutTile
      title="3. Billing information"
      completed={Boolean(address) && Boolean(hasPaymentData)}
      isEditable={Boolean(address)}
      editing={editing}
      toggleEditing={props.toggleEditing}
      submitButtonText={'Add billing information'}
      handleSubmit={isLoggedIn ? (ev) => props.handleSubmit(ev) : undefined}
      saving={props.saving}
    >
      <div className={`${editing ? 'block' : 'hidden'}`}>
        <p className="mt-4 font-base font-bold">Billing address</p>
        <CheckoutField field={billingForm.address1} onChange={handleChange} />
        <CheckoutField field={billingForm.address2} onChange={handleChange} />
        <div className="grid grid-cols-2 gap-2">
          <CheckoutField field={billingForm.postal_code} onChange={handleChange} />
          <CheckoutField field={billingForm.country} onChange={handleChange} select={true} selectOptions={countries} />
        </div>
        <hr className="mt-10 border-ev-navy-blue-2 border-[2px]" />
        <p className="font-base font-bold">Payment method</p>
        <CheckoutField field={billingForm.number} onChange={handleChange} recurlyContainer={true} />
        <div className="grid grid-cols-3 gap-2">
          <CheckoutField field={billingForm.month} onChange={handleChange} recurlyContainer={true} />
          <CheckoutField field={billingForm.year} onChange={handleChange} recurlyContainer={true} />
          <CheckoutField field={billingForm.cvv} onChange={handleChange} recurlyContainer={true} />
        </div>
        <div className="grid grid-cols-2 gap-2">
          <CheckoutField field={billingForm.first_name} onChange={handleChange} />
          <CheckoutField field={billingForm.last_name} onChange={handleChange} />
        </div>
      </div>
      {address && <p className={`${editing ? 'hidden' : 'block'}`}>{address}</p>}
      {hasPaymentData && (
        <div className={`${editing ? 'hidden' : 'flex'} items-center gap-2 text-base`}>
          <i className={`fa-brands fa-cc-${cardType}`} style={{ fontSize: '2rem' }} />
          <span>ending in {billingData?.last_four_digits}</span>
        </div>
      )}
    </CheckoutTile>
  );
};

export default BillingInformation;
