import React from 'react';
import PrimaryButton from './buttons/PrimaryButton';
import TextButton from './buttons/TextButton';

type UnsavedChangesBarProps = {
  onChangesReset: () => void;
  onChangesSave: () => void;
};

const UnsavedChangesBar = ({ onChangesReset, onChangesSave }: UnsavedChangesBarProps) => (
  <div className="bg-ev-alert-notice sticky bottom-6 ev-sm:h-14 rounded flex items-center justify-between p-4 ev-sm-max:flex-col">
    <p className="m-0 ev-sm-max:mb-2">Careful — you have unsaved changes!</p>
    <div>
      <TextButton text="Reset" medium={true} className="mr-4" onClick={onChangesReset} />
      <PrimaryButton text="Save changes" onClick={onChangesSave} />
    </div>
  </div>
);

export default UnsavedChangesBar;
