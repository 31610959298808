import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import AsyncSelect from 'react-select/async';
import { InputActionMeta, OptionProps, components } from 'react-select';
import { setLinkingDataError } from 'redux/reducers/wizardReducer';
import { searchApiAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { searchInputStyles } from 'shared/utils/selectStylesHelper';
import {
  ChartLiveDataProps,
  LinkDataTypes,
  Markets,
  PolygonMarkets,
  StockTimePeriod,
  LinkDataOption,
  StockDataType,
  PerformanceTimePeriod
} from 'shared/wizard/meta/LinkDataOptions';

import CloseIcon from 'static/icons/close.svg';
import LoaderIcon from 'shared/loader/LoaderIcon';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';

let loadOptionsTimeout: NodeJS.Timeout;

export type SearchedOption = {
  label: string;
  value: string;
  type: string;
  exchange?: string;
  isCurrency?: boolean;
};

type DataServerStocksProps = {
  handleSubmit: (options: ChartLiveDataProps, dataType: LinkDataTypes) => void;
  shouldSubmit: boolean;
  setErrorsComponent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  provider: LinkDataTypes;
};

type LabelProps = {
  text: string;
  className?: string;
};

const Label = (props: LabelProps) => (
  <span className={`text-ev-navy-blue font-bold mb-2 mt-4 ${props.className}`}>{props.text}</span>
);

const DataServerStocks = (props: DataServerStocksProps) => {
  const { handleSubmit, shouldSubmit, provider } = props;
  const [selectedMarkers, setSelectedMarkers] = useState<SearchedOption[]>([]);
  const [timePeriod, setTimePeriod] = useState<StockTimePeriod | PerformanceTimePeriod>(StockTimePeriod.Year);
  const [stockDataType, setStockDataType] = useState(StockDataType.Pricing);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const { linkingDataError } = useSelector((state: RootState) => state.wizardConfig);

  const markets = provider === LinkDataOption.Polygon ? PolygonMarkets : Markets;
  const [market, setMarket] = useState(markets.All);

  useEffect(() => {
    dispatch(setLinkingDataError({ error: '' }));
  }, []);

  useEffect(() => {
    if (stockDataType === StockDataType.Pricing) setTimePeriod(StockTimePeriod.Year);
    if (stockDataType === StockDataType.Performance) setTimePeriod(PerformanceTimePeriod.ThreeYears);
  }, [stockDataType]);

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit({ symbols: selectedMarkers, timePeriod, stockDataType }, provider);
    }
  }, [shouldSubmit]);

  useEffect(() => {
    if (linkingDataError) setMenuIsOpen(false);
  }, [linkingDataError]);

  const handleClick = (newMarker: SearchedOption) => {
    setSelectedMarkers((prev) => {
      if (prev.some((item) => item.value === newMarker.value)) return prev;
      else return [...prev, newMarker];
    });
  };

  const Item = ({ data }: { data: SearchedOption }) => {
    return (
      <div className="flex flex-col text-base text-ev-navy-blue font-bold m-0">
        <span className="text-ev-navy-blue">{data.label}</span>
        {!data.isCurrency && (
          <span className="font-medium text-ev-navy-blue text-sm">
            {data.value.toUpperCase()}
            {data.exchange ? ` (${data.exchange})` : ''}
          </span>
        )}
      </div>
    );
  };

  const LoadingIndicator = () => <LoaderIcon loading={true} iconClass="text-xl" />;
  const Option = (props: OptionProps<SearchedOption>) => (
    <components.Option
      {...props}
      innerProps={{
        onClick: (ev) => {
          if (props.innerProps.onClick) props.innerProps.onClick(ev);
          handleClick(props.data);
        }
      }}
    >
      <Item data={props.data} />
    </components.Option>
  );

  const deleteFromSelectedMarkers = (ev: React.MouseEvent) => {
    const id = ev.currentTarget.id;
    setSelectedMarkers((prev) => prev.filter((item) => item.value !== id));
  };

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    switch (actionMeta.action) {
      case 'input-change':
        setValue(newValue);
        return;
      case 'menu-close':
        setValue('');
        setMenuIsOpen(false);
        return;
    }
  };

  const loadOptions = (query: string) => {
    setMenuIsOpen(true);
    return new Promise<SearchedOption[]>((resolve) => {
      if (loadOptionsTimeout) {
        clearTimeout(loadOptionsTimeout);
      }

      loadOptionsTimeout = setTimeout(() => {
        dispatch(setLinkingDataError({ error: '' }));
        dispatch(searchApiAction({ query, market, resolve, provider: provider }));
      }, 500);
    });
  };

  return (
    <>
      <Label text="Select market" className="text-base" />
      <div className="flex gap-1">
        {Object.values(markets).map((item) => (
          <PrimaryButton
            key={item}
            text={item}
            onClick={() => setMarket(item)}
            buttonColor={market === item ? ButtonColor.NavyBlue : ButtonColor.White}
            buttonType={ButtonType.Info}
          />
        ))}
      </div>

      <Label
        text="Search for stocks and currencies"
        className={`text-base ${linkingDataError ? 'text-ev-error' : ''}`}
      />
      <AsyncSelect
        styles={searchInputStyles}
        placeholder="Search..."
        components={{ LoadingIndicator, Option }}
        classNames={{ control: () => `highed-field-input highed-field-input${linkingDataError ? '--error' : ''}` }}
        loadOptions={loadOptions}
        inputValue={value}
        onInputChange={handleInputChange}
        blurInputOnSelect={true}
        menuIsOpen={menuIsOpen}
        value={null}
      />
      {linkingDataError && <span className="text-ev-error pt-1">{linkingDataError}</span>}
      <Label text="Selected stocks and currencies" />

      {selectedMarkers.map((item) => (
        <div
          className="flex justify-between items-center text-base text-ev-navy-blue bg-white py-2 px-4 rounded-sm"
          key={item.value}
        >
          <Item data={item} />
          <SvgIconButton
            Icon={CloseIcon}
            onClick={(ev) => deleteFromSelectedMarkers(ev)}
            id={item.value}
            key={item.value}
            width={16}
            height={16}
            buttonColor={ButtonColor.Transparent}
            buttonClasses="w-6 h-6"
          />
        </div>
      ))}

      {!selectedMarkers.length && <p className="text-sm m-auto mt-4">No stocks have been selected yet</p>}

      {Boolean(selectedMarkers.length) && (
        <>
          <Label text="Select Data Type" />
          <div className="flex gap-1">
            {Object.values(StockDataType).map((item) => (
              <PrimaryButton
                key={item}
                text={item}
                onClick={() => setStockDataType(item)}
                buttonColor={stockDataType === item ? ButtonColor.NavyBlue : ButtonColor.White}
                buttonType={ButtonType.Info}
              />
            ))}
          </div>
        </>
      )}

      {selectedMarkers.length > 1 && stockDataType === StockDataType.Performance && (
        <Label text={`Performance data can only be shown for the first stock: ${selectedMarkers[0].label}`} />
      )}

      {Boolean(selectedMarkers.length) && stockDataType === StockDataType.Pricing && (
        <>
          <Label text="Select time period for dataset" />
          <div className="flex gap-1">
            {Object.values(StockTimePeriod).map((item) => (
              <PrimaryButton
                key={item}
                text={item}
                onClick={() => setTimePeriod(item)}
                buttonColor={timePeriod === item ? ButtonColor.NavyBlue : ButtonColor.White}
                buttonType={ButtonType.Info}
              />
            ))}
          </div>
        </>
      )}

      {Boolean(selectedMarkers.length) && stockDataType === StockDataType.Performance && (
        <>
          <Label text="Select time period for dataset" />
          <div className="flex gap-1">
            {Object.values(PerformanceTimePeriod).map((item) => (
              <PrimaryButton
                key={item}
                text={item}
                onClick={() => setTimePeriod(item)}
                buttonColor={timePeriod === item ? ButtonColor.NavyBlue : ButtonColor.White}
                buttonType={ButtonType.Info}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default DataServerStocks;
