import { loadCSVAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { setDataAction } from 'pages/TableEditorPage/actions/tableEditor';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CustomSelect, { SelectItem, customSelectColors } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';

const SeparatorTab = () => {
  const options: SelectItem[] = [
    { label: 'Comma', value: ',' },
    { label: 'Tab', value: '\t' },
    { label: 'Semicolon', value: ';' }
  ];
  const dispatch = useDispatch();
  const { aggregatedOptions, rawImportedCSV } = useSelector((state: RootState) => state.projectConfig);
  const { isMap } = useSelector((state: RootState) => state.chartEditorPage);
  const { type } = useSelector((state: RootState) => state.layoutEditorPage);
  const itemDelimiter = aggregatedOptions?.data?.itemDelimiter;
  const [separatorType, setSeparatorType] = React.useState(options[0]);

  React.useEffect(() => {
    if (itemDelimiter) setSeparatorType(options.find(({ value }) => value === itemDelimiter) as SelectItem);
  }, [itemDelimiter]);

  const handleChange = (selected: SelectItem | null) => {
    if (selected) {
      const loadDataAction = type === 'table' ? setDataAction : loadCSVAction;
      dispatch(loadDataAction({ dataType: 'csv', csv: rawImportedCSV, itemDelimiter: selected.value, isMap }));
      setSeparatorType(selected);
    }
  };

  return (
    <CustomSelect
      label="Separator type"
      containerClasses="mb-2"
      options={options}
      value={separatorType}
      onChange={handleChange}
      styles={editorStyles}
      backgroundColor={customSelectColors.white}
    />
  );
};

export default SeparatorTab;
