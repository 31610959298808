import { TemplateProps } from '../../types';

const Multicolor: TemplateProps = {
  title: 'Multi color',
  description: '',
  constructor: '',
  thumbnail: 'alyqyz.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        colorByPoint: true
      }
    }
  },
  sampleSet: 'column',
  advancedParent: 'Column',
  dataValidator: false
};

export default Multicolor;
