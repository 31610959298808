import AreaTemplate from './charts/area/AreaTemplate';
import BarTemplate from './charts/bar/BarTemplate';
import BubbleTemplate from './charts/bubble/BubbleTemplate';
import ColumnTemplate from './charts/column/ColumnTemplate';
import DonutTemplate from './charts/donut/DonutTemplate';
import LineTemplate from './charts/line/LineTemplate';
import MoreTemplate from './charts/more/MoreTemplate';
import PackedBubbleTemplate from './charts/packedBubble/PackedBubbleTemplate';
import PieTemplate from './charts/pie/PieTemplate';
import PolarAreaTemplate from './charts/polarArea/PolarAreaTemplate';
import ScatterTemplate from './charts/scatter/ScatterTemplate';
import StackedAreaTemplate from './charts/stackedArea/StackedAreaTemplate';
import StackedBarTemplate from './charts/stackedBar/StackedBarTemplate';
import StackedColumnTemplate from './charts/stackedColumn/StackedColumnTemplate';
import TimelineTemplate from './charts/timeline/TimelineTemplate';
import WindroseTemplate from './charts/windrose/WindroseTemplate';

import MapTemplate from './maps/MapTemplate';

import StockTemplate from './stock/StockTemplate';

export default {
  Area: AreaTemplate,
  Bar: BarTemplate,
  Line: LineTemplate,
  Pie: PieTemplate,
  Column: ColumnTemplate,
  Stock: StockTemplate,
  'Scatter chart': ScatterTemplate,
  Timeline: TimelineTemplate,
  More: MoreTemplate,
  'Stacked area': StackedAreaTemplate,
  'Stacked bar': StackedBarTemplate,
  'Stacked column': StackedColumnTemplate,
  Donut: DonutTemplate,
  'Bubble chart': BubbleTemplate,
  Map: MapTemplate,
  PackedBubble: PackedBubbleTemplate,
  'Polar area': PolarAreaTemplate,
  'Wind rose': WindroseTemplate
};
