import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getAvailableChannelForType } from 'shared/wizard/utils/channelHelper';
import { ChannelOption, ChannelOptions } from '../../meta/ChannelOptions';
import ChannelContent from './ChannelContent';
import ChannelTile from './ChannelTile';
import { TypeProps } from 'shared/types/chooseTheme';

type ChannelSectionContentProps = {
  onPublish: () => void;
};

const ChannelSectionContent = (props: ChannelSectionContentProps) => {
  const { type } = useSelector((state: RootState) => state.projectConfig);
  const [activeChannel, setActiveChannel] = useState(ChannelOption.Online);
  const [channelOptions, setChannelOptions] = useState(ChannelOptions);

  useEffect(() => {
    setChannelOptions(
      channelOptions.filter((option) => getAvailableChannelForType(type as TypeProps).includes(option.id))
    );
  }, [type]);

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        {channelOptions.map((channel) => {
          return (
            <ChannelTile
              key={channel.id}
              channel={channel}
              handleClick={() => setActiveChannel(channel.id)}
              isActive={activeChannel === channel.id}
            />
          );
        })}
      </div>
      <hr className="bg-ev-grey-3 my-6" />
      <div className="mb-4">
        <ChannelContent activeChannel={activeChannel} onPublish={props.onPublish} />
      </div>
    </>
  );
};

export default ChannelSectionContent;
