import { snackBar } from 'editor/editors/highed.init';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getGroups } from '../../../middleware/Profile';
import actionTypes from '../../../redux/actions/action-types';
import { getGroupsAction } from '../../../redux/actions/profile';
import { getProfileConfig } from '../../../redux/selectors/profile';
import { getRolePermissionsAction, setAction } from '../actions/rolesPage';

import {
  delTeamRolePermissionUsingTeamidAndRoleidAndPermissionid as delPermission,
  delTeamRoleUsingTeamidAndRoleid,
  getPermissions,
  getTeamRolePermissionsWithTeamidAndRoleid,
  postTeamRolePermissionUsingTeamidAndRoleidAndPermissionid as postPermission,
  postTeamRoleUsingTeamid,
  postTeamRoleUsingTeamidAndRoleid
} from '../../../api/cloudApiGenerated';

export function* getAllPermissions() {
  try {
    const data = yield call(getPermissions);
    yield put(
      setAction({
        permissions: data.data
      })
    );
  } catch (error) {
    yield put(
      setAction({
        error: 'Loading permissions failed.'
      })
    );
  }
}

export function* getRolePermissions(params) {
  const { teamId, roleId } = params.data;

  try {
    const data = yield call(getTeamRolePermissionsWithTeamidAndRoleid, teamId, roleId);
    const rolePermissions = data.map((permission) => permission.id);
    yield put(
      setAction({
        rolePermissionsDb: [...rolePermissions],
        rolePermissions: [...rolePermissions],
        roleId
      })
    );
  } catch (error) {
    yield put(
      setAction({
        error: 'Loading role permissions failed.'
      })
    );
  }
}

export function* addRole(params) {
  const { team, newRoleName } = params.data;

  try {
    const data = yield call(postTeamRoleUsingTeamid, team.id, { name: newRoleName });
    yield put(
      setAction({
        modalConfig: '',
        newRoleName: '',
        roleId: data.ids[0]
      })
    );
    yield put(getGroupsAction({ team }));
  } catch (error) {
    yield put(
      setAction({
        error: 'Adding new role failed.'
      })
    );
  }
}

export function* updateRole(params) {
  const { team, roles, roleId, newRoleName } = params.data;

  try {
    yield call(postTeamRoleUsingTeamidAndRoleid, team.id, roleId, { name: newRoleName });
    const id = roleId;
    roles.forEach((e) => {
      if (e.id === id) e.name = newRoleName;
    });
    yield put(setAction({ roles, roleName: newRoleName, modalConfig: '', newRoleName: '' }));
  } catch (error) {
    yield put(
      setAction({
        error: 'Loading role permissions failed.'
      })
    );
  }
}

export function* deleteRole(params) {
  const { team, roleId } = params.data;
  try {
    yield call(delTeamRoleUsingTeamidAndRoleid, team.id, roleId);
    yield put(setAction({ modalConfig: '', newRoleName: '' }));

    yield call(getGroups, {
      data: {
        team
      }
    });

    const config = yield select(getProfileConfig);
    yield put(getRolePermissionsAction({ team, roleId: config.groups[0].id }));
  } catch (error) {
    yield put(
      setAction({
        error: 'Deleting role failed.'
      })
    );
  }
}

export function* setRolePermissions(params) {
  const { team, permissions, roleId, rolePermissions, rolePermissionsDb } = params.data;

  const transactions = [];
  permissions.forEach((permission) => {
    const teamId = team.id;
    const permissionId = permission.id;
    const checked = rolePermissions.includes(permissionId);
    const wasChecked = rolePermissionsDb.includes(permissionId);
    if (checked && !wasChecked) transactions.push(call(postPermission, teamId, roleId, permissionId));
    else if (!checked && wasChecked) transactions.push(call(delPermission, teamId, roleId, permissionId));
  });

  try {
    yield all(transactions);
    yield put(getRolePermissionsAction({ teamId: team.id, roleId }));
    yield put(setAction({ unsavedChanges: false }));
    snackBar('Permissions changed');
  } catch (error) {
    yield put(
      setAction({
        error: 'Saving role permissions failed.'
      })
    );
  }
}

/** Watch functions */
export function* watchGetAllPermissions() {
  yield takeLatest(actionTypes.rolesPage.getPermissions, getAllPermissions);
}
export function* watchGetRolePermissions() {
  yield takeLatest(actionTypes.rolesPage.getRolePermissions, getRolePermissions);
}
export function* watchSetRole() {
  yield takeLatest(actionTypes.rolesPage.addRole, addRole);
}
export function* watchUpdateRole() {
  yield takeLatest(actionTypes.rolesPage.updateRole, updateRole);
}
export function* watchDeleteRole() {
  yield takeLatest(actionTypes.rolesPage.deleteRole, deleteRole);
}
export function* watchSetRolePermissions() {
  yield takeLatest(actionTypes.rolesPage.setRolePermissions, setRolePermissions);
}

export default function* rootSaga() {
  yield all([
    watchGetAllPermissions(),
    watchGetRolePermissions(),
    watchSetRole(),
    watchUpdateRole(),
    watchDeleteRole(),
    watchSetRolePermissions()
  ]);
}
