import actionTypes from '../../../redux/actions/action-types';

export const setAction = (data) => ({
  type: actionTypes.chartEditor.set,
  data
});
export const setDataAction = (data) => ({
  type: actionTypes.chartEditor.setData,
  data
});

export const saveChartAction = (data) => ({
  type: actionTypes.chartEditor.saveChart,
  data
});

export const publishChartAction = (data) => ({
  type: actionTypes.chartEditor.publishChart,
  data
});

export const assignThemeAction = (data) => ({
  type: actionTypes.chartEditor.assignTheme,
  data
});

export const initEditorAction = (data) => ({
  type: actionTypes.chartEditor.initEditor,
  data
});

export const setTemplateAction = (data) => ({
  type: actionTypes.chartEditor.setTemplate,
  data
});

export const setMapAction = (data) => ({
  type: actionTypes.chartEditor.setMap,
  data
});

export const loadCSVAction = (data) => ({
  type: actionTypes.chartEditor.loadCSV,
  data
});

export const loadMapCSVAction = (data) => ({
  type: actionTypes.chartEditor.loadMapCSV,
  data
});

export const setCustomizedOptionsAction = (data) => ({
  type: actionTypes.chartEditor.setCustomizedOptions,
  data
});

export const addBlankSeriesAction = (data) => ({
  type: actionTypes.chartEditor.addBlankSeries,
  data
});

export const loadProjectAction = (data) => ({
  type: actionTypes.chartEditor.loadProject,
  data
});

export const deleteSeriesAction = (data) => ({
  type: actionTypes.chartEditor.deleteSeries,
  data
});

export const deleteSingleSeriesAction = (data) => ({
  type: actionTypes.chartEditor.deleteSingleSeries,
  data
});

export const loadGoogleSheetAction = (data) => ({
  type: actionTypes.chartEditor.loadGoogleSheet,
  data
});

export const loadLiveDataAction = (data) => ({
  type: actionTypes.chartEditor.loadLiveData,
  data
});

export const clearDataAction = (data) => ({
  type: actionTypes.chartEditor.clearData,
  data
});

export const updateCustomizedAction = (data) => ({
  type: actionTypes.chartEditor.updateCustomized,
  data
});

export const deleteCustomizedAction = (data) => ({
  type: actionTypes.chartEditor.deleteCustomized,
  data
});

export const updateDataLabelAction = (data) => ({
  type: actionTypes.chartEditor.updateDataLabel,
  data
});

export const updateDataRelatedPropAction = (data) => ({
  type: actionTypes.chartEditor.updateDataRelatedProp,
  data
});

export const updateCustomizedBulkAction = (data) => ({
  type: actionTypes.chartEditor.updateCustomizedBulk,
  data
});

export const loadChartAction = (data) => ({
  type: actionTypes.chartEditor.loadChart,
  data
});

export const setCustomCodeAction = (data) => ({
  type: actionTypes.chartEditor.setCustomCode,
  data
});

export const redrawProjectAction = (data) => ({
  type: actionTypes.chartEditor.redrawProject,
  data
});

export const resetStateAction = (data) => ({
  type: actionTypes.chartEditor.resetState,
  data
});

export const addAnnotationAction = (data) => ({
  type: actionTypes.chartEditor.addAnnotation,
  data
});

export const removeAnnotationAction = (data) => ({
  type: actionTypes.chartEditor.removeAnnotation,
  data
});

export const removeHighlightAction = (data) => ({
  type: actionTypes.chartEditor.removeHighlight,
  data
});

export const onChangeActiveAnnotationAction = (data) => ({
  type: actionTypes.chartEditor.onChangeActiveAnnotation,
  data
});

export const updateLabelAnnotationAction = (data) => ({
  type: actionTypes.chartEditor.updateLabelAnnotation,
  data
});

export const updateLineAnnotationAction = (data) => ({
  type: actionTypes.chartEditor.updateLineAnnotation,
  data
});

export const updateHighlightAnnotationAction = (data) => ({
  type: actionTypes.chartEditor.updateHighlightAnnotation,
  data
});

export const updateAnnotationsAction = (data) => ({
  type: actionTypes.chartEditor.updateAnnotations,
  data
});

export const showAnnotationInMenuAction = (data) => ({
  type: actionTypes.chartEditor.showAnnotationInMenu,
  data
});

export const addHighlightAction = (data) => ({
  type: actionTypes.chartEditor.addHighlight,
  data
});

export const useGeoJSONMapAction = (data) => ({
  type: actionTypes.chartEditor.useGeoJSONMap,
  data
});

export const showGeoJSONModalAction = (data) => ({
  type: actionTypes.chartEditor.showGeoJSONModal,
  data
});

export const hideGeoJSONModalAction = (data) => ({
  type: actionTypes.chartEditor.hideGeoJSONModal,
  data
});

export const setGeoJSONMapAction = (data) => ({
  type: actionTypes.chartEditor.setGeoJSONMap,
  data
});
export const closePublishModalAction = (data) => ({
  type: actionTypes.chartEditor.closePublishModal,
  data
});
export const openPublishModalAction = (data) => ({
  type: actionTypes.chartEditor.openPublishModal,
  data
});

export const initSettingsAction = (data) => ({
  type: actionTypes.chartEditor.initSettings,
  data
});

export const closeCMSModalAction = (data) => ({
  type: actionTypes.chartEditor.closeCMSModal,
  data
});

export const loadChartFromUUIDAction = (data) => ({
  type: actionTypes.chartEditor.loadChartFromUUID,
  data
});

export const changeProjectNameAction = (data) => ({
  type: actionTypes.projectConfig.changeProjectName,
  data
});

export const addItemArrayAction = (data) => ({
  type: actionTypes.chartEditor.addItemArray,
  data
});

export const deleteItemArrayAction = (data) => ({
  type: actionTypes.chartEditor.deleteItemArray,
  data
});

export const editDataGridAction = (data) => ({
  type: actionTypes.chartEditor.editDataGrid,
  data
});

export const addDataGridSeriesAction = (data) => ({
  type: actionTypes.chartEditor.addDataGridSeries,
  data
});

export const deleteDataGridSeriesAction = (data) => ({
  type: actionTypes.chartEditor.deleteDataGridSeries,
  data
});

export const sortDataGridAction = (data) => ({
  type: actionTypes.chartEditor.sortData,
  data
});
export const clearColumnAction = (data) => ({
  type: actionTypes.chartEditor.clearColumn,
  data
});

export const updateChartCategoriesAction = (data) => ({
  type: actionTypes.chartEditor.updateChartCategories,
  data
});

export const updateChartSeriesAction = (data) => ({
  type: actionTypes.chartEditor.updateChartSeries,
  data
});

export const addChartSeriesAction = (data) => ({
  type: actionTypes.chartEditor.addChartSeries,
  data
});

export const deleteChartSeriesAction = (data) => ({
  type: actionTypes.chartEditor.deleteChartSeries,
  data
});

export const transposeDataAction = (data) => ({
  type: actionTypes.chartEditor.transposeData,
  data
});

export const moveCellsAction = (data) => ({
  type: actionTypes.chartEditor.moveCells,
  data
});

export const remapAction = (data) => ({
  type: actionTypes.chartEditor.remap,
  data
});

export const updateBasedOnDataPropAction = (data) => ({
  type: actionTypes.chartEditor.updateBasedOnDataProp,
  data
});

export const adjustSeriesAssignsAction = (data) => ({
  type: actionTypes.chartEditor.adjustSeriesAssigns,
  data
});

export const deletePointAction = (data) => ({
  type: actionTypes.pointMap.deletePoint,
  data
});

export const addPointAction = (data) => ({
  type: actionTypes.pointMap.addPoint,
  data
});

export const searchApiAction = (data) => ({
  type: actionTypes.chartEditor.searchApi,
  data
});

export const linkExternalDataAction = (data) => ({
  type: actionTypes.chartEditor.linkExternalData,
  data
});
