import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import Modal from 'shared/modal/Modal';
import { deleteTeamUsersAction, setTeamMembersAction } from '../actions/teamMembersAction';
import { RootState } from 'redux/store';

const UsersDeleteModal = () => {
  const { team, divisions } = useSelector((state: RootState) => state.profile);
  const { members, subteamMembers, activeDivision, isUsersDeleteModalOpen, selectedUser, membersQuery } = useSelector(
    (state: RootState) => state.teamMembersPage
  );
  const dispatch = useDispatch();

  const isSubteam = !!activeDivision?.value;

  const handleOnConfirm = () => {
    dispatch(
      deleteTeamUsersAction({
        team,
        divisions,
        users: [selectedUser],
        activeDivisionId: activeDivision?.value,
        membersQuery,
        page: isSubteam ? subteamMembers.page : members.page,
        pageSize: isSubteam ? subteamMembers.pageSize : members.pageSize
      })
    );
  };

  const handleOnClose = () => {
    dispatch(setTeamMembersAction({ isUsersDeleteModalOpen: false }));

    setTimeout(() => {
      dispatch(setTeamMembersAction({ selectedUser: null }));
    }, 150);
  };

  return (
    <Modal
      title="Delete member"
      width="w-7/12 max-w-xl"
      bg="bg-ev-yellow-1"
      onClose={handleOnClose}
      isOpen={isUsersDeleteModalOpen}
    >
      <p className="mb-4">
        Are you sure you want to delete <b>{selectedUser?.username}</b> from <b>{team?.name}</b>?
      </p>
      <hr />
      <div className="mt-8 flex gap-4">
        <PrimaryButton onClick={handleOnConfirm} text="Confirm" />
        <PrimaryButton onClick={handleOnClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
      </div>
    </Modal>
  );
};

export default UsersDeleteModal;
