import classNames from 'classnames';
import React from 'react';

type OptionsPanelContainerProps = {
  children: React.ReactNode;
  isAdvanced?: boolean;
};

export default function OptionsPanelContainer(props: OptionsPanelContainerProps) {
  const classes = classNames('flex-1 ev-sm:w-1/4 bg-white z-[1] relative left-panel transition-all', {
    'min-w-full max-w-full w-full ev-sm:max-w-[388px] ev-sm:min-w-[388px]': !props.isAdvanced,
    'max-w-[600px] min-w-[600px]': props.isAdvanced
  });
  return <div className={classes}>{props.children}</div>;
}
