export default {
  teamSettings: {
    name: '',
    charts_private_by_default: false,
    private: false
  },
  saving: false,
  generatingLicense: false,
  cmsLicenses: [],
  deleting: false,
  sending: false,
  showDeleteModal: false,
  showNewTeamModal: false,
  showDeleteError: '',
  form: {
    teamName: {
      value: '',
      error: false
    }
  },
  error: ''
};
