import { connect } from 'react-redux';
import { checkPermission } from '../../../utils/profileHelper';
import { setAction } from '../actions/projectsPage.js';
import Charts from '../ProjectsPage';

const mapStateToProps = (state) => {
  return {
    charts: state.projectsPage.charts || [],
    paths: state.app.paths || {},
    team: state.profile.team,
    page: state.projectsPage.page,
    division: state.profile.division,
    pageCount: state.projectsPage.pageCount,
    dataReturned: state.projectsPage.dataReturned,
    isWizardActive: state.projectsPage.isWizardActive,
    isFeedbackFormActive: state.projectsPage.isFeedbackFormActive,
    viewChartsPerm: checkPermission('view_charts', state.profile),
    checkPermission: (permission) => checkPermission(permission, state.profile)
  };
};

const mapDispatchToProps = (dispatch) => ({
  set: (params) => dispatch(setAction(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Charts);
