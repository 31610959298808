import { TemplateTypeProps } from '../../types';
import Bubble from './Bubble';

const BubbleTemplate: TemplateTypeProps = {
  description: '',
  icon: '',
  sampleData: [],
  templates: {
    'Bubble chart': Bubble
  }
};

export default BubbleTemplate;
