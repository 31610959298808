import React from 'react';
import ChartConfig from '../meta/config/ChartConfig';
import BarChartConfig from '../meta/config/BarChartConfig';
import LineChartConfig from '../meta/config/LineChartConfig';
import ColumnChartConfig from '../meta/config/ColumnChartConfig';
import { stripHtml } from '../../../utils/helper';
import RichTextEditor from './RichTextEditor';
import DOMPurify from 'dompurify';
import { isNull, isObj, merge } from 'editor/core/highcharts-editor';

class TableRow extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.draggedOver = this.draggedOver.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDropped = this.onDropped.bind(this);
    this.renderChart = this.renderChart.bind(this);
    this.draggedLeave = this.draggedLeave.bind(this);
    this.resetKey = this.resetKey.bind(this);
    this.setInDragMode = this.setInDragMode.bind(this);
    this.setOutDragMode = this.setOutDragMode.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.loadCharts = this.loadCharts.bind(this);

    this.state = {
      operations: {
        '=': (value, condition) => {
          return stripHtml(value) == condition;
        },
        '>': (value, condition) => {
          value = String(value).replace(/,| /g, '');
          return !isNaN(condition) ? stripHtml(value) > parseFloat(condition) : value > condition;
        },
        '<': (value, condition) => {
          value = String(value).replace(/,| /g, '');
          return !isNaN(condition) ? stripHtml(value) < parseFloat(condition) : value < condition;
        },
        like: (value, condition) => {
          return stripHtml(value).indexOf(condition) > -1;
        }
      },
      chartTypes: {
        stacked: BarChartConfig,
        column: ColumnChartConfig,
        bar: ChartConfig,
        area: LineChartConfig,
        line: LineChartConfig
      }
    };
  }

  componentDidMount() {
    this.loadCharts();
  }

  componentDidUpdate() {
    this.loadCharts();
  }

  loadCharts() {
    if (this.props.headers) {
      if (this.props.row) {
        this.props.row.map((column, x) => {
          if (Array.isArray(column)) {
            this.renderChart(
              'table_row_' + this.props.rowIndex + '_col_' + x,
              this.props.aggregatedOptions.columns[x],
              column,
              (this.props.aggregatedOptions.columns[x].columns || []).map((i) => this.props.headers[i])
            );
          }
        });
      }
    }
  }

  draggedOver(e) {
    if (this.props.isGSheet) return;
    e.preventDefault();
    const index = parseInt(e.target.getAttribute('data-row')) - 1;
    if (this.props.draggedOver !== index) {
      this.props.updateDraggedOver(index);
    }
  }

  onDragStart(e) {
    if (this.props.isGSheet) return;
    e.dataTransfer.setData('element', e.currentTarget.getAttribute('data-row'));
  }

  onDropped(e) {
    this.props.setTableAction({
      isInDragMode: false,
      activeCellRow: null,
      activeCellColumn: null,
      activeBufferCellColumn: null
    });

    if (this.props.isGSheet) return;
    e.preventDefault();
    var data = e.dataTransfer.getData('element');
    this.props.changeRowOrder(parseInt(data) + 1, parseInt(e.currentTarget.getAttribute('data-row')) + 1);
  }

  draggedLeave() {
    if (this.props.isGSheet) return;
    this.props.updateDraggedOver(null);
  }

  renderChart(id, columnData, data, headings) {
    setTimeout(() => {
      new Highcharts.SparkLine(
        id,
        this.state.chartTypes[columnData.chartType](
          columnData.chartType,
          data,
          columnData,
          headings,
          this.props.columnsLength <= 4
        )
      );
    }, 100);
  }

  resetKey() {
    this.keySet = null;
  }

  setInDragMode() {
    this.props.setTableAction({
      isInDragMode: true
    });
  }

  setOutDragMode() {
    this.props.setTableAction({
      isInDragMode: false
    });
  }

  handleKeyDown(e) {
    this.props.changeDirection(e, (e) => {
      this.props.cellOnDblClick(e);
      if (e.which !== 0 && !e.ctrlKey && !e.metaKey && !e.altKey) {
        this.keySet = String.fromCharCode(e.which);
      }
    });
  }

  render() {
    const {
      rowStyle,
      row,
      rowIndex,
      aggregatedOptions,
      classToUse,
      headers,
      isGSheet,
      columnsLength,
      activeCellRow,
      activeCellColumn,
      lowCell,
      highCell,
      headerRows,
      page,
      resultsPerPage,
      searchDataIndex
    } = this.props;
    const { operations } = this.state;

    let rowCount = this.props.rowIndex + headerRows;
    if (resultsPerPage) rowCount = rowIndex + page * resultsPerPage + headerRows;
    let richTextEditorRow = searchDataIndex?.length > 0 ? rowCount - 1 : rowCount;

    return (
      <tr
        key={'row_' + rowIndex}
        data-row={rowIndex}
        style={rowIndex % 2 ? rowStyle : null}
        className={classToUse}
        draggable={!isGSheet && this.props.isInDragMode}
        onDragStart={this.onDragStart}
        onDragOver={this.draggedOver}
        onDragLeave={this.draggedLeave}
        onDrop={this.onDropped}
      >
        {row.map((column, x) => {
          if (Array.isArray(column)) {
            return (
              <td
                key={'column_chart_row_' + rowIndex + '_column_' + x}
                data-row={rowCount}
                style={rowIndex % 2 ? rowStyle : null}
                className={'everviz-table-chart-cell' + (columnsLength >= 4 ? ' responsive' : '')}
                data-column={x}
              >
                {x === 0 && (
                  <div
                    className={'table-move-handle ' + this.props.isInDragMode}
                    onMouseDown={this.setInDragMode}
                    onMouseUp={this.setOutDragMode}
                  >
                    <div className={'option '}>
                      <span className="fa fa-ellipsis-h" />
                    </div>
                  </div>
                )}
                <div
                  className="mobile-text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize('<b>' + aggregatedOptions.columns[x].columnName + ': </b>')
                  }}
                ></div>
                <div className="sparkline-chart" id={'table_row_' + rowIndex + '_col_' + x}></div>
              </td>
            );
          }
          let style = {},
            cellValue = column,
            colSpan = 1,
            rowSpan = 1;

          if (isObj(column)) {
            if (column.mergedCell)
              return <React.Fragment key={'mergedcell_' + rowIndex + '_column_' + x}></React.Fragment>;

            cellValue = column.value;
            colSpan = column.colSpan || 1;
            rowSpan = column.rowSpan || 1;
          }

          if (isNull(cellValue)) cellValue = '';

          if (aggregatedOptions.columns && aggregatedOptions.columns[x]) {
            if ('style' in aggregatedOptions.columns[x]) {
              style = merge({}, aggregatedOptions.columns[x].style);
            }
            if (
              'rule' in aggregatedOptions.columns[x] &&
              operations[aggregatedOptions.columns[x].rule](cellValue, aggregatedOptions.columns[x].value)
            ) {
              style.color = aggregatedOptions.columns[x].color;
            }
          }

          if (column && column.style) style = merge(style, column.style);
          const isActive = activeCellColumn === x && activeCellRow === rowCount;

          return (
            <td
              key={'column_' + x}
              data-row={rowCount}
              style={style}
              colSpan={colSpan}
              rowSpan={rowSpan}
              onDoubleClick={this.props.cellOnDblClick}
              onMouseOver={this.props.onMouseOverCell}
              onContextMenu={this.props.showContextMenu}
              onMouseDown={this.props.onMouseDownCell}
              className={
                (aggregatedOptions.columns[x] && aggregatedOptions.columns[x].visible === false ? 'hidden ' : ' ') +
                (column && column.link && column.link.url ? ' is-link ' : ' ') +
                (lowCell && x >= lowCell[0] && x <= highCell[0] && rowCount >= lowCell[1] && rowCount <= highCell[1]
                  ? ' is-selected '
                  : '')
              }
              data-column={x}
            >
              {x === 0 && (
                <div
                  className={'table-move-handle ' + this.props.isInDragMode}
                  onMouseDown={this.setInDragMode}
                  onMouseUp={this.setOutDragMode}
                >
                  <div className={'option '}>
                    <span className="fa fa-ellipsis-h" />
                  </div>
                </div>
              )}
              {!isActive && (
                <div
                  className="input"
                  data-row={rowCount}
                  onKeyDown={this.handleKeyDown}
                  disabled={isGSheet}
                  onFocus={this.props.onFocus}
                  tabIndex="0"
                  data-column={x}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cellValue.toString()) }}
                />
              )}
              {isActive && (
                <RichTextEditor
                  row={richTextEditorRow}
                  column={x}
                  text={cellValue.toString()}
                  handleCEUpdate={this.props.handleCEUpdate}
                  setTableAction={this.props.setTableAction}
                  keySet={this.keySet || ''}
                  resetKey={this.resetKey}
                  bufferDataOptions={this.props.bufferDataOptions}
                  activeCellColumn={activeCellColumn}
                  activeCellRow={activeCellRow}
                  cellStyle={style}
                  finishAndGoDown={this.props.finishAndGoDown}
                />
              )}
              <div
                className="mobile-text"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize('<b>' + (headers[x] || '') + ': </b>' + (cellValue || '').toString())
                }}
              ></div>
            </td>
          );
        })}
      </tr>
    );
  }
}

TableRow.propTypes = {};

export default TableRow;
