import store from 'redux/store';
import { merge } from 'editor/core/highcharts-editor';
import { cloneDeep, get, isFunction } from 'lodash';
import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanById } from 'pages/SubscriptionPage/utils/plansHelper';
import { getFilteredValue } from 'shared/widgets/utils/widgetHelper';
import LocationMapCustomizeOptions from './../meta/locationMap/CustomizeOptions';
import CustomizeOptions from './../meta/CustomizeOptions';
import { getChart } from '../meta/highchartsHelper';

const customizeOptionsMap = {
  locationMap: () => LocationMapCustomizeOptions,
  highcharts: (CustomizeOptions, chartEditorPage, projectConfig, layoutEditorPage) =>
    parseCustomizeOptions(CustomizeOptions, chartEditorPage, projectConfig, layoutEditorPage)
};

const getFilterValue = (properties, aggregatedOptions, chart) => {
  const val = getFilteredValue(properties, chart, 0);
  if (properties.filteredBy && isFunction(properties.filteredBy)) {
    return val;
  }
  return get(aggregatedOptions, val);
};

export const parseCustomizeOptions = (CustomizeOptions, chartEditorPage, projectConfig, layoutEditorPage) => {
  const { editorConfig, isMap, editorChartIndex } = chartEditorPage;
  const chart = getChart(editorChartIndex);
  let { templateMeta, themeOptions, customizedOptions, aggregatedOptions, type } = projectConfig;
  const { templateUsed } = layoutEditorPage;

  let customizeOptions = cloneDeep(CustomizeOptions);
  if (type !== 'chart') {
    aggregatedOptions = merge(cloneDeep(themeOptions.options), cloneDeep(customizedOptions));
  }

  const aggregated =
    type === 'chart' && (chart?.options || chart?.usersOptions)
      ? cloneDeep(chart.options ? chart.options : chart.usersOptions)
      : cloneDeep(aggregatedOptions ?? {});

  let projectType = type;
  if (isMap && type === 'chart') {
    projectType = 'map';
  }

  Object.keys(customizeOptions || {}).forEach((key) => {
    const option = (customizeOptions || {})[key];
    const disabled = option.every(
      (opt) => (opt.mapDisabled && isMap) || (opt.projectType && !opt.projectType.includes(projectType))
    );
    if (disabled) {
      delete (customizeOptions || {})[key];
      return;
    }

    if (option.relevantTo && option.relevantTo.indexOf(templateUsed) === -1) {
      delete (customizeOptions || {})[key];
      return;
    }

    (customizeOptions || {})[key] = option.filter((section) => {
      const isDisabled =
        (isMap && section.mapDisabled) || (section.projectType && !section.projectType.includes(projectType));

      if (isDisabled) return false;

      section.options = section.options.filter((properties) => {
        if (
          (properties.mapDisabled && isMap) ||
          (properties.projectType && !properties.projectType.includes(projectType))
        ) {
          return false;
        }

        if (properties.hidden) return false;

        if (
          type === 'chart' &&
          properties.id &&
          editorConfig.customizer &&
          !editorConfig.customizer.availableSettings.includes(properties.id) &&
          properties.type !== 'separator'
        ) {
          return false;
        }

        if (properties.blacklist) {
          let canRender = false;

          if (isFunction(properties.blacklist)) canRender = properties.blacklist(aggregated);
          else canRender = !properties.blacklist.includes(getFilterValue(properties, aggregated, chart));
          if (!canRender) return false;
        }

        if (properties.subType) {
          let canRender = false;
          canRender = properties.subType.includes(getFilterValue(properties, aggregated, chart));
          if (!canRender) return false;
        }

        if (isMap && properties.templateType && !properties.templateType?.includes(templateMeta?.[0]?.templateTitle)) {
          return false;
        }

        if (properties.layoutType && properties.layoutType.indexOf(templateUsed) === -1) {
          return false;
        }

        return true;
      });

      return section.options.length > 0;
    });
    if (!(customizeOptions || {})[key].length) delete (customizeOptions || {})[key];
  });

  return customizeOptions;
};

export const getCustomizeOptions = (provider, chartEditorPage, projectConfig, layoutEditorPage) => {
  const typedOptions = customizeOptionsMap[provider];
  return typedOptions(CustomizeOptions, chartEditorPage, projectConfig, layoutEditorPage);
};

export const isTrialPlan = () => {
  const profile = store.getState().profile;
  const plan = getPlanById(profile?.subscription?.plan_id);
  return [SubscriptionPlans.ProTrial, SubscriptionPlans.Free].includes(plan);
};
