import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Accordion from 'shared/Accordion';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import TextButton from 'shared/buttons/TextButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { SelectItem, customSelectColors } from 'shared/inputs/CustomSelect';
import { SeriesAssign, SeriesAssigns } from 'shared/types/commonPropTypes';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import BinIcon from 'static/icons/bin.svg';
import { deleteSeriesTypes, getMappedValues } from '../utils/seriesHelper';
import SeriesOption from './SeriesOption';

type SeriesTabProps = {
  setActiveColumns: (indexes: number[], series: number[]) => void;
  options: SelectItem[];
  index: number;
  assignOptions: SeriesAssigns;
  letter: string;
  moreThanOneSeries: boolean;
  activeTab: number;
  setActiveTab: (tabIndex: number) => void;
  onMouseLeave: () => void;
  type: string;
  seriesTypes: string[];
};

const SeriesTab = (props: SeriesTabProps) => {
  const { options, index, assignOptions, moreThanOneSeries } = props;
  const { isMap } = useSelector((state: RootState) => state.chartEditorPage);
  const { showWizard } = useSelector((state: RootState) => state.projectConfig);
  const dispatch = useDispatch();

  const deleteSeries = useCallback(() => dispatch(deleteSeriesTypes[props.type]({ index })), [assignOptions]);
  const inChartEditor = !showWizard && !isMap;
  const advancedCriteria = showWizard && !assignOptions.values && !assignOptions.value && moreThanOneSeries;
  const isAdvanced = (inChartEditor && moreThanOneSeries) || advancedCriteria;

  const generateSection = (labelSelectStyle: Record<string, unknown>, backgroundColor: string) => {
    if (assignOptions.hidden) return <></>;
    return (
      <>
        {Object.entries(assignOptions).map(([key, value], i) => {
          return (
            <SeriesOption
              key={`series_${i}`}
              assignOption={value as SeriesAssign}
              index={index}
              options={options}
              moreThanOneSeries={moreThanOneSeries}
              labelSelectStyle={labelSelectStyle}
              backgroundColor={backgroundColor}
              deleteSeries={deleteSeries}
              setActiveColumns={props.setActiveColumns}
              onMouseLeave={props.onMouseLeave}
              optionKey={key}
              type={props.type}
            />
          );
        })}
      </>
    );
  };

  const setActiveTab = () => props.setActiveTab(props.activeTab === index ? -1 : index);

  if (isAdvanced) {
    const { columns, series } = getMappedValues(assignOptions as Record<string, SeriesAssign>, isAdvanced);

    return (
      <Accordion
        headerText={`Series ${index + 1}`}
        childrenClasses="relative flex flex-col py-2 px-2 gap-1"
        selectedChildren={`Columns ${columns.join(',')}`}
        onMouseEnter={() => props.setActiveColumns(series, [index])}
        onMouseLeave={props.onMouseLeave}
        accordionClasses="w-full bg-ev-grey"
        selected={props.activeTab === index}
        onClick={setActiveTab}
      >
        {generateSection(editorStyles, customSelectColors.white)}
        <div className="flex items-center text-right ml-auto mt-2">
          <TextButton text="Remove this series" onClick={deleteSeries} className="text-sm" />
          <SvgIconButton
            width={16}
            height={16}
            onClick={deleteSeries}
            buttonClasses="p-1 rounded ml-2"
            buttonColor={ButtonColor.Grey}
            Icon={BinIcon}
          />
        </div>
      </Accordion>
    );
  }
  return generateSection(editorStyles, '#F7F8F8');
};

export default SeriesTab;
