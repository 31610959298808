import actionTypes from '../../../redux/actions/action-types';
import initialState from '../../../redux/reducers/initial-state';

const ShowChartPagePageReducer = (state = initialState.ShowChartPage, action) => {
  switch (action.type) {
    case actionTypes.showChartPage.set:
      return {
        ...state,
        ...action.data
      };
  }
  return state;
};

export default ShowChartPagePageReducer;
