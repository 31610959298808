import actionTypes from '../../../redux/actions/action-types';

export const setAction = (data) => ({
  type: actionTypes.companyThemeEditor.set,
  data
});

export const changeTypeAction = (data) => ({
  type: actionTypes.companyThemeEditor.changeType,
  data
});

export const saveCompanyThemeAction = (data) => ({
  type: actionTypes.companyThemeEditor.saveCompanyTheme,
  data
});

export const loadCompanyThemeAction = (data) => ({
  type: actionTypes.companyThemeEditor.loadCompanyTheme,
  data
});

export const resetThemePageSettingsAction = (data) => ({
  type: actionTypes.companyThemeEditor.resetThemePageSettings,
  data
});

export const switchProjectTypeAction = (data) => ({
  type: actionTypes.companyThemeEditor.switchProjectType,
  data
});

export const initCompanyThemeEditorAction = (data) => ({
  type: actionTypes.companyThemeEditor.initCompanyThemeEditor,
  data
});

export const updateThemeConfigAction = (data) => ({
  type: actionTypes.companyThemeEditor.updateThemeConfig,
  data
});
