import { connect } from 'react-redux';
import { closeWizardAction } from '../../../redux/actions/projectConfig';
import { getColumnsLength } from '../../../redux/selectors/tableRow.js';
import { checkPermission } from '../../../utils/profileHelper';
import {
  changeTitleTextAction,
  closePublishModalAction,
  openPublishModalAction,
  setAction
} from '../../LayoutEditorPage/actions/layoutEditor.js';
import {
  changeRowOrderAction,
  loadTableAction,
  setAction as setTableAction,
  updateChartHeaderAction,
  updateDataAction
} from '../actions/tableEditor.js';
import TableRow from '../components/TableRow';

const mapStateToProps = (state) => {
  return {
    charts: state.projectsPage.charts || [],
    team: state.profile.team,
    division: state.profile.division,
    paths: state.app.paths || {},
    showPublishPopup: state.layoutEditorPage.showPublishPopup,
    showWizard: state.projectConfig.showWizard,
    resolution: state.layoutEditorPage.resolution,
    dataOptions: state.projectConfig.dataOptions,
    bufferDataOptions: state.tableEditorPage.bufferDataOptions,
    shouldUpdateTableComponent: state.tableEditorPage.shouldUpdateTableComponent,
    rowSettings: state.tableEditorPage.rowSettings,
    columnSettings: state.tableEditorPage.columnSettings,
    page: state.tableEditorPage.page,
    templateOptions: state.projectConfig.templateOptions,
    customizedOptions: state.projectConfig.customizedOptions,
    themeOptions: state.projectConfig.themeOptions,
    settingsStyle: state.tableEditorPage.settingsStyle,
    isGSheet: state.tableEditorPage.isGSheet,
    checkPermission: (permission) => checkPermission(permission, state.profile),
    activeCellColumn: state.tableEditorPage.activeCellColumn,
    activeCellRow: state.tableEditorPage.activeCellRow,
    activeBufferCell: state.tableEditorPage.activeBufferCell,
    activeSelectedBufferCell: state.tableEditorPage.activeSelectedBufferCell,
    isInDragMode: state.tableEditorPage.isInDragMode,
    inSelectMode: state.tableEditorPage.inSelectMode,
    columnsLength: getColumnsLength(state.tableEditorPage.bufferDataOptions)
  };
};

const mapDispatchToProps = (dispatch) => ({
  set: (params) => dispatch(setAction(params)),
  setTableAction: (params) => dispatch(setTableAction(params)),
  updateData: (dataOptions) => dispatch(updateDataAction({ dataOptions })),
  closeWizard: (params) => dispatch(closeWizardAction(params)),
  loadTable: (team, storyId, cb) => dispatch(loadTableAction({ team, storyId, cb })),
  toggleChartModal: (parent) => dispatch(toggleChartModalAction(parent)),
  addChartToGrid: (chart, parentToAttachChartTo, dataOptions) =>
    dispatch(addChartToGridAction({ chart, parentToAttachChartTo, dataOptions })),
  addImageToGrid: (image, parentToAttachChartTo, dataOptions) =>
    dispatch(addImageToGridAction({ image, parentToAttachChartTo, dataOptions })),
  changeTitleText: (event, customizedOptions, type) =>
    dispatch(changeTitleTextAction({ event, customizedOptions, type })),
  openPublishModal: () => dispatch(openPublishModalAction({})),
  closePublishModal: () => dispatch(closePublishModalAction({})),
  changeRowOrder: (dataOptions, from, to) => dispatch(changeRowOrderAction({ dataOptions, from, to })),
  updateChartHeader: (customizedOptions, index, value) =>
    dispatch(updateChartHeaderAction({ customizedOptions, index, value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TableRow);
