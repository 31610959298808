/* eslint-disable @typescript-eslint/no-empty-function */
import { defaultFonts } from 'highed';
import { get, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCSSModulesAction } from 'redux/actions/projectConfig';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import { loadCSS } from '../../pages/LayoutEditorPage/utils/storyHelper';
import SelectWidget from './SelectWidget';

export default function FunctionPickerWidget(props) {
  const dispatch = useDispatch();
  const { aggregatedOptions, option } = props;
  const { customFonts } = useSelector((state) => state.layoutEditorPage);

  const [fonts, setFonts] = useState([
    { label: 'Default', value: 'Default' },
    ...customFonts.map((e) => {
      return { label: e.name, value: e.uuid };
    }),
    ...defaultFonts.map((e) => {
      return { label: e, value: e };
    })
  ]);

  useEffect(() => {
    setFonts([
      { label: 'Default', value: 'Default' },
      ...customFonts.map((e) => {
        return { label: e.name, value: e.uuid };
      }),
      ...defaultFonts.map((e) => {
        return { label: e, value: e };
      })
    ]);
  }, [customFonts]);

  const onFontFamilyChange = (event) => {
    let { option } = props;
    let { val } = event;
    const found = (customFonts || []).find((font) => font.uuid === val);

    if (found) {
      loadCSS(window.hcconfig.frontend.hostname + '/fonts/' + found.uuid + '/font.css')
        .then(() => {
          setTimeout(() => {
            props.onChange({
              val: found.font_name,
              extraModulesToLoad: window.hcconfig.frontend.hostname + '/fonts/' + found.uuid + '/font.css',
              optional: {
                ...option,
                id: `${option.id}.fontFamily`,
                name: `${option.name}.fontFamily` // Used in advanced section (AdvanceCustomize.tsx)
              }
            });

            dispatch(
              updateCSSModulesAction({
                newModule: window.hcconfig.frontend.hostname + '/fonts/' + found.uuid + '/font.css'
              })
            );
          }, 500);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      if (val === 'Default') val = '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif';
      props.onChange({
        val,
        optional: {
          ...option,
          id: `${option.id}.fontFamily`,
          name: `${option.name}.fontFamily`
        }
      });
    }
  };

  const extractValue = (aggregatedOptions, defaultVal, property) => {
    const { option } = props;
    if (option.nestedValue) {
      let val = get(aggregatedOptions, `${option.id}.${property}`);
      if (isNil(val)) {
        // eslint-disable-next-line array-callback-return
        option.nestedValue.some((nestedValue) => {
          val = get(aggregatedOptions, nestedValue);
          if (!isNil(val)) return true;
        });
        return val ?? defaultVal;
      }
    }
    return get(aggregatedOptions, `${option.id}.${property}`) ?? defaultVal;
  };

  const fontFamily = extractValue(aggregatedOptions, 'Default', 'fontFamily');

  const customOption = (customFonts || []).find((font) => font.font_name === fontFamily);
  const fontOption = (fonts || []).find((c) => {
    if (customOption) return c.label === customOption.name;
    return c.label === fontFamily;
  });

  return (
    <div className={'justify-between' + (option.custom?.simple ? ' simple w-full' : '')} id={props.option?.id}>
      <SelectWidget
        selectOptions={fonts}
        onChange={onFontFamilyChange}
        value={fontOption}
        label={props.label}
        customStyles={props.customStyles ? props.customStyles : editorStyles}
        backgroundColor={props.backgroundColor ?? props.option?.backgroundColor}
      />
    </div>
  );
}
