import React from 'react';
import Chart from './DraggableComponent';
import Buttons from '../containers/Buttons';
import { getTemplateOptions } from '../meta/config/templates';
import PropTypes from 'prop-types';

class Slider extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  componentDidMount() {
    EvervizSlider('slider');
  }

  render() {
    const { dataOptions, templateOptions, templateUsed, aggregatedOptions } = this.props;
    const setting = getTemplateOptions(templateUsed);
    const style = (aggregatedOptions && aggregatedOptions.layout && aggregatedOptions.layout.style) || {};
    const first = dataOptions[0];
    const second = dataOptions[1];

    return (
      <div id="slider" className="section ba-slider slider-container">
        <div className="resizable-element">
          {second && second.chart_id && (
            <Chart
              type={'img'}
              onRemove={() => {
                this.props.onRemove(1);
              }}
              option={{ ...second }}
              id={'2'}
              aggregatedOptions={aggregatedOptions}
            />
          )}

          {second && second.image && (
            <span>
              <img src={second.image} />
              <div className={'context-menu'}>
                <ul>
                  <li
                    title={'Remove'}
                    onClick={() => {
                      this.props.onRemove(1);
                    }}
                  >
                    {' '}
                    <i className={'fa fa-trash'} />{' '}
                  </li>
                </ul>
              </div>
            </span>
          )}
        </div>

        <div className={'resize ' + (first && first.image ? '' : 'no-image')}>
          <div className="resizable-element">
            {first && first.chart_id && (
              <Chart
                type={'img'}
                buttonPos={'left'}
                onRemove={() => {
                  this.props.onRemove(0);
                }}
                option={{ ...first }}
                id={'1'}
                aggregatedOptions={aggregatedOptions}
              />
            )}
            {first && first.image && (
              <span>
                <img src={first.image} />
                <div className={'context-menu left'}>
                  <ul>
                    <li
                      title={'Remove'}
                      onClick={() => {
                        this.props.onRemove(0);
                      }}
                    >
                      {' '}
                      <i className={'fa fa-trash'} />{' '}
                    </li>
                  </ul>
                </div>
              </span>
            )}
          </div>
        </div>

        <span className="handle" style={style} />

        {((!first.chart_id && !first.image) || (!second.chart_id && !second.image)) && (
          <div className="slider-buttons-container">
            {!first ||
              (!first.chart_id && !first.image && <Buttons section={first} sectionIndex={0} extraClass="first" />)}
            {!second ||
              (!second.chart_id && !second.image && (
                <Buttons section={second} sectionIndex={1} extraClass={'second'} />
              ))}
          </div>
        )}
      </div>
    );
  }
}

Slider.propTypes = {
  options: PropTypes.array,
  section: PropTypes.number,
};

export default Slider;
