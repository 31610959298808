import { TemplateProps } from '../../types';

const Line: TemplateProps = {
  title: 'Line',
  description: '',
  constructor: '',
  thumbnail: 'abywon.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'line'
    },
    yAxis: {
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'line',
  advancedParent: 'Line',
  basic: {
    order: 1,
    thumbnail: 'line_graph.svg',
    name: 'Line',
    icon: 'Line'
  },
  advanced: {
    name: 'Standard'
  },
  dataValidator: false,
  parent: 'Line'
};

export default Line;
