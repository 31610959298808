import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../../shared/message/ErrorMessage';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import SelectWidget from 'shared/widgets/SelectWidget';
import InputWidget from 'shared/widgets/InputWidget';

const TeamWebhooksForm = ({ onSave, onClose, onFormChange, form, types }) => {
  const value = types.find((element) => {
    return element.value === form.type;
  });

  return (
    <div>
      {form.error && <ErrorMessage error={form.error} dismissable={true} />}
      <div className="flex flex-col gap-2">
        <InputWidget label="Title:" onChange={(value) => onFormChange(value, 'title')} value={form.title} />
        <InputWidget label="URL:" onChange={(value) => onFormChange(value, 'url')} value={form.url} />
        <SelectWidget
          selectOptions={types}
          label={'Trigger:'}
          onChange={(value) => onFormChange(value, 'type')}
          value={value}
        />
      </div>
      <hr />
      <div className="pt-6 flex gap-2">
        <PrimaryButton onClick={onSave} text="Save" />
        <PrimaryButton onClick={onClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
      </div>
    </div>
  );
};

TeamWebhooksForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired
};

export default TeamWebhooksForm;
