import { TemplateProps } from '../../types';

const SemiCircleDonut: TemplateProps = {
  title: 'Semi circle donut',
  description: '',
  constructor: '',
  thumbnail: 'iwyfes.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'pie',
      polar: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        dataLabels: {
          distance: -30,
          style: {
            fontWeight: 'bold',
            color: 'white',
            textShadow: '0px 1px 2px black'
          }
        },
        innerSize: '50%',
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '75%']
      },
      series: {
        dataLabels: {
          enabled: true
        }
      }
    }
  },
  sampleSet: 'pie',
  dataValidator: false,
  advancedParent: 'Donut',
  advanced: {
    name: 'Semicircle'
  }
};

export default SemiCircleDonut;
