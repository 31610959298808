import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Treemap: TemplateProps = {
  title: 'Tree map',
  description: '',
  constructor: '',
  thumbnail: 'wordcloud.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'treemap'
    },
    plotOptions: {
      series: {
        colorByPoint: true,
        levels: [
          {
            level: 1,
            layoutAlgorithm: 'sliceAndDice'
          }
        ],
        dataLabels: {
          enabled: true,
          align: 'left',
          format: '{point.name}',
          verticalAlign: 'top',
          style: {
            fontSize: '15px',
            fontWeight: 'bold'
          }
        }
      }
    },
    legend: {
      enabled: false
    }
  },
  plugins: ['treemap'],
  advancedParent: 'Treemap',
  sampleSet: 'wordcloud',
  dataValidator: false,
  basic: {
    order: 10,
    thumbnail: 'word_cloud.svg',
    icon: 'Treemap'
  },
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter)
};

export default Treemap;
