import { TemplateTypeProps } from '../../types';
import ErrorBar from './ErrorBar';
import Inverted from './Inverted';
import Line from './Line';
import LineWithDataLabels from './LineWithDataLabels';
import LogarithmicLine from './LogarithmicLine';
import NegativeColor from './NegativeColor';
import Spline from './Spline';
import SplineWithLabels from './SplineWithLabels';
import StepLine from './StepLine';
import StepLineWithLabels from './StepLineWithLabels';

const LineTemplate: TemplateTypeProps = {
  templates: {
    Line,
    'Error bar': ErrorBar,
    Inverted,
    'Logarithmic line': LogarithmicLine,
    'Negative color': NegativeColor,
    Spline,
    'Spline w/ labels': SplineWithLabels,
    'Step line': StepLine,
    'Step line with labels': StepLineWithLabels,
    'With data labels': LineWithDataLabels
  },
  description: [
    'A line chart is a type of chart which diplays information as a series of data points called "markers" connected by straight line segments'
  ],
  samples: ['os-stats', 'line-series-simple', 'line-series-four-series']
};

export default LineTemplate;
