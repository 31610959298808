export default {
  series: 1,
  data: 'Category;Sales per employee (y);Sales per employee (value)\nAlexander;Monday;10\nAlexander;Tuesday;19\nAlexander;Wednesday;8\nAlexander;Thursday;24\nAlexander;Friday;67\nMarie;Monday;92\nMarie;Tuesday;58\nMarie;Wednesday;78\nMarie;Thursday;117\nMarie;Friday;48\nMaximilian;Monday;35\nMaximilian;Tuesday;15\nMaximilian;Wednesday;123\nMaximilian;Thursday;64\nMaximilian;Friday;52\nSophia;Monday;72\nSophia;Tuesday;132\nSophia;Wednesday;114\nSophia;Thursday;19\nSophia;Friday;16\nLukas;Monday;38\nLukas;Tuesday;5\nLukas;Wednesday;8\nLukas;Thursday;117\nLukas;Friday;115\nMaria;Monday;88\nMaria;Tuesday;32\nMaria;Wednesday;12\nMaria;Thursday;6\nMaria;Friday;120\nLeon;Monday;13\nLeon;Tuesday;44\nLeon;Wednesday;88\nLeon;Thursday;98\nLeon;Friday;96\nAnna;Monday;31\nAnna;Tuesday;1\nAnna;Wednesday;82\nAnna;Thursday;32\nAnna;Friday;30\nTim;Monday;85\nTim;Tuesday;97\nTim;Wednesday;123\nTim;Thursday;64\nTim;Friday;84\nLaura;Monday;47\nLaura;Tuesday;114\nLaura;Wednesday;31\nLaura;Thursday;48\nLaura;Friday;91',
  seriesMapping: [
    {
      xLabel: 0,
      yLabel: 1,
      x: 2,
      y: 3,
      value: 4,
    },
  ],
  assignDataFields: [
    {
      xLabel: 'A',
      yLabel: 'B',
      x: 'A',
      y: 'B',
      value: 'C',
    },
  ],
};
