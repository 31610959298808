import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUnpublishModalAction, unpublishAction } from 'redux/actions/projectConfig';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import PrimaryButton from 'shared/buttons//PrimaryButton.tsx';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import Modal from 'shared/modal/Modal';

export default function UnpublishModal() {
  const dispatch = useDispatch();
  const { showUnpublishModal } = useSelector(getProjectConfig);

  const toggleModal = () => dispatch(toggleUnpublishModalAction(false));
  const unpublish = () => {
    dispatch(unpublishAction({}));
    toggleModal();
  };

  return (
    <Modal
      isOpen={showUnpublishModal}
      onClose={toggleModal}
      title={'Unpublish'}
      width={'w-4/12 max-w-xl'}
      bg={'bg-ev-yellow-1'}
    >
      <p className="font-medium pb-6 m-0">
        Are you sure you want to unpublish? <br />
        All embed codes and links to this project will stop working. You can make them work again by republishing the
        project.
      </p>

      <div className="flex gap-2">
        <PrimaryButton onClick={unpublish} text={'Yes, unpublish project'} />
        <PrimaryButton onClick={toggleModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
      </div>
    </Modal>
  );
}
