import { parseCustomizeOptions } from 'pages/ChartEditorPage/utils/customizeHelper';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomizeSection from '../CustomizeSection';
import PanelContainer from './PanelContainer';

export default function Customize(props) {
  const { customizeOptions, lang } = props;
  const chartEditorPage = useSelector((state) => state.chartEditorPage);
  const projectConfig = useSelector((state) => state.projectConfig);
  const layoutEditorPage = useSelector((state) => state.layoutEditorPage);
  useEffect(() => {
    if (props.team) {
      props.getFonts(props.team);
      props.getLogos(props.team);
    }
  }, []);

  useEffect(() => {
    props.getFonts(props.team);
    props.getLogos(props.team);
  }, [props.team]);

  const parsedCustomizeOptions = parseCustomizeOptions(
    customizeOptions,
    chartEditorPage,
    projectConfig,
    layoutEditorPage
  );

  return (
    <PanelContainer active={props.active} className="pt-6">
      <CustomizeSection customizeOptions={parsedCustomizeOptions} lang={lang} defaultTab={'option.text.title'} />
    </PanelContainer>
  );
}
