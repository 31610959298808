import React from 'react';

export default function DownloadIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1511_44669)">
        <path
          className="active-white focus-white"
          opacity="0.4"
          d="M8.5 4C8.36739 4 8.24022 3.94732 8.14645 3.85355C8.05268 3.75979 8 3.63261 8 3.5V2.35571e-06H0.750002C0.651757 -0.000244182 0.554425 0.0188626 0.463564 0.0562318C0.372703 0.093601 0.290092 0.148501 0.220448 0.217797C0.150804 0.287092 0.095491 0.369427 0.0576669 0.4601C0.0198427 0.550772 0.000248276 0.648007 2.35571e-06 0.746252V15.25C-0.000244182 15.3482 0.0188626 15.4456 0.0562318 15.5364C0.093601 15.6273 0.148501 15.7099 0.217797 15.7796C0.287092 15.8492 0.369427 15.9045 0.4601 15.9423C0.550772 15.9802 0.648007 15.9998 0.746252 16H11.25C11.3482 16.0002 11.4456 15.9811 11.5364 15.9438C11.6273 15.9064 11.7099 15.8515 11.7796 15.7822C11.8492 15.7129 11.9045 15.6306 11.9423 15.5399C11.9802 15.4492 11.9998 15.352 12 15.2537V4H8.5ZM10.25 13.5C10.25 13.6326 10.1973 13.7598 10.1036 13.8536C10.0098 13.9473 9.88261 14 9.75 14H2.25C2.11739 14 1.99022 13.9473 1.89645 13.8536C1.80268 13.7598 1.75 13.6326 1.75 13.5V7.5C1.75 7.36739 1.80268 7.24022 1.89645 7.14645C1.99022 7.05268 2.11739 7 2.25 7H9.75C9.88261 7 10.0098 7.05268 10.1036 7.14645C10.1973 7.24022 10.25 7.36739 10.25 7.5V13.5Z"
          fill="#28277E"
        />
        <path
          className="active-white focus-white"
          d="M11.7812 3.28125L8.72187 0.218752C8.65204 0.149165 8.56918 0.0940266 8.47802 0.0564914C8.38687 0.0189562 8.28921 -0.00023949 8.19062 2.25522e-06H8V3.5C8 3.63261 8.05268 3.75979 8.14644 3.85355C8.24021 3.94732 8.36739 4 8.5 4H12V3.80938C12 3.71129 11.9807 3.61417 11.9431 3.52356C11.9056 3.43294 11.8506 3.35061 11.7812 3.28125ZM9.75 7H2.25C2.11739 7 1.99021 7.05268 1.89645 7.14645C1.80268 7.24022 1.75 7.36739 1.75 7.5V13.5C1.75 13.6326 1.80268 13.7598 1.89645 13.8536C1.99021 13.9473 2.11739 14 2.25 14H9.75C9.88261 14 10.0098 13.9473 10.1036 13.8536C10.1973 13.7598 10.25 13.6326 10.25 13.5V7.5C10.25 7.36739 10.1973 7.24022 10.1036 7.14645C10.0098 7.05268 9.88261 7 9.75 7ZM4.25 13H2.75V11.5H4.25V13ZM4.25 10.5H2.75V9H4.25V10.5ZM6.75 13H5.25V11.5H6.75V13ZM6.75 10.5H5.25V9H6.75V10.5ZM9.25 13H7.75V11.5H9.25V13ZM9.25 10.5H7.75V9H9.25V10.5Z"
          fill="#28277E"
        />
        <path
          className="active-white focus-white"
          d="M19.2073 14.2937C19.5976 14.6841 19.5976 15.3172 19.2073 15.7078L15.2073 19.7078C15.0135 19.9023 14.7573 20 14.501 20C14.2448 20 13.9891 19.9023 13.7941 19.707L9.79413 15.707C9.40382 15.3167 9.40382 14.6836 9.79413 14.293C10.1848 13.9027 10.8179 13.9023 11.2082 14.2929L13.501 16.5844L13.501 9C13.501 8.44781 13.9488 8 14.501 8C15.0532 8 15.501 8.44781 15.501 9L15.501 16.5844L17.7948 14.2937C18.1854 13.9031 18.8166 13.9031 19.2073 14.2937Z"
          fill="#28277E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1511_44669">
          <rect width="20" height="20" fill="white" transform="translate(0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
}
