import actionTypes from './action-types';

export const setAction = (data) => ({
  type: actionTypes.profile.setAll,
  data
});

export const setLoading = (data) => ({
  type: actionTypes.profile.setLoading,
  data
});

export const setAnnouncements = (data) => ({
  type: actionTypes.profile.setAnnouncements,
  data
});

export const setSubteams = (data) => ({
  type: actionTypes.profile.setSubteams,
  data
});

export const getProfileAction = (data) => ({
  type: actionTypes.profile.getProfile,
  data
});

export const getGroupsAction = (data) => ({
  type: actionTypes.profile.getGroups,
  data
});

export const getSubscriptionAction = (data) => ({
  type: actionTypes.profile.getSubscription,
  data
});

export const updateTeamsAction = (data) => ({
  type: actionTypes.profile.updateTeams,
  data
});

export const deleteTeamAction = (data) => ({
  type: actionTypes.profile.deleteTeam,
  data
});

export const setLocalTeamAction = (data) => ({
  type: actionTypes.profile.setTeam,
  data
});

export const reloadDivisionsAction = (data) => ({
  type: actionTypes.profile.reloadDivisions,
  data
});

export const logoutAction = (data) => ({
  type: actionTypes.profile.logout,
  data
});

export const updateChartCountAction = (data) => ({
  type: actionTypes.profile.updateChartCount,
  data
});

export const getRecentExportAction = (data) => ({
  type: actionTypes.profile.getRecentExportHistory,
  data
});

export const deleteRecentExportAction = (data) => ({
  type: actionTypes.profile.deleteRecentExport,
  data
});
