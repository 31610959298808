/* eslint-disable max-depth */
import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import actionTypes from '../../../redux/actions/action-types';
import { updateAggregated } from './ChartEditor';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { getChartConfig } from '../selectors/chartEditor';
import { getDefaultThemes } from 'shared/utils/themeHelper';
import { cloneDeep } from 'lodash';
import {
  getThemeDetails,
  handleCssModules,
  handleCustomizedOptions,
  processThemeDetails,
  updateHighchartsOptions
} from '../utils/chartEditorThemesHelper';

function* fetchThemeDetails(theme, team, provider) {
  if (theme.id === 'master-theme') {
    return { ...theme };
  } else {
    return yield call(getThemeDetails, team?.id, theme.id, provider);
  }
}

export function* assignTheme(params) {
  let { theme, team, skipEmit } = params.data;
  const config = yield select(getProjectConfig);
  let chartConfig = yield select(getChartConfig);
  const { isMap } = chartConfig;
  let { customizedOptions, provider } = config;
  let plugins = cloneDeep(config.plugins);

  let themeDetails = yield fetchThemeDetails(theme, team, provider);
  let { themeMeta, themeOptions } = processThemeDetails(themeDetails, isMap, config, provider);

  let cssModules = plugins.cssModules || [];
  handleCssModules(themeDetails, plugins, cssModules);

  let newCustomizedOptions = handleCustomizedOptions(customizedOptions, provider);

  updateHighchartsOptions(themeOptions);

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      themeOptions,
      themeMeta,
      plugins,
      changeMade: true,
      cssModules
    })
  );

  if (!skipEmit) {
    yield call(updateAggregated, true);
  }
}

export function* selectDefaultTheme() {
  const { team } = yield select((state) => state.profile);
  const { provider } = yield select(getProjectConfig);
  const defaultThemes = getDefaultThemes('chart', provider);

  yield call(assignTheme, {
    data: {
      team,
      theme: defaultThemes[0],
      skipEmit: true
    }
  });
}

/** Watch functions */
export function* watchAssignTheme() {
  yield takeEvery(actionTypes.chartEditor.assignTheme, assignTheme);
}

export default function* rootSaga() {
  yield all([watchAssignTheme()]);
}
