import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  gridData: [[]],
  colHeaders: [] as string[]
};

const dataGridSlice = createSlice({
  name: 'dataGridConfig',
  initialState,
  reducers: {
    updateGridData(state, action: PayloadAction<{ gridData: [[]]; colHeaders: string[] }>) {
      return { ...state, gridData: action.payload.gridData, colHeaders: action.payload.colHeaders };
    }
  }
});

export const { updateGridData } = dataGridSlice.actions;
export default dataGridSlice.reducer;
