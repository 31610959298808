import React, { useEffect } from 'react';
import BuildInputs from './../../shared/widgets/BuildInputs';
import SelectWidget from './SelectWidget';
import { isArray } from 'lodash';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  onChangeActiveAnnotationAction,
  updateHighlightAnnotationAction,
  removeHighlightAction,
  addHighlightAction
} from '../../pages/ChartEditorPage/actions/chartEditor';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';

export default (props) => {
  const { option } = props;
  const { annotationSection } = useSelector((state) => state.chartEditorPage);
  const chart = useChart();
  const dispatch = useDispatch();
  const store = useStore();

  const onChangeActiveAnnotation = (selected, type) => dispatch(onChangeActiveAnnotationAction({ selected, type }));
  const updateHighlightAnnotation = (optionProps, val, extraModulesToLoad, index) =>
    dispatch(updateHighlightAnnotationAction({ optionProps, val, extraModulesToLoad, index }));
  const removeHighlight = () => dispatch(removeHighlightAction({ index: annotationSection.highlights.value || 0 }));
  const addHighlight = () => dispatch(addHighlightAction({}));

  useEffect(() => {
    return () => {
      // Unmount
      const { annotationSection } = store.getState().chartEditorPage;
      if (annotationSection.highlights) {
        onChangeActiveAnnotation(null, 'highlights');
      }
    };
  }, []);

  const getHighlights = () => {
    const axisMap = {
      xAxis: 'vertical',
      yAxis: 'horizontal'
    };
    let vals = [];
    let selectOptions = [];
    let optionValues = [];

    if (chart?.xAxis) {
      vals.push(...((isArray(chart.xAxis) ? chart.xAxis[0].plotLinesAndBands : chart.xAxis.plotLinesAndBands) || []));
    }
    if (chart?.yAxis) {
      vals.push(...((isArray(chart.yAxis) ? chart.yAxis[0].plotLinesAndBands : chart.yAxis.plotLinesAndBands) || []));
    }

    vals = vals.sort((a, b) => a.id - b.id);

    let count = 1;

    vals.forEach((val, i) => {
      let label = 'Highlight ' + count;
      const hasLabel =
        val.options?.label &&
        val.options.label.text !== '[Insert Text]' &&
        val.options.label.text !== '' &&
        val.options.label.text !== undefined;
      if (hasLabel) {
        label = val.options.label.text;
      }

      selectOptions.push({
        label,
        value: i
      });

      optionValues.push({
        ...val.options,
        highlight: {
          orientation: axisMap[val.axis.coll],
          type: 'value' in val.options ? 'line' : 'range'
        }
      });

      count++;
    });

    return {
      selectOptions,
      optionValues
    };
  };

  const onChangeLabel = (selected) => {
    onChangeActiveAnnotation(selected, 'highlights');
  };

  const onChange = (type, optionProps, val, extraModulesToLoad) => {
    updateHighlightAnnotation(optionProps, val, extraModulesToLoad, annotationSection.highlights.value || 0);
  };

  const getValue = (selectOptions) => {
    if (annotationSection.highlights === false) return false;

    if (annotationSection.highlights.label) {
      return annotationSection.highlights;
    }

    // No label, find the label from select options
    return selectOptions.find((d) => d.value === annotationSection.highlights.value);
  };

  const filterOptionValues = (options, values) => {
    const index = options.findIndex((option) => option.pid === 'plotLines.dashStyle');
    if (values?.highlight?.type === 'line') {
      options[index].hidden = false;
    } else options[index].hidden = true;
  };

  const { selectOptions, optionValues } = getHighlights();
  const selectValue = getValue(selectOptions);
  filterOptionValues(option.options, optionValues[selectValue ? selectValue.value : 0]);

  return (
    <>
      <div className="font-bold pt-4 pb-2 text-ev-navy-blue-4"> Highlights </div>

      <div className="flex">
        <div className="inline-block annotation-dropdown w-full">
          <SelectWidget
            selectOptions={selectOptions}
            onChange={onChangeLabel}
            value={selectValue}
            placeholder={'Highlights'}
            canBeEmpty={true}
          />
        </div>
        <button className="inline-block highed-delete-add-option-button" onClick={addHighlight}>
          <i className="fa fa-plus" />
        </button>
        <button className="inline-block highed-delete-add-option-button disabled" onClick={removeHighlight}>
          <i className="fa fa-trash" />
        </button>
      </div>

      {annotationSection.highlights && (
        <div className="flex flex-wrap gap-x-1 flex-col gap-y-2">
          <BuildInputs
            section={option}
            parentKey={''}
            overrideOnChange={onChange}
            detailIndex={selectValue ? selectValue.value : 0}
            overrideOptions={optionValues[selectValue ? selectValue.value : 0]}
          />
        </div>
      )}
    </>
  );
};
