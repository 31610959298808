import { updateCustomizedBulkAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { GenericInputProps, InputValue } from 'shared/types/commonPropTypes';
import ArrowDown from 'static/icons/arrow_down.svg';
import ArrowUp from 'static/icons/arrow_up.svg';
import FieldContainer from './FieldContainer';
import InputWidget from './InputWidget';
import {
  checkIfNumberInputValueValidToChange,
  checkIfNumberInputValueValidToFormat,
  getFormattedNumberInputValue,
  getParsedNumberInputValue
} from './utils/widgetHelper';

const NumberInputWidget = (props: GenericInputProps) => {
  const dispatch = useDispatch();
  const { customizedOptions, isCompanyThemeEditor, aggregatedOptions } = useSelector(
    (state: RootState) => state.projectConfig
  );
  const { value, option, label, onChange } = props;
  const suffix = option?.suffix;
  const customOption = option?.custom;
  const minValue = customOption?.minValue;
  const maxValue = customOption?.maxValue;

  const [parsedValue, setParsedValue] = useState<InputValue>(getParsedNumberInputValue(value, suffix));

  useEffect(() => {
    setParsedValue(getParsedNumberInputValue(value, suffix));
  }, [value]);

  const handleClick = (operation: (val: number) => number) => {
    const value = Number(operation(parsedValue as number).toFixed(2));

    // Prevent saving a value below min value
    if (typeof minValue === 'number' && value < minValue) {
      return;
    }

    // Prevent saving a value above max value
    if (typeof maxValue === 'number' && value > maxValue) {
      return;
    }

    const formattedValue = getFormattedNumberInputValue(value, customOption?.initialValue);
    const valueToUpdate = suffix ? formattedValue + suffix : formattedValue;

    onChange({ val: valueToUpdate, index: props.index });
    if (option?.onOptionsChange) {
      const options = option.onOptionsChange(
        isCompanyThemeEditor ? aggregatedOptions : customizedOptions,
        formattedValue
      );
      dispatch(updateCustomizedBulkAction({ options }));
    }
  };

  const handleOnChange = ({ val }: { val: InputValue }) => {
    const dottedValue = (val as string).replace(/,/g, '.');
    const isMinusSymbol = dottedValue === '-';
    const isMinValueValid = typeof minValue === 'number';

    if (!checkIfNumberInputValueValidToChange(dottedValue, isMinusSymbol, isMinValueValid, minValue)) return;

    if (!checkIfNumberInputValueValidToFormat(dottedValue, isMinusSymbol)) return setParsedValue(dottedValue);

    const parsedDottedValue = parseFloat(dottedValue);
    const isParsedDottedValueNaN = Number.isNaN(parsedDottedValue);

    // Prevent saving a value below min value
    if (isMinValueValid && !isParsedDottedValueNaN && parsedDottedValue < minValue) {
      return;
    }

    // Prevent saving a value above max value
    if (typeof maxValue === 'number' && !isParsedDottedValueNaN && parsedDottedValue > maxValue) {
      return;
    }

    const valueToFormat = isParsedDottedValueNaN && minValue && isMinValueValid ? minValue : dottedValue;
    let formattedValue = getFormattedNumberInputValue(valueToFormat, customOption?.initialValue);

    // Prevent saving the same value
    if (value?.toString() === formattedValue?.toString()) {
      return setParsedValue(formattedValue);
    }
    setParsedValue(formattedValue);

    if (suffix && formattedValue) {
      formattedValue = formattedValue.toString() + suffix;
    }

    onChange({
      val: option?.suffix && val ? val + option?.suffix : formattedValue,
      index: props.index
    });
    if (option?.onOptionsChange) {
      const options = option.onOptionsChange(
        isCompanyThemeEditor ? aggregatedOptions : customizedOptions,
        formattedValue
      );
      dispatch(updateCustomizedBulkAction({ options }));
    }
  };

  const valueComponent = (
    <InputWidget
      inputType="number"
      onChange={handleOnChange}
      value={parsedValue}
      placeholder="Auto"
      option={option}
      className={`text-ev-dark-purple font-bold w-16 py-0 text-right h-6 min-h-0 mr-1 placeholder-ev-dark-purple
    ${option?.className ?? 'bg-ev-grey'}
  `}
    />
  );

  return (
    <FieldContainer
      label={label}
      labelClass="text-ev-navy-blue-4"
      className={props.className}
      id={props.option?.id}
      value={valueComponent}
      disabled={option?.shouldBeDisabled && option?.shouldBeDisabled(aggregatedOptions)}
      disabledText={option?.disabledText}
    >
      <div className="w-6 h-6 bg-ev-grey rounded flex flex-col items-center justify-evenly drop-shadow-number-input">
        <SvgIconButton
          Icon={ArrowUp}
          buttonColor={ButtonColor.Purple}
          width={8}
          height={4}
          onClick={() => handleClick((val) => ++val)}
          buttonClasses="rounded-t w-5 h-[0.625rem] content-centered"
        />
        <SvgIconButton
          Icon={ArrowDown}
          buttonColor={ButtonColor.Purple}
          width={8}
          height={4}
          onClick={() => handleClick((val) => --val)}
          buttonClasses="rounded-b w-5 h-[0.625rem] content-centered"
        />
      </div>
    </FieldContainer>
  );
};

export default NumberInputWidget;
