import React from 'react';

type ProjectContainerProps = {
  children: React.ReactNode;
  className?: string;
  topBar?: React.ReactNode;
  bottomBar?: React.ReactNode;
};

export default function ProjectContainer(props: ProjectContainerProps) {
  const { children, className, topBar, bottomBar } = props;

  return (
    <div className={`align-top h-full w-full ev-sm:flex-1 min-w-0 flex flex-col overflow-hidden ${className}`}>
      {topBar}
      <div className={'flex-1 overflow-auto p-6 pt-4 pb-2 ev-sm:pb-6 ev-sm:pt-6 ev-sm:pr-8'}>{children}</div>
      {bottomBar}
      <div className="italic px-6 pb-4 font-medium ev-sm:hidden">
        This is a limited version of the editor. Use a larger screen to access all features
      </div>
    </div>
  );
}
