import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { setAction } from '../actions/settingsPage';
import { deleteTeamAction, updateTeamsAction } from '../../../redux/actions/profile';
import actionTypes from '../../../redux/actions/action-types';
import { snackBar } from 'editor/editors/highed.init';
import {
  getTeamProfileWithTeamid,
  postProfile,
  postTeamUsingTeamid,
  postAccountRequestverification,
  postTeamLicenseUsingTeamid,
  getTeamLicenseWithTeamid,
  delTeamLicenseUsingTeamidAndLicenseid
} from '../../../api/cloudApiGenerated';
import { reloadDivisions } from 'utils/profileHelper';
import { cloneDeep } from 'lodash';

export function* getTeamProfile(params) {
  const { team } = params.data;

  try {
    const data = yield call(getTeamProfileWithTeamid, team.id);
    yield put(
      setAction({
        teamSettings: data[0]
      })
    );
  } catch (error) {
    yield put(
      setAction({
        error: 'loading team settings: ' + error.message || error.toString()
      })
    );
  }
}

export function* setTeamSettings(params) {
  const { team, teamSettings } = params.data;

  yield put(
    setAction({
      saving: true
    })
  );

  try {
    yield call(postTeamUsingTeamid, team.id, teamSettings);
    yield put(
      setAction({
        saving: false
      })
    );
    yield put(updateTeamsAction({ teamSettings }));
    snackBar('Team updated');
  } catch (error) {
    yield put(
      setAction({
        error: 'saving team settings: ' + error.message || error.toString(),
        saving: false
      })
    );
  }
}

export function* deleteTeam(params) {
  const { team, profile, form } = params.data;

  if (team.name === form.teamName.value) {
    yield put(deleteTeamAction({ team }));
    yield put(setAction({ showDeleteModal: false }));
    reloadDivisions(team, profile);
    snackBar('Team deleted');
  } else yield put(setAction({ showDeleteError: 'You need to type in the name of this team to deactivate it' }));
}

export function* verifyEmail() {
  yield call(postAccountRequestverification);
  yield put(setAction({ sending: true }));
  snackBar('Email sent');
}

export function* setUserSettings(params) {
  const { userSettings } = params.data;

  yield put(setAction({ saving: true }));

  try {
    yield call(postProfile, userSettings);
    yield put(setAction({ saving: false }));
    snackBar('User updated');
  } catch (error) {
    yield put(
      setAction({
        error: 'saving user settings: ' + error.message || error.toString(),
        saving: false
      })
    );
  }
}

export function* generateLicense(params) {
  const { team } = params.data;

  const { cmsLicenses } = yield select((state) => state.settingsPage);
  const newLicenses = cloneDeep(cmsLicenses);

  yield put(
    setAction({
      generatingLicense: true
    })
  );

  try {
    const data = yield call(postTeamLicenseUsingTeamid, team.id);
    newLicenses.push(data.license);

    yield put(
      setAction({
        generatingLicense: false,
        cmsLicenses: newLicenses
      })
    );
    snackBar('License generated');
  } catch (error) {
    yield put(
      setAction({
        error: 'generating cms settings: ' + error.message || error.toString(),
        generatingLicense: false
      })
    );
  }
}

export function* getCMSLicense(params) {
  const { team } = params.data;

  try {
    const data = yield call(getTeamLicenseWithTeamid, team.id);

    yield put(
      setAction({
        cmsLicenses: data
      })
    );
  } catch (error) {
    yield put(
      setAction({
        error: 'getting cms settings: ' + error.message || error.toString()
      })
    );
  }
}

export function* deleteCMSLicense(params) {
  const { id } = params.data;

  const { team } = yield select((state) => state.profile);
  const { cmsLicenses } = yield select((state) => state.settingsPage);
  let newLicenses = cloneDeep(cmsLicenses);

  try {
    yield call(delTeamLicenseUsingTeamidAndLicenseid, team.id, id);
    newLicenses = newLicenses.filter((d) => d.id !== id);

    snackBar('License deleted');
    yield put(
      setAction({
        cmsLicenses: newLicenses
      })
    );
  } catch (error) {
    yield put(
      setAction({
        error: 'getting cms settings: ' + error.message || error.toString()
      })
    );
  }
}

/** Watch functions */
export function* watchGetTeamProfile() {
  yield takeLatest(actionTypes.settingsPage.getTeamProfile, getTeamProfile);
}
export function* watchSetTeamSettings() {
  yield takeLatest(actionTypes.settingsPage.setTeamSettings, setTeamSettings);
}
export function* watchDeleteTeam() {
  yield takeLatest(actionTypes.settingsPage.deleteTeam, deleteTeam);
}
export function* watchVerifyEmail() {
  yield takeLatest(actionTypes.settingsPage.verifyEmail, verifyEmail);
}
export function* watchSetUserSettings() {
  yield takeLatest(actionTypes.settingsPage.setUserSettings, setUserSettings);
}
export function* watchGenerateLicense() {
  yield takeLatest(actionTypes.settingsPage.generateLicense, generateLicense);
}
export function* watchGetCMSLicense() {
  yield takeLatest(actionTypes.settingsPage.getCMSLicense, getCMSLicense);
}
export function* watchDeleteCMSLicense() {
  yield takeLatest(actionTypes.settingsPage.deleteCMSLicense, deleteCMSLicense);
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchGetTeamProfile(),
    watchSetTeamSettings(),
    watchDeleteTeam(),
    watchVerifyEmail(),
    watchSetUserSettings(),
    watchGenerateLicense(),
    watchGetCMSLicense(),
    watchDeleteCMSLicense()
  ]);
}
