import React, { useState } from 'react';

import { isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission, checkPlanPermission } from 'utils/profileHelper';
import Select from '../../../shared/inputs/Select';
import Modal from '../../../shared/modal/Modal';
import { SetChartMetaAction } from '../actions/projectsPage.js';
import SelectMultiWidget from './../../../shared/widgets/SelectMultiWidget';
import AuthorsInfo from 'shared/message/AuthorsInfo';
import useToggle from 'shared/utils/useToggle';
import { ModalDeleteTag } from './ModalDeleteTag';

const InputText = ({ ...props }) => (
  <input
    {...props}
    className="transition border-gray-300 hover:border-gray-400 block w-full pl-3 rounded focus:ring-blue-500 focus:border-blue-500 highed-field-input"
  />
);
const InputCheck = ({ ...props }) => <input {...props} />;

const LabelButton = ({ ...props }) => {
  const disabledPassedProps = {
    className: 'text-gray-400 line-through underline cursor-not-allowed font-medium text-sm',
    title: 'Upgrade plan to use this feature'
  };

  const activatedPassedProps = {
    className: 'text-gray-700 font-medium text-sm underline hover:font-bold'
  };

  const passedProps = props.disabled ? disabledPassedProps : activatedPassedProps;
  return (
    <div className={`col-span-${props.numberofcolspan} last:border-l-2 last:text-center`}>
      <button {...passedProps} {...props}>
        {props.children}
      </button>
    </div>
  );
};

const Label = (props) => <label {...props.labelAttributes}>{props.label}</label>;

export default function EditChartModal(props) {
  const dispatch = useDispatch();
  const { tags } = useSelector((state) => state.projectsPage);
  const [settingsModal, toggleSettingsModal] = useToggle(false);
  const [selectTag, setSelectTag] = useState();

  const [form, setForm] = useState({
    name: props.chart.name,
    tags: props.appliedTags,
    checked: false,
    password: props.chart.password,
    division: { id: props.chart.division_id, name: props.chart.division }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      SetChartMetaAction({
        team: props.team,
        chart: props.chart,
        data: {
          ...form,
          ...{ has_published: form.checked ? form.password : null, private: form.checked ? 1 : 0 }
        }
      })
    );

    props.toggle();
  };

  const fields = [
    {
      Component: InputText,
      containerStyle: 'col-span-full',
      label: 'Title',
      props: {
        type: 'text',
        name: 'title',
        value: form.name,
        onChange: (v) =>
          setForm({
            ...form,
            name: v.target.value
          })
      }
    },
    {
      Component: SelectMultiWidget,
      containerStyle: 'col-span-full',
      label: 'Tags',
      props: {
        value: form.tags,
        onChange: (v) =>
          setForm({
            ...form,
            tags: v
          }),
        allowCreateNewTags: true,
        onClick: (action) => {
          setSelectTag(action);
          toggleSettingsModal(!settingsModal);
        },
        selectOptions: tags,
        placeholder: 'No tags yet',
        isValidNewOption: (val, opts) => [val.trim().length === 0, opts.find((v) => v.label === val)].includes(false)
      }
    },
    {
      Component: Select,
      containerStyle: 'col-span-full',
      label: 'Subteam',
      props: {
        options: props.divisions,
        getOptionValue: (x) => x.id,
        getOptionLabel: (x) => x.name,
        name: 'subteam',
        type: 'select',
        placeholder: 'All subteams',
        isDisabled: !checkPermission('subteam_management'),
        value: form.division.id === null ? props.divisions.find(isNull) : form.division,
        onChange: (v) =>
          setForm({
            ...form,
            division: v
          })
      }
    },
    (props.type === 'chart' || 'map') && {
      Component: InputCheck,
      containerStyle:
        'col-span-4 flex flex-row-reverse justify-end align-middle items-center space-x-reverse space-x-2',
      label: props.chart.private ? 'Change password' : 'Use password',
      props: {
        name: 'private',
        type: 'checkbox',
        id: 'password',
        className: 'border-2 border-ev-navy-blue-2',
        onChange: () =>
          setForm({
            ...form,
            checked: !form.checked
          })
      }
    },
    (props.type === 'chart' || 'map') && {
      Component: InputText,
      containerStyle: 'col-span-8',
      props: {
        name: 'privatePassword',
        type: 'password',
        placeholder: 'Enter Password',
        disabled: !form.checked,
        onChange: (v) =>
          setForm({
            ...form,
            password: v.target.value
          })
      }
    }
  ].filter(Boolean);

  const themeable = !(props.chart.chart_type === 4 || props.chart.chart_type === 5);

  const maybeThemeAction = (name, f, cond = true) =>
    cond && [name, f, !(checkPermission('theme_management') && checkPlanPermission('custom_themes'))].filter(Boolean);

  const chartActions = [
    maybeThemeAction('Duplicate to theme', props.duplicateToTheme, themeable),

    maybeThemeAction('Convert to theme', props.convertToTheme),

    ['Duplicate', props.duplicateChart, !checkPermission('duplicate_chart')]
  ].filter(Boolean);

  const colSpanNumber = 12 / chartActions.length;

  const templateChartActions = (
    <div className="mt-4 grid grid-cols-12">
      {chartActions.map(([name, callback, disabled = false]) => (
        <LabelButton
          key={name}
          onClick={() => {
            props.toggle();
            callback(props.team, props.chart);
          }}
          numberofcolspan={colSpanNumber}
          disabled={disabled}
        >
          {name}
        </LabelButton>
      ))}
    </div>
  );

  const makeField = ({ containerStyle, label, Component, props }) => {
    const labelAttribute = {
      className: 'text-base text-ev-navy-blue font-bold'
    };
    Component == InputCheck && (labelAttribute.className = 'text-sm text-black m-0 font-normal');
    props.id && (labelAttribute.htmlFor = props.id);
    return (
      <div className={containerStyle} key={props.name}>
        {label && <Label labelAttributes={labelAttribute} label={label} />}
        <Component {...props} />
      </div>
    );
  };

  const usePlaceholder = (e) => {
    e.target.src = props.placeholder;
    e.target.className = 'w-1/2 h-1/2 m-auto';
  };

  const body = (
    <div className="grid grid-cols-12 gap-8">
      <div className="col-span-6">
        <figure className="rounded-md flex p-6 place-self-center border-2 border-ev-navy-blue w-full">
          <img src={props.thumbnailURL} onError={(e) => usePlaceholder(e)} />
        </figure>
        {templateChartActions}
        <AuthorsInfo className="mt-6" chartInfo={props.chart} />
      </div>

      <div className="col-span-6 space-y-4">
        <form id="setMetadata" autoComplete="off">
          <div className="grid grid-cols-12 gap-6">{fields.map(makeField)}</div>
        </form>

        <div className="flex space-x-4 items-center">
          <button
            // form="setMetadata"
            onClick={handleSubmit}
            className="py-2 px-8 border-2 transition-colors hover:bg-rose hover:border-rose border-ev-red bg-ev-red text-white text-base font-bold rounded-3xl"
          >
            Save
          </button>
          <a
            href={props.editChart()}
            className="py-2 px-8 border-2 no-underline transition ease text-base font-bold duration-250 ring-1 ring-transparent hover:ring-ev-navy-blue-2 border-ev-navy-blue-2 text-ev-navy-blue-2 rounded-3xl"
          >
            Go to editor
          </a>
          {form.checked && !props.chart.private && form.password && (
            <span className="text-gray-600 flex-grow">
              <i className="fa fa-exclamation-circle text-yellow-600 mr-2" />
              chart will be published
            </span>
          )}
        </div>
      </div>
      <ModalDeleteTag isOpen={settingsModal} onClose={() => toggleSettingsModal(false)} tag={selectTag} />
    </div>
  );

  return (
    <Modal isOpen={props.isOpen} onClose={props.toggle} title={'Edit your project'} bg={'bg-ev-yellow-1'}>
      {body}
    </Modal>
  );
}
