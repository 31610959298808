import PropTypes from 'prop-types';
import React from 'react';
import InputError from 'shared/message/InputError';

const SelectInput = ({
  name,
  label,
  onChange,
  defaultOption,
  value,
  error,
  options,
  extraClass,
  labelKey,
  valueKey,
  selectClassName
}) => {
  let wrapperClass = 'form-group';
  if (error && error.length > 0) {
    wrapperClass += ' has-error';
  }
  if (extraClass) {
    wrapperClass += ' ' + extraClass;
  }

  return (
    <div className={wrapperClass}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className="field">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select name={name} value={value} onChange={onChange} className={'form-control ' + selectClassName}>
          {defaultOption && <option value="">{defaultOption}</option>}
          {(options || []).map((option) => {
            return (
              <option
                key={valueKey ? option[valueKey] : option.value}
                value={valueKey ? option[valueKey] : option.value}
              >
                {labelKey ? option[labelKey] : option.text}
              </option>
            );
          })}
        </select>
        <InputError error={error} />
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  defaultOption: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  extraClass: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  selectClassName: PropTypes.string
};

export default SelectInput;
