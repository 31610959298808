import { connect } from 'react-redux';
import SettingsPage from '../SettingsPage';
import {
  setAction,
  getTeamProfileAction,
  setTeamSettingsAction,
  deleteTeamAction,
  verifyEmailAction,
  setUserSettingsAction,
  generateLicenseAction,
  getCMSLicenseAction
} from '../actions/settingsPage';
import { checkPermission, onLoggedIn } from '../../../utils/profileHelper';

const mapStateToProps = (state) => {
  return {
    paths: state.app.paths || {},
    profile: state.profile,
    team: state.profile.team,
    user: state.profile.user,
    division: state.profile.division,
    checkPermission: (permission) => checkPermission(permission, state.profile),
    userSettings: state.settingsPage.userSettings,
    teamSettings: state.settingsPage.teamSettings,
    saving: state.settingsPage.saving,
    generatingLicense: state.settingsPage.generatingLicense,
    cmsLicenseKey: state.settingsPage.cmsLicenseKey,
    deleting: state.settingsPage.deleting,
    sending: state.settingsPage.sending,
    showDeleteModal: state.settingsPage.showDeleteModal,
    showNewTeamModal: state.settingsPage.showNewTeamModal,
    showDeleteError: state.settingsPage.showDeleteError,
    form: state.settingsPage.form,
    reloadTeams: onLoggedIn
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTeamProfile: (team) => dispatch(getTeamProfileAction({ team })),
  saveTeamSettings: (team, teamSettings) => dispatch(setTeamSettingsAction({ team, teamSettings })),
  generateLicense: (team) => dispatch(generateLicenseAction({ team })),
  getCMSLicenseAction: (team) => dispatch(getCMSLicenseAction({ team })),
  deleteTeam: (team, profile, form) => dispatch(deleteTeamAction({ team, profile, form })),
  verifyEmail: () => dispatch(verifyEmailAction()),
  saveUserSettings: (userSettings) => dispatch(setUserSettingsAction({ userSettings })),
  set: (params) => dispatch(setAction(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
