import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Wordcloud: TemplateProps = {
  title: 'Word cloud',
  description: '',
  constructor: '',
  thumbnail: 'wordcloud.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'wordcloud'
    },
    legend: {
      enabled: false
    }
  },
  plugins: ['wordcloud'],
  advancedParent: 'Wordcloud',
  sampleSet: 'wordcloud',
  dataValidator: false,
  usesTextArea: true,
  basic: {
    order: 10,
    thumbnail: 'word_cloud.svg',
    icon: 'WordCloud'
  },
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter)
};

export default Wordcloud;
