import { find } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import Toggle from 'shared/inputs/Toggle';
import { setAction } from '../actions/rolesPage';
import { Role, RolePermission, rolePermissionsCategorized } from '../types/rolesModels';
import UnsavedChangesBar from 'shared/UnsavedChangesBar';

type RolesPermissionsProps = {
  permissions: RolePermission[];
  rolePermissions: number[];
  activeRole: Role;
  unsavedChanges: boolean;
  saveChanges: () => void;
  onReset: () => void;
};

export default function RolesPermissions(props: RolesPermissionsProps) {
  const dispatch = useDispatch();
  const { rolePermissions, permissions, unsavedChanges, saveChanges, onReset } = props;

  const setRolePermissions = (permissionIds: number[], checked: boolean) => {
    const newPermissions = rolePermissions;
    permissionIds.forEach((permId) => {
      if (checked) {
        if (!rolePermissions.includes(permId)) newPermissions.push(permId);
      } else if (rolePermissions.includes(permId)) {
        newPermissions.splice(newPermissions.indexOf(permId), 1);
      }
    });

    dispatch(setAction({ unsavedChanges: true, rolePermissions: newPermissions }));
  };

  return (
    <div className="relative flex flex-col">
      <p className="text-2xl text-ev-navy-blue font-bold mb-10 px-4">Permissions - {props.activeRole.name}</p>

      <div className="grid grid-cols-1 gap-12 ev-md:grid-cols-2 px-4">
        {Object.entries(rolePermissionsCategorized).map(([category, categoryPermissions]) => {
          return (
            <div key={category} className="flex flex-col w-full list-none ev-sm:max-w-[480px]">
              <p className="text-xl text-ev-navy-blue border-b-ev-navy-blue border-b-2 font-medium pb-2 mb-4">{`${category} permissions`}</p>
              <div className="flex gap-4 mb-2 ev-sm-max:flex-col">
                <PrimaryButton
                  buttonColor={ButtonColor.NavyBlue}
                  text="Choose all"
                  onClick={() => setRolePermissions(categoryPermissions, true)}
                />
                <PrimaryButton
                  buttonColor={ButtonColor.WhiteBordered}
                  text="Clear permissions"
                  onClick={() => setRolePermissions(categoryPermissions, false)}
                />
              </div>
              {categoryPermissions.map((perm) => {
                const permission = find(permissions, { id: perm }) as RolePermission;
                if (!permission) return;
                const checked = rolePermissions.includes(permission.id);
                return (
                  <div key={perm} className="border-b-ev-navy-blue border-b py-6 last:border-none last:mb-4">
                    <div className="flex items-center justify-between mb-2">
                      <p className="font-medium text-base m-0">{permission.title}</p>
                      <Toggle checked={checked} onToggle={() => setRolePermissions([permission.id], !checked)} />
                    </div>
                    <p className="text-base">{permission.description}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {unsavedChanges && <UnsavedChangesBar onChangesReset={onReset} onChangesSave={saveChanges} />}
    </div>
  );
}
