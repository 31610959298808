import { connect } from 'react-redux';
import { checkPermission } from '../../../utils/profileHelper';
import {
  addChartToGridAction,
  addImageToGridAction,
  changeTitleTextAction,
  closePublishModalAction,
  loadLayoutAction,
  openPublishModalAction,
  removeAction,
  resetStateAction,
  setAction,
  toggleChartModalAction,
  toggleCMSModalAction
} from '../actions/layoutEditor.js';
import LayoutComponent from '../components/LayoutComponent';
import { getTemplateOptions } from '../meta/config/templates';
import { getAggregatedOptions as getLayoutAggregatedOptions } from '../selectors/layoutEditor.js';

const mapStateToProps = (state) => {
  const setting = getTemplateOptions(state.layoutEditorPage.templateUsed);
  const aggregatedOptions = getLayoutAggregatedOptions(state.projectConfig);

  return {
    charts: state.projectsPage.charts || [],
    team: state.profile.team,
    division: state.profile.division,
    paths: state.app.paths || {},
    pages: state.layoutEditorPage.pages,
    activePage: state.layoutEditorPage.activePage,
    chartsUsed: state.layoutEditorPage.chartsUsed,
    loadedPage: state.layoutEditorPage.loadedPage,
    showPublishPopup: state.layoutEditorPage.showPublishPopup,
    settings: state.layoutEditorPage.settings,
    showWizard: state.projectConfig.showWizard,
    resolution: state.layoutEditorPage.resolution,
    isChartModalOpen: state.layoutEditorPage.isChartModalOpen,
    parentToAttachChartTo: state.layoutEditorPage.parentToAttachChartTo,
    templateUsed: state.layoutEditorPage.templateUsed,
    dataOptions: state.projectConfig.dataOptions,
    templateOptions: state.projectConfig.templateOptions,
    customizedOptions: state.projectConfig.customizedOptions,
    themeOptions: state.projectConfig.themeOptions,
    cmsModalOpen: state.layoutEditorPage.cmsModalOpen,
    checkPermission: (permission) => checkPermission(permission, state.profile),
    setting,
    aggregatedOptions,
    isCarouselOrLinks: ['carousel', 'tabs', 'dropdown'].includes(state.layoutEditorPage.templateUsed),
    isSlider: state.layoutEditorPage.templateUsed === 'slider',
    titleStyle: aggregatedOptions.title ? aggregatedOptions.title.style : null,
    subtitleStyle: aggregatedOptions.subtitle ? aggregatedOptions.subtitle.style : null,
    captionStyle: aggregatedOptions.caption ? aggregatedOptions.caption.style : null,
    canvasStyle: aggregatedOptions.canvas ? aggregatedOptions.canvas.style : null,
    logoStyle: aggregatedOptions.logo ? aggregatedOptions.logo.style : null
  };
};

const mapDispatchToProps = (dispatch) => ({
  set: (params) => dispatch(setAction(params)),
  remove: (section) => dispatch(removeAction({ section })),
  loadLayout: (team, storyId, cb) => dispatch(loadLayoutAction({ team, storyId, cb })),
  toggleChartModal: (parent) => dispatch(toggleChartModalAction(parent)),
  addChartToGrid: (chart, parentToAttachChartTo, dataOptions) =>
    dispatch(addChartToGridAction({ chart, parentToAttachChartTo, dataOptions })),
  addImageToGrid: (image, parentToAttachChartTo, dataOptions) =>
    dispatch(addImageToGridAction({ image, parentToAttachChartTo, dataOptions })),
  changeText: (event, customizedOptions, type) => dispatch(changeTitleTextAction({ event, customizedOptions, type })),
  openPublishModal: () => dispatch(openPublishModalAction({})),
  closePublishModal: () => dispatch(closePublishModalAction({})),
  resetState: () => dispatch(resetStateAction({})),
  toggleCMSModal: (toggle) => dispatch(toggleCMSModalAction({ toggle }))
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
