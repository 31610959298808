import actionTypes from '../../../redux/actions/action-types';
import initialState from '../../../redux/reducers/initial-state';

const redeemInvitePageReducer = (state = initialState.redeemInvitePage, action) => {
  switch (action.type) {
    case actionTypes.redeemInvite.acceptInvitation:
      return {
        ...state,
        accepted: action.data.accepted,
        error: action.data.error || null
      };
    default:
      return state;
  }
};

export default redeemInvitePageReducer;
