import { javascript } from '@codemirror/lang-javascript';
import { tokyoNightStorm } from '@uiw/codemirror-theme-tokyo-night-storm';
import CodeMirror from '@uiw/react-codemirror';
import React, { useState } from 'react';
import CustomCodeDefault from './../../../editor/meta/highed.meta.customcode.default';

type CustomCodeProps = {
  customCodeStr: string | null;
  setCustomCode: (customCode: string) => void;
};

let customCodeTimeout: NodeJS.Timeout;

export default (props: CustomCodeProps) => {
  const [customCode, setCustomCode] = useState(props.customCodeStr || CustomCodeDefault.join('\n'));

  const onChange = (value: string) => {
    setCustomCode(value);

    if (customCodeTimeout) {
      clearTimeout(customCodeTimeout);
    }

    customCodeTimeout = setTimeout(() => {
      props.setCustomCode(value);
    }, 1000);
  };

  return (
    <div className="custom-code" style={{ width: 332 }}>
      <CodeMirror
        theme={tokyoNightStorm}
        value={customCode}
        onChange={onChange}
        className="rounded overflow-auto h-[calc(100vh-315px)]"
        extensions={[javascript()]}
      />
    </div>
  );
};
