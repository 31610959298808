import { download } from 'editor/core/highcharts-editor';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import CustomSelect, { SelectItem } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import { CodeOption, codeOptions } from 'shared/wizard/meta/CodeOptions';
import { getEmbeddableHTML, getEmbeddableJSON, stringifyFn } from 'shared/wizard/utils/exportHelper';
import withExportDimensions, { ExportDimensionsProps } from './withExportDimensions';

const CodeChannel = (props: ExportDimensionsProps) => {
  const { showSubscriptionLock, showPaidPlanModal } = props;
  const projectConfig = useSelector((state: RootState) => state.projectConfig);
  const chartEditorPage = useSelector((state: RootState) => state.chartEditorPage);
  const packagesPage = useSelector((state: RootState) => state.packagesPage);
  const { team } = useSelector((state: RootState) => state.profile);
  const { packageProject } = packagesPage;
  const [codeOption, setCodeOption] = useState<SelectItem>(codeOptions[0].item);
  const [options, setCodeOptions] = useState(Object.values(codeOptions).map((opt) => opt.item));

  useEffect(() => {
    if (packageProject) {
      const options = codeOptions.filter((option) => option.id !== CodeOption.JSON);
      setCodeOptions(options.map((opt) => opt.item));
    }
  }, []);

  const downloadFile = () => {
    switch (codeOption.label) {
      case CodeOption.HTML:
        return download(
          'chart.html',
          getEmbeddableHTML(projectConfig, chartEditorPage, packagesPage, team),
          codeOption.value
        );
      case CodeOption.JSON:
        return download('chart.json', stringifyFn(getEmbeddableJSON(projectConfig), null), codeOption.value);
    }
  };
  return (
    <div className="flex flex-col">
      <p className="text-sm mb-4 text-black">Select your preferred format to export your project as.</p>
      <CustomSelect
        label="Format"
        options={options}
        onChange={(selected) => setCodeOption(selected as SelectItem)}
        styles={editorStyles}
        value={codeOption}
      />
      <PrimaryButton
        text="Export"
        onClick={showSubscriptionLock ? showPaidPlanModal : downloadFile}
        className="content-centered mt-4 px-6 h-10 text-base self-end"
        showSubscriptionLock={showSubscriptionLock}
      />
    </div>
  );
};

export default withExportDimensions(CodeChannel);
