export const enum ImageOption {
  PNG = 'PNG',
  JPG = 'JPG',
  SVG = 'SVG',
  PDF = 'PDF'
}

export type ImageMimeType = 'image/jpeg' | 'image/png' | 'image/svg+xml' | 'application/pdf';

export type ImageOptionType = {
  id: keyof typeof ImageOption;
  item: {
    label: keyof typeof ImageOption;
    value: ImageMimeType;
    type: 'image';
  };
  provider: string[];
  dimensions: { width: number | null; height: number | null };
};

export const imageOptions: ImageOptionType[] = [
  {
    id: ImageOption.PNG,
    item: { label: ImageOption.PNG, value: 'image/png', type: 'image' },
    dimensions: { width: null, height: null },
    provider: ['highcharts', 'locationMap']
  },
  {
    id: ImageOption.JPG,
    item: { label: ImageOption.JPG, value: 'image/jpeg', type: 'image' },
    dimensions: { width: null, height: null },
    provider: ['highcharts', 'locationMap']
  },
  {
    id: ImageOption.SVG,
    item: { label: ImageOption.SVG, value: 'image/svg+xml', type: 'image' },
    dimensions: { width: 700, height: 500 },
    provider: ['highcharts']
  },
  {
    id: ImageOption.PDF,
    item: { label: ImageOption.PDF, value: 'application/pdf', type: 'image' },
    dimensions: { width: 700, height: 500 },
    provider: ['highcharts', 'locationMap']
  }
];
