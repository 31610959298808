import React from 'react';

type TagProps = {
  text: string;
};

export default function Tag(props: TagProps) {
  const { text } = props;

  return <div className="bg-ev-light-orange text-ev-navy-blue font-bold w-fit px-2 py-1 rounded">{text}</div>;
}
