import React from 'react';
import { Helmet } from 'react-helmet';

const CookieConsent = () => {
  const cookiesKey = window.hcconfig.cookiesKey;
  return (
    <Helmet>
      {cookiesKey && (
        <script
          id="cookieyes"
          type="text/javascript"
          src={`https://cdn-cookieyes.com/client_data/${cookiesKey}/script.js`}
        />
      )}
    </Helmet>
  );
};

export default CookieConsent;
