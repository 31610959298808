import initialState from '../../../redux/reducers/initial-state';
import actionTypes from '../../../redux/actions/action-types';

const packagesPageReducer = (state = initialState.packagesPage, action: { type: string; data: any }) => {
  switch (action.type) {
    case actionTypes.packagesPage.set:
      return {
        ...state,
        ...action.data
      };
  }
  return state;
};

export default packagesPageReducer;
