import classNames from 'classnames';
import React from 'react';
import Button from './Button';
import { ButtonColor, ButtonType } from './types/ButtonModels';
import { getClassesForButtonColor, getClassesForButtonType } from './utils/buttonHelper';
import ButtonContainer from './ButtonContainer';

export type IconButtonProps = {
  icon: string | React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  buttonColor?: ButtonColor;
  hoverText?: string | React.ReactNode;
  tabIndex?: number;
  iconClass?: string;
  className?: string;
  active?: boolean;
  tooltipText?: string;
  colors?: string;
  disabled?: boolean;
  id?: string;
};

export default function IconButton(props: IconButtonProps) {
  const classes = classNames(
    props.className,
    getClassesForButtonType(ButtonType.Icon),
    getClassesForButtonColor(props.buttonColor || ButtonColor.White),
    {
      active: props.active,
      'pointer-events-none opacity-50': props.disabled
    }
  );

  return (
    <ButtonContainer {...props}>
      <Button
        extraClass={classes}
        iconLeft={true}
        buttonText={props.hoverText}
        tabIndex={props.tabIndex}
        icon={props.icon}
        iconClass={props.iconClass}
        onClick={props.onClick}
        id={props.id}
      />
    </ButtonContainer>
  );
}
