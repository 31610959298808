import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  displaySeparatorTab: false,
  linkingDataError: ''
};

const wizardSlice = createSlice({
  name: 'wizardConfig',
  initialState,
  reducers: {
    setSeparatorTab(state, action: PayloadAction<{ display: boolean }>) {
      return { ...state, displaySeparatorTab: action.payload.display };
    },
    setLinkingDataError(state, action: PayloadAction<{ error: string }>) {
      return { ...state, linkingDataError: action.payload.error };
    }
  }
});

export const { setSeparatorTab, setLinkingDataError } = wizardSlice.actions;
export default wizardSlice.reducer;
