import { default as getTemplateType } from '../../../../editor/meta/highed.basic.template.type';
import { checkSeriesAxisCompat, OptionProps } from '../CustomizeOptions';
import highlights from './annotations/AnnotationsHighlights';
import labels from './annotations/AnnotationsLabels';
import lines from './annotations/AnnotationsLines';
import { isTrialPlan } from 'pages/ChartEditorPage/utils/customizeHelper';

const options: Record<string, OptionProps[]> = {
  'option.cat.annotations': [
    {
      text: 'option.util.empty',
      mapDisabled: true,
      options: [
        {
          id: 'annotation.text.labels',
          pid: 'highlights',
          type: 'highlights',
          blacklist: [
            'dependencywheel',
            'sankey',
            'funnel',
            'streamgraph',
            'gauge',
            'pyramid',
            'packedbubble',
            'pie',
            'item',
            'wordcloud',
            'heatmap',
            'treemap',
            'timeline'
          ],
          filteredBy: getTemplateType,
          options: [labels, highlights, lines]
        }
      ]
    }
  ],
  'option.cat.text': [
    {
      id: 'text-category',
      pid: 'text.category',
      text: 'option.subcat.text',

      options: [
        {
          id: 'title.text',
          pid: 'title.text',
          type: 'richtextgroup',
          richtext: {
            rawId: 'title'
          },
          default: {
            text: 'Chart title'
          },
          inline: {
            highchartsId: 'title.alignOptions'
          },
          className: 'title-text'
        },
        {
          id: 'subtitle.text',
          richtext: {
            rawId: 'subtitle'
          },
          pid: 'subtitle.text',
          type: 'richtextgroup',
          inline: {
            highchartsId: 'subtitle.alignOptions'
          },
          className: 'subtitle-text'
        },
        {
          id: 'caption.text',
          richtext: {
            rawId: 'caption'
          },
          inline: {
            highchartsId: 'caption.alignOptions'
          },
          pid: 'caption.text',
          type: 'richtextgroup',
          className: 'caption-text'
        },
        {
          id: 'credits.text',
          pid: 'credits.text',
          type: 'richtextgroup',
          className: 'credits-text',
          inline: {
            highchartsId: ['credits.styles', 'credits.textStr']
          },
          richtext: {
            rawId: 'credits',
            removeWatermark: true
          },
          shouldBeDisabled: () => isTrialPlan(),
          disabledText: 'Upgrade your plan to enable this field.'
        },
        {
          id: 'credits.href',
          pid: 'href',
          type: 'string',
          default: 'https://app.everviz.com',
          shouldBeDisabled: () => isTrialPlan(),
          disabledText: 'Upgrade your plan to enable this field.'
        },
        {
          id: 'xAxis[0].title.text',
          pid: 'xAxis-title.text',
          type: 'richtextgroup',
          inline: {
            highchartsId: ['xAxis[0].axisTitle.styles', 'xAxis[0].axisTitle.textStr'],
            isAxis: true
          },
          richtext: {
            rawId: 'xAxis.title',
            highchartsDefault: 'axisTitle',
            highchartsStyleDefault: 'xAxis.axisTitle.styles'
          },
          mapDisabled: true,
          blacklist: checkSeriesAxisCompat,
          className: 'xAxis-title-text'
        },
        {
          id: 'yAxis[0].title.text',
          pid: 'yAxis-title.text',
          type: 'richtextgroup',
          inline: {
            highchartsId: ['yAxis[0].axisTitle.styles', 'yAxis[0].axisTitle.textStr'],
            isAxis: true
          },
          richtext: {
            highchartsDefault: 'axisTitle',
            highchartsStyleDefault: 'yAxis.axisTitle.styles',
            rawId: 'yAxis.title'
          },
          mapDisabled: true,
          blacklist: checkSeriesAxisCompat,
          className: 'yAxis-title-text'
        }
      ]
    }
  ]
};

export default options;
