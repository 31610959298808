import actionTypes from '../../../redux/actions/action-types';
import initialState from '../../../redux/reducers/initial-state';

const projectsPageReducer = (state = initialState.projectsPage, action) => {
  switch (action.type) {
    case actionTypes.projectsPage.set:
      return {
        ...state,
        ...action.data
      };
    case actionTypes.projectsPage.updateCharts:
      return {
        ...state
      };
    case actionTypes.projectsPage.setSelectFilter:
      return {
        ...state,
        ...action.data
      };
    case actionTypes.projectsPage.pageUpdate:
      return {
        ...state,
        page: action.data.page
      };
  }
  return state;
};

export default projectsPageReducer;
