import { TemplateProps } from '../../types';

const Pyramid: TemplateProps = {
  title: 'Pyramid',
  description: '',
  constructor: '',
  thumbnail: 'obulek.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'pyramid'
    },
    plotOptions: {
      series: {
        datalabels: {
          color: '#000000'
        },
        dataLabels: {
          softConnector: true
        }
      }
    },
    'series[0]': {
      width: '64%'
    }
  },
  sampleSet: 'pyramid',
  advancedParent: 'More',
  dataValidator: false
};

export default Pyramid;
