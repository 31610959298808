/* eslint-disable no-new */
/* eslint-disable max-lines */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-use-before-define */
import { put, takeEvery, all, call } from 'redux-saga/effects';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import actionTypes from '../../../redux/actions/action-types';
import { getExtentCells } from '../../../redux/selectors/tableRow';
import { cloneDeep } from 'lodash';
import { updateBufferData } from './tableEditorData';
import { isObj, merge } from 'editor/core/highcharts-editor';

const cssHTMLTags = {
  fontWeight: 'strong',
  fontStyle: 'em',
  textDecoration: 'u'
};

const alignmentStyling = {
  textAlign: ['text-align: left;', 'text-align: right;', 'text-align: center;', 'text-align: justify;']
};

const borderDirections = ['Left', 'Right', 'Top', 'Bottom'];

export function* setCellSetting(params) {
  let { action, actionType, cellSettings, dataOptions } = params.data;

  const row = parseInt(cellSettings.row, 10);
  const column = parseInt(cellSettings.column, 10);

  const newDataOptions = cloneDeep(dataOptions);

  if (isObj(newDataOptions[row][column])) {
    if (!newDataOptions[row][column].style) newDataOptions[row][column].style = {};

    if (newDataOptions[row][column].style[actionType]) {
      delete newDataOptions[row][column].style[actionType];
    } else {
      merge(newDataOptions[row][column].style, {
        [actionType]: action
      });
    }
  } else {
    newDataOptions[row][column] = {
      value: newDataOptions[row][column] || '',
      style: {
        [actionType]: action
      }
    };
  }

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
}

function getActiveCells(activeBufferCell, activeSelectedBufferCell, newDataOptions) {
  let { lowCell, highCell } = getExtentCells(activeBufferCell, activeSelectedBufferCell, newDataOptions);

  if (!lowCell.length) lowCell = activeBufferCell;
  if (!highCell.length) highCell = lowCell;

  return {
    lowCell,
    highCell
  };
}

export function* mergeCells(params) {
  let { dataOptions, activeBufferCell, activeSelectedBufferCell } = params.data;

  const newDataOptions = cloneDeep(dataOptions);
  const { lowCell, highCell } = getActiveCells(activeBufferCell, activeSelectedBufferCell, newDataOptions);
  const rowSpan = Math.abs(highCell[1] - lowCell[1]) + 1;

  // Check if all cells are merged already, if so unmerge everything to do with it.
  const columnArr = Array(Math.abs(lowCell[0] - highCell[0]) + 1)
    .fill()
    .map((_, idx) => lowCell[0] + idx);
  const rowArr = Array(Math.abs(lowCell[1] - highCell[1]) + 1)
    .fill()
    .map((_, idx) => lowCell[1] + idx);
  let oldMergeId = '';

  const topLeftCell = newDataOptions[lowCell[1]][lowCell[0]];
  if (isObj(topLeftCell) && topLeftCell.mergeId) oldMergeId = topLeftCell.mergeId;

  const allAreMerged = rowArr.every((rowIndex) => {
    return columnArr.every((columnIndex) => {
      const val = newDataOptions[rowIndex][columnIndex];
      return isObj(val) && val.mergeId && val.mergeId === oldMergeId;
    });
  });

  if (allAreMerged) {
    newDataOptions.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        if (isObj(cell) && cell.mergeId && cell.mergeId === oldMergeId) {
          newDataOptions[rowIndex][cellIndex] = '';
        }
      });
    });

    yield put(
      setProjectConfigAction({
        dataOptions: newDataOptions,
        changeMade: true
      })
    );

    yield call(updateBufferData, {
      dataOptions: newDataOptions
    });
    return;
  }

  const originalTopLeftVal = newDataOptions[lowCell[1]][lowCell[0]];
  let valsArr = [];
  let finalMergedValue = '';

  rowArr.forEach((rowIndex) => {
    return columnArr.forEach((columnIndex) => {
      const val = newDataOptions[rowIndex][columnIndex] || '';
      const isValObj = isObj(val);
      if (!isValObj || (isValObj && !val.mergedCell)) {
        if (isValObj) {
          if (val.value !== '') valsArr.push(val.value);
        } else if (val !== '') valsArr.push(val);
      }
    });
  });

  if (valsArr.length > 1) {
    finalMergedValue = isObj(originalTopLeftVal) ? originalTopLeftVal.value : originalTopLeftVal;
  } else if (valsArr.length === 1) finalMergedValue = valsArr[0];

  newDataOptions[lowCell[1]][lowCell[0]] = {
    value: finalMergedValue,
    colSpan: Math.abs(highCell[0] - lowCell[0]) + 1,
    rowSpan,
    mergeId: 'row_' + lowCell[0] + '_column_' + lowCell[1]
  };

  let diffX = Math.abs(lowCell[0] - highCell[0]);

  for (let i = 0; i < rowSpan; i++) {
    if (!i) {
      newDataOptions[lowCell[1] + i].splice(
        lowCell[0] + 1,
        diffX,
        ...Array(diffX)
          .fill()
          .map(() => {
            return {
              mergedCell: true,
              parentCell: [lowCell[0], lowCell[1]],
              mergeId: 'row_' + lowCell[0] + '_column_' + lowCell[1]
            };
          })
      );
    } else {
      newDataOptions[lowCell[1] + i].splice(
        lowCell[0],
        diffX + 1,
        ...Array(diffX + 1)
          .fill()
          .map(() => {
            return {
              mergedCell: true,
              parentCell: [lowCell[0], lowCell[1]],
              mergeId: 'row_' + lowCell[0] + '_column_' + lowCell[1]
            };
          })
      );
    }
  }

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
}

export function* setDataStyle(params) {
  let { dataOptions, activeBufferCell, activeSelectedBufferCell, props, value } = params.data;

  const newDataOptions = cloneDeep(dataOptions);

  if (!activeBufferCell) return;
  const { lowCell, highCell } = getActiveCells(activeBufferCell, activeSelectedBufferCell, newDataOptions);

  changeArrayStyleVals(newDataOptions, lowCell[0], lowCell[1], highCell[0], highCell[1], {
    [props]: value
  });

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
}

export function* removeDataStyle(params) {
  let { dataOptions, activeBufferCell, activeSelectedBufferCell, props } = params.data;

  const newDataOptions = cloneDeep(dataOptions);

  if (!activeBufferCell) return;
  const { lowCell, highCell } = getActiveCells(activeBufferCell, activeSelectedBufferCell, newDataOptions);

  deleteArrayStyleVals(newDataOptions, lowCell[0], lowCell[1], highCell[0], highCell[1], props);

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
}

function forEachCell(startColumn, startRow, endColumn, endRow, fn) {
  for (let i = startRow; i <= endRow; i++) {
    for (let x = startColumn; x <= endColumn; x++) {
      fn(x, i);
    }
  }
}

function changeArrayStyleVals(options, startColumn, startRow, endColumn, endRow, obj) {
  forEachCell(startColumn, startRow, endColumn, endRow, (x, i) => {
    const val = options[i][x];
    if (!isObj(val)) options[i][x] = { value: val };
    if (!options[i][x].style) options[i][x].style = {};
    merge(options[i][x].style, obj);
  });
}

function deleteArrayVals(options, startColumn, startRow, endColumn, endRow) {
  forEachCell(startColumn, startRow, endColumn, endRow, (x, i) => {
    const val = options[i][x];
    if (!isObj(val)) options[i][x] = '';
    else val.value = '';
  });
}

function deleteArrayStyleVals(options, startColumn, startRow, endColumn, endRow, prop) {
  forEachCell(startColumn, startRow, endColumn, endRow, (x, i) => {
    let val = options[i][x];
    if (isObj(val)) {
      if (!val.mergedCell) {
        if (val.style && prop in val.style) {
          delete val.style[prop];
          if (cssHTMLTags[prop]) {
            const htmlTag = cssHTMLTags[prop];
            const regStart = new RegExp('<' + htmlTag + '>', 'g');
            const regEnd = new RegExp('</' + htmlTag + '>', 'g');
            val.value = val.value.toString().replace(regEnd, '');
            val.value = val.value.replace(regStart, '');
          } else if (alignmentStyling[prop]) {
            alignmentStyling[prop].forEach((alignment) => {
              const regStart = new RegExp(alignment, 'g');
              val.value = val.value.toString().replace(regStart, '');
            });
          }
        }
      }
    } else {
      // Check if val has a corresponding tag in it.
      // eslint-disable-next-line no-lonely-if
      if (cssHTMLTags[prop]) {
        const htmlTag = cssHTMLTags[prop];
        const regStart = new RegExp('<' + htmlTag + '>', 'g');
        const regEnd = new RegExp('<' + htmlTag + '/>', 'g');
        val = val.toString().replace(regEnd, '');
        val = val.replace(regStart, '');
      } else if (alignmentStyling[prop]) {
        alignmentStyling[prop].forEach((alignment) => {
          const regStart = new RegExp(alignment, 'g');
          val = val.toString().replace(regStart, '');
        });
      }
    }
  });
}

function createBorder(newDataOptions, startX, startY, endX, endY, type) {
  changeArrayStyleVals(newDataOptions, startX, startY, endX, endY, {
    ['border' + type]: '1px',
    ['border' + type + 'Style']: 'solid',
    ['border' + type + 'Color']: 'black'
  });
}

function clearBorder(options, startColumn, startRow, endColumn, endRow) {
  forEachCell(startColumn, startRow, endColumn, endRow, (x, i) => {
    const val = options[i][x];
    if (isObj(val) && val.style) {
      Object.keys(val.style).forEach((key) => {
        if (key.indexOf('border') > -1) delete val.style[key];
      });
    }
  });
}

export function* setBordersAllEdges(params) {
  let { dataOptions, activeBufferCell, activeSelectedBufferCell, type } = params.data;

  const newDataOptions = cloneDeep(dataOptions);

  const { lowCell, highCell } = getActiveCells(activeBufferCell, activeSelectedBufferCell, newDataOptions);

  if (type === 'edges') {
    createBorder(newDataOptions, lowCell[0], lowCell[1], highCell[0], lowCell[1], 'Top');
    createBorder(newDataOptions, lowCell[0], highCell[1], highCell[0], highCell[1], 'Bottom');
    createBorder(newDataOptions, lowCell[0], lowCell[1], lowCell[0], highCell[1], 'Left');
    createBorder(newDataOptions, highCell[0], lowCell[1], highCell[0], highCell[1], 'Right');
  } else if (type === 'clear') {
    clearBorder(newDataOptions, lowCell[0], lowCell[1], highCell[0], highCell[1]);
  } else if (type === 'all') {
    createBorder(newDataOptions, lowCell[0], lowCell[1], highCell[0], lowCell[1], 'Top');
    createBorder(newDataOptions, lowCell[0], highCell[1], highCell[0], highCell[1], 'Bottom');
    createBorder(newDataOptions, lowCell[0], lowCell[1], lowCell[0], highCell[1], 'Left');
    createBorder(newDataOptions, highCell[0], lowCell[1], highCell[0], highCell[1], 'Right');
    const columnArr = Array(Math.abs(lowCell[0] - highCell[0]))
      .fill()
      .map((_, idx) => lowCell[0] + 1 + idx);
    const rowArr = Array(Math.abs(lowCell[1] - highCell[1]))
      .fill()
      .map((_, idx) => lowCell[1] + 1 + idx);
    columnArr.forEach((col) => createBorder(newDataOptions, col, lowCell[1], col, highCell[1], 'Left'));
    rowArr.forEach((row) => createBorder(newDataOptions, lowCell[0], row, highCell[0], row, 'Top'));
  } else if (type === 'left') createBorder(newDataOptions, lowCell[0], lowCell[1], lowCell[0], highCell[1], 'Left');
  else if (type === 'top') createBorder(newDataOptions, lowCell[0], lowCell[1], highCell[0], lowCell[1], 'Top');
  else if (type === 'bottom') createBorder(newDataOptions, lowCell[0], highCell[1], highCell[0], highCell[1], 'Bottom');
  else if (type === 'right') createBorder(newDataOptions, highCell[0], lowCell[1], highCell[0], highCell[1], 'Right');

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
}

export function* toggleDataStyle(params) {
  let { dataOptions, activeBufferCell, activeSelectedBufferCell, props, value } = params.data;

  const newDataOptions = cloneDeep(dataOptions);

  if (!activeBufferCell) return;

  let { lowCell, highCell } = getActiveCells(activeBufferCell, activeSelectedBufferCell, newDataOptions);

  const columnArr = Array(Math.abs(lowCell[0] - highCell[0]) + 1)
    .fill()
    .map((_, idx) => lowCell[0] + idx);
  const rowArr = Array(Math.abs(lowCell[1] - highCell[1]) + 1)
    .fill()
    .map((_, idx) => lowCell[1] + idx);

  const isTextAlign = props === 'textAlign';

  const allHaveProperty = rowArr.some((rowIndex) => {
    return columnArr.some((columnIndex) => {
      const val = newDataOptions[rowIndex][columnIndex];
      if (isTextAlign) return isObj(val) && val.style && props in val.style && val.style.props === value;
      else return isObj(val) && val.style && props in val.style;
    });
  });

  if (allHaveProperty) {
    // Remove property
    deleteArrayStyleVals(newDataOptions, lowCell[0], lowCell[1], highCell[0], highCell[1], props);
  } else {
    changeArrayStyleVals(newDataOptions, lowCell[0], lowCell[1], highCell[0], highCell[1], {
      [props]: value
    });
  }

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
}

export function* deleteBulkCellValues(params) {
  let { dataOptions, activeBufferCell, activeSelectedBufferCell } = params.data;

  const newDataOptions = cloneDeep(dataOptions);

  if (!activeBufferCell) return;

  let { lowCell, highCell } = getActiveCells(activeBufferCell, activeSelectedBufferCell, newDataOptions);

  deleteArrayVals(newDataOptions, lowCell[0], lowCell[1], highCell[0], highCell[1]);

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
}

function changeBorderStyle(options, startColumn, startRow, endColumn, endRow, props) {
  const keys = Object.keys(props);
  forEachCell(startColumn, startRow, endColumn, endRow, (x, i) => {
    const val = options[i][x];
    if (isObj(val)) {
      if (!options[i][x].style) options[i][x].style = {};
      Object.keys(options[i][x].style).forEach((key) => {
        borderDirections.forEach((dir) => {
          if (key === 'border' + dir) {
            keys.forEach((key) => {
              options[i][x].style['border' + dir + key] = props[key];
            });
          }
        });
      });
    }
  });
}

export function* setBorderStyle(params) {
  let { dataOptions, activeBufferCell, activeSelectedBufferCell, props } = params.data;

  const newDataOptions = cloneDeep(dataOptions);

  const { lowCell, highCell } = getActiveCells(activeBufferCell, activeSelectedBufferCell, newDataOptions);

  changeBorderStyle(newDataOptions, lowCell[0], lowCell[1], highCell[0], highCell[1], props);

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
}

/** Watch functions */
export function* watchSetCellSetting() {
  yield takeEvery(actionTypes.tableEditor.setCellSetting, setCellSetting);
}
export function* watchMergeCells() {
  yield takeEvery(actionTypes.tableEditor.mergeCells, mergeCells);
}

export function* watchSetDataStyle() {
  yield takeEvery(actionTypes.tableEditor.setDataStyle, setDataStyle);
}
export function* watchRemoveDataStyle() {
  yield takeEvery(actionTypes.tableEditor.removeDataStyle, removeDataStyle);
}
export function* watchToggleDataStyle() {
  yield takeEvery(actionTypes.tableEditor.toggleDataStyle, toggleDataStyle);
}
export function* watchDeleteBulkCellValues() {
  yield takeEvery(actionTypes.tableEditor.deleteBulkCellValues, deleteBulkCellValues);
}

export function* watchSetBordersAllEdges() {
  yield takeEvery(actionTypes.tableEditor.setBordersAllEdges, setBordersAllEdges);
}
export function* watchSetBorderStyle() {
  yield takeEvery(actionTypes.tableEditor.setBorderStyle, setBorderStyle);
}

export default function* rootSaga() {
  yield all([
    watchSetCellSetting(),
    watchMergeCells(),
    watchSetDataStyle(),
    watchRemoveDataStyle(),
    watchToggleDataStyle(),
    watchSetBordersAllEdges(),
    watchDeleteBulkCellValues(),
    watchSetBorderStyle()
  ]);
}
