import { TemplateProps } from '../../types';

const Bar: TemplateProps = {
  title: 'Bar',
  description: '',
  constructor: '',
  thumbnail: 'ovuvul.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column',
      inverted: true,
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'bar',
  advancedParent: 'Bar',
  dataValidator: false,
  basic: {
    order: 3,
    thumbnail: 'bar_graph.svg',
    icon: 'Bar'
  },
  advanced: {
    name: 'Standard'
  },
  parent: 'Bar'
};

export default Bar;
