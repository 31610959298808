/* eslint-disable no-invalid-this */
import React from 'react';
import CopyButton from './CopyButton';
import TextInput from './TextInput';

type CopyInputProps = {
  value: string;
  tabIndex: number;
  className?: string;
};

const CopyInput = ({ value, className, tabIndex }: CopyInputProps) => {
  return (
    <div className={`relative ${className}`}>
      <div className="flex justify-between w-full">
        <TextInput
          name="search"
          value={value}
          extraClass={'w-full'}
          inputClassName={'highed-field-input shadow-none font-bold text-xs'}
          readOnly={true}
          tabIndex={tabIndex}
        />
        {value && <CopyButton value={value} tabIndex={tabIndex} />}
      </div>
    </div>
  );
};

export default CopyInput;
