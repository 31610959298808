import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CustomSelect, { customSelectColors } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import { changeTypeAction } from '../actions/ThemeEditorPage';

const chartOptions = [
  { label: 'Line', value: 'line' },
  { label: 'Area', value: 'area' },
  { label: 'Bar', value: 'bar' },
  { label: 'Column', value: 'column' },
  { label: 'Pie', value: 'pie' },
  { label: 'Word cloud', value: 'wordcloud' },
  { label: 'Sankey', value: 'sankey' }
];

const mapOptions = [
  { label: 'Choropleth', value: 'choropleth' },
  { label: 'Categories', value: 'categoriesMap' },
  { label: 'Bubble map', value: 'bubbleMap' },
  { label: 'Honeycomb', value: 'honeycomb' },
  { label: 'Point map', value: 'pointMap' }
];

const typeMap = {
  Map: mapOptions,
  Chart: chartOptions
};

export default function SelectType() {
  const dispatch = useDispatch();
  const { view, chartType } = useSelector((state: RootState) => state.companyThemeEditorPage);
  const isTable = view === 'Tables';
  const type = view === 'Maps' ? 'Map' : 'Chart';

  const changeType = (selected: any) => {
    dispatch(
      changeTypeAction({
        chartType: selected
      })
    );
  };

  return (
    <>
      {!isTable && (
        <div className="ml-auto w-full ev-md:w-[260px]">
          <CustomSelect
            styles={editorStyles}
            backgroundColor={customSelectColors.grey}
            label={`${type} type:`}
            labelClasses="text-base"
            options={typeMap[type]}
            value={chartType}
            onChange={changeType}
          />
        </div>
      )}
    </>
  );
}
