export default [
  {
    id: 99999995,
    name: 'everviz',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-everviz.png',
    template: {
      options: {
        rows: {
          stripes: true,
          style: {},
          stripesColor: '#ddd8f5'
        },
        title: {
          enabled: true,
          text: 'Lorem ipsum dolor sit amet',
          style: {
            fontSize: '22px',
            fontFamily: 'Gordita Bold',
            color: '#172b4c'
          }
        },
        subtitle: {
          text: 'consectetur adipiscing elit, sed do eiusmod',
          style: {
            fontFamily: 'Gordita',
            fontSize: '16px',
            color: '#172b4c'
          }
        },
        caption: {
          style: {
            fontSize: '12px',
            fontStyle: 'italic',
            color: '#444444'
          }
        },
        exporting: {
          enabled: true
        },
        table: {
          pagination: {
            enabled: true
          },
          style: {
            fontFamily: 'Gordita',
            backgroundColor: '#faf6ec',
            fontSize: '12px'
          },
          searchable: {
            enabled: true,
            style: {
              borderColor: '#172b4c'
            },
            labelText: 'Search in table',
            noDataText: 'No data',
            placeholderText: 'Search in table'
          },
          extra: {
            style: {
              backgroundColor: '#ddd8f5'
            }
          }
        },
        header: {
          style: {
            backgroundColor: '#172b4c',
            fontSize: '12px',
            fontFamily: 'Gordita Bold',
            color: '#ffffff'
          }
        }
      },
      cssModules: [
        'https://app.everviz.com/fonts/cAMLbHmJ2/font.css',
        'https://app.everviz.com/fonts/HPLuvkaK_/font.css'
      ]
    }
  },
  {
    id: 99999996,
    name: 'Comfort',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-comfort.png',
    template: {
      options: {
        rows: {
          stripes: true,
          style: {},
          textColor: '#47475c',
          stripesColor: '#9eb3dc'
        },
        exporting: {
          enabled: true
        },
        title: {
          enabled: true,
          style: {
            color: '#333333',
            fontFamily: 'OpenSans-SemiBold',
            fontSize: '22px'
          },
          text: 'Table title'
        },
        table: {
          pagination: {
            enabled: true,
            backButtonStyle: {
              color: '#4e8c61'
            },
            frontButtonStyle: {
              color: '#4e8c61'
            }
          },
          style: {
            fontFamily: 'OpenSans-Regular',
            color: '#47475c',
            fontSize: '12px',
            backgroundColor: '#f3f3f3'
          },
          searchable: {
            enabled: true,
            style: {
              borderColor: '#4e8c61'
            },
            labelText: 'Search in table',
            noDataText: 'No data',
            placeholderText: 'Search in table'
          },
          sortable: {
            enabled: true
          },
          extra: {
            style: {
              backgroundColor: '#9eb3dc'
            }
          }
        },
        header: {
          style: {
            color: '#ffffff',
            backgroundColor: '#4e8c61',
            fontWeight: 'bold'
          }
        },
        subtitle: {
          style: {
            fontFamily: 'OpenSans-Regular',
            fontSize: '16px',
            color: '#333333'
          },
          text: 'Table subtitle',
          enabled: true
        },
        caption: {
          style: {
            fontFamily: 'OpenSans-Regular',
            color: '#333333',
            fontStyle: 'italic',
            fontSize: '12px'
          },
          enabled: true,
          text: 'Table caption'
        }
      },
      cssModules: [
        'https://app.everviz.com/fonts/b5FGmyCeZ/font.css',
        'https://app.everviz.com/fonts/SuXe00RLa/font.css'
      ]
    }
  },
  {
    id: 99999997,
    isDefault: true,
    name: 'Monochromatic',
    thumbnail_url: '/static/thumbnails/themes/theme-monochromatic.png',
    template: {
      options: {
        rows: {
          stripes: true,
          style: {},
          stripesColor: '#dbeafe',
          textColor: '#444444'
        },
        exporting: {
          enabled: true
        },
        title: {
          enabled: true,
          style: {
            textAlign: 'left',
            fontSize: '22px',
            fontFamily: 'Inter-Regular',
            color: '#444444'
          },
          text: 'Table title'
        },
        table: {
          pagination: {
            enabled: true,
            backButtonStyle: {
              color: '#1e3a8a'
            },
            frontButtonStyle: {
              color: '#1e3a8a'
            }
          },
          style: {
            fontFamily: 'Inter-Regular',
            fontSize: '12px',
            backgroundColor: '#f3f3f3',
            color: '#444444'
          },
          searchable: {
            enabled: true,
            style: {
              borderColor: '#1e3a8a'
            },
            labelText: 'Search in table',
            noDataText: 'No data',
            placeholderText: 'Search in table'
          },
          extra: {
            style: {
              backgroundColor: '#dbeafe'
            }
          }
        },
        subtitle: {
          style: {
            textAlign: 'left',
            fontFamily: 'Inter-Regular',
            fontSize: '16px',
            color: '#444444'
          },
          text: 'Table subtitle',
          enabled: true
        },
        header: {
          style: {
            backgroundColor: '#1e3a8a',
            color: '#ffffff'
          }
        },
        caption: {
          style: {
            fontFamily: 'Inter-Regular',
            fontStyle: 'italic',
            fontSize: '12px',
            color: '#444444'
          },
          enabled: true,
          text: 'Table caption'
        }
      },
      cssModules: [
        'https://app.everviz.com/fonts/hCLaqXfO8/font.css',
        'https://app.everviz.com/fonts/wnDyLY2cA/font.css',
        'https://app.everviz.com/fonts/DZt9aXmCJ/font.css'
      ]
    }
  },
  {
    id: 99999998,
    name: 'Monokai',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-monokai.png',
    template: {
      options: {
        rows: {
          stripes: true,
          style: {},
          stripesColor: '#d6d6d6',
          textColor: '#2e2e2e'
        },
        exporting: {
          enabled: true
        },
        title: {
          enabled: true,
          style: {
            backgroundColor: '#2e2e2e',
            color: '#d6d6d6',
            fontFamily: 'RobotoMono-Regular',
            textAlign: 'left',
            fontSize: '22px'
          },
          text: 'Table title'
        },
        table: {
          pagination: {
            enabled: true,
            backButtonStyle: {
              color: '#f92672'
            },
            frontButtonStyle: {
              color: '#f92672'
            }
          },
          style: {
            fontFamily: 'RobotoMono-Regular',
            backgroundColor: '#d6d6d6',
            color: '#2e2e2e',
            borderColor: '#a2a39c',
            borderWidth: '0px',
            fontSize: '12px'
          },
          searchable: {
            enabled: true,
            style: {
              borderColor: '#f92672'
            },
            labelText: 'Search in table',
            noDataText: 'No data',
            placeholderText: 'Search in table'
          },
          extra: {
            style: {
              backgroundColor: '#d6d6d6'
            }
          }
        },
        canvas: {
          style: {
            backgroundColor: '#2e2e2e'
          }
        },
        subtitle: {
          style: {
            backgroundColor: '#2e2e2e',
            color: '#d6d6d6',
            fontSize: '16px',
            fontFamily: 'RobotoMono-Regular'
          },
          enabled: true
        },
        header: {
          style: {
            backgroundColor: '#f92672',
            fontSize: '12px',
            color: '#2e2e2e',
            fontWeight: 'bold'
          }
        },
        caption: {
          style: {
            fontFamily: 'RobotoMono-Regular',
            color: '#d6d6d6',
            backgroundColor: '#2e2e2e',
            fontStyle: 'italic'
          },
          enabled: true
        }
      },
      cssModules: [
        'https://app.everviz.com/fonts/TiSVa0aay/font.css',
        'https://app.everviz.com/fonts/DZt9aXmCJ/font.css'
      ]
    }
  },
  {
    id: 99999999,
    name: 'Google',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-google.png',
    template: {
      options: {
        rows: {
          stripes: false,
          style: {}
        },
        exporting: {
          enabled: true
        },
        title: {
          enabled: true,
          style: {
            textAlign: 'center',
            fontFamily: 'Roboto-Medium',
            fontSize: '22px',
            color: '#444444'
          },
          text: 'Table title'
        },
        table: {
          pagination: {
            enabled: true,
            frontButtonStyle: {
              color: '#4d90fe'
            },
            backButtonStyle: {
              color: '#4d90fe'
            }
          },
          style: {
            fontFamily: 'Roboto-Regular',
            color: '#202124',
            borderColor: '#ffffff',
            borderWidth: '1px',
            fontSize: '12px',
            backgroundColor: '#f3f3f3'
          },
          searchable: {
            enabled: true,
            style: {
              borderColor: '#4d90fe'
            },
            labelText: 'Search in table',
            noDataText: 'No data',
            placeholderText: 'Search in table'
          },
          sortable: {
            enabled: true
          },
          extra: {
            style: {
              backgroundColor: '#f3f3f3'
            }
          }
        },
        subtitle: {
          style: {
            fontFamily: 'Roboto-Regular',
            textAlign: 'center',
            fontSize: '16px',
            color: '#444444'
          },
          enabled: true,
          text: 'Table subtitle'
        },
        caption: {
          style: {
            fontFamily: 'Roboto-Regular',
            textAlign: 'center',
            fontStyle: 'italic'
          },
          enabled: true,
          text: 'Table caption'
        },
        header: {
          style: {
            color: '#ffffff',
            backgroundColor: '#4285f4',
            fontSize: '12px',
            fontWeight: 'bold'
          }
        }
      },
      cssModules: [
        'https://app.everviz.com/fonts/DssrKoz1/font.css',
        'https://app.everviz.com/fonts/HPLuvkaK_/font.css',
        'https://app.everviz.com/fonts/wnDyLY2cA/font.css',
        'https://app.everviz.com/fonts/DZt9aXmCJ/font.css'
      ]
    }
  }
];
