export default {
  series: 2,
  data: 'Categories;Low;High;Low2;High2\nA;0;4;4;6\nB;1;3;6;8\nC;2;10;6;9\nD;3;6;5;9\nE;2;8;6;9\nF;5;10;4;10',
  assignDataFields: [
    {
      labels: 'A',
      low: 'B',
      high: 'C'
    },
    {
      labels: 'A',
      low: 'D',
      high: 'E'
    }
  ],
  seriesMapping: [
    {
      x: 0,
      low: 1,
      high: 2
    },
    {
      x: 0,
      low: 3,
      high: 4
    }
  ]
};
