export default {
  series: 1,
  data: 'Category;Series 1 (low);Series 1 (high)\nA;69;82\nB;70;81\nC;69;75',
  seriesMapping: [
    {
      x: 0,
      low: 1,
      high: 2,
    },
  ],
  assignDataFields: [
    {
      labels: 'A',
      low: 'B',
      high: 'C',
    },
  ],
};
