import { connect } from 'react-redux';
import ChartArrayWidget from '../../shared/widgets/ChartArrayWidget';
import {
  toggleChartModalAction,
  removeChartFromDataOptionsAction,
} from '../../pages/LayoutEditorPage/actions/layoutEditor.js';


const mapStateToProps = (state) => {
  return {
    templateUsed: state.layoutEditorPage.templateUsed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleChartModal: (parent) => dispatch(toggleChartModalAction(parent)),
  removeChartFromDataOptions: (index) =>
    dispatch(removeChartFromDataOptionsAction({ index })),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ChartArrayWidget);
