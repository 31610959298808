import DataLeftSection from './../../../shared/wizard/components/DataLeftSection';
import React from 'react';
import { isUsingChartType } from 'shared/wizard/utils/seriesHelper';
import PanelContainer from 'shared/editor/generic/PanelContainer';

type DataProps = { active: boolean; aggregatedOptions: unknown };

export default function Data(props: DataProps) {
  const { aggregatedOptions } = props;
  const isWordcloud = isUsingChartType(aggregatedOptions, 'wordcloud');

  return (
    <PanelContainer active={props.active} className="pt-6">
      <DataLeftSection isWordcloud={isWordcloud} inEditor={true} />
    </PanelContainer>
  );
}
