import initialState from '../../../redux/reducers/initial-state';
import actionTypes from '../../../redux/actions/action-types';

const plansPageReducer = (state = initialState.plansPage, action) => {
  switch (action.type) {
    case actionTypes.plansPage.set:
      return {
        ...state,
        ...action.data
      };
  }
  return state;
};

export default plansPageReducer;
