import React from 'react';
import Tooltip from 'shared/tooltip/Tooltip';

export type ButtonContainerProps = {
  children: React.ReactNode;
  tooltipText?: string;
  className?: string;
};

export default function ButtonContainer(props: ButtonContainerProps) {
  if (props.tooltipText) {
    return (
      <Tooltip
        tippyProps={{
          content: props.tooltipText,
          placement: 'left'
        }}
      >
        <div className={props.className}>{props.children}</div>
      </Tooltip>
    );
  }

  return <>{props.children}</>;
}
