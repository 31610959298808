import qs from 'qs';
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { login } from '../../api/cloudApiGenerated';
import Button from '../../shared/buttons/Button';
import { handleGoogleTagManagerEvent } from 'shared/utils/googleTagManagerHelper';
import paths from 'paths';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import AlertMessage from 'shared/message/AlertMessage';
import TextInput from 'shared/inputs/TextInput';

type LoginPageProps = {
  anonymous: boolean;
  redirect: string;
  onLoggedIn: (arg: boolean, announcements: any) => void;
  history: any;
};

type LocationStateProps = {
  overrideRedirect: boolean;
  from: string;
};

const LoginPage = (props: LoginPageProps) => {
  const { redirect, onLoggedIn, anonymous, history } = props;

  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { team } = useSelector((state: RootState) => state.profile);

  const [state, setState] = useState({
    failure: false,
    shouldRedirect: false,
    resetted: false,
    ref: false
  });

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    setState((prevState) => ({
      ...prevState,
      shouldRedirect: 'r' in query,
      resetted: Object.prototype.hasOwnProperty.call(query, 'reset'),
      ref: Object.prototype.hasOwnProperty.call(query, 'ref')
    }));

    if ('r' in query && team) {
      history.push({
        pathname: paths.profileCharts
      });
    }
  }, [location.search, team]);

  useEffect(() => {
    const newTeam = team;
    if (anonymous) return;
    if (newTeam && newTeam.id && state.shouldRedirect) {
      history.push({
        pathname: paths.profileCharts
      });
    }
  }, [anonymous, team, state.shouldRedirect]);

  const onLogin = (event: React.FormEvent) => {
    event.preventDefault();

    login(email, password)
      .then((data) => {
        handleGoogleTagManagerEvent({
          event: 'login',
          loginMethod: 'email'
        });

        onLoggedIn(false, data?.announcements);

        const overrideRedirect = (location?.state as LocationStateProps)?.overrideRedirect;

        if (overrideRedirect) {
          history.push({
            pathname: redirect
          });
        } else if (
          state.ref ||
          state.shouldRedirect ||
          redirect ||
          (location?.state as LocationStateProps)?.from === paths.frontPage
        ) {
          history.push({
            pathname: paths.profileCharts
          });
        } else {
          history.goBack();
        }
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          failure: true
        }));
      });
  };

  return (
    <div className="dark-layout login-page col-xs-12">
      <div className="page-inner-div">
        {state.resetted && (
          <div className="reset-trial-notice">
            <div className="header-text">Thank you for activating your 14 day free trial!</div>
            <div className="notice-text">
              Simply sign in with one of the options below to get started with all available features.
              <br className="hidden-xs hidden-sm hidden-md " />
              Don’t remember your password? Simply <NavLink to={paths.forgottenPassword}> click here </NavLink> to
              request a password change, and we&apos;ll send you a link to reset your password.
            </div>
          </div>
        )}

        <section className="page-section">
          <div className="page-form">
            <form id="login-form" role="form" method="post">
              <div className="page-div">
                <h3 className="my-8 font-bold">Sign in with email</h3>

                <TextInput
                  label="Email Address"
                  name="email"
                  type="email"
                  value={email}
                  placeholder="Email..."
                  onChange={(event) => setEmail(event.target.value)}
                  labelClass={' hidden-xs font-bold text-ev-navy-blue'}
                  inputClassName="highed-field-input"
                />

                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  value={password}
                  placeholder="Password..."
                  onChange={(event) => setPassword(event.target.value)}
                  labelClass={' hidden-xs font-bold mt-2 text-ev-navy-blue'}
                  inputClassName="highed-field-input"
                />

                <p className="mt-2 text-sm font-medium">
                  <NavLink to={paths.forgottenPassword}>Forgot password?</NavLink>
                </p>
                <div className="btn-page-div">
                  <Button
                    buttonText="Sign in"
                    buttonClass="bulk-action bulk-action-btn"
                    type="submit"
                    onClick={onLogin}
                  />
                </div>

                <p className="text-center font-medium">
                  <NavLink to={paths.sso}> Sign in with Single Sign On</NavLink>
                </p>
                <p className="text-center">
                  Don&rsquo;t have an account?{' '}
                  <NavLink className={'font-medium'} to={paths.signup}>
                    Register here
                  </NavLink>
                </p>
              </div>
            </form>

            <div className="flex items-center px-8 pb-4">
              <hr className="flex-grow border border-ev-navy-blue" />
              <span className="mx-2 text-ev-navy-blue text-base">OR</span>
              <hr className="flex-grow border border-ev-navy-blue" />
            </div>

            <div className="flex items-center justify-center pb-8">
              <a className="btn-google rounded" href={paths.oauthSignup}>
                <img src="/static/images/btn_google_dark_normal_ios.svg" alt="google_auth" className="rounded" />
                <span> Sign in with Google </span>
              </a>
            </div>
          </div>
          <div className="m-4">
            {state.failure && (
              <AlertMessage
                hideTag={true}
                type={'danger'}
                text={
                  <>
                    <strong>Oops!</strong> The email or password is incorrect.
                  </>
                }
                dismissable={false}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default LoginPage;
