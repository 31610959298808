export const copyTextToClipboard = (text, noAlert) => {
  let textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    let successful = document.execCommand('copy');
    document.body.removeChild(textArea);
    if (!noAlert) alert(successful ? 'Copied to clipboard!' : 'Something went wrong...');
  } catch (err) {
    document.body.removeChild(textArea);
  }
};
