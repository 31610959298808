import { TemplateProps } from '../types';

const Categories: TemplateProps = {
  title: 'Categories',
  description: [
    'Similar to the Choroplketh, except that the geographical regions colors are split based on specific ranges.'
  ],
  constructor: 'Map',
  thumbnail: 'us-election.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {},
    mapNavigation: {
      enabled: true
    },
    plotOptions: {
      series: {
        borderWidth: 1,
        lineWidth: 1
      }
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },
    colorAxis: {
      dataClasses: [
        {
          from: -86.78,
          to: 0,
          color: '#FF495E',
          name: 'Red'
        },
        {
          from: 0,
          to: 46.3,
          color: '#28277E',
          name: 'Blue'
        }
      ]
    }
  },
  dataValidator: false,
  sampleSet: 'category',
  advancedParent: 'Categories',
  defaultDataType: 'categoriesMap',
  isMap: true,
  basic: {
    order: 2,
    thumbnail: 'categories_map.svg',
    icon: 'Categories'
  }
};

export default Categories;
