import React from 'react';

type InputErrorProps = {
  error: string;
};

const InputError = ({ error }: InputErrorProps) => {
  return error ? <span className="block pt-1 text-ev-error">{error}</span> : <></>;
};

export default InputError;
