import actionTypes from './action-types';

export const setAction = (data) => ({
  type: actionTypes.projectConfig.set,
  data
});
export const updateDataAndConfigAction = (data) => ({
  type: actionTypes.projectConfig.updateDataAndConfig,
  data
});
export const updateDataAndConfigBulkAction = (data) => ({
  type: actionTypes.projectConfig.updateDataAndConfigBulk,
  data
});
export const movedColumnsAction = (data) => ({
  type: actionTypes.projectConfig.movedColumns,
  data
});
export const movedRowsAction = (data) => ({
  type: actionTypes.projectConfig.movedRows,
  data
});

export const handleDeletedColumnAction = (data) => ({
  type: actionTypes.projectConfig.handleDeletedColumn,
  data
});

export const clearColumnAction = (data) => ({
  type: actionTypes.projectConfig.clearColumn,
  data
});

export const clearDataGridAction = (data) => ({
  type: actionTypes.projectConfig.clearDataGrid,
  data
});
export const deleteSeriesAction = (data) => ({
  type: actionTypes.projectConfig.deleteSeries,
  data
});
export const addDataSeriesAction = (data) => ({
  type: actionTypes.projectConfig.addDataSeries,
  data
});

export const sortDataAction = (data) => ({
  type: actionTypes.projectConfig.sortData,
  data
});

export const resetProjectConfigAction = (data) => ({
  type: actionTypes.projectConfig.reset,
  data
});

export const resetThemeAction = (data) => ({
  type: actionTypes.projectConfig.resetTheme,
  data
});

export const registerChangeMadeAction = (data) => ({
  type: actionTypes.projectConfig.registerChangeMade,
  data
});

export const deleteColumnFromOptionsAction = (data) => ({
  type: actionTypes.projectConfig.deleteColumnFromOptions,
  data
});

export const unsetChangeMadeAction = (data) => ({
  type: actionTypes.projectConfig.unsetChangeMade,
  data
});

export const toggleInspiredAction = (data) => ({
  type: actionTypes.projectConfig.toggleInspired,
  data
});

export const toggleUnsavedChangesModalAction = (data) => ({
  type: actionTypes.projectConfig.toggleUnsavedChangesModal,
  data
});

export const unpublishAction = (data) => ({
  type: actionTypes.projectConfig.unpublish,
  data
});

export const toggleUnpublishModalAction = (data) => ({
  type: actionTypes.projectConfig.toggleUnpublishModal,
  data
});

export const showSignupModalAction = (data) => ({
  type: actionTypes.projectConfig.showSignupModal,
  data
});

export const getThemesAction = (data) => ({
  type: actionTypes.projectConfig.getThemes,
  data
});

export const getCompanyThemesAction = (data) => ({
  type: actionTypes.projectConfig.getCompanyThemes,
  data
});

export const changeProjectNameAction = (data) => ({
  type: actionTypes.projectConfig.changeProjectName,
  data
});

export const updateSeriesMappingAction = (data) => ({
  type: actionTypes.projectConfig.updateSeriesMapping,
  data
});

export const updateCSSModulesAction = (data) => ({
  type: actionTypes.projectConfig.updateCSSModules,
  data
});

export const closeWizardAction = (data) => ({
  type: actionTypes.projectConfig.closeWizard,
  data
});

export const assignCompanyThemeAction = (data) => ({
  type: actionTypes.projectConfig.assignCompanyTheme,
  data
});
