import { connect } from 'react-redux';
import CustomiseEditorPage from '../CustomiseEditorPage';
import {
  setAction,
  getEditorConfigAction,
  getUserEditorConfigAction,
  setUserEditorConfigAction,
  getLogosAction,
  getFontsAction,
  deleteFontAction,
  deleteLogoAction
} from '../actions/customiseEditorPage.js';
import { checkPermission, checkPlanPermission } from '../../../utils/profileHelper';
import { openPaidPlanModalAction } from '../../../redux/actions/app';

const mapStateToProps = (state) => {
  return {
    editorFeatures: state.customiseEditorPage.editorFeatures,
    features: state.customiseEditorPage.features,
    hideDefaultThemes: state.customiseEditorPage.hideDefaultThemes,
    allOptions: state.customiseEditorPage.allOptions || [],
    userOptions: state.customiseEditorPage.userOptions || [],
    error: state.customiseEditorPage.error,
    paths: state.app.paths || {},
    team: state.profile.team,
    division: state.profile.division,
    showDeleteFontModal: state.customiseEditorPage.showDeleteFontModal,
    showDeleteLogoModal: state.customiseEditorPage.showDeleteLogoModal,
    fonts: state.customiseEditorPage.fonts,
    logos: state.customiseEditorPage.logos,
    selectedFont: state.customiseEditorPage.selectedFont,
    selectedLogo: state.customiseEditorPage.selectedLogo,
    checkPermission: (permission) => checkPermission(permission, state.profile),
    checkPlanPermission: (permission) => checkPlanPermission(permission, state.profile)
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserEditorConfig: (team, division, editorFeatures, features) =>
    dispatch(getUserEditorConfigAction({ team, division, editorFeatures, features })),
  getEditorConfig: (team, division, editorFeatures, features) =>
    dispatch(getEditorConfigAction({ team, division, editorFeatures, features })),
  setUserEditorConfig: (results, editorFeatures, features, hideDefaultThemes, division, team) =>
    dispatch(setUserEditorConfigAction({ hideDefaultThemes, results, team, division, editorFeatures, features })),
  getFonts: (team) => dispatch(getFontsAction({ team })),
  getLogos: (team) => dispatch(getLogosAction({ team })),
  deleteFont: (team, selectedFont) => dispatch(deleteFontAction({ team, selectedFont })),
  deleteLogo: (team, selectedLogo) => dispatch(deleteLogoAction({ team, selectedLogo })),
  set: (params) => dispatch(setAction(params)),
  showPaidPlanModal: (plan) =>
    dispatch(
      openPaidPlanModalAction({
        paidPlanType: plan
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomiseEditorPage);
