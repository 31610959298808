import React from 'react';
import PropTypes from 'prop-types';
import { snackBar } from 'editor/editors/highed.init';
import ErrorMessage from '../../shared/message/ErrorMessage';
import MultipleSelectFields from '../../shared/MultipleSelectFields';
import Button from '../../shared/buttons/Button';
import CheckboxInput from '../../shared/inputs/CheckboxInput';
import Select from '../../shared/inputs/Select';
import FontModal from '../../shared/editor/FontModal';
import LogoModal from '../../shared/editor/LogoModal';
import { copyTextToClipboard } from '../../utils/inputHelper';
import Modal from 'shared/modal/Modal';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import TextButton from 'shared/buttons/TextButton';
import { goToPage } from 'utils/profileHelper';
import PremiumFeature from 'shared/message/PremiumFeature';

class CustomiseEditorPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
    this.onChangeFeaturePermissions = this.onChangeFeaturePermissions.bind(this);
    this.onChangePermissions = this.onChangePermissions.bind(this);
    this.loadEditorConfig = this.loadEditorConfig.bind(this);
    this.setHideDefaultThemes = this.setHideDefaultThemes.bind(this);
    this.getFonts = this.getFonts.bind(this);
    this.handleFontsChange = this.handleFontsChange.bind(this);
    this.openAddFontModal = this.openAddFontModal.bind(this);
    this.openEditFontModal = this.openEditFontModal.bind(this);
    this.openDeleteFontModal = this.openDeleteFontModal.bind(this);
    this.closeDeleteFontModal = this.closeDeleteFontModal.bind(this);
    this.deleteFont = this.deleteFont.bind(this);
    this.openAddLogoModal = this.openAddLogoModal.bind(this);
    this.getLogos = this.getLogos.bind(this);
    this.deleteLogo = this.deleteLogo.bind(this);
    this.openDeleteLogoModal = this.openDeleteLogoModal.bind(this);
    this.closeDeleteLogoModal = this.closeDeleteLogoModal.bind(this);
    this.handleLogosChange = this.handleLogosChange.bind(this);
    this.copyText = this.copyText.bind(this);

    this.fontModal = React.createRef();
    this.logoModal = React.createRef();
  }

  componentDidMount() {
    this.props.getEditorConfig(this.props.team, this.props.division, this.props.editorFeatures, this.props.features);
    this.loadEditorConfig();
    this.props.getFonts(this.props.team);
    this.props.getLogos(this.props.team);
  }

  loadEditorConfig() {
    this.props.getUserEditorConfig(
      this.props.team,
      this.props.division,
      this.props.editorFeatures,
      this.props.features
    );
  }

  componentDidUpdate(prevProps) {
    const isDifferentTeam = this.props.team && (!prevProps.team || prevProps.team.id !== this.props.team.id);
    const isDifferentDivision =
      this.props.division && (!prevProps.division || prevProps.division.id !== this.props.division.id);

    if (isDifferentTeam || isDifferentDivision) {
      this.loadEditorConfig(this.props.team);
      this.props.getFonts(this.props.team);
    }
  }

  getFonts(team) {
    this.props.getFonts(team || this.props.team);
  }

  setHideDefaultThemes(event) {
    let hideDefaultThemes = this.props.hideDefaultThemes;
    hideDefaultThemes = event.target.checked;
    this.props.set({
      hideDefaultThemes
    });
  }

  onClick(results) {
    this.props.setUserEditorConfig(
      results,
      this.props.editorFeatures,
      this.props.features,
      this.props.hideDefaultThemes,
      this.props.division,
      this.props.team
    );
  }

  onChangePermissions(event) {
    let editorFeatures = this.props.editorFeatures;
    editorFeatures[event.target.name].checked = event.target.checked;
    this.props.set({
      editorFeatures
    });
  }

  onChangeFeaturePermissions(event) {
    let features = this.props.features;

    features.forEach((f) => {
      if (f[event.target.name]) f[event.target.name].checked = event.target.checked;
    });

    this.props.set({
      features
    });
  }

  handleLogosChange(val) {
    this.props.set({
      selectedLogo: val
    });
  }

  handleFontsChange(val) {
    this.props.set({
      selectedFont: val
    });
  }

  openAddFontModal() {
    if (!this.props.checkPlanPermission('custom_fonts')) {
      this.props.showPaidPlanModal();
      return;
    }
    this.props.set({
      showFontModal: {
        type: 'Add'
      }
    });
  }

  openEditFontModal() {
    if (!this.props.checkPlanPermission('custom_fonts')) {
      this.props.showPaidPlanModal();
      return;
    }
    this.props.set({
      showFontModal: {
        type: 'Edit'
      }
    });
  }

  openDeleteFontModal() {
    if (!this.props.checkPlanPermission('custom_fonts')) {
      this.props.showPaidPlanModal();
      return;
    }
    this.props.set({
      showDeleteFontModal: true
    });
  }

  closeDeleteFontModal() {
    this.props.set({
      showDeleteFontModal: false
    });
  }

  deleteFont() {
    this.props.deleteFont(this.props.team, this.props.selectedFont);
  }

  deleteLogo() {
    this.props.deleteLogo(this.props.team, this.props.selectedLogo);
  }

  getLogos(team) {
    this.props.getLogos(team || this.props.team);
  }
  openAddLogoModal() {
    if (!this.props.checkPlanPermission('custom_logo')) {
      this.props.showPaidPlanModal();
      return;
    }
    this.logoModal.current.show();
  }

  openDeleteLogoModal() {
    if (!this.props.checkPlanPermission('custom_logo')) {
      this.props.showPaidPlanModal();
      return;
    }
    this.props.set({
      showDeleteLogoModal: true
    });
  }

  closeDeleteLogoModal() {
    this.props.set({
      showDeleteLogoModal: false
    });
  }

  copyText() {
    let text = 'https://app.everviz.com/logos/' + this.props.selectedLogo.uuid + '/logo.png';
    copyTextToClipboard(text);
  }

  render() {
    const editorFeatures = this.props.editorFeatures || {};
    const features = this.props.features || [];
    //TODO: Refactor all the font/logo modals
    return (
      <ProfilePageContainer
        title="Customize editor"
        header={<>Customize editor</>}
        description={
          <>
            Configure your own everviz charting editor, that only displays the options you really need.{' '}
            <TextButton
              onClick={() => goToPage('https://help.everviz.com/article/826-customize-editor')}
              text="More information about customizing the editor can be found in our knowledge base."
            />
          </>
        }
      >
        <ProfilePageSectionContainer>
          <div className="team-groups-page">
            <ErrorMessage error={this.props.error} />

            <Modal
              title={'Delete logo'}
              width={'w-5/12 max-w-xl'}
              bg={'bg-ev-yellow-1'}
              isOpen={this.props.showDeleteLogoModal}
              onClose={this.closeDeleteLogoModal}
            >
              <p>Are you sure you want to delete this logo?</p>

              <hr />
              <div className="pt-6 flex gap-2">
                <PrimaryButton onClick={this.deleteLogo} text="Confirm" />
                <PrimaryButton onClick={this.closeDeleteLogoModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
              </div>
            </Modal>

            <Modal
              title={'Delete font'}
              width={'w-5/12 max-w-xl'}
              bg={'bg-ev-yellow-1'}
              isOpen={this.props.showDeleteFontModal}
              onClose={this.closeDeleteFontModal}
            >
              <p>Are you sure you want to delete this font?</p>
              <hr />
              <div className="pt-6 flex gap-2">
                <PrimaryButton onClick={this.deleteFont} text="Confirm" />
                <PrimaryButton onClick={this.closeDeleteFontModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
              </div>
            </Modal>

            <FontModal
              ref={this.fontModal}
              team={this.props.team}
              snackBar={snackBar}
              refreshFonts={this.getFonts}
              selectedFont={this.props.selectedFont}
            />

            <LogoModal
              ref={this.logoModal}
              team={this.props.team}
              snackBar={snackBar}
              refreshLogos={this.getLogos}
              selectedLogo={this.props.selectedLogo}
            />
            <section>
              <div className="editor-features">
                <p className="enable-tabs-header">Enable tabs</p>
                <div className="editor-feature-container ml-4 mb-4">
                  {features.map((e, i) => {
                    return (
                      <div key={i} className="div-row">
                        {Object.keys(e).map((f, x) => {
                          return (
                            <CheckboxInput
                              label={e[f].text}
                              type="checkbox"
                              name={f}
                              key={x}
                              checked={e[f].checked}
                              onChange={this.onChangeFeaturePermissions}
                              className="mt-1"
                            />
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div className="editor-features-container">
                  <div className="div-row">
                    {Object.keys(editorFeatures).map((e, i) => {
                      return (
                        <CheckboxInput
                          key={i}
                          label={editorFeatures[e].text}
                          type="checkbox"
                          name={e}
                          checked={editorFeatures[e].checked}
                          onChange={this.onChangePermissions}
                          className="mt-1"
                        />
                      );
                    })}
                  </div>
                </div>

                <div className="div-row">
                  <CheckboxInput
                    label={'Hide standard themes'}
                    type="checkbox"
                    name={'hide_default_themes'}
                    checked={this.props.hideDefaultThemes}
                    onChange={this.setHideDefaultThemes}
                    className="mt-1"
                  />
                </div>
              </div>

              <div className="custom-fonts-container">
                <PremiumFeature />
                <div className="div-row custom-fonts-row mt-8">
                  <div className="enable-tabs-header custom-fonts-header">Custom fonts</div>
                  <div className="custom-font-inputs flex">
                    <Button
                      icon={'fa fa-plus'}
                      extraClass="bulk-action-btn add-custom-font-btn"
                      onClick={this.openAddFontModal}
                      buttonText={''}
                    />

                    <Select
                      options={this.props.fonts}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleFontsChange}
                      value={this.props.selectedFont}
                      placeholder={'Custom Fonts'}
                    />

                    {this.props.selectedFont && (
                      <span>
                        <Button
                          buttonText={'Edit'}
                          extraClass="bulk-action-btn edit-custom-font-btn"
                          onClick={this.openEditFontModal}
                        />

                        <Button
                          buttonText={'Delete'}
                          extraClass="bulk-action-btn edit-custom-font-btn danger"
                          onClick={this.openDeleteFontModal}
                        />
                      </span>
                    )}
                  </div>
                </div>

                <div className="div-row custom-fonts-row add-logo-container mt-8">
                  <div className="enable-tabs-header custom-fonts-header">Logo</div>
                  <div className="custom-font-inputs">
                    <div className="flex">
                      <Button
                        icon={'fa fa-plus'}
                        extraClass="bulk-action-btn add-custom-font-btn"
                        onClick={this.openAddLogoModal}
                        buttonText={''}
                      />

                      <div className="w-full">
                        <Select
                          options={this.props.logos}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                          onChange={this.handleLogosChange}
                          value={this.props.selectedLogo}
                          placeholder={'Add logo'}
                        />
                      </div>

                      {this.props.selectedLogo && (
                        <span>
                          <Button
                            buttonText={'Delete'}
                            extraClass="bulk-action-btn edit-custom-font-btn danger"
                            onClick={this.openDeleteLogoModal}
                          />
                        </span>
                      )}
                    </div>

                    {this.props.selectedLogo && (
                      <div className="link">
                        <i onClick={this.copyText} className="fa fa-files-o clickable" aria-hidden="true" /> &nbsp;
                        https://app.everviz.com/logos/{this.props.selectedLogo.uuid}/logo.png
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.props.allOptions && (
                <div className="multiple-select-container">
                  <MultipleSelectFields
                    options={this.props.allOptions}
                    userOptions={this.props.userOptions}
                    onClick={this.onClick}
                  />
                </div>
              )}
            </section>
          </div>
        </ProfilePageSectionContainer>
      </ProfilePageContainer>
    );
  }
}

CustomiseEditorPage.propTypes = {
  team: PropTypes.object.isRequired,
  division: PropTypes.object
};

export default CustomiseEditorPage;
