import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import paths from 'paths';
import WelcomeImage from 'static/images/welcome-card.svg';
import DOMPurify from 'dompurify';

type WelcomeCardProps = {
  label?: string;
  labelClass?: string;
  message?: string;
  wrapClass?: string;
  imgClass?: string;
};

const WelcomeCard: FC<WelcomeCardProps> = ({
  label = '🌟 Welcome to everviz! 📈',
  labelClass,
  message = `We’re glad you’re here. Dive right in and create your first chart.</br></br>
            If you need help, either find us on the everviz forum or check out the
            Knowledge Base by clicking the question mark (?) on the bottom-right.`,
  wrapClass,
  imgClass
}) => {
  return (
    <div
      className={`grid gap-2 ev-md:grid-cols-3 h-full py-4 px-6 ev-md:py-8 ev-md:px-12 bg-white shadow-sm rounded-md col-span-1 ev-md:col-span-3  ${wrapClass}`}
    >
      <div className="flex flex-col ev-md:col-span-2">
        {label && (
          <h1 className={`text-2xl ev-md:text-4xl font-bold mb-8 text-ev-navy-blue-2 ${labelClass}`}> {label} </h1>
        )}
        <div className="flex flex-col justify-between h-full">
          {message && (
            <p
              className="font-medium text-ev-navy-blue-2"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
            />
          )}
          <NavLink to={paths.newChart} className="w-fit rounded-full">
            <PrimaryButton text="Create my first chart" />
          </NavLink>
        </div>
      </div>

      <div className="relative min-h-[250px]">
        <WelcomeImage className={`absolute top-0 scale-75 translate-x-1/2 right-1/2 mt-3 ev-md:mt-0 ${imgClass}`} />
      </div>
    </div>
  );
};

export default WelcomeCard;
