import actionTypes from '../../../redux/actions/action-types';

export const getPublishedChartsAction = (data) => ({
  type: actionTypes.layoutEditor.getPublishedCharts,
  data
});

export const setAction = (data) => ({
  type: actionTypes.layoutEditor.set,
  data
});

export const setPageTemplateAction = (data) => ({
  type: actionTypes.layoutEditor.setPageTemplate,
  data
});

export const updatePageTemplateAction = (data) => ({
  type: actionTypes.layoutEditor.updatePageTemplate,
  data
});

export const setResolutionAction = (data) => ({
  type: actionTypes.layoutEditor.setResolution,
  data
});

export const saveLayoutAction = (data) => ({
  type: actionTypes.layoutEditor.saveLayout,
  data
});

export const loadLayoutAction = (data) => ({
  type: actionTypes.layoutEditor.loadLayout,
  data
});

export const toggleChartModalAction = (data) => ({
  type: actionTypes.layoutEditor.toggleChartModal,
  data
});

export const addChartToGridAction = (data) => ({
  type: actionTypes.layoutEditor.addChartToGrid,
  data
});

export const addImageToGridAction = (data) => ({
  type: actionTypes.layoutEditor.addImageToGrid,
  data
});
export const addToGridAction = (data) => ({
  type: actionTypes.layoutEditor.addToGrid,
  data
});
export const changeTitleTextAction = (data) => ({
  type: actionTypes.layoutEditor.changeTitleText,
  data
});
export const updateCustomizedPropAction = (data) => ({
  type: actionTypes.layoutEditor.updateCustomizedProp,
  data
});
export const updateDataPropAction = (data) => ({
  type: actionTypes.layoutEditor.updateDataProp,
  data
});
export const openPublishModalAction = (data) => ({
  type: actionTypes.layoutEditor.openPublishModal,
  data
});
export const closePublishModalAction = (data) => ({
  type: actionTypes.layoutEditor.closePublishModal,
  data
});
export const publishLayoutAction = (data) => ({
  type: actionTypes.layoutEditor.publishLayout,
  data
});
export const assignThemeAction = (data) => ({
  type: actionTypes.layoutEditor.assignTheme,
  data
});
export const removeChartFromDataOptionsAction = (data) => ({
  type: actionTypes.layoutEditor.removeChartFromDataOptions,
  data
});

export const getFontsAction = (data) => ({
  type: actionTypes.layoutEditor.getFonts,
  data
});

export const getLogosAction = (data) => ({
  type: actionTypes.layoutEditor.getLogos,
  data
});

export const resetStateAction = (data) => ({
  type: actionTypes.layoutEditor.resetState,
  data
});

export const toggleCMSModalAction = (data) => ({
  type: actionTypes.layoutEditor.toggleCMSModal,
  data
});

export const redrawProjectAction = (data) => ({
  type: actionTypes.layoutEditor.redrawProject,
  data
});

export const initEditorAction = (data) => ({
  type: actionTypes.layoutEditor.initEditor,
  data
});

export const removeAction = (data) => ({
  type: actionTypes.layoutEditor.remove,
  data
});
