import React, { useState } from 'react';
import qs from 'qs';
import Modal from 'react-modal';
import VizCard, { VizCardSize } from './components/VizCard';

import ChartIcon from '../../shared/wizard/Icons/ChartIcon';
import MapIcon from '../../shared/wizard/Icons/MapIcon';
import LayoutIcon from '../../shared/wizard/Icons/LayoutIcon';
import TableIcon from '../../shared/wizard/Icons/TableIcon';
import SmallScreenMessage from '../../shared/message/SmallScreenMessage';

type VizPickerProps = {
  team: any;
  paths: any;
};

export default function VizPicker(props: VizPickerProps) {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [isOpen, setIsOpen] = useState(Boolean(query.cms));
  const { team, paths } = props;

  return (
    <div className="content-centered flex-col">
      <Modal
        className="hc-modal-content hc-viz-picker-modal"
        overlayClassName="hc-modal-overlay"
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        appElement={document.querySelector('.page-container') as HTMLElement}
        contentLabel=""
      >
        <div className="viz-picker-modal-header">
          Welcome to everviz
          <div className="viz-picker-modal-close" onClick={() => setIsOpen(false)}>
            <i className="fa fa-times" />
          </div>
        </div>
        <div className="viz-picker-modal-text-container">
          <div className="viz-picker-modal-text-header">
            You are now ready to create <br /> your data visualization!
          </div>
          <div className="viz-picker-modal-text">
            After saving you will be able to find and embed your <br /> project using the wordpress plugin.
          </div>
        </div>
      </Modal>

      <div className="pt-8 ev-sm:pt-14 ev-md:pt-16 pb-4 ev-sm:pb-6 sm:mt-28 mt-10 text-center">
        <h1 className="pl-0 font-bold m-0 ">Select your project type</h1>
      </div>

      <div className="grid grid-cols-2 ev-md:grid-cols-4 gap-8 ev-md:max-w-screen-md mt-14">
        <VizCard size={VizCardSize.Large} path={paths.newChart} title={'Chart'} icon={<ChartIcon />} />

        <VizCard size={VizCardSize.Large} path={paths.newChart + '?maps'} title={'Map'} icon={<MapIcon />} />

        <VizCard
          size={VizCardSize.Large}
          path={team ? paths.createTable : '/new'}
          title={'Table'}
          icon={<TableIcon />}
          disabled={!team}
          disabledText="Login to create a new table"
          iconClass={'pt-4'}
        />

        <VizCard
          size={VizCardSize.Large}
          path={team ? paths.createLayout : '/new'}
          title={'Layout'}
          icon={<LayoutIcon />}
          disabled={!team}
          disabledText="Login to create a new layout"
        />
      </div>

      <SmallScreenMessage />
    </div>
  );
}
