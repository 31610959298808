import actionTypes from '../../../redux/actions/action-types';

export const getTeamThemes = (data) => ({
  type: actionTypes.themesPage.getTeamThemes,
  data
});

export const setAction = (data) => ({
  type: actionTypes.themesPage.set,
  data
});

export const getCompanyThemeAction = (data) => ({
  type: actionTypes.themesPage.getCompanyTheme,
  data
});

export const updateThemeDataAction = (data) => ({
  type: actionTypes.themesPage.updateThemeData,
  data
});
