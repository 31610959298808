import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Boxplot: TemplateProps = {
  title: 'Boxplot',
  description: '',
  constructor: '',
  thumbnail: 'idagib.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'boxplot'
    },
    yAxis: {
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'boxplot',
  advancedParent: 'Boxplot',
  dataValidator: false,
  basic: {
    order: 22,
    thumbnail: 'box_plot.svg',
    icon: 'Boxplot'
  },
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter)
};

export default Boxplot;
