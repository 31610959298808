import React from 'react';
import Accordian from 'shared/Accordion';
import IconButton from 'shared/buttons/IconButton';
import { get } from 'lodash';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default (props: GenericInputProps) => {
  const { aggregatedOptions, option } = props;
  const { type } = useSelector((state: RootState) => state.projectConfig);
  const placement = option?.custom?.placement ?? {
    topLeft: 1,
    bottomLeft: 1,
    topCenter: 1,
    bottomCenter: 1,
    topRight: 1,
    bottomRight: 1
  };

  const capitalizeFirstLetter = (string: string) => (string ?? '').charAt(0).toUpperCase() + (string ?? '').slice(1);

  const extractValue = (aggregatedOptions: unknown, defaultVal?: unknown) => {
    const { option } = props;
    let horizontal = '';
    let vertical = '';

    if (type === 'chart') {
      horizontal = get(aggregatedOptions, `${option?.id ?? ''}`) ?? defaultVal;
      vertical = get(aggregatedOptions, `${(option?.id ?? '').replace('.align', '.verticalAlign')}`) ?? defaultVal;
    } else {
      const val = get(aggregatedOptions, `${option?.id ?? ''}`) ?? defaultVal;
      const splitVal = val.split(/(?=[A-Z])/);
      horizontal = (splitVal?.[1] ?? '').toLowerCase();
      vertical = splitVal?.[0] ?? '';
    }

    return [vertical, horizontal];
  };

  const onSelect = (vVal: string, hVal: string) => {
    const { option } = props;

    if (type === 'chart') {
      const id = option?.id ?? '';
      const verticalId = id.replace('.align', '.verticalAlign');
      const aliasKey = option?.aliasKey ?? [];
      const verticalRawKey = (aliasKey as string[]).map((key) => key.replace('.align', '.verticalAlign'));

      props.onChange({ val: hVal, optional: { ...option, id, aliasKey: aliasKey } });
      props.onChange({
        val: vVal,
        optional: { ...option, id: verticalId, aliasKey: verticalRawKey }
      });
    } else {
      props.onChange({ val: vVal + capitalizeFirstLetter(hVal) });
    }
  };

  const alignValues = extractValue(aggregatedOptions, option?.default);

  return (
    <Accordian
      key={'key'}
      defaultSelection={false}
      headerText={props.label}
      smallHeaderText={true}
      selectedChildren={`${capitalizeFirstLetter(alignValues[0])} ${alignValues[1]}`}
      useInternalSelect={true}
      accordionClasses={props.className ?? 'bg-white'}
    >
      <div>
        {placement.topLeft && (
          <IconButton
            icon="arrow-up-left"
            className="w-8 h-8 mx-1 rounded "
            onClick={() => {
              onSelect('top', 'left');
            }}
            active={alignValues[0] === 'top' && alignValues[1] === 'left'}
          />
        )}

        {placement.topCenter && (
          <IconButton
            icon="arrow-up"
            className="w-8 h-8 mx-1 rounded "
            onClick={() => onSelect('top', 'center')}
            active={alignValues[0] === 'top' && alignValues[1] === 'center'}
          />
        )}

        {placement.topRight && (
          <IconButton
            icon="arrow-up-right"
            className="w-8 h-8 mx-1 rounded "
            onClick={() => onSelect('top', 'right')}
            active={alignValues[0] === 'top' && alignValues[1] === 'right'}
          />
        )}
      </div>
      <div>
        {placement.bottomLeft && (
          <IconButton
            icon="arrow-down-left"
            className="w-8 h-8 mx-1 rounded "
            onClick={() => onSelect('bottom', 'left')}
            active={alignValues[0] === 'bottom' && alignValues[1] === 'left'}
          />
        )}

        {placement.bottomCenter && (
          <IconButton
            icon="arrow-down"
            className="w-8 h-8 mx-1 rounded "
            onClick={() => onSelect('bottom', 'center')}
            active={alignValues[0] === 'bottom' && alignValues[1] === 'center'}
          />
        )}

        {placement.bottomRight && (
          <IconButton
            icon="arrow-down-right"
            className="w-8 h-8 mx-1 rounded "
            onClick={() => onSelect('bottom', 'right')}
            active={alignValues[0] === 'bottom' && alignValues[1] === 'right'}
          />
        )}
      </div>
    </Accordian>
  );
};
