import { assignThemeAction, redrawProjectAction } from 'pages/ChartEditorPage/actions/chartEditor';
import {
  redrawTableAction,
  assignThemeAction as assignTableThemeAction
} from 'pages/TableEditorPage/actions/tableEditor';

import { assignThemeAction as assignLayoutThemeAction } from 'pages/LayoutEditorPage/actions/layoutEditor';

import TableDefaultThemes from '../../pages/TableEditorPage/meta/DefaultThemes';
import LayoutDefaultThemes from '../../pages/LayoutEditorPage/meta/DefaultThemes';
import EditorDefaultThemes from '../editor/DefaultThemes';

export const defaultThemeMap = {
  chart: EditorDefaultThemes,
  layout: LayoutDefaultThemes,
  table: TableDefaultThemes
};

export const redrawProjectMap = {
  chart: redrawProjectAction,
  table: redrawTableAction,
  layout: redrawTableAction
};

export const assignThemeMap = {
  chart: assignThemeAction,
  table: assignTableThemeAction,
  layout: assignLayoutThemeAction
};
