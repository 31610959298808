import { TemplateProps } from '../../types';

const BarWithLabels: TemplateProps = {
  title: 'Bar with labels',
  description: '',
  constructor: '',
  thumbnail: 'ovuvul.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column',
      inverted: true,
      polar: false
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true
        }
      }
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'bar',
  advancedParent: 'Stacked bar',
  advanced: {
    name: 'Stacked + label'
  },
  dataValidator: false
};

export default BarWithLabels;
