import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import Modal from 'shared/modal/Modal';
import { setTeamMembersAction, updateDivisionMembersAction } from '../actions/teamMembersAction';
import { RootState } from 'redux/store';

const UserSubteamRemoveModal = () => {
  const { team, divisions } = useSelector((state: RootState) => state.profile);
  const { subteamMembers, activeDivision, isUserSubteamRemoveModalOpen, selectedUser, membersQuery } = useSelector(
    (state: RootState) => state.teamMembersPage
  );
  const dispatch = useDispatch();

  const handleOnConfirm = () => {
    const subteamMembersData = subteamMembers.data;
    const validSubteamMembers = subteamMembersData.filter((member: any) => member.id !== selectedUser?.id);
    dispatch(
      updateDivisionMembersAction({
        team,
        divisions,
        membersQuery,
        page: subteamMembers.page,
        pageSize: subteamMembers.pageSize,
        subteamMembers: subteamMembersData,
        validSubteamMembers,
        activeDivision
      })
    );
  };

  const handleOnClose = () => {
    dispatch(setTeamMembersAction({ isUserSubteamRemoveModalOpen: false }));

    setTimeout(() => {
      dispatch(setTeamMembersAction({ selectedUser: null }));
    }, 150);
  };

  return (
    <Modal
      title="Remove member from subteam"
      width="w-7/12 max-w-xl"
      bg="bg-ev-yellow-1"
      onClose={handleOnClose}
      isOpen={isUserSubteamRemoveModalOpen}
    >
      <p className="mb-4">
        Are you sure you want to remove <b>{selectedUser?.username}</b> from <b>{activeDivision?.label}</b>?
      </p>
      <hr />
      <div className="mt-8 flex gap-4">
        <PrimaryButton onClick={handleOnConfirm} text="Confirm" />
        <PrimaryButton onClick={handleOnClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
      </div>
    </Modal>
  );
};

export default UserSubteamRemoveModal;
