import { connect } from 'react-redux';
import Customize from '../../../shared/editor/generic/Customize';
import {
  updateCustomizedPropAction,
  updateDataPropAction,
  toggleChartModalAction,
  getFontsAction,
  getLogosAction
} from '../actions/layoutEditor.js';
import { checkPermission } from '../../../utils/profileHelper';
import en from '../meta/en.js';

const mapStateToProps = (state) => {
  return {
    checkPermission: (permission) => checkPermission(permission, state.profile),
    customizedOptions: state.projectConfig.customizedOptions,
    dataOptions: state.projectConfig.dataOptions,
    templateUsed: state.layoutEditorPage.templateUsed,
    customFonts: state.layoutEditorPage.customFonts,
    team: state.profile.team,
    division: state.profile.division,
    logos: state.layoutEditorPage.logos,
    paths: state.app.paths || {},
    lang: en
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCustomizedProp: (optionProps, val, extraModulesToLoad, index) => {
    return dispatch(updateCustomizedPropAction({ optionProps, val, extraModulesToLoad, index }));
  },
  updateDataProp: (dataOptions, option, index, val) =>
    dispatch(updateDataPropAction({ dataOptions, option, index, val })),
  toggleChartModal: (parent) => dispatch(toggleChartModalAction(parent)),
  getFonts: (team) => dispatch(getFontsAction({ team })),
  getLogos: (team) => dispatch(getLogosAction({ team }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Customize);
