import { setAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { getChartConfig } from 'pages/ChartEditorPage/selectors/chartEditor';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerChangeMadeAction } from 'redux/actions/projectConfig';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import { onPublish } from 'shared/editor/publishHelper';
import CheckboxInput from 'shared/inputs/CheckboxInput';
import TextInput from 'shared/inputs/TextInput';
import LoaderIcon from 'shared/loader/LoaderIcon';

export default (props) => {
  const dispatch = useDispatch();
  const { passwordProtected } = useSelector(getChartConfig);
  const { published, publishLoading, changeMade } = useSelector(getProjectConfig);
  const { type } = props;
  const alreadyPublished = published && !changeMade;

  const publish = () => {
    if (alreadyPublished) return;
    onPublish(type, dispatch);
  };

  const onPasswordChange = (e) => {
    dispatch(
      setAction({
        passwordProtected: {
          enabled: true,
          password: e.target.value
        }
      })
    );
    dispatch(registerChangeMadeAction());
  };

  const onCheckboxChange = (e) => {
    dispatch(
      setAction({
        passwordProtected: {
          enabled: e.target.checked,
          password: e.target.value ? passwordProtected.password : ''
        }
      })
    );
    dispatch(registerChangeMadeAction());
  };

  return (
    <>
      <CheckboxInput
        name={'private'}
        label="Add password on share/embed"
        onChange={onCheckboxChange}
        checked={passwordProtected.enabled}
        extraClass="pt-4"
        tabIndex={props.active ? null : '-1'}
      />

      {passwordProtected.enabled && (
        <TextInput
          name="search"
          type="password"
          value={passwordProtected.password}
          onChange={onPasswordChange}
          placeholder="Enter password (minimum 8 characters)"
          disabled={!passwordProtected.enabled}
          inputClassName="highed-field-input"
          tabIndex={props.active ? null : '-1'}
          extraClass={'pb-0 pt-4 mb-2'}
        />
      )}
      {published && !changeMade && (
        <PrimaryButton
          onClick={publish}
          thin={true}
          text={
            <>
              <LoaderIcon
                icon={alreadyPublished ? 'circle-check' : 'key'}
                iconClass={alreadyPublished ? '' : 'rotate-[-45deg]'}
                loading={publishLoading}
              />
              &nbsp;{changeMade ? 'Apply' : 'Applied'}
            </>
          }
          buttonColor={alreadyPublished ? ButtonColor.White : ButtonColor.NavyBlue}
          buttonType={ButtonType.Info}
          className="mt-2 text-sm"
        />
      )}
    </>
  );
};
