import { isArray } from 'lodash';
import React from 'react';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { customStyles } from 'shared/utils/selectStylesHelper';
import { isObj } from '../../../editor/core/highcharts-editor';
import PrimaryButton from '../../buttons//PrimaryButton.tsx';
import CustomSelect from '../../inputs/CustomSelect';
import Modal from '../../modal/Modal';
import Table from '../../table/Table';
import { customProjectionCRS } from '../meta/GeoJSONOptions';

class GeoJSONModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.parseOptions = this.parseOptions.bind(this);
    this.submit = this.submit.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeProjectionCRS = this.onChangeProjectionCRS.bind(this);

    this.projectionsConvert = ['EPSG::31493'];

    this.state = {
      codeValue: false,
      nameValue: false,
      mapCRS: null,
      options: [],
      tableRows: [],
      tableColumns: [],
      customProjectionCRS: [],
      keyedData: null,
      projectValue: true,
      showProjectionToggle: true
    };
  }

  componentDidMount() {
    this.parseOptions();
  }

  isMercatorProjection(crsName) {
    return this.mercatorProjections.includes(crsName);
  }

  submit() {
    const mercatorProjections = [
      'EPSG:3857', // Web Mercator
      'EPSG:3395', // WGS 84 Mercator
      'EPSG:102113', // Deprecated, equivalent to EPSG:3857
      'EPSG:900913', // Deprecated, Google Mercator
      'EPSG:3785', // Deprecated, Web Mercator
      'EPSG:54004' // World Mercator
    ];
    let { codeValue, nameValue, options, mapCRS } = this.state;
    let { geoJSONMapData } = this.props;

    let data = JSON.parse(geoJSONMapData.data);

    const project = (geojson, targetProjection) => {
      this.props.updateCustomizedAction({
        optionProps: { id: 'everviz.mapCRS' },
        val: {
          fromProjection: targetProjection,
          toProjection: geojson?.crs?.properties?.name ?? 'EPSG:3857'
        }
      });

      const projectCoordinates = (coordinate) => {
        return window.proj4(targetProjection, 'EPSG:3857', coordinate);
      };

      geojson.features.forEach((feature) => {
        if (feature.geometry?.coordinates) {
          if (feature.geometry.type === 'Polygon') {
            feature.geometry.coordinates = feature.geometry.coordinates.map((ring) => {
              return ring.map(projectCoordinates);
            });
          } else if (feature.geometry.type === 'MultiPolygon') {
            feature.geometry.coordinates = feature.geometry.coordinates.map((polygon) => {
              return polygon.map((ring) => {
                return ring.map(projectCoordinates);
              });
            });
          }
        }
      });

      return geojson;
    };

    const isMercatorProjection = (crsName) => {
      return mercatorProjections.includes(crsName);
    };

    // Default to lat/lon if not Mercator
    let projectionType = 'EPSG:4326';
    const handleProjection = (geojson) => {
      if (mapCRS && mapCRS !== 'none') {
        projectionType = mapCRS.value;
      } else if (geojson.crs?.properties?.name) {
        const crsName = geojson.crs.properties.name;

        // Check if the CRS is one of the Mercator types
        if (isMercatorProjection(crsName)) {
          projectionType = 'EPSG:3857';
        }
      }

      if (mapCRS && mapCRS !== 'none') {
        project(geojson, mapCRS.value);
      } else if (projectionType === 'EPSG:3857') {
        project(geojson, 'EPSG:4326');
      } else {
        this.props.updateCustomizedAction({
          optionProps: { id: 'everviz.mapCRS' },
          val: {
            fromProjection: projectionType,
            toProjection: geojson?.crs?.properties?.name ?? projectionType
          }
        });
      }
    };

    handleProjection(data);

    if (data?.features) {
      // User has a name property in their datasource but has not assigned it through the dropdown.
      // Create a new property in their dataset and assign name to that instead, properties.name = nameValue.item().title value
      (data.features ?? []).forEach((feature) => {
        if (feature.properties) {
          if (feature.properties.name && nameValue?.label !== 'name') {
            feature.properties.dataName = feature.properties.name;
          }
          feature.properties.name = feature.properties[nameValue.label];
        }
      });
    }

    this.props.setGeoJSONMap(data, codeValue.label || options?.[0]?.label, nameValue.label || options?.[0]?.label);
    this.props.hideGeoJSONModalAction();
  }

  onChangeCode(e) {
    this.setState({
      codeValue: e
    });
  }
  onChangeName(e) {
    this.setState({
      nameValue: e
    });
  }
  onChangeProjectionCRS(e) {
    this.setState({
      mapCRS: e
    });
  }

  parseOptions() {
    const { geoJSONMapData } = this.props;

    if (!geoJSONMapData) {
      this.setState({
        options: [],
        tableRows: [],
        tableColumns: [],
        keyedData: null
      });
      return;
    }

    let data = JSON.parse(geoJSONMapData.data);

    if (data.type && data.type === 'Topology') {
      // Project the data using Proj4
      data = window.topojson.feature(data, data.objects[Object.keys(data.objects)[0]]);
    }

    // Dont have default keys, find out from user which they are and use them instead.
    let keyedData = Object.keys(data.features[0].properties),
      length = 0;

    const extractProps = (data, features, key) => {
      // User has an array value in their geojson props, extract all the values from here into own key
      features.forEach((feature, i) => {
        // eslint-disable-next-line no-undef
        if (isObj(feature)) {
          Object.keys(feature).forEach(function (featureKeys) {
            data[key + '_' + i + '_' + featureKeys] = feature[featureKeys];
          });
        }
      });
    };

    data.features.forEach((d) => {
      if (d.properties) {
        Object.keys(d.properties).forEach((p) => {
          if (isArray(d.properties[p])) {
            extractProps(d.properties, d.properties[p], p);
            delete d.properties[p];
          }
        });
      }

      length = Object.keys(d.properties).length;
      if (length > keyedData.length) keyedData = Object.keys(d.properties);
    });

    keyedData.some((key, index) => {
      if (index === 2) return false;
      return true;
    });

    function formatData(d) {
      return keyedData.map(function (key) {
        return d.properties[key];
      });
    }

    let dataArr = data.features.slice(0, 3).map(formatData);

    dataArr = dataArr.map((d) => {
      let obj = {};
      keyedData.forEach((key, i) => {
        obj[key] = d[i];
      });

      return obj;
    });

    let showProjectionToggle;
    if (data?.crs?.properties?.name) {
      showProjectionToggle = false;
    } else {
      this.setState({
        mapCRS: customProjectionCRS[0]
      });
    }

    this.setState({
      options: keyedData.map((key, i) => ({
        label: key,
        value: i
      })),
      tableRows: dataArr,
      tableColumns: keyedData.map((d) => ({ id: d, title: d })),
      keyedData,
      showProjectionToggle,
      customProjectionCRS
    });
  }

  render() {
    const {
      codeValue,
      mapCRS,
      nameValue,
      options,
      tableRows,
      tableColumns,
      customProjectionCRS,
      showProjectionToggle
    } = this.state;
    const { showGeoJSONModal, hideGeoJSONModalAction } = this.props;

    return (
      <Modal
        isOpen={showGeoJSONModal}
        onClose={hideGeoJSONModalAction}
        title={'Upload a new map (GeoJSON)'}
        width={'w-7/12 max-w-xl'}
        bg={'bg-ev-yellow-1'}
        bodyClass={'px-8 py-6'}
      >
        <div className="text-left">
          <div>
            <div className="pb-2 font-bold relative">Link your data</div>
            <div className="pb-2">
              Please select which columns in your datasheet represents a country code and name.
            </div>
          </div>

          <div>
            <div className="pb-2">
              {showProjectionToggle ?? (
                <CustomSelect
                  styles={customStyles}
                  label={'Projection'}
                  options={customProjectionCRS}
                  onChange={this.onChangeProjectionCRS}
                  value={mapCRS || customProjectionCRS[0]}
                />
              )}
            </div>
            <div className="pb-2">
              <CustomSelect
                styles={customStyles}
                label={'Code'}
                options={options}
                onChange={this.onChangeCode}
                value={codeValue || options[0]}
              />
            </div>
            <div className="pb-2">
              <CustomSelect
                styles={customStyles}
                label={'Name'}
                options={options}
                onChange={this.onChangeName}
                value={nameValue || options[0]}
              />
            </div>
          </div>

          <div className="bg-white">
            <div className="px-4 pt-4 font-bold text-ev-dark-purple">Data preview:</div>
            <div className="overflow-auto mb-0">
              <Table columns={tableColumns} rows={tableRows} dataReturned={true} />
            </div>
          </div>

          <div className="pt-2 flex gap-2">
            <PrimaryButton onClick={this.submit} text={'Create custom map'} />
            <PrimaryButton onClick={hideGeoJSONModalAction} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
          </div>
        </div>
      </Modal>
    );
  }
}

export default GeoJSONModal;
