import { TemplateTypeProps } from '../../types';
import ThreeDPie from './3DPie';
import ThreeDPiewithLegend from './3DPieWithLegend';
import Pie from './Pie';
import PieWithLegend from './PieWithLegend';

const PieTemplate: TemplateTypeProps = {
  templates: {
    Pie: Pie,
    'Pie w/ legend': PieWithLegend,
    '3D Pie w/ legend': ThreeDPiewithLegend,
    '3D Pie': ThreeDPie
  },
  description: [
    'A pie chart is a circular statistical graphic which is divided into slices to illustrate numerical proportions.',
    'In a pie chart, the arc length of each slice is proportional to the quantity it represents.'
  ],
  samples: ['pie-series-simple']
};

export default PieTemplate;
