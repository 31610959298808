const area =
  'Area Charts are often used for showing how a variable changes over time, with time plotted on the x-axis.  However, they can also be used for other variables, for example, showing how the elevation of a route changes over distance.  Stacked area charts can also be used to show how the sum total is comprised of different categories or types.';
const bar =
  'Bar charts are a good choice for a visualization that shows the difference in values between different categories.  Humans can easily visually distinguish the differences in bar lengths and understand how this relates to the size of the data.  This means that bar charts are easier to interpret than charts that rely on area to depict the magnitude of a value.  The horizontal orientation of a bar chart also makes it easier to display longer category names and it can usually accommodate a larger number of categories than a column chart.';
const column =
  'Column charts are a good choice for a visualization that shows the difference in values between different categories.  Humans can visually distinguish the differences in column lengths and understand how this relates to the size of the data.  This means that column charts are easier to interpret than charts that rely on area to depict the magnitude of a value.  The vertical orientation of a column chart can restrict the number of categories that can be included, so for larger data sets, a horizontal bar chart might be more effective.';
const pie =
  'The primary objective of a pie chart is to illustrate how different elements contribute to a whole.  For example, a pie chart might be used to show how a company’s sales are broken down by region or by department.  A larger segment of the pie would indicate that a greater proportion of the sales can be attributed to this category.';
const stock =
  'A stock chart is a graph that shows how the price of stock has changed over time.  Often, stock charts will have two or more panes that give different information.  The main pane is usually a line chart showing price fluctuations, but this information can also be presented in other formats (such as column, area or candlestick charts).';

export default {
  Area: area,
  'Stacked area': area,
  'Stacked bar': bar,
  'Stacked column': column,
  Bar: bar,
  Line: 'Line charts are a good choice for displaying large data sets and how a value changes over time or another variable.  Multiple lines can also be plotted on the same chart, allowing comparison of different series.  However, line charts should not be used for categorical data:  if you don’t have a continuous variable to plot on the x-axis, then a bar or column chart is likely to be more suitable.',
  Pie: pie,
  Donut: pie.replaceAll('pie', 'donut'),
  Column: column,
  Stock: stock,
  Candlestick: stock,
  OHLC: stock,
  'Scatter chart':
    'Scatter charts are useful for showing larger data sets and they help us to identify any correlations as well as see any outliers.  However, more complex relationships can be difficult to interpret, and so for presentation purposes, it might be more appropriate to process the data first and include trend lines or use a different chart type (such as a candlestick chart if you wish to preserve some statistical information).',
  'Bubble chart':
    'A bubble chart is a variation on a scatter chart, but which uses the size of the data point to depict an additional variable.  Like in a scatter chart, the data points are plotted on the x and y axes according to two variables.  However, the size of the bubble is determined by a third variable, so that larger values are shown as bigger bubbles.',
  'Polar area':
    'Polar charts, also known as radar charts, are circular charts that are often used to compare multivariate data sets. A polar chart in everviz is simply a cartesian chart where the x-axis is wrapped around the perimeter. It can render common cartesian series types like line, column, area or area range.',
  Spider:
    'Spider charts can be used to rate things along three or more axes, allowing you to compare the advantages and disadvantages of different entities.  For example, you might compare three different colleges by plotting the cost, faculty quality, campus facilities, and student life ratings for each.',
  'Wind rose':
    'A wind rose chart is used to illustrate both the wind speed and wind direction at a particular point.  The chart takes the form of a compass with each segment representing a direction.  Data is depicted by columns extending from the center of the chart, the height of which indicates the length of time the wind was blowing from a particular direction.  Stacked columns can also be used to provide further breakdown such as the length of time the wind speed was within a set range.',
  Timeline:
    'A timeline chart is a good choice when you want to create a simple diagram that shows a small number of key events on a time axis.  However, for more complicated schedules with multiple overlapping events, other chart types might be more appropriate.  For example, a bar chart format could be used to create a simple Gantt diagram.',
  'Dependency wheel':
    'A dependency wheel is a good choice for data that can flow in two directions (such as immigration patterns where a single country may have both immigrants entering the country and emigrants leaving).  However, if your data only flows in one direction you might choose to use a Sankey chart instead.',
  Sankey:
    'A Sankey chart is a good choice for visualizing data flow through multiple stages, especially where the data flows in one direction.  However, if your data flows in two directions, you might want to choose a dependency wheel instead.',
  Dumbbell:
    'Dumbbell charts are a good choice for visualizing a range of data or the difference between two conditions (e.g. before and after; minimum and maximum).  However, if you have more than two conditions to compare, you might want to choose another plot type.',
  'Unit chart':
    'Unit charts are a good choice for presenting simple data in a visual manner that allows for a quick comparison of a few categories, especially when the different categories can easily be depicted with an icon or symbol.',
  Boxplot:
    'A boxplot chart summarizes the distribution of values within measured categories using boxes and whiskers.   A boxplot is a standardized method of displaying statistical data and consists of two parts: the box and the whiskers.  The box is plotted from the first quartile (25th percentile or the median of the lower half of the data set) to the third quartile (75th percentile or the median of the upper half).  The median (50th percentile) is also shown as a line across the box.  The whiskers indicate the minimum and maximum data points.',
  Funnel:
    'A funnel chart is often used to visualize how the volume of data reduces as it passes through stages of a process.  The top of the chart (the head of the funnel) is the widest and contains the full extent of the data starting the process.  The lower parts (the neck of the funnel) represent different stages of the process in order, moving down the chart.',
  'Solid guage':
    'Solid gauge charts are a good choice for displaying a single value within a range.  They are easy and intuitive to interpret and can be visually compared to other gauges using the same scale.  However, if you have a number of data points to compare, then another chart type (such as a column chart or radial bar) might be more appropriate.',
  Parliament:
    'Parliament charts are used to show the number of seats held by each political party within a government.  They take the form of a semicircular shape with dots to represent each seat.  The dots are then colored according to the political party that they represent.  Parties are grouped together so that the size of each can be visually compared.  The semicircular shape makes it easy to see if one party has more than half of the seats representing an overall majority.',
  Streamgraph:
    'A stream graph chart is a type of stacked area chart where the areas are displaced around a varying central axis. This type of chart is good for displaying changes in data over time across different categories and it creates an attractive, attention-grabbing visualization.',
  Waterfall:
    'Waterfall charts are used to visualize cumulative values, where each data point contributes to a running total. The data points are displayed as floating columns, often connected by a line to illustrate the sequential relationship.  They are also sometimes known as cascade charts or bridge charts.',
  Wordcloud:
    'A word cloud is a visualization that is used to show the frequency of words within a text or other format.  Words are displayed in a visually attractive pattern with the most frequent words being assigned a larger font size to show their prominence.',
  Heatmap:
    'A heatmap is a data visualization that uses color to depict the magnitude of each data point.  Since color is being used to illustrate the value, this frees up both the x and y axes for identifying the data point.  This means that heatmaps can be a relatively compact way of presenting quite complex data.',
  Choropleth:
    'Choropleth maps can be a good choice for plotting boundary-bound data that can be normalized for comparison to other regions. The data needs to be classified into suitable ranges: four to seven categories are generally recommended.  If the number of categories is too high, it becomes difficult for people to distinguish the different color shades.  Choropleth maps can also be difficult to read for people with visual impairment or color vision deficiencies, so it is important to check that the map is appropriate for the intended audience.',
  Categories:
    'Category maps are good for displaying data that falls into discrete, easily described categories.  They provide a visual representation of the status of each region; however, the overall impression can be skewed by the size differences of the regions.  Larger regions will visually dominate the map, but this will not always be representative of their overall importance.  In some cases, alternative charts may be more appropriate (e.g. tilemaps where regions all have equal importance, or choropleths for data that can be normalized to fit a data range).',
  Bubble:
    'A bubble map is a good choice for representing numerical data with a location.  However, if there are a lot of data points close together, the bubbles can overlap making things difficult to read.  In this case, it might be appropriate to group regions together or provide a close-up chart of the detail.',
  'Tilemap circle':
    'A tilemap is a good choice if you want to create a map that gives equal weight to each region regardless of its area but geographical accuracy is not important.',
  Honeycomb:
    'Honeycomb maps are an attractive method of presenting data that maintains the approximate positions of the regions allowing geographical patterns to be illustrated.  However, they are limited by the number of categories that can be distinguished using color shading.  If more than about seven different categories are required, then an alternative chart type might be more appropriate.',
  'Pattern fill':
    'Pattern fill maps are a good choice if you want to produce a unique and engaging map of data that is easily represented by simple graphics.  This is most effective for a map with regions of similar sizes because small regions will not be easily distinguishable in a busy map.  However, you can ensure that smaller regions are included through the use of a tooltip or by the inclusion of an additional map showing specific detail.',
  'Point map':
    'Point maps are useful for showing specific locations and the point appearance can be adapted to suit the illustration.  Additional information can be delivered using a tooltip, but if you want to include a visual representation of additional data fields then another chart type (e.g. a bubble map) might be more appropriate.',
  'Location map':
    "The location map is very useful for pinpointing the location of an event. Markers are placed on the map to provide context to viewers or readers about where an event is unfolding or happened. Location maps feature a 'mini map' showing the location in the context of a larger geographic region. In addition, 3D buildings, streets, navigation, animations, layers and more can be enabled for the location map.",
  Treemap:
    'Treemaps are used to show how the whole is divided. It is easy to understand which entity is the most important and how the whole is distributed among entities. The current Treemap in everviz supports one level (i.e not hierarchical data), just to show the value of several entities like in a barplot. Treemaps have the advantage of making efficient use of space, which makes them useful to represent a large amount of data.',
  PackedBubble:
    'Packed Bubble is great for visualizing hierarchical data as nested circles of different sizes and colors. Each circle represents a level in the data hierarchy, with the color of the circle corresponding to an attribute and the size determined by a metric value.'
};
