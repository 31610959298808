import { uncamelize } from 'editor/core/highcharts-editor';
import { SearchedOption } from 'pages/ChartEditorPage/types/advance/advanceTypes';
import * as React from 'react';
import { OptionProps, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import LoaderIcon from 'shared/loader/LoaderIcon';
import { searchInputStyles } from 'shared/utils/selectStylesHelper';

type SearchInputProps = {
  filterResults: (input: string) => SearchedOption[];
  handleClick: (ev: React.MouseEvent, breadcrumbs: string, fieldId: string) => void;
  label?: string;
};

const SearchInput = (props: SearchInputProps) => {
  const { label, filterResults, handleClick } = props;

  const loadOptions = (inputValue: string, callback: (option: SearchedOption[]) => void) => {
    setTimeout(() => {
      callback(filterResults(inputValue));
    }, 1000);
  };

  const LoadingIndicator = () => <LoaderIcon loading={true} iconClass="text-xl" />;

  const Option = (props: OptionProps<SearchedOption>) => (
    <components.Option
      {...props}
      innerProps={{
        onClick: (ev) => {
          if (props.innerProps.onClick) props.innerProps.onClick(ev);
          handleClick(ev, props.data.breadcrumbs, props.data.fieldId);
        }
      }}
    >
      <div className={`text-base font-medium ${props.isSelected ? 'text-ev-dark-purple' : 'text-ev-navy-blue-2'}`}>
        {props.data.label}
      </div>
      <div className="text-ev-navy-blue ml-2">
        {props.data.breadcrumbs.split('.').map((item, i) => {
          const isLast = i === props.data.breadcrumbs.split('.').length - 1;
          return (
            <span key={item}>
              {uncamelize(item)} {!isLast && <>&bull; </>}
            </span>
          );
        })}
      </div>
    </components.Option>
  );

  return (
    <div className="flex flex-col mb-2">
      {label && <span className="text-ev-navy-blue font-bold mb-2">{label}</span>}
      <AsyncSelect
        loadOptions={loadOptions}
        cacheOptions={true}
        styles={searchInputStyles}
        placeholder="Search..."
        components={{ LoadingIndicator, Option }}
        classNames={{ control: () => 'highed-field-input' }}
      />
    </div>
  );
};

export default SearchInput;
