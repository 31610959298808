import React from 'react';

export default function OpenContainer(props) {
  return (
    <div className={`mb-6 bg-white p-4 flex flex-col gap gap-2 ${props.useKey}`} key={props.useKey}>
      <div className="pb-2 text-ev-navy-blue-2">
        <b className="text-base font-bold	">{props.headerText}</b>
      </div>
      {props.children}
    </div>
  );
}
