import { cloneDeep, maxBy } from 'lodash';
import { generateTypeObject } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import { toCSV } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import { getLetterFromIndex, isNull } from '../../../editor/core/highcharts-editor';
import chartTypes from '../../../editor/meta/highed.meta.charttype';

// Dont need the helper for these types
const BLOCK_LIST = ['heatmap', 'timeline', 'packedbubble'];
const MULTIPLE_SERIES_BLOCK_LIST = [
  'candlestick',
  'bubble',
  'tilemap',
  'pie',
  'ohlc',
  'item',
  'dependencywheel',
  'sankey',
  'wordcloud',
  'vector',
  'boxplot'
];
const NULLABLE_VALUES = ['#n/a'];

const defaultTypedAssigns = [
  {
    name: 'Categories',
    value: 'labels',
    mandatory: true
  },
  {
    name: 'Values',
    value: 'values',
    mandatory: true
  }
];

const hasNumber = /\d/;

const getChartCustomizedOptionChanges = (seriesSuffix, seriesPrefix) => {
  let customizedOptionChanges = {
    yAxis: {},
    tooltip: {}
  };

  if (seriesPrefix) {
    customizedOptionChanges.yAxis = {
      labels: {
        format: seriesPrefix + '{value}'
      }
    };
    customizedOptionChanges.tooltip = {
      valuePrefix: seriesPrefix
    };
  }

  if (seriesSuffix) {
    if (!customizedOptionChanges.yAxis.labels) customizedOptionChanges.yAxis.labels = {};
    if (!customizedOptionChanges.tooltip) customizedOptionChanges.tooltip = {};
    customizedOptionChanges.yAxis.labels.format =
      (customizedOptionChanges.yAxis.labels.format || '{value}') + seriesSuffix;
    customizedOptionChanges.tooltip.valueSuffix = seriesSuffix;
  }

  return customizedOptionChanges;
};

export const detectDecimalDelimiter = (input) => {
  input = input.replace(/[^\d,.-]/g, '').trim();

  let c = input.split(',').length - 1;

  if (c > 1) return '.'; // '123,456,789' or '123,456,789.12'
  if (input.indexOf(' ') >= 0) return ','; // '123 456'
  if (input.indexOf('،') >= 0) return '.'; // '123،456'
  if (input.indexOf("'") >= 0) return '.'; // '123\'456'

  let d = input.split('.').length - 1;
  if (c === 1 && d === 1) {
    // '123,456.789' or '1.234,45'
    let ci = input.lastIndexOf(',');
    let di = input.lastIndexOf('.');
    if (di > ci) return '.';
    else return ',';
  }

  if (c + d === 1) {
    let ci = input.indexOf(',');
    let di = input.indexOf('.');
    let len = input.length;

    if (ci !== -1 && len - ci !== 4) return ',';
    if (di !== -1 && len - di !== 4) return '.';
  }
  return null;
};

export const getDecimalDetails = (chartData) => {
  const decimalTypes = {
    undefined: 1
  };

  chartData.forEach((row, rowIndex) => {
    if (rowIndex === 0) return true;
    row.forEach((cell) => {
      const isNumber = hasNumber.test(cell);
      if (isNumber || !cell) {
        let stringVersion = (!isNull(cell) ? cell : '').toString();
        let decimalType;

        if (cell) decimalType = detectDecimalDelimiter(stringVersion);
        if (decimalType) {
          if (decimalTypes[decimalType]) decimalTypes[decimalType]++;
          else decimalTypes[decimalType] = 1;
        }
      }
    });
  });

  const detectedDecimalCheck = maxBy(Object.keys(decimalTypes), (val) => decimalTypes[val]);
  const detectedDecimal = detectedDecimalCheck === 'undefined' ? '.' : detectedDecimalCheck;

  return detectedDecimal;
};

const getCellsDetails = (chartData, colIndex, seriesPrefix, seriesSuffix, detectedDecimal) => {
  if (colIndex === 0) return false;
  const hasNegative = /^-/;
  const hasOnlyZeros = /^[0]+$/;
  const hasDecimalAndThousandSeparator = /(?=.*,)(?=.*\.)/g;

  let allEmpty = true,
    hasNegativeOrZeroValues = false,
    rowsHaveNumbers = [];

  const isDecimalPoint = detectedDecimal === '.';
  const thousandSeperator = isDecimalPoint ? ',' : '.';
  const decimal = isDecimalPoint ? '.' : ',';

  const regex = new RegExp('\\b\\d+(?:[' + thousandSeperator + ' ]\\d+)*(?:\\' + decimal + '\\d+)?\\b', '');

  chartData.forEach((row, rowIndex) => {
    if (rowIndex === 0) return true;
    if (row[colIndex] !== null && row[colIndex] !== undefined && row[colIndex] !== '') allEmpty = false;

    const isNumber = hasNumber.test(row[colIndex]);

    if (isNumber || !row[colIndex]) {
      let stringVersion = (!isNull(row[colIndex]) ? row[colIndex] : '').toString();
      const prefixAndSuffix = stringVersion.split(/-?\d+\.?,?\d*\.?,?\d*/);

      stringVersion = stringVersion.replace(' ', '');
      if (isNumber && hasDecimalAndThousandSeparator.test(stringVersion)) {
        stringVersion = stringVersion.replace(/,(?=\d{3})/g, '');
      }

      const isNegative = hasNegative.test(row[colIndex]);
      const isOnlyZeros = hasOnlyZeros.test(row[colIndex]);

      if (isNumber && (isNegative || isOnlyZeros)) hasNegativeOrZeroValues = true;

      let numberVal = stringVersion.match(regex) || '';
      row[colIndex] = isNumber && isNegative ? `-${numberVal[0]}` : numberVal[0];

      // Define a variable for the symbol length threshold (currently set to 3).
      // This threshold is used to determine whether a prefix or suffix should be
      // considered part of a larger string (e.g., "Zone", "USD") or just a symbol (e.g., "%", "$").
      // Symbols shorter than or equal to this threshold are removed, while longer ones are retained.
      // Adjust the SYMBOL_LENGTH_THRESHOLD if necessary, especially when Highcharts adds
      // new symbol support or if the full list of supported symbols is available.
      const SYMBOL_LENGTH_THRESHOLD = 3;

      // Ensure prefixAndSuffix[0] exists and has a length property
      if (prefixAndSuffix[0] && prefixAndSuffix[0].length > SYMBOL_LENGTH_THRESHOLD) {
        row[colIndex] = `${prefixAndSuffix[0]}${row[colIndex]}`;
      }

      // Ensure prefixAndSuffix[1] exists and has a length property
      if (prefixAndSuffix[1] && prefixAndSuffix[1].length > SYMBOL_LENGTH_THRESHOLD) {
        row[colIndex] = `${row[colIndex]}${prefixAndSuffix[1]}`;
      }

      if (isNumber) {
        // Remove the thousand seperator. This will get added through highcharts instead.
        row[colIndex] = row[colIndex].replaceAll(thousandSeperator, '');
      }

      if (prefixAndSuffix.length > 1) {
        if (prefixAndSuffix[0] !== '') seriesPrefix = prefixAndSuffix[0];
        if (prefixAndSuffix[1] !== '') seriesSuffix = prefixAndSuffix[1];
      }
    } else if (NULLABLE_VALUES.includes(row[colIndex].toLowerCase())) row[colIndex] = null;

    rowsHaveNumbers.push(isNumber || !row[colIndex]);
  });

  const allRowsHaveNumbers = !allEmpty && colIndex !== 0 && !rowsHaveNumbers.includes(false);
  return { allRowsHaveNumbers, seriesPrefix, seriesSuffix, detectedDecimal, hasNegativeOrZeroValues };
};

// Helper function for parsing through users data and determining which columns are compatible to use
// Also will find any prefix/suffixes and add them to the highcharts config automatically too
export const parseDataForValidColumns = (type, chartData, itemDelimiter) => {
  let parsedData = chartData,
    assignOptions = {},
    seriesAssigns = [],
    seriesCount = 0,
    allRowsHaveNumbers,
    seriesPrefix = '',
    seriesSuffix = '',
    options,
    typedAssigns = cloneDeep(defaultTypedAssigns);

  if (parsedData && parsedData.length) {
    parsedData = parsedData.filter((a) => a.length !== 0);
  }

  assignOptions = options = generateTypeObject(chartTypes[type] ?? {});

  if (BLOCK_LIST.includes(type)) {
    // Skip everything else in this function, we dont use this helper for these template types
    return {
      csv: toCSV(parsedData, itemDelimiter),
      rawData: parsedData,
      customizedOptionChanges: {},
      seriesAssigns: [
        {
          ...assignOptions
        }
      ]
    };
  }

  if (chartTypes[type]) {
    let typeOptions = [];
    Object.keys(options ?? []).forEach((key) => {
      const opt = options[key];
      if (opt.mandatory || opt.isLabel || opt.isData) {
        typeOptions.push({
          name: opt.name,
          value: key,
          order: opt.order
        });
      }
    });

    typedAssigns = [...typeOptions.sort((a, b) => a.order - b.order)];
  }

  let selectCount = 1; // Skip categories

  const detectedDecimal = getDecimalDetails(parsedData);

  parsedData[0].some((_, index) => {
    ({ allRowsHaveNumbers, seriesPrefix, seriesSuffix } = getCellsDetails(
      parsedData,
      index,
      seriesPrefix,
      seriesSuffix,
      detectedDecimal
    ));
    if (allRowsHaveNumbers) {
      if (!seriesAssigns[seriesCount]) {
        seriesAssigns[seriesCount] = cloneDeep(assignOptions);
      }

      // Set category/labels assign
      const categoryKey = typedAssigns[0] ? typedAssigns[0].value : 'labels';
      seriesAssigns[seriesCount] = {
        ...seriesAssigns[seriesCount],
        [categoryKey]: {
          ...assignOptions[categoryKey],
          rawValue: [0],
          value: getLetterFromIndex(0)
        }
      };

      // Set value assign
      const seriesValueKey = typedAssigns[selectCount] ? typedAssigns[selectCount].value : 'values';
      seriesAssigns[seriesCount][seriesValueKey] = {
        ...assignOptions[seriesValueKey],
        rawValue: [index],
        value: getLetterFromIndex(index)
      };

      if (selectCount < typedAssigns.length - 1) selectCount++;
      else {
        selectCount = 1;
        seriesCount++;
        if (MULTIPLE_SERIES_BLOCK_LIST.includes(type)) return true;
      }
    }
  });

  const customizedOptionChanges = getChartCustomizedOptionChanges(seriesSuffix, seriesPrefix);

  return {
    csv: toCSV(parsedData, itemDelimiter),
    rawData: parsedData,
    customizedOptionChanges,
    seriesAssigns,
    decimalDelimiter: detectedDecimal
  };
};

// This function mutates options
export const parseDataForValueColumn = (customizedOptions, seriesAssigns, options, mapCodeIndex) => {
  let allRowsHaveNumbers,
    //  detectedDelimiter,
    seriesPrefix = '',
    seriesSuffix = '',
    customizedOptionChanges = {},
    hasNegativeOrZeroValues = false,
    rowsNumberArray = [];

  const detectedDecimal = getDecimalDetails(options);

  options[0].some((_, index) => {
    if (mapCodeIndex === index) {
      rowsNumberArray.push({ allRowsHaveNumbers: false, hasNegativeOrZeroValues: false });
      return false;
    }

    ({ allRowsHaveNumbers, seriesPrefix, seriesSuffix, hasNegativeOrZeroValues } = getCellsDetails(
      options,
      index,
      seriesPrefix,
      seriesSuffix,
      detectedDecimal
    ));

    rowsNumberArray.push({ allRowsHaveNumbers, hasNegativeOrZeroValues });
  });

  rowsNumberArray.some(({ allRowsHaveNumbers, hasNegativeOrZeroValues }, index) => {
    if (mapCodeIndex === index) return false;

    if (allRowsHaveNumbers) {
      customizedOptionChanges.colorAxis = cloneDeep({
        ...(customizedOptions.colorAxis ?? {}),
        type: hasNegativeOrZeroValues ? 'linear' : 'logarithmic'
      });

      seriesAssigns.forEach((assign) => {
        // We only want to work out the value if the user hasnt changed the dropdown before
        // This check for index=1 is basically saying if the user hasnt changed the value dropdown
        // Then work it out for them.
        if (assign?.value?.rawValue?.[0] === 1) {
          assign.value.rawValue = [index];
          assign.value.value = getLetterFromIndex(index);
        }
      });

      return true;
    }
  });

  return { seriesAssigns, customizedOptionChanges };
};
