import { cloneDeep } from 'lodash';
import {
  deleteCustomizedAction,
  redrawProjectAction,
  updateCustomizedAction
} from 'pages/ChartEditorPage/actions/chartEditor';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';
import { setResolutionAction } from 'pages/LayoutEditorPage/actions/layoutEditor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Dimensions } from 'shared/editor/generic/ResolutionSection';
import { ChannelOption } from 'shared/wizard/meta/ChannelOptions';
import { getOriginalExportSize } from 'shared/wizard/utils/chartPreviewHelper';
import CodeChannel from './CodeChannel';
import ImageChannel from './ImageChannel';
import LiveVideoChannel from './LiveVideoChannel';
import OnlineChannel from './OnlineChannel';
import SocialChannel from './SocialChannel';
import VideoChannel from './VideoChannel';

type ChannelContentProps = {
  activeChannel: ChannelOption;
  onPublish?: () => void;
};

const ChannelContent = (props: ChannelContentProps) => {
  const { activeChannel, onPublish } = props;

  const initResolution: Dimensions = { width: null, height: null };
  const [defaultResolution, setDefaultResolution] = useState(initResolution);
  const [configExportingSize, setConfigExportingSize] = useState(initResolution);
  const dispatch = useDispatch();

  const chart = useChart();
  const { aggregatedOptions, type, provider } = useSelector<any, any>((state: RootState) => state.projectConfig);

  const isChart = type === 'chart';

  const revertExportSize = () => {
    [
      ['width', 'sourceWidth'],
      ['height', 'sourceHeight']
    ].forEach(([prop, key]) => {
      if (!configExportingSize[prop as keyof Dimensions]) {
        dispatch(
          deleteCustomizedAction({
            optionProps: `exporting.${key}`
          })
        );
      } else {
        dispatch(
          updateCustomizedAction({
            optionProps: `exporting.${key}`,
            val: configExportingSize[prop as keyof Dimensions],
            skipEmit: true
          })
        );
      }
    });
  };

  useEffect(() => {
    const exporting = getOriginalExportSize(aggregatedOptions) as Dimensions;
    setConfigExportingSize(exporting);

    if (isChart) {
      dispatch(redrawProjectAction({ noAnimation: true }));
      setTimeout(() => {
        if (chart) {
          setDefaultResolution({
            width: chart.chartWidth,
            height: chart.chartHeight
          });
        }
      }, 1000);
    }
    return () => {
      if (isChart) {
        if (chart?.showingSmallAnnotations) chart.setSize(415);
        setDefaultResolution({
          width: null,
          height: null
        });
        revertExportSize();
      }
    };
  }, []);

  const setResolutionHandler = (res: Dimensions) => {
    const resolution = cloneDeep(res ?? {});
    if (isChart && provider !== 'locationMap') {
      if (!resolution.width) resolution.width = defaultResolution.width;
      if (!resolution.height) resolution.height = defaultResolution.height;
    }
    dispatch(setResolutionAction(resolution));
  };

  return (
    <>
      {activeChannel === ChannelOption.Online && (
        <OnlineChannel onPublish={onPublish} setResolution={setResolutionHandler} />
      )}
      {activeChannel === ChannelOption.Social && <SocialChannel setResolution={setResolutionHandler} />}
      {activeChannel === ChannelOption.Image && <ImageChannel setResolution={setResolutionHandler} />}
      {activeChannel === ChannelOption.Code && <CodeChannel setResolution={setResolutionHandler} />}
      {activeChannel === ChannelOption.Video && <VideoChannel setResolution={setResolutionHandler} />}
      {activeChannel === ChannelOption.LiveVideo && <LiveVideoChannel onPublish={onPublish} />}
    </>
  );
};

export default ChannelContent;
