import React from 'react';
import Select from 'react-select';
import { customStyles } from 'shared/utils/selectStylesHelper';
import { isNull, isStr } from '../../editor/core/highcharts-editor';

export default (props) => {
  const { value, selectOptions } = props;
  const onChange = (e) => {
    let val = e.target.value;

    props.onChange({ val });
  };

  const onChangeSelect = (val) => {
    props.onChange({
      val: val.value
    });
  };

  return (
    <div className="mt-2">
      <div className="text-ev-navy-blue font-bold mb-2">{props.label}</div>
      <div className="relative h-[32px] mb-[10px]">
        <Select
          options={selectOptions}
          onChange={onChangeSelect}
          name="fontFamily"
          value={
            isStr(value) || isNull(value) ? selectOptions.find((d) => d.value === value) ?? selectOptions[0] : value
          }
          isSearchable={false}
          className={'absolute w-full'}
          menuPlacement={'auto'}
          styles={{
            ...customStyles,
            control: (provided) => ({
              ...provided,
              border: 0,
              height: '40px',
              boxShadow: 'inset 2px 2px 2px rgb(0 0 0 / 25%) !important'
            })
          }}
        />
        <input className={'highed-field-input absolute w-[calc(100%-41px)]'} value={value} onChange={onChange} />
      </div>
    </div>
  );
};
