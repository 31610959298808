import en from 'editor/en';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAction } from 'redux/actions/projectConfig';
import PanelContainer from 'shared/editor/generic/PanelContainer';
import CustomizeSection from '../../../shared/editor/CustomizeSection';
import { setCustomCodeAction, updateCustomizedAction } from '../../ChartEditorPage/actions/chartEditor';
import { getFontsAction, getLogosAction } from '../../LayoutEditorPage/actions/layoutEditor';
import { getCustomizeOptions } from '../utils/customizeHelper';
import AdvanceCustomize from './advance/AdvanceCustomize';
import CustomCode from './CustomCode';
import CustomizePanelOptions from './CustomizePanelOptions';
import PreviewOptions from './PreviewOptions';
import { renderCondition } from '../meta/PanelOptions';
import { useChart } from '../meta/highchartsHelper';
import { LocationMapCustomize } from './LocationMapCustomize';

export default function Customize(props) {
  const chartEditorPage = useSelector((state) => state.chartEditorPage);
  const projectConfig = useSelector((state) => state.projectConfig);
  const layoutEditorPage = useSelector((state) => state.layoutEditorPage);

  const { editorConfig, customCodeStr, constr, inspector } = chartEditorPage;
  const { activeSection, type, provider } = projectConfig;
  const { team } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const chart = useChart();

  const updateCustomizedProp = (optionProps, val, extraModulesToLoad, index) => {
    return dispatch(updateCustomizedAction({ optionProps, val, extraModulesToLoad, index }));
  };
  const getFonts = (team) => dispatch(getFontsAction({ team }));
  const getLogos = (team) => dispatch(getLogosAction({ team }));
  const setCustomCode = (customCode, skipEmit) => dispatch(setCustomCodeAction({ customCode, skipEmit }));

  const switchActiveSection = (section) => {
    dispatch(
      setAction({
        activeSection: section
      })
    );

    if (inspector && section !== 'inspector') {
      inspector.toggleActive(false);
    }
  };

  useEffect(() => {
    if (team) {
      getFonts(team);
      getLogos(team);
    }

    return () => {
      if (inspector) inspector.toggleActive(false);
    };
  }, []);

  useEffect(() => {
    if (!props.active) {
      switchActiveSection('basic');
      if (inspector) inspector.toggleActive(false);
    }
  }, [props.active]);

  const isLocationMap = provider === 'locationMap';
  const customizeOptions = getCustomizeOptions(provider, chartEditorPage, projectConfig, layoutEditorPage);

  const isAdvanced = activeSection === 'advanced' || activeSection === 'inspector';

  return (
    <PanelContainer active={props.active} isAdvanced={isAdvanced}>
      {type === 'chart' && !isLocationMap && (
        <CustomizePanelOptions
          activeSection={activeSection}
          switchActiveSection={switchActiveSection}
          renderCondition={renderCondition[provider]}
        />
      )}

      {activeSection === 'basic' && isLocationMap && <LocationMapCustomize />}
      {!isAdvanced && activeSection === 'basic' && !isLocationMap && (
        <CustomizeSection customizeOptions={customizeOptions} lang={en} editorConfig={editorConfig} defaultTab={''} />
      )}

      {activeSection === 'customcode' && <CustomCode customCodeStr={customCodeStr} setCustomCode={setCustomCode} />}
      {activeSection === 'preview' && <PreviewOptions aggregatedOptions={props.aggregatedOptions} />}
      {isAdvanced && (
        <AdvanceCustomize
          aggregatedOptions={props.aggregatedOptions}
          constr={constr}
          chart={chart}
          updateCustomizedProp={updateCustomizedProp}
          isOpen={isAdvanced}
        />
      )}
    </PanelContainer>
  );
}
