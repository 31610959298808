import { javascript } from '@codemirror/lang-javascript';
import { tokyoNightStorm } from '@uiw/codemirror-theme-tokyo-night-storm';
import CodeMirror from '@uiw/react-codemirror';
import { isFunction } from 'lodash';
import React from 'react';

type PreviewOptionsProps = {
  aggregatedOptions: any;
};

export default (props: PreviewOptionsProps) => {
  const { aggregatedOptions } = props;

  return (
    <div className="custom-code" style={{ width: 332 }}>
      <CodeMirror
        theme={tokyoNightStorm}
        readOnly={true}
        value={JSON.stringify(
          aggregatedOptions ?? {},
          function (_, value) {
            if (isFunction(value)) {
              return value.toString();
            }

            return value;
          },
          ' '
        )}
        className="rounded overflow-auto h-[calc(100vh-315px)]"
        extensions={[javascript()]}
      />
    </div>
  );
};
