import React from 'react';
import PropTypes from 'prop-types';

class Timer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      style: {
        animationPlayState: 'paused',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.animState !== this.props.animState) {
      this.setState({
        style: {
          animationPlayState: this.props.animState === 'stop' ? 'paused' : 'running',
          animationDuration: this.props.duration / 1000 + 's',
        },
      });
    }
  }

  render() {
    return (
      <>
        {this.props.animState === 'play' && (
          <div className="carousel-progress-bar">
            <div className="inner" style={this.state.style}></div>
          </div>
        )}
      </>
    );
  }
}

Timer.propTypes = {
  animState: PropTypes.string,
  duration: PropTypes.number,
};

export default Timer;
