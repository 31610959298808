/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import AlertMessage from '../message/AlertMessage';
import Modal from 'shared/modal/Modal';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import InputWidget from 'shared/widgets/InputWidget';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import snackBar from 'editor/core-ui/highed.snackbar';
import {
  addCustomFontAction,
  editCustomFontAction,
  setAction
} from 'pages/CustomiseEditorPage/actions/customiseEditorPage';

type FontModalProps = {
  refreshFonts: (team: { id: number }) => void;
  selectedFont: { id: number; name: string };
};

const fontModalActionMap = {
  edit: editCustomFontAction,
  add: addCustomFontAction
};

export default function FontModal(props: FontModalProps) {
  const dispatch = useDispatch();
  const { team } = useSelector((state: RootState) => state.profile);
  const { showFontModal } = useSelector((state: RootState) => state.customiseEditorPage);
  const show = !!showFontModal;
  const type = showFontModal?.type;
  const { refreshFonts, selectedFont } = props;
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (showFontModal) {
      setName(showFontModal.type === 'Edit' ? selectedFont.name : '');
    }
  }, [showFontModal]);

  const updateState = (value: { val: any }) => setName(value.val);

  const closeModal = (event?: React.MouseEvent | React.KeyboardEvent) => {
    event?.preventDefault();
    dispatch(setAction({ showFontModal: false }));
  };

  const dispatchAction = (
    event: React.MouseEvent | React.KeyboardEvent | undefined,
    type: keyof typeof fontModalActionMap,
    files: any,
    name: string
  ) => {
    dispatch(
      fontModalActionMap[type]({
        files,
        name,
        selectedFont,
        cb: () => {
          snackBar(`Font ${type === 'add' ? 'uploaded' : 'updated'}`, '', () => {});
          refreshFonts(team);
          closeModal(event);
        }
      })
    );
  };

  const changeMetaData = (event?: React.MouseEvent | React.KeyboardEvent) => {
    event?.preventDefault();
    if (name === '') {
      setError('Name field is required');
      return false;
    }

    const files = (document.querySelector('#font') as HTMLInputElement).files;
    if (type === 'Add') {
      if (!files || files.length === 0) {
        setError('File is required');
        return false;
      }
      dispatchAction(event, 'add', files, name);
    } else dispatchAction(event, 'edit', files, name);

    return false;
  };

  return (
    <Modal title={`${type} font`} width={'w-5/12 max-w-xl'} bg={'bg-ev-yellow-1'} isOpen={show} onClose={closeModal}>
      <div className="row row-centered">
        {error && <AlertMessage type="danger" hideTag={true} text={error} extraClass="mb-4" />}

        <form role="form">
          <InputWidget label="Name:" onChange={updateState} placeholder="Name" value={name} />
          <div>
            <label htmlFor="private"> File: </label>
          </div>
          <div>
            <input type="file" id="font" name="font" accept=".woff2, .ttf, .woff, .otf" />
          </div>
          <hr />
          <div className="pt-6 flex gap-2">
            <PrimaryButton onClick={changeMetaData} text="Confirm" />
            <PrimaryButton onClick={closeModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
          </div>
        </form>
      </div>
    </Modal>
  );
}
