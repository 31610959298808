import { BillingForm, SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import React from 'react';
import validateEmail from 'shared/utils/validateEmail';

export const validateBillingForm = (
  form: BillingForm,
  setForm: React.Dispatch<React.SetStateAction<BillingForm>>,
  paymentProviderErrors = []
) => {
  let formIsValid = true;
  const validatedForm = form;

  const hasError = (array: any[], error: string) => {
    return array.indexOf(error) >= 0;
  };

  const stringTooShort = (value: string, length = 1) => {
    return !value || value.length < length;
  };

  const fieldCheck = (id: string, error: string, length?: number) => {
    if (stringTooShort(form[id].value, length)) {
      validatedForm[id].error = error;
      formIsValid = false;
    }
  };

  const fieldCheckPaymentProvider = (id: string, error: string) => {
    if (hasError(paymentProviderErrors, id)) {
      validatedForm[id].error = error;
      formIsValid = false;
    }
  };

  const fieldIsString = (id: string, error: string) => {
    if (/\d/.test(form[id].value)) {
      validatedForm[id].error = error;
      formIsValid = false;
    }
  };

  fieldIsString('first_name', 'You can only enter text into this field');
  fieldCheck('first_name', 'Card owner first name must be at least 2 characters long', 2);

  fieldIsString('last_name', 'You can only enter text into this field');
  fieldCheck('last_name', 'Card owner last name must be at least 2 characters long', 2);

  if (!validateEmail(form.email.value)) {
    form.email.error = 'The email you specified is not a valid email format';
    formIsValid = false;
  }

  fieldCheck('address1', 'Address line 1 is missing');
  fieldCheck('address2', 'Address line 2 is missing');
  fieldCheck('postal_code', 'Zip/Postal code is missing');

  fieldCheckPaymentProvider('postal_code', 'Zip/Postal code is invalid');
  fieldCheck('country', 'Country is missing');
  fieldCheckPaymentProvider('country', 'Country is invalid');

  fieldCheckPaymentProvider('number', 'Credit card number is invalid');
  fieldCheckPaymentProvider('month', 'Month is invalid');
  fieldCheckPaymentProvider('year', 'Year is invalid');
  fieldCheckPaymentProvider('cvv', 'Security code is invalid');

  if (!formIsValid) {
    setForm(validatedForm);
  }

  return formIsValid;
};

export const isSubmitDisabled = (form: BillingForm, billingData: any, isLoggedIn: boolean) => {
  let isDisabled = false;
  const requiredFields = ['address1', 'address2', 'postal_code', 'country'];
  const hasPaymentData = Boolean(billingData?.card_type && billingData?.last_four_digits);

  Object.values(form).forEach((field) => {
    if (requiredFields.includes(field.name) && field.value === '') {
      isDisabled = true;
    }
  });
  if (isLoggedIn) {
    return isDisabled || !hasPaymentData;
  } else return isDisabled;
};

export const generateAddress = (
  billingData: any,
  billingForm: BillingForm,
  setAddress: React.Dispatch<React.SetStateAction<string>>
) => {
  if (billingForm && billingForm.address1.value && billingForm.address2.value && billingForm.postal_code.value) {
    setAddress(`${billingForm.address1.value}, ${billingForm.postal_code.value}, ${billingForm.address2.value}`);
  } else if (billingData && billingData.address1 && billingData.postal_code && billingData.address2) {
    setAddress(`${billingData.address1}, ${billingData.postal_code}, ${billingData.address2}`);
  }
};

export const getPlanDescription = (plan: SubscriptionPlans) => {
  switch (plan) {
    case SubscriptionPlans.Starter:
      return ['Up to 100 projects', '5 users included', 'Custom branding'];
    case SubscriptionPlans.Business:
      return ['Up to 500 projects', '5 users included', 'Custom branding'];
    case SubscriptionPlans.Pro:
      return ['Up to 500 projects', '10 users included', '1 company theme'];
    case SubscriptionPlans.Storage:
      return ['Hosting of projects and embeds', 'Read-only access'];
  }
};
