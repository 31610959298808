/* eslint-disable react/no-unescaped-entities */
import { ProviderTypes } from 'pages/ChartEditorPage/meta/templates/types';
import { ThemeOptionProps } from 'pages/ThemesPage/components/Theme';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignCompanyThemeAction, getCompanyThemesAction, getThemesAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import { assignThemeMap, redrawProjectMap } from 'shared/meta/chooseTheme';
import { ChooseThemeProps, SelectedProps, ThemeMetaProps, TypeProps } from 'shared/types/chooseTheme';
import { getDefaultThemes } from 'shared/utils/themeHelper';
import Card from '../../../editor/ui/highed.card';
import { getEditorConfig } from '../../utils/editorHelper';
import WizardProjectPreview from '../data/WizardProjectPreview';
import WizardContainer from './WizardContainer';
import WizardLeftContainer from './WizardLeftContainer';
import WizardRightContainer from './WizardRightContainer';

export default function ChooseTheme(props: ChooseThemeProps) {
  const { cb } = props;

  const { team, division } = useSelector((state: RootState) => state.profile);
  const { themes } = useSelector((state: RootState) => state.layoutEditorPage);
  const { companyThemes, themeMeta, provider, type } = useSelector((state: RootState) => state.projectConfig);
  const { isMap } = useSelector((state: RootState) => state.chartEditorPage);
  const [showDefaults, setShowDefaults] = useState(true);
  const dispatch = useDispatch();
  const isLocationMap = provider === 'locationMap';

  const [selectedMeta, setSelectedMeta] = useState<SelectedProps>({
    selected: undefined,
    selectedOption: undefined
  });

  const { selected } = selectedMeta;

  const defaultThemes = getDefaultThemes(type as TypeProps, provider as ProviderTypes);

  const getThemes = (team: any, division: any) => dispatch(getThemesAction({ team, division, type }));
  const getCompanyThemes = () => dispatch(getCompanyThemesAction({}));
  const redrawProject = (noAnimation: boolean, noRedraw?: boolean) => {
    if (type == 'chart') {
      return dispatch(redrawProjectMap[type as TypeProps]({ noAnimation, noRedraw }));
    }
  };
  const assignTheme = (team: any, theme: ThemeOptionProps, cb: () => void) =>
    dispatch(assignThemeMap[(type || 'chart') as TypeProps]({ team, theme, cb }));

  const assignCompanyTheme = (selected: ThemeOptionProps) => dispatch(assignCompanyThemeAction({ selected }));

  useEffect(() => {
    getEditorConfig(team, division, !team).then((data) => {
      if (!isLocationMap && data?.length) {
        setShowDefaults(!data[0].hide_default_themes);
      }
    });

    getThemes(team, division);

    if (!isLocationMap) {
      getCompanyThemes();
    }

    if (themeMeta && Object.keys(themeMeta).length > 1) {
      setSelectedMeta({
        selected: (themeMeta as ThemeMetaProps).id,
        selectedOption: (themes as ThemeOptionProps[]).filter((d) => d.id === (themeMeta as ThemeMetaProps).id)[0]
      });
    } else {
      setSelectedMeta({
        selected: defaultThemes[0].id,
        selectedOption: defaultThemes[0]
      });
    }

    redrawProject(true, isMap);
  }, []);

  const selectTheme = (selected: ThemeOptionProps) => {
    setSelectedMeta({
      selected: selected.id,
      selectedOption: selected
    });

    assignTheme(team, selected, () => {
      if (type === 'table') {
        redrawProject(true);
      }
    });
  };

  const next = () => cb();

  const onDoubleClick = (theme: ThemeOptionProps) => {
    selectTheme(theme);
    next();
  };

  const onDoubleClickCompanyTheme = (theme: ThemeOptionProps) => {
    selectCompanyTheme(theme);
    next();
  };

  const selectCompanyTheme = (selected: ThemeOptionProps) => {
    setSelectedMeta({
      selected: selected.id,
      selectedOption: selected
    });

    assignCompanyTheme(selected);
  };

  return (
    <WizardContainer>
      <WizardLeftContainer text={'Choose a design template for your project.'} header={'Design'} subheader="Theme">
        <div className="project-grid-themes">
          {(companyThemes || []).map((theme: ThemeOptionProps, i) => {
            return (
              <div key={`master_theme_card_${i}`}>
                <Card
                  className={theme.id === selected ? 'active' : ''}
                  onClick={() => selectCompanyTheme(theme)}
                  onDoubleClick={() => onDoubleClickCompanyTheme(theme)}
                  thumbnail_url={theme.thumbnail_url}
                  name={theme.name}
                  wizard={true}
                />
              </div>
            );
          })}

          {(defaultThemes || []).map((theme, i) => {
            if (!showDefaults) return <></>;

            return (
              <div key={`theme_card_${i}`}>
                <Card
                  className={theme.id === selected ? 'active' : ''}
                  onClick={() => selectTheme(theme)}
                  thumbnail_url={theme.thumbnail_url}
                  name={theme.name}
                  onDoubleClick={() => onDoubleClick(theme)}
                  wizard={true}
                />
              </div>
            );
          })}

          {(themes || []).map((theme: ThemeOptionProps, i: number) => {
            return (
              <div key={`theme_card_${i}`}>
                <Card
                  className={theme.id === selected ? 'active' : ''}
                  onClick={() => selectTheme(theme)}
                  thumbnail_url={theme.thumbnail_url}
                  name={theme.name}
                  onDoubleClick={() => onDoubleClick(theme)}
                  wizard={true}
                />
              </div>
            );
          })}
        </div>
      </WizardLeftContainer>

      <WizardRightContainer showToolbar={false}>
        <WizardProjectPreview showBottomBar={true}>
          <div className="text-justify mt-4 ev-sm:block hidden">
            <div className="font-bold">Need to change your data or don&apos;t see a preview?</div>
            <div>
              Go back to step 2 and upload new data, or go back to step 1 and see if there&apos;s another chart type
              that can fit your data better.
            </div>
          </div>
        </WizardProjectPreview>
      </WizardRightContainer>
    </WizardContainer>
  );
}
