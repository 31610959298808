import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import Modal from 'shared/modal/Modal';
import { deleteDivisionAction, setTeamMembersAction, updateDivisionNameAction } from '../actions/teamMembersAction';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import InputWidget from 'shared/widgets/InputWidget';
import IconButton from 'shared/buttons/IconButton';
import TrashIcon from 'static/icons/trash.svg';

const EditSubteamModal = () => {
  const { divisions } = useSelector((state: RootState) => state.profile);
  const { activeDivision, editDivisionModalOpen } = useSelector((state: RootState) => state.teamMembersPage);
  const dispatch = useDispatch();

  const [subteamName, setSubteamName] = useState(activeDivision?.label);

  useEffect(() => {
    setSubteamName(activeDivision?.label);
  }, [activeDivision]);

  const handleSubteamNameChange = (value: any) => {
    setSubteamName(value.val);
  };

  const handleOnConfirm = () => {
    dispatch(updateDivisionNameAction({ divisions, divisionId: activeDivision.value, divisionName: subteamName }));
  };

  const handleOnClose = () => {
    dispatch(setTeamMembersAction({ editDivisionModalOpen: false }));
  };

  const handleSubteamDelete = () => {
    dispatch(deleteDivisionAction({ divisions, divisionId: activeDivision.value }));
  };

  return (
    <Modal
      title="Edit subteam"
      width="w-7/12 max-w-xl"
      bg="bg-ev-yellow-1"
      isOpen={editDivisionModalOpen}
      onClose={handleOnClose}
    >
      <InputWidget value={subteamName} onChange={handleSubteamNameChange} placeholder="Name" label="Name:" />
      <hr />
      <div className="pt-6 flex gap-4 justify-between">
        <div className="flex gap-x-4">
          <PrimaryButton onClick={handleOnConfirm} text="Confirm" />
          <PrimaryButton onClick={handleOnClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
        <IconButton
          icon={<TrashIcon />}
          className="flex items-center gap-1 font-medium hover:text-ev-baby-blue-2"
          buttonColor={ButtonColor.TransparentNavyBlue2}
          hoverText="Delete subteam"
          onClick={handleSubteamDelete}
        />
      </div>
    </Modal>
  );
};

export default EditSubteamModal;
