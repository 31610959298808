import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SelectItem } from 'shared/inputs/CustomSelect';

const useCategoriesAndSeries = (seriesAssignsIndex?: number) => {
  const { seriesAssigns } = useSelector((state: RootState) => state.projectConfig);
  const colHeaders = useSelector((state: RootState) => state.dataGridConfig.colHeaders);

  const categories: string[] = [],
    categoriesIndexes: number[] = [],
    series: string[] = [],
    seriesIndexes: number[] = [],
    categoriesLabels: Record<string, SelectItem> = {};

  for (const options of seriesAssigns) {
    Object.values(options).forEach((val: any) => {
      if (val.isData || val.isExtra) {
        if (val.rawValue) {
          series.push(val.value);
          seriesIndexes.push(val.rawValue[0]);
        }
      }
    });
  }
  Object.values(seriesAssigns[seriesAssignsIndex ?? 0] ?? {}).forEach((val: any) => {
    if (val.isLabel) {
      categories.push(val.value);
      categoriesIndexes.push(val.rawValue[0]);
      categoriesLabels[val.name] = { label: `Column ${val.value}`, value: val.value, index: val.rawValue[0] };
    }
  });

  return {
    categories,
    categoriesIndexes,
    categoriesLabels,
    series,
    seriesIndexes,
    columns: colHeaders
  };
};
export default useCategoriesAndSeries;
