import React from 'react';
import { addItemArrayAction } from '../../actions/chartEditor';
import { useDispatch } from 'react-redux';

type AddButtonProps = {
  elementKey: string;
};

export default (props: AddButtonProps) => {
  const { elementKey } = props;
  const dispatch = useDispatch();
  const addItem = () => {
    dispatch(
      addItemArrayAction({
        key: elementKey
      })
    );
  };

  return <span className="far fa-plus-square float-right relative top-[5px] pr-0.5" onClick={addItem} />;
};
