import { TemplateTypeProps } from '../../types';
import PolarArea from './PolarArea';
import PolarLine from './PolarLine';

const PolarAreaTemplate: TemplateTypeProps = {
  description: '',
  icon: '',
  sampleData: [],
  templates: {
    'Polar area': PolarArea,
    'Polar line': PolarLine
  }
};

export default PolarAreaTemplate;
