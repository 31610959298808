import { get } from 'lodash';
import React from 'react';
import Accordion from 'shared/Accordion';
import NumberInputWidget from './NumberInputWidget';
import { GenericInputProps, InputChangeParams } from 'shared/types/commonPropTypes';

export type FourInputProps = {
  options?: [string, string, string, string];
};

export default (props: GenericInputProps & FourInputProps) => {
  const { option } = props;
  const optionClassName = {
    className: 'bg-white rounded'
  };

  const onChange = (index: number, newValue: InputChangeParams) => {
    if (option && option?.fourInputs?.options) {
      props.onChange({
        val: option.suffix ? newValue.val + option.suffix : newValue.val,

        optional: {
          id: option.fourInputs.options[index]
        }
      });
    }
  };

  const extractOption = (index: number) => {
    if (option?.fourInputs?.options) {
      const attr = get(props.aggregatedOptions, `${option.fourInputs.options[index]}`);

      if (attr) {
        return parseInt(attr, 10);
      } else if (option?.default && Array.isArray(option.default)) {
        return parseInt(option.default[index], 10);
      }

      return option?.default ?? 0;
    }
  };

  const extractValues = () => {
    // const { option } = props;
    if (option?.fourInputs?.options) {
      return option.fourInputs.options.map((_: unknown, index) => extractOption(index) ?? 0).join(', ');
    }
  };

  return (
    <Accordion
      useInternalSelect={true}
      defaultSelection={false}
      headerText={props.label}
      smallHeaderText={true}
      selectedChildren={extractValues()}
      accordionClasses="bg-white"
      childrenClasses="flex-wrap"
    >
      <div className="flex w-full">
        <NumberInputWidget
          label={<i className="fa fa-arrow-up" />}
          value={extractOption(0) ?? 0}
          onChange={(newValue) => onChange(0, newValue)}
          className={'bg-ev-grey h-10'}
          option={optionClassName}
        />
        <NumberInputWidget
          label={<i className="fa fa-arrow-right" />}
          value={extractOption(1) ?? 0}
          onChange={(newValue) => onChange(1, newValue)}
          className={'bg-ev-grey h-10'}
          option={optionClassName}
        />
      </div>
      <div className="flex w-full">
        <NumberInputWidget
          label={<i className="fa fa-arrow-down" />}
          value={extractOption(2) ?? 0}
          onChange={(newValue) => onChange(2, newValue)}
          className={'bg-ev-grey h-10'}
          option={optionClassName}
        />
        <NumberInputWidget
          label={<i className="fa fa-arrow-left" />}
          value={extractOption(3) ?? 0}
          onChange={(newValue) => onChange(3, newValue)}
          className={'bg-ev-grey h-10'}
          option={optionClassName}
        />
      </div>
    </Accordion>
  );
};
