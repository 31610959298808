import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const EditorError = () => {
  const { editorError } = useSelector((state: RootState) => state.chartEditorPage);
  const editorErr = (editorError ?? '').toString();

  return (
    <>
      {editorErr && (
        <div className="fixed bottom-0 p-4 bg-ev-error text-white font-bold w-full z-[100000]">{editorErr}</div>
      )}
    </>
  );
};

export default EditorError;
