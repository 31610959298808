export default {
  series: 4,
  data: 'Category;1800;1900;2000;2100\nAfrica;20;18;16;10\nAmerica;18;10;12;8\nAsia;16;12;14;6\nEurope;14;8;10;4\nOceania;12;2;8;2',
  assignDataFields: [
    {
      labels: 'A',
      values: 'B'
    },
    {
      labels: 'A',
      values: 'C'
    },
    {
      labels: 'A',
      values: 'D'
    },
    {
      labels: 'A',
      values: 'E'
    }
  ],
  seriesMapping: [
    {
      x: 0,
      y: 1
    },
    {
      x: 0,
      y: 2
    },
    {
      x: 0,
      y: 3
    },
    {
      x: 0,
      y: 4
    }
  ]
};
