import * as React from 'react';

type LinkProps = {
  link: string;
  text: string;
  anchorLink?: boolean;
};

const Link = (props: LinkProps) => {
  return (
    <a
      href={props.link}
      className="text-ev-navy-blue-2"
      target={props.anchorLink ? '_self' : '_blank'}
      rel="noreferrer"
    >
      {props.text}
    </a>
  );
};

export default Link;
