import React from 'react';
import { InputChangeHandler } from 'shared/types/commonPropTypes';

type CheckboxInputProps = {
  onChange: InputChangeHandler;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  checked?: boolean;
  labelAlign?: 'left' | 'right';
  name?: string | React.ReactNode;
  disabled?: boolean;
  tabIndex?: number;
  className?: string;
  labelClass?: string;
  label?: string;
};

const CheckboxInput = ({
  name,
  label,
  onChange,
  checked,
  disabled,
  tabIndex,
  className,
  labelAlign = 'right',
  labelClass,
  onClick
}: CheckboxInputProps) => {
  return (
    <label className={`group flex items-center m-0 ev-checkbox ${className}`}>
      {labelAlign === 'left' && <span className={labelClass ?? 'text-xs'}> {label || ''} </span>}
      <input
        type="checkbox"
        name={name as string}
        onChange={onChange}
        disabled={disabled}
        tabIndex={tabIndex}
        className="checkbox-widget h-6 w-6 grid appearance-none bg-white m-0 font-inherit text-ev-navy-blue-2 border-2 border-ev-navy-blue-2 rounded-md hover:bg-ev-baby-blue-2 checked:bg-ev-navy-blue-2"
        checked={checked}
        onClick={onClick}
      />
      {labelAlign === 'right' && <span className={labelClass ?? 'text-xs pl-1'}> {label || ''} </span>}
    </label>
  );
};

export default CheckboxInput;
