import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getAvailableChannelForType } from 'shared/wizard/utils/channelHelper';
import Accordion from '../../../Accordion';
import { ChannelOptions } from '../../meta/ChannelOptions';
import { TypeProps } from 'shared/types/chooseTheme';
import ChannelSectionContent from './ChannelSectionContent';

type DownloadImageProps = {
  onPublish: () => void;
  smallHeaderText?: boolean;
  childrenClasses?: string;
  accordionClasses?: string;
};

const ChannelSection = (props: DownloadImageProps) => {
  const { type } = useSelector((state: RootState) => state.projectConfig);
  const [channelOptions, setChannelOptions] = useState(ChannelOptions);

  useEffect(() => {
    setChannelOptions(
      channelOptions.filter((option) => getAvailableChannelForType(type as TypeProps).includes(option.id))
    );
  }, [type]);

  return (
    <Accordion
      accordionClasses="mt-0 bg-ev-grey"
      useInternalSelect={true}
      headerText="Platform"
      defaultSelection={true}
      smallHeaderText={props.smallHeaderText}
    >
      <ChannelSectionContent onPublish={props.onPublish} />
    </Accordion>
  );
};

export default ChannelSection;
