import React from 'react';

export default function PlusIcon() {
  return (
    <div
      className={`
      content-centered w-6 h-6 rounded-full bg-ev-baby-blue-2
      group-hover:bg-white 
      group-active:bg-ev-baby-blue-2 group-active:text-ev-navy-blue-2
      group-focus:text-ev-navy-blue-2
    `}
    >
      <i className="fa fa-plus text-xs" />
    </div>
  );
}
