import React from 'react';
import { useDispatch } from 'react-redux';
import { setAction } from 'redux/actions/projectConfig';

export default (props) => {
  const { option } = props;
  const dispatch = useDispatch();

  const switchActiveSection = (section) => {
    dispatch(
      setAction({
        activeSection: section
      })
    );
  };

  const onClick = () => {
    switchActiveSection('advanced');
    setTimeout(() => {
      const button = document.querySelector('.left-advance-panel .node#lang');
      if (button) button.click();
    }, 1000);
  };

  return (
    <>
      <div className="highed-field-container notice font-medium">
        <div className="text-left">
          {option.default}
          <span className="action-link" onClick={onClick}>
            advanced options
          </span>
          {/* make this generic later */}
        </div>
      </div>
    </>
  );
};
