import React from 'react';
import { ActionMeta, components } from 'react-select';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { TrashIcon } from '@heroicons/react/outline';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { selectMultiStyles } from 'shared/utils/selectStylesHelper';
import Close from 'static/icons/close.svg';
import Tooltip from 'shared/tooltip/Tooltip';

export type SelectItem = { label: string; value: number; disabled?: boolean; index?: number };

export type SelectMultiWidgetProps = {
  label: string;
  options: SelectItem[];
  onChange: (newValue: SelectItem[] | null, actionMeta: ActionMeta<SelectItem>) => void;
  styles: Record<string, unknown>;
  containerClasses?: string;
  labelClasses?: string;
  value?: SelectItem;
  selectOptions?: any;
  onClick: (tag: SelectItem) => void;
  allowCreateNewTags?: boolean;
};

export default function SelectMultiWidget(props: SelectMultiWidgetProps) {
  const { label, allowCreateNewTags = false } = props;
  const value = props.value ?? props.options[0];
  const maxLength = 5;

  const handleClick = (tag: { value: number; label: string }) => props.onClick(tag);

  const DropdownIndicator = (props: any) => {
    const selectedTag = props.selectProps.options.filter((obj: any) => obj.label === props.children);
    return (
      <components.Option {...props}>
        {props.label}
        {!props.children.includes('Create') && (
          <SvgIconButton
            Icon={TrashIcon}
            width={20}
            height={20}
            buttonColor={ButtonColor.Transparent}
            onClick={() => handleClick(selectedTag[0])}
            buttonClasses={'border-none transition-none active:bg-transparent hover:bg-transparent'}
          />
        )}
      </components.Option>
    );
  };

  const CustomMultiValue = (props: any) => {
    const selectedTag = props.selectProps.options.filter((obj: any) => obj.label === props.children);
    const tooltipText = props.selectProps.getOptionLabel(props.data);
    const truncatedText =
      props.selectProps.getOptionLabel(props.data).length > maxLength
        ? props.selectProps.getOptionLabel(props.data).slice(0, maxLength) + '...'
        : props.selectProps.getOptionLabel(props.data);

    return (
      <components.MultiValue key={props.value} {...props}>
        <div className="flex">
          <Tooltip
            tippyProps={{
              content: tooltipText,
              placement: 'bottom',
              zIndex: 999999
            }}
          >
            <div className="font-bold text-xs text-ev-navy-blue-2 mr-1 self-center">{truncatedText}</div>
          </Tooltip>
          <SvgIconButton
            Icon={TrashIcon}
            width={16}
            height={16}
            buttonColor={ButtonColor.Transparent}
            onClick={() => handleClick(selectedTag[0])}
            tabIndex={-1}
            className="border-none"
          />
        </div>
      </components.MultiValue>
    );
  };

  const ClearIndicator = (props: any) => {
    return (
      <components.ClearIndicator {...props}>
        <Close className="text-stale-900 hover:text-gray-700 h-3 w-3" />
      </components.ClearIndicator>
    );
  };

  const CommonSelectProps = {
    placeholder: label,
    components: {
      MultiValue: CustomMultiValue,
      Option: DropdownIndicator,
      ClearIndicator
    },
    onChange: (selectedOptions: any, actionMeta: any) => {
      const mutableSelectedOptions = selectedOptions as SelectItem[];
      const castedActionMeta = actionMeta as ActionMeta<SelectItem>;
      props.onChange(mutableSelectedOptions, castedActionMeta);
    },
    options: props.selectOptions,
    value: value,
    isMulti: true,
    styles: selectMultiStyles
  };

  return (
    <div className={`relative ${props.containerClasses}`}>
      {allowCreateNewTags ? <CreatableSelect {...CommonSelectProps} /> : <Select {...CommonSelectProps} />}
    </div>
  );
}
