import React from 'react';

export default (props) => {
  const { value: inputValue } = props;

  const onClick = (e) => {
    const value = e.target.getAttribute('data-value');
    props.onChange({ val: value });
  };

  const value = inputValue || 'bottomRight';
  return (
    <div className={'align-widget'}>
      <div className="top">
        <div onClick={onClick} className={'option left ' + (value === 'topLeft' ? 'active' : '')} data-value="topLeft">
          <i data-value="topLeft" className="fa fa-arrow-left" />
        </div>
        <div
          onClick={onClick}
          className={'option right ' + (value === 'topRight' ? 'active' : '')}
          data-value="topRight"
        >
          <i data-value="topRight" className="fa fa-arrow-right" />
        </div>
      </div>
      <div className="bottom">
        <div
          onClick={onClick}
          className={'option left ' + (value === 'bottomLeft' ? 'active' : '')}
          data-value="bottomLeft"
        >
          <i data-value="bottomLeft" className="fa fa-arrow-left" />
        </div>
        <div
          onClick={onClick}
          className={'option right ' + (value === 'bottomRight' ? 'active' : '')}
          data-value="bottomRight"
        >
          <i data-value="bottomRight" className="fa fa-arrow-right" />
        </div>
      </div>
    </div>
  );
};
