export default {
  'option.text.title': [
    {
      id: 'title',
      options: [
        {
          pid: 'title.text',
          id: 'title.text',

          placeholder: '[Input Text Here]'
        },
        { type: 'separator' },
        {
          pid: 'title.style.font',
          id: 'title.style',
          type: 'fontpicker'
        },
        {
          id: 'title.style',
          pid: 'title.font.style',
          type: 'fontstyler',
          custom: {
            fields: {
              bold: 1,
              italic: 1,
              color: 1,
              fontSize: 1,
              align: 1
            }
          }
        },
        {
          type: 'separator'
        },
        {
          pid: 'title.style.backgroundColor',
          id: 'title.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        },
        {
          pid: 'title.style.background',
          id: 'title.style.background',
          type: 'link'
        }
      ]
    }
  ],

  'option.text.subtitle': [
    {
      id: 'subtitle',
      options: [
        {
          pid: 'subtitle.text',
          id: 'subtitle.text',

          placeholder: '[Input Text Here]'
        },
        {
          type: 'separator'
        },
        {
          pid: 'subtitle.style.font',
          id: 'subtitle.style',
          type: 'fontpicker'
        },
        {
          id: 'subtitle.style',
          pid: 'subtitle.font.style',
          type: 'fontstyler',
          custom: {
            fields: {
              bold: 1,
              italic: 1,
              color: 1,
              fontSize: 1,
              align: 1
            }
          }
        },
        {
          type: 'separator'
        },
        {
          pid: 'subtitle.style.backgroundColor',
          id: 'subtitle.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        }
      ]
    }
  ],

  'option.text.caption': [
    {
      id: 'caption',
      options: [
        {
          pid: 'caption.text',
          id: 'caption.text',
          type: 'text',

          placeholder: '[Input Text Here]'
        },
        { type: 'separator' },
        {
          pid: 'caption.style.font',
          id: 'caption.style',
          type: 'fontpicker'
        },
        {
          id: 'caption.style',
          pid: 'caption.font.style',
          type: 'fontstyler',
          custom: {
            fields: {
              bold: 1,
              italic: 1,
              color: 1,
              fontSize: 1,
              align: 1
            }
          }
        },
        { type: 'separator' },
        {
          pid: 'caption.style.backgroundColor',
          id: 'caption.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        }
      ]
    }
  ],

  'option.text.layout': [
    {
      id: 'layout_specific',
      relevantTo: ['carousel', 'tabs', 'dropdown', 'slider'],
      options: [
        {
          pid: 'layout.visualizations',

          id: 'layout',
          type: 'chartarray',
          layoutType: ['carousel']
        },
        {
          pid: 'layout.visualizations',
          id: 'layout',
          type: 'chartarray',
          editableName: true,
          layoutType: ['tabs', 'dropdown']
        },
        {
          pid: 'layout.buttonPosition',
          id: 'layout.buttonPosition',
          default: 'leftRight',
          type: 'select',
          values: [
            {
              label: 'Left/Right',
              value: 'leftRight'
            },
            {
              label: 'Top Right',
              value: 'topRight'
            },
            {
              label: 'Top Left',
              value: 'topLeft'
            }
          ],
          clearFieldsWhenSet: ['style.backgroundColor'],
          layoutType: ['carousel'],

          postFields: [
            {
              optionNeeded: ['leftRight', 'topRight', 'topLeft'],
              className: 'mx-4',
              options: [
                {
                  pid: 'layout.style.color',
                  id: 'layout.style.color',
                  default: '#000',
                  type: 'color',
                  layoutType: ['carousel']
                }
              ]
            },
            {
              optionNeeded: ['topRight', 'topLeft'],
              className: 'mx-4',
              options: [
                {
                  pid: 'layout.style.backgroundColor',
                  id: 'layout.style.backgroundColor',
                  default: '#000',
                  type: 'color',
                  layoutType: ['carousel']
                }
              ]
            }
          ]
        },
        {
          pid: 'layout.animation',
          id: 'layout.animation',
          type: 'checkbox',
          layoutType: ['tabs'],
          default: true
        },
        {
          id: 'layout.auto',
          pid: 'layout.auto',
          type: 'checkbox',
          default: false,
          layoutType: ['carousel'],
          postFields: [
            {
              optionNeeded: [true],
              className: 'mx-4',
              options: [
                {
                  pid: 'layout.animDuration',
                  id: 'layout.animDuration'
                }
              ]
            }
          ]
        },
        {
          id: ['layout.style.background', 'layout.style.borderColor'],
          default: '#000',
          pid: 'layout.slidercolor',
          type: 'color',
          layoutType: ['slider']
        }
      ]
    }
  ],

  'option.text.canvas': [
    {
      id: 'canvas',
      options: [
        {
          pid: 'canvas.style.padding',
          id: 'canvas.style.padding',
          type: 'fourinputs',
          fourInputs: {
            options: [
              'canvas.style.paddingTop',
              'canvas.style.paddingRight',
              'canvas.style.paddingBottom',
              'canvas.style.paddingLeft'
            ]
          },
          default: [0, 0, 0, 0]
        },
        {
          pid: 'canvas.style.backgroundColor',
          id: 'canvas.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        },
        {
          pid: 'canvas.style.background',
          id: 'canvas.style.background',
          type: 'link'
        }
      ]
    }
  ],

  'option.text.logo': [
    {
      id: 'logo',
      options: [
        {
          pid: 'logo.url',
          id: 'logo.url',
          type: 'select',
          useLogos: true
        },
        {
          pid: 'logo.position',
          id: 'logo.position',
          type: 'placementalignwidget',
          default: 'bottomRight',
          custom: {
            placement: {
              topLeft: 1,
              bottomLeft: 1,
              topRight: 1,
              bottomRight: 1
            }
          }
        },
        {
          pid: 'logo.width',
          id: 'logo.width',
          type: 'number',
          suffix: '%',
          default: '100%'
        },
        {
          pid: 'logo.minWidth',
          id: 'logo.minWidth',
          type: 'number'
        },
        {
          pid: 'logo.style.padding',
          id: 'logo.style.padding',
          type: 'fourinputs',
          fourInputs: {
            options: [
              'logo.style.paddingTop',
              'logo.style.paddingRight',
              'logo.style.paddingBottom',
              'logo.style.paddingLeft'
            ]
          }
        },
        {
          type: 'subtext',
          text: 'Logos can be added through the ',
          linkText: 'customize editor page',
          pathLink: 'profileCustomiseEditor'
        }
      ]
    }
  ]
};
