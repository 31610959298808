export default {
  series: 1,
  data: 'hc-a2;name;x;y;value\nAL;Alabama;6;7;4849377\nAK;Alaska;0;0;737732\nAZ;Arizona;5;3;6745408\nAR;Arkansas;5;6;2994079\nCA;California;5;2;39250017\nCO;Colorado;4;3;5540545\nCT;Connecticut;3;11;3596677\nDE;Delaware;4;9;935614\nDC;District of Columbia;4;10;7288000\nFL;Florida;8;8;20612439\nGA;Georgia;7;8;10310371\nHI;Hawaii;8;0;1419561\nID;Idaho;3;2;1634464\nIL;Illinois;3;6;12801539\nIN;Indiana;3;7;6596855\nIA;Iowa;3;5;3107126\nKS;Kansas;5;5;2904021\nKY;Kentucky;4;6;4413457\nLA;Louisiana;6;5;4649676\nME;Maine;0;11;1330089\nMD;Maryland;4;8;6016447\nMA;Massachusetts;2;10;6811779\nMI;Michigan;2;7;9928301\nMN;Minnesota;2;4;5519952\nMS;Mississippi;6;6;2984926\nMO;Missouri;4;5;6093000\nMT;Montana;2;2;1023579\nNE;Nebraska;4;4;1881503\nNV;Nevada;4;2;2839099\nNH;New Hampshire;1;11;1326813\nNJ;New Jersey;3;10;8944469\nNM;New Mexico;6;3;2085572\nNY;New York;2;9;19745289\nNC;North Carolina;5;9;10146788\nND;North Dakota;2;3;739482\nOH;Ohio;3;8;11614373\nOK;Oklahoma;6;4;3878051\nOR;Oregon;4;1;3970239\nPA;Pennsylvania;3;9;12784227\nRI;Rhode Island;2;11;1055173\nSC;South Carolina;6;8;4832482\nSD;South Dakota;3;4;853175\nTN;Tennessee;5;7;6651194\nTX;Texas;7;4;27862596\nUT;Utah;5;4;2942902\nVT;Vermont;1;10;626011\nVA;Virginia;5;8;8411808\nWA;Washington;2;1;7288000\nWV;West Virginia;4;7;1850326\nWI;Wisconsin;2;5;5778708\nWY;Wyoming;3;3;584153',
  seriesMapping: [
    {
      'hc-a2': 0,
      name: 1,
      x: 2,
      y: 3,
      value: 4
    }
  ],
  assignDataFields: [
    {
      country_code: 'A',
      name: 'B',
      x: 'C',
      y: 'D',
      value: 'E'
    }
  ],
  seriesOptions: [
    {
      type: 'tilemap',
      keys: ['hc-a2', 'x', 'y', 'value']
    }
  ],
  customizedOptions: {
    colorAxis: {
      dataClasses: [
        {
          from: 0,
          to: 1000000,
          color: '#F9EDB3',
          name: '< 1M'
        },
        {
          from: 1000000,
          to: 5000000,
          color: '#FFC428',
          name: '1M - 5M'
        },
        {
          from: 5000000,
          to: 20000000,
          color: '#FF7987',
          name: '5M - 20M'
        },
        {
          from: 20000000,
          color: '#FF2371',
          name: '> 20M'
        }
      ]
    }
  },
  seriesTemplate: ['tilemap']
};
