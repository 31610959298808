import React from 'react';
import PrimaryButton from 'shared/buttons//PrimaryButton';
import Modal from 'shared/modal/Modal';

type PermissionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function PermissionModal({ isOpen, onClose }: PermissionModalProps) {
  return (
    <Modal
      title="Access denied"
      width="w-7/11 max-w-xl pb-0"
      bg="bg-ev-yellow-1"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div>
        <p className="m-0 tracking-tight">
          You do not have permission to access this feature. Please contact your team administrator to request access.
        </p>
        <hr />
        <div className="pt-4 flex">
          <PrimaryButton onClick={() => onClose()} text="Close" className="py-1.5 text-base" />
        </div>
      </div>
    </Modal>
  );
}
