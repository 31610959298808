import { TemplateProps } from '../types';

const MapBubble: TemplateProps = {
  title: 'Bubble',
  description: [
    'The bubble map uses circles of different size to represent a numeric value on a territory. It displays one bubble per region. The bubble is displayed in the baricentre of the region).'
  ],
  constructor: 'Map',
  thumbnail: 'mapbubble.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {},
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    xAxis: {
      visible: false,
      type: 'linear'
    },
    yAxis: {
      visible: false
    },
    legend: {
      enabled: false
    },
    colorAxis: {
      type: 'logarithmic',
      minColor: '#EEEEFF',
      maxColor: '#000022',
      stops: [
        [0, '#EFEFFF'],
        [0.67, '#FF495E'],
        [1, '#000022']
      ]
    },
    plotOptions: {
      series: {
        lineWidth: 0,
        minSize: 4,
        maxSize: 60,
        borderWidth: 1,
        marker: {
          enabled: true
        }
      }
    },
    series: [
      {
        type: 'map'
      },
      {
        joinBy: 'hc-key',
        type: 'mapbubble'
      }
    ]
  },
  dataValidator: false,
  sampleSet: 'mapbubble',
  loadForEachSeries: false,
  advancedParent: 'Bubble',
  defaultDataType: 'bubbleMap',
  isMap: true,
  basic: {
    order: 3,
    thumbnail: 'bubble_map.svg',
    icon: 'BubbleMap'
  }
};

export default MapBubble;
