export type Role = {
  id: number;
  name: string;
};

export enum RolesModalConfigType {
  Add = 'add',
  Remove = 'remove',
  Edit = 'edit'
}

export type RolePermission = {
  id: number;
  name: string;
  description: string;
  title: string;
};

export enum RolePermissions {
  RoleManagement = 1,
  ApiKeyManagement = 2,
  TeamManagement = 3,
  CreateChart = 4,
  ViewCharts = 6,
  ChartTrash = 7,
  DuplicateChart = 8,
  Publish = 9,
  ViewUsers = 11,
  ThemeManagement = 12,
  EditChartMeta = 13,
  SubteamManagement = 14,
  CustomizeEditor = 15,
  AllSubteams = 16
}

enum PermissionCategory {
  General = 'General',
  Administrative = 'Administrative'
}

type RolePermissionsCategorized = Record<PermissionCategory, RolePermissions[]>;

export const rolePermissionsCategorized: RolePermissionsCategorized = {
  [PermissionCategory.General]: [
    RolePermissions.ViewCharts,
    RolePermissions.CreateChart,
    RolePermissions.Publish,
    RolePermissions.DuplicateChart,
    RolePermissions.ChartTrash,
    RolePermissions.ThemeManagement,
    RolePermissions.ViewUsers,
    RolePermissions.AllSubteams,
    RolePermissions.EditChartMeta
  ],
  [PermissionCategory.Administrative]: [
    RolePermissions.TeamManagement,
    RolePermissions.SubteamManagement,
    RolePermissions.RoleManagement,
    RolePermissions.CustomizeEditor,
    RolePermissions.ApiKeyManagement
  ]
};
