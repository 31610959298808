import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { TableColumnHeadersType } from 'shared/types/tableModels';

type TableCellProps = {
  cell: ReactNode;
  index: number;
  columnHeaders: TableColumnHeadersType;
};

const TableCell = ({ cell, index, columnHeaders }: TableCellProps) => {
  const isFirst = index === 0;
  const isLast = index + 1 === columnHeaders.length;
  const tableRowClasses = classNames(
    'h-unset align-middle px-4 py-1 block ev-md:h-14 ev-md:table-cell ev-sm:pl-6 ev-md:py-2',
    {
      'pt-4 ev-md:pl-10 ev-md:pt-2': isFirst,
      'ev-md:pl-4': !isFirst,
      'pb-4 ev-md:pb-2': isLast
    }
  );
  const columnHeader = columnHeaders[index];

  return (
    <td className={tableRowClasses} key={index}>
      {typeof columnHeader !== 'object' && columnHeader !== '' && columnHeader !== undefined ? (
        <span className="ev-md:hidden">
          <b>{columnHeader}: </b>
        </span>
      ) : (
        ''
      )}{' '}
      {cell}
    </td>
  );
};

export default TableCell;
