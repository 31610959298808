import React, { Component } from 'react';

export default class BorderStyleMenu extends React.Component {
  constructor(props) {
    super(props);

    this.setStyle = this.setStyle.bind(this);

    this.state = {
      types: {
        thin: {
          Width: '1px',
          Style: 'solid',
        },
        medium: {
          Width: '2px',
          Style: 'solid',
        },
        thick: {
          Width: '3px',
          Style: 'solid',
        },
        dashed: {
          Width: '1px',
          Style: 'dashed',
        },
        dotted: {
          Width: '1px',
          Style: 'dotted',
        },
      },
    };
  }

  componentDidMount() {}

  setStyle(e) {
    const target = e.currentTarget;
    const { types } = this.state;
    const type = target.getAttribute('data-value');

    this.props.setBorderStyle(
      this.props.dataOptions,
      this.props.activeBufferCell,
      this.props.activeSelectedBufferCell,
      types[type]
    );
    this.props.styleClick();
  }

  render() {
    const { showStyles } = this.props;
    return (
      <div className={'border-style-toolbar ' + (showStyles ? ' active' : '')}>
        <div className="border-style-container" data-value="thin" onClick={this.setStyle}>
          <div className="thin" />
        </div>

        <div className="border-style-container" data-value="medium" onClick={this.setStyle}>
          <div className="medium" />
        </div>

        <div className="border-style-container" data-value="thick" onClick={this.setStyle}>
          <div className="thick" />
        </div>

        <div className="border-style-container" data-value="dashed" onClick={this.setStyle}>
          <div className="dashed" />
        </div>

        <div className="border-style-container" data-value="dotted" onClick={this.setStyle}>
          <div className="dotted" />
        </div>
      </div>
    );
  }
}
