import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import CustomSelect, { customSelectColors } from 'shared/inputs/CustomSelect';
import Modal from 'shared/modal/Modal';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import { InputChangeParams } from 'shared/types/commonPropTypes';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import InputWidget from 'shared/widgets/InputWidget';
import { checkPlanPermission } from 'utils/profileHelper';
import ErrorMessage from '../../shared/message/ErrorMessage';
import {
  addRoleAction,
  deleteRoleAction,
  getPermissionsAction,
  getRolePermissionsAction,
  setAction,
  setRolePermissionsAction,
  updateRoleAction
} from './actions/rolesPage';
import RolesPermissions from './components/RolesPermissions';
import { Role, RolesModalConfigType } from './types/rolesModels';

export default function RolesPage() {
  const dispatch = useDispatch();
  const { groups, team } = useSelector((state: RootState) => state.profile);
  const { error, unsavedChanges, roleId, modalConfig, rolePermissions, rolePermissionsDb, newRoleName, permissions } =
    useSelector((state: RootState) => state.rolesPage);
  const [activeRole, setActiveRole] = useState<Role>(groups[0]);

  const loadRolePermissions = (roleId?: number) => {
    dispatch(getRolePermissionsAction({ teamId: team.id, roleId }));
  };

  useEffect(() => {
    dispatch(getPermissionsAction());
    if (groups && groups.length) loadRolePermissions(groups[0].id);
  }, []);

  useEffect(() => {
    if (activeRole) loadRolePermissions(activeRole.id);
  }, [activeRole]);

  useEffect(() => {
    setActiveRole(groups.filter((g: Role) => g.id === roleId)[0] || groups[0]);
  }, [groups, roleId]);

  const addRole = () => {
    dispatch(addRoleAction({ team, newRoleName }));
  };

  const editRole = () => {
    dispatch(updateRoleAction({ team, roles: groups, roleId: activeRole.id, newRoleName }));
  };

  const deleteRole = () => {
    dispatch(deleteRoleAction({ team, roleId: activeRole.id }));
  };

  const setModal = (modalConfig: RolesModalConfigType) => {
    dispatch(setAction({ modalConfig }));
  };

  const closeModal = () => {
    dispatch(
      setAction({
        modalConfig: '',
        newRoleName: ''
      })
    );
  };

  const onChangeNewRoleName = ({ val }: InputChangeParams) => {
    dispatch(setAction({ newRoleName: val }));
  };

  const saveRolePermissions = () => {
    dispatch(
      setRolePermissionsAction({
        team,
        permissions,
        roleId: activeRole.id,
        rolePermissions,
        rolePermissionsDb
      })
    );
  };

  const hasPermission = checkPlanPermission('team_management') || false;

  const modalContent = {
    add: (
      <div>
        <InputWidget label="Role name" onChange={onChangeNewRoleName} value={newRoleName} />
        <hr />
        <div className="pt-6 flex gap-2">
          <PrimaryButton onClick={addRole} text="Confirm" />
          <PrimaryButton onClick={closeModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
      </div>
    ),
    edit: (
      <div>
        <InputWidget label="Role name" onChange={onChangeNewRoleName} value={newRoleName} placeholder="Ex: 'editors'" />
        <hr />
        <div className="pt-6 flex gap-2">
          <PrimaryButton onClick={editRole} text="Confirm" />
          <PrimaryButton onClick={closeModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
      </div>
    ),
    remove: (
      <div>
        <p>Are you sure you want to delete the {activeRole && <b>{activeRole.name}</b>} role?</p>
        <hr />
        <div className="pt-6 flex gap-2">
          <PrimaryButton onClick={deleteRole} text="Confirm" />
          <PrimaryButton onClick={closeModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
      </div>
    )
  }[modalConfig as RolesModalConfigType];

  const title = {
    add: 'Add role',
    edit: 'Edit role',
    remove: 'Delete role'
  }[modalConfig as RolesModalConfigType];

  return (
    <ProfilePageContainer
      title="Roles"
      header="Roles"
      description="Create different user types and assign permissions."
    >
      <Modal
        title={title}
        width="w-7/12 max-w-xl"
        bg="bg-ev-yellow-1"
        isOpen={Boolean(modalConfig)}
        onClose={closeModal}
      >
        {modalContent}
      </Modal>

      <ProfilePageSectionContainer>
        <ErrorMessage error={error} />
        <section className="bg-white p-4 mb-6 rounded flex gap-4 ev-sm:items-end ev-sm-max:flex-col shadow-ev-standard">
          <div className="min-w-[200px]">
            <p className="mb-2 text-base ev-navy-blue font-bold">Choose a role:</p>

            {activeRole && groups && (
              <CustomSelect
                options={groups.map((g: Role) => ({ value: g.id, label: g.name }))}
                value={{ label: activeRole.name, value: String(activeRole.id) }}
                onChange={(val) => loadRolePermissions(Number(val?.value))}
                styles={editorStyles}
                backgroundColor={customSelectColors.grey}
              />
            )}
          </div>

          <PrimaryButton
            text="Add role"
            onClick={() => setModal(RolesModalConfigType.Add)}
            buttonColor={ButtonColor.NavyBlue}
            showSubscriptionLock={!hasPermission}
            className="h-10"
          />

          <PrimaryButton
            text="Edit"
            onClick={() => setModal(RolesModalConfigType.Edit)}
            showSubscriptionLock={!hasPermission}
            buttonColor={ButtonColor.WhiteBordered}
            className="h-10"
          />
          <PrimaryButton
            text="Delete"
            onClick={() => setModal(RolesModalConfigType.Remove)}
            showSubscriptionLock={!hasPermission}
            buttonColor={ButtonColor.WhiteBordered}
            className="h-10"
          />
        </section>
        <section className="py-8 bg-white rounded justify-center flex relative shadow-ev-standard">
          {activeRole && permissions ? (
            <RolesPermissions
              permissions={permissions}
              rolePermissions={rolePermissions}
              activeRole={activeRole}
              unsavedChanges={unsavedChanges}
              saveChanges={saveRolePermissions}
              onReset={() => {
                loadRolePermissions(activeRole.id);
                dispatch(setAction({ unsavedChanges: false }));
              }}
            />
          ) : (
            <p>No roles found. Please add a role.</p>
          )}
        </section>
      </ProfilePageSectionContainer>
    </ProfilePageContainer>
  );
}
