import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ChartEditor from 'pages/ChartEditorPage/components/ChartEditor';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import {
  initEditorAction,
  resetStateAction as resetChartState,
  showAnnotationInMenuAction,
  updateCustomizedAction
} from 'pages/ChartEditorPage/actions/chartEditor';
import { resetStateAction } from 'pages/LayoutEditorPage/actions/layoutEditor';
import { resetProjectConfigAction } from 'redux/actions/projectConfig';
import { cloneDeep, isArray } from 'lodash';
import { merge } from 'editor/core/highcharts-editor';
import GlobalModals from './../../shared/wizard/editor/GlobalModals';
import HighchartsAnnotations from 'pages/ChartEditorPage/components/HighchartsAnnotations';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';

export default (props) => {
  const dispatch = useDispatch();
  const { isMap } = useSelector((state) => state.chartEditorPage);
  const { resolution } = useSelector((state) => state.layoutEditorPage);
  const { aggregatedOptions } = useSelector((state) => state.projectConfig);
  const chart = useChart();

  const updateCustomized = (optionProps, val, extraModulesToLoad, index, skipEmit) => {
    dispatch(updateCustomizedAction({ optionProps, val, extraModulesToLoad, index, skipEmit }));
  };

  const showAnnotationInMenu = (type, index, annotation) =>
    dispatch(showAnnotationInMenuAction({ type, index, annotation }));

  useEffect(() => {
    const params = props.match.params;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    dispatch(
      initEditorAction({
        query,
        urlParams: params,
        history: props.history,
        isThemeEditor: true,
        defaultThemeDetails: props.location?.state?.defaultThemeDetails
      })
    );

    // Used in plotbands helper
    window.EvervizWizard = {
      selectPlotBand: (index, axis, isBand, option) => {
        showAnnotationInMenu('highlight', 0, option);
      },
      updatePlotBands: (rawOption, options, isBand) => {
        let customizedOptions = cloneDeep(props.customizedOptions);
        const type = isBand ? 'plotBands' : 'plotLines';

        if (!isBand) {
          options.value = options.from;
          delete options.from;
          delete options.to;
        }

        let plotBands = [];
        const customizedAxis = customizedOptions[rawOption.axisType];
        const axis = isArray(customizedAxis) ? customizedAxis[0] : customizedAxis;
        const index = axis[type].findIndex((val) => val.id === rawOption.option.id);

        if (customizedAxis) {
          plotBands = (isArray(customizedAxis) ? customizedAxis[0][type] : customizedAxis[type]) || [];
        }
        if (plotBands[index]) {
          plotBands[index] = merge(plotBands[index], options);
        }

        if (isArray(customizedAxis)) {
          updateCustomized(`${rawOption.axisType}[0]`, customizedAxis[0], null, null, true);
        } else updateCustomized(rawOption.axisType, customizedAxis, null, null, true);
      }
    };

    return () => {
      // Reset redux state
      dispatch(resetStateAction());
      dispatch(resetChartState());
      dispatch(resetProjectConfigAction());
    };
  }, []);

  return (
    <div className="hc-editor">
      <Helmet>
        <title> everviz </title>
      </Helmet>

      <HighchartsAnnotations />
      <ChartEditor
        aggregatedOptions={aggregatedOptions}
        history={props.history}
        resolution={resolution}
        showWizard={false}
        chart={chart}
        isMap={isMap}
        isTheme={true}
      />

      <GlobalModals />
    </div>
  );
};
