import { cloneDeep } from 'lodash';
import { parseCustomizeOptions } from 'pages/ChartEditorPage/utils/customizeHelper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CustomizeSection from 'shared/editor/CustomizeSection';
import OpenContainer from 'shared/OpenContainer';
import { UpdateChartFunction } from 'shared/types/commonPropTypes';
import { updateThemeConfigAction } from '../actions/ThemeEditorPage';
import customizeOptions from '../meta/CustomizeOptions';
import lang from '../meta/en';

export default function LeftContainer() {
  const [options, setOptions] = useState(cloneDeep(customizeOptions));
  const { view } = useSelector((state: RootState) => state.companyThemeEditorPage);
  const chartEditorPage = useSelector((state: RootState) => state.chartEditorPage);
  const projectConfig = useSelector((state: RootState) => state.projectConfig);
  const layoutEditorPage = useSelector((state: RootState) => state.layoutEditorPage);
  const dispatch = useDispatch();

  useEffect(() => {
    const options = cloneDeep(customizeOptions);
    setOptions(parseCustomizeOptions(options, chartEditorPage, projectConfig, layoutEditorPage));
  }, []);

  useEffect(() => {
    const options = cloneDeep(customizeOptions);
    setOptions(parseCustomizeOptions(options, chartEditorPage, projectConfig, layoutEditorPage));
  }, [view]);

  const updateCustomizedProp: UpdateChartFunction = (type, optionProps, val, extraModulesToLoad?, index?) => {
    dispatch(updateThemeConfigAction({ type, optionProps, val, extraModulesToLoad, index }));
  };

  return (
    <div className="">
      <div className="flex flex-col">
        <CustomizeSection
          customizeOptions={options}
          lang={lang}
          defaultTab="option.text.font"
          container={OpenContainer}
          baseColor={'bg-ev-grey'}
          overrideOnChange={updateCustomizedProp}
        />
      </div>
    </div>
  );
}
