import actionTypes from './action-types';

export const setAction = (data) => ({
  type: actionTypes.app.set,
  data
});

export const openPaidPlanModalAction = (data) => ({
  type: actionTypes.app.openPaidPlanModal,
  data
});

export const openPremiumFeatureModalAction = (data) => ({
  type: actionTypes.app.openPremiumFeatureModal,
  data
});
