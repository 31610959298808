import React, { Component } from 'react';

export default class BorderIcon extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="border-icon"
        viewBox="0 0 24 24"
        fill="#444"
        width="35px"
        height="19px"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M3 3v18h18V3H3zm8 16H5v-6h6v6zm0-8H5V5h6v6zm8 8h-6v-6h6v6zm0-8h-6V5h6v6z" />
      </svg>
    );
  }
}
