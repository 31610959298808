import classNames from 'classnames';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { setPlansPageAction } from 'pages/SubscriptionPage/actions/plansPage';
import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import {
  TrialUIInfo,
  getFormattedPlanFeatures,
  getPlanById,
  getRemainingDays,
  getTrialPlanUIInfo,
  nextPlanMap,
  yearlyPlanMap
} from 'pages/SubscriptionPage/utils/plansHelper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import TextButton from 'shared/buttons/TextButton';
import { subscriptionPlans } from '../meta/subscriptionPlans';
import HeaderedBlock from './HeaderedBlock';
import { handleGoogleTagManagerPurchaseEvent } from 'shared/utils/googleTagManagerHelper';

type CustomTextButtonProps = {
  text: string;
  onClick: () => void;
};

const CustomTextButton = ({ text, onClick }: CustomTextButtonProps) => (
  <TextButton className="text-ev-navy-blue-2 text-base" text={text} thin={true} onClick={onClick} />
);

type BillingDetailsSectionProps = {
  subscription: any;
  team: any;
  chartsCreated: any;
  openCheckout: (planCode: number) => void;
  editPaymentMethod: () => void;
};

const BillingDetailsSection = ({
  subscription,
  team,
  chartsCreated,
  openCheckout,
  editPaymentMethod
}: BillingDetailsSectionProps) => {
  const dispatch = useDispatch();
  const [trialUIInfo, setTrialUIInfo] = useState<TrialUIInfo>();
  const { billingDetails, contactUsModalOpen } = useSelector((state: RootState) => state.plansPage);
  const currentPlan = getPlanById(subscription?.id);

  const isFree = currentPlan === SubscriptionPlans.Free;
  const isEnterprise = currentPlan === SubscriptionPlans.Enterprise;
  const isStorage = currentPlan === SubscriptionPlans.Storage;
  const isMonthly = subscription.interval_length !== 12;
  const isTrial = currentPlan === SubscriptionPlans.ProTrial;
  const isProjectsLimit = chartsCreated >= team?.charts_max || (trialUIInfo && trialUIInfo.remainingDays < 1);
  const isCardAdded = billingDetails?.last_four_digits && billingDetails?.expires && billingDetails?.card_type;
  const isAddressAdded =
    billingDetails?.address1 && billingDetails?.address2 && billingDetails?.country && billingDetails?.postal_code;

  const getPercentOverfilled = () => {
    if (trialUIInfo) return trialUIInfo.dayPercent;

    const min = chartsCreated || 0;
    const max = team ? team.charts_max : 10;
    return Math.min((min / max) * 100, 100);
  };

  useEffect(() => {
    if (subscription) {
      const remainingDays = getRemainingDays(subscription.period_end_time);
      setTrialUIInfo(getTrialPlanUIInfo(currentPlan, remainingDays, subscription));
    }
  }, [subscription, currentPlan]);

  const generatePeriodText = () => {
    if (isTrial) {
      return trialUIInfo?.billingPeriodText;
    } else if (isFree) {
      return 'No billing period';
    } else if (isMonthly) {
      return 'Monthly billing';
    } else {
      return 'Yearly billing';
    }
  };

  const getNextPlan = () => {
    if (isFree || isTrial) {
      return SubscriptionPlans.Pro;
    } else if (
      currentPlan === SubscriptionPlans.Starter ||
      currentPlan === SubscriptionPlans.Business ||
      currentPlan === SubscriptionPlans.Pro
    ) {
      return SubscriptionPlans.Enterprise;
    }
  };

  const getNextPlanFeatures = () => {
    const nextPlan = getNextPlan();

    if (nextPlan) return getFormattedPlanFeatures(nextPlan);
    else return null;
  };

  const getRegionName = new Intl.DisplayNames(['en'], { type: 'region' });

  const scrollIntoPlansSection = () => {
    const plansContainerElement = document.getElementById('plans-container');

    if (plansContainerElement) {
      window.scrollTo({ behavior: 'smooth', top: plansContainerElement.offsetTop });
    }
  };

  const handleEditPaymentMethod = () => {
    editPaymentMethod();
    scrollIntoPlansSection();
  };

  const handleAnnualPlanChange = () => {
    openCheckout(yearlyPlanMap[subscription?.id]);
    scrollIntoPlansSection();
  };

  const handlePlanUpgrade = () => {
    const nextPlanId = nextPlanMap[subscription?.id];

    if (currentPlan === SubscriptionPlans.Business || currentPlan === SubscriptionPlans.Pro) {
      dispatch(setPlansPageAction({ contactUsModalOpen: true }));
    } else {
      openCheckout(nextPlanId);
      scrollIntoPlansSection();
    }

    const planDetails = subscriptionPlans[nextPlanId];
    handleGoogleTagManagerPurchaseEvent('purchase_begin', planDetails);
  };

  const nextPlanFeatures = getNextPlanFeatures();
  const enterpriseFeatures = getFormattedPlanFeatures(SubscriptionPlans.Enterprise);

  const projectsProgressClasses = classNames('h-1 progress-charts-bar rounded-full', {
    danger: !isProjectsLimit,
    'bg-ev-red': isProjectsLimit
  });

  const toggleContactUsModal = () => {
    dispatch(setPlansPageAction({ contactUsModalOpen: !contactUsModalOpen }));
  };

  return (
    <div className="summary-section-blocks">
      <HeaderedBlock header="Current subscription plan">
        <div className="h-full flex flex-col justify-between text-base">
          {isFree && <span>Free plan</span>}

          {!isFree && (
            <div className="flex flex-col text-base">
              <span>
                {isTrial ? trialUIInfo?.name : subscription.description}
                {isMonthly && !isTrial && ' monthly'}
              </span>

              {!isEnterprise && !isTrial && (
                <span>
                  $ {subscriptionPlans[subscription.id]?.price} /{!isTrial ? (isMonthly ? 'month' : 'year') : ''}
                </span>
              )}

              <span>
                {isTrial && trialUIInfo?.dateExpiredText}
                {!isTrial && (
                  <span>
                    Next billing&nbsp;
                    {subscription?.period_end_time
                      ? format(new Date(subscription.period_end_time), 'MMMM d, yyyy')
                      : 'N/A'}
                  </span>
                )}
              </span>
            </div>
          )}

          {isEnterprise && <span className="text-base">Unlimited projects</span>}

          {!isEnterprise && (
            <div className="flex flex-col text-base">
              {isTrial ? <span>Pro Plan Trial</span> : <span>Projects</span>}
              <div className="my-1 w-full h-1 mb-1 bg-white rounded-full transition">
                <div className={projectsProgressClasses} style={{ width: `${getPercentOverfilled()}%` }} />
              </div>

              {trialUIInfo && (
                <span className="font-normal" dangerouslySetInnerHTML={{ __html: trialUIInfo.dayExpiredText }} />
              )}

              {!trialUIInfo && (
                <span>
                  {chartsCreated} of {team.charts_max} projects used
                </span>
              )}
            </div>
          )}
        </div>
      </HeaderedBlock>

      <HeaderedBlock header="Billing information">
        <div className="flex flex-col">
          {!isCardAdded ? (
            <>
              <span className="text-base">No credit card added</span>
              <CustomTextButton text="Add new" onClick={handleEditPaymentMethod} />
            </>
          ) : (
            <div className="w-full flex justify-between">
              <div className="overflow-auto flex flex-col text-base">
                <span>
                  {billingDetails.card_type} {billingDetails.last_four_digits}
                </span>
                <span>Expiry date {billingDetails.expires}</span>
              </div>
              <CustomTextButton text="Edit" onClick={handleEditPaymentMethod} />
            </div>
          )}

          <hr className="my-2 border-2 border-ev-navy-blue-2" />

          {!isAddressAdded ? (
            <>
              <span className="text-base">You haven&apos;t added an address yet</span>
              <CustomTextButton text="Add a new address" onClick={handleEditPaymentMethod} />
            </>
          ) : (
            <div className="w-full flex justify-between">
              <div className="overflow-auto flex flex-col text-base">
                <span>{billingDetails.address1}</span>
                <span>
                  {billingDetails.address2}, {billingDetails.postal_code}
                </span>
                {getRegionName.of(billingDetails.country)}
              </div>
              <CustomTextButton text="Edit" onClick={handleEditPaymentMethod} />
            </div>
          )}
        </div>
      </HeaderedBlock>

      <HeaderedBlock header="Billing period">
        <div className="h-full flex flex-col justify-between">
          <span className="text-base">{generatePeriodText()}</span>
          {isFree ? (
            <PrimaryButton
              text="No billing period"
              className="mx-auto"
              disabled={true}
              buttonColor={ButtonColor.GreyDisabled}
            />
          ) : isTrial ? (
            <PrimaryButton
              text={`Continue with ${getNextPlan() ?? ''} plan`}
              className="mx-auto"
              buttonColor={ButtonColor.NavyBlue}
              onClick={handlePlanUpgrade}
            />
          ) : (
            !isEnterprise &&
            isMonthly &&
            subscription?.id !== 11 && (
              <div className="flex flex-col justify-center">
                <span className="mb-2 inline-block text-center">Get 2 months free with yearly billing</span>
                <PrimaryButton
                  text="Switch to yearly"
                  className="mx-auto"
                  buttonColor={ButtonColor.NavyBlue}
                  onClick={handleAnnualPlanChange}
                />
              </div>
            )
          )}
        </div>
      </HeaderedBlock>

      {!isEnterprise && !isStorage && isTrial && (
        <HeaderedBlock isDark={true} header={'Upgrade to Enterprise to unlock more features'}>
          <div className="h-full flex flex-col justify-between">
            {enterpriseFeatures && (
              <div className="flex flex-col">
                {enterpriseFeatures.map(({ text }, index) =>
                  index <= 2 ? (
                    <div key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('- ' + text) }} />
                  ) : null
                )}
              </div>
            )}
            <PrimaryButton text="Contact us" className="mt-2 mx-auto" onClick={toggleContactUsModal} />
          </div>
        </HeaderedBlock>
      )}

      {!isEnterprise && !isStorage && !isTrial && (
        <HeaderedBlock isDark={true} header={`Upgrade to ${getNextPlan() ?? ''} to unlock more features`}>
          <div className="h-full flex flex-col justify-between">
            {nextPlanFeatures && (
              <div className="flex flex-col">
                {nextPlanFeatures.map(({ text }, index) =>
                  index <= 2 ? (
                    <div key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('- ' + text) }} />
                  ) : null
                )}
              </div>
            )}
            <PrimaryButton text="Upgrade plan" className="mt-2 mx-auto" onClick={handlePlanUpgrade} />
          </div>
        </HeaderedBlock>
      )}
    </div>
  );
};

export default BillingDetailsSection;
