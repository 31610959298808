import { isLoggedIn } from '../api/cloudApiGenerated';
import store from '../redux/store';
import { snackBar } from 'editor/editors/highed.init';
import config from '../config';
import {
  getGroupsAction,
  getProfileAction,
  getSubscriptionAction,
  logoutAction,
  reloadDivisionsAction,
  setLocalTeamAction,
  setSubteams
} from '../redux/actions/profile';
import { setTeamMembersAction } from 'pages/TeamPage/actions/teamMembersAction';
export const checkLoggedIn = isLoggedIn;

export const checkPlanPermission = (permission, profile = store.getState().profile) => {
  // I dont know why this is in like 3 different places. TODO: sort this out.
  return profile.billingPermissions && profile.billingPermissions[permission];
};

export const checkPermission = (permission, profile = store.getState().profile) => {
  return (
    profile.permissions &&
    (profile.permissions[permission] || (profile.user && profile.team && profile.user.id === profile.team.owner_user))
  );
};

export const setTeam = (team, profile, pathname) => {
  store.dispatch(setLocalTeamAction({ team, config, profile, pathname }));
};

export const checkCreatePermission = (type) => {
  return (
    checkPermission('create_chart') &&
    (type === 'chart' ||
      (type === 'table' && checkPlanPermission('create_table')) ||
      (type === 'layout' && checkPlanPermission('create_layout')))
  );
};

export const setDivision = (division) => {
  localStorage.setItem('division', JSON.stringify(division));
  store.dispatch(
    setSubteams({
      division
    })
  );

  store.dispatch(
    setTeamMembersAction({
      activeDivision: { val: division.id, label: division.name }
    })
  );
  snackBar('Changed subteam');
};

export const loadGroups = (team) => {
  store.dispatch(getGroupsAction({ team }));
};

export const loadSubscription = (team) => {
  store.dispatch(getSubscriptionAction({ team, config }));
};

export const reloadDivisions = (team, profile) => {
  store.dispatch(reloadDivisionsAction({ team, profile, checkPermission }));
};

export const logout = () => {
  store.dispatch(logoutAction());
  localStorage.removeItem('editorTeam');
  localStorage.removeItem('team');
};

export const onGoogleLoggedIn = (announcements, newUser, history) => {
  store.dispatch(
    getProfileAction({
      config,
      getLast: false,
      announcements,
      checkPermission,
      callback: null,
      noLoading: null,
      newUser,
      saveChartAfterLogin: true,
      history,
      search: location.search
    })
  );
};

export const onLoggedIn = (getLast = false, announcements, callback, noLoading, newUser = false) => {
  store.dispatch(
    getProfileAction({
      config,
      getLast,
      announcements,
      checkPermission,
      callback,
      noLoading,
      newUser,
      search: location.search
    })
  );
};

export const goToPage = (link) => {
  window.open(link, '_blank');
};
