import {
  login,
  postAccount,
  postTeamBillingInfoUsingTeamidAndToken,
  postTeamSubscribeUsingTeamidAndPlanid
} from 'api/cloudApiGenerated';
import config from 'config';
import { isArray, isString, mapValues, isObject } from 'lodash';
import { SubscriptionPlan } from 'pages/SubscriptionPage/types/plansModels';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { validateSignupForm } from 'shared/modal/utils/signupModalHelper';
import { UnknownParamsFunction } from 'shared/types/commonPropTypes';
import ArrowLeft from 'static/icons/arrow_left.svg';
import paymentProvider from 'utils/paymentProvider';
import { onLoggedIn } from 'utils/profileHelper';
import { useCaptcha } from 'utils/signupHelper';
import { setPlansPageAction, updatePlanAction } from '../actions/plansPage';
import { billingFormConfig } from '../meta/billingForm';
import { generateAddress, validateBillingForm } from '../utils/billingHelpers';
import AccountInfo from './AccountInfo';
import BillingCycle from './BillingCycle';
import BillingInformation from './BillingInformation';
import EditPaymentForm from './EditPaymentForm';
import PlanSummary from './PlanSummary';

type PlanCheckoutProps = {
  plan: SubscriptionPlan;
  closeCheckout: () => void;
  closeEditing: () => void;
  onBillingSave: () => void;
  setPlan: (planCode: number) => void;
  editingPaymentMethod: boolean;
};
let providerStrategy: UnknownParamsFunction;

const PlanCheckout = (props: PlanCheckoutProps) => {
  const { plan, editingPaymentMethod } = props;
  const dispatch = useDispatch();

  const { user, team } = useSelector((state: RootState) => state.profile);
  const { planCode, billingDetails, error } = useSelector((state: RootState) => state.plansPage);
  const formId = 'recurly-form';

  const [editing, setEditing] = useState({
    account: false,
    billingCycle: false,
    billingInfo: false
  });
  const isLoggedIn = !!team;
  const [teamId, setTeamId] = useState('');
  const [billingForm, setBillingForm] = useState(billingFormConfig(user, billingDetails, isLoggedIn));
  const [tokenId, setTokenId] = useState('');
  const [billingError, setBillingError] = useState('');
  const [savingBillingInfo, setSavingBillingInfo] = useState(false);
  const [address, setAddress] = useState('');

  useEffect(() => {
    const paymentConfig = config.billing.provider;
    providerStrategy = paymentProvider.strategy.recurly(paymentConfig);
  }, []);

  useEffect(() => {
    if (isString(error) || isObject(error)) {
      handleError(error);

      const errorWithResponse = error as { response: { three_d_secure: any } };
      if (isObject(error) && errorWithResponse.response) {
        paymentProvider.setup3dSecure(errorWithResponse.response.three_d_secure, '', dunningFlowResubmit3DSecure);
      }
    }
  }, [error]);

  useEffect(() => {
    if (tokenId) {
      saveBillingForm(undefined, tokenId);
    }
  }, [tokenId]);

  useEffect(() => {
    generateAddress(billingDetails, billingForm, setAddress);
  }, [billingDetails, billingForm]);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.persist();
    const field = ev.target.name;
    const updatedForm = billingForm;
    updatedForm[field].error = '';
    updatedForm[field].value = ev.target.value;
    setBillingForm({ ...updatedForm, ...billingForm });
  };

  const toggleEditing = (name: keyof typeof editing) => {
    setEditing((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const setSaving = (saving: boolean) => {
    if (isLoggedIn) setSavingBillingInfo(saving);
    else dispatch(setPlansPageAction({ savingPlan: saving }));
  };

  const onPlanChange = (ev?: React.MouseEvent) => {
    if (ev) ev.preventDefault();
    if (isLoggedIn) {
      dispatch(updatePlanAction({ team, planCode, cb: props.closeCheckout }));
    } else saveBillingForm(ev);
  };

  const dunningFlowResubmit3DSecure = (three_d_token: string) => {
    try {
      dispatch(setPlansPageAction({ error: false }));
      dispatch(updatePlanAction({ team, planCode, three_d_secure_token: three_d_token, cb: props.closeCheckout }));
    } catch (err) {
      dispatch(setPlansPageAction({ err }));
    }
  };

  const resubmit3DSecure = (three_d_token: string, token_id: string) => {
    const updated3dsecure = { ...billingForm.three_d_secure_token, value: three_d_token };
    setBillingForm((prev) => ({ ...prev, three_d_secure_token: updated3dsecure }));
    setTokenId(token_id);
  };

  const redirectToProfileSubscription = () => {
    setSaving(false);
    props.closeCheckout();
    onLoggedIn();
    dispatch(setPlansPageAction({ successScreenText: 'Thank you for signing up!' }));
  };

  const handleError = (error: any) => {
    setSaving(false);
    if (isString(error)) {
      setBillingError(error);
    } else if (error && error.response) {
      if (isString(error.response.error)) {
        setBillingError(error.response.error);
      } else if (isArray(error.response.message)) {
        setBillingError(error.response.message.join(' '));
      } else if (error.response?.errors?.error?.[0]?._) {
        let message = '';
        if (error.response?.errors?.error?.[0]?.$?.field) message = `${error.response.errors.error[0].$.field} `;
        message = `${message}${error.response.errors.error[0]._}`;
        setBillingError(message);
      }
    } else {
      setBillingError((error.message.join && error.message.join(' ')) || error.message);
    }
  };

  const saveBillingForm = (event?: React.MouseEvent, token_id?: string) => {
    if (event) event.preventDefault();
    setSaving(true);

    const signupUser = async (): Promise<string> => {
      const isValid = validateSignupForm(billingForm, (newForm) => setBillingForm({ ...newForm }));
      if (!isValid) {
        setSaving(false);
        throw new Error('Signup details validation failed.');
      }
      const action = 'login';
      const token = await useCaptcha(config, action);
      const { team_id } = await postAccount({
        username: billingForm.username.value,
        email: billingForm.email.value,
        password: billingForm.password.value,
        captchaResponse: token,
        action
      });
      setTeamId(team_id);
      await login(billingForm.email.value, billingForm.password.value);
      return team_id;
    };

    const processPayment = async (resolvedTeamId?: string) => {
      let paymentToken;
      try {
        paymentToken =
          token_id ?? (await paymentProvider.saveInfo(providerStrategy, document.querySelector(`#${formId}`)));
        const action = 'updatedetails';
        const captchaResponse = await useCaptcha(config, action);
        await postTeamBillingInfoUsingTeamidAndToken(team?.id || resolvedTeamId || teamId, paymentToken, {
          ...mapValues(billingForm, 'value'),
          action,
          captchaResponse
        });
      } catch (error: any) {
        setSaving(false);
        if (error && error.fields) {
          validateBillingForm(billingForm, setBillingForm, error.fields);
        } else if (error && error.response && error.response.three_d_secure) {
          paymentProvider.setup3dSecure(error.response.three_d_secure, paymentToken, resubmit3DSecure);
        }
        throw error;
      }
    };

    const updateBillingInfo = () => {
      setSavingBillingInfo(false);
      setBillingError('');
      if (isLoggedIn) {
        props.onBillingSave();
        if (editingPaymentMethod) {
          dispatch(setPlansPageAction({ successScreenText: 'Thank you for updating payment details.' }));
        } else {
          toggleEditing('billingInfo');
        }
      } else {
        toggleEditing('billingInfo');
      }
    };

    const subscribeToPlan = async (resolvedTeamId?: string) => {
      const action = 'subscription';
      const token = await useCaptcha(config, action);
      await postTeamSubscribeUsingTeamidAndPlanid(resolvedTeamId || teamId, planCode, {
        captchaResponse: token,
        action
      });
    };

    if (isLoggedIn) {
      processPayment()
        .then(() => updateBillingInfo())
        .catch((e) => handleError(e));
    } else if ((!isLoggedIn && token_id) || teamId) {
      processPayment()
        .then(() => updateBillingInfo())
        .then(() => subscribeToPlan())
        .then(() => redirectToProfileSubscription())
        .catch((e) => handleError(e));
    } else {
      signupUser()
        .then((resolvedTeamId: string) =>
          processPayment(resolvedTeamId)
            .then(() => updateBillingInfo())
            .then(() => subscribeToPlan(resolvedTeamId))
            .then(() => redirectToProfileSubscription())
            .catch((e) => handleError(e))
        )
        .catch((e) => handleError(e));
    }
  };

  return (
    <div className="bg-white py-8 border ev-sm-max:-mx-8 ev-sm-max:p-8">
      <div className="ev-sm:max-w-5xl m-auto">
        <div className="flex items-center mb-10">
          <SvgIconButton
            Icon={ArrowLeft}
            buttonColor={ButtonColor.Grey}
            onClick={() => {
              props.closeCheckout();
              props.closeEditing();
            }}
            width={28}
            height={24}
            buttonClasses="content-centered shrink-0 rounded-lg w-10 h-10 ev-sm:mr-6 mr-2 border"
          />
          <p className="m-0 text-la ev-md:text-2xl color-ev-navy-blue-2 font-medium">
            {editingPaymentMethod ? 'Edit payment method' : 'Go back to pricing'}
          </p>
        </div>
        <form className="flex flex-col ev-lg:flex-row w-full gap-6" id={formId}>
          <div className="flex flex-col w-full gap-6 h-fit" id={`${formId}-blocks`}>
            {editingPaymentMethod ? (
              <EditPaymentForm
                billingForm={billingForm}
                handleChange={handleChange}
                handleSubmit={saveBillingForm}
                saving={savingBillingInfo}
                billingError={billingError}
              />
            ) : (
              <>
                <AccountInfo
                  user={user}
                  billingForm={billingForm}
                  handleChange={handleChange}
                  editing={editing.account}
                  toggleEditing={() => toggleEditing('account')}
                  isLoggedIn={isLoggedIn || Boolean(teamId)}
                  isEditable={Boolean(!teamId)}
                />
                <BillingCycle
                  editing={editing.billingCycle}
                  toggleEditing={() => toggleEditing('billingCycle')}
                  plan={plan}
                  setPlan={props.setPlan}
                  isLoggedIn={isLoggedIn}
                />
                <BillingInformation
                  address={address}
                  billingForm={billingForm}
                  billingData={billingDetails}
                  handleChange={handleChange}
                  handleSubmit={saveBillingForm}
                  editing={editing.billingInfo}
                  toggleEditing={() => toggleEditing('billingInfo')}
                  saving={savingBillingInfo}
                  isLoggedIn={isLoggedIn}
                />
              </>
            )}
          </div>
          {!editingPaymentMethod && (
            <PlanSummary
              plan={plan}
              billingError={billingError}
              billingForm={billingForm}
              billingData={billingDetails}
              handleSubmit={onPlanChange}
              isLoggedIn={isLoggedIn}
            />
          )}
        </form>
        <div id="recurly-3dsecure" />
      </div>
    </div>
  );
};

export default PlanCheckout;
