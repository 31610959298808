import classNames from 'classnames';
import React from 'react';

type PanelContainerProps = {
  children: React.ReactNode;
  active: boolean;
  className?: string;
  isAdvanced?: boolean;
};

export default function PanelContainer(props: PanelContainerProps) {
  const classes = classNames(
    'min-h-[calc(100vh-560px)] max-h-[calc(100vh-560px)] ev-sm:max-h-max ev-sm:min-h-min customize-panel gap-2 option-panel px-6 pb-4 ev-sm:pl-8',
    {
      'min-w-full max-w-full w-full ev-sm:max-w-[388px] ev-sm:min-w-[388px]': !props.isAdvanced,
      'max-w-[600px] min-w-[600px]': props.isAdvanced,
      active: props.active
    },
    props.className
  );
  return <div className={classes}>{props.children}</div>;
}
