/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { ProviderTypes } from 'pages/ChartEditorPage/meta/templates/types';
import LayoutComponent from 'pages/LayoutEditorPage/containers/LayoutComponent';
import TableComponent from 'pages/TableEditorPage/components/TableComponent';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { TypeProps } from 'shared/types/chooseTheme';
import InlineOptionsContainer from '../../editor/generic/InlineOptionsContainer';
import LocationMapBottomBar from '../components/LocationMapBottomBar';
import HighchartsPreview from './HighchartsPreview';
import LocationMapPreview from './LocationMapPreview';

const getClassName = {
  highcharts: 'ev-sm-max:h-full',
  locationMap: 'h-[500px]'
};

type getElementProps = (hcContainerClass?: string, children?: ReactNode) => ReactNode;

const LocationMapContainer = (
  <>
    <div className="h-[520px] w-full" style={{ maxHeight: 'inherit' }}>
      <LocationMapPreview />
    </div>
  </>
);

const bottomBarMap = {
  locationMap: (
    <InlineOptionsContainer version="transparent">
      <LocationMapBottomBar />
    </InlineOptionsContainer>
  ),
  highcharts: <></>
};
const elementMap: Record<TypeProps, getElementProps> = {
  chart: (hcContainerClass?: string, children?: ReactNode) => (
    <>
      {<HighchartsPreview className={hcContainerClass} />}
      {children}
    </>
  ),
  layout: () => <LayoutComponent />,
  table: () => <TableComponent />
};

const getRenderElement = (provider: ProviderTypes, type: TypeProps) => {
  switch (provider) {
    case 'locationMap':
      return () => LocationMapContainer;
    case 'highcharts':
      return elementMap[type];
  }
};

const WizardProjectPreview = (props: { children?: React.ReactNode; hcClassName?: string; showBottomBar?: boolean }) => {
  const { children, hcClassName, showBottomBar } = props;
  const { provider, type, loadedTemplate } = useSelector((state: RootState) => state.projectConfig);

  const hcContainerClass = classNames(hcClassName, getClassName[provider as ProviderTypes]);
  const getElement = getRenderElement(provider, type);

  return (
    <>
      {loadedTemplate && getElement(hcContainerClass, children)}{' '}
      {loadedTemplate && showBottomBar && bottomBarMap[provider]}
    </>
  );
};

export default WizardProjectPreview;
