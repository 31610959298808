import { ready } from '../core/highcharts-editor';
import { cr, ap, on, setStyle } from '../core/highed.dom';

const container = cr('div', 'highed-snackbar no-print', '', ''),
  title = cr('span', 'snackbar-title', '', ''),
  action = cr('span', 'snackbar-action', '', ''),
  closeNode = cr('span', 'highed-snackbar-close fa fa-times-circle', '', '');

let timeout: any = false,
  callback: any = false;

ready(function () {
  ap(document.body, ap(container, title, action, closeNode));
});

on(
  container,
  'mouseover',
  function () {
    window.clearTimeout(timeout);
  },
  null as any
);

on(
  container,
  'mouseout',
  function () {
    hide();
  },
  null as any
);

on(
  closeNode,
  'click',
  function () {
    setStyle(container, {
      right: '-100%'
    });
  },
  null as any
);

///////////////////////////////////////////////////////////////////////////

function hide() {
  timeout = setTimeout(function () {
    setStyle(container, {
      right: '-100%'
    });
  }, 5000);
}

///////////////////////////////////////////////////////////////////////////

/**  Show a snackbar
 *   A snack bar is those info rectangles showing up on the bottom left.
 *
 *   @example
 *   snackBar('Hello world!');
 *
 *   @param stitle {string} (optional) - the snackbar title
 *   @param saction {string} (optional) - the snackbar action text
 *   @param fn {function} (optional) - the function to call when clicking the action
 */
export default function (stitle: string, saction?: string, fn?: () => void) {
  title.innerHTML = stitle; // .toUpperCase();

  window.clearTimeout(timeout);

  if (saction) {
    action.innerHTML = saction.toUpperCase();
  }

  if (callback) {
    callback();
  }

  setStyle(container, {
    right: '10px'
  });

  setStyle(action, {
    display: saction ? '' : 'none'
  });

  const noCallBackFunction = function () {
    console.log('no callback');
  };

  callback = on(action, 'click', fn ?? noCallBackFunction, null as any);

  hide();
}
