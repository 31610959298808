import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function WizardLoading() {
  const { uploadingData } = useSelector((state: RootState) => state.layoutEditorPage);
  const [loading, setLoading] = useState(false);
  const [loadingBuffer, setLoadingBuffer] = useState(false);

  useEffect(() => {
    if (loading && !loadingBuffer) {
      setLoadingBuffer(true);
      setTimeout(() => {
        setLoading(false);
        setLoadingBuffer(false);
      }, 1000);
    } else {
      setLoading(uploadingData);
    }
  }, [uploadingData]);

  return (
    <>
      {loading && (
        <div className={'bg-[rgba(40,39,126,0.8)] w-full h-full fixed top-0 left-0 z-10 center'}>
          <div className=" flex h-screen">
            <div className="m-auto text-white">
              <i className={`fad wizard-loader ${loadingBuffer ? 'fa-check-circle' : 'fa-spinner ev-spin'} text-6xl`} />
              <div className={`pt-4 font-bold ${loadingBuffer ? '' : 'loading-text'}`}>
                {loadingBuffer ? 'Done!' : 'Uploading data'}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
