import { get, isFunction } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import showDimmer from '../../editor/core-ui/highed.dimmer';
import {
  onChangeActiveAnnotationAction,
  removeAnnotationAction,
  updateLabelAnnotationAction
} from '../../pages/ChartEditorPage/actions/chartEditor';
import BuildInputs from './../../shared/widgets/BuildInputs';
import SelectWidget from './SelectWidget';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';

let hideDimmer = false;

export default (props) => {
  const { option } = props;
  const { annotationSection } = useSelector((state) => state.chartEditorPage);
  const { customizedOptions } = useSelector((state) => state.projectConfig);
  const dispatch = useDispatch();
  const store = useStore();
  const chart = useChart();

  const onChangeActiveAnnotation = (selected, type) => dispatch(onChangeActiveAnnotationAction({ selected, type }));
  const updateLabelAnnotation = (optionProps, val, extraModulesToLoad, index) =>
    dispatch(updateLabelAnnotationAction({ optionProps, val, extraModulesToLoad, index }));

  useEffect(() => {
    // Unmount
    return () => {
      // Need the updated version of annotationSection
      const { annotationSection } = store.getState().chartEditorPage;
      if (annotationSection.labels) {
        onChangeActiveAnnotation();
      }
    };
  }, []);

  const getLabels = () => {
    let selectOptions = [];
    let optionValues = [];
    const vals = get(customizedOptions, 'annotations') || [];

    let count = 1;

    (vals || []).forEach((val, i) => {
      const isLabelAnnotation = val.langKey === 'label' || val.labels;
      if (isLabelAnnotation) {
        let option = {
          label: 'Text label ' + count,
          value: i,
          index: count - 1
        };

        if (val.labels[0].format && val.labels[0].format !== 'Insert Text' && val.labels[0].format !== '') {
          option.label = val.labels[0].format;
        }
        selectOptions.push(option);
        optionValues.push(val.labels[0]);
        count++;
      }
    });

    return {
      selectOptions,
      optionValues
    };
  };

  const onChangeLabel = (selected) => onChangeActiveAnnotation(selected, 'labels');

  const hide = () => {
    const helpContainer = document.querySelector('.highed-annotation-help-container');
    chart.container.style.zIndex = 0;
    helpContainer.classList.remove('active');
    if (isFunction(hideDimmer)) {
      hideDimmer();
    }
  };

  const getValue = (selectOptions) => {
    if (annotationSection.labels === false) return false;

    if (annotationSection.labels.label) {
      return annotationSection.labels;
    }

    // No label, find the label from select options
    return selectOptions.find((d) => d.value === annotationSection.labels.value);
  };

  const removeAnnotation = () => {
    const { selectOptions } = getLabels();
    const selectValue = getValue(selectOptions);
    dispatch(removeAnnotationAction({ type: 'label', index: selectValue ? selectValue.index : 0 }));
  };

  const addAnnotation = (type) => {
    chart.annotations.forEach((annotation) => annotation.setControlPointsVisibility(false));
    chart.navigationBindings.activeAnnotation = null;

    const selector = type === 'label' ? 'highcharts-label-annotation' : 'highcharts-segment';
    const btn = document.querySelector(`.highed-template-tooltip.annotation-buttons.${selector}.highed-svg-icon`);
    const helpContainer = document.querySelector('.highed-annotation-help-container');

    btn.click();

    hideDimmer = showDimmer(
      hide,
      true,
      false,
      2,
      document.querySelector('#app .page-container .wizard-popup, #app .page-container .left-panel')
    );
    helpContainer.className += ' active';
    chart.container.style.zIndex = 10002;
  };

  const onChange = (type, optionProps, val, extraModulesToLoad) => {
    updateLabelAnnotation(optionProps, val, extraModulesToLoad, annotationSection.labels.value || 0);
  };

  const { selectOptions, optionValues } = getLabels();
  const selectValue = getValue(selectOptions);

  return (
    <>
      <div className="font-bold pt-4 pb-2 text-ev-navy-blue-4"> Text labels </div>

      <div className="flex">
        <SelectWidget
          selectOptions={selectOptions}
          onChange={onChangeLabel}
          value={selectValue}
          placeholder="Text labels"
          canBeEmpty={true}
          className="ev-sm:w-[67%]"
        />
        <button className="highed-delete-add-option-button" onClick={() => addAnnotation('label')}>
          <i className="fa fa-plus" />
        </button>
        <button className="highed-delete-add-option-button disabled" onClick={removeAnnotation}>
          <i className="fa fa-trash" />
        </button>
      </div>

      {annotationSection.labels && (
        <div className="flex flex-wrap gap-x-1 gap-y-2">
          <BuildInputs
            section={option}
            parentKey={''}
            overrideOnChange={onChange}
            overrideOptions={optionValues[selectValue?.index ?? 0]}
          />
        </div>
      )}
    </>
  );
};
