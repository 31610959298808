import { TemplateTypeProps } from '../../types';
import Windrose from './Windrose';

const WindroseTemplate: TemplateTypeProps = {
  description: '',
  icon: '',
  sampleData: [],
  templates: {
    'Wind rose': Windrose
  }
};

export default WindroseTemplate;
