import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, useHistory } from 'react-router-dom';
import paths from 'paths';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import Loading from 'shared/loader/Loading';
import Footer from '../../shared/Footer';
import IconButton from '../../shared/buttons/IconButton';
import { duplicateChartAction, editChartAction, loadChartAction, loadPackageAction } from './actions/showChartPage';
import AuthorsInfo from 'shared/message/AuthorsInfo';

type ShowChartPageProps = {
  match: any;
  setNavActions: any;
};

const ShowChartPage = (props: ShowChartPageProps) => {
  const dispatch = useDispatch();
  const chartId = props.match.params.id;
  const path = props.match.path;
  const history = useHistory();
  const { chart, twitterUrl, facebookUrl, hasAccess, isPublished, loading, error } = useSelector(
    (state: RootState) => state.showChartPage
  );
  const isPackage = path.includes('package/share');
  const team = useSelector((state: RootState) => state.profile.team);
  const { loading: teamLoading } = useSelector((state: RootState) => state.app);
  const [loader, setLoader] = useState(loading);

  useEffect(() => {
    loadChart();
  }, [team]);

  useEffect(() => {
    setLoader(loading || teamLoading);
  }, [loading, teamLoading]);

  const duplicateChart = () => {
    dispatch(duplicateChartAction({ team, chart, history }));
  };

  const editChart = () => {
    dispatch(editChartAction({ chartId: chart.chart_id, history }));
  };

  const goToProjects = () => {
    history.push({ pathname: paths.profileCharts });
  };

  const handleFacebookIconClick = () => {
    window.open(facebookUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  };

  const handleTwitterIconClick = () => {
    window.open(
      twitterUrl + '&via=evervizApp&text=' + (chart.name || '').replace(/ /g, '+'),
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    );
  };

  const handleMailIconClick = () => {
    window.open(
      'mailto:?subject=' +
        chart.name +
        '&body=Check out this visualization created with everviz: https://api.everviz.com/share/' +
        chart.uuid
    );
  };

  const loadChart = () => {
    const load = isPackage ? loadPackageAction : loadChartAction;
    dispatch(load({ team, id: chartId, setNavActions: props.setNavActions, duplicateChart, editChart }));
  };

  const getLink = (isPackage: boolean, chartId: string) => {
    const base = (window as any).hcconfig.frontend.hostname;
    return `${base}/${isPackage ? `packages/embed/${chartId}/index.html` : `/embed/${chartId}`}`;
  };
  return (
    <div className="show-chart-page">
      <Helmet>
        {chart && <meta property="og:title" content={chart.name} />}
        {chart && <meta property="og:type" content="website" />}
        {chart && <meta property="og:image" content={chart.thumbnail_url} />}
        {chart && <meta property="og:url" content={'https://api.everviz.com/share/' + chart.uuid} />}
        {chart && <meta name="twitter:card" content="photo" />}
        {chart && <meta name="twitter:site" content="@evervizapp" />}
        {chart && <meta name="twitter:title" content={chart.name} />}
        {chart && <meta name="twitter:image" content={chart.thumbnail_url} />}
        {chart && <meta name="twitter:url" content={'https://api.everviz.com/share/' + chart.uuid} />}
        {chart && <title> {chart.name} </title>}
      </Helmet>

      <div className="show-chart-page__chart">
        {loader && <Loading iconClass="fad text-ev-navy-blue text-2xl" textClass="mt-2 text-ev-navy-blue" />}
        {!isPublished && !loader && !error && (
          <div className="flex flex-col items-center">
            <h1 className="font-bold mb-6">Unpublished project</h1>
            <p className="text-xl mb-10">This project needs to be published to appear on this page.</p>
            {hasAccess && (
              <div className="flex gap-4">
                <PrimaryButton text="Go to publish my project" onClick={editChart} />
                <PrimaryButton
                  text="Go back to my projects"
                  onClick={goToProjects}
                  buttonColor={ButtonColor.WhiteBordered}
                />
              </div>
            )}
          </div>
        )}
        {!isPublished && !loader && error && (
          <div className="flex flex-col items-center">
            <h1 className="font-bold mb-6">Project not found</h1>
            <p className="text-xl mb-10">Please ensure you&apos;re using the correct url.</p>
            <PrimaryButton text="Go back to my projects" onClick={goToProjects} />
          </div>
        )}
        {!!isPublished && !loader && (
          <>
            <iframe
              className="highcharts-iframe"
              src={getLink(isPackage, chartId)}
              style={{ border: 0, width: '100%', height: '500px' }}
            />
            <div className="show-chart-page__details">
              <div className="flex items-center">
                <b>Share: </b>
                <IconButton
                  icon="envelope"
                  className="w-8 h-8 mx-1 text-navy-blue-2 rounded-full"
                  onClick={handleMailIconClick}
                  tooltipText="Share in email"
                />
                <IconButton
                  icon="facebook-f"
                  className="w-8 h-8 mx-1 text-navy-blue-2 rounded-full"
                  iconClass="fab"
                  onClick={handleFacebookIconClick}
                  tooltipText="Share on Facebook"
                />
                <IconButton
                  icon="twitter"
                  className="w-8 h-8 mx-1 text-navy-blue-2 rounded-full"
                  iconClass="fab"
                  onClick={handleTwitterIconClick}
                  tooltipText="Share on Twitter"
                />
              </div>
              <div>{chart.creator && chart.username && <AuthorsInfo chartInfo={chart} />}</div>
            </div>
          </>
        )}
      </div>
      {!team && !loader && (
        <>
          <div className="show-chart-page__non-logged-footer">
            <p className="text-xl mb-8">
              Journalist? Researcher? Or maybe a content creator? <br />
              Sharing your data through attractive, interactive charts/maps is now simpler than ever.
            </p>
            <p className="text-3xl font-bold">Try everviz for free</p>
            <NavLink to={paths.newChart} className="btn m-4 bulk-action-btn everviz-red">
              Start creating
            </NavLink>
            <NavLink to={paths.frontPage} className="text-white">
              Discover everviz
            </NavLink>
          </div>
          <Footer onlySubsection={true} />
        </>
      )}
    </div>
  );
};

export default ShowChartPage;
