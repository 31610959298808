import { ap, cr } from './../core/highed.dom';
import highed from './../globals';
import Events from './highed.events';

/* eslint-disable no-empty */
/* eslint-disable no-useless-escape */

/** Trigger file download
 *  @namespace highed
 *  @param filename {string} - the filename
 *  @param data {string} - the contained data
 */
export function download(filename, data, mime) {
  let l = cr('a');
  mime = mime || 'application/octet-stream';
  l.download = filename || 'unkown';
  l.href = 'data:' + mime + ',' + encodeURIComponent(data);
  ap(document.body, l);
  l.click();
  document.body.removeChild(l);
}

/** Preform an AJAX request. Same syntax as jQuery.
 *  @namespace highed
 *  @param p {object} - options
 *    > url {string} - the URL to call
 *    > type {enum} - the type of request
 *    > dataType {enum} - the type of data expected
 *    > success {function} - function to call on success
 *    > error {function} - function to call on request fail
 *    > data {object} - the payload
 *    > autoFire {boolean} - whether or not to fire the request right away
 *
 *   @emits Error {string} - when there's an error
 *   @emits OK {string} - when the request succeeded
 *   @returns {object} - interface to the request
 */
export function ajax(p) {
  let props = merge(
      {
        url: false,
        type: 'GET',
        dataType: 'json',
        success: false,
        error: false,
        data: {},
        autoFire: true,
        headers: {}
      },
      p
    ),
    headers = {
      json: 'application/json',
      xml: 'application/xml',
      text: 'text/plain',
      octet: 'application/octet-stream'
    },
    r = new XMLHttpRequest(),
    events = Events();

  if (!props.url) return false;

  r.open(props.type, props.url, true);

  if (!props.skipContentType) r.setRequestHeader('Content-Type', headers[props.dataType] || headers.text);

  Object.keys(props.headers).forEach(function (key) {
    r.setRequestHeader(key, props.headers[key]);
  });

  r.onreadystatechange = function () {
    events.emit('ReadyStateChange', r.readyState, r.status);

    if (r.readyState === 4 && r.status === 200) {
      if (props.dataType === 'json') {
        try {
          let json = JSON.parse(r.responseText);
          if (isFn(props.success)) {
            props.success(json);
          }
          events.emit('OK', json);
        } catch (e) {
          console.log('parse error', e);
          if (isFn(props.error)) {
            props.error(e.toString(), r.responseText);
          }
          events.emit('Error', e.toString(), r.status);
        }
      } else {
        if (isFn(props.success)) {
          props.success(r.responseText);
        }
        events.emit('OK', r.responseText);
      }
    } else if (r.readyState === 4) {
      events.emit('Error', r.status, r.statusText);
      if (isFn(props.error)) {
        props.error(r.status, r.statusText);
      }
    }
  };

  function fire() {
    try {
      r.send(JSON.stringify(props.data));
    } catch (e) {
      r.send(props.data || true);
    }
  }

  if (props.autoFire) {
    fire();
  }

  return {
    on: events.on,
    fire: fire,
    request: r
  };
}

/** Generate a uuid
 *  Borrowed from http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 *  @namespace highed
 *  @returns {string} - a UUID string
 */
export function uuid() {
  let d = new Date().getTime(),
    uuid;

  if (window.performance && typeof window.performance.now === 'function') {
    d += window.performance.now(); //use high-precision timer if available
  }

  uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function generateUuid(len) {
  function dec2hex(dec) {
    return dec.toString(16).padStart(2, '0');
  }
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

/** Convert a string to a bool
 *  @namespace highed
 *  @param {string} what - the string to convert
 *  @return {bool} - the resulting bool
 */
export function toBool(what) {
  return what === 'true' || what === true || what === 'on';
}

/** Set a property based on -- delimited path
 *  @namespace highed
 *  @param {object} obj - the object to modify
 *  @param {string} path - the path to the attribute to change
 *  @param {anything} value - the value to set
 *  @param {number} index - if we're accessing an array, this is the index
 */
export function setAttr(obj, path, value, index) {
  let current = obj;

  if (!current) return;

  if (isArr(obj)) {
    obj.forEach(function (thing) {
      setAttr(thing, path, value, index);
    });
    return;
  }

  path = path.replace(/\-\-/g, '.').replace(/\-/g, '.').split('.');

  path.forEach(function (p, i) {
    if (i === path.length - 1) {
      current[p] = value;
    } else {
      if (typeof current[p] === 'undefined') {
        current = current[p] = {};
      } else {
        current = current[p];

        if (isArr(current)) {
          if (index > current.length - 1) {
            for (let j = current.length; j <= index; j++) {
              current.push({});
            }
          }
          if (index >= 0) {
            current = current[index];
          }
        }
      }
    }
  });
}

/** Get a property based on -- delimited path
 *  @namespace highed
 *  @param {object} obj - the object to traverse
 *  @param {string} path - the path to the attribute to get
 *  @param {number} index - if we're accessing an array, this is the index
 *  @returns {anything} - the value or false
 */

export function getAttr(obj, path, index) {
  let current = obj,
    result = undefined;

  if (!current) return result;

  if (isArr(obj)) {
    obj.forEach(function (thing) {
      result = getAttr(thing, path);
    });
    return result;
  }

  path = path.replace(/\-\-/g, '.').replace(/\-/g, '.').split('.');

  path.forEach(function (p, i) {
    if (i === path.length - 1) {
      if (typeof current !== 'undefined') {
        result = current[p];
      }
    } else {
      if (typeof current[p] === 'undefined') {
        current = current[p] = {};
      } else {
        current = current[p];

        if (isArr(current) && index >= 0 && index < current.length) {
          current = current[index];
        }
      }
    }
  });

  return result;
}

export function isEmptyObjectArray(arr) {
  return (
    isObj(arr[0]) &&
    arr.some(function (b) {
      return Object.keys(b).length === 0;
    })
  );
}

export function isObj(what) {
  return what && what.constructor.toString().indexOf('Object') > -1;
}

/** Deep merge two objects.
 * Note: this modifies object `a`!
 * @namespace highed
 * @param {object} a - the destination object
 * @param {object} b - the source object
 * @param {bool} ignoreEmpty - Ignore empty things
 * @param {object} excludeMap - Map of properties to exclude from the merge
 * @return {object} - argument a
 */
export function merge(a, b, ignoreEmpty, excludeMap) {
  if (!a || !b) return a || b;

  if (ignoreEmpty && Object.keys(b).length === 0) {
    return;
  }

  Object.keys(b).forEach(function (bk) {
    if (excludeMap && excludeMap[bk]) {
    } else if (isNull(b[bk]) || isBasic(b[bk])) {
      a[bk] = b[bk];
    } else if (isArr(b[bk])) {
      if (isEmptyObjectArray(b[bk])) return;
      a[bk] = [];

      b[bk].forEach(function (i) {
        if (isNull(i) || isBasic(i)) {
          a[bk].push(i);
        } else {
          a[bk].push(merge(isArr(i) ? [] : {}, i));
        }
      });
    } else if (b[bk].tagName && b[bk].appendChild && b[bk].removeChild && b[bk].style) {
      a[bk] = b[bk];
    } else {
      if (ignoreEmpty && Object.keys(b[bk]).length === 0) {
        return;
      }
      if (isObj(b[bk]) && isStr(a[bk])) {
        // special case for gradient color objects merging to string
        a[bk] = b[bk];
        return;
      }

      a[bk] = a[bk] || {};
      merge(a[bk], b[bk]);
    }
  });
  return a;
}

/** Check if something is null or undefined
 *  @namespace highed
 *  @param {anything} what - the value to check
 *  @return {bool} - true if null
 */
export function isNull(what) {
  return typeof what === 'undefined' || what === null;
}

/** Check if something is a string
 *  @namespace highed
 *  @param {anything} what - the value to check
 *  @return {bool} - true if string
 */
export function isStr(what) {
  return typeof what === 'string' || what instanceof String;
}

/** Check if something is a number
 * @namespace highed
 *  @param {anything} what - the value to check
 *  @return {bool} - true if number
 */
export function isNum(what) {
  return !isNaN(parseFloat(what)) && isFinite(what);
}

/** Check if a value is a function
 * @namespace highed
 * @param {anything} what - the value to check
 * @return {bool} - true if function
 */
export function isFn(what) {
  return (what && typeof what === 'function') || what instanceof Function;
}

/** Check if a value is an array
 * @namespace highed
 * @param {anything} what - the value to check
 * @return {bool} - true if array
 */
export function isArr(what) {
  return !isNull(what) && what.constructor.toString().indexOf('Array') > -1;
}

/** Check if a value is a boolean
 * @namespace highed
 * @param {anything} what - the value to check
 * @return {bool} - true if bool
 */
export function isBool(what) {
  return what === true || what === false;
}

/** Check if a value is a basic type
 * A basic type is either a bool, string, or a number
 * @namespace highed
 * @param {anything} what - the value to check
 * @return {bool} - true if basic
 */
export function isBasic(what) {
  return !isArr(what) && (isStr(what) || isNum(what) || isBool(what) || isFn(what));
}

export function isScriptAlreadyIncluded(scripts, src) {
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].hasAttribute('src')) {
      const scriptTag = scripts[i].getAttribute('src') || '';
      if (
        scriptTag.indexOf(src) >= 0 ||
        (scriptTag.indexOf('highcharts.src.js') > -1 && src === 'highcharts.js') ||
        (scriptTag.indexOf('stock/highstock.js') > -1 && src === 'modules/highstock.js')
      ) {
        return true;
      }
    }
  }
  return false;
}

export function loadModules(paths, isJS, cb, addToExportList) {
  const length = paths.length;
  let counter = 0;

  paths.forEach(function (path) {
    let s;

    if (isJS) {
      let scripts = document.getElementsByTagName('script');
      if (!isScriptAlreadyIncluded(scripts, path)) {
        s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = path;
        s.onload = s.onreadystatechange = function (_, isAbort) {
          if (isAbort || !s.readyState || /loaded|complete/.test(s.readyState)) {
            s.onload = s.onreadystatechange = null;
            s = undefined;
            counter++;
            if (counter === length - 1) {
              if (cb) cb();
            }
          }
        };
        document.getElementsByTagName('head')[0].appendChild(s);
      }
    } else {
      s = document.createElement('link');
      s.rel = 'stylesheet';
      s.href = path;
      s.async = true;
      document.getElementsByTagName('head')[0].appendChild(s);
      if (addToExportList) {
        if (!window.Everviz) window.Everviz = {};
        if (!window.Everviz.externalCSS) window.Everviz.externalCSS = [];
        if (window.Everviz.externalCSS.indexOf(path) === -1) {
          window.Everviz.externalCSS.push(path);
        }
      }
    }
  });
}

export function clean(obj) {
  Object.keys(obj).forEach((key) => {
    if (isNull(obj[key])) delete obj[key];
  });
  return obj;
}

export function generateRandomColors() {
  let randomRGB = getRandomRgb();
  return {
    light: 'rgb(' + generateLighterColor(randomRGB, 0.2) + ')',
    dark: 'rgb(' + randomRGB.join(',') + ')'
  };
}

/** Make a camel back string pretty
 *  Transforms e.g. `imACamelBackString` to `Im a camel back string`.
 *  @namespace highed
 *  @param str {string} - the input string
 *  @return {string} - the transformed string
 */

export function uncamelize(str) {
  let s = '';

  if (!str) {
    return str;
  }

  if (str.length < 0 || !str) {
    return str;
  }

  for (let i = 0; i < str.length; i++) {
    if (str[i] === str[i].toUpperCase()) {
      if (
        (str[i + 1] && str[i + 1] === str[i + 1].toUpperCase()) ||
        (str[i - 1] && str[i - 1] === str[i - 1].toUpperCase())
      ) {
      } else {
        s += ' ';
      }
    }
    s += str[i];
  }

  return s[0].toUpperCase() + s.substr(1);
}

export function getLetterIndex(letters) {
  return letters.split('').reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0) - 1;
}

export function getLetterFromIndex(i) {
  return (i >= 26 ? getLetterFromIndex(Math.floor(i / 26) - 1) : '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26];
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

///////////////////////////////////////////////////////////////////////////

export function pollForReady() {
  if (typeof window === 'undefined') return;
  if (!highed.isReady) {
    if (document.body) {
      highed.isReady = true;
      highed.initQueue.forEach(function (fn) {
        fn();
      });
    } else {
      window.setTimeout(pollForReady, 100);
    }
  }
}

pollForReady();

///////////////////////////////////////////////////////////////////////////

/** Set/get an option
 *  Skip `value` to get the value
 *  @param option {string} - the option to set
 *  @param value {anything} - the value to set
 *  @returns {anything} - the option value
 */
export function option(option, value) {
  if (!isBasic(option)) {
    merge(highed.options, option);
  } else if (highed.options[option]) {
    if (typeof value !== 'undefined') {
      highed.options[option] = value;
    }
    return highed.options[option];
  }
  return false;
}

/** Add a function to call when the document is ready
 * @param {function} fn - the function to call
 */
export function ready(fn) {
  if (isFn(fn)) {
    if (highed.isReady) {
      fn();
    } else {
      highed.initQueue.push(fn);
    }
  }
}

export function getRandomRgb() {
  let num = Math.round(0xffffff * Math.random());
  let r = num >> 16;
  let g = (num >> 8) & 255;
  let b = num & 255;

  return [r, g, b];
}
export function generateLighterColor(fg, o) {
  return fg
    .map((colFg, idx) => {
      return Math.round(o * colFg + (1 - o) * [255, 255, 255][idx]);
    })
    .join(',');
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
