import { updateCustomizedAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentExportAction } from 'redux/actions/profile';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { Dimensions } from 'shared/editor/generic/ResolutionSection';
import CustomSelect, { SelectItem } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import CheckboxWidget from 'shared/widgets/CheckboxWidget';
import {
  VideoMimeType,
  fpsOptions,
  gifVideoOption,
  movVideoOption,
  videoOptions
} from 'shared/wizard/meta/VideoOptions';
import { downloadVideo } from 'shared/wizard/utils/videoHelper';
import { LocationMapExportMappingOptions, getLocationMapPropsForExporting } from '../../utils/locationMapMapper';
import ExportDimensions from './ExportDimensions';
import GeneratingVideo from './GeneratingVideo';
import withExportDimensions, { ExportDimensionsProps } from './withExportDimensions';

interface VideoSelectItem extends Omit<SelectItem, 'value'> {
  value: VideoMimeType;
}

const VideoChannel = (props: ExportDimensionsProps) => {
  const { setResolution, dimensions, showPaidPlanModal, showSubscriptionLock } = props;
  const dispatch = useDispatch();
  const postExportRef = useRef<HTMLDivElement>(null);
  const [videoFormat, setVideoFormat] = useState<VideoSelectItem>(videoOptions[0].item);
  const [fpsOption, setFpsOption] = useState<SelectItem>(fpsOptions[0]);
  const [loop, setLoop] = useState(false);
  const [callRecentExport, shouldCallRecentExport] = useState(false);
  const [videoStatus, setVideoStatus] = useState({
    generating: false,
    done: false
  });

  const { constr, chartId } = useSelector((state: RootState) => state.chartEditorPage);
  const projectConfig = useSelector((state: RootState) => state.projectConfig);
  const { uuid, provider, aggregatedOptions, projectName } = projectConfig;
  const { user, team } = useSelector((state: RootState) => state.profile);

  const handleDimensionsChange = () => {
    const newDimension = videoOptions.reduce((filtered, value) => {
      if (value.id === videoFormat.label) filtered = value.dimensions;
      return filtered;
    }, {} as Dimensions);
    setResolution(newDimension);
  };

  React.useEffect(() => {
    dispatch(updateCustomizedAction({ optionProps: 'everviz.exporting.fps', val: fpsOption.value }));
  }, [fpsOption]);

  React.useEffect(() => {
    handleDimensionsChange();
  }, [videoFormat]);

  const setGeneratingVideo = (status: boolean) => {
    setVideoStatus({
      generating: status,
      done: !status
    });

    if (!status) {
      setTimeout(() => {
        setVideoStatus({
          generating: false,
          done: false
        });
      }, 3000);
    }
  };

  useEffect(() => {
    dispatch(getRecentExportAction());
    shouldCallRecentExport(false);
  }, [callRecentExport]);

  const onExport = async () => {
    let options = aggregatedOptions;
    if (provider === 'locationMap') {
      const mappingOptions: LocationMapExportMappingOptions = {
        resolution: {
          type: 'manual',
          height: dimensions.height ?? 1200,
          width: dimensions.width ?? 800
        },
        disableControls: true
      };
      options = getLocationMapPropsForExporting(aggregatedOptions, mappingOptions);
    }

    downloadVideo({
      constr,
      setGeneratingVideo,
      aggregatedOptions: options,
      type: videoFormat.value,
      exportedBy: user.id,
      uuid,
      user,
      loop: loop ? '0' : '-1',
      teamId: team?.id,
      chartId,
      projectName,
      provider,
      shouldCallRecentExport
    });
  };

  return (
    <div className="flex flex-col">
      <p className="text-sm mb-4 text-black">Select your preferred format to export your project as.</p>
      <CustomSelect
        label="Format"
        options={Object.values(videoOptions).map((opt) => opt.item)}
        onChange={(selected) => setVideoFormat(selected as VideoSelectItem)}
        styles={editorStyles}
        value={videoFormat}
      />
      <ExportDimensions dimensions={dimensions} setResolution={setResolution} />
      {videoFormat.value === movVideoOption.item.value && (
        <CustomSelect
          label="FPS"
          options={fpsOptions as SelectItem[]}
          onChange={(selected) => setFpsOption(selected as VideoSelectItem)}
          styles={editorStyles}
          value={fpsOption}
          containerClasses="mt-2"
        />
      )}
      {videoFormat.value === gifVideoOption.item.value && (
        <CheckboxWidget label="Loop" value={loop} onChange={({ val }) => setLoop(val as boolean)} />
      )}
      <PrimaryButton
        useLoader={{
          default: {
            text: 'Export'
          },
          loading: {
            text: 'Export',
            check: videoStatus.generating
          },
          done: {
            text: 'Export',
            icon: 'check-circle',
            check: videoStatus.done
          }
        }}
        onClick={showSubscriptionLock ? showPaidPlanModal : onExport}
        className="content-centered mt-4 px-6 h-10 text-base self-end"
        showSubscriptionLock={showSubscriptionLock}
      />
      {videoStatus.generating && <GeneratingVideo userEmail={user.email} />}
      <div ref={postExportRef} />
    </div>
  );
};

export default withExportDimensions(VideoChannel);
