import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import actionTypes from '../../../redux/actions/action-types';
import { getTeamThemeWithTeamidAndThemeid } from '../../../api/cloudApiGenerated';
import { loadCSS } from '../../LayoutEditorPage/utils/storyHelper';
import { merge } from 'lodash';

export function* assignTheme(params) {
  let { theme, team, cb } = params.data;
  let themeData = null;

  const projectConfig = yield select((state) => state.projectConfig);
  let cssModules = (projectConfig.cssModules || []).slice();

  if (theme.isDefault) {
    themeData = {
      options: theme.template.options
    };

    if (theme.template.cssModules.length > 0) {
      theme.template.cssModules.forEach((module) => {
        loadCSS(module);
        if (!cssModules.includes(module)) {
          cssModules.push(module);
        }
      });

      yield put(
        setProjectConfigAction({
          cssModules
        })
      );
    }
  } else {
    let actualTheme = yield call(getTeamThemeWithTeamidAndThemeid, team.id, theme.id);
    themeData = JSON.parse(actualTheme.data);
  }

  let updatedProps = {
    themeOptions: {
      id: theme.id,
      options: merge(themeData.options, themeData.theme?.options)
    },
    themeMeta: {
      id: theme.id,
      name: theme.name
    },
    changeMade: true
  };

  if (themeData && themeData.cssModules && themeData.cssModules.length) {
    themeData.cssModules.forEach((module) => {
      if (cssModules.indexOf(module) === -1) {
        loadCSS(module);
        cssModules.push(module);
      }
    });
    updatedProps.cssModules = cssModules;
  }

  yield put(setProjectConfigAction(updatedProps));

  if (cb) {
    return cb();
  }
}

/** Watch functions */
export function* watchAssignTheme() {
  yield takeEvery(actionTypes.tableEditor.assignTheme, assignTheme);
}

export default function* rootSaga() {
  yield all([watchAssignTheme()]);
}
