import React from 'react';
import WizardContainer from 'shared/wizard/containers/WizardContainer';
import WizardLeftContainer from 'shared/wizard/containers/WizardLeftContainer';
import WizardRightContainer from 'shared/wizard/containers/WizardRightContainer';
import LayoutComponent from '../containers/LayoutComponent';
import Templates from './Templates';
class TemplateSelector extends React.Component {
  nextPage() {
    if (this.props.cb) {
      this.props.cb();
    }
  }

  render() {
    const { inWizard } = this.props;

    return (
      <WizardContainer>
        <WizardLeftContainer text={'Select a layout type.'} header={'Type'} subheader="Type">
          <Templates inWizard={inWizard} />
        </WizardLeftContainer>

        {inWizard && (
          <WizardRightContainer>
            <LayoutComponent />
          </WizardRightContainer>
        )}
      </WizardContainer>
    );
  }
}

export default TemplateSelector;
