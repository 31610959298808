import React, { useEffect, useState } from 'react';
import Modal from 'shared/modal/Modal';
import ReactGPicker from './../widgets/colorpicker/index';
import {
  generateGradientPatterns,
  getFinalColorStop,
  modifyColorPalette,
  parseColorPickerValue
} from './utils/colorPickerModalHelper';

import { calculateModalPosition, PositionProps } from './utils/modalPositionHelper';

type ColorPickerModalProps = {
  value: string;
  setValue?: (color: string) => void;
  isOpen: boolean;
  onClose: () => void;
  onChange?: (color: any) => void;
  showGradientColor?: boolean;
  colors?: string[];
  position: PositionProps;
};

export default function ColorPickerModal({
  value,
  setValue,
  isOpen,
  onClose,
  onChange,
  showGradientColor = false,
  colors,
  position
}: ColorPickerModalProps) {
  const [baseColor, setBaseColor] = useState(value ?? '');
  const [defaultColors, setDefaultColors] = useState(modifyColorPalette(colors ?? []));
  // initial render off-screen to obtain client height
  const [divStyle, setDivStyle] = useState<React.CSSProperties>({ position: 'absolute', top: '-9999px' });

  const onColorChange = (color: string) => {
    setBaseColor(color);
    const parsedColor = parseColorPickerValue(color);
    if (onChange) onChange(parsedColor);
    if (setValue) setValue(color);
  };

  const onTabChange = () => {
    calculateModalPosition(setDivStyle, position, 'gradient-color-picker', true);
  };

  useEffect(() => {
    calculateModalPosition(setDivStyle, position, 'gradient-color-picker');
  }, []);

  useEffect(() => {
    const base = getFinalColorStop(baseColor);
    if (base) {
      generateGradientPatterns(base, setDefaultColors);
    }
  }, [baseColor]);

  return (
    <Modal
      width="w-7/11 max-w-xl pb-0"
      bodyClass={'p-0'}
      overlayClass="bg-transparent"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      {divStyle && (
        <>
          <div className="gradient-color-picker" style={divStyle}>
            <ReactGPicker
              defaultColors={defaultColors}
              value={value ?? ''}
              onChange={(color) => onColorChange(color)}
              gradient={showGradientColor}
              format="hex"
              showGradientPosition={false}
              showGradientMode={false}
              onChangeTabs={onTabChange}
            />
          </div>
        </>
      )}
    </Modal>
  );
}
