import React, { ChangeEvent, isValidElement, useEffect, useState } from 'react';
import { getActionCells, getColumnCells } from 'shared/utils/tableRowHelper';
import TableCell from './TableCell';
import {
  TableActionCellsType,
  TableCellType,
  TableCellsType,
  TableColumnCellsType,
  TableRowProps
} from 'shared/types/tableModels';
import TableEditAction from './TableEditAction';

const TableRow = ({
  columns,
  row,
  columnHeaders,
  editableColumns,
  editText,
  isDisabled,
  isActionCell,
  editActions,
  editActionsDisableIds,
  onRowSave,
  onRowDataChange
}: TableRowProps) => {
  const [rowData, setRowData] = useState(Object.assign({}, row));
  const [isEditMode, setIsEditMode] = useState(!isActionCell && !isDisabled);
  const [columnCells, setColumnCells] = useState<TableColumnCellsType>([]);
  const [actionCells, setActionCells] = useState<TableActionCellsType>([]);
  const [allCells, setAllCells] = useState<TableCellsType>([]);

  useEffect(() => {
    setIsEditMode(!isActionCell && !isDisabled);
  }, [isDisabled]);

  useEffect(() => {
    setRowData(Object.assign({}, row));
  }, [row]);

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCellChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const copiedRowData = Object.assign({}, rowData);
    copiedRowData[target.name] = target.type === 'checkbox' ? target.checked : target.value;

    onRowDataChange(copiedRowData);
  };

  const onCellSelectChange = (columnId: string, data: any) => {
    const copiedRowData = Object.assign({}, rowData);
    copiedRowData[columnId] = data.value;

    onRowDataChange(copiedRowData);
  };

  const onCancel = () => {
    setRowData(Object.assign({}, row));
    setIsEditMode(false);
  };

  const onSave = () => {
    if (onRowSave) {
      onRowSave(rowData);
    }

    setIsEditMode(false);
  };

  useEffect(() => {
    const generattedColumnCells = getColumnCells(
      columns,
      columnCells,
      editableColumns,
      rowData,
      isEditMode,
      onCellSelectChange,
      onCellChange
    );

    setColumnCells(generattedColumnCells);
  }, [columns, editableColumns, rowData, isEditMode]);

  useEffect(() => {
    if (isActionCell && editableColumns && Object.keys(editableColumns).length > 0) {
      const formattedActionCells = getActionCells(isDisabled, isEditMode, editText, onEdit, onSave, onCancel);
      setActionCells(formattedActionCells);
    }
  }, [editableColumns, rowData, isDisabled, isEditMode]);

  useEffect(() => {
    setAllCells([
      ...(Array.isArray(columnCells) ? columnCells : []),
      ...(Array.isArray(actionCells) ? actionCells : []),
      ...(isValidElement(actionCells) ? [actionCells] : [])
    ]);
  }, [columnCells, actionCells]);

  const isTableEditAction =
    !editActionsDisableIds?.some((id) => id === row?.id) && Array.isArray(editActions) && editActions.length > 0;

  return (
    <tr className="py-2 shadow-ev-standard ev-md:min-h-14 ev-md:py-0 ev-md:table-row">
      {allCells.map((cell: TableCellType, index: number) => (
        <TableCell key={index} cell={cell} index={index} columnHeaders={columnHeaders} />
      ))}
      {isTableEditAction && <TableEditAction actionsList={editActions} rowData={rowData} />}
    </tr>
  );
};

export default TableRow;
