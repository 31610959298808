import React from 'react';

export default (props) => {
  const { panelOptions, options, activePanel, customizeOptions } = props;
  return (
    <div className="overflow-y-auto overflow-x-hidden ev-sm:h-[calc(100vh-130px)] text-ev-navy-blue-2 shadow-sm left-panel mr-2">
      {(panelOptions || []).map((panel) => {
        return (
          <panel.element
            key={'option_' + panel.id}
            aggregatedOptions={options}
            active={activePanel === panel.id}
            customizeOptions={customizeOptions}
          />
        );
      })}
    </div>
  );
};
