import { cloneDeep } from 'lodash';
import { updateAggregated } from 'pages/ChartEditorPage/middleware/ChartEditor';
import { useCompanyTheme, useCompanyThemeOptions } from 'pages/CompanyThemeEditorPage/middleware/themeEditorPage';
import { updateBufferData } from 'pages/TableEditorPage/middleware/tableEditorData';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setAction as setChartAction } from '../../pages/ChartEditorPage/actions/chartEditor';
import { getAggregatedConfig, getChartConfig } from '../../pages/ChartEditorPage/selectors/chartEditor';
import { setAction as setLayoutAction } from '../../pages/LayoutEditorPage/actions/layoutEditor';
import { getConfig as getLayoutConfig } from '../../pages/TableEditorPage/selectors/tableEditor';
import actionTypes from '../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../redux/actions/projectConfig';
import {
  getDivisionTypeThemesWithDivisionidAndCharttype,
  getTeamCompanythemesWithTeamid,
  getTeamTypeThemesWithTeamidAndCharttype,
  postTeamChartUnpublishUsingTeamidAndChartid
} from './../../api/cloudApiGenerated';
import { getProfileConfig } from './../../redux/selectors/profile';
import { getProjectConfig } from './../../redux/selectors/projectConfig';

// Unpublish a project
export function* unpublish() {
  const { chartId } = yield select(getChartConfig);
  const { team } = yield select(getProfileConfig);
  const { type } = yield select(getProjectConfig);
  const { storyId } = yield select(getLayoutConfig);

  let id = chartId;
  if (type === 'table' || type === 'layout') id = storyId;

  yield call(postTeamChartUnpublishUsingTeamidAndChartid, team.id, id);

  yield put(
    setProjectConfigAction({
      embedDetails: {},
      published: false,
      lastPublishTime: false
    })
  );
}

export function* showSignupModal() {
  let chartEditorPage = yield select(getChartConfig);
  const { dataId, referenced, live } = chartEditorPage;
  const projectConfig = yield select(getProjectConfig);
  let { projectName } = projectConfig;

  let body = {};
  let project = {};
  project = getAggregatedConfig(projectConfig, chartEditorPage);

  let titleValue = projectName;

  if (!projectName) {
    if (project?.title?.text) {
      titleValue = project?.title?.text;
    } else titleValue = 'Untitled chart';
  }

  body = {
    data: JSON.stringify(project),
    dataId,
    referenced,
    live,
    name: titleValue
  };

  // Anon project, pop up modal
  yield put(
    setChartAction({
      editorChartConfig: body,
      signupModalOpen: true
    })
  );
}

export function* getCompanyThemes() {
  const { team } = yield select((state) => state.profile);
  if (!team) return;

  const data = yield call(getTeamCompanythemesWithTeamid, team.id);
  yield put(
    setProjectConfigAction({
      companyThemes: data ?? []
    })
  );
}

export function* assignCompanyTheme(params) {
  const { selected } = params.data;
  const { type } = yield select(getProjectConfig);
  yield call(useCompanyTheme, selected, selected.id, false, type);

  if (type === 'chart') {
    // Regenerate map colorAxis properties
    yield call(useCompanyThemeOptions);
    yield call(updateAggregated, true);
  } else if (type === 'table') yield call(updateBufferData, {});
}

export function* getThemes(params) {
  let { team, division, type } = params.data;
  const { provider } = yield select((state) => state.projectConfig);

  if (!team) {
    yield put(
      setLayoutAction({
        themes: []
      })
    );
    return;
  }

  const chartConfig = yield select(getChartConfig);
  const chartConstr = chartConfig.constr || 'Chart';
  const themesMap = {
    Chart: 1,
    StockChart: 2,
    Map: 3
  };

  let themeType = themesMap[chartConstr];
  if (type === 'table') themeType = 5;
  else if (type === 'layout') themeType = 4;
  if (provider === 'locationMap') themeType = 6;

  const getThemesAjax = () => {
    if (division && division.id !== null) {
      return getDivisionTypeThemesWithDivisionidAndCharttype(division.id, themeType);
    } else if (team && team.id) return getTeamTypeThemesWithTeamidAndCharttype(team.id, themeType);
  };

  try {
    const data = yield call(getThemesAjax);
    // TODO: set this on generic project config instead
    yield put(
      setLayoutAction({
        themes: data.data
      })
    );
  } catch (e) {
    throw new Error(e);
  }
}

export function* updateCSSModules(params) {
  let { newModule } = params.data;
  const { cssModules: oldCSSModules } = yield select((state) => state.projectConfig);
  const cssModules = cloneDeep(oldCSSModules);

  if (!cssModules.includes(newModule)) {
    cssModules.push(newModule);
  }

  yield put(
    setProjectConfigAction({
      cssModules
    })
  );
}

export function* watchUnpublish() {
  yield takeEvery(actionTypes.projectConfig.unpublish, unpublish);
}
export function* watchShowSignupModal() {
  yield takeEvery(actionTypes.projectConfig.showSignupModal, showSignupModal);
}
export function* watchGetThemes() {
  yield takeEvery(actionTypes.projectConfig.getThemes, getThemes);
}
export function* watchGetCompanyThemes() {
  yield takeEvery(actionTypes.projectConfig.getCompanyThemes, getCompanyThemes);
}
export function* watchUpdateCSSModules() {
  yield takeEvery(actionTypes.projectConfig.updateCSSModules, updateCSSModules);
}
export function* watchAssignCompanyTheme() {
  yield takeEvery(actionTypes.projectConfig.assignCompanyTheme, assignCompanyTheme);
}

export default function* rootSaga() {
  yield all([
    watchUnpublish(),
    watchShowSignupModal(),
    watchGetThemes(),
    watchUpdateCSSModules(),
    watchGetCompanyThemes(),
    watchAssignCompanyTheme()
  ]);
}
