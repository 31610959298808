import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useToggle from 'shared/utils/useToggle';

import FieldContainer from './FieldContainer';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import ColorPickerModal from 'shared/modal/ColorPickerModal';
import { createColorPickerValue, isStringGradient } from 'shared/modal/utils/colorPickerModalHelper';

const ColorWidget = (props: GenericInputProps) => {
  const [showColorPickerModal, toggleColorPickerModal] = useToggle();
  const [value, setValue] = useState('');
  const [position, setPosition] = useState({ xPos: 0, yPos: 0 });

  const { aggregatedOptions } = useSelector((state: RootState) => state.projectConfig);
  const defaultColors = aggregatedOptions?.colors ?? [];

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition({ xPos: e.clientX, yPos: e.pageY });
    toggleColorPickerModal(true);
  };

  const onChange = (color: any) => {
    if (props.isArraySelector) props.onChange({ val: color, index: props.index });
    else {
      props.onChange({ val: color });
    }
  };

  useEffect(() => {
    setValue(createColorPickerValue(props.value));
  }, [props.value]);

  return (
    <>
      {props.inputType === 'array<color>' || !props.label ? (
        <>
          <div className="relative group" id={props.option?.id}>
            <div className="color-picker-transparent-widget w-[30px] h-[30px] " />
            <div
              onClick={onClick}
              className="color-picker-widget w-[30px] h-[30px] border-4 border-solid border-white hover:border-ev-baby-blue-2 group-hover:border-ev-baby-blue-2 drop-shadow-color-widget-shadow"
              style={{
                background: value
              }}
            />
          </div>
        </>
      ) : (
        <FieldContainer
          label={props.label}
          labelClass={props.option?.labelClasses ? props.option.labelClasses : 'text-ev-navy-blue-4'}
          value={isStringGradient(value) ? 'Gradient' : value}
          className={props.className}
          id={props.option?.id}
        >
          <div className="relative group ml-3">
            <div className="color-picker-transparent-widget h-6 w-6" />
            <div
              onClick={onClick}
              className="color-picker-widget w-6 h-6 border-4 border-solid border-white hover:border-ev-baby-blue-2 group-hover:border-ev-baby-blue-2 drop-shadow-color-widget-shadow"
              style={{
                background: value
              }}
            />
          </div>
        </FieldContainer>
      )}

      {showColorPickerModal && (
        <ColorPickerModal
          value={value}
          setValue={setValue}
          isOpen={showColorPickerModal}
          onClose={toggleColorPickerModal}
          onChange={onChange}
          showGradientColor={props.option?.custom?.showGradientColorTab}
          colors={defaultColors}
          position={position}
        />
      )}
    </>
  );
};

export default ColorWidget;
