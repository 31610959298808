import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import InlineOptionsContainer from '../../editor/generic/InlineOptionsContainer';
import LocationMapToolbar from '../../editor/generic/LocationMapToolbar';

const toolbarMap = {
  highcharts: <></>,
  locationMap: (
    <InlineOptionsContainer>
      <LocationMapToolbar isCompact={true} />
    </InlineOptionsContainer>
  )
};

export type WizardRightContainerProps = {
  children: React.ReactNode;
  isMap?: boolean;
  showToolbar?: boolean;
  showBottomToolbar?: boolean;
};
export default function WizardRightContainer(props: WizardRightContainerProps) {
  const { provider } = useSelector((state: RootState) => state.projectConfig);

  const toolbar = toolbarMap[provider];

  const classes = classNames(
    'bg-ev-yellow-1 ev-sm:bg-ev-grey  ev-sm-max:h-[330px] rounded p-6 grow ev-sm-max:shrink-0 relative shadow-top ev-sm:shadow-none',
    {
      'overflow-auto': props.isMap
    }
  );

  return (
    <div className={'flex flex-col w-full'}>
      {props.showToolbar && <div className="pt-4 pb-2">{toolbar}</div>}
      <div className={classes}>{props.children}</div>
    </div>
  );
}
