import { TemplateTypeProps } from '../../types';
import Bar from './Bar';
import ColumnRangeWithLabels from './ColumnRangeWithLabels';
import ErrorBar from './ErrorBar';
import HorizontalColumnRange from './HorizontalColumnRange';
import Logarithmic from './Logarithmic';
import Multicolor from './Multicolor';
import NegativeColor from './NegativeColor';
import PackedColumns from './PackedColumns';
import RadialBar from './RadialBar';

const BarTemplate: TemplateTypeProps = {
  templates: {
    Bar,
    'Horizontal columnrange': HorizontalColumnRange,
    'Columnrange with labels': ColumnRangeWithLabels,
    'Error bar': ErrorBar,
    Logarithmic,
    'Multi color': Multicolor,
    'Negative color': NegativeColor,
    'Packed columns': PackedColumns,
    'Radial bar': RadialBar
  },
  description: [
    'A bar chart is a chart that presents grouped data with rectangular bars with lengths proportional to the values that they represent.'
  ],
  nofits: 'The dataset must contain at least one column.',
  samples: []
};

export default BarTemplate;
