import { cloneDeep, isString, capitalize } from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLinkingDataError } from 'redux/reducers/wizardReducer';
import { getProfileConfig } from 'redux/selectors/profile';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import api from 'utils/ajax';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { setDataAction } from '../actions/chartEditor';
import { togglePlugins } from './ChartEditorCustomize';
import { StockDataType } from 'shared/wizard/meta/LinkDataOptions';
import { parseCSV } from '../utils/chartEditorDataHelper';

const domain = 'https://data.everviz.com';

/**
 * Getting third party api search results
 */
export function* searchApi(params) {
  const { query, market, resolve, provider } = params.data;
  const { team } = yield select(getProfileConfig);

  try {
    const searchUrl = `${domain}/team/${team.id}/provider/${provider}/search`;
    const data = yield call(api.ajax, {
      url: searchUrl,
      type: 'post',
      data: { query, market }
    });

    const searchResults = data.map((item) => ({
      label: item.name,
      value: item.symbol,
      exchange: item.exchange,
      isCurrency: item.isCurrency
    }));

    resolve(searchResults);
  } catch (error) {
    yield put(
      setLinkingDataError({
        error: isString(error) ? error : `There was an error when fetching the ${capitalize(provider)} data.`
      })
    );
  }
}

export function* linkExternalData(params) {
  const { symbols, dataRefreshRate, enablePolling, timePeriod, provider, stockDataType } = params.data;
  const { team } = yield select(getProfileConfig);
  const { customizedOptions: oc } = yield select(getProjectConfig);
  const customizedOptions = cloneDeep(oc);

  const urlPathType = stockDataType === StockDataType.Performance ? 'financial' : 'data';
  const symbolsData = {
    symbols: symbols.map(({ label, value }) => ({ name: label, symbol: value })),
    interval: timePeriod
  };
  customizedOptions.everviz = {
    ...(customizedOptions.everviz ?? {}),
    data: symbolsData
  };

  if (window?.everviz?.cachedDataServerData) {
    delete window.everviz.cachedDataServerData;
  }

  try {
    yield call(togglePlugins, ['data-server'], true);
    const csvURL = `${domain}/team/${team.id}/provider/${provider}/${urlPathType}`;

    const data = yield call(api.ajax, {
      url: csvURL,
      type: 'post',
      data: symbolsData,
      dataType: 'csv'
    });

    if (data) {
      const parsed = parseCSV(data, ';');
      const [headers] = parsed;
      if (headers?.length) {
        // Pull out headers so we can work out how many series there should be in the chart.
        const seriesLength = headers.length - 1;
        customizedOptions.series = Array(seriesLength)
          .fill(null)
          .map((_, i) => ({
            data: [],
            name: headers[i + 1]
          }));
      }
    }

    yield put(
      setProjectConfigAction({
        customizedOptions
      })
    );

    yield put(
      setDataAction({
        options: {
          csv: data,
          csvURL,
          dataRefreshRate: dataRefreshRate.value,
          enablePolling
        },
        dataType: 'data-server'
      })
    );
  } catch (error) {
    console.error(error);
  }
}

/** Watch functions */

export function* watchGetApiData() {
  yield takeEvery(actionTypes.chartEditor.searchApi, searchApi);
}

export function* watchLinkExternalData() {
  yield takeEvery(actionTypes.chartEditor.linkExternalData, linkExternalData);
}

export default function* rootSaga() {
  yield all([watchGetApiData(), watchLinkExternalData()]);
}
