export const adjustmentsTableColumns = [
  {
    title: 'Date',
    id: 'created'
  },
  {
    title: 'Amount',
    id: 'total'
  },
  {
    title: 'Status',
    id: 'state'
  },
  {
    title: 'Plan',
    id: 'description'
  },
  {
    title: 'ID',
    id: 'id'
  },
  {
    title: 'Download invoice',
    id: 'invoice'
  }
];
