import { connect } from 'react-redux';
import TeamWebhooksPage from '../TeamWebhooksPage';
import {
  setTeamWebhookAction,
  getTeamWebhooksAction,
  getSupportedWebhooksAction,
  deleteWebhooksAction,
  updateWebhookAction,
  setAction
} from '../actions/teamWebhooksPage.js';
import { checkPermission, checkPlanPermission } from '../../../utils/profileHelper';

const mapStateToProps = (state) => {
  return {
    supported: state.teamWebhooksPage.supported,
    webhooks: state.teamWebhooksPage.webhooks,
    modalIsOpen: state.teamWebhooksPage.modalIsOpen,
    form: state.teamWebhooksPage.form,
    paths: state.app.paths || {},
    team: state.profile.team,
    division: state.profile.division,
    errorMessage: '',
    checkPermission: (permission) => checkPermission(permission, state.profile),
    checkPlanPermission: (permission) => checkPlanPermission(permission, state.profile)
  };
};

const mapDispatchToProps = (dispatch) => ({
  getWebhooks: (team) => dispatch(getTeamWebhooksAction(team)),
  getSupportedWebhooks: (data) => dispatch(getSupportedWebhooksAction(data)),
  setWebhook: (team, form) => dispatch(setTeamWebhookAction({ team, form })),
  deleteWebhooks: (team, webhooks) => dispatch(deleteWebhooksAction({ team, webhooks })),
  updateWebhook: (team, row) => dispatch(updateWebhookAction({ team, row })),
  set: (params) => dispatch(setAction(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamWebhooksPage);
