export const loadCSS = (src, addToExport) => {
  return new Promise((resolve) => {
    let s = document.createElement('link');
    s.rel = 'stylesheet';
    s.async = true;
    s.href = src;
    s.onload = s.onreadystatechange = function (_, isAbort) {
      if (isAbort || !s.readyState || /loaded|complete/.test(s.readyState)) {
        s.onload = s.onreadystatechange = null;
        s = undefined;

        if (!isAbort) {
          return resolve();
        }
      }
    };
    document.getElementsByTagName('head')[0].appendChild(s);

    if (addToExport) {
      if (!window.evervizCSS) window.evervizCSS = {};
      if (!window.evervizCSS.externalCSS) window.evervizCSS.externalCSS = [];
      if (window.evervizCSS.externalCSS.indexOf(src) === -1) {
        window.evervizCSS.externalCSS.push(src);
      }
    }
  });
};

export const isEmpty = (section) => {
  return section?.id || section?.hash;
};

export const fontSizes = [
  '2',
  '4',
  '6',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
  '26',
  '28',
  '30',
  '32',
  '36',
  '48',
  '60',
  '72',
  '96',
  '100'
].map((e) => {
  return { label: e, value: e };
});
