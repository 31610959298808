export default {
  supported: [], // The webhooks supported by the system
  webhooks: [], // The webhooks owned by the team.
  modalIsOpen: false,
  form: {
    title: '',
    url: '',
    type: -1,
    error: '',
  },
};
