// This is a copy of lib/plugins.js

let config = {
  sankey: {
    scripts: ['modules/sankey.js']
  },
  dependencywheel: {
    scripts: ['modules/sankey.js', 'modules/dependency-wheel.js']
  },
  treemap: {
    scripts: ['modules/heatmap.js', 'modules/treemap.js']
  },
  parliament: {
    scripts: ['modules/item-series.js', 'https://app.everviz.com/resources/js/highcharts-font-awesome.js']
  },
  wordcloud: {
    scripts: ['modules/wordcloud.js']
  },
  dumbbell: {
    scripts: ['modules/dumbbell.js']
  },
  vector: {
    scripts: ['modules/vector.js']
  },
  pyramid3d: {
    scripts: ['modules/cylinder.js', 'modules/funnel3d.js', 'modules/pyramid3d.js']
  },
  funnel3d: {
    scripts: ['modules/cylinder.js', 'modules/funnel3d.js', 'modules/pyramid3d.js']
  },
  packedbubble: {},
  heatmap: {
    scripts: ['modules/heatmap.js']
  },
  streamgraph: {
    scripts: ['modules/streamgraph.js']
  },
  timeline: {
    scripts: ['modules/timeline.js']
  },
  'icon-chart': {
    scripts: ['modules/item-series.js', 'https://app.everviz.com/resources/js/highcharts-font-awesome.js'],
    externalCSS: ['https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css']
  },
  annotations: {
    scripts: [
      'modules/annotations.js',
      'modules/annotations-advanced.js',
      'https://app.everviz.com/resources/js/highcharts-small-screen-annotations.js'
    ],
    externalCSS: ['https://app.everviz.com/resources/css/highcharts-small-screen-annotations.css']
  },
  map: {
    scripts: [
      'maps/modules/map.js',
      'mapdata/index.js?1',
      'modules/tilemap.js',
      'modules/pattern-fill.js',
      'https://cdnjs.cloudflare.com/ajax/libs/proj4js/2.3.6/proj4.js'
    ],
    externalScripts: []
  },
  'exporting--offlineExporting': {
    scripts: ['modules/offline-exporting.js']
  },
  'series-label': {
    scripts: ['modules/series-label.js']
  },
  'data-server': {
    scripts: ['https://app.everviz.com/resources/js/highcharts-live-data-override.js']
  },
  'background-image': {
    scripts: ['https://app.everviz.com/resources/js/highcharts-addons.js']
  }
};

export const getPluginsConfig = () => config;

export const getPlugin = (name) => {
  if (typeof config[name] === 'undefined') {
    console.error('accessed undefined config:', name);
    return false;
  }

  return config[name];
};
