/* eslint-disable no-invalid-this */
import React from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';

export default function SmallScreenMessage() {
  const onClick = () => {
    window.location.href = 'https://www.everviz.com';
  };

  return (
    <div className="block ev-xs:hidden bg-ev-navy-blue-2 w-full h-full fixed top-0 z-[100000] text-white font-bold pt-24 mt-[66px] px-16 center">
      <div className="">
        <img src="/static/images/small-screen-disable.svg" className="" />
      </div>
      <div className="px-10">
        <div>This content is not optimized for editing on small devices.</div>
        <div className="pt-4">Use a larger device to create engaging visualizations.</div>
      </div>

      <div className="pt-12">
        <PrimaryButton text="Go to everviz.com" onClick={onClick} buttonColor={ButtonColor.Yellow} />
      </div>
    </div>
  );
}
