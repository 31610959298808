import * as React from 'react';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import TextButton from 'shared/buttons/TextButton';
import { UnknownParamsFunction } from 'shared/types/commonPropTypes';

type AccordionButtonProps = {
  handleClick: UnknownParamsFunction;
  label: string;
  buttonIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  iconClasses: string;
  buttonColor: ButtonColor;
  size: number;
};

const AccordionButton = (props: AccordionButtonProps) => {
  return (
    <div className="flex items-center justify-end">
      <TextButton text={props.label} onClick={props.handleClick} className="text-ev-navy-blue-2 text-xs" />
      <SvgIconButton
        Icon={props.buttonIcon}
        width={props.size}
        height={props.size}
        buttonClasses={props.iconClasses}
        buttonColor={props.buttonColor}
        onClick={props.handleClick}
      />
    </div>
  );
};

export default AccordionButton;
