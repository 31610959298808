import React, { memo, useCallback, useEffect } from 'react';
import Accordion from '../../Accordion';
import { useSectionStore } from './sectionStore';

export type SectionContainerProps = {
  children: React.ReactNode;
  headerText: React.ReactNode;
  sectionKey: string;
  parentSectionKey?: string;
  childrenSectionKeys?: string[];
  showContainer?: boolean;
  openedByDefault?: boolean;
  selectedChildren?: string;
  accordionClasses?: string;
  smallHeaderText?: boolean;
  onSelect?: () => void;
};

export const SectionNode = memo(
  ({
    children,
    showContainer,
    headerText,
    selectedChildren,
    accordionClasses,
    openedByDefault,
    sectionKey,
    parentSectionKey,
    childrenSectionKeys,
    smallHeaderText,
    onSelect
  }: SectionContainerProps) => {
    const sectionStore = useSectionStore();

    const isSelected = sectionStore.selectedSections.has(sectionKey);

    const onClick = useCallback(() => {
      sectionStore.addOrRemoveSelectedSection(sectionKey);
    }, [sectionKey]);

    useEffect(() => {
      if (openedByDefault ?? false) {
        sectionStore.addOrRemoveSelectedSection(sectionKey);
      }
    }, []);

    useEffect(() => {
      if (!sectionKey) return;
      sectionStore.addSection(sectionKey, parentSectionKey);
    }, [sectionKey, parentSectionKey]);

    useEffect(() => {
      if (childrenSectionKeys) {
        childrenSectionKeys.forEach((x) => sectionStore.addSection(x, sectionKey));
      }
    }, [childrenSectionKeys]);

    useEffect(() => {
      if (isSelected) {
        if (onSelect) onSelect();
      }
    }, [isSelected]);

    if (!(showContainer ?? true)) return <>{children}</>;
    return (
      <Accordion
        key={sectionKey}
        headerText={headerText}
        smallHeaderText={smallHeaderText}
        useInternalSelect={false}
        selected={isSelected}
        selectedChildren={selectedChildren}
        accordionClasses={accordionClasses}
        childrenClasses="rounded mx-6 py-2 gap-2 relative flex-col"
        onClick={onClick}
      >
        {children}
      </Accordion>
    );
  }
);
