import { TemplateProps } from '../../types';

const Pie: TemplateProps = {
  title: 'Pie',
  description: ['Good starting point for custom pie series.'],
  constructor: 'Chart',
  thumbnail: 'yqoxob.svg',
  icon: '',
  sampleSets: [],
  validator: 'pie',
  config: {
    chart: {
      type: 'pie'
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true
        }
      }
    }
  },
  advancedParent: 'Pie',
  basic: {
    order: 4,
    thumbnail: 'pie_chart.svg',
    icon: 'Pie'
  },
  advanced: {
    name: 'Standard'
  },
  sampleSet: 'pie',
  parent: 'Pie'
};

export default Pie;
