import React, { useState } from 'react';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import Modal from 'shared/modal/Modal';
import StarIcon from 'static/icons/star.svg';
import { ButtonColor } from '../buttons/types/ButtonModels';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { postTeamSalesBookUsingTeamid } from '../../api/cloudApiGenerated';

type PremiumFeatureModalProps = {
  isOpen: boolean;
  close: () => void;
};

export default function PremiumFeatureModal(props: PremiumFeatureModalProps) {
  const { isOpen, close } = props;
  const { premiumFeatureName } = useSelector((state: RootState) => state.app);
  const { team } = useSelector((state: RootState) => state.profile);
  const [bookingSent, setBookingSent] = useState(false);

  const notifyInterest = () => {
    postTeamSalesBookUsingTeamid(team.id, { subject: premiumFeatureName });
    setBookingSent(true);
  };

  const closeModal = () => {
    close();
    setBookingSent(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title={
        <div className="flex gap-2 items-center">
          <StarIcon width={17} height={16} />
          Premium feature
        </div>
      }
      width="w-5/12 max-w-xl"
      bg="bg-ev-yellow-1"
    >
      {bookingSent ? (
        <div>
          <div className="hc-premium-text">
            <p>
              That is great! Sales have been notified of your interest in {premiumFeatureName} and will contact you
              shortly.
            </p>
          </div>
          <hr />
          <div className="flex gap-4">
            <PrimaryButton onClick={closeModal} text="Take me back" />
          </div>
        </div>
      ) : (
        <div>
          <div className="hc-premium-text">
            <p>
              {premiumFeatureName} is a premium feature. Are you interested in requesting a demo on {premiumFeatureName}
              ?
            </p>
          </div>

          <hr />
          <div className="flex gap-4">
            <PrimaryButton onClick={notifyInterest} text="Request a demo" />
            <PrimaryButton onClick={closeModal} buttonColor={ButtonColor.BabyBlue2} text="Take me back" />
          </div>
        </div>
      )}
    </Modal>
  );
}
