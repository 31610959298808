import { TemplateTypeProps } from '../../types';
import Timeline from './Timeline';
import TimelineBlocks from './TimelineBlocks';

const TimelineTemplate: TemplateTypeProps = {
  templates: {
    Timeline,
    'Timeline blocks': TimelineBlocks
  }
};

export default TimelineTemplate;
