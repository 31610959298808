import React from 'react';
import Modal from 'react-modal';
import { stripHtml } from '../../../utils/helper';
import { download } from 'highed';
import { isObj } from 'editor/core/highcharts-editor';

class ExportModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.close = this.close.bind(this);
    this.exportCommaDelimited = this.exportCommaDelimited.bind(this);
    this.exportSemiColonDelimited = this.exportSemiColonDelimited.bind(this);

    this.state = {
      value: ''
    };
  }

  close() {
    this.props.close('export');
  }

  exportCommaDelimited() {
    const options = this.props.dataOptions;
    const csv = options
      .map((cols) => {
        cols = cols.map((c) => (isObj(c) ? stripHtml(c.value) : stripHtml(c)));
        return cols.join(',');
      })
      .join('\n');
    download('data.csv', csv, 'application/csv');
  }

  exportSemiColonDelimited() {
    const options = this.props.dataOptions;
    const csv = options
      .map((cols) => {
        cols = cols.map((c) => (isObj(c) ? stripHtml(c.value) : stripHtml(c)));
        return cols.join(';');
      })
      .join('\n');
    download('data.csv', csv, 'application/csv');
  }

  render() {
    return (
      <Modal
        className="hc-modal-content hc-gsheet-modal hc-import-richtext-modal"
        overlayClassName="hc-modal-overlay"
        appElement={document.querySelector('.page-container')}
        parentSelector={this.props.parentSelector}
        isOpen={this.props.isOpen}
        onRequestClose={this.close}
        contentLabel="Export"
      >
        <div className="highed-modal-container">
          <div className="highed-modal-title highed-help-toolbar">Export</div>
          <div>
            <div className="highed-modal-text">
              Export your data using the buttons below. You can either export your csv file using comma or semi-colon
              delimiters.
            </div>

            <button className="bulk-action-btn negative" onClick={this.exportCommaDelimited}>
              Export comma delimited
            </button>
            <button className="bulk-action-btn negative" onClick={this.exportSemiColonDelimited}>
              Export semi-colon delimited
            </button>
          </div>
          <div></div>
        </div>
      </Modal>
    );
  }
}

ExportModal.propTypes = {};

export default ExportModal;
