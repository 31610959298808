import { cancelTextMap } from 'shared/utils/paidPlanHelper';
import actionTypes from '../actions/action-types';
import initialState from './initial-state';

const appReducer = (state = initialState.app, action) => {
  switch (action.type) {
    case actionTypes.app.set:
      return {
        ...state,
        ...action.data
      };
    case actionTypes.app.openPaidPlanModal:
      return {
        ...state,
        ...action.data,
        isPaidPlanModalOpen: true,
        cancelText: action?.data?.cancelText ?? cancelTextMap.def,
        featureText: action?.data?.featureText ?? 'feature'
      };

    case actionTypes.app.openPremiumFeatureModal:
      return {
        ...state,
        ...action.data,
        premiumFeatureName: action?.data?.premiumFeatureName,
        isPremiumFeatureModalOpen: true
      };
  }
  return state;
};

export default appReducer;
