import actionTypes from '../../../redux/actions/action-types';

export const saveThemeAction = (data) => ({
  type: actionTypes.themeEditor.saveTheme,
  data
});
export const loadThemeAction = (data) => ({
  type: actionTypes.themeEditor.loadTheme,
  data
});
