import { TemplateProps } from '../../types';

const Column3D: TemplateProps = {
  title: 'Column 3D',
  description: '',
  constructor: '',
  thumbnail: 'ahyqyx.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column',
      margin: 75,
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 15
      },
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      column: {
        depth: 25
      }
    }
  },
  sampleSet: 'column',
  dataValidator: false,
  advancedParent: 'Column',
  advanced: {
    name: '3D'
  }
};

export default Column3D;
