import { PlanPeriod, SubscriptionPlan, SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanById, getPlanDetailsByNameAndPeriod } from 'pages/SubscriptionPage/utils/plansHelper';
import * as React from 'react';
import CheckoutTile from './CheckoutTile';

type BillingCycleProps = {
  editing: boolean;
  toggleEditing: () => void;
  setPlan: (planCode: number) => void;
  plan: SubscriptionPlan;
  isLoggedIn: boolean;
};

const BillingCycle = (props: BillingCycleProps) => {
  const { plan, isLoggedIn } = props;
  const [billingCycle, setBillingCycle] = React.useState(
    plan.interval_length === 12 ? PlanPeriod.Yearly : PlanPeriod.Monthly
  );
  const isStarterPlan = getPlanById(plan.id) === SubscriptionPlans.Starter;
  const isBusinessPlan = getPlanById(plan.id) === SubscriptionPlans.Business;
  const editing = isLoggedIn ? props.editing : true;
  const isMonthly = billingCycle === PlanPeriod.Monthly;

  const setBillingPlan = (period: PlanPeriod, planCode: number) => {
    setBillingCycle(period);
    props.setPlan(planCode);
  };

  const blockClasses = 'p-4 active:text-ev-navy-blue focus:shadow-light cursor-pointer';

  const getActiveClasses = (condition: boolean) => {
    return condition ? 'bg-ev-grey hover:bg-ev-baby-blue-2 border' : 'border-ev-navy-blue-2 text-ev-navy-blue border-2';
  };

  const checkIcon = () => {
    return (
      <div className="relative">
        <i className={'fa fa-circle-check absolute right-0'} />
      </div>
    );
  };

  return (
    <CheckoutTile
      title="2. Billing cycle"
      completed={true}
      isEditable={isLoggedIn}
      editing={editing}
      toggleEditing={props.toggleEditing}
      submitButtonText={'Choose billing cycle'}
      handleSubmit={isLoggedIn ? props.toggleEditing : undefined}
    >
      <div className={`${editing ? 'block' : 'hidden'}`}>
        <p>Choose how often you would like to be billed.</p>
        <div className="flex w-full rounded gap-4">
          <div
            className={`${getActiveClasses(!isMonthly)} ${blockClasses} rounded flex-1`}
            onClick={() => setBillingPlan(PlanPeriod.Monthly, isStarterPlan ? 9 : isBusinessPlan ? 10 : 21)}
          >
            {isMonthly && checkIcon()}
            <div className="text-lg font-medium mb-2">Monthly</div>
            <div>${getPlanDetailsByNameAndPeriod(getPlanById(plan.id), PlanPeriod.Monthly).price} / billed monthly</div>
          </div>

          <div
            className={`${getActiveClasses(isMonthly)} ${blockClasses} rounded flex-1`}
            onClick={() => setBillingPlan(PlanPeriod.Yearly, isStarterPlan ? 12 : isBusinessPlan ? 13 : 22)}
          >
            {!isMonthly && checkIcon()}
            <div className="flex mb-2">
              <div className="text-lg font-medium">Yearly</div>
              <div className="bg-ev-red text-white font-normal ml-2 p-1 rounded ev-sm-max:hidden">2 months free!</div>
            </div>
            <div>
              ${getPlanDetailsByNameAndPeriod(getPlanById(plan.id), PlanPeriod.Yearly).price.toLocaleString()} / billed
              yearly
            </div>
          </div>
        </div>
      </div>
      <div className={`${editing ? 'hidden' : 'block'} text-base pt-2`}>
        {billingCycle}
        <span className="ml-4">
          ${plan.price.toLocaleString()} every {billingCycle.slice(0, -2).toLowerCase()}
        </span>
      </div>
    </CheckoutTile>
  );
};

export default BillingCycle;
