'use strict';
window.addEventListener('message', function (e) {
  if (e.data.type === 'everviz-dimensions') {
    var UUID_POSITION = 4;
    var iframes = document.getElementsByTagName('iframe');
    for (var i = 0; i < iframes.length; i++) {
      var iframe = iframes.item(i);
      var uuid = iframe.attributes.src.value.split('/')[UUID_POSITION];
      if (uuid === e.data.uuid) {
        iframe.style.height = e.data.height + 'px';
      }
    }
  }
});
