/* eslint-disable react/jsx-no-bind */
import RolesPage from 'pages/RolesPage/RolesPage';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import config from '../config';
import AnalyticsPage from '../pages/AnalyticsPage/containers/AnalyticsPage';
import ApiPage from '../pages/ApiPage/containers/ApiPage';
import CustomiseEditorPage from '../pages/CustomiseEditorPage/containers/CustomiseEditorPage';
import PreviewChartsPage from '../pages/PreviewChartsPage/PreviewCharts';
import SettingsPage from '../pages/SettingsPage/containers/SettingsPage';
import TeamWebhooksPage from '../pages/TeamWebhooksPage/containers/TeamWebhooksPage';
import ThemesPage from '../pages/ThemesPage/ThemesPage';
import { generateMultipleRoutes, generateRoutes } from './routesHelper';
import TeamPage from 'pages/TeamPage/TeamPage';

import ProjectsPage from '../pages/ProjectsPage/containers/ProjectsPage';
import SubscriptionPage from '../pages/SubscriptionPage/SubscriptionPage';
import LayoutEditor from '../pages/LayoutEditorPage/LayoutEditorPage';
import CompanyThemeEditor from '../pages/CompanyThemeEditorPage/ThemeEditor';
import PackagePage from '../pages/PackagesPage/PackagesPage';
import PackageEditor from '../pages/PackageEditorPage/PackageEditor';
import TableEditor from '../pages/TableEditorPage/TableEditor';

export default function ProfileRoutes(props) {
  const { user, teams, team, paths, loading, openNewDivisionModal, setNavActions, toggleMenu, location } = props;

  useEffect(() => {
    if (window.Beacon) window.Beacon('suggest');
  }, [location.pathname]);

  const profilePages = generateMultipleRoutes(
    user,
    teams,
    team,
    toggleMenu,
    loading,
    paths,
    [
      {
        render: (props) => <ProjectsPage deleted={props.match.params.filter === 'deleted'} {...props} />,
        path: paths.profileCharts + '/:filter?'
      },
      {
        render: (props) => <TeamPage openNewDivisionModal={openNewDivisionModal} {...props} />,
        path: paths.profileTeamMembers
      },
      {
        render: (props) => <PackagePage {...props} />,
        path: paths.packages
      },
      {
        render: (props) => <RolesPage {...props} />,
        path: paths.profileTeamGroups
      },
      {
        render: (props) => <ThemesPage deleted={props.match.params.filter === 'deleted'} {...props} />,
        path: paths.profileTeamThemes + '/:filter?'
      },
      {
        render: (props) => <PreviewChartsPage {...props} />,
        path: paths.themePreview
      },
      {
        render: (props) => <SettingsPage {...props} />,
        path: paths.profileSettings
      },
      {
        render: (props) => <ApiPage {...props} />,
        path: paths.profileApi
      },
      {
        render: (props) => <TeamWebhooksPage {...props} />,
        path: paths.profileWebhooks
      },
      {
        render: (props) => <CustomiseEditorPage {...props} />,
        path: paths.profileCustomiseEditor
      },
      {
        render: (props) => <PackageEditor setNavActions={setNavActions} {...props} />,
        path: paths.editPackage
      },
      {
        render: (props) => <LayoutEditor setNavActions={setNavActions} {...props} />,
        path: paths.createLayout
      },
      {
        render: (props) => <LayoutEditor setNavActions={setNavActions} {...props} />,
        path: paths.editLayout
      },
      {
        render: (props) => <TableEditor setNavActions={setNavActions} {...props} />,
        path: paths.createTable
      },
      {
        render: (props) => <CompanyThemeEditor setNavActions={setNavActions} {...props} />,
        path: paths.createCompanyTheme
      },
      {
        render: (props) => <CompanyThemeEditor setNavActions={setNavActions} {...props} />,
        path: paths.editCompanyTheme
      },
      {
        render: (props) => <TableEditor setNavActions={setNavActions} {...props} />,
        path: paths.editTable
      },
      team && (team.in_beta || team.beta_features?.includes(2))
        ? {
            render: (props) => <AnalyticsPage {...props} />,
            path: paths.analytics
          }
        : null
    ],
    true,
    false
  );

  return (
    <>
      {profilePages}

      {config.billing.enabled &&
        generateRoutes(
          user,
          teams,
          team,
          toggleMenu,
          loading,
          [paths.profileSubscription],
          () => <SubscriptionPage />,
          true,
          false,
          paths
        )}

      <Route exact={true} path={paths.frontPage}>
        <Redirect to={{ pathname: paths.profileCharts, search: location.search }} />
      </Route>
    </>
  );
}
