import React from 'react'
import PrimaryButton from './buttons/PrimaryButton'

const ErrorBoundary = ({error, componentStack, resetError}) => {
  return (
    <div className="flex flex-col justify-center items-center h-64">
      <h2 title={`${error.toString()}\n${componentStack}`}>We made a mistake!</h2>
      <PrimaryButton onClick={resetError} text="Take me back"/>
    </div>
  )
}

export default ErrorBoundary