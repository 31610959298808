import { downloadInvoiceAction } from 'pages/SubscriptionPage/actions/plansPage';
import React from 'react';
import TextButton from 'shared/buttons/TextButton';
import store from '../../../redux/store';

type GetAdjustmentsData = {
  team: any;
  adjustments: any[];
};

export const getAdjustments = (data: GetAdjustmentsData) => {
  const team = data.team || {},
    adjustments = data.adjustments || [];

  return adjustments.map((adjustment) => {
    return {
      unitAmount: adjustment.unitAmountInCents / 100,
      discount: adjustment.discountInCents / 100,
      tax: adjustment.discountInCents / 100,
      total: adjustment.totalInCents / 100,
      invoice: (
        <TextButton
          text="Download invoice"
          thin={true}
          onClick={() => {
            store.dispatch(downloadInvoiceAction({ team, invoiceNumber: adjustment.invoiceNumber }));
          }}
        />
      ),
      ...adjustment
    };
  });
};
