import { ProviderTypes } from 'pages/ChartEditorPage/meta/templates/types';
import DefaultLocationMapThemes from 'shared/editor/DefaultLocationMapThemes';
import { defaultThemeMap } from 'shared/meta/chooseTheme';
import { TypeProps } from 'shared/types/chooseTheme';

const defaultThemesMap = {
  highcharts: (type: TypeProps) => defaultThemeMap[type],
  locationMap: () => DefaultLocationMapThemes
};

export const getDefaultThemes = (type: TypeProps, provider: ProviderTypes) => defaultThemesMap[provider](type);
