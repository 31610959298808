import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const DependencyWheel: TemplateProps = {
  title: 'Dependency wheel',
  description: '',
  constructor: '',
  thumbnail: 'dependencywheel.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'dependencywheel'
    },
    plotOptions: {
      series: {
        borderRadius: false
      }
    }
  },
  plugins: ['dependencywheel'],
  advancedParent: 'Dependency wheel',
  sampleSet: 'dependencywheel',
  basic: {
    order: 9,
    thumbnail: 'dependency_wheel.svg',
    icon: 'DependencyWheel'
  },
  dataValidator: false,
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter)
};

export default DependencyWheel;
