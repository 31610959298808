import React from 'react';
import PropTypes from 'prop-types';

class Info extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      hover: false,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    this.setState({
      hover: true,
    });
  }

  onMouseLeave() {
    this.setState({
      hover: false,
    });
  }

  render() {
    const show = this.state.hover;

    return (
      <div className="hc-info">
        <i
          className="hc-info-icon fa fa-fw fa-question-circle-o"
          onMouseLeave={this.onMouseLeave}
          onMouseEnter={this.onMouseEnter}
        />

        {show && <div className="hc-info-popover">{this.props.description}</div>}
      </div>
    );
  }
}

Info.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Info;
