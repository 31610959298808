export default {
  url: 'https://eu1.locationiq.com/v1/search.php',
  api_key: 'pk.3027bc00a750f60c5effceb74ad756e1	',
  version: 'v1',
  latKey: 'lat',
  longKey: 'lon',
  nameKey: 'display_name',
  bbox: 'boundingbox',
  credit: {
    text: 'Search by LocationIQ.com',
    href: 'https://locationiq.com/'
  }
};
