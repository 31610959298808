import React from 'react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import { postTeamMessageReadUsingTeamidAndId } from '../../api/cloudApiGenerated';

class TeamMessage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      messages: props.messages,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ messages: newProps.messages });
  }

  setAsRead(message) {
    postTeamMessageReadUsingTeamidAndId(this.props.team.id, message.id).then(() => {
      this.props.setAsRead(message);
    });
  }

  render() {
    const messages = this.state.messages;
    return (
      <div>
        <div className={'messages-container'}>
          <h2> Messages </h2>
          {!messages || (messages && messages.length === 0 && <div>No Messages</div>)}
          {messages.map((message, i) => {
            const setAsRead = () => {
              this.setAsRead(message);
            };
            return (
              <div key={i} className={'message-container ' + message.type}>
                <div className={'default-icon message-' + message.type} />
                <div className="message-text">
                  <div className={'message-title ' + message.type}> {message.type} </div>
                  <div className="message-body"> {message.message} </div>
                  <div className="message-date"> {message.date} </div>
                </div>
                {!message.read && (
                  <div className="message-btn">
                    <Button buttonClass="btn-primary" buttonText="Mark as Read" onClick={setAsRead} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="close-btn-container">
          <Button buttonClass="btn-primary" buttonText="Close" onClick={this.props.close} />
        </div>
      </div>
    );
  }
}

TeamMessage.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  messages: PropTypes.array,
  team: PropTypes.object,
  close: PropTypes.func,
  setAsRead: PropTypes.func,
};

export default TeamMessage;
