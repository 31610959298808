import { loadCSVAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect, { customSelectColors } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import { getDropdownValue } from '../utils/mapDataHelper';
import { getAllowProps } from '../utils/seriesHelper';
import { getAcceptedValues } from 'pages/ChartEditorPage/utils/chartEditorMapHelper';

const getFirstThreeExamples = (map, key) =>
  (map?.features ?? [])
    .slice(0, 3)
    .map((p) => p?.properties?.[key])
    .filter((d) => d);

const MatchingKeyTab = () => {
  const dispatch = useDispatch();
  const { aggregatedOptions, rawImportedCSV } = useSelector((state) => state.projectConfig);

  const { isMap } = useSelector((state) => state.chartEditorPage);
  const [dropdownValues, setDropdownValues] = useState([
    {
      value: '',
      label: 'None'
    }
  ]);
  const value = getDropdownValue(aggregatedOptions, dropdownValues);
  const { seriesTypes } = getAllowProps(aggregatedOptions, false);
  const isPointMap = isMap && seriesTypes.includes('mappoint');
  const isTilemap = isMap && seriesTypes.includes('tilemap');

  useEffect(() => {
    if (window?.Highcharts?.maps) {
      const mapKey = aggregatedOptions?.chart?.map ?? 'countries/us/us-all';
      if (window?.Highcharts?.maps[mapKey]) {
        const map = window?.Highcharts?.maps[mapKey];
        const firstProperties = map?.features?.[0]?.properties ?? {};
        const acceptedValues = getAcceptedValues(map?.features, mapKey);

        const newDropdownValues = Object.keys(firstProperties)
          .map((key) => {
            if (acceptedValues[key]) {
              const properties = getFirstThreeExamples(map, key);
              return {
                value: key,
                label: `${key.replaceAll('-', ' ')} (${properties.join(', ')})`
              };
            }
          })
          .filter((d) => d);

        newDropdownValues.unshift({
          value: '',
          label: 'None'
        });

        setDropdownValues(newDropdownValues);
      }
    }
  }, [window?.Highcharts?.maps, rawImportedCSV]);

  const handleMatchingKeyChange = (selected) => {
    dispatch(
      loadCSVAction({
        dataType: 'csv',
        csv: rawImportedCSV,
        matchingKey: selected.value,
        isMap
      })
    );
  };

  return (
    <>
      {!isTilemap && !isPointMap && rawImportedCSV && (
        <CustomSelect
          label="Matching key"
          containerClasses="mb-2"
          options={dropdownValues}
          value={value}
          onChange={handleMatchingKeyChange}
          styles={editorStyles}
          backgroundColor={customSelectColors.white}
        />
      )}
    </>
  );
};

export default MatchingKeyTab;
