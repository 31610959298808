import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from 'redux/store';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import Tooltip from 'shared/tooltip/Tooltip';
import SlashedEyeIcon from 'static/icons/eye-slash-solid.svg';
import { setAction, updateCustomizedAction } from '../../../pages/ChartEditorPage/actions/chartEditor';

export const hideElementHideButton = () => {
  store.dispatch(
    setAction({
      hideElementButtonOptions: null
    })
  );
};

const HideElementButton = () => {
  const dispatch = useDispatch();
  const { hideElementButtonOptions: options } = useSelector<any, any>((state: RootState) => state.chartEditorPage);

  const hiddenPosition = { display: 'none', left: '', top: '' };
  const [position, setPosition] = useState(hiddenPosition);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [elementName, setElementName] = useState();

  useEffect(() => {
    setShouldDisplay(isButtonHovered || !!options);
    if (options) {
      let offsetX = 0,
        offsetY = 0;
      const marginTop = 8;
      const { x, y, height } = options.box.getBoundingClientRect();

      const navBar = document.querySelector('.profile-nav-bar');
      const topBar = document.querySelector('.navbar');
      if (navBar) offsetX = navBar.getBoundingClientRect().width;
      if (topBar) offsetY = topBar.getBoundingClientRect().height;

      setElementName(options.name);
      setPosition({
        top: y + height + marginTop - offsetY + 'px',
        left: x - offsetX + 'px',
        display: 'block'
      });
    }
  }, [options]);

  const deleteElement = () => {
    const disable = options?.popup?.disable?.option;
    if (disable) {
      dispatch(updateCustomizedAction({ optionProps: { id: disable }, val: false }));
      setPosition(hiddenPosition);
    }
  };

  const onMouseLeave = () => {
    setShouldDisplay(false);
    setIsButtonHovered(false);
  };

  return (
    <>
      {shouldDisplay && (
        <Tooltip tippyProps={{ content: `Hide the ${elementName}.`, placement: 'auto' }}>
          <div
            style={position}
            className="absolute z-10"
            id="charts-menu-hide-button"
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={onMouseLeave}
          >
            <SvgIconButton
              Icon={SlashedEyeIcon}
              buttonColor={ButtonColor.Grey}
              width={16}
              height={16}
              onClick={deleteElement}
              buttonClasses="w-7 h-7 content-centered rounded"
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default HideElementButton;
