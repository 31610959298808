import { TemplateProps } from '../../types';

const Spiral: TemplateProps = {
  title: 'Spiral',
  description: '',
  constructor: '',
  thumbnail: 'spiral.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'packedbubble'
    },
    legend: {
      itemMarginBottom: 4,
      margin: 0,
      padding: 0
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        },
        lineWidth: 0
      },
      packedbubble: {
        useSimulation: false
      }
    }
  },
  parseData: 'packedbubble',
  plugins: ['packedbubble'],
  advancedParent: 'PackedBubble',
  sampleSet: 'packedbubble',
  dataValidator: false
};

export default Spiral;
