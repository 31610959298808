import paths from '../../paths';

import { cancelTextMap } from 'shared/utils/paidPlanHelper';
import apiPage from '../../pages/ApiPage/reducers/init';
import chartEditorPage from '../../pages/ChartEditorPage/reducers/init';
import companyThemeEditorPage from '../../pages/CompanyThemeEditorPage/reducers/init';
import customiseEditorPage from '../../pages/CustomiseEditorPage/reducers/init';
import projectConfig from '../../pages/Editor/reducers/init';
import layoutEditorPage from '../../pages/LayoutEditorPage/reducers/init';
import projectsPage from '../../pages/ProjectsPage/reducers/init';
import rolesPage from '../../pages/RolesPage/reducers/init';
import settingsPage from '../../pages/SettingsPage/reducers/init';
import ShowChartPage from '../../pages/ShowChartPage/reducers/init';
import plansPage from '../../pages/SubscriptionPage/reducers/init';
import tableEditorPage from '../../pages/TableEditorPage/reducers/init';
import teamInvitationsPage from '../../pages/TeamPage/reducers/teamInvitationsInit';
import teamMembersPage from '../../pages/TeamPage/reducers/teamMembersInit';
import teamWebhooksPage from '../../pages/TeamWebhooksPage/reducers/init';
import themesPage from '../../pages/ThemesPage/reducers/init';
import redeemInvitePage from '../../pages/RedeemInvitePage/reducers/init';
import packagesPage from '../../pages/PackagesPage/reducers/init';

export default {
  profile: {
    groups: [],
    chartsCreated: 0,
    recentExports: [],
    previousExports: []
  },
  app: {
    paths: paths,
    error: '',
    loading: true,
    paidPlanType: 'starter',
    isPaidPlanModalOpen: false,
    isPremiumFeatureModalOpen: false,
    cancelText: cancelTextMap.def,
    featureText: 'feature'
  },
  projectsPage,
  teamMembersPage,
  teamInvitationsPage,
  rolesPage,
  themesPage,
  settingsPage,
  apiPage,
  teamWebhooksPage,
  customiseEditorPage,
  plansPage,
  ShowChartPage,
  projectConfig,
  chartEditorPage,
  tableEditorPage,
  layoutEditorPage,
  companyThemeEditorPage,
  redeemInvitePage,
  packagesPage
};
