import { getMapKey } from 'pages/ChartEditorPage/utils/chartEditorMapHelper';

export const formatGeoJSONMapData = (data: any) => {
  const tempData = JSON.parse(data);
  const tempDataFeatures = tempData?.features;

  if (tempDataFeatures?.length > 0) {
    tempDataFeatures.forEach((feature: any) => {
      const tempFeatureProperties = feature?.properties ?? [];

      if (typeof tempFeatureProperties === 'object') {
        Object.keys(tempFeatureProperties).forEach((propertyKey) => {
          const propertyValue = tempFeatureProperties[propertyKey];

          if (typeof propertyValue !== 'string' && typeof propertyValue !== 'number') {
            delete tempFeatureProperties[propertyKey];
          }
        });
      }
    });
  }

  return JSON.stringify(tempData);
};

export const getDropdownValue = (aggregatedOptions: any, dropdownOptions: any) => {
  const code = getMapKey(aggregatedOptions);
  const option = dropdownOptions.filter((option: { value: string }) => option.value === code);
  return (
    option ?? {
      value: '',
      label: 'None'
    }
  );
};
