import React from 'react';

export default function TableIcon(props) {
  return (
    <svg viewBox="0 0 80 48" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="10" rx="5" className="group-hover:fill-[#fff]" fill="#AEC8FA" />
      <rect y="12.5" width="25" height="10" className="group-hover:fill-[#fff]" rx="5" fill="#AEC8FA" />
      <rect y="25" width="25" height="10" rx="5" className="group-active:fill-[#AEC8FA]" fill="#28277E" />
      <rect y="37.5" width="25" height="10" rx="5" className="group-hover:fill-[#fff]" fill="#AEC8FA" />
      <rect x="27.5" width="25" height="10" rx="5" className="group-active:fill-[#AEC8FA]" fill="#28277E" />
      <rect x="27.5" y="12.5" width="25" height="10" className="group-hover:fill-[#fff]" rx="5" fill="#AEC8FA" />
      <rect x="27.5" y="25" width="25" height="10" className="group-hover:fill-[#fff]" rx="5" fill="#AEC8FA" />
      <rect x="27.5" y="37.5" width="25" height="10" className="group-hover:fill-[#fff]" rx="5" fill="#AEC8FA" />
      <rect x="55" width="25" height="10" rx="5" className="group-hover:fill-[#fff]" fill="#AEC8FA" />
      <rect x="55" y="12.5" width="25" height="10" rx="5" className="group-active:fill-[#AEC8FA]" fill="#28277E" />
      <rect x="55" y="25" width="25" height="10" className="group-hover:fill-[#fff]" rx="5" fill="#AEC8FA" />
      <rect x="55" y="37.5" width="25" height="10" rx="5" className="group-active:fill-[#AEC8FA]" fill="#28277E" />
    </svg>
  );
}
