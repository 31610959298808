import { TemplateProps } from '../../types';

const Area: TemplateProps = {
  title: 'Area',
  description: '',
  constructor: '',
  thumbnail: 'ecexev.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'area',
  advancedParent: 'Area',
  dataValidator: false,
  basic: {
    order: 2,
    thumbnail: 'area_graph.svg',
    icon: 'Area'
  },
  advanced: {
    name: 'Standard'
  },
  parent: 'Area',
  thumbnail_url: 'https://app.everviz.com/static/thumbnails/ecexev.svg',
  name: 'Area'
};

export default Area;
