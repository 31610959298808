/*

    en Language Pack for the Highcharts Editor

    This file was generated by tools/gen.localization.js from
    en.json, Thu Mar 15 2018 10:04:15 GMT+0100 (CET)

*/

//Install "en" translations
//highed.installLanguage({
export default {
  language: 'en',
  entries: {
    'option.text.title': 'Title',
    'option.text.subtitle': 'Subtitle',
    'option.text.caption': 'Caption',
    'option.text.canvas': 'Canvas',
    'option.text.table': 'Table specific',
    'option.text.header': 'Header',
    'option.text.rows': 'Rows',
    'option.text.columns': 'Columns',
    'option.text.chart': 'Chart in table',
    'option.text.logo': 'Logo',
    'option.text.title.text': 'Title',
    'option.text.title.style.font': 'Text font',
    'option.text.title.font.style': 'Text style',
    'option.text.title.style.textAlign': 'Position',
    'option.text.title.backgroundColor': 'Background color',
    'option.text.title.enabled': 'Enabled',
    'option.text.subtitle.text': 'Subtitle',
    'option.text.subtitle.style.font': 'Text font',
    'option.text.subtitle.font.style': 'Text style',
    'option.text.subtitle.style.textAlign': 'Position',
    'option.text.subtitle.style.backgroundColor': 'Background color',
    'option.text.subtitle.enabled': 'Enabled',
    'option.text.caption.text': 'Caption',
    'option.text.caption.style.font': 'Text font',
    'option.text.caption.font.style': 'Text style',
    'option.text.caption.style.textAlign': 'Position',
    'option.text.caption.style.backgroundColor': 'Background color',
    'option.text.caption.enabled': 'Enabled',
    'option.text.canvas.style.padding': 'Padding (px)',
    'option.text.canvas.style.backgroundColor': 'Background color',
    'option.text.table.searchable.enabled': 'Search field',
    'table.searchable.placeholderText': 'Placeholder text',
    'table.searchable.labelText': 'Search in table',
    'table.searchable.noDataText': 'No data text',
    'table.searchable.style.borderColor': 'Color',
    'option.text.table.sortable': 'Sortable',
    'option.text.table.cards': 'Show as card view on mobile',
    'option.text.table.pagination.enabled': 'Pagination',
    'option.text.table.pagination.resultsPerPage': 'Results per page',
    'option.text.table.pagination.backButtonStyle.backgroundColor': 'Back button color',
    'option.text.table.pagination.backButtonStyle.color': 'Back text color',
    'option.text.table.pagination.frontButtonStyle.backgroundColor': 'Forward button color',
    'option.text.table.pagination.frontButtonStyle.color': 'Forward text color',
    'option.text.table.pagination.text.style.color': 'Text color',
    'option.text.table.style.font': 'Text font',
    'option.text.table.font.style': 'Text style',
    'option.text.table.style.textAlign': 'Position',
    'option.text.table.style.backgroundColor': 'Background color',
    'option.text.table.style.borderWidth': 'Border width',
    'option.text.table.style.borderColor': 'Border color',
    'option.text.table.extra.style.backgroundColor': 'Extra background color',
    'option.text.table.extra.style.borderWidth': 'Extra border width',
    'option.text.table.extra.style.borderColor': 'Extra border color',
    'option.text.table.extra.position': 'Extra position',
    'option.text.header.style.font': 'Text font',
    'option.text.header.font.style': 'Text style',
    'option.text.header.style.textAlign': 'Position',
    'option.text.header.style.backgroundColor': 'Background color',
    'option.text.header.headerRows': 'Header rows',
    'option.text.rows.stripes': 'Stripes',
    'option.text.rows.stripesColor': 'Stripes color',
    'option.text.rows.textColor': 'Text color',
    'option.text.columns.selector': 'Column selector',
    'option.text.chart.columns': 'Chart selector',
    'option.text.logo.url': 'Image',
    'option.text.logo.position': 'Position',
    'option.text.logo.width': 'Width (%)',
    'option.text.logo.minWidth': 'Min width (px)',
    'option.text.logo.style.padding': 'Padding (px)',

    'option.text.export': 'Context menu options',
    'option.text.exporting.enabled': 'Visibility',
    'option.text.exporting.buttons.contextButton.text': 'Button text',
    'option.text.exporting.buttons.contextButton.symbol': 'Button icon',
    'option.text.exporting.localization': 'Text descriptions',
    'option.text.exporting.style.backgroundColor': 'Background color',
    'option.text.exporting.style.color': 'Text color',
    'option.text.exporting.lang.contextButtonTitle': 'Context button tooltip',
    'option.text.exporting.lang.downloadPNG': 'Download PNG image',
    'option.text.exporting.lang.downloadJPEG': 'Download JPEG image',
    'option.text.exporting.lang.downloadPDF': 'Download PDF document',
    'option.text.exporting.lang.downloadCSV': 'Download CSV',
    'option.text.exporting.lang.downloadXLS': 'Download XLS',
    'option.text.exporting.sourceWidth': 'Export width (px)'
  }
};

//});
