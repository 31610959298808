import actionTypes from '../../../redux/actions/action-types';

export const getUserEditorConfigAction = (data) => ({
  type: actionTypes.customiseEditorPage.getUserEditorConfig,
  data
});

export const getEditorConfigAction = (data) => ({
  type: actionTypes.customiseEditorPage.getEditorConfig,
  data
});

export const setAction = (data) => ({
  type: actionTypes.customiseEditorPage.set,
  data
});

export const setUserEditorConfigAction = (data) => ({
  type: actionTypes.customiseEditorPage.setUserEditorConfig,
  data
});

export const getFontsAction = (data) => ({
  type: actionTypes.customiseEditorPage.getFonts,
  data
});
export const deleteFontAction = (data) => ({
  type: actionTypes.customiseEditorPage.deleteFont,
  data
});

export const getLogosAction = (data) => ({
  type: actionTypes.customiseEditorPage.getLogos,
  data
});

export const deleteLogoAction = (data) => ({
  type: actionTypes.customiseEditorPage.deleteLogo,
  data
});

export const addCustomFontAction = (data) => ({
  type: actionTypes.customiseEditorPage.addCustomFont,
  data
});

export const editCustomFontAction = (data) => ({
  type: actionTypes.customiseEditorPage.editCustomFont,
  data
});
