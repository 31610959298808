import React from 'react';
import ReactModal from 'react-modal';

export default function Modal(props) {
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.toggle}
      shouldCloseOnOverlayClick={true}
      appElement={document.querySelector('.page-container')}
      overlayClassName={'fixed inset-0 w-screen h-screen'}
      className={'h-full pointer-events-none flex items-center justify-center'}
      closeTimeoutMS={150}
      id={props.id}
    >
      <div
        className={`pointer-events-auto z-10 mx-10 pb-6 w-8/12 max-w-md rounded-3xl shadow-xl
           bg-ev-navy-blue-2 text-white
        `}
      >
        <div className="rounded-t-3xl pt-10 px-10 items-center text-white text-xl flex justify-between">
          <span className="font-bold text-[1.375rem] pt-1">{props.title}</span>
        </div>
        <div className={`modal__container p-6 px-10 max-h-[70vh] ${props.allowOverflow ? 'overflow-y-auto' : ''}`}>
          {props.children}
        </div>
      </div>
    </ReactModal>
  );
}
