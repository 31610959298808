export default (type, data, config, headers, responsive) => {
  return {
    chart: {
      type: 'bar',
      width: responsive ? null : 250,
    },
    series: data.map((d, i) => {
      return {
        data: [parseFloat(typeof d === 'string' ? d.replace(/ /g, '').replace(/,/g, '.') : d)],
        pointStart: 1,
        index: data.length - i,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
        name: headers[i],
      };
    }),
    colors: config.chartColors || [
      '#235A61',
      '#DD495E',
      '#2A2383',
      '#F2C60E',
      '#24CBE5',
      '#64E572',
      '#FF9655',
      '#FFF263',
      '#6AF9C4',
    ],
    plotOptions: {
      bar: {
        stacking: 'normal',
        groupPadding: -1,
        negativeColor: config.negativeChartColor || '#DD495E',
      },
    },
    tooltip: {
      enabled: config.showTooltip ? true : false,
      headerFormat: '<b>' + config.columnName + '</b><br/>',
      pointFormat: '<b>{series.name}:</b> {point.y}<br/>',
    },
  };
};
