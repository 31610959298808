import { TemplateTypeProps } from '../../types';
import Stacked3D from './Stacked3D';
import StackedColumn from './StackedColumn';
import StackedColumnPercent from './StackedColumnPercent';
import StackedColumnWithLabels from './StackedColumnWithLabels';
import StackedPercentWithLabels from './StackedPercentWithLabels';

const StackedColumnTemplate: TemplateTypeProps = {
  description: '',
  icon: '',
  sampleData: [],
  templates: {
    'Stacked 3D': Stacked3D,
    'Stacked column percent': StackedColumnPercent,
    'Stacked percent with labels': StackedPercentWithLabels,
    'Stacked column': StackedColumn,
    'Stacked column w/ labels': StackedColumnWithLabels
  }
};

export default StackedColumnTemplate;
