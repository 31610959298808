import { formatDistanceToNowStrict } from 'date-fns';
import React from 'react';
import { useDispatch } from 'react-redux';
import GifFileIcon from 'static/images/exports/format_gif.svg';
import PngFileIcon from 'static/images/exports/format_png.svg';
import Mp4FileIcon from 'static/images/exports/format_mp4.svg';
import SvgFileIcon from 'static/images/exports/format_svg.svg';
import JpgFileIcon from 'static/images/exports/format_jpg.svg';
import MovFileIcon from 'static/images/exports/format_mov.svg';
import PdfFileIcon from 'static/images/exports/format_pdf.svg';
import WebpFileIcon from 'static/images/exports/format_webp.svg';
import Cross from 'static/images/exports/cross_icon.svg';
import { deleteRecentExportAction } from 'redux/actions/profile';
import LoaderIcon from '../loader/LoaderIcon';

type ExportProps = {
  fileName: string;
  fileType: string;
  fileSize: string;
  exportStatus?: string;
  downloadLink: string;
  lastExportTime: string;
  exportId: string;
  teamId: string;
  exportedBy: string;
};

const iconMapping: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  JPG: JpgFileIcon,
  JPEG: JpgFileIcon,
  PNG: PngFileIcon,
  SVG: SvgFileIcon,
  MP4: Mp4FileIcon,
  MOV: MovFileIcon,
  GIF: GifFileIcon,
  PDF: PdfFileIcon,
  WEBP: WebpFileIcon
};

const RecentExport = ({
  fileName,
  fileType,
  exportStatus,
  downloadLink,
  lastExportTime,
  exportId,
  teamId,
  exportedBy,
  fileSize
}: ExportProps) => {
  const FileIcon = iconMapping[fileType.toUpperCase()] || SvgFileIcon;
  const dispatch = useDispatch();

  const formatFileSize = (size: number): string => {
    if (size < 1024) return `${size} B`;
    const kbSize = size / 1024;
    if (kbSize < 1024) return `${Math.round(kbSize)} KB`;
    const mbSize = kbSize / 1024;
    if (mbSize < 1024) return `${Math.round(mbSize)} MB`;
    const gbSize = mbSize / 1024;
    return `${Math.round(gbSize)} GB`;
  };

  const fileSizeFormatted = fileSize ? formatFileSize(parseInt(fileSize, 10)) : '';

  const handleDownload = () => {
    if (exportStatus === 'PENDING' || exportStatus === 'FAILED') {
      return;
    }

    fetch(downloadLink)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  const deleteExport = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(deleteRecentExportAction({ teamId, exportId }));
  };

  const timeStatusText =
    exportStatus === 'PENDING'
      ? 'Rendering'
      : formatDistanceToNowStrict(new Date(lastExportTime)).includes('seconds')
      ? 'just now'
      : `${formatDistanceToNowStrict(new Date(lastExportTime))} ago`;

  return (
    <div
      className="w-full p-2 focus:outline-none flex rounded justify-between hover:bg-ev-baby-blue-2 transition-colors active:bg-ev-navy-blue-2 cursor-pointer"
      onClick={handleDownload}
    >
      <FileIcon />
      <div className="w-48 flex flex-col mt-0 pt-0">
        <p className="m-0 p-0 font-Gordita font-medium text-ev-navy-blue text-sm text-left">{fileName}</p>
        <p className="m-0 p-0 mt-1 text-ev-grey-6 text-sm text-left">
          {exportStatus === 'PENDING' ? timeStatusText : `${fileSizeFormatted} | ${timeStatusText}`}
        </p>
        <p className="m-0 p-0 mt-1 text-ev-grey-6 text-sm text-left">exported by {exportedBy}</p>
      </div>
      <div className="flex">
        {exportStatus === 'PENDING' ? (
          <LoaderIcon loading={true} iconClass="flex items-center fa-lg" />
        ) : exportStatus === 'FAILED' ? (
          <i className="text-ev-red fas fa-circle-exclamation fa-lg flex items-center" />
        ) : (
          <div className="focus:outline-none w-5 h-5 mt-1">
            <i className="text-ev-navy-blue-2 group-hover:text-white fa fa-download no-margin text-2xl z-30" />
          </div>
        )}
        <button
          className="focus:outline-none w-4 h-4 ml-2"
          onClick={(event) => deleteExport(event)}
          title="Delete exported file"
        >
          <Cross />
        </button>
      </div>
    </div>
  );
};

export default RecentExport;
