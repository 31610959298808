import React from 'react';
import { SectionRoot } from '../../../shared/editor/generic/SectionRoot';
import { AppearanceSection } from '../../../shared/editor/generic/locationMap/AppearanceSection';
import { InteractivitySection } from '../../../shared/editor/generic/locationMap/InteractivitySection';
import { LayersSection } from '../../../shared/editor/generic/locationMap/LayersSection';
import { MarkersSection } from '../../../shared/editor/generic/locationMap/MarkersSection';
import { TextSection } from '../../../shared/editor/generic/locationMap/TextSection';

export const LocationMapCustomize = () => {
  return (
    <SectionRoot>
      <TextSection openedByDefault={true} />
      <MarkersSection />
      <InteractivitySection />
      <AppearanceSection />
      <LayersSection />
    </SectionRoot>
  );
};
