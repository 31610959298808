import { SubscriptionPlans } from '../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../types';

const TileMapCircle: TemplateProps = {
  title: 'Tilemap circle',
  description: [
    'Tilemaps are maps where each area is represented by tiles of equal shape. You can choose from circle or honeycomb which uses hexagonal tiles.'
  ],
  constructor: 'Chart',
  thumbnail: 'mapcircle.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter),
  config: {
    chart: {
      type: 'tilemap',
      inverted: true
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },
    colorAxis: {
      dataClasses: [
        {
          from: 0,
          to: 1000000,
          color: '#e8f5e9',
          name: 'Weak'
        },
        {
          from: 1000000,
          to: 5000000,
          color: '#81c784',
          name: 'Average'
        },
        {
          from: 5000000,
          to: 20000000,
          color: '#43a047',
          name: 'Strong'
        },
        {
          from: 20000000,
          color: '#1b5e20',
          name: 'Stellar'
        }
      ]
    },
    tooltip: {
      headerFormat: '',
      pointFormat: 'The real GDP growth of <b>{point.name}</b> is <b>{point.value}</b> %'
    },
    plotOptions: {
      series: {
        tileShape: 'circle',
        lineWidth: 0,
        borderRadius: 0,
        dataLabels: {
          enabled: true,
          format: '{point.hc-a2}',
          color: '#000000',
          style: {
            textOutline: false
          }
        }
      }
    }
  },
  sampleSet: 'maptile',
  dataValidator: false,
  defaultDataType: 'tilemapCircle',
  isMap: true,
  advancedParent: 'Tilemap circle',
  basic: {
    order: 4,
    thumbnail: 'tile_map.svg',
    icon: 'TilemapCircle'
  }
};

export default TileMapCircle;
