/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import FontStyler from './FontStyler';
import FontPickerWidget from './FontPickerWidget';
import { isObject } from 'lodash';

export default (props) => {
  const { option, value } = props;
  option.custom = {
    fields: {}
  };

  if (option && isObject(option.default)) {
    ['fontFamily', 'fontSize'].forEach(function (styleId) {
      const hasStyle = styleId in (option.default ?? {});

      if (hasStyle) {
        option.custom = {
          fields: {
            [styleId]: 1
          }
        };
      }
    });
  }

  if (!option.custom.fields.length) {
    option.custom.fields = {
      bold: 1,
      italic: 1,
      color: 1,
      fontSize: 1
    };
  }

  return (
    <div className={'w-full flex flex-col gap-y-2'}>
      <div className="w-full">
        <FontPickerWidget
          option={props.option}
          aggregatedOptions={props.aggregatedOptions}
          onChange={props.onChange}
          label={'Text Font'}
          inputType={'fontpicker'}
        />
      </div>
      <div className="w-full">
        <FontStyler
          option={option}
          value={value}
          aggregatedOptions={props.aggregatedOptions}
          onChange={props.onChange}
          label={'Text Style'}
        />
      </div>
    </div>
  );
};
