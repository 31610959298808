import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'shared/Accordion';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import TextButton from 'shared/buttons/TextButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import AddIcon from 'static/icons/add.svg';
import BinIcon from 'static/icons/bin.svg';
import { getElementPosition, getElementSize } from '../../editor/core/highed.dom';
import NumberInputWidget from './NumberInputWidget';
import useToggle from 'shared/utils/useToggle';
import ColorPickerModal from 'shared/modal/ColorPickerModal';

let state = {
  moving: false,
  stopIndex: false,
  containerProps: {}
};

const setState = (props) => {
  state = {
    ...state,
    ...props
  };
};

const optionClassName = {
  className: 'bg-white'
};

export default (props) => {
  const ref = useRef(null);
  const [showColorPickerModal, toggleColorPickerModal] = useToggle();
  const [position, setPosition] = useState({ xPos: 0, yPos: 0 });
  const [colorStopIndex, setColorStopIndex] = useState();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLoaded] = useState(false);

  useEffect(() => {
    const container = ref.current;
    setState({
      containerProps: {
        size: getElementSize(container, true),
        position: getElementPosition(container, true)
      }
    });
    setLoaded(true);
  }, []);

  const getTickPosition = (x) => {
    const { containerProps } = state;
    if (containerProps.size) {
      return containerProps.size.w * x + containerProps.position.x - 4 - containerProps.position.x + 'px';
    }
    return 0;
  };

  const preventDefault = (e) => {
    e.cancelBubble = true;
    e.preventDefault();
    e.stopPropagation();
  };

  const deleteStop = (e, stopIndex) => {
    let { value } = props;

    value.splice(stopIndex, 1);
    props.onChange({ val: value });

    preventDefault(e);
    return false;
  };

  const addStop = (e) => {
    if (!e.currentTarget?.className?.includes('highed-field-color-markers')) {
      return;
    }

    let { value } = props;
    const { containerProps } = state;

    const containerSize = containerProps.size;
    value.push([(e.pageX - containerProps.position.x) / containerSize.w, '#000']);
    value = value.sort((a, b) => a[0] - b[0]);

    props.onChange({ val: value });
  };

  const clicked = () => {
    const { startPos, endPos } = state;
    return startPos.x === endPos.x && startPos.y === endPos.y;
  };

  const onClickStop = (e, stopIndex) => {
    preventDefault(e);
    if (clicked()) {
      setColorStopIndex(stopIndex);
      setPosition({ xPos: e.clientX, yPos: e.pageY });
      toggleColorPickerModal(true);
    }

    return false;
  };

  const onColorChange = (color) => {
    let { value } = props;
    let val = cloneDeep(value);
    val[colorStopIndex][1] = color;
    props.onChange({ val });
  };

  const clamp = (num, min, max) => {
    return Math.min(Math.max(num, min), max);
  };

  const onMouseOver = (e) => {
    const { moving, stopIndex } = state;

    if (!moving) return;

    const { containerProps } = state;

    const containerSize = containerProps.size;
    let { value } = props;
    let val = cloneDeep(value);
    val[stopIndex][0] = clamp((e.pageX - containerProps.position.x) / containerSize.w, 0, 1);

    props.onChange({ val });
  };

  const onMouseUp = (e) => {
    if (state.moving) {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseOver);
      setState({
        moving: false,
        endPos: {
          x: e.clientX,
          y: e.clientY
        }
      });
    }
  };

  const onMouseDown = (e, stopIndex) => {
    if (state.moving) return;

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseOver);

    setState({
      moving: true,
      startPos: {
        x: e.clientX,
        y: e.clientY
      },
      stopIndex
    });
  };

  const convertToPercent = (number) => {
    return (number * 100).toFixed(0);
  };

  const onChangeStop = (index, newValue) => {
    const { value } = props;
    const clonedValue = cloneDeep(value);
    clonedValue[index][0] = newValue.val / 100;
    props.onChange({ val: clonedValue });
  };

  const addEmptyStop = () => {
    let { value } = props;
    value.push([1, '#000']);
    value = value.sort((a, b) => a[0] - b[0]);
    props.onChange({ val: value });
  };

  const { value } = props;
  let gradientStyle = 'linear-gradient(to right';

  (value || []).forEach(function (stop) {
    gradientStyle += ',' + stop[1] + (stop[0] ? ' ' + stop[0] * 100 + '%' : '');
  });

  gradientStyle += ')';

  return (
    <Accordion
      headerText="Color stops"
      smallHeaderText={true}
      selectedChildren={(value || []).length}
      useInternalSelect={true}
      accordionClasses={props.className ?? 'w-full bg-white'}
    >
      <div className="highed-field-multiple-colorpicker pt-4" id="colorAxis.stops">
        <div
          ref={ref}
          className="highed-field-color-gradient h-[25px] rounded border-white border"
          style={{
            background: gradientStyle
          }}
        />
        <div className="highed-field-color-markers relative top-[-30px] cursor-copy h-8" onClick={addStop}>
          {(value || []).map((stop, index) => {
            const xPos = getTickPosition(stop[0]);

            return (
              <div
                key={`stops_${index}`}
                onContextMenu={(e) => deleteStop(e, index)}
                onClick={(e) => onClickStop(e, index)}
                onMouseDown={(e) => onMouseDown(e, index)}
                className="highed-field-color-marker border border-white inline-block h-[33px] absolute w-[8px] rounded-[14px] top-px cursor-move"
                style={{ '--background': stop[1], left: xPos }}
              >
                <div className="w-[7px] height-[29px] rounded-[7px]" />
              </div>
            );
          })}
        </div>
      </div>

      {(value || []).map((stop, index) => {
        return (
          <div className="flex" key={`stops_section_${index}`}>
            <NumberInputWidget
              value={convertToPercent(stop[0])}
              label={`Stop ${index + 1}`}
              onChange={(val) => onChangeStop(index, val)}
              className="bg-ev-grey h-10"
              option={optionClassName}
            />
            <SvgIconButton
              width={20}
              height={20}
              onClick={(e) => deleteStop(e, index)}
              buttonClasses="p-2 rounded ml-1"
              buttonColor={ButtonColor.Grey}
              Icon={BinIcon}
            />
          </div>
        );
      })}

      <div className="flex items-center justify-end py-2">
        <TextButton text="Add color stop" onClick={addEmptyStop} className="text-sm" />
        <SvgIconButton
          width={12}
          height={12}
          onClick={addEmptyStop}
          buttonClasses="ml-2 p-2 rounded-full"
          Icon={AddIcon}
          buttonColor={ButtonColor.NavyBlue}
        />
      </div>

      {showColorPickerModal && (
        <ColorPickerModal
          value={value[colorStopIndex][1]}
          isOpen={showColorPickerModal}
          onClose={toggleColorPickerModal}
          onChange={onColorChange}
          showGradientColor={false}
          colors={[]}
          position={position}
        />
      )}
    </Accordion>
  );
};
