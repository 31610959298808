import { isArray } from 'lodash';

export function deleteAllProps(element) {
  delete element.value;
  delete element.from;
  delete element.to;
}

export function setPlotBandDefault(element, axis) {
  const props = getPlotBandDefault(axis);
  element.from = props.min;
  element.to = props.min + props.tenPercent;
}

export function setPlotLineDefault(element, axis) {
  element.value = axis.dataMin;
}

export function updateAnnotation(currentAnnotation, config, type) {
  if (type === 'line') {
    currentAnnotation.shapes[0].update(config);
    if (!currentAnnotation.userOptions.typeOptions.line) {
      currentAnnotation.userOptions.typeOptions.line = {};
    }
    currentAnnotation.userOptions.typeOptions.line.stroke = config.stroke;
    currentAnnotation.userOptions.typeOptions.line.strokeWidth = config.strokeWidth;
  } else if (type === 'verticalCounter') {
    currentAnnotation.shapes[0].update(config);
    currentAnnotation.labels[0].update(config);

    if (!currentAnnotation.userOptions.typeOptions.label) {
      currentAnnotation.userOptions.typeOptions.label = {};
    }
    if (!currentAnnotation.userOptions.typeOptions.connector) {
      currentAnnotation.userOptions.typeOptions.connector = {};
    }

    currentAnnotation.userOptions.typeOptions.label.text = config.text;
    currentAnnotation.userOptions.typeOptions.connector.stroke = config.stroke;
    currentAnnotation.userOptions.typeOptions.connector.fill = config.stroke;
    currentAnnotation.userOptions.typeOptions.connector.strokeWidth = config.strokeWidth;
  } else {
    currentAnnotation.update(config);
    currentAnnotation.labelsGroup.uniqueId = config.labels[0].uniqueId;
    currentAnnotation.redraw();
  }
}

export function getPlotBandDefault(axis) {
  const min = axis.dataMin;
  const max = axis.dataMax;
  const tenPercent = (Math.abs(max - min) / 100) * 10;
  return {
    min,
    max,
    tenPercent
  };
}

export function getHighlightValues(chart) {
  let vals = [];
  if (chart.xAxis) {
    vals.push(...((isArray(chart.xAxis) ? chart.xAxis[0].plotLinesAndBands : chart.xAxis.plotLinesAndBands) || []));
  }
  if (chart.yAxis) {
    vals.push(...((isArray(chart.yAxis) ? chart.yAxis[0].plotLinesAndBands : chart.yAxis.plotLinesAndBands) || []));
  }
  return vals.sort((a, b) => a.id - b.id);
}
