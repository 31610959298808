import { openPaidPlanModalAction } from 'redux/actions/app';
import store from 'redux/store';

export const cancelTextMap = {
  def: 'No thanks',
  createChart: 'Try out chart for free',
  previewChart: 'Preview chart'
};

export const showPaidPlanModal = (plan: string, cancelMsg?: string, featureText?: string) =>
  store.dispatch(openPaidPlanModalAction({ paidPlanType: plan, cancelText: cancelMsg, featureText }));
