import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { TableEditAction as TableEditActionType } from 'shared/types/tableModels';
import SettingsIcon from 'static/icons/settings.svg';

type TableEditActionProps = {
  actionsList: TableEditActionType[];
  rowData: any;
};

const TableEditAction = ({ actionsList, rowData }: TableEditActionProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleTippyClose = () => {
    setIsVisible(false);
  };

  const handleOnClick = (action: (rowData: any) => void) => {
    action(rowData);
    handleTippyClose();
  };

  return (
    <td className="relative align-top h-14 w-14 p-4 before:content-[''] before:absolute before:top-1 before:left-0 before:h-12 before:w-px ev-md:before:bg-ev-grey-5 ev-md:align-middle">
      <Tippy
        animation="none"
        duration={[0, 0]}
        arrow={false}
        placement="bottom"
        offset={[-90, 0]}
        theme="default"
        visible={isVisible}
        interactive={true}
        onClickOutside={handleTippyClose}
        content={
          <div className="z-100 w-56 p-0.5 flex flex-col gap-x-0.5 rounded bg-white shadow-ev-standard">
            {actionsList.map(({ text, disabled, disableId, action }) => (
              <div
                key={text}
                onClick={() => handleOnClick(action)}
                className={`cursor-pointer h-10 w-full px-4 flex items-center rounded text-ev-navy-blue-2 text-base shadow-ev-standard hover:bg-ev-baby-blue-2 active:bg-ev-navy-blue-2 active:text-white focus:outline focus:outline-1 focus:outline-ev-dark-purple outline-offset-[-2px] ${
                  (disabled || disableId === rowData.id) && 'pointer-events-none opacity-50'
                }`}
              >
                {text}
              </div>
            ))}
          </div>
        }
      >
        <div
          className="cursor-pointer h-6 w-6 flex justify-center items-center rounded shadow-ev-standard hover:bg-ev-baby-blue-2 active:bg-ev-navy-blue-2 active:text-white focus:outline focus:outline-1 focus:outline-ev-dark-purple outline-offset-[-2px]"
          onClick={() => setIsVisible(!isVisible)}
        >
          <SettingsIcon className="mx-auto" />
        </div>
      </Tippy>
    </td>
  );
};

export default TableEditAction;
