import { BillingForm } from 'pages/SubscriptionPage/types/plansModels';
import { SignupModalHeader, SignupModalMode, SignupModalTexts } from 'shared/types/signupModels';
import validateEmail from 'shared/utils/validateEmail';

export const getTextInputForWindowMode = (mode: SignupModalMode): SignupModalTexts => {
  switch (mode) {
    case SignupModalMode.Signup:
      return {
        additionalText:
          'To save or publish your project, sign up for a 14 day free trial. No credit card required. Already have an account?',
        link: 'Log in',
        externalGoogleLink: 'Sign up with Google'
      };
    case SignupModalMode.Login:
      return {
        mainText: 'To save or publish your project, log into your account.',
        additionalText: "Don't have an account?",
        link: 'Sign up for free',
        externalGoogleLink: 'Sign in with Google'
      };
    default:
      return {
        additionalText:
          'To save or publish your project, sign up for a 14 day free trial. No credit card required. Already have an account?',
        link: 'Log in',
        externalGoogleLink: 'Sign in with Google'
      };
  }
};

export const getHeaderForWindowModal = (mode: SignupModalMode, type: string) => {
  switch (mode) {
    case SignupModalMode.Signup:
      return SignupModalHeader.Signup;
    case SignupModalMode.Login:
      return `${type} - ${SignupModalHeader.Login}`;
    default:
      return SignupModalHeader.Signup;
  }
};

export const generateFormForWindowMode = (mode: SignupModalMode): BillingForm => {
  switch (mode) {
    case SignupModalMode.Signup:
      return {
        username: {
          name: 'username',
          value: '',
          error: '',
          placeholder: '',
          label: 'Name'
        },
        email: {
          name: 'email',
          value: '',
          error: '',
          placeholder: '',
          type: 'email',
          label: 'Email'
        },
        password: {
          name: 'password',
          value: '',
          error: '',
          placeholder: 'Password (at least 8 characters)',
          type: 'password',
          label: 'Password'
        },
        confirm_password: {
          name: 'confirm_password',
          value: '',
          error: '',
          placeholder: 'Confirm password',
          type: 'password',
          label: 'Confirm password'
        }
      };
    case SignupModalMode.Login:
      return {
        email: {
          name: 'email',
          value: '',
          error: '',
          placeholder: '',
          type: 'email',
          label: 'Email'
        },
        password: {
          name: 'password',
          value: '',
          error: '',
          placeholder: 'Password (at least 8 characters)',
          type: 'password',
          label: 'Password'
        }
      };
    default:
      return {} as BillingForm;
  }
};

export const validateSignupForm = (form: BillingForm, updateForm: (form: BillingForm) => void) => {
  let valid = true;
  const validatedForm = form;

  if (validatedForm.username) {
    if (validatedForm.username.value.length < 2) {
      validatedForm.username.error = 'Your name needs to be at least 2 characters long';
      valid = false;
    } else {
      validatedForm.username.error = '';
    }
  }

  const emailValid = validateEmail(validatedForm.email.value);
  if (!emailValid) {
    validatedForm.email.error = 'The email you specified is not a valid email format';
    valid = false;
  } else {
    validatedForm.email.error = '';
  }

  if (validatedForm.password.value.length < 8) {
    validatedForm.password.error = 'Your password needs to be at least 8 characters long';
    valid = false;
  } else {
    validatedForm.password.error = '';
  }

  if (validatedForm.password.value !== validatedForm.confirm_password.value) {
    validatedForm.confirm_password.error = 'The passwords you entered do not match';
    valid = false;
  } else {
    validatedForm.confirm_password.error = '';
  }

  updateForm(validatedForm);
  return valid;
};
