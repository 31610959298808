import React from 'react';
import { isEmpty } from '../utils/storyHelper';
import { getTemplateOptions } from '../meta/config/templates';

class Buttons extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.click = this.click.bind(this);
    this.get = this.get.bind(this);
    // this.addToGrid = this.addToGrid.bind(this);

    this.buttons = {
      add_chart: {
        tooltip: 'Add visualization',
        icon: 'plus',
        onClick: (section) => {
          this.props.toggleChartModal(section);
        }
      },
      add: {
        tooltip: 'Add',
        icon: 'plus',
        onClick: (section) => {
          this.props.toggleChartModal(section);
        }
      }
    };
  }

  click(id, section) {
    this.buttons[id].onClick(section);
  }

  get(id) {
    return this.buttons[id];
  }

  render() {
    const { sectionIndex, templateUsed } = this.props;
    const setting = getTemplateOptions(templateUsed ?? 'blank');

    return (
      <div
        className={
          'buttons-container ' +
          (setting?.[sectionIndex]?.settings?.bottomLinks ? 'bottom ' : ' ') +
          (this.props.extraClass || '')
        }
      >
        {(!isEmpty(setting[sectionIndex]) || setting[sectionIndex].settings.hasMultipleElements) &&
          (setting?.[sectionIndex]?.settings?.buttons ?? []).map((id) => {
            const onClick = () => {
              this.click(id, sectionIndex);
            };

            return (
              <button key={id + sectionIndex} className="bulk-action bulk-action-btn" onClick={onClick}>
                <span>
                  <i className={'fa fa-' + this.get(id).icon} /> {this.get(id).tooltip}
                </span>
              </button>
            );
          })}
      </div>
    );
  }
}

export default Buttons;
