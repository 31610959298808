import classNames from 'classnames';
import React from 'react';

import StarIcon from 'static/icons/star.svg';
import Lock from 'static/icons/lock-solid.svg';
import LoaderIcon from './../loader/LoaderIcon';
import { ButtonColor, ButtonType } from './types/ButtonModels';
import {
  LoaderProps,
  getClassesForButtonColor,
  getClassesForButtonType,
  getDisabledButtonClasses,
  getLoaderText
} from './utils/buttonHelper';

type PrimaryButtonProps = {
  text?: string | React.ReactElement;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  link?: string;
  disabled?: boolean;
  useLoader?: LoaderProps;
  className?: string;
  buttonColor?: ButtonColor;
  buttonType?: ButtonType;
  id?: string;
  tooltip?: string;
  chevron?: boolean;
  showPermissionLock?: boolean;
  showSubscriptionLock?: boolean;
};

const PrimaryButton = ({
  text,
  onClick,
  link,
  disabled,
  useLoader,
  className,
  buttonColor,
  buttonType,
  id,
  chevron,
  tooltip,
  showPermissionLock,
  showSubscriptionLock
}: PrimaryButtonProps) => {
  const classes = classNames(className, getClassesForButtonType(buttonType || ButtonType.Action), {
    [getClassesForButtonColor(buttonColor || ButtonColor.Red)]: !disabled,
    [getDisabledButtonClasses(buttonColor || ButtonColor.Disabled)]: disabled,
    flex: showSubscriptionLock && useLoader
  });

  const chevronColor = chevron && buttonColor === 'NavyBlue' ? 'border-t-ev-navy-blue-3' : 'border-t-white';

  const getLockButtonContent = (text: string | React.ReactElement | undefined) => {
    const LockIcon = showSubscriptionLock ? StarIcon : showPermissionLock ? Lock : null;
    return (
      LockIcon && (
        <span className="flex items-center">
          <LockIcon width={17} height={16} className="mr-1" />
          {text}
        </span>
      )
    );
  };

  const buttonComponent = (
    <button onClick={onClick} className={classes} disabled={disabled} id={id} title={tooltip ?? ''}>
      {!showPermissionLock && useLoader && (
        <>
          <LoaderIcon
            icon={useLoader.done?.check ? useLoader.done.icon : useLoader.default.icon}
            loading={useLoader.loading.check as boolean}
          />
          &nbsp;
          {getLoaderText(useLoader)}
        </>
      )}
      {getLockButtonContent(text)}
      {!useLoader && !showSubscriptionLock && !showPermissionLock && text}
    </button>
  );

  if (link) {
    return (
      <a href={link} className={classes} target="_blank" rel="noreferrer">
        {text}
      </a>
    );
  }

  if (chevron) {
    return (
      <div className="group">
        {buttonComponent}

        {chevron && (
          <div
            className={`group-hover:hidden mx-auto w-0 h-0 border-l-[5px] border-r-[5px] border-t-[5px] border-l-transparent border-r-transparent border-solid ${chevronColor}`}
          />
        )}
      </div>
    );
  }
  return buttonComponent;
};

export default PrimaryButton;
