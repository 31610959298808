import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { MenuItem } from 'shared/types/menuItemsModels';

type NavigationTabProps = {
  changeToggled: any;
  toggled: boolean;
  tabs: Record<string, MenuItem>;
};

const titleClasses = 'fixed left-14 h-14 pl-8 pr-4 py-4 text-base font-bold';

const NavTabHoverTitle = ({ name }: { name: string }) => {
  return (
    <div className={`menu-hover-title hidden pointer-events-none group-hover:block transition-all ${titleClasses}`}>
      {name}
    </div>
  );
};

const NavTab = ({ toggled, tab }: { toggled: boolean; tab: MenuItem }) => {
  return (
    <div className="content-centered ev-sm:w-14 ev-sm:h-14 ev-sm:p-4 w-11 h-11 p-3">
      <div className="flex w-full h-full transition-all text-base justify-center">
        <tab.svg className="profile-nav-icon group-hover:text-ev-navy-blue transition-all" />
      </div>
      <div
        className={`profile-nav-title group-hover:hidden transition-all ${
          toggled ? 'block' : 'hidden'
        } ${titleClasses}`}
      >
        {tab.name}
      </div>
      <NavTabHoverTitle name={tab.name} />
    </div>
  );
};

export default function NavigationTab(props: NavigationTabProps) {
  const { tabs, changeToggled, toggled } = props;
  const history = useHistory();
  const from = history.location.pathname;
  const hideMenu = () => changeToggled(false);

  const getElement = (tab: MenuItem, path: string) => {
    if (tab.link) {
      return (
        <a target="_blank" href={tab.link} rel="noreferrer" className="group flex">
          <NavTab toggled={toggled} tab={tab} />
        </a>
      );
    } else {
      return (
        <NavLink
          onClick={hideMenu}
          to={{ pathname: path, state: { from } }}
          activeClassName="active"
          className="group flex"
        >
          <NavTab toggled={toggled} tab={tab} />
        </NavLink>
      );
    }
  };

  return (
    <nav className="ev-sm:mt-0 mt-16 overflow-hidden whitespace-nowrap">
      <ul className="list-none bg-ev-baby-blue-2 ev-sm:bg-ev-navy-blue">
        {tabs &&
          Object.keys(tabs).map((path, index) => {
            return (
              <li
                key={index}
                className={
                  'ml-1 p-1 ev-sm:ml-0 ev-sm:p-0 profile-nav-' + tabs[path].icon + ' hover:bg-ev-baby-blue-2 group'
                }
              >
                {getElement(tabs[path], path)}
              </li>
            );
          })}
      </ul>
    </nav>
  );
}
