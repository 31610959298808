import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanById } from 'pages/SubscriptionPage/utils/plansHelper';
import { ChannelOption } from '../meta/ChannelOptions';
import { TypeProps } from 'shared/types/chooseTheme';

export const getAvailableChannelsBySubscription = (subscriptionId: number) => {
  switch (getPlanById(subscriptionId)) {
    case SubscriptionPlans.Enterprise:
      return [ChannelOption.Online, ChannelOption.Image, ChannelOption.Social, ChannelOption.Code, ChannelOption.Video];
    case SubscriptionPlans.Starter:
    case SubscriptionPlans.ProTrial:
    case SubscriptionPlans.Pro:
      return [ChannelOption.Online, ChannelOption.Image, ChannelOption.Social];
    case SubscriptionPlans.Business:
      return [ChannelOption.Online, ChannelOption.Image, ChannelOption.Social, ChannelOption.Code];
    case SubscriptionPlans.Free:
    case SubscriptionPlans.Storage:
      return [ChannelOption.Online];
    default:
      return [ChannelOption.Online];
  }
};

export const getAvailableChannelsByPaidFeature = (featureIds: [number]) => {
  const channelOptions: ChannelOption[] = [];

  for (let i = 0; i < (featureIds ?? []).length; i++) {
    if (featureIds[i] === 1) channelOptions.push(ChannelOption.LiveVideo);
  }
  return channelOptions;
};

export const getAvailableChannelForType = (type: TypeProps) => {
  switch (type) {
    case 'table':
      return [ChannelOption.Online, ChannelOption.Image, ChannelOption.Social, ChannelOption.LiveVideo];
    case 'layout':
      return [ChannelOption.Online, ChannelOption.LiveVideo];
    default:
      return [
        ChannelOption.Online,
        ChannelOption.Image,
        ChannelOption.Social,
        ChannelOption.Code,
        ChannelOption.LiveVideo,
        ChannelOption.Video
      ];
  }
};
