import React from 'react';

type LoaderIconProps = {
  loading: boolean;
  iconClass?: string;
  icon?: string;
};

export default function LoaderIcon(props: LoaderIconProps) {
  const icon = props.loading ? 'spinner ev-spin wizard-loader' : props.icon || ''

  return <i className={`fas fa-${icon} ${props.iconClass}`} />;
}
