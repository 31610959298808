import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDataAction as setTableDataAction } from '../actions/tableEditor';
import { UnknownParamsFunction } from 'shared/types/commonPropTypes';

export default function CreateTable() {
  const dispatch = useDispatch();
  const [state, set] = useState({
    rows: 5,
    columns: 5,
    selectedRows: -1,
    selectedColumns: -1,
    maxColumns: 25,
    maxRows: 25
  });

  const { rows, columns, selectedRows, selectedColumns } = state;

  const setState = (props: any) => set({ ...state, ...props });
  const setData = (options: unknown, dataType?: string, cb?: UnknownParamsFunction | null) => {
    dispatch(setTableDataAction({ options, dataType, cb }));
  };

  const create = () => {
    const { selectedRows, selectedColumns } = state;
    const dataOptions = [...Array(selectedRows + 1)].map(() => [...Array(selectedColumns + 1).fill('')]);
    dataOptions[0].forEach((_, i) => {
      dataOptions[0][i] = 'Header ' + (i + 1);
    });

    setData(dataOptions);
  };

  const handleOnMouseOver = (e: React.MouseEvent) => {
    const { rows, columns, maxColumns, maxRows } = state;
    const target = e.target as HTMLDivElement;
    const parentTarget = target?.parentNode as HTMLDivElement;

    const newState: {
      selectedColumns: number;
      selectedRows: number;
      rows?: number;
      columns?: number;
    } = {
      selectedColumns: parseInt(target?.getAttribute('data-value') ?? '', 10),
      selectedRows: parseInt(parentTarget?.getAttribute('data-value') ?? '', 10)
    };

    if (newState.selectedColumns === columns - 1) {
      newState.columns = columns + 1;
    }

    if (newState.selectedRows === rows - 1) {
      newState.rows = rows + 1;
    }
    if ((newState.rows ?? -1) > maxRows) newState.rows = maxRows;
    if ((newState.columns ?? -1) > maxColumns) newState.columns = maxColumns;

    setState(newState);
  };

  return (
    <div className="create-table-widget" onClick={create}>
      {[...Array(rows)].map((_, rowCount) => {
        return (
          <div className="create-table-row " key={'create_table_row_' + rowCount} data-value={rowCount}>
            {[...Array(columns)].map((x, columnCount) => {
              return (
                <div
                  key={'create_table_column_' + columnCount}
                  className={
                    'create-table-column ' +
                    (rowCount <= selectedRows && columnCount <= selectedColumns ? 'active' : '')
                  }
                  onMouseOver={handleOnMouseOver}
                  data-value={columnCount}
                />
              );
            })}
          </div>
        );
      })}
      {selectedRows !== -1 && selectedColumns !== -1 && (
        <div className="rows-column-indicator">
          {selectedColumns + 1}x{selectedRows + 1}
        </div>
      )}
    </div>
  );
}
