import { postTeamCompanythemeActiveUsingTeamidAndThemeid } from 'api/cloudApiGenerated';
import paths from 'paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import { snackBar } from 'editor/editors/highed.init';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import TextButton from 'shared/buttons/TextButton';
import PremiumFeature from 'shared/message/PremiumFeature';
import ProfilePageWhiteSectionContainer from 'shared/profile/ProfilePageWhiteSectionContainer';
import Subheader from 'shared/profile/Subheader';
import Tag from 'shared/profile/Tag';
import Theme from './Theme';

type CompanyThemesSectionProps = {
  showPaidPlanModal: () => void;
  deleted?: boolean;
  hasPermission?: () => void;
  checkHasPermission?: (e: any) => void;
  getCompanyTheme: () => void;
};

type CompanyThemeProps = { id: number; active: boolean };

export default function CompanyThemesSection(props: CompanyThemesSectionProps) {
  const { showPaidPlanModal, deleted, hasPermission, checkHasPermission, getCompanyTheme } = props;
  const { companyThemes }: { companyThemes: CompanyThemeProps[] } = useSelector((state: RootState) => state.themesPage);
  const { team } = useSelector((state: RootState) => state.profile);
  const history = useHistory();

  const setAsDefault = (id: number) => {
    postTeamCompanythemeActiveUsingTeamidAndThemeid(team.id, id).then(() => {
      snackBar('Company theme changed');
      getCompanyTheme();
    });
  };

  const goToCompanyThemePage = () => {
    history.push(paths.createCompanyTheme);
  };

  return (
    <ProfilePageWhiteSectionContainer
      headerNode={
        <div>
          <PremiumFeature />
          <div className="flex">
            <Subheader>Company themes</Subheader>
            <div className="content-centered ml-4">
              <IconButton
                icon="plus"
                tooltipText="Set up a company theme"
                className="shrink-0 ml-2 h-8 w-8 rounded-full"
                buttonColor={ButtonColor.BabyBlue2}
                onClick={goToCompanyThemePage}
              />
            </div>
          </div>
        </div>
      }
      description={
        "Brand your projects with your company's corporate colors, fonts and styles. The time-saving option, company themes can be applied to all project types."
      }
    >
      <div className="project-grid mb-16">
        {(companyThemes || []).map((companyTheme: CompanyThemeProps) => {
          const isActive = Boolean(companyTheme.active);

          return (
            <Theme
              key={companyTheme.id}
              theme={companyTheme}
              showPaidPlanModal={showPaidPlanModal}
              isDeleted={deleted}
              hasPermission={hasPermission}
              checkHasPermission={checkHasPermission}
              isCompanyTheme={true}
              refresh={getCompanyTheme}
            >
              {isActive && <Tag text="Default theme" />}
              {!isActive && (
                <div className="h-7">
                  {!deleted && <TextButton onClick={() => setAsDefault(companyTheme.id)} text="Set as default theme" />}
                </div>
              )}
            </Theme>
          );
        })}
      </div>
    </ProfilePageWhiteSectionContainer>
  );
}
