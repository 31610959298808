import { uncamelize } from '../../../editor/core/highcharts-editor';
import highchartsTypeMap from '../../../editor/meta/highchart.types';

export const filters = {
  // Filter the series properties based on the series.type property
  series: {
    controller: 'type',
    state: false,
    default: 'line'
  },
  plotOptions: {
    controller: 'type',
    state: false,
    default: 'line'
  }
};

export const exclusionConditions = (node, aggregatedOptions) => {
  // We should exclude from the search results all the functions and highcharts types default, but include the one that's supplying the current project chart
  const isHighchartsDefault = node.meta.products && Object.keys(node.meta.products).length;
  const isNotCurrentChartType =
    node.meta.validFor && !Object.keys(node.meta.validFor).includes(aggregatedOptions.chart.type);
  const isFunction = Object.keys(node.meta.types)[0] === 'function';
  return Boolean(isFunction || (isHighchartsDefault && isNotCurrentChartType));
};

export const getTreeItemClassName = (
  selectedPath,
  option,
  open,
  isLastSelectedOption,
  isArray = option.meta?.types?.array
) => {
  if (isArray) return 'fas fa-th-list';
  if (option.meta.hasSubTree) {
    return ` ${isLastSelectedOption ? 'bg-ev-navy-blue-2 text-white' : ''} fas fa-folder${
      open || selectedPath.includes(option)
        ? '-open  group-hover:bg-ev-baby-blue-2 group-hover:text-ev-navy-blue-2'
        : ''
    }`;
  }

  return 'fas fa-sliders-h';
};

export const getFilteredList = (selected, path) =>
  selected.children
    .map((child) => {
      if (['function'].includes(Object.keys(child.meta.types)[0])) {
        return null;
      }

      const splitNS = child.meta.ns.split('.');

      if (['xAxis', 'yAxis', 'colorAxis'].includes(splitNS[0])) {
        child.meta.isOptionalArray = true;
        child.meta.rootPath = splitNS[0];
      }
      if (child.meta.leafNode) {
        let type = Object.keys(child.meta.types)[0] ?? 'string';
        if (['number', 'string'].includes(child.meta?.types?.array)) type = `array<${child.meta?.types?.array}>`;
        const highchartsType = highchartsTypeMap[type];
        return {
          ...child.meta,
          type: highchartsType?.type ?? type,
          title: uncamelize(child.meta.name),
          id: `${path}.${child.meta.name}`,
          values: highchartsType?.values
        };
      }
      return null;
    })
    .filter((d) => d);
