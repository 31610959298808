import React from 'react';

const ErrorMessage = (props: { text: string }) => {
  const { text } = props;
  return (
    <div className="pt-2 font-bold text-ev-red">
      <span>
        <i className="pr-2 fa fa-info-circle" />
      </span>
      {text}
    </div>
  );
};

export default ErrorMessage;
