import { TemplateTypeProps } from '../../types';
import BarWithLabels from './BarWithLabels';
import StackedBar from './StackedBar';
import StackedBarPercent from './StackedBarPercent';
import StackedBarPercentWithLabels from './StackedBarPercentWithLabels';
import StackedBarWithLabels from './StackedBarWithLabels';

const StackedBarTemplate: TemplateTypeProps = {
  description: '',
  icon: '',
  sampleData: [],
  templates: {
    'Stacked bar': StackedBar,
    'Stacked bar w/ labels': StackedBarWithLabels,
    'Stacked percent bar': StackedBarPercent,
    'Stacked bar percent w/ labels': StackedBarPercentWithLabels,
    'Bar with labels': BarWithLabels
  }
};

export default StackedBarTemplate;
