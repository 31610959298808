import actionTypes from 'redux/actions/action-types';

export const setAction = (data: any) => ({
  type: actionTypes.packagesPage.set,
  data
});

export const loadPackageProjectAction = (data: { uuid: string }) => ({
  type: actionTypes.packagesPage.loadPackageProject,
  data
});

export const getTeamPackagesAction = () => ({
  type: actionTypes.packagesPage.getTeamPackages
});

export const searchTeamPackagesAction = (data: any) => ({
  type: actionTypes.packagesPage.searchTeamPackages,
  data
});
