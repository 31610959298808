type ProjectsPageProps = {
  page: number;
  pageSize: number;
  pageCount: number;
  query: string;
  tags: [];
  creators: [];
  selectSortValue: { value: 0; label: 'Most recent' };
  selectCreatorValue: [];
  selectValue: [];
  charts: [];
};

const projectsPageStore: ProjectsPageProps = {
  page: 1,
  pageSize: 15,
  pageCount: 1,
  query: '',
  tags: [],
  creators: [],
  selectSortValue: { value: 0, label: 'Most recent' },
  selectCreatorValue: [],
  selectValue: [],
  charts: []
};

export default projectsPageStore;
