import React from 'react';
import { IAnnouncement } from 'shared/types/announcementModels';
import Announcement from './Announcement';
import SideContainer from './SideContainer';

type AnnouncementsListProps = {
  data: IAnnouncement[];
  isOpen: boolean;
  onClose: () => void;
  header: string;
};

const AnnouncementsList = ({ data, isOpen, onClose, header }: AnnouncementsListProps) => {
  const handleOnClose = () => {
    onClose();
  };

  return (
    <SideContainer isOpen={isOpen} header={header} onClose={handleOnClose}>
      {data?.length > 0 ? (
        data.map(({ publish_date: publishDate, title, image, description, link }, index) => (
          <Announcement
            key={index}
            publishDate={publishDate}
            title={title}
            image={image}
            description={description}
            link={link}
          />
        ))
      ) : (
        <div>No new announcements.</div>
      )}
    </SideContainer>
  );
};

export default AnnouncementsList;
