import * as React from 'react';
import { Dimensions } from 'shared/editor/generic/ResolutionSection';
import NumberInputWidget from 'shared/widgets/NumberInputWidget';

export type ExportDimensionsProps = {
  dimensions: Dimensions;
  setResolution: (dimensions: Dimensions) => void;
};

const ExportDimensions = (props: ExportDimensionsProps) => {
  const { dimensions, setResolution } = props;

  return (
    <div className="flex flex-col mt-6 gap-2">
      <NumberInputWidget
        label="Export width (px)"
        value={props.dimensions.width}
        onChange={(params) =>
          setResolution({
            ...dimensions,
            width: params.val as number
          })
        }
      />
      <NumberInputWidget
        label="Export height (px)"
        value={props.dimensions.height}
        onChange={(params) =>
          setResolution({
            ...dimensions,
            height: params.val as number
          })
        }
      />
    </div>
  );
};

export default ExportDimensions;
