import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goToNextPageAction, goToPreviousPageAction } from '../actions/tableEditor';

export default function Pagination(props) {
  const { aggregatedOptions } = props;
  const dispatch = useDispatch();
  const { dataOptions } = useSelector((state) => state.projectConfig);
  const { page, pageAmount } = useSelector((state) => state.tableEditorPage);

  const next = () => {
    if (page + 1 >= pageAmount) return;
    dispatch(goToNextPageAction({ currentPage: page, dataOptions, aggregatedOptions }));
  };

  const previous = () => {
    if (page <= 0) return;
    dispatch(goToPreviousPageAction({ currentPage: page, dataOptions, aggregatedOptions }));
  };

  const backStyle = aggregatedOptions?.table?.pagination?.backButtonStyle || {};
  const frontStyle = aggregatedOptions?.table?.pagination?.frontButtonStyle || {};
  const textStyle = aggregatedOptions?.table?.pagination?.text?.style || {};

  return (
    <div className="pagination-buttons">
      <div className="prev button" style={backStyle} onClick={previous}>
        <span className="fa fa-chevron-left" />
      </div>
      <div className="text" style={textStyle}>
        {page + 1}/{pageAmount}
      </div>
      <div className="button" style={frontStyle} onClick={next}>
        <span className="fa fa-chevron-right" />
      </div>
    </div>
  );
}
