import initialState from '../../../redux/reducers/initial-state';
import actionTypes from '../../../redux/actions/action-types';

const teamInvitationsPageReducer = (state = initialState.teamInvitationsPage, action) => {
  switch (action.type) {
    case actionTypes.teamInvitationsPage.set:
      return {
        ...state,
        ...action.data
      };
  }
  return state;
};

export default teamInvitationsPageReducer;
