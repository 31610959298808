import { TemplateTypeProps } from '../../types';
import Boxplot from './Boxplot';
import DependencyWheel from './DependencyWheel';
import Dumbbell from './Dumbbell';
import Funnel from './Funnel';
import Heatmap from './Heatmap';
import Parliament from './Parliament';
import Pyramid from './Pyramid';
import Sankey from './Sankey';
import SolidGauge from './SolidGauge';
import SpiderArea from './SpiderArea';
import SpiderLine from './SpiderLine';
import Streamgraph from './Streamgraph';
import Treemap from './Treemap';
import Unit from './Unit';
import Waterfall from './Waterfall';
import Wordcloud from './Wordcloud';

const MoreTemplate: TemplateTypeProps = {
  templates: {
    'Dependency wheel': DependencyWheel,
    Sankey,
    Dumbbell,
    Heatmap,
    'Unit chart': Unit,
    Boxplot,
    Funnel,
    Pyramid,
    'Solid gauge': SolidGauge,
    Parliament,
    'Spider area': SpiderArea,
    'Spider line': SpiderLine,
    'Stream graph': Streamgraph,
    Waterfall,
    'Word cloud': Wordcloud,
    'Tree map': Treemap
  }
};

export default MoreTemplate;
