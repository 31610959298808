import { tooltipFormatter } from '../../../editor/core-ui/tooltip/highed.tooltip.helper.utils';
import {
  capitalizeFirstLetter,
  isBool,
  isNull,
  isNum,
  isObj,
  isStr,
  uncamelize
} from '../../../editor/core/highcharts-editor';
import { parseDataNameForSeriesMapping } from 'redux/helpers/projectConfigHelper';
import { get } from 'lodash';

const blacklist = [
  'headerFormat',
  'pointFormat',
  'footerFormat',
  'id',
  'visible',
  'plotX',
  'plotY',
  'yBottom',
  'formatPrefix',
  'isNull',
  'selected',
  'plotClose',
  'plotOpen',
  'isInside',
  'clientX',
  'negative',
  'zone',
  'barX',
  'pointWidth',
  'shapeType',
  'width',
  'height',
  'plotHigh',
  'plotLow',
  'valueDescription',
  'state',
  'distX',
  'dist',
  'destroyed',
  'colorIndex',
  'state',
  'graphic',
  'shapeArgs',
  'chart',
  'point.series',
  'columnMetrics',
  'group',
  'legendItem',
  'markerGroup',
  'options',
  'userOptions',
  'xAxis',
  'yAxis'
];

const multiLevel = ['point', 'series'];
const multiLevelProps = [
  'point.name',
  'series.name',
  'point.key',
  'point.from',
  'point.to',
  'point.weight',
  'point.stackTotal',
  'point.stackY',
  'point.stackX',
  'point.low',
  'point.high',
  'point.z',
  'point.extra',
  'point.label',
  'point.formattedValue',
  'point.median',
  'point.q1',
  'point.q3',
  'point.total',
  'point.description',
  'point.value',
  'point.xLabel',
  'point.yLabel'
];

export const getDefaultTooltipConfig = () => ({
  tooltip: {
    formatter: tooltipFormatter(),
    useHTML: true
  },
  everviz: {
    tooltip: {
      useEvervizHelper: true,
      options: {
        vars: {}
      }
    }
  }
});

const tooltipOptionsInclude = (options, id) => {
  return options.every((option) => option.id !== id);
};

const recursivelyExtractOptions = (tooltipDropdownOptions, option, key) => {
  if (multiLevel.includes(key)) {
    const parentKey = key;
    Object.keys(option).forEach((key) => {
      if (multiLevelProps.includes(parentKey + '.' + key)) {
        recursivelyExtractOptions(tooltipDropdownOptions, option[key], parentKey + '.' + key);
      }
    });
  } else if (isObj(option)) {
    const parentKey = key;
    Object.keys(option).forEach((key) => {
      const propsKey = parentKey + '.' + key;
      if (!blacklist.includes(key) && !blacklist.includes(propsKey)) {
        recursivelyExtractOptions(tooltipDropdownOptions, option[key], key);
      }
    });
  } else if (
    !blacklist.includes(key) &&
    !isNull(option) &&
    tooltipOptionsInclude(tooltipDropdownOptions, key) &&
    (isStr(option) || isNum(option) || isBool(option))
  ) {
    let name = capitalizeFirstLetter(uncamelize(key));
    const isPointValue = !key.includes('.');
    tooltipDropdownOptions.push({
      label: name.replace('.', '') + ` (${option})`,
      value: isPointValue ? `point.${key}` : key,
      id: key
    });
  }
};

const addDataHeadersToTooltipOptions = (tooltipDropdownOptions, options, dataGridHeaders) => {
  dataGridHeaders.forEach((name) => {
    const parsedName = parseDataNameForSeriesMapping(name);
    tooltipDropdownOptions.push({
      label: `${name} (${get(options, `point.${parsedName}`)})`,
      value: `point.${parsedName}`,
      id: name
    });
  });
};

export const getTooltipOptions = (options, dataGridHeaders) => {
  const tooltipDropdownOptions = [];
  recursivelyExtractOptions(tooltipDropdownOptions, options);
  addDataHeadersToTooltipOptions(tooltipDropdownOptions, options, dataGridHeaders);

  return tooltipDropdownOptions;
};
