export default {
  'option.cat.text': [
    {
      id: 'text-category',
      pid: 'text.category',
      text: 'option.subcat.text',
      options: [
        {
          id: 'title.text',
          richtext: {
            rawId: 'title'
          },
          pid: 'title.text',
          type: 'richtextgroup',
          isRichtext: true,
          className: 'title-text'
        },
        {
          id: 'subtitle.text',
          richtext: {
            rawId: 'subtitle'
          },
          pid: 'subtitle.text',
          type: 'richtextgroup',
          isRichtext: true,
          className: 'subtitle-text'
        },
        {
          id: 'caption.text',
          richtext: {
            rawId: 'caption'
          },
          pid: 'caption.text',
          type: 'richtextgroup',
          isRichtext: true,
          className: 'caption-text'
        }
      ]
    }
  ]
};
