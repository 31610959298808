import { SubscriptionPlan } from '../types/plansModels';

export const subscriptionPlans: Record<number, SubscriptionPlan> = {
  1: {
    id: 1,
    value: 1,
    text: 'Free Trial',
    name: 'Free Trial',
    price: 0
  },
  9: {
    id: 9,
    value: 9,
    text: 'Starter (Monthly payment) - $59',
    name: 'Starter (Monthly)',
    price: 59
  },
  19: {
    id: 19,
    value: 19,
    text: 'Starter (Monthly payment) - $59',
    name: 'Starter (Monthly)',
    price: 59
  },
  20: {
    id: 20,
    value: 20,
    text: 'Starter (Yearly payment) - $590 ($49 per month)',
    name: 'Starter (Yearly)',
    price: 590,
    interval_length: 12
  },
  10: {
    id: 10,
    value: 10,
    text: 'Business (Monthly payment) - $239',
    name: 'Business (Monthly)',
    price: 239
  },
  12: {
    id: 12,
    value: 12,
    text: 'Starter (Yearly payment) - $590 ($49 per month)',
    name: 'Starter (Yearly)',
    price: 590,
    interval_length: 12
  },
  13: {
    id: 13,
    value: 13,
    text: 'Business (Yearly payment) - $2390 ($199 per month)',
    name: 'Business (Yearly)',
    price: 2390,
    interval_length: 12
  },
  17: {
    id: 17,
    value: 17,
    name: 'Storage',
    price: 300,
    interval_length: 12
  },
  21: {
    id: 21,
    value: 21,
    text: 'Pro (Monthly payment) - $599',
    name: 'Pro (Monthly)',
    price: 599
  },
  22: {
    id: 22,
    value: 22,
    text: 'Pro (Yearly payment) - $5990 ($499 per month)',
    name: 'Pro (Yearly)',
    price: 5990,
    interval_length: 12
  },
  23: {
    id: 23,
    value: 23,
    text: 'Pro Trial - 14 Days',
    name: 'Pro Trial',
    price: 0
  }
};
