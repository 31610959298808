import * as React from 'react';
import { useDispatch } from 'react-redux';
import { showSignupModalAction } from 'redux/actions/projectConfig';
import TextButton from 'shared/buttons/TextButton';
import { AccountFormSetup, BillingForm } from '../types/plansModels';
import CheckoutField from './CheckoutField';
import CheckoutTile from './CheckoutTile';

type AccountInfoProps = {
  editing: boolean;
  toggleEditing: () => void;
  billingForm: BillingForm;
  handleChange: React.ChangeEventHandler;
  user: any;
  isLoggedIn: boolean;
  isEditable: boolean;
};

const AccountInfo = (props: AccountInfoProps) => {
  const { isLoggedIn, billingForm } = props;
  const dispatch = useDispatch();
  const formSetup = isLoggedIn ? AccountFormSetup.Account : AccountFormSetup.Register;

  return (
    <CheckoutTile
      title={`1. ${formSetup}`}
      completed={isLoggedIn}
      isEditable={props.isEditable}
      editing={isLoggedIn ? props.editing : true}
      toggleEditing={props.toggleEditing}
      submitButtonText="Add account information"
      handleSubmit={isLoggedIn ? props.toggleEditing : undefined}
    >
      {formSetup === AccountFormSetup.Account && (
        <>
          <div className={`${props.editing ? 'block' : 'hidden'}`}>
            <CheckoutField field={billingForm.username} onChange={props.handleChange} readOnly={true} />
            <CheckoutField field={billingForm.email} onChange={props.handleChange} />
          </div>
          <div className={`${props.editing ? 'hidden' : 'block'} pt-2`}>
            <p className="mb-0">
              {billingForm.username.value || props.user?.username} <br /> {billingForm.email.value || props.user?.email}
            </p>
          </div>
        </>
      )}
      {formSetup === AccountFormSetup.Register && (
        <>
          <CheckoutField field={billingForm.username} onChange={props.handleChange} />
          <CheckoutField field={billingForm.email} onChange={props.handleChange} />
          <CheckoutField field={billingForm.password} onChange={props.handleChange} type="password" />
          <CheckoutField field={billingForm.confirm_password} onChange={props.handleChange} type="password" />
          <p className="mt-4 mx-auto text-sm max-w-lg text-center text-ev-navy-blue">
            This site is protected by reCAPTCHA and the&nbsp;
            <a href="https://policies.google.com/privacy">Google Privacy Policy</a> and&nbsp;
            <a href="https://policies.google.com/terms">Terms of Service</a> apply. By clicking Register, you agree to
            our&nbsp;
            <a href="https://www.everviz.com/terms">Terms of Service</a> and&nbsp;
            <a href="https://www.everviz.com/privacy">Privacy Policy</a>.
          </p>
          <p className="text-ev-navy-blue mx-auto">
            Already have an account?&nbsp;
            <TextButton text="Sign in" onClick={() => dispatch(showSignupModalAction())} />
          </p>
        </>
      )}
    </CheckoutTile>
  );
};

export default AccountInfo;
