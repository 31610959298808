import React from 'react';
import { format } from 'date-fns';

type AuthorsInfoPropsDataType = {
  chartInfo: any;
  className?: string;
};

const AuthorsInfo = (props: AuthorsInfoPropsDataType) => {
  const dateFormat = 'EEEE, MMMM d, yyyy, HH:mm';

  const formatedDateTime = (datetime: string | undefined) => {
    return datetime ? format(new Date(datetime), dateFormat) : '';
  };

  return (
    <div className={`text-xs ${props.className ?? ''}`}>
      <div>
        <b>Created by:</b>
        &nbsp;
        <span>
          {props.chartInfo?.creator} on {formatedDateTime(props.chartInfo?.creation_time)}
        </span>
      </div>
      <div className="mt-1">
        <b>Last Modified by:</b>
        &nbsp;
        <span>
          {props.chartInfo?.username} on {formatedDateTime(props.chartInfo?.last_updated)}
        </span>
      </div>
    </div>
  );
};

export default AuthorsInfo;
