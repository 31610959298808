import React from 'react';

export default function ResizeHorizontalIcon() {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3926 6.97539C14.3926 7.19928 14.3071 7.4241 14.1362 7.595L12.1675 9.56375C11.9973 9.75898 11.773 9.84375 11.5488 9.84375C11.049 9.84375 10.6738 9.43496 10.6738 8.96875C10.6738 8.74486 10.7593 8.52086 10.9302 8.35023L11.4066 7.875H3.38125L3.85648 8.35023C4.02656 8.52031 4.11133 8.74453 4.11133 8.96875C4.11133 9.43496 3.7359 9.84375 3.23633 9.84375C3.01244 9.84375 2.78844 9.7583 2.61781 9.5874L0.649063 7.61865C0.478027 7.44844 0.392578 7.22422 0.392578 6.97539C0.392578 6.72656 0.478027 6.52859 0.648926 6.3577L2.61768 4.38895C2.78871 4.24102 3.01238 4.15625 3.23633 4.15625C3.73617 4.15625 4.11133 4.56504 4.11133 5.03125C4.11133 5.25514 4.02588 5.47914 3.85498 5.64977L3.38125 6.125H11.4066L10.9314 5.64977C10.7586 5.47969 10.6738 5.25547 10.6738 5.03125C10.6738 4.56504 11.0493 4.15625 11.5488 4.15625C11.7727 4.15625 11.9967 4.2417 12.1673 4.4126L14.1361 6.38135C14.3078 6.55156 14.3926 6.77578 14.3926 6.97539Z"
        fill="currentColor"
      />
      <path
        d="M5.42383 3.71875C5.64772 3.71875 5.87172 3.6333 6.04234 3.4624L6.51758 2.98867V5.125H8.26758V2.98867L8.74281 3.46391C8.91289 3.63398 9.13711 3.71875 9.36133 3.71875C9.86117 3.71875 10.2363 3.30996 10.2363 2.84375C10.2363 2.61986 10.1509 2.39586 9.97998 2.22523L8.01123 0.256484C7.84102 0.0854492 7.6168 0 7.39258 0C7.16836 0 6.94414 0.0854492 6.77461 0.256348L4.80586 2.2251C4.63359 2.39613 4.54883 2.6198 4.54883 2.84375C4.54883 3.31133 4.92344 3.71875 5.42383 3.71875ZM9.36133 10.2812C9.13744 10.2812 8.91344 10.3667 8.74281 10.5376L8.26758 11.0141V8.875H6.51758V11.0141L6.04234 10.5388C5.87227 10.366 5.64805 10.2812 5.42383 10.2812C4.92398 10.2812 4.54883 10.69 4.54883 11.1562C4.54883 11.3801 4.63428 11.6041 4.80518 11.7748L6.77393 13.7435C6.94414 13.9152 7.16836 14 7.39258 14C7.6168 14 7.84047 13.9146 8.01109 13.7437L9.97984 11.7749C10.1516 11.6047 10.2363 11.3805 10.2363 11.1562C10.2363 10.6887 9.86172 10.2812 9.36133 10.2812Z"
        fill="#AEC8FA"
      />
    </svg>
  );
}
