import React, { useEffect } from 'react';

import { isArray, isEmpty, isNull, range } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Pagination from '../../../shared/Pagination';
import { checkPermission, setDivision } from '../../../utils/profileHelper';
import CreateNewProjectTile from './CreateNewProjectTile';
import Search from './ProjectsViewSearch.js';

import { RootState } from 'redux/store';
import { getChartsAction, pageUpdateAction, setAction, setSelectFilterAction } from '../actions/projectsPage.js';
import WelcomeCard from './WelcomeCard';

type ProjectsViewProps = {
  simple: boolean;
  with: React.ElementType;
  placeholder: React.ElementType;
  deleted?: boolean;
  showCreateCard?: boolean;
};

const ProjectsView = (props: ProjectsViewProps) => {
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.profile);
  const projectsPage = useSelector((state: RootState) => state.projectsPage);
  const viewChartsPerm = checkPermission('view_charts', profile);
  const allSubteamsPerm = checkPermission('all_subteams', profile);

  // todo: this should be moved to middleware
  useEffect(() => {
    dispatch(getChartsAction());
  }, [projectsPage.page, props.deleted, profile.division?.id, profile.team?.id, viewChartsPerm]);

  useEffect(() => {
    dispatch(setAction({ page: 1 }));
    dispatch(
      setSelectFilterAction({
        selectValue: [],
        selectCreatorValue: [],
        selectSortValue: { value: 0, label: 'Most recent' }
      })
    );
  }, [profile.division?.id]);

  const noResults = projectsPage.charts.length === 0;
  const emptyQuery = projectsPage.query === '';
  const inSubteam = profile.division.id !== null;
  const isFeedbackFormActive = projectsPage.isFeedbackFormActive;

  const switchToAll = () => {
    const allSubteams = profile.divisions.find((x: any) => isNull(x.id));

    setDivision(allSubteams);
  };

  // Partially duplicated from ProjectsViewSearch without page- & query reset.
  // TODO: Simpler way of getting back to initial state.
  const resetFilters = () => {
    dispatch(
      setSelectFilterAction({
        query: '',
        selectValue: [],
        selectCreatorValue: [],
        selectSortValue: { value: 0, label: 'Most recent' }
      })
    );
  };

  const filtersApplied =
    !isEmpty(projectsPage.selectValue) ||
    !isEmpty(projectsPage.selectCreatorValue) ||
    projectsPage.selectSortValue.id !== 0;

  const availableActions = [
    !filtersApplied && inSubteam && allSubteamsPerm && [['Try your search in', ['All Subteams', switchToAll]]],
    filtersApplied && !inSubteam && [['Try your', ['search without filters', resetFilters]]],
    filtersApplied &&
      inSubteam &&
      allSubteamsPerm && [
        ['Try your search in', ['All subteams', switchToAll]],
        ['or', ['reset your filters', resetFilters]]
      ]
  ].find(isArray);

  const actions = availableActions
    ? availableActions.map(([prefix, [text, onClick]]) => (
        <div key={prefix}>
          {prefix}{' '}
          <button className="underline font-bold hover:text-ev-lighter-blue transition-colors" onClick={onClick}>
            {text}
          </button>
        </div>
      ))
    : undefined;

  return (
    <div className="projects-view">
      {!props.deleted && (
        <Search
          simple={props.simple}
          deleted={props.deleted}
          viewChartsPerm={viewChartsPerm}
          checkPermission={checkPermission}
        />
      )}

      {
        // User has no projects
        emptyQuery && noResults && (
          <div className="project-grid mb-12 mt-4">
            {props.showCreateCard && (
              <>
                <CreateNewProjectTile />
                {profile.chartsCreated === 0 && <WelcomeCard />}
              </>
            )}
          </div>
        )
      }
      {
        // No results
        !emptyQuery && noResults && (
          <div className="flex items-center justify-center h-52">
            <p className="text-2xl text-center text-ev-navy-blue">
              <div className="mb-4">
                Sorry, no results for
                <span className="font-bold"> “{projectsPage.query}” </span> {inSubteam && 'in ' + profile.division.name}
              </div>
              {actions}
            </p>
          </div>
        )
      }

      {!noResults && (
        <div className="project-grid mb-12 mt-4">
          {props.showCreateCard && <CreateNewProjectTile />}
          {
            // Search is ongoing
            projectsPage.charts.includes(null) &&
              range(projectsPage.pageSize).map((el) => <props.placeholder key={el} />)
          }

          {
            // Search got results
            !projectsPage.charts.includes(null) &&
              projectsPage.charts.length > 0 &&
              projectsPage.charts.map((chart: any, i: number) => (
                <props.with
                  key={chart.id}
                  chart={chart}
                  highlighted={!i && isFeedbackFormActive}
                  deleted={props.deleted}
                />
              ))
          }
        </div>
      )}

      {projectsPage.pageCount > 1 && (
        <Pagination
          pageCount={projectsPage.pageCount}
          currentPage={projectsPage.page}
          onSelectPage={(p) => {
            dispatch(pageUpdateAction({ page: p }));
          }}
        />
      )}
    </div>
  );
};

export default ProjectsView;
