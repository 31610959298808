import React, { useEffect } from 'react';
import BuildInputs from './../../shared/widgets/BuildInputs';
import SelectWidget from './SelectWidget';
import showDimmer from '../../editor/core-ui/highed.dimmer';
import { getAttr } from '../../editor/core/highcharts-editor';
import { isFunction } from 'lodash';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  onChangeActiveAnnotationAction,
  updateLineAnnotationAction,
  removeAnnotationAction
} from '../../pages/ChartEditorPage/actions/chartEditor';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';

let hideDimmer = false;
export default (props) => {
  const { option } = props;
  const { annotationSection } = useSelector((state) => state.chartEditorPage);
  const { customizedOptions } = useSelector((state) => state.projectConfig);
  const dispatch = useDispatch();
  const store = useStore();
  const chart = useChart();

  const onChangeActiveAnnotation = (selected, type) => dispatch(onChangeActiveAnnotationAction({ selected, type }));
  const updateLineAnnotation = (optionProps, val, extraModulesToLoad, index) =>
    dispatch(updateLineAnnotationAction({ optionProps, val, extraModulesToLoad, index }));

  useEffect(() => {
    return () => {
      const { annotationSection } = store.getState().chartEditorPage;
      if (annotationSection.lines) {
        onChangeActiveAnnotation();
      }
    };
  }, []);

  const onChange = (type, optionProps, val, extraModulesToLoad) => {
    updateLineAnnotation(optionProps, val, extraModulesToLoad, annotationSection.lines.value || 0);
  };

  const getLines = () => {
    let optionValues = [];
    let selectOptions = [];
    const vals = getAttr(customizedOptions, 'annotations') || [];

    let count = 1;

    (vals || []).forEach((val, i) => {
      const isLineAnnotation = val.langKey !== 'label' && !val.labels;
      if (isLineAnnotation) {
        let option = {
          label: 'Line ' + count,
          value: i,
          index: count - 1
        };

        selectOptions.push(option);
        optionValues.push(val.typeOptions?.line);
        count++;
      }
    });

    return {
      selectOptions,
      optionValues
    };
  };

  const onChangeLine = (selected) => {
    onChangeActiveAnnotation(selected, 'lines');
  };

  const hide = () => {
    const helpContainer = document.querySelector('.highed-annotation-help-container');
    chart.container.style.zIndex = 0;
    helpContainer.classList.remove('active');
    if (isFunction(hideDimmer)) {
      hideDimmer();
    }
  };

  const getValue = (selectOptions) => {
    if (annotationSection.lines === false) return false;

    if (annotationSection.lines.label) {
      return annotationSection.lines;
    }

    // No line, find the label from select options
    return selectOptions.find((d) => d.value === annotationSection.lines.value);
  };

  const removeAnnotation = () => {
    const { selectOptions } = getLines();
    const selectValue = getValue(selectOptions);
    dispatch(removeAnnotationAction({ type: 'line', index: selectValue ? selectValue.index : 0 }));
  };

  const addAnnotation = (type) => {
    chart.annotations.forEach((annotation) => annotation.setControlPointsVisibility(false));
    chart.navigationBindings.activeAnnotation = null;

    const selector = type === 'label' ? 'highcharts-label-annotation' : 'highcharts-segment';
    const btn = document.querySelector(`.highed-template-tooltip.annotation-buttons.${selector}.highed-svg-icon`);
    const helpContainer = document.querySelector('.highed-annotation-help-container');

    btn.click();

    hideDimmer = showDimmer(
      hide,
      true,
      false,
      2,
      document.querySelector('#app .page-container .wizard-popup, #app .page-container .left-panel')
    );
    helpContainer.className += ' active';

    chart.container.style.zIndex = 10002;
  };

  const { selectOptions, optionValues } = getLines();
  const selectValue = getValue(selectOptions);

  return (
    <>
      <div className="font-bold pt-4 pb-2 text-ev-navy-blue-4"> Lines </div>

      <div className="flex">
        <div className="inline-block annotation-dropdown w-full">
          <SelectWidget
            selectOptions={selectOptions}
            onChange={onChangeLine}
            value={selectValue}
            placeholder={'Lines'}
            canBeEmpty={true}
          />
        </div>
        <button className="inline-block highed-delete-add-option-button" onClick={() => addAnnotation('line')}>
          <i className="fa fa-plus" />
        </button>
        <button className="inline-block highed-delete-add-option-button disabled" onClick={removeAnnotation}>
          <i className="fa fa-trash" />
        </button>
      </div>

      {annotationSection.lines && (
        <div className="flex flex-wrap gap-x-1 gap-y-2">
          <BuildInputs
            section={option}
            parentKey={''}
            overrideOnChange={onChange}
            overrideOptions={optionValues[selectValue?.index ?? 0]}
          />
        </div>
      )}
    </>
  );
};
