import { SubscriptionPlans } from '../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../types';

const Honeycomb: TemplateProps = {
  title: 'Honeycomb',
  description: [
    'Tilemaps are maps where each area is represented by tiles of equal shape. You can choose from circle or honeycomb which uses hexagonal tiles.'
  ],
  constructor: 'Chart',
  thumbnail: 'maphoneycomb.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter),
  config: {
    chart: {
      type: 'tilemap',
      inverted: true
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },
    colorAxis: {
      dataClasses: [
        {
          from: 0,
          to: 1000000,
          color: '#F9EDB3',
          name: '< 1M'
        },
        {
          from: 1000000,
          to: 5000000,
          color: '#FFC428',
          name: '1M - 5M'
        },
        {
          from: 5000000,
          to: 20000000,
          color: '#FF7987',
          name: '5M - 20M'
        },
        {
          from: 20000000,
          color: '#FF2371',
          name: '> 20M'
        }
      ]
    },
    tooltip: {
      headerFormat: '',
      pointFormat: 'The real GDP growth of <b>{point.name}</b> is <b>{point.value}</b> %'
    },
    plotOptions: {
      series: {
        lineWidth: 0,
        pointPadding: 1,
        dataLabels: {
          enabled: true,
          format: '{point.hc-a2}',
          color: '#000000',
          style: {
            textOutline: false
          }
        }
      }
    }
  },
  sampleSet: 'maptile',
  dataValidator: false,
  isMap: true,
  advancedParent: 'Honeycomb',
  defaultDataType: 'honeycomb',
  basic: {
    order: 5,
    thumbnail: 'honeycomb_map.svg',
    icon: 'Honeycomb'
  }
};

export default Honeycomb;
