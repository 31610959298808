import React from 'react';

const MetricContainer = ({ title, icon, sumMetric, metric }) => (
  <div className="flex h-96 space-x-4">
    <div className="w-1/6 bg-[#FFFFFF] space-y-10 rounded-md border-solid border">
      <h3 className="text-center">{title}</h3>
      <img className="mx-auto py-4" src={icon} />
      <p className="text-xl text-center">{sumMetric}</p>
    </div>
    <div className="w-5/6 border-solid border rounded-md " id={`highchart-container-${metric}`}></div>
  </div>
);

export default MetricContainer;
