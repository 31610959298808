/* eslint-disable callback-return */
import { merge, setAttr } from 'editor/core/highcharts-editor';
import { snackBar } from 'editor/editors/highed.init';
import { cloneDeep, isArray } from 'lodash';
import { loadActiveCompanyTheme } from 'pages/CompanyThemeEditorPage/middleware/themeEditorPage';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getTeamFontsWithTeamid,
  getTeamLogosWithTeamid,
  getTeamStoryWithTeamidAndStoryid,
  getTeamThemeWithTeamidAndThemeid,
  postTeamStoryEmbedUsingTeamidAndStoryid,
  postTeamStoryUsingTeamid,
  postTeamStoryUsingTeamidAndStoryid
} from '../../../api/cloudApiGenerated';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProfileAction } from '../../../redux/actions/profile';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { getProfileConfig } from '../../../redux/selectors/profile';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { getConfig } from '../../TableEditorPage/selectors/tableEditor';
import { setAction } from '../actions/layoutEditor';
import { loadCSS } from '../utils/storyHelper';
import DefaultThemes from '../meta/DefaultThemes';
import { getCompanyThemes } from 'middleware/editor/ProjectEditor';

export function* initEditor(params) {
  let { newLayout } = params.data;
  const hasCompanyTheme = yield call(loadActiveCompanyTheme, 'layout');

  if (newLayout && !hasCompanyTheme) {
    // eslint-disable-next-line no-use-before-define
    yield call(assignTheme, {
      data: {
        theme: DefaultThemes[0]
      }
    });
  }

  yield put(
    setProjectConfigAction({
      showWizard: location.pathname.match('/create'),
      type: 'layout'
    })
  );
}

export function* saveLayout(params) {
  let { callback, hideNotification } = params.data;
  const config = yield select(getProfileConfig);
  const layoutConfig = yield select(getConfig);
  const projectConfig = yield select(getProjectConfig);
  let { storyId } = layoutConfig;
  let { division, team } = config;
  let { customizedOptions, dataOptions, templateOptions, themeOptions, themeMeta, projectName, cssModules } =
    projectConfig;
  let project = {
    pages: [
      {
        options: customizedOptions,
        theme: themeOptions,
        themeMeta,
        template: templateOptions,
        data: dataOptions,
        cssModules
      }
    ]
  };

  let titleValue = projectName;
  if (!projectName) {
    if (project?.options?.title?.text) {
      titleValue = project?.options?.title?.text;
    } else titleValue = 'Untitled layout';
  }

  const body = { data: JSON.stringify(project) };
  body.name = titleValue;

  let save;
  let parameters = [];

  if (!storyId) {
    if (division && division.id !== null) body.division_id = division.id;
    save = postTeamStoryUsingTeamid;
    parameters = [team.id, body];
  } else {
    save = postTeamStoryUsingTeamidAndStoryid;
    parameters = [team.id, storyId, body];
  }

  let data = false;
  try {
    data = yield call(save, ...parameters);
  } catch (error) {
    if (error && error.response && error.response.message && error.response.message[0] === 'Could not create chart') {
      snackBar('Sorry, you have run out of projects');
    }
    return error;
  }

  if (!storyId) {
    yield put(
      setProfileAction({
        chartsCreated: config.chartsCreated + 1
      })
    );
  }

  if (!hideNotification) snackBar('Layout saved');

  if (storyId) {
    if (callback) {
      callback({
        ...data,
        story_id: storyId
      });
    }
  } else {
    yield all([
      put(
        setAction({
          storyId: data.story_id
        })
      ),
      put(
        setProjectConfigAction({
          saved: true,
          uuid: data.uuid,
          lastSaved: new Date()
        })
      )
    ]);

    if (callback) {
      callback(data);
    }
  }

  yield put(
    setProjectConfigAction({
      changeMade: false,
      lastSaved: new Date()
    })
  );
}

export function* publishLayout(params) {
  let { inspired } = params.data;
  let postEmbed;

  const layoutConfig = yield select(getConfig);
  const { showWizard } = yield select(getProjectConfig);
  const profileConfig = yield select(getProfileConfig);
  let { storyId } = layoutConfig;
  let { team } = profileConfig;

  const projectConfigOptions = {
    publishLoading: true
  };

  if (!showWizard) projectConfigOptions.tab = 'publish';
  yield put(setProjectConfigAction(projectConfigOptions));

  if (storyId) {
    postEmbed = postTeamStoryEmbedUsingTeamidAndStoryid;
  }

  let data = yield call(postEmbed, team.id, storyId, {
    inspired
  });

  let embedDetails = {};
  embedDetails.injectCode = data.story;
  embedDetails.iframeCode = data.storyIframe;
  embedDetails.shareUrl = window.hcconfig.backend.hostname + '/share/' + data.uuid;
  embedDetails.socialLinks = window.hcconfig.backend.hostname + '/share/' + data.uuid;
  embedDetails.iframeURL = data.storyURL.replace('inject', 'embed');
  embedDetails.uuid = data.uuid;

  yield put(
    setProjectConfigAction({
      published: true,
      publishLoading: false,
      embedDetails,
      lastPublishTime: data.last_publish_time
    })
  );
}

export function* loadLayout(params) {
  const { team, storyId, cb } = params.data;
  if (team) {
    const data = yield call(getTeamStoryWithTeamidAndStoryid, team.id, storyId);
    const project = JSON.parse(data.data);
    const embedDetails = {};
    if (data.injectCode) embedDetails.injectCode = data.injectCode;
    if (data.embedCode) embedDetails.iframeCode = data.embedCode;

    embedDetails.shareUrl = window.hcconfig.backend.hostname + '/share/' + data.uuid;
    embedDetails.socialLinks = window.hcconfig.backend.hostname + '/share/' + data.uuid;
    embedDetails.iframeURL = window.hcconfig.frontend.hostname + '/embed/' + data.uuid + '/?v=' + data.version;
    embedDetails.uuid = data.uuid;

    yield all([
      put(
        setProjectConfigAction({
          customizedOptions: project.pages[0].options,
          themeOptions: project.pages[0].theme,
          themeMeta: project.pages[0].themeMeta,
          templateOptions: project.pages[0].template,
          dataOptions: project.pages[0].data,
          embedDetails,
          uuid: data.uuid,
          changeMade: false,
          lastPublishTime: data.last_publish_time,
          published: data.has_published,
          saved: true,
          lastSaved: data.creation_time,
          cssModules: project.pages[0].cssModules,
          projectName: data.name
        })
      ),
      put(
        setAction({
          templateUsed: project.pages[0].options.layout.type,
          loadedPage: project.pages
        })
      )
    ]);

    yield call(getCompanyThemes);

    if (project.pages[0].cssModules && project.pages[0].cssModules.length) {
      project.pages[0].cssModules.forEach((module) => {
        loadCSS(module);
      });
    }

    if (cb) {
      cb();
    }
  }
}

export function* setPageTemplate(params) {
  let { template, customizedOptions, dataOptions } = params.data;

  let newCustomizedOptions = cloneDeep(customizedOptions);
  if (newCustomizedOptions.layout) delete newCustomizedOptions.layout;

  newCustomizedOptions = merge(newCustomizedOptions, {
    layout: {
      type: template.type
    },
    ...(template.config || {})
  });

  let newDataOptions = cloneDeep(dataOptions);

  if (template.type !== 'carousel' && template.type !== 'tabs' && template.type !== 'dropdown') {
    if (newDataOptions.length > template.layout.length) {
      newDataOptions = newDataOptions.slice(0, template.layout.length);
    } else {
      const diff = template.layout.length - newDataOptions.length;
      for (let i = 0; i < diff; i++) newDataOptions.push({});
    }
  } else {
    newDataOptions = newDataOptions.filter((data) => 'chart_id' in data);
  }

  if (template.type !== 'slider') {
    newDataOptions.forEach((data) => {
      if ('image' in data) delete data.image;
    });
  }

  if (newDataOptions.length === 0) newDataOptions[0] = {};

  yield all([
    put(
      setProjectConfigAction({
        customizedOptions: newCustomizedOptions,
        templateOptions: template.layout.map((template) => {
          return {
            style: template.style,
            smallStyle: template.smallStyle
          };
        }),
        dataOptions: newDataOptions,
        changeMade: true
      })
    ),

    put(
      setAction({
        templateUsed: template.type
      })
    )
  ]);
}

export function* addChartToGrid(params) {
  let { chart, parentToAttachChartTo, dataOptions } = params.data;

  dataOptions = cloneDeep(dataOptions);

  if (!dataOptions[parentToAttachChartTo]) dataOptions[parentToAttachChartTo] = {};

  delete dataOptions[parentToAttachChartTo].image;

  dataOptions[parentToAttachChartTo].hash = chart.hash;
  dataOptions[parentToAttachChartTo].version = chart.version;
  dataOptions[parentToAttachChartTo].chart_id = chart.id;
  dataOptions[parentToAttachChartTo].chart_type = chart.chart_type;

  yield all([
    put(
      setProjectConfigAction({
        dataOptions,
        changeMade: true
      })
    ),
    put(
      setAction({
        isChartModalOpen: false,
        activeSectionIndex: false,
        parentToAttachChartTo: false
      })
    )
  ]);
}

export function* addImageToGrid(params) {
  let { image, parentToAttachChartTo, dataOptions } = params.data;

  dataOptions = cloneDeep(dataOptions);

  if (!dataOptions[parentToAttachChartTo]) dataOptions[parentToAttachChartTo] = {};
  dataOptions[parentToAttachChartTo].image = image;

  delete dataOptions[parentToAttachChartTo].hash;
  delete dataOptions[parentToAttachChartTo].chart_id;
  delete dataOptions[parentToAttachChartTo].version;
  delete dataOptions[parentToAttachChartTo].chart_type;

  yield all([
    put(
      setProjectConfigAction({
        dataOptions,
        changeMade: true
      })
    ),
    put(
      setAction({
        isChartModalOpen: false,
        activeSectionIndex: false,
        parentToAttachChartTo: false
      })
    )
  ]);
}

export function* addToGrid(params) {
  let { pages, section } = params.data;

  let activePage = 0;
  const properties = {};

  if (!pages[activePage][section].data) pages[activePage][section].data = [];
  pages[activePage][section].data.push(properties);

  yield all([
    put(
      setProjectConfigAction({
        changeMade: true
      })
    ),
    put(
      setAction({
        pages
      })
    )
  ]);
}

export function* changeTitleText(params) {
  let { event, customizedOptions, type } = params.data;

  let newCustomizedOptions = cloneDeep(customizedOptions);
  if (!newCustomizedOptions[type]) {
    newCustomizedOptions[type] = {};
  }
  newCustomizedOptions[type].text = event.target.value;

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );
}

export function* updateCustomizedProp(params) {
  // let {option, val, parent, cssModules, extraModulesToLoad, optionProps} = params.data;
  let { optionProps, val, index } = params.data;

  let config = yield select(getProjectConfig);
  let newCustomizedOptions = cloneDeep(config.customizedOptions);

  if (isArray(optionProps.id)) {
    optionProps.id.forEach((prop) => {
      setAttr(newCustomizedOptions, prop, val, index ?? 0);
    });
  } else setAttr(newCustomizedOptions, optionProps.id || optionProps, val, index ?? 0);

  if (optionProps && optionProps.clearFieldsWhenSet) {
    optionProps.clearFieldsWhenSet.forEach((field) => {
      setAttr(newCustomizedOptions[parent], field, null);
    });
  }

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );
}

export function* updateDataProp(params) {
  let projectConfig = yield select(getProjectConfig);
  let { option, val, dataOptions, index } = params.data;

  let newDataOptions = cloneDeep(dataOptions ? [...dataOptions] : projectConfig.dataOptions);

  if (!newDataOptions[index]) {
    newDataOptions[index] = {};
  }

  newDataOptions[index][option] = val;

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );
}

export function* assignTheme(params) {
  let { theme, team } = params.data;
  let themeData = null;

  const projectConfig = yield select((state) => state.projectConfig);
  let cssModules = (projectConfig.cssModules || []).slice();

  if (theme.isDefault) {
    themeData = {
      pages: [
        {
          options: theme.template.options
        }
      ]
    };

    if (theme.template.cssModules.length > 0) {
      theme.template.cssModules.forEach((module) => {
        loadCSS(module);
        if (!cssModules.includes(module)) {
          cssModules.push(module);
        }
      });

      yield put(
        setProjectConfigAction({
          cssModules
        })
      );
    }
  } else {
    let actualTheme = yield call(getTeamThemeWithTeamidAndThemeid, team.id, theme.id);
    themeData = JSON.parse(actualTheme.data);
    delete themeData.pages[0].options.layout;
  }

  let themeMeta = {};
  if (theme?.id && theme?.name) {
    themeMeta.id = theme.id;
    themeMeta.name = theme.name;
  }
  yield put(
    setProjectConfigAction({
      themeOptions: {
        id: theme.id,
        options: themeData.pages[0].options
      },
      themeMeta,
      changeMade: true
    })
  );
}

export function* remove(params) {
  let { section } = params.data;

  try {
    let { templateUsed } = yield select(getConfig);
    let { dataOptions } = yield select(getProjectConfig);
    dataOptions = cloneDeep(dataOptions);

    if (dataOptions[section]) {
      if (
        (templateUsed === 'carousel' || templateUsed === 'tabs' || templateUsed === 'dropdown') &&
        dataOptions.length > 1
      ) {
        dataOptions = dataOptions.filter((d, index) => {
          return index !== section;
        });
      } else {
        if (dataOptions[section].chart_id) delete dataOptions[section].chart_id;
        if (dataOptions[section].hash) delete dataOptions[section].hash;
        if (dataOptions[section].version) delete dataOptions[section].version;
        if (dataOptions[section].image) delete dataOptions[section].image;
      }
    }

    yield all([
      put(
        setProjectConfigAction({
          changeMade: true,
          dataOptions
        })
      )
    ]);
  } catch (e) {
    console.log(e);
  }
}

export function* removeChartFromDataOptions(params) {
  let { index } = params.data;
  let { dataOptions } = yield select(getProjectConfig);
  let newDataOptions = dataOptions.filter((d, i) => i !== index);

  if (newDataOptions.length === 0) {
    newDataOptions = [{}];
  }

  yield all([
    put(
      setProjectConfigAction({
        dataOptions: newDataOptions,
        changeMade: true
      })
    )
  ]);
}

export function* getFonts(params) {
  let { team } = params.data;
  let data = yield call(getTeamFontsWithTeamid, team.id);

  yield put(
    setAction({
      customFonts: data
    })
  );
}

export function* getLogos(params) {
  let { team } = params.data;
  let data = yield call(getTeamLogosWithTeamid, team.id);

  let parsedData = data.map((d) => {
    return {
      label: d.name,
      value: window.hcconfig.frontend.hostname + '/logos/' + d.uuid + '/logo.png'
    };
  });

  parsedData.unshift({ label: 'None', value: '' });
  yield put(
    setAction({
      logos: parsedData
    })
  );
}

export function* toggleCMSModal(params) {
  let { toggle } = params.data;
  yield put(
    setAction({
      cmsModalOpen: toggle
    })
  );
}

/** Watch functions */

export function* watchSetPageTemplate() {
  yield takeEvery(actionTypes.layoutEditor.setPageTemplate, setPageTemplate);
}

export function* watchSaveLayout() {
  yield takeEvery(actionTypes.layoutEditor.saveLayout, saveLayout);
}

export function* watchLoadLayout() {
  yield takeEvery(actionTypes.layoutEditor.loadLayout, loadLayout);
}

export function* watchAddChartToGrid() {
  yield takeEvery(actionTypes.layoutEditor.addChartToGrid, addChartToGrid);
}

export function* watchAddImageToGrid() {
  yield takeEvery(actionTypes.layoutEditor.addImageToGrid, addImageToGrid);
}

export function* watchAddToGrid() {
  yield takeEvery(actionTypes.layoutEditor.addToGrid, addToGrid);
}
export function* watchChangeTitleText() {
  yield takeEvery(actionTypes.layoutEditor.changeTitleText, changeTitleText);
}

export function* watchUpdateCustomizedProp() {
  yield takeEvery(actionTypes.layoutEditor.updateCustomizedProp, updateCustomizedProp);
}
export function* watchUpdateDataProp() {
  yield takeEvery(actionTypes.layoutEditor.updateDataProp, updateDataProp);
}

export function* watchPublishLayout() {
  yield takeEvery(actionTypes.layoutEditor.publishLayout, publishLayout);
}

export function* watchAssignTheme() {
  yield takeEvery(actionTypes.layoutEditor.assignTheme, assignTheme);
}

export function* watchRemoveChartFromDataOptions() {
  yield takeEvery(actionTypes.layoutEditor.removeChartFromDataOptions, removeChartFromDataOptions);
}

export function* watchGetFonts() {
  yield takeEvery(actionTypes.layoutEditor.getFonts, getFonts);
}

export function* watchGetLogos() {
  yield takeEvery(actionTypes.layoutEditor.getLogos, getLogos);
}

export function* watchToggleCMSModal() {
  yield takeEvery(actionTypes.layoutEditor.toggleCMSModal, toggleCMSModal);
}

export function* watchInitEditor() {
  yield takeEvery(actionTypes.layoutEditor.initEditor, initEditor);
}

export function* watchRemove() {
  yield takeEvery(actionTypes.layoutEditor.remove, remove);
}

export default function* rootSaga() {
  yield all([
    watchInitEditor(),
    watchSetPageTemplate(),
    watchSaveLayout(),
    watchLoadLayout(),
    watchAddChartToGrid(),
    watchAddImageToGrid(),
    watchAddToGrid(),
    watchChangeTitleText(),
    watchUpdateCustomizedProp(),
    watchUpdateDataProp(),
    watchPublishLayout(),
    watchAssignTheme(),
    watchRemoveChartFromDataOptions(),
    watchGetFonts(),
    watchGetLogos(),
    watchToggleCMSModal(),
    watchRemove()
  ]);
}
