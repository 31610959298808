export const defaultTableOptions = {
  rows: {
    stripes: true
  },
  columns: [
    { type: 'data', visible: true },
    { type: 'data', visible: true },
    { type: 'data', visible: true }
  ]
};

export const defaultData = [
  ['A', 'B', 'C'],
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9']
];

export default {
  themes: [],
  embedDetails: {},
  bufferDataOptions: [],
  searchDataIndex: [],
  page: 0,
  searchValue: '',
  settingsStyle: {},
  cellSettings: {
    row: 0,
    column: 0
  },
  activeCellColumn: null,
  activeCellRow: null,
  activeBufferCell: null,
  activeSelectedBufferCell: null,
  rowSettings: null,
  columnSettings: null,
  shouldUpdateTableComponent: true,
  isGSheet: false,
  isInDragMode: false,
  pageAmount: 1,
  inSelectMode: false,
  isInHeaderSelectMode: false,
  showContextMenu: false,
  contextMenuPos: null,
  contextMenuCell: null,
  defaultCustomizeOptions: defaultTableOptions,
  defaultAssignOption: {
    values: {
      value: 'A',
      rawValue: [0],
      isData: true,
      canDelete: true
    }
  },
  defaultAssignRawOptions: [
    {
      values: {
        value: 'A',
        rawValue: [0],
        isData: true,
        canDelete: true
      }
    },
    {
      values: {
        value: 'B',
        rawValue: [1],
        isData: true,
        canDelete: true
      }
    },
    {
      values: {
        value: 'C',
        rawValue: [2],
        isData: true,
        canDelete: true
      }
    }
  ],
  defaultDataOptions: defaultData
};
