import classNames from 'classnames';
import { PlanPeriod, SubscriptionPlan, SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanById, getPlanDetailsByNameAndPeriod, standardFeatures } from 'pages/SubscriptionPage/utils/plansHelper';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import Branding from 'static/images/branding.svg';
import { plansCardsData } from '../meta/plansCardsData';
import PlanCard from './PlanCard';

type PlansTableProps = {
  pendingPlan: SubscriptionPlan | undefined;
  showPlanCheckout: (planCode: number) => void;
  showFree?: boolean;
  toggleContactUsModal: () => void;
};

export default function PlansTable(props: PlansTableProps) {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [planPeriod, setPlanPeriod] = useState(PlanPeriod.Yearly);
  const { subscription } = useSelector((state: RootState) => state.profile);
  const { showPlanCheckout } = props;
  const currentPlan = getPlanById(subscription?.id);
  const isFreePlan = currentPlan === SubscriptionPlans.Free;

  const plansTableContainerClasses = classNames(
    'bg-white ev-sm-max:-mx-8 ev-sm-max:py-8 py-16 ev-md:px-32 shadow-ev-standard'
  );

  const planCardsWrapperClasses = classNames('flex flex-wrap gap-9 justify-center items-end mx-auto mb-6', {
    'max-w-[648px] ev-xl:max-w-[1280px]': isFreePlan
  });

  const openCheckout = (planCode: number) => {
    history.push({ search: `?plan_id=${planCode}` });
    showPlanCheckout(planCode);
  };

  const checkIfDowngraded = (planCard: SubscriptionPlans) =>
    (planCard === SubscriptionPlans.Starter &&
      (currentPlan === SubscriptionPlans.Business || currentPlan === SubscriptionPlans.Enterprise)) ||
    (planCard === SubscriptionPlans.Business && currentPlan === SubscriptionPlans.Enterprise) ||
    (planCard === SubscriptionPlans.Pro && currentPlan === SubscriptionPlans.Enterprise);

  const handlePlanCardClick = (planCard: SubscriptionPlans) => {
    if (
      planCard === SubscriptionPlans.Starter ||
      planCard === SubscriptionPlans.Business ||
      planCard === SubscriptionPlans.Pro
    ) {
      openCheckout(getPlanDetailsByNameAndPeriod(planCard, planPeriod)?.id);
    } else if (planCard === SubscriptionPlans.Enterprise) {
      props.toggleContactUsModal();
    }
  };

  const filterPlans = () => {
    const plansToDeleteLookup = {
      [SubscriptionPlans.Starter]: [SubscriptionPlans.Business],
      [SubscriptionPlans.Business]: [SubscriptionPlans.Starter, SubscriptionPlans.Pro]
    };

    const plansToDelete = plansToDeleteLookup[currentPlan as keyof typeof plansToDeleteLookup] || [
      SubscriptionPlans.Free,
      SubscriptionPlans.Starter,
      SubscriptionPlans.Business,
      SubscriptionPlans.ProTrial
    ];

    const filteredPlansData = Object.fromEntries(
      Object.entries(plansCardsData).filter(([plan]) => !plansToDelete.includes(plan as SubscriptionPlans))
    );

    return filteredPlansData;
  };

  const filteredPlansCardsData = filterPlans();

  return (
    <div id="plans" className={plansTableContainerClasses}>
      <div className="mb-14">
        {props.showFree && (
          <div className="free-info">
            <p className="text-center mb-6">
              Getting started using everviz for <b>free</b> with all standard features now - no credit card needed.
            </p>
            <a className="btn bulk-action-btn btn-primary everviz-red" href="https://app.everviz.com/new">
              Start for free
            </a>
          </div>
        )}

        <div className={planCardsWrapperClasses}>
          {Object.entries(filteredPlansCardsData).map(([plan, { description, features }]) => (
            <PlanCard
              key={plan}
              plan={plan as SubscriptionPlans}
              period={planPeriod}
              description={description}
              onClick={() => handlePlanCardClick(plan as SubscriptionPlans)}
              isActive={plan === currentPlan}
              isHidden={!isFreePlan && plan === SubscriptionPlans.Free}
              isDowngraded={checkIfDowngraded(plan as SubscriptionPlans)}
              pendingPlan={props.pendingPlan}
              features={features}
            />
          ))}
        </div>

        <p className="contact text-center mt-3 px-8">
          We also offer discounts for non-profit organizations. Email{' '}
          <a title="email sales" href="mailto:sales@everviz.com">
            sales@everviz.com
          </a>{' '}
          for more information
        </p>

        {subscription && subscription.id === 1 && (
          <p onClick={() => showPlanCheckout(17)} className="text-center mt-3 mb-8 clickable underline">
            Taking a break from everviz? You can retain your projects beyond 6 months
            <br /> by signing up to our Storage plan here
          </p>
        )}
      </div>

      <div className="mb-14 mx-16" id="standard-features">
        <h2 className="text-center font-semibold mb-10">Standard features</h2>
        <div className="grid ev-sm:grid-cols-2 ev-md:grid-cols-3 gap-4">
          {standardFeatures.map((feature, i) => {
            return (
              <div className="flex py-2" key={i}>
                <div className={`text-white ${feature.size}`}>
                  <i
                    className={`fa fa-${feature.icon} flex items-center justify-center w-14 h-14 bg-ev-navy-blue rounded-full`}
                  />
                </div>
                <div className="ml-5 -mt-1">
                  <div className="font-bold text-base mb-2">{feature.header}</div>
                  <div className="">{feature.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="px-4">
        <div className="flex mt-8 ev-sm-max:flex-col">
          <Branding className="ev-sm:w-3/6 p-2" />

          <div className="flex-1 px-4" id="branding">
            <h3 className="font-bold mb-6 mt-0">Incorporate your branding</h3>
            <p>
              everviz comes with a simple and powerful theming concept which allows you to incorporate your corporate
              brand elements such as color palette, fonts, styling and logos into your projects.
            </p>
          </div>
        </div>

        <div className="flex mt-8 ev-sm-max:flex-col">
          <div className="ev-sm:w-3/6 px-4" id="branding">
            <div className={'flex items-center justify-center mb-6'}>
              <i
                className={
                  'fas fa-share-nodes flex items-center justify-center w-16 h-16 bg-ev-navy-blue-2 text-white text-3xl rounded-full'
                }
              />
            </div>
            <h3 className="font-bold mb-6 mt-0">Multiplatform publishing</h3>
            <p>
              Charts optimized for viewing across all publication destinations. Chart elements including font scale
              automatically.
            </p>
          </div>

          <div className="flex items-center justify-center ev-sm:w-7/12 p-2">
            <img alt="multiplatform" src="https://app.everviz.com/static/images/multiplatform.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}
