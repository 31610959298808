import React from 'react';

export default function ResizeVerticalIcon() {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.41719 14C7.1933 14 6.96848 13.9146 6.79758 13.7437L4.82883 11.7749C4.63359 11.6047 4.54883 11.3805 4.54883 11.1562C4.54883 10.6564 4.95762 10.2812 5.42383 10.2812C5.64772 10.2812 5.87172 10.3667 6.04234 10.5376L6.51758 11.0141L6.51758 2.98867L6.04234 3.46391C5.87227 3.63398 5.64805 3.71875 5.42383 3.71875C4.95762 3.71875 4.54883 3.34332 4.54883 2.84375C4.54883 2.61986 4.63428 2.39586 4.80518 2.22523L6.77393 0.256484C6.94414 0.0854491 7.16836 -1.34105e-07 7.41719 -1.23229e-07C7.66602 -1.12352e-07 7.86398 0.0854491 8.03488 0.256348L10.0036 2.2251C10.1516 2.39613 10.2363 2.6198 10.2363 2.84375C10.2363 3.34359 9.82754 3.71875 9.36133 3.71875C9.13744 3.71875 8.91344 3.6333 8.74281 3.4624L8.26758 2.98867L8.26758 11.0141L8.74281 10.5388C8.91289 10.366 9.13711 10.2812 9.36133 10.2812C9.82754 10.2812 10.2363 10.6567 10.2363 11.1562C10.2363 11.3801 10.1509 11.6041 9.97998 11.7748L8.01123 13.7435C7.84101 13.9152 7.6168 14 7.41719 14Z"
        fill="currentColor"
      />
      <path
        d="M10.6738 5.03125C10.6738 5.25514 10.7593 5.47914 10.9302 5.64977L11.4039 6.125L9.26758 6.125L9.26758 7.875L11.4039 7.875L10.9287 8.35023C10.7586 8.52031 10.6738 8.74453 10.6738 8.96875C10.6738 9.46859 11.0826 9.84375 11.5488 9.84375C11.7727 9.84375 11.9967 9.7583 12.1673 9.5874L14.1361 7.61865C14.3071 7.44844 14.3926 7.22422 14.3926 7C14.3926 6.77578 14.3071 6.55156 14.1362 6.38203L12.1675 4.41328C11.9964 4.24102 11.7728 4.15625 11.5488 4.15625C11.0812 4.15625 10.6738 4.53086 10.6738 5.03125ZM4.11133 8.96875C4.11133 8.74486 4.02588 8.52086 3.85498 8.35023L3.37852 7.875L5.51758 7.875L5.51758 6.125L3.37852 6.125L3.85375 5.64977C4.02656 5.47969 4.11133 5.25547 4.11133 5.03125C4.11133 4.53141 3.70254 4.15625 3.23633 4.15625C3.01244 4.15625 2.78844 4.2417 2.61781 4.4126L0.649063 6.38135C0.477344 6.55156 0.392578 6.77578 0.392578 7C0.392578 7.22422 0.478027 7.44789 0.648926 7.61851L2.61768 9.58726C2.78789 9.75898 3.01211 9.84375 3.23633 9.84375C3.70391 9.84375 4.11133 9.46914 4.11133 8.96875Z"
        fill="#AEC8FA"
      />
    </svg>
  );
}
