import { ProviderTypes } from 'pages/ChartEditorPage/meta/templates/types';
import { parseCSV } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import { addPointAction, deletePointAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { addLocationMapMarkerAction, removeLocationMapMarkerAction } from 'pages/ChartEditorPage/actions/locationMap';

const extractPointForLocationMap = (data: any) => {
  if (data) {
    const result: string[] = [];

    for (let i = 1; i < data.length; i++) {
      // Start at 1 to skip the header
      const subArray = data[i];
      if (subArray.length >= 3) {
        result.push(subArray[2]);
      }
    }
    return result;
  }
  return [];
};

const extractPointForHighcharts = (data: any) => {
  if (data) {
    const parsedCSV = parseCSV(data, ';', null) as [number, number, string][];
    if (parsedCSV) {
      const c = parsedCSV.map((csvRecord) => csvRecord[2]);
      c.shift();
      return c;
    }
  }
  return [];
};

export const extractPointMap = {
  locationMap: extractPointForLocationMap,
  highcharts: extractPointForHighcharts
};

export const handlerMap: Record<string, any> = {
  locationMap: {
    addPoint: addLocationMapMarkerAction,
    deletePoint: removeLocationMapMarkerAction
  },
  highcharts: {
    addPoint: addPointAction,
    deletePoint: deletePointAction
  }
};

export const extractPoints = (provider: ProviderTypes, data: any) => {
  return extractPointMap[provider](data);
};
