import React, { useEffect } from 'react';
import './../../../../node_modules/react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  addColumnAction,
  addRowAction,
  deleteColumnAction,
  deleteRowAction,
  hideContextMenuAction
} from '../actions/tableEditor';

export default function ContextMenu() {
  const dispatch = useDispatch();
  const { dataOptions, customizedOptions } = useSelector((state) => state.projectConfig);
  const { showContextMenu, contextMenuPos, contextMenuCell } = useSelector((state) => state.tableEditorPage);

  useEffect(() => {
    const elem = document.activeElement,
      contextMenu = document.querySelector('.table-context-menu');

    if (contextMenu && elem.className !== contextMenu.className) {
      contextMenu.focus();
    }
  }, [showContextMenu]);

  const deleteOption = (e) => {
    const target = e.currentTarget;
    const type = target.getAttribute('data-value');
    if (type === 'column') {
      deleteColumn(dataOptions, customizedOptions, contextMenuCell[0]);
    } else deleteRow(dataOptions, customizedOptions, contextMenuCell[1]);
  };

  const add = (e) => {
    const target = e.currentTarget;
    const type = target.getAttribute('data-value');
    const dir = target.getAttribute('data-dir');
    if (type === 'column') {
      addColumn(dataOptions, customizedOptions, contextMenuCell[0], dir);
    } else addRow(dataOptions, customizedOptions, contextMenuCell[1], dir);
  };

  const deleteColumn = (dataOptions, customizedOptions, index) =>
    dispatch(deleteColumnAction({ dataOptions, customizedOptions, index }));
  const deleteRow = (dataOptions, customizedOptions, index) =>
    dispatch(deleteRowAction({ dataOptions, customizedOptions, index }));
  const addColumn = (dataOptions, customizedOptions, index, dir) =>
    dispatch(addColumnAction({ dataOptions, customizedOptions, index, dir }));
  const addRow = (dataOptions, customizedOptions, index, dir) =>
    dispatch(addRowAction({ dataOptions, customizedOptions, index, dir }));
  const hideContextMenu = () => dispatch(hideContextMenuAction({}));

  return (
    <div
      className="table-context-menu"
      onBlur={hideContextMenu}
      tabIndex={0}
      style={{ left: contextMenuPos[0], top: contextMenuPos[1] }}
    >
      <div onClick={add} data-value="row" data-dir="before">
        {' '}
        Add new row before
      </div>
      <div onClick={add} data-value="row" data-dir="after">
        {' '}
        Add new row after
      </div>
      <div onClick={deleteOption} data-value="row">
        {' '}
        Delete row{' '}
      </div>
      <div onClick={add} data-value="column" data-dir="before">
        {' '}
        Add new column before{' '}
      </div>
      <div onClick={add} data-value="column" data-dir="after">
        {' '}
        Add new column after{' '}
      </div>
      <div onClick={deleteOption} data-value="column">
        {' '}
        Delete column{' '}
      </div>
    </div>
  );
}
