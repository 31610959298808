import React from 'react';
import Button from '../buttons/Button';
import ColorWidget from './ColorWidget';

class ChartArrayWidget extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.addNewChart = this.addNewChart.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.removeChartFromDataOptions = this.removeChartFromDataOptions.bind(this);
    this.state = {};
  }

  addNewChart() {
    let length = this.props.dataOptions.length;
    const prevProp = this.props.dataOptions[this.props.dataOptions.length - 1];
    if (!prevProp.hash) {
      length -= 1;
    }
    this.props.toggleChartModal(length);
  }

  onInputChange(e) {
    this.props.onDataChange(e.target.value, e.target.getAttribute('index'), 'name');
  }

  removeChartFromDataOptions(e) {
    this.props.removeChartFromDataOptions(parseInt(e.target.getAttribute('data-value'), 10));
  }

  onChange(field, newValue) {
    this.props.onChange({
      val: newValue.val,
      optional: {
        id: `${this.props.option.id}.style.${field}`
      }
    });
  }

  render() {
    const { dataOptions, aggregatedOptions, templateUsed } = this.props;
    const style = (aggregatedOptions && aggregatedOptions.layout && aggregatedOptions.layout.style) || {};
    return (
      <div className={'chart-array-widget horizontal'}>
        <div className="charts-container">
          {(this.props.dataOptions || []).map((chart, index) => {
            if (!chart.hash) return <div className="no-charts-added"> No Visualizations Added</div>;
            return (
              <div className="chart-container" key={`viz_${index}`}>
                <img src={window.hcconfig.frontend.hostname + '/thumbnails/' + chart.hash + '.png'} />
                {this.props.option?.editableName && (
                  <div>
                    <input
                      className="form-control"
                      onChange={this.onInputChange}
                      value={dataOptions[index].hasOwnProperty('name') ? dataOptions[index].name : 'Tab ' + (index + 1)}
                      index={index}
                    />
                  </div>
                )}
                <span className="times-container" onClick={this.removeChartFromDataOptions} data-value={index}>
                  <i className="fa fa-times" data-value={index} />
                </span>
              </div>
            );
          })}
        </div>
        <div>
          <Button buttonText="Add visualization" buttonclassName="" onClick={this.addNewChart} />
        </div>
        {templateUsed === 'tabs' && (
          <div className="flex flex-col gap-2 mt-2">
            <ColorWidget
              onChange={(newValue) => this.onChange('backgroundColor', newValue)}
              value={style.backgroundColor || ''}
              label={'Button color'}
            />
            <ColorWidget
              onChange={(newValue) => this.onChange('color', newValue)}
              value={style.color || ''}
              label={'Text color'}
            />
            <ColorWidget
              onChange={(newValue) => this.onChange('activeButtonColor', newValue)}
              value={style.activeButtonColor || ''}
              label={'Active button color'}
            />
            <ColorWidget
              onChange={(newValue) => this.onChange('activeColor', newValue)}
              value={style.activeColor || ''}
              label={'Active text color'}
            />
            <ColorWidget
              onChange={(newValue) => this.onChange('borderColor', newValue)}
              value={style.borderColor || ''}
              label={'Border color'}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ChartArrayWidget;
