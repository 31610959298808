import { updateChartCategoriesAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React from 'react';
import { useDispatch } from 'react-redux';
import CustomSelect, { customSelectColors, SelectItem } from 'shared/inputs/CustomSelect';
import { SeriesAssigns } from 'shared/types/commonPropTypes';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import useCategoriesAndSeries from '../utils/useCategoriesAndSeries';

type CategoriesTabProps = {
  assignOption: SeriesAssigns;
  setActiveColumns: (indexes: number[], series: number[]) => void;
  onMouseLeave: () => void;
  seriesAssignsIndex: number;
};

const CategoriesTab = (props: CategoriesTabProps) => {
  const dispatch = useDispatch();
  const { columns, categoriesLabels } = useCategoriesAndSeries(props.seriesAssignsIndex);

  const handleCategoriesChange = (selected: SelectItem | null) => {
    dispatch(updateChartCategoriesAction({ newCategories: selected?.value, newIndex: selected?.index }));
  };

  const onMouseEnter = (index: number) => () => props.setActiveColumns([index], [-1]);

  const options: SelectItem[] = columns.map((val, index) => {
    return { label: `Column ${val}`, value: val, index };
  });

  return (
    <>
      {Object.values(props.assignOption ?? {}).map((value: any, i) => {
        if (!value.isLabel || value.hidden) return null;

        return (
          <div
            key={`category_${i}`}
            onMouseEnter={onMouseEnter(categoriesLabels[value.name].index as number)}
            onMouseLeave={props.onMouseLeave}
          >
            <CustomSelect
              styles={editorStyles}
              backgroundColor={customSelectColors.grey}
              label={value.name ?? 'Categories'}
              labelClasses="text-base"
              options={options}
              value={categoriesLabels[value.name]}
              onChange={handleCategoriesChange}
              isDisabled={value.locked}
              isOptionDisabled={(value) => Boolean(value.disabled)}
            />
          </div>
        );
      })}
    </>
  );
};

export default CategoriesTab;
