import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Timeline: TemplateProps = {
  title: 'Timeline',
  description: '',
  constructor: '',
  thumbnail: 'timeline-dots.png',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'timeline'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      }
    },
    tooltip: {
      headerFormat: '{point.x}<br/>'
    },
    xAxis: {
      type: 'datetime',
      visible: false
    },
    yAxis: {
      visible: false
    },
    legend: {
      enabled: false
    }
  },
  dataValidator: false,
  sampleSet: 'timeline',
  plugins: ['timeline'],
  advancedParent: 'Timeline',
  basic: {
    order: 30,
    thumbnail: 'timeline.svg',
    icon: 'Timeline'
  },
  advanced: {
    name: 'Standard'
  },
  parseData: 'timeline',
  parent: 'Timeline',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter)
};

export default Timeline;
