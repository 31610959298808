import { SubscriptionPlans } from '../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../types';

const PointMap: TemplateProps = {
  title: 'Point map',
  description: [
    'Point maps can be used to show the distribution of data over a geographical region, by placing equally sized markers over the region.'
  ],
  constructor: 'Map',
  thumbnail: 'mappoint.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Starter),
  config: {
    chart: {},
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    xAxis: {
      visible: false
    },
    plotOptions: {
      series: {
        lineWidth: 0,
        borderWidth: 1,
        marker: {
          enabled: true
        },
        fillColor: 'black'
      }
    },
    yAxis: {
      visible: false
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        type: 'map'
      },
      {
        joinBy: 'hc-key',
        type: 'mappoint'
      }
    ]
  },
  sampleSet: 'mappoint',
  dataValidator: false,
  loadForEachSeries: false,
  defaultDataType: 'pointMap',
  isMap: true,
  advancedParent: 'Point map',
  basic: {
    order: 7,
    thumbnail: 'point_map.svg',
    icon: 'PointMap'
  }
};

export default PointMap;
