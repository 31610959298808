import { TemplateProps } from '../../types';

const Spline: TemplateProps = {
  title: 'Spline',
  description: '',
  constructor: '',
  thumbnail: 'upafes.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'spline',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'line',
  advancedParent: 'Line',
  dataValidator: false
};

export default Spline;
