import React, { ReactNode } from 'react';

type TypeProps = 'success' | 'info' | 'warning' | 'danger';

type AlertMessageProp = {
  type: TypeProps;
  text: ReactNode | string;
  tag?: string;
  dismissable?: boolean;
  extraClass?: string;
  hideTag?: boolean;
};

const AlertMessage = ({ type, text, tag, dismissable = true, extraClass = '', hideTag = false }: AlertMessageProp) => {
  if (['success', 'info', 'warning', 'danger'].indexOf(type) < 0) {
    type = 'info';
  }

  if (!tag) {
    tag = type.charAt(0).toUpperCase() + type.slice(1);
  }

  const dismiss = (event: React.MouseEvent) => {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const alert = target.parentNode;
    if (alert?.parentNode) {
      alert.parentNode.removeChild(alert);
    }
  };

  const colorMap: Record<any, any> = {
    warning: 'bg-ev-yellow-4 text-black',
    danger: 'bg-ev-red text-white'
  };

  const color = colorMap[type] ?? '';

  return (
    <div className={`alert alert-${type} ${dismissable ? ' alert-dismissable' : ''} ${color} ${extraClass} `}>
      {dismissable && (
        <a href="#" onClick={dismiss} className="close" data-dismiss="alert" aria-label="close">
          &times;
        </a>
      )}
      {!hideTag && (
        <span>
          <strong>{tag}</strong>:{' '}
        </span>
      )}{' '}
      {text}
    </div>
  );
};

export default AlertMessage;
