import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import SeparatorWidget from 'shared/widgets/SeparatorWidget';

export default function CustomizePanelOptions({
  switchActiveSection,
  activeSection,
  renderCondition
}: {
  switchActiveSection: (type: string) => void;
  activeSection: string;
  renderCondition: any;
}) {
  const { inspector, isThemeEditor } = useSelector<any, any>((state: RootState) => state.chartEditorPage);

  const toggleInspectorMode = () => {
    const nextActiveSection = activeSection === 'inspector' ? 'advanced' : 'inspector';
    const toggleInspectorMode = activeSection !== 'inspector';
    inspector.toggleActive(toggleInspectorMode);
    switchActiveSection(nextActiveSection);
  };

  const isBasic = activeSection === 'basic';
  const isAdvanced = activeSection === 'advanced' || activeSection === 'inspector';
  const buttonsShownCount = Object.values(renderCondition).filter((item) => item === true).length;
  return (
    <div className="highed-icons-container customize-icons mt-2 hidden ev-sm:block">
      {Boolean(buttonsShownCount) && (
        <div className="flex gap-4 w-[332px]">
          <div className="w-3/5 flex gap-2">
            <PrimaryButton
              text="Basic"
              onClick={() => switchActiveSection('basic')}
              buttonColor={isBasic ? ButtonColor.NavyBlue : ButtonColor.White}
              buttonType={ButtonType.Info}
              className={`grow text-sm ${isBasic ? '' : 'font-medium underline'}`}
            />
            {renderCondition.advanced && (
              <PrimaryButton
                text="Advanced"
                onClick={() => switchActiveSection('advanced')}
                buttonColor={isAdvanced ? ButtonColor.NavyBlue : ButtonColor.White}
                buttonType={ButtonType.Info}
                className={`grow text-sm ${isAdvanced ? '' : 'font-medium underline'}`}
              />
            )}
          </div>

          {!isThemeEditor && (
            <div className="flex gap-2 ml-auto">
              {renderCondition.inspector && (
                <IconButton
                  icon={'crosshairs'}
                  tooltipText="Inspector"
                  onClick={() => {
                    toggleInspectorMode();
                  }}
                  buttonColor={activeSection !== 'inspector' ? ButtonColor.White : ButtonColor.NavyBlue}
                  className="w-8 h-8 rounded"
                />
              )}

              {renderCondition.customCode && (
                <IconButton
                  icon={'code'}
                  tooltipText="Custom code"
                  onClick={() => switchActiveSection('customcode')}
                  buttonColor={activeSection !== 'customcode' ? ButtonColor.White : ButtonColor.NavyBlue}
                  className="w-8 h-8 rounded"
                />
              )}

              {renderCondition.preview && (
                <IconButton
                  icon={'eye'}
                  tooltipText="Preview"
                  onClick={() => switchActiveSection('preview')}
                  buttonColor={activeSection !== 'preview' ? ButtonColor.White : ButtonColor.NavyBlue}
                  className="w-8 h-8 rounded"
                />
              )}
            </div>
          )}
        </div>
      )}
      <SeparatorWidget />
    </div>
  );
}
