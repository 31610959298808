import initialState from '../../../redux/reducers/initial-state';
import actionTypes from '../../../redux/actions/action-types';

const apiPageReducer = (state = initialState.apiPage, action) => {
  switch (action.type) {
    case actionTypes.apiPage.set:
      return {
        ...state,
        ...action.data,
      };
  }
  return state;
};

export default apiPageReducer;
