import React from 'react';

const NotFound = () => {
  return (
    <div className="col-sm-12 text-center">
      <h1>404</h1>
      <p>Page not found.</p>
      <p>We're sorry, but the page you requested does not exist.</p>
    </div>
  );
};

export default NotFound;
