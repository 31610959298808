import { redrawProjectAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import { getClassesForButtonColor } from '../buttons/utils/buttonHelper';
import FieldContainer from './FieldContainer';

const redrawProjectMap = {
  highcharts: redrawProjectAction,
  locationMap: () => null
};

export default (props: Partial<GenericInputProps & { icon?: string; onClick?: () => void }>) => {
  const dispatch = useDispatch();
  const { provider } = useSelector((state: RootState) => state.projectConfig);

  const handleOnClick = () => {
    if (props.onClick) props.onClick();
    else dispatch(redrawProjectMap[provider]({}));
  };

  return (
    <FieldContainer
      onClick={handleOnClick}
      label={props.label}
      className={`cursor-pointer transition-colors ${getClassesForButtonColor(ButtonColor.NavyBlue)}`}
    >
      <IconButton
        icon={props.icon ?? 'play'}
        onClick={handleOnClick}
        className="rounded-full w-6 h-6 shrink-0 content-centered"
        buttonColor={ButtonColor.White}
        iconClass="content-centered"
      />
    </FieldContainer>
  );
};
