import Line from './charts/Line';
import Area from './charts/Area';
import AreaRange from './charts/AreaRange';
import Pie from './charts/Pie';
import Bar from './charts/Bar';
import Column from './charts/Column';
import Scatter from './charts/Scatter';
import Bubble from './charts/Bubble';
import Sankey from './charts/Sankey';
import DependencyWheel from './charts/DependencyWheel';
import Item from './charts/Item';
import Parliament from './charts/Parliament';
import Polar from './charts/Polar';
import Boxplot from './charts/Boxplot';
import Dumbbell from './charts/Dumbbell';
import Waterfall from './charts/Waterfall';
import Funnel from './charts/Funnel';
import Heatmap from './charts/Heatmap';
import Timeline from './charts/Timeline';
import ColumnRange from './charts/ColumnRange';
import ErrorBar from './charts/ErrorBar';
import Negative from './charts/Negative';
import SolidGauge from './charts/SolidGauge';

import Wordcloud from './charts/Wordcloud';
import Treemap from './charts/Treemap';
import PackedBubble from './charts/PackedBubble';

import StockBasic from './stock/Basic';
import StockLine from './stock/Line';
import OHLC from './stock/OHLC';

import Choropleth from './maps/Choropleth';
import MapCategory from './maps/Category';
import BubbleMap from './maps/BubbleMap';
import TilemapCircle from './maps/TilemapCircle';
import Honeycomb from './maps/Honeycomb';
import PatternFill from './maps/PatternFill';
import PointMap from './maps/PointMap';
import LocationMap from './maps/LocationMap';

export default {
  line: Line,
  area: Area,
  arearange: AreaRange,
  pie: Pie,
  bar: Bar,
  column: Column,
  scatter: Scatter,
  bubble: Bubble,
  sankey: Sankey,
  solidgauge: SolidGauge,
  dependencywheel: DependencyWheel,
  item: Item,
  parliament: Parliament,
  polar: Polar,
  boxplot: Boxplot,
  dumbbell: Dumbbell,
  waterfall: Waterfall,
  funnel: Funnel,
  wordcloud: Wordcloud,
  treemap: Treemap,
  packedbubble: PackedBubble,
  heatmap: Heatmap,
  timeline: Timeline,
  columnrange: ColumnRange,
  errorbar: ErrorBar,
  negative: Negative,

  'stock-basic': StockBasic,
  'stock-line': StockLine,
  'stock-ohlc': OHLC,

  choropleth: Choropleth,
  categoriesMap: MapCategory,
  bubbleMap: BubbleMap,
  tilemapCircle: TilemapCircle,
  honeycomb: Honeycomb,
  patternfill: PatternFill,
  pointMap: PointMap,
  locationMap: LocationMap
};
