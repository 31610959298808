import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationMapContainerRef } from '@visual-elements/location-map';
import { RootState } from '../../store';

export type LocationMapInstanceState = {
  editorMapRef: LocationMapContainerRef | null;
  previewMapRef: LocationMapContainerRef | null;
};

const initialState: LocationMapInstanceState = {
  editorMapRef: null,
  previewMapRef: null
};

const locationMapInstanceSlice = createSlice({
  name: 'locationMapInstance',
  initialState,
  reducers: {
    storeLocationMapRef(
      state,
      action: PayloadAction<{
        editorMapRef: LocationMapContainerRef;
        previewMapRef: LocationMapContainerRef;
      }>
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      state.editorMapRef = action.payload.editorMapRef;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      state.previewMapRef = action.payload.previewMapRef;
    }
  }
});

export const selectEditorMapRef = (state: RootState) => state.locationMapInstance.editorMapRef;
export const selectPreviewMapRef = (state: RootState) => state.locationMapInstance.previewMapRef;

export const { storeLocationMapRef } = locationMapInstanceSlice.actions;

export default locationMapInstanceSlice.reducer;
