import React from 'react';
import ContentEditable from 'react-contenteditable';
import './../../../../node_modules/react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAggregatedOptions } from '../selectors/tableEditor';
import { changeTitleTextAction } from '../../LayoutEditorPage/actions/layoutEditor';

export default function Text(props) {
  const { type, placeholder, containerClass, inputClass } = props;
  const dispatch = useDispatch();
  const { customizedOptions } = useSelector((state) => state.projectConfig);
  const aggregatedOptions = useSelector((state) => getAggregatedOptions(state.projectConfig));

  const style = aggregatedOptions[type] ? aggregatedOptions[type].style : null;

  const noText = !(aggregatedOptions?.[type]?.text !== '');
  const disabled = noText && !props.children;

  const changeText = (e) => {
    dispatch(changeTitleTextAction({ event: e, customizedOptions, type }));
  };

  return (
    <div className={`${containerClass}-container ${disabled ? ' hidden' : ''}`} style={style}>
      <div className={`layout-${type}-container `}>
        <div className="page-caption">
          <ContentEditable
            className={`caption ${inputClass}`}
            placeholder={placeholder}
            html={aggregatedOptions?.[type]?.text || ''}
            onChange={changeText}
            suppressContentEditableWarning={true}
            style={style}
          />
        </div>
      </div>
      {props.children}
    </div>
  );
}
