import React from 'react';
import defaultThemes from './../../../shared/editor/DefaultThemes';
import Theme from './../components/Theme';
import paths from 'paths';
import Subheader from 'shared/profile/Subheader';
import ProfilePageSectionDescription from 'shared/profile/ProfilePageSectionDescription';

type DefaultThemesSectionProps = {
  deleted?: boolean;
  loadThemes: () => void;
  hasPermission: () => void;
  checkHasPermission: (e: any) => void;
  showPaidPlanModal: () => void;
};

export default function DefaultThemesSection(props: DefaultThemesSectionProps) {
  const { loadThemes, checkHasPermission, hasPermission, showPaidPlanModal } = props;

  (defaultThemes || []).forEach((theme: any) => {
    const editLink = paths.newTheme;
    const options = {
      pathname: editLink,
      state: {
        defaultThemeDetails: {
          id: theme.id,
          name: theme.name,
          options: theme.template,
          customCode: theme.customCode.join('')
        }
      }
    };

    theme.href = options;
  });

  return (
    <>
      <Subheader>Standard themes</Subheader>
      <ProfilePageSectionDescription>
        Test out themes by applying a pre-built theme to your project.
      </ProfilePageSectionDescription>
      <div className="project-grid">
        {defaultThemes.map((theme) => (
          <Theme
            key={theme.id}
            theme={theme}
            refresh={loadThemes}
            isDefaultTheme={true}
            hasPermission={hasPermission}
            checkHasPermission={checkHasPermission}
            showPaidPlanModal={showPaidPlanModal}
          />
        ))}
      </div>
    </>
  );
}
