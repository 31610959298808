import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setAction } from 'pages/ChartEditorPage/actions/chartEditor';
import Inline from 'pages/ChartEditorPage/middleware/ChartEditorInline';
import CustomizeOptions from 'pages/ChartEditorPage/meta/CustomizeOptions';
import { getChartConfig, setupHighchartsEvents } from '../utils/chartHelper';
import { WindowProps } from 'shared/widgets/FontStyler';
import { EvervizChart } from 'shared/types/chart';
import HighchartsReact from './HighchartsReact';
import HighchartsReactTypes from '../../types/HighchartsReact';
import highchartsErrors from './../../../editor/meta/errors';
import { removeHighchartsStyling } from 'pages/ChartEditorPage/utils/removeHighchartsStyling';

// Extend the Highcharts type definitions
declare module 'highcharts' {
  interface Chart {
    dataTableDiv?: HTMLDivElement;
  }
}

type HighchartsContainerProps = {
  className?: string;
};

const HighchartsPreview = ({ className }: HighchartsContainerProps) => {
  const { chartEditorPage: chartConfig, projectConfig } = useSelector((state: RootState) => state);
  const { aggregatedOptions } = projectConfig;
  const { constr, useInlineEditor } = chartConfig;

  const dispatch = useDispatch();
  const chartRef = useRef<HighchartsReactTypes.RefObject>(null);

  useEffect(() => {
    setupHighchartsEvents();

    return () => {
      (window as WindowProps).everviz.disconnectResizeObservers();
    };
  }, []);

  const options = useMemo(() => getChartConfig(projectConfig, chartConfig), [aggregatedOptions]);

  const callback = useCallback(
    (chart: EvervizChart) => {
      const inlineEditor = useInlineEditor ? Inline(CustomizeOptions, chart) : false;
      if (chartConfig?.inspector) {
        //@ts-expect-error ts-2339
        chartConfig.inspector.init(true);
      }

      dispatch(
        setAction({
          inlineEditor,
          editorChartIndex: chart.index
        })
      );
    },
    [options]
  );

  const Chart = (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={options}
      immutable={false}
      allowChartUpdate={true}
      constructorType={constr}
      containerProps={{ className, id: 'highcharts-container' }}
      callback={callback}
      onError={(chart: EvervizChart, code: any) => {
        const codeStr = code?.toString();
        if (codeStr?.indexOf('Highcharts error') >= 0) {
          const i1 = codeStr.indexOf('#'),
            i = codeStr.substr(i1).indexOf(':'),
            id = parseInt(codeStr.substr(i1 + 1, i), 10),
            item = highchartsErrors[id];
          if (item) {
            dispatch(
              setAction({
                editorError: `${item.title} ${item.text}`
              })
            );
          }
        }
        removeHighchartsStyling(chart);
      }}
    />
  );

  return Chart;
};
export default HighchartsPreview;
