export default [
  {
    id: 99999995,
    name: 'everviz',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-everviz.png',
    template: {
      options: {
        title: {
          enabled: true,
          style: {
            fontSize: '22px',
            fontFamily: 'Gordita Bold',
            color: '#172b4c',
          },
          text: 'Layout title',
        },
        subtitle: {
          style: {
            fontFamily: 'Gordita Regular',
            fontSize: '16px',
            color: '#172b4c',
          },
          enabled: false,
          text: 'Layout subtitle',
        },
        caption: {
          style: {
            fontSize: '12px',
            fontStyle: 'italic',
            color: '#444444',
          },
          enabled: false,
          text: 'Layout caption',
        },
      },
      cssModules: [
        'https://app.everviz.com/fonts/cAMLbHmJ2/font.css',
        'https://app.everviz.com/fonts/HPLuvkaK_/font.css',
      ],
    },
  },
  {
    id: 99999996,
    name: 'Comfort',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-comfort.png',
    template: {
      options: {
        title: {
          enabled: true,
          style: {
            color: '#333333',
            fontFamily: 'OpenSans-SemiBold',
            fontSize: '22px',
          },
          text: 'Layout title',
        },
        subtitle: {
          style: {
            fontFamily: 'OpenSans-Regular',
            fontSize: '16px',
            color: '#333333',
          },
          text: 'Layout subtitle',
          enabled: true,
        },
        caption: {
          style: {
            fontFamily: 'OpenSans-Regular',
            color: '#333333',
            fontStyle: 'italic',
            fontSize: '12px',
          },
          enabled: true,
          text: 'Layout caption',
        },
      },
      cssModules: [
        'https://app.everviz.com/fonts/b5FGmyCeZ/font.css',
        'https://app.everviz.com/fonts/SuXe00RLa/font.css',
      ],
    },
  },
  {
    id: 99999997,
    isDefault: true,
    name: 'Monochromatic',
    thumbnail_url: '/static/thumbnails/themes/theme-monochromatic.png',
    template: {
      options: {
        title: {
          enabled: true,
          style: {
            textAlign: 'left',
            fontSize: '22px',
            fontFamily: 'Inter-Regular',
            color: '#444444',
          },
          text: 'Layout title',
        },
        subtitle: {
          style: {
            textAlign: 'left',
            fontFamily: 'Inter-Regular',
            fontSize: '16px',
            color: '#444444',
          },
          text: 'Layout subtitle',
          enabled: true,
        },
        caption: {
          style: {
            fontFamily: 'Inter-Regular',
            fontStyle: 'italic',
            fontSize: '12px',
            color: '#444444',
          },
          enabled: true,
          text: 'Layout caption',
        },
      },
      cssModules: [
        'https://app.everviz.com/fonts/hCLaqXfO8/font.css',
        'https://app.everviz.com/fonts/wnDyLY2cA/font.css',
        'https://app.everviz.com/fonts/DZt9aXmCJ/font.css',
      ],
    },
  },
  {
    id: 99999998,
    name: 'Monokai',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-monokai.png',
    template: {
      options: {
        title: {
          enabled: true,
          style: {
            backgroundColor: '#2e2e2e',
            color: '#d6d6d6',
            fontFamily: 'RobotoMono-Regular',
            textAlign: 'left',
            fontSize: '22px',
          },
          text: 'Layout title',
        },
        canvas: {
          style: {
            backgroundColor: '#2e2e2e',
            paddingTop: '13px',
            paddingRight: '13px',
            paddingBottom: '13px',
            paddingLeft: '13px',
          },
        },
        subtitle: {
          style: {
            backgroundColor: '#2e2e2e',
            color: '#d6d6d6',
            fontSize: '16px',
            fontFamily: 'RobotoMono-Regular',
          },
          enabled: true,
        },
        caption: {
          style: {
            fontFamily: 'RobotoMono-Regular',
            color: '#d6d6d6',
            backgroundColor: '#2e2e2e',
            fontStyle: 'italic',
          },
          enabled: true,
        },
      },
      cssModules: [
        'https://app.everviz.com/fonts/TiSVa0aay/font.css',
        'https://app.everviz.com/fonts/DZt9aXmCJ/font.css',
      ],
    },
  },
  {
    id: 99999999,
    name: 'Google',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-google.png',
    template: {
      options: {
        title: {
          enabled: true,
          style: {
            textAlign: 'center',
            fontFamily: 'Roboto-Medium',
            fontSize: '22px',
            color: '#444444',
          },
          text: 'Layout title',
        },
        subtitle: {
          style: {
            fontFamily: 'Roboto-Regular',
            textAlign: 'center',
            fontSize: '16px',
            color: '#444444',
          },
          enabled: true,
          text: 'Layout subtitle',
        },
        caption: {
          style: {
            fontFamily: 'Roboto-Regular',
            textAlign: 'center',
            fontStyle: 'italic',
          },
          enabled: true,
          text: 'Layout caption',
        },
      },
      cssModules: [
        'https://app.everviz.com/fonts/DssrKoz1/font.css',
        'https://app.everviz.com/fonts/HPLuvkaK_/font.css',
        'https://app.everviz.com/fonts/wnDyLY2cA/font.css',
        'https://app.everviz.com/fonts/DZt9aXmCJ/font.css',
      ],
    },
  },
];
