import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { deleteTagAction } from '../actions/projectsPage';
import Modal from './../../../shared/modal/Modal';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import { ButtonColor } from '../../../shared/buttons/types/ButtonModels';

type Tag = {
  value: number;
  label: string;
};

type ModalDeleteTagProps = {
  isOpen: boolean;
  onClose: () => void;
  tag: Tag;
};

export const ModalDeleteTag = ({ isOpen, onClose, tag }: ModalDeleteTagProps) => {
  const dispatch = useDispatch();
  const { team } = useSelector((state: RootState) => state.profile);

  const deleteTag = useCallback(() => {
    dispatch(deleteTagAction({ team, tag }));
    onClose();
  }, [tag, team]);

  const modalTitle = useMemo(() => 'Delete tag', []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={modalTitle} bg="bg-ev-yellow-1">
      Are you sure you want to delete {tag?.label}? All linked projects will lose the tag reference.
      <div className="pt-6 flex gap-2">
        <PrimaryButton onClick={deleteTag} text="Confirm" />
        <PrimaryButton onClick={onClose} text="Cancel" buttonColor={ButtonColor.LightBlue} />
      </div>
    </Modal>
  );
};
