import ajax from './utils/ajax';

export default {
  frontPage: '/',
  resetPassword: '/account/changepassword/:token',
  forgottenPassword: '/account/forgottenpassword',
  login: '/account/login',
  sso: '/account/sso',
  logout: '/account/logout',
  invitation: '/account/invitation/:type/:token',
  verify: '/account/verify/:token',
  oauth: '/account/login/callback',
  oauthSignup: ajax.getURL() + '/login/google' + location.search,
  ssoSignin: ajax.getURL() + '/login/saml',
  signup: '/account/signup',
  admin: '/admin',
  adminLogin: '/admin/login',
  billingAdmin: '/admin/billing',
  browsePublicCharts: '/search/charts',
  vizPicker: '/new',
  newChart: '/create',
  editChartLatest: '/edit/:chartid',
  editChartVersion: '/edit/:chartid/:chartversion',
  editChartByHash: '/charts/:id',
  viewHistoryLatest: '/history/:chartid',
  viewHistoryVersion: '/history/:chartid/:chartversion',
  newTheme: '/createtheme',
  editTheme: '/edittheme/:themeid',
  chartShow: '/show/:id',
  chartShare: '/share/:id',
  packageShare: '/package/share/:id',
  chartShowVersion: '/show/:id/:version',
  profile: '/profile',
  profileCharts: '/profile/charts',
  profileChartsWizard: {
    pathname: '/profile/charts',
    search: '?wizard'
  },
  profileTeamMembers: '/profile/members',
  profileTeamGroups: '/profile/groups',
  profileTeamInvitations: '/profile/invitations',
  profileTeamThemes: '/profile/themes',
  profileDivisionsPage: '/profile/subteams',
  profileSubscription: '/profile/subscription',
  profileSubscriptionSummary: '/profile/subscription/summary',
  profileSubscriptionPlans: '/profile/subscription/plans',
  profileSubscriptionBilling: '/profile/subscription/billing',
  profileSubscriptionHistory: '/profile/subscription/history',
  profileSettings: '/profile/settings',
  createLayout: '/layout/create',
  editLayout: '/layout/edit/:storyid',
  createTable: '/table/create',
  editTable: '/table/edit/:tableid',
  profileLayouts: '/profile/layouts',
  profileApi: '/profile/api',
  profileWebhooks: '/profile/webhooks',
  profileCustomiseEditor: '/profile/customise/editor',
  analytics: '/profile/analytics/:chartid',
  packages: '/profile/packages',
  editPackage: '/package/edit/:packageuuid/:projectid',
  faq: '/FAQ',
  themePreview: '/companytheme/preview/:themeid',
  createCompanyTheme: '/companytheme/create',
  editCompanyTheme: '/companytheme/edit/:themeid',
  subscription: '/subscription',
  url: ajax.getURL()
};
