import { TemplateProps } from '../../types';

const PieWithLegend: TemplateProps = {
  title: 'Pie w/ legend',
  description: '',
  constructor: '',
  thumbnail: 'anofof.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'pie',
      polar: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: true,
        showInLegend: true,
        dataLabels: {
          enabled: false
        }
      }
    }
  },
  sampleSet: 'pie',
  advancedParent: 'Pie',
  dataValidator: false,
  advanced: {
    name: 'Pie + legend'
  }
};

export default PieWithLegend;
