import initialState from '../../../redux/reducers/initial-state';
import actionTypes from '../../../redux/actions/action-types';

const layoutEditorReducer = (state = initialState.layoutEditorPage, action) => {
  switch (action.type) {
    case actionTypes.layoutEditor.set:
      return {
        ...state,
        ...action.data
      };
    case actionTypes.layoutEditor.updatePageTemplate:
      return {
        ...state,
        //pages: action.data.pages,
        customizedOptions: action.data.customizedOptions,
        templateOptions: action.data.templateOptions,
        dataOptions: action.data.dataOptions,
        templateUsed: action.data.type
      };
    case actionTypes.layoutEditor.setResolution:
      return {
        ...state,
        resolution: action.data
      };

    case actionTypes.layoutEditor.toggleChartModal:
      return {
        ...state,
        isChartModalOpen: !state.isChartModalOpen,
        parentToAttachChartTo: action.data
      };

    case actionTypes.layoutEditor.openPublishModal:
      return {
        ...state,
        showPublishPopup: true
      };
    case actionTypes.layoutEditor.closePublishModal:
      return {
        ...state,
        showPublishPopup: false
      };
    case actionTypes.layoutEditor.resetState:
      return initialState.layoutEditorPage;
  }
  return state;
};

export default layoutEditorReducer;
