import EditorError from 'pages/ChartEditorPage/components/EditorError';
import React, { useEffect, useState } from 'react';
import WizardOptions from '../../../pages/ChartEditorPage/meta/wizard/WizardOptions';
import Wizard from '../../editor/generic/Wizard';
import WizardModal from './WizardModal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setResolutionAction } from '../../../pages/LayoutEditorPage/actions/layoutEditor.js';
import { redrawProjectAction } from '../../../pages/ChartEditorPage/actions/chartEditor';

export default function ChartWizard(props) {
  const dispatch = useDispatch();
  const { editorConfig, closeChartWizard, anonymous } = props;
  const { isMap } = useSelector((state) => state.chartEditorPage);
  const history = useHistory();
  const [wizardOptions, setWizardOptions] = useState(WizardOptions);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const leaveWizard = () => {
    dispatch(redrawProjectAction({}));
    dispatch(setResolutionAction({ width: null, height: null }));
  };

  useEffect(() => {
    return () => {
      setTimeout(() => {
        leaveWizard();
      }, 10);
    };
  }, []);

  useEffect(() => {
    if (anonymous && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [anonymous]);

  useEffect(() => {
    filterWizardOptions();
  }, [editorConfig, isMap]);

  const filterWizardOptions = () => {
    let wizardOptions = [];
    if (!isMap) {
      wizardOptions = WizardOptions.filter((option) => !option.onlyMap);
    } else wizardOptions = WizardOptions;

    if (editorConfig) {
      const editorOptions = editorConfig.features;
      wizardOptions = wizardOptions.filter((option) => {
        return !option.permission || (option.permission && editorOptions.indexOf(option.permission) > -1);
      });
    }

    setWizardOptions(wizardOptions);
  };

  const closeModal = () => closeChartWizard();
  const closeHelperModal = () => setIsModalOpen(false);

  return (
    <div className="chart-wizard">
      <Wizard close={closeModal} wizardOptions={wizardOptions} isMap={isMap} history={history} />
      <WizardModal isOpen={isModalOpen} isMap={isMap} close={closeHelperModal} />
      <EditorError />
    </div>
  );
}
