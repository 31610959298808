import actionTypes from '../../../redux/actions/action-types';

export const setAction = (data) => ({
  type: actionTypes.settingsPage.set,
  data
});

export const getTeamProfileAction = (data) => ({
  type: actionTypes.settingsPage.getTeamProfile,
  data
});

export const setTeamSettingsAction = (data) => ({
  type: actionTypes.settingsPage.setTeamSettings,
  data
});

export const deleteTeamAction = (data) => ({
  type: actionTypes.settingsPage.deleteTeam,
  data
});

export const verifyEmailAction = (data) => ({
  type: actionTypes.settingsPage.verifyEmail,
  data
});

export const setUserSettingsAction = (data) => ({
  type: actionTypes.settingsPage.setUserSettings,
  data
});

export const generateLicenseAction = (data) => ({
  type: actionTypes.settingsPage.generateLicense,
  data
});

export const getCMSLicenseAction = (data) => ({
  type: actionTypes.settingsPage.getCMSLicense,
  data
});

export const deleteCMSLicenseAction = (data) => ({
  type: actionTypes.settingsPage.deleteCMSLicense,
  data
});
