export enum ButtonColor {
  Red = 'Red',
  DangerRed = 'DangerRed',
  ErrorRed = 'ErrorRed',
  Yellow = 'Yellow',
  NavyBlue = 'NavyBlue',
  DarkBlue = 'DarkBlue',
  DarkBlueBordered = 'DarkBlueBordered',
  LightBlue = 'LightBlue',
  BabyBlue = 'BabyBlue',
  BabyBlue2 = 'BabyBlue2',
  White = 'White',
  WhiteBordered = 'WhiteBordered',
  Transparent = 'Transparent',
  TransparentBordered = 'TransparentBordered',
  TransparentNavyBlue2 = 'TransparentNavyBlue2',
  Grey = 'Grey',
  GreyBordered = 'GreyBordered',
  Purple = 'Purple',
  Disabled = 'Disabled',
  GreyDisabled = 'GreyDisabled'
}

export enum ButtonType {
  Info = 'Info',
  Action = 'Action',
  Icon = 'Icon'
}
