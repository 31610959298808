import { UnknownParamsFunction } from 'shared/types/commonPropTypes';
import readLocalFile from '../../../editor/core/highed.fileupload';

type FileInfoProps = { filename: string; data: string };
type FileUploadProps = { name: string; data: string; type: string };
export type SetDataParams = {
  dataType: string;
  options?: unknown;
  csv?: string;
  cb?: UnknownParamsFunction;
  isMap?: boolean;
};
export type SetDataFunction = (params: SetDataParams) => void;

const htmlEntities: Record<string, string> = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>'
};

const parseAndSetData = (csv: string, setData: SetDataFunction, isMap: boolean, cb?: UnknownParamsFunction) => {
  Object.keys(htmlEntities).forEach(function (ent) {
    csv = csv.replace(new RegExp(ent, 'g'), htmlEntities[ent]);
  });

  setData({ csv, dataType: 'csv', isMap });
  if (cb) return cb();
};

export const handleFileUpload = (
  f: File | FileUploadProps | null,
  setData: SetDataFunction,
  isMap: boolean,
  cb?: UnknownParamsFunction
) => {
  const { data } = f as FileUploadProps;
  if (data) {
    parseAndSetData(data, setData, isMap, cb);
  } else if (f) {
    const reader = new FileReader();
    reader.onload = (e) => {
      parseAndSetData(e.target?.result as string, setData, isMap, cb);
    };
    reader.readAsText(f as File);
  }
};

export const handleOnDrop = (
  e: React.DragEvent<HTMLDivElement>,
  setData: SetDataFunction,
  isMap: boolean,
  cb?: UnknownParamsFunction
) => {
  e.preventDefault();
  const d = e.dataTransfer;
  let f, i;
  if (d.items) {
    for (i = 0; i < d.items.length; i++) {
      f = d.items[i];
      if (f.kind === 'file') {
        handleFileUpload(f.getAsFile(), setData, isMap, cb);
      }
    }
  } else {
    for (i = 0; i < d.files.length; i++) {
      f = d.files[i];
      handleFileUpload(f, setData, isMap, cb);
    }
  }
};

export const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
};

export const onUpload = (setData: SetDataFunction, isMap: boolean, cb?: UnknownParamsFunction) => {
  readLocalFile({
    type: 'text',
    accept: '.csv',
    success: (info: FileInfoProps) => {
      const file: FileUploadProps = {
        name: info.filename,
        data: info.data,
        type: 'text/csv'
      };
      handleFileUpload(file, setData, isMap, cb);
    }
  });
};

export const parsePlainTextValue = (e: ClipboardEvent) => {
  const plainText = e.clipboardData?.getData('text/plain');
  // trimEnd as copying from google sheets, they may add extra cells at the bottom of the dataset
  // if a user selects the whole column and then copy/paste. We dont want these in here so remove them.
  // Dont use trim() as this will remove any potential tab delimited empty values at the beginning of the sheet.
  return plainText?.trimEnd();
};

export const parsePastedValue = (e: ClipboardEvent, isHTML?: boolean) => {
  e.preventDefault();
  if (!isHTML) return parsePlainTextValue(e);

  const text = e.clipboardData?.getData('text/html');
  if (!text) {
    return parsePlainTextValue(e);
  }

  const tmp = document.createElement('DIV');
  tmp.innerHTML = text.replaceAll('\n', '');
  const tbody = tmp.querySelector('tbody');
  if (!tbody) {
    return parsePlainTextValue(e);
  }

  const rows = Array.from(tmp.querySelector('tbody')?.children ?? []);
  const parsedRows = rows.map((row) => {
    return Array.from(row.children).map((cell) => {
      return (cell.innerHTML || '').replace(/&nbsp;/g, ' ');
    });
  });

  let searching = true;
  while (searching) {
    const lastRow = parsedRows[parsedRows.length - 1];
    const allAreEmpty = lastRow.every((cell) => cell === '');
    if (allAreEmpty) parsedRows.splice(parsedRows.length - 1, 1);
    else searching = false;
  }

  return parsedRows
    .map((cols) => {
      return cols.join(';');
    })
    .join('\n');
};
