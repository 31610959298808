import { all, call, select, put, takeLatest } from 'redux-saga/effects';
import {
  getPackagesProjectWithTeamidAndUuid,
  getPackagesWithTeamid,
  postTeamPackagesThemeUsingTeamidAndId,
  getPackagesSearchWithTeamid
} from '../../../api/cloudApiGenerated';
import actionTypes from '../../../redux/actions/action-types';
import { getProfileConfig } from 'redux/selectors/profile';
import { setAction } from '../actions/packagesPage';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { loadModules } from 'editor/core/highcharts-editor';

export type GenericPayload = {
  type: string;
};

type loadPackageProjectPayload = GenericPayload & {
  data: {
    uuid: string;
  };
};

export function* getTeamPackages(): any {
  const { team } = yield select(getProfileConfig);
  try {
    const data = yield call(getPackagesWithTeamid, team.id);

    yield put(
      setAction({
        packages: data
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* searchTeamPackages(params: any): any {
  const { query } = params.data;
  const { team } = yield select(getProfileConfig);
  try {
    const data = yield call(getPackagesSearchWithTeamid, team.id, query);

    yield put(
      setAction({
        packages: data
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* loadPackageProject(params: loadPackageProjectPayload): any {
  const { uuid } = params.data;

  const { team } = yield select(getProfileConfig);
  try {
    const data = yield call(getPackagesProjectWithTeamidAndUuid, team.id, uuid);
    const themeData = yield call(postTeamPackagesThemeUsingTeamidAndId, team.id, data.team_package_id);
    let theme = null;

    if (themeData.data) theme = JSON.parse(themeData?.data);
    loadModules(theme?.customFonts ?? [], false, null, true);

    yield put(
      setAction({
        packageProject: data,
        themeData: theme
      })
    );

    const iframeUrl = data.embed_code.split('"')[1].replaceAll("'", '');

    yield put(
      setProjectConfigAction({
        changeMade: false,
        embedDetails: {
          iframeCode: data.embed_code,
          injectCode: data.inject_code,
          iframeURL: iframeUrl
        },
        published: true,
        projectName: data.name,
        cssModules: theme?.customFonts ?? []
      })
    );
  } catch (e) {
    console.log(e);
  }
}

/** Watch functions */
export function* watchGetTeamPackages() {
  yield takeLatest(actionTypes.packagesPage.getTeamPackages, getTeamPackages);
}

export function* watchSearchTeamPackages() {
  yield takeLatest(actionTypes.packagesPage.searchTeamPackages, searchTeamPackages);
}

export function* watchLoadPackageProject() {
  yield takeLatest(actionTypes.packagesPage.loadPackageProject, loadPackageProject);
}

export default function* rootSaga() {
  yield all([watchGetTeamPackages(), watchSearchTeamPackages(), watchLoadPackageProject()]);
}
