import { StylesConfig } from 'react-select';

const lightColor = '#FFFFFF';
const evNavyBlue2 = '#28277E';
const evNavyBlue = '#172B4C';
const evBabyBlue2 = '#AEC8FA';
const evError = '#F24A40';
const evLightOrange = '#F8D6BB';
const evGray = '#F7F8F8';
const grayColor = '#656565';

export const customStyles: StylesConfig = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: evNavyBlue2,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    '& > button': {
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    // eslint-disable-next-line no-nested-ternary
    color: state.isSelected ? lightColor : state.isDisabled ? grayColor : evNavyBlue,
    background: state.isSelected ? evNavyBlue2 : 'transparent',
    fontWeight: state.isSelected ? 700 : 500,
    boxShadow: 'none',
    borderRadius: 2,
    backgroundColor: state.isFocused && !state.isSelected ? evBabyBlue2 : '#fff',
    textTransform: 'capitalize',
    '&:hover': {
      background: state.isSelected ? evNavyBlue2 : state.isDisabled ? 'transparent' : evBabyBlue2,
      cursor: state.isSelected || state.isDisabled ? 'default' : 'pointer'
    }
  }),

  menu: (provided, state) => ({
    ...provided,
    fontWeight: 700,
    padding: 4,
    backgroundColor: evGray,
    color: evNavyBlue2,
    marginTop: '1px',
    borderRadius: state.selectProps.menuIsOpen ? '0px 0px 4px 4px' : '4px 4px 4px 4px',
    textAlign: 'left',
    textTransform: 'capitalize'
  }),

  control: (provided, state: any) => ({
    ...provided,
    border: 0,
    minHeight: '2.5rem',
    '&:hover': {
      backgroundColor: evBabyBlue2
    },

    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',

    borderRadius: state.selectProps.menuIsOpen ? '4px 4px 0px 0px' : '4px 4px 4px 4px',
    backgroundColor: state.selectProps.menuIsOpen ? evGray : state.selectProps.backgroundColor ?? lightColor,
    color: evNavyBlue2,
    fontWeight: 700,
    whiteSpace: 'nowrap'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: evNavyBlue2,
    textTransform: 'capitalize'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),

  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    padding: '0px 8px',
    flexWrap: 'nowrap'
  })
};

export const editorStyles: StylesConfig = {
  ...customStyles,
  dropdownIndicator: (base, state) => ({
    ...base,
    position: 'absolute',
    right: '1rem',
    top: '0.75rem',
    height: '1rem',
    padding: 0,
    color: evNavyBlue2,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    '&:hover': { color: evNavyBlue2 },
    '& > svg': {
      width: '1.5rem',
      height: '1.5rem'
    }
  }),
  control: (provided, state: any) => ({
    ...provided,
    minHeight: '2.5rem',
    backgroundColor: state.selectProps.backgroundColor ?? lightColor,
    borderRadius: '0.25rem',
    paddingRight: '2.5rem',
    border: 'none',
    '&:hover': { backgroundColor: evBabyBlue2 }
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    paddingLeft: '1rem',
    flexWrap: 'nowrap'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? lightColor : state.isDisabled ? grayColor : evNavyBlue,
    background: state.isSelected ? evNavyBlue2 : 'transparent',
    '&:hover': {
      background: state.isSelected ? evNavyBlue2 : state.isDisabled ? 'transparent' : evBabyBlue2,
      cursor: state.isSelected || state.isDisabled ? 'default' : 'pointer'
    }
  })
};

export const searchInputStyles: StylesConfig<any> = {
  control: (base) => ({
    ...base
  }),
  input: (base) => ({
    ...base,
    display: 'inline-grid',
    '& > input': {
      boxShadow: 'none !important'
    }
  }),
  placeholder: (base) => ({
    ...base,
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflowX: 'hidden'
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0
  }),
  option: (base) => ({
    ...base,
    backgroundColor: '#F7F8F8',
    '&:hover': {
      backgroundColor: evBabyBlue2
    }
  }),
  menu: (base) => ({
    ...base,
    zIndex: 10
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 4
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.selectProps.menuIsOpen ? '#D4D4D4' : base.color
  })
};

export const searchWithButtonStyles: StylesConfig<any> = {
  control: (base) => ({
    ...base
  }),
  input: (base) => ({
    ...base,
    display: 'inline-grid',
    '& > input': {
      boxShadow: 'none !important'
    }
  }),
  indicatorsContainer: (base) => base,
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'none',
    '&:hover': {
      backgroundColor: evBabyBlue2
    }
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.selectProps.menuIsOpen ? '#D4D4D4' : base.color
  })
};

export const richtextStyles: StylesConfig = {
  ...editorStyles,
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flexWrap: 'nowrap'
  }),
  container: (provided) => ({
    ...provided,
    height: '2rem',
    width: '3.5rem'
  }),
  control: (provided) => ({
    ...provided,
    border: 0,
    height: '2rem',
    minHeight: '2rem',
    width: '3.5rem',
    '&:hover': {
      backgroundColor: evBabyBlue2
    },
    color: evNavyBlue2,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 0,
    color: evNavyBlue2,
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    width: '1rem',
    height: '1rem',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    '& > button': {
      width: '1rem !important',
      height: '1rem !important',
      padding: '0 !important',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }),
  menu: (base) => ({
    ...base,
    width: 'auto !important'
  })
};

export const customRichtextStyles: StylesConfig = {
  ...editorStyles,
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flexWrap: 'nowrap'
  }),
  container: (provided) => ({
    ...provided,
    height: '2rem',
    width: '3.5rem'
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: 'inset 0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    border: 0,
    height: '2rem',
    minHeight: '2rem',
    width: '3.6rem',
    '&:hover': {
      backgroundColor: evBabyBlue2
    },
    color: '#444444',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 0,
    color: evNavyBlue2,
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    width: '1rem',
    height: '1rem',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    '& > button': {
      width: '1rem !important',
      height: '1rem !important',
      padding: '0 !important',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }),
  menu: (base) => ({
    ...base,
    width: 'auto !important'
  })
};

export const selectCustomStyles: StylesConfig = {
  ...customStyles,
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px'
  }),
  input: (provided) => ({
    ...provided,
    '& div': {
      '& input': {
        boxShadow: 'none !important'
      }
    }
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    border: state.isFocused ? `1px solid ${evNavyBlue2}` : '1px solid rgba(0, 0, 0, 0.12)',
    minWidth: '12.5rem',
    height: '2.5rem',
    fontWeight: 500,
    '& svg': {
      color: evNavyBlue
    },
    '&:hover': { backgroundColor: evBabyBlue2 }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    '&:hover': { color: evNavyBlue2 }
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? lightColor : state.isDisabled ? grayColor : evNavyBlue,
    background: state.isSelected ? evNavyBlue2 : 'transparent',
    fontWeight: state.isSelected ? 700 : 500,
    boxShadow: 'none',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem',
    fontSize: '14px',
    '&:hover': {
      background: state.isSelected ? evNavyBlue2 : state.isDisabled ? 'transparent' : evBabyBlue2,
      cursor: state.isSelected || state.isDisabled ? 'default' : 'pointer'
    },
    '& svg': {
      color: evNavyBlue2,
      '&:hover': { color: evError }
    }
  }),
  singleValue: (base) => ({
    ...base,
    color: '#6D5BD1',
    fontWeight: 700
  }),
  multiValue: (base, state) => ({
    ...base,
    textTransform: 'capitalize',
    backgroundColor: evLightOrange,
    borderRadius: '4px',
    border: state.isFocused ? `1px solid ${evNavyBlue2}` : 'none'
  }),
  multiValueLabel: (base) => ({
    ...base,
    '& svg': {
      color: evNavyBlue2,
      border: 'none',
      backgroundColor: evLightOrange,
      '&:hover': { color: evError }
    }
  }),
  multiValueRemove: (base) => ({
    ...base,
    alignSelf: 'center',
    borderRadius: '50%',
    background: evNavyBlue,
    width: '0.875rem',
    height: '0.875rem',
    padding: 0,
    marginRight: '0.5rem',
    '&:hover': { background: evBabyBlue2 },
    '& svg': {
      color: lightColor,
      '&:hover': { color: evNavyBlue },
      '&:active': { color: evNavyBlue, backgroundColor: lightColor, borderRadius: '50%' }
    }
  })
};

export const selectMultiStyles: StylesConfig = {
  ...selectCustomStyles,
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    border: state.isFocused ? `1px solid ${evNavyBlue2}` : '1px solid rgba(0, 0, 0, 0.12)',
    minWidth: '12.5rem',
    height: 'auto !important',
    minHeight: '2.5rem',
    fontWeight: 500,
    '& svg': {
      color: evNavyBlue
    },
    '&:hover': { backgroundColor: evBabyBlue2 }
  }),
  valueContainer: (base) => ({
    ...base,
    flexWrap: 'wrap'
  }),
  placeholder: (base, state) => ({
    ...base,
    display: state.isFocused ? 'none' : 'block'
  }),
  multiValueRemove: (base) => ({
    ...base,
    alignSelf: 'center',
    borderRadius: '50%',
    background: evNavyBlue,
    width: '0.875rem',
    height: '0.875rem',
    padding: 0,
    marginRight: '0.5rem',
    '&:hover': { background: evBabyBlue2 },
    '& svg': {
      color: `${lightColor} !important`,
      '&:hover': { color: `${evError} !important` }
    }
  })
};
