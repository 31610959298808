import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import Button from '../../shared/buttons/Button';
import TextInput from '../../shared/inputs/TextInput';
import ErrorMessage from '../../shared/message/ErrorMessage';
import { FormStateType } from './types';
import { useHistory } from 'react-router-dom';
import { acceptInvitationAction } from './actions/redeemInvitePage';

interface RedeemInvitePageProps {
  match: { params: { [key: string]: string } };
  onLoggedIn: () => void;
  loggedIn?: boolean;
}

export default function RedeemInvitePage({ match, onLoggedIn, loggedIn }: RedeemInvitePageProps) {
  const [form, setForm] = useState<FormStateType>({
    name: {
      value: '',
      error: ''
    },
    password: {
      value: '',
      error: ''
    },
    passwordConfirmation: {
      value: '',
      error: ''
    }
  });

  const token = match.params.token;
  const paths = useSelector((state: RootState) => state.app.paths);
  const history = useHistory();
  const isNewUser = match.params.type === 'new';
  const { error } = useSelector<any, any>((state: RootState) => state.redeemInvitePage);

  const dispatch = useDispatch();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    const valid = !isNewUser || validateForm();

    if (valid) {
      dispatch(
        acceptInvitationAction({
          token,
          form,
          isNewUser,
          history,
          loggedIn,
          paths,
          onLoggedIn
        })
      );
    }
  };

  const updateState = (event: ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    const value = event.target.value;
    setForm((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field as keyof FormStateType],
        value: value,
        error: ''
      }
    }));
  };

  const validateForm = () => {
    let valid = true;
    const updatedForm: FormStateType = { ...form };

    if (form.name.value.length < 2) {
      updatedForm.name.error = 'Your name needs to be at least 2 characters long';
      valid = false;
    } else {
      updatedForm.name.error = '';
    }

    if (form.password.value.length < 8) {
      updatedForm.password.error = 'Your password needs to be at least 8 characters long';
      valid = false;
    } else {
      updatedForm.password.error = '';
    }

    if (form.password.value !== form.passwordConfirmation.value) {
      updatedForm.passwordConfirmation.error = 'The passwords you entered do not match';
      valid = false;
    } else {
      updatedForm.passwordConfirmation.error = '';
    }

    setForm(updatedForm);
    return valid;
  };

  const getFormComponents = () => {
    const labelClass = 'text-base font-bold text-ev-navy-blue mb-1';
    const inputClassName = 'placeholder:text-ev-grey-4 placeholder:font-medium py-2.5 h-10 shadow-ev-inset';
    const extraClass = 'w-96 m-0 mb-3 pr-0';

    const button = (
      <Button
        key="0"
        buttonText="Confirm"
        buttonClass="px-6 py-2 m-0 text-base font-bold leading-snug h-10 rounded-full bg-ev-red"
        type="submit"
        onClick={onSubmit}
      />
    );

    const inputs = [
      <TextInput
        key="1"
        label="Name"
        name="name"
        value={form.name.value}
        placeholder={'Name'}
        extraClass={extraClass}
        onChange={updateState}
        inputClassName={inputClassName}
        labelClass={labelClass}
      />,
      <ErrorMessage errorBoxClass="w-96" key="2" error={form.name.error} dismissable={false} />,
      <TextInput
        key="3"
        label="Password"
        name="password"
        type="password"
        value={form.password.value}
        extraClass={extraClass}
        placeholder={'Enter password (minimum 8 characters)'}
        onChange={updateState}
        inputClassName={inputClassName}
        labelClass={labelClass}
      />,
      <ErrorMessage errorBoxClass="w-96" key="4" error={form.password.error} dismissable={false} />,
      <TextInput
        key="5"
        label="Password confirmation"
        name="passwordConfirmation"
        type="password"
        value={form.passwordConfirmation.value}
        extraClass={`w-96 m-0 pr-0 ${form.passwordConfirmation.error ? 'mb-2.5 ' : 'mb-8'}`}
        placeholder={'Enter password (minimum 8 characters)'}
        onChange={updateState}
        inputClassName={inputClassName}
        labelClass={labelClass}
      />,
      <ErrorMessage errorBoxClass="w-96" key="6" error={form.passwordConfirmation.error} dismissable={false} />
    ];

    return isNewUser ? [...inputs, button] : button;
  };

  const text = isNewUser
    ? "You're almost there. We just need a few details."
    : "You're all set. Just click to confirm.";

  return (
    <div className="py-20 px-8 ev-sm:px-20 flex justify-center items-center w-full">
      <div className="py-16 flex justify-center items-center flex-col bg-white w-full gap-y-6 rounded shadow-ev-standard">
        <h1 className="m-0 font-bold text-5xl">Welcome aboard</h1>
        <p className="m-0 text-ev-navy-blue">{text}</p>
        {error && <ErrorMessage errorBoxClass="w-96" error={error} dismissable={false} />}
        <form role="form" className={isNewUser ? 'bg-ev-grey rounded-lg p-5 shadow-ev-standard' : ''}>
          {getFormComponents()}
        </form>
      </div>
    </div>
  );
}
