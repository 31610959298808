import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleInspiredAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import PermissionModal from 'shared/modal/PermissionModal';
import useToggle from 'shared/utils/useToggle';
import PublishSummary from 'shared/wizard/components/PublishSummary';
import UnpublishModal from 'shared/wizard/components/UnpublishModal';
import ChannelSection from 'shared/wizard/components/publishChannel/ChannelSection';
import ChartExamplesModal from 'shared/wizard/editor/ChartExamplesModal';
import { checkCreatePermission, checkPermission } from 'utils/profileHelper';
import { onSaveAndPublish, onSave } from '../publishHelper';
import { setResolutionAction } from '../../../pages/LayoutEditorPage/actions/layoutEditor';
import PanelContainer from './PanelContainer';

type PublishExportProps = {
  active: boolean;
};

const PublishExport = (props: PublishExportProps) => {
  const dispatch = useDispatch();
  const { isMap } = useSelector((state: RootState) => state.chartEditorPage);
  const { published, type, inPackagesMode } = useSelector((state: RootState) => state.projectConfig);

  const [showPermissionModal, toggleShowPermissionModal] = useToggle();
  const hasPublishPermission = checkPermission('publish');
  const hasCreatePermission = checkCreatePermission(type);
  const projectTypeText = isMap ? 'map' : type ?? 'project';

  const handlePublish = () => {
    if (!hasPublishPermission || !hasCreatePermission) {
      toggleShowPermissionModal(true);
    } else {
      onSaveAndPublish(type, dispatch);
    }
  };

  const handleSave = () => {
    if (!hasCreatePermission) {
      toggleShowPermissionModal(true);
    } else {
      onSave(type, false, dispatch);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setResolutionAction({ height: null, width: null }));
    };
  }, []);

  return (
    <>
      {props.active && (
        <PanelContainer active={props.active}>
          <UnpublishModal />
          <ChartExamplesModal />
          <PermissionModal isOpen={showPermissionModal} onClose={toggleShowPermissionModal} />
          <PublishSummary onPublish={handlePublish} onSave={handleSave} />
          <ChannelSection onPublish={handlePublish} />

          {!inPackagesMode && (
            <div className={`bg-ev-light-purple p-6 rounded ${!published ? 'pointer-events-none opacity-60' : ''}`}>
              <p className="font-bold text-ev-navy-blue">Allow reuse of this {projectTypeText}</p>
              <div className="mb-4">
                You can increase reach by allowing other users to adapt and reuse your {projectTypeText}.
              </div>
              <PrimaryButton
                onClick={() => dispatch(toggleInspiredAction(true))}
                text="Share to chart examples"
                buttonColor={ButtonColor.NavyBlue}
                buttonType={ButtonType.Info}
              />
            </div>
          )}
        </PanelContainer>
      )}
    </>
  );
};

export default PublishExport;
