import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { projectsPlaceholders } from 'shared/utils/projectsPlaceholders';
import { setSelectFilterAction } from '../actions/projectsPage';

type Tag = {
  value: string;
  label: string;
};

type TagsProps = {
  tags: Tag[];
  chartType: number;
};

const Tags = (props: TagsProps) => {
  const dispatch = useDispatch();
  const { selectValue } = useSelector((state: RootState) => state.projectsPage);

  const usertags = (props.tags || []).map((tag) => {
    const userTagsClasses = classNames(
      'rounded bg-ev-light-orange py-1 px-1.5 text-xs font-bold text-ev-navy-blue transition duration-75 cursor-default border border-ev-light-orange hover:cursor-pointer hover:border-ev-dark-purple',
      {
        ['pointer-events-none']: selectValue?.some((x: Tag) => x.label === tag.label)
      }
    );
    return (
      <button
        key={tag.value}
        onClick={() => {
          dispatch(setSelectFilterAction({ selectValue: [...selectValue, tag] }));
          window.scroll(0, 0);
        }}
        className={userTagsClasses}
      >
        {tag.label}
      </button>
    );
  });

  const typetag = (
    <span className="rounded bg-ev-navy-blue-2 pointer-events-none text-xs py-1 px-1.5 font-bold text-ev-yellow-7">
      {projectsPlaceholders[props.chartType].name}
    </span>
  );

  const tags = (
    <div className={`space-x-2 space-y-2 max-h-[74px] overflow-y-auto ${usertags.length === 0 && 'py-2'}`}>
      {typetag} {usertags}
    </div>
  );
  return tags;
};

export default Tags;
