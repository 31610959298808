import React from 'react';
import { cloneDeep, get } from 'lodash';
import Accordion from 'shared/Accordion';
import NumberInputWidget from './NumberInputWidget';
import TextButton from 'shared/buttons/TextButton';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import AddIcon from 'static/icons/add.svg';
import BinIcon from 'static/icons/bin.svg';
import ColorWidget from './ColorWidget';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import InputWidget from './InputWidget';

type StopProps = {
  from?: number;
  to?: number;
  color?: string;
  name?: string;
};

type TypeProps = { id: keyof StopProps; type: React.ElementType; label: string };

type ValueProps = {
  val: string | number;
};

const properties: TypeProps[] = [
  {
    id: 'name',
    type: InputWidget,
    label: 'Name'
  },
  {
    type: NumberInputWidget,
    id: 'from',
    label: 'Start'
  },
  {
    type: NumberInputWidget,
    id: 'to',
    label: 'Stop'
  },
  {
    type: ColorWidget,
    id: 'color',
    label: 'Color'
  }
];

const optionClassName = {
  className: 'bg-white'
};

export default (props: GenericInputProps) => {
  const deleteStop = (stopIndex: number) => {
    const { value } = props;
    (value as unknown[]).splice(stopIndex, 1);
    props.onChange({ val: value });
  };

  const addStop = () => {
    const { value } = props;
    (value as unknown[]).push({
      from: 0,
      to: 100,
      color: '#000'
    });
    props.onChange({ val: value });
  };

  const onChange = (index: number, property: TypeProps, newValue: ValueProps) => {
    const { value } = props;
    const copiedValue = cloneDeep(value) as unknown as Record<string, string | number>[];
    copiedValue[index][property.id] = newValue.val;
    props.onChange({
      val: copiedValue
    });
  };

  const getLabel = (stop: StopProps) => {
    if ('to' in stop && 'from' in stop) return `${stop.from} to ${stop.to}`;
    if ('to' in stop) return `Less than ${stop.to}`;
    if ('from' in stop) return `Greater than ${stop.from}`;
  };

  const { value } = props;
  const stops = value as StopProps[];

  return (
    <>
      {stops.map((stop: StopProps, index: number) => {
        return (
          <Accordion
            key={`stop_${index}`}
            accordionClasses="bg-white"
            headerText={`Color stop ${index + 1}`}
            smallHeaderText={true}
            selectedChildren={getLabel(stop)}
            useInternalSelect={true}
          >
            {properties.map((property, propIndex) => {
              return (
                <property.type
                  key={`type_${propIndex}`}
                  value={get(stop, property.id)}
                  label={property.label}
                  onChange={(value: ValueProps) => onChange(index, property, value)}
                  className={property.id === 'name' ? '' : 'bg-ev-grey'}
                  option={{ ...optionClassName, inputClassName: 'bg-ev-grey' }}
                />
              );
            })}
            <div className="flex items-center justify-end py-2">
              <div className="text-right ml-auto">
                <TextButton
                  text={`Delete color stop ${index + 1}`}
                  onClick={() => deleteStop(index)}
                  className="text-sm"
                />
                <SvgIconButton
                  width={20}
                  height={20}
                  onClick={() => deleteStop(index)}
                  buttonClasses="p-2 rounded ml-1"
                  buttonColor={ButtonColor.White}
                  Icon={BinIcon}
                />
              </div>
            </div>
          </Accordion>
        );
      })}

      <div className="flex items-center justify-end py-2">
        <TextButton text="Add color stop" onClick={addStop} className="text-sm" />
        <SvgIconButton
          width={12}
          height={12}
          onClick={addStop}
          buttonClasses="ml-2 p-2 rounded-full"
          Icon={AddIcon}
          buttonColor={ButtonColor.NavyBlue}
        />
      </div>
    </>
  );
};
