import { TemplateProps } from '../../types';

const Donut: TemplateProps = {
  title: 'Donut',
  description: '',
  constructor: '',
  thumbnail: 'upaxab.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'pie',
      polar: false
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: true,
        innerSize: '60%'
      },
      series: {
        dataLabels: {
          enabled: true
        }
      }
    }
  },
  sampleSet: 'pie',
  dataValidator: false,
  basic: {
    order: 6,
    thumbnail: 'donut_chart.svg',
    icon: 'Donut'
  },
  advanced: {
    name: 'Standard'
  },
  advancedParent: 'Donut',
  parent: 'Donut'
};

export default Donut;
