import React from 'react';
import { stripHtml } from '../../utils/helper';
import SelectWidget from './SelectWidget';
import ColorWidget from './ColorWidget';
import CheckboxWidget from './CheckboxWidget';
import ArrayWidget from './ArrayWidget';
import SeparatorWidget from './SeparatorWidget';
import { isArray } from 'lodash';
import { isObj } from 'editor/core/highcharts-editor';

class TableChart extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.selectTableChart = this.selectTableChart.bind(this);
    this.onSelectChangeTo = this.onSelectChangeTo.bind(this);
    this.onSelectChangeFrom = this.onSelectChangeFrom.bind(this);
    this.onSelectChangeType = this.onSelectChangeType.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.addChartToTable = this.addChartToTable.bind(this);
    this.addAnotherChartToTable = this.addAnotherChartToTable.bind(this);
    this.isEqual = this.isEqual.bind(this);
    this.onChangeToggle = this.onChangeToggle.bind(this);
    this.onChangeColors = this.onChangeColors.bind(this);
    this.onChangeNegativeColor = this.onChangeNegativeColor.bind(this);
    this.setDefaultValues = this.setDefaultValues.bind(this);

    this.state = {
      value: this.props.value || '',
      selected: null,
      columnToValue: null,
      columnFromValue: null,
      typeValue: 'bar',
      showTooltip: false,
      showStartEndValues: true,
      smoothenLines: true,
      charts: [],
      hideOriginalColumnsValue: false,
      selectOptions: [
        {
          value: 'area',
          label: 'Area'
        },
        {
          value: 'line',
          label: 'Line'
        },
        {
          value: 'bar',
          label: 'Bar'
        },
        {
          value: 'stacked',
          label: 'Stacked'
        },
        {
          value: 'column',
          label: 'Column'
        }
      ],
      defaultColors: [
        '#235A61',
        '#DD495E',
        '#2A2383',
        '#F2C60E',
        '#24CBE5',
        '#64E572',
        '#FF9655',
        '#FFF263',
        '#6AF9C4'
      ],
      colors: [],
      columnNameValue: '',
      colorValue: '',
      negativeColor: null,
      defaultNegativeColor: '#DD495E'
    };
  }

  componentDidMount() {
    const columns = this.props.aggregatedOptions.columns || [];
    this.updateList(columns);
  }

  componentDidUpdate(prevProps) {
    const columns = this.props.aggregatedOptions.columns || [],
      oldColumns = prevProps.aggregatedOptions.columns || [];

    if (!this.isEqual(columns, oldColumns)) {
      this.updateList(columns, oldColumns);
    }
  }

  isEqual(value, other) {
    let type = Object.prototype.toString.call(value);
    if (type !== Object.prototype.toString.call(other)) return false;
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;
    let valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    let otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    const compare = (item1, item2) => {
      let itemType = Object.prototype.toString.call(item1);
      if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
        if (!this.isEqual(item1, item2)) return false;
      } else {
        if (itemType !== Object.prototype.toString.call(item2)) return false;
        if (itemType === '[object Function]') {
          if (item1.toString() !== item2.toString()) return false;
        } else {
          if (item1 !== item2) return false;
        }
      }
    };
    if (type === '[object Array]') {
      for (let i = 0; i < valueLen; i++) {
        if (compare(value[i], other[i]) === false) return false;
      }
    } else {
      for (let key in value) {
        if (value && key in value) {
          if (compare(value[key], other[key]) === false) return false;
        }
      }
    }
    return true;
  }

  makeSwitch({ title, state }) {
    return (
      <CheckboxWidget
        value={this.state[state]}
        onChange={(newValue) => {
          this.setState({
            [state]: newValue.val
          });

          this.props.updateCustomizedArrayProp('columns', this.state.selected, {
            [state]: newValue.val
          });
        }}
        label={title}
      />
    );
  }

  areColumnsDifferentType(columns, oldColumns) {
    return columns.some((column, i) => {
      return oldColumns[i] && column.type !== oldColumns[i].type;
    });
  }

  updateList(columns, oldColumns) {
    const aggregatedOptions = this.props.aggregatedOptions;
    const charts = (aggregatedOptions.columns || [])
      .map((column, index) => {
        return {
          element: column,
          index
        };
      })
      .filter((column) => 'chartType' in column.element);

    let stateValues = {
      charts
    };

    if (
      (this.state.selected === undefined || this.state.selected === null) &&
      (!columns ||
        !oldColumns ||
        columns.length !== oldColumns.length ||
        this.areColumnsDifferentType(columns, oldColumns))
    ) {
      if (charts[0]) {
        stateValues.selected = charts[0].index;
      }
    } else if (this.state.selected !== undefined && this.state.selected !== null) {
      // Somethings probably changed with the column order,
      // see if the selected value still matches up with the column index
      if (!columns[this.state.selected] || !('chartType' in columns[this.state.selected])) {
        if (charts[0]) {
          stateValues.selected = charts[0].index;
        }
      }
    }

    this.setState(stateValues);

    const selected = parseInt(stateValues.selected, 10);
    const hasInitialChart = selected >= 0;
    if (hasInitialChart) {
      this.selectTableChart(selected);
    }
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    });

    this.props.onChange({ val: e.target.value });
  }

  onSelectChangeType(newValue) {
    this.setState({
      typeValue: newValue.val
    });

    this.props.updateCustomizedArrayProp('columns', this.state.selected, {
      chartType: newValue.val,
      columns:
        newValue.val === 'bar'
          ? [this.state.columnFromValue]
          : [...Array(this.state.columnToValue + 1).keys()].filter((val) => val >= this.state.columnFromValue)
    });
  }

  onSelectChangeTo(newValue) {
    this.setState({
      columnToValue: newValue.val
    });

    const toValue = parseInt(newValue.val, 10);
    let fromValue = this.state.columnFromValue;
    let stateVals = {
      columnToValue: toValue,
      selected: toValue + 1
    };

    if (toValue < fromValue) {
      stateVals.columnFromValue = fromValue = toValue;
    }

    this.setState(stateVals);
    this.props.updateCustomizedArrayProp(
      'columns',
      this.state.selected,
      {
        columns: [...Array(toValue + 1).keys()].filter((val) => val >= fromValue)
      },
      true
    );
  }

  onSelectChangeFrom(newValue) {
    const fromValue = parseInt(newValue.val, 10);
    let toValue = this.state.columnToValue;
    let stateVals = {
      columnFromValue: fromValue
    };

    if (fromValue > toValue) {
      stateVals.columnToValue = toValue = fromValue;
      stateVals.selected = toValue + 1;
    }

    this.setState(stateVals);
    this.props.updateCustomizedArrayProp(
      'columns',
      this.state.selected,
      {
        columns:
          this.state.typeValue === 'bar'
            ? [fromValue]
            : [...Array(toValue + 1).keys()].filter((val) => val >= fromValue)
      },
      true
    );
  }

  onChangeValue(e) {
    this.setState({
      columnNameValue: e.target.value
    });
  }

  selectTableChart(dataIndex) {
    const aggOption = this.props.aggregatedOptions.columns[dataIndex];
    const hideOriginal = aggOption.columns.some(
      (columnIndex) => this.props.aggregatedOptions.columns[columnIndex].visible === false
    );
    this.setState({
      selected: dataIndex,
      columnToValue: aggOption.columns[aggOption.columns.length - 1],
      columnFromValue: aggOption.columns[0],
      typeValue: aggOption.chartType,
      hideOriginalColumnsValue: hideOriginal,
      negativeColor: aggOption.negativeChartColor || null,
      colors: aggOption.chartColors || []
    });
  }

  addChartToTable() {
    this.props.addChartToTable(0);
    this.setState({
      columnNameValue: 'Chart Column'
    });
    this.setState({
      selected: null
    });
    this.setDefaultValues();
  }

  addAnotherChartToTable() {
    this.props.addChartToTable(0);
    this.setState({
      selected: null
    });
    this.setDefaultValues();
  }

  onChangeToggle(newValue) {
    this.setState({
      hideOriginalColumnsValue: newValue.val
    });
    const charts = this.state.charts;

    if (charts) {
      const index = charts.findIndex((chart) => chart.index === this.state.selected);
      this.props.updateColumnHiddenValue(charts[index].element.columns, !newValue.val, charts[index].index);
    }
  }

  onChangeColors(newValue) {
    const val = isArray(newValue.val) ? newValue.val : [newValue.val];
    this.props.updateCustomizedArrayProp('columns', this.state.selected, {
      chartColors: val
    });

    this.setState({
      colors: val
    });
  }

  onChangeNegativeColor(newValue) {
    const negativeColor = newValue.val;

    this.props.updateCustomizedArrayProp('columns', this.state.selected, {
      negativeChartColor: negativeColor
    });

    this.setState({
      negativeColor
    });
  }

  setDefaultValues() {
    this.setState({
      columnToValue: null,
      columnFromValue: null,
      typeValue: 'bar',
      hideOriginalColumnsValue: false,
      negativeColor: null,
      showTooltip: false,
      colors: []
    });
  }

  render() {
    const { aggregatedOptions, dataOptions } = this.props;
    const { colors, defaultColors, selected, negativeColor, defaultNegativeColor, typeValue } = this.state;
    const options = (dataOptions[0] || [])
      .map((data, i) => {
        const isDataObj = isObj(data);
        if (isDataObj && data.mergedCell) return null;
        return { label: stripHtml(isDataObj ? data.value : data), value: i };
      })
      .filter((a) => a !== null);
    const charts = this.state.charts;
    return (
      <>
        <div className={'column-selector-widget add-chart'}>
          {(charts || []).map((chart) => {
            return (
              <div
                key={chart.index}
                data-index={chart.index}
                onClick={(e) => this.selectTableChart(parseInt(e.target.getAttribute('data-index'), 10))}
                className={'column-option ' + (this.state.selected === chart.index ? 'active' : '')}
              >
                {stripHtml(chart.element.columnName)} ({chart.element.chartType})
              </div>
            );
          })}
          {(charts || []).length === 0 && (
            <div className="add-chart-to-table" onClick={this.addChartToTable}>
              <div className="table-icon">
                {' '}
                <span className="fa fa-plus" />
              </div>
              <div className="table-text">Add chart to table </div>
            </div>
          )}
        </div>
        {(charts || []).length > 0 && (
          <div className="add-another-chart" onClick={this.addAnotherChartToTable}>
            <div className="table-icon">
              {' '}
              <span className="fa fa-plus" /> Add new chart
            </div>
          </div>
        )}

        {(charts || []).length > 0 && (
          <div className="column-options p-2">
            <div className="property-label conditional-formatting-label">Chart properties</div>

            <div className={'flex flex-col gap-2'}>
              <SelectWidget
                selectOptions={this.state.selectOptions}
                value={this.state.typeValue}
                label="Type"
                onChange={this.onSelectChangeType}
              />

              <SelectWidget
                selectOptions={options}
                value={this.state.columnFromValue}
                label={typeValue === 'bar' ? 'Column' : 'Column From'}
                onChange={this.onSelectChangeFrom}
              />

              {typeValue !== 'bar' && (
                <SelectWidget
                  selectOptions={options}
                  value={this.state.columnToValue}
                  label={'Column To'}
                  onChange={this.onSelectChangeTo}
                />
              )}

              {aggregatedOptions.columns?.[selected]?.columns && typeValue !== 'area' && typeValue !== 'line' && (
                <>
                  <SeparatorWidget />
                  <div className="chart-options">
                    <div className="property-label">Colors:</div>
                    <div className="property-value">
                      <ArrayWidget
                        value={aggregatedOptions.columns[selected].columns.map((_, i) => {
                          return colors[i] || defaultColors[i] || '';
                        })}
                        inputType="array<color>"
                        option={{
                          custom: {
                            hideAddButton: true,
                            hideDeleteButtons: true
                          }
                        }}
                        onChange={this.onChangeColors}
                        label={'Colors'}
                      />
                    </div>
                  </div>
                  <SeparatorWidget />
                </>
              )}

              {(typeValue === 'area' || typeValue === 'line') && (
                <ColorWidget
                  onChange={this.onChangeColors}
                  value={colors[0] || defaultColors[0] || ''}
                  label={'Color'}
                />
              )}

              {aggregatedOptions.columns[selected] && (
                <ColorWidget
                  onChange={this.onChangeNegativeColor}
                  value={negativeColor || defaultNegativeColor}
                  label={'Negative color'}
                />
              )}

              <CheckboxWidget
                value={this.state.hideOriginalColumnsValue}
                onChange={this.onChangeToggle}
                label={'Hide original columns'}
              />

              {['line', 'area'].includes(this.state.typeValue) &&
                this.makeSwitch({
                  title: 'Smoothen lines',
                  state: 'smoothenLines'
                })}

              {['line', 'area'].includes(this.state.typeValue) &&
                this.makeSwitch({
                  title: 'Show start and end value',
                  state: 'showStartEndValues'
                })}

              {this.makeSwitch({
                title: 'Enable tooltip',
                state: 'showTooltip'
              })}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default TableChart;
