import { TemplateTypeProps } from '../../types';
import Grouped from './Grouped';
import PackedBubble from './PackedBubble';
import Spiral from './Spiral';

const PackedBubbleTemplate: TemplateTypeProps = {
  description: '',
  icon: '',
  sampleData: [],
  templates: {
    'Packed bubble': PackedBubble,
    Grouped,
    Spiral
  }
};

export default PackedBubbleTemplate;
