import { snakeCase } from 'lodash';
import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanById } from 'pages/SubscriptionPage/utils/plansHelper';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setAction, showSignupModalAction, toggleUnsavedChangesModalAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { langMap } from 'shared/utils/LangMap';
import { cancelTextMap, showPaidPlanModal } from 'shared/utils/paidPlanHelper';
import { generateQueryString } from '../../utils/editorHelper';
import WizardStepper from '../../wizard/WizardStepper';

type WizardProps = {
  wizardOptions: any;
  close: (module: any) => void;
  isMap: boolean;
};

export default function Wizard(props: WizardProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { team, subscription } = useSelector((state: RootState) => state.profile);
  const { paths } = useSelector((state: RootState) => state.app);
  const { activeBasicTab, changeMade, showWizard, type } = useSelector((state: RootState) => state.projectConfig);
  const { chosenWizardTemplate } = useSelector((state: RootState) => state.chartEditorPage);
  const [step, setStep] = useState(0);
  const { wizardOptions, isMap } = props;

  const currentPlan = getPlanById(subscription?.id);
  const isFreePlan = currentPlan === SubscriptionPlans.Free;
  const hasBillingSetup = window.hcconfig.billing.enabled;
  const isLocked = hasBillingSetup && isFreePlan && (chosenWizardTemplate as any)?.locked;

  const closeWizard = () => {
    props.close('wizard');
    setStep(0);
  };

  useEffect(() => {
    const urlParam = wizardOptions[step].urlParam;
    const typeParam = new URLSearchParams(search).get('type');
    const templateTitle = (chosenWizardTemplate as any).title;
    const templateKey = templateTitle ? snakeCase(templateTitle) : null;
    const template = typeParam !== templateKey ? templateKey : typeParam;
    const contextualKey = type ? snakeCase(langMap[type].entries[activeBasicTab]) : '';
    const queryString = generateQueryString(qs, urlParam, type, showWizard, isMap, template, contextualKey);

    window.history.replaceState(null, '', window.location.pathname + queryString);
    if ((window as any).Beacon) {
      (window as any).Beacon('suggest');
    }
    dispatch(setAction({ urlParam }));
  }, [step, chosenWizardTemplate]);

  const generateNextButtonText = () => {
    if (isLocked) {
      return 'Upgrade';
    } else if (wizardOptions.length - 1 === step) {
      return 'Done';
    } else {
      return 'Next';
    }
  };

  const done = () => {
    if (!team) {
      dispatch(showSignupModalAction());
      return;
    }
    if (changeMade) {
      dispatch(toggleUnsavedChangesModalAction(true));
    } else {
      history.push({
        pathname: paths.profileCharts,
        state: {
          from: history.location.pathname
        }
      });
    }
  };

  const goToNextPage = () => {
    if (step + 1 >= wizardOptions.length) {
      closeWizard();
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    if (step - 1 < 0) {
      //No more steps, go back to the new page
      window.location = (window.location.origin + '/new') as string & Location;
    } else {
      setStep((prev) => prev - 1);
    }
  };

  const onNextButtonClick = () => {
    if (isLocked) return history.push(paths.profileSubscription);

    if (wizardOptions.length - 1 === step) done();
    else goToNextPage();
  };

  const onClickStep = (index: number) => {
    if (isLocked) return showPaidPlanModal('starter', cancelTextMap.previewChart);

    if (index <= step + 1) {
      setStep(index);
    }
  };

  return (
    <div className="w-full text-center highed-box-size highed-wizard-title-container dimmer">
      <div className="wizard-popup active">
        <div className="relative z-[1300] w-full ev-lg:w-[78%] py-6 px-10 ev-md:px-20 ev-lg:px-auto flex ev-sm-max:items-center flex-row ev-lg:mx-auto ev-lg:max-w-[944px] ev-lg:px-0 shadow-bottom ev-md:shadow-none ev-md:z-0">
          <PrimaryButton className="w-[88px] h-10" text="Back" onClick={prevStep} buttonColor={ButtonColor.NavyBlue} />

          <WizardStepper step={step} onClickStep={onClickStep} wizardOptions={wizardOptions} />

          {!wizardOptions[step].noNextButton && (
            <PrimaryButton
              className="min-w-[88px] h-10"
              text={generateNextButtonText()}
              onClick={onNextButtonClick}
              id="ev-wizard-button-next"
            />
          )}
        </div>
        {(wizardOptions || []).map((opt: any, i: number) => {
          if (i !== step || (opt.onlyMap && !isMap)) return <span key={'step_' + i} />;

          return (
            <div key={'step_' + i} className="text-left inline-block w-full ev-lg:w-[78%]">
              <opt.element cb={goToNextPage} inWizard={true} closeWizard={closeWizard} history={history} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
