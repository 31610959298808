import React from 'react';
import { Route } from 'react-router-dom';
import Loading from '../shared/loader/Loading';
import LoginPage from '../pages/LoginPage/LoginPage';
import NoTeam from '../pages/NoTeamPage/NoTeam';
import { onLoggedIn } from '../utils/profileHelper';

export const generateMultipleRoutes = (
  user,
  teams,
  team,
  toggleMenu,
  loading,
  paths,
  options,
  useExactRoutes,
  allowAnonymous
) => {
  const notLoggedInRender = () => <LoginPage onLoggedIn={onLoggedIn} />;
  const noTeamRender = () => <NoTeam paths={paths} toggleMenu={toggleMenu} reloadTeams={onLoggedIn} />;
  const loadingRender = () => <Loading text="Loading team..." />;
  return options.map((option, i) => {
    if (!option) return <></>;
    let render;
    if (loading) {
      render = loadingRender;
    } else if (user && teams.length < 1) {
      render = noTeamRender;
    } else if ((user && team) || allowAnonymous) {
      render = option.render; // successRender;
    } else {
      render = notLoggedInRender;
    }
    return <Route exact={useExactRoutes === true} key={i} path={option.path} render={render} />;
  });
};

export const generateRoutes = (
  user,
  teams,
  team,
  toggleMenu,
  loading,
  paths,
  successRender,
  useExactRoutes,
  allowAnonymous,
  allPaths
) => {
  const notLoggedInRender = () => <LoginPage onLoggedIn={onLoggedIn} />;
  const noTeamRender = () => <NoTeam paths={allPaths} toggleMenu={toggleMenu} reloadTeams={onLoggedIn} />;
  const loadingRender = () => <Loading text="Loading team..." />;
  return paths.map((path, i) => {
    let render;
    if (loading) {
      render = loadingRender;
    } else if (user && teams.length < 1) {
      render = noTeamRender;
    } else if ((user && team) || allowAnonymous) {
      render = successRender;
    } else {
      render = notLoggedInRender;
    }
    return <Route exact={useExactRoutes === true} key={i} path={path} render={render} />;
  });
};
