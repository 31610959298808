import actionTypes from '../../../redux/actions/action-types';
import initialState from '../../../redux/reducers/initial-state';

const companyThemeEditorPageReducer = (state = initialState.companyThemeEditorPage, action) => {
  switch (action.type) {
    case actionTypes.companyThemeEditor.set:
      return {
        ...state,
        ...action.data
      };
    case actionTypes.companyThemeEditor.toggleDiscardModal:
      return {
        ...state,
        showDiscardModal: action.data
      };
    case actionTypes.companyThemeEditor.resetThemePageSettings:
      return initialState.companyThemeEditorPage;
  }
  return state;
};

export default companyThemeEditorPageReducer;
