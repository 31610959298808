import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import CopyInput from 'shared/inputs/CopyInput';
import BinIcon from 'static/icons/bin.svg';
import { deleteCMSLicenseAction } from '../actions/settingsPage';
import Modal from 'shared/modal/Modal';
import PrimaryButton from 'shared/buttons/PrimaryButton';

const CMSSettingsForm = ({ generateLicense, generatingLicense, checkPermission, cmsLicenses }) => {
  const [showModal, setShowModal] = useState(false);
  const [licenseId, setLicenseId] = useState();
  const dispatch = useDispatch();

  const onShowModal = (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    setLicenseId(id);
    setShowModal(true);

    return false;
  };

  const deleteLicense = () => {
    dispatch(
      deleteCMSLicenseAction({
        id: licenseId
      })
    );

    setLicenseId();
    setShowModal(false);
  };

  return (
    <>
      <Modal
        title={'Delete license key'}
        width={'w-5/12 max-w-xl'}
        bg={'bg-ev-yellow-1'}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <p>Are you sure you want to delete this license key?</p>
        <hr />
        <div className="pt-6 flex gap-2">
          <PrimaryButton onClick={deleteLicense} text="Confirm" />
          <PrimaryButton onClick={() => setShowModal(false)} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
        </div>
      </Modal>

      <form className="table-container">
        <div className="ml-4">
          Link everviz with your CMS for easier access to your published charts.
          <br />
          <a href="https://help.everviz.com/article/892-everviz-wordpress-plugin">
            Read more about our wordpress plugin here.
          </a>
        </div>
        <div className="table-btn-container flex flex-col gap-2 w-full ev-sm:w-2/4 ev-lg:w-1/4 ">
          {checkPermission('team_management') && (
            <input
              type="submit"
              disabled={generatingLicense}
              value={generatingLicense ? 'Generating...' : 'Generate license'}
              className="bulk-action bulk-action-btn inline"
              onClick={generateLicense}
            />
          )}
          <div className="flex gap-2 flex-col">
            {cmsLicenses.map((license, i) => {
              return (
                <div key={`license_${i}`} className="w-full flex gap-x-2">
                  <CopyInput value={license.token} tabIndex={-1} className={'w-full'} />
                  <SvgIconButton
                    width={16}
                    height={16}
                    onClick={(e) => onShowModal(e, license.id)}
                    buttonClasses="p-1 rounded ml-2"
                    buttonColor={ButtonColor.Grey}
                    Icon={BinIcon}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </>
  );
};

export default CMSSettingsForm;
