import { connect } from 'react-redux';
import ApiPage from '../ApiPage';

import {
  getTeamKeysAction,
  deleteKeysAction,
  updateKeyAction,
  setTeamKeyAction,
  setAction
} from '../actions/apiPage.js';
import { checkPermission, loadGroups, checkPlanPermission } from '../../../utils/profileHelper';

const mapStateToProps = (state) => {
  return {
    groupId: state.apiPage.groupId || (state.profile.groups[0] ? state.profile.groups[0].id : undefined), //.groupId,
    name: state.apiPage.name,
    groups: state.profile.groups,
    group: state.apiPage.groupId || state.profile.groups[0],
    page: state.apiPage.page,
    pageSize: state.apiPage.pageSize,
    pageCount: state.apiPage.pageCount,
    keys: state.apiPage.keys,
    error: state.apiPage.error,
    dataReturned: state.apiPage.dataReturned,
    paths: state.app.paths || {},
    team: state.profile.team,
    division: state.profile.division,
    errorMessage: '',
    loadGroups: loadGroups,
    checkPermission: (permission) => checkPermission(permission, state.profile),
    checkPlanPermission: (permission) => checkPlanPermission(permission, state.profile)
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTeamKeys: (team, page, pageSize) => dispatch(getTeamKeysAction({ team, page, pageSize })),
  updateKey: (team, row, page, pageSize) => dispatch(updateKeyAction({ team, row, page, pageSize })),
  deleteKeys: (team, keys, page, pageSize) => dispatch(deleteKeysAction({ team, keys, page, pageSize })),
  setTeamKey: (team, groupId, name, page, pageSize) =>
    dispatch(setTeamKeyAction({ team, groupId, name, page, pageSize })),
  set: (params) => dispatch(setAction(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiPage);
