import { createReducer } from '@reduxjs/toolkit';
import actionTypes from '../../../redux/actions/action-types';
import initialState from '../../../redux/reducers/initial-state';

const chartEditorReducer = createReducer(initialState.chartEditorPage, (builder) => {
  builder
    .addCase(actionTypes.chartEditor.set, (state, action) => {
      Object.assign(state, action.data);
    })
    .addCase(actionTypes.chartEditor.closeCMSModal, (state) => {
      state.closeCMSModal = false;
    })
    .addCase(actionTypes.chartEditor.showGeoJSONModal, (state, action) => {
      Object.assign(state, action.data);
      state.showGeoJSONModal = true;
    })
    .addCase(actionTypes.chartEditor.hideGeoJSONModal, (state) => {
      state.showGeoJSONModal = false;
    })
    .addCase(actionTypes.chartEditor.closePublishModal, (state) => {
      state.showPublishModal = false;
    })
    .addCase(actionTypes.chartEditor.openPublishModal, (state) => {
      state.showPublishModal = true;
    })
    .addCase(actionTypes.chartEditor.resetState, () => {
      return initialState.chartEditorPage;
    });
});

export default chartEditorReducer;
