/*

    en Language Pack for the Highcharts Editor

    This file was generated by tools/gen.localization.js from
    en.json, Thu Mar 15 2018 10:04:15 GMT+0100 (CET)

*/

//Install "en" translations
//highed.installLanguage({
export default {
  language: 'en',
  entries: {
    'option.text.title': 'Title',
    'option.text.subtitle': 'Subtitle',
    'option.text.caption': 'Caption',
    'option.text.canvas': 'Canvas',
    'option.text.layout': 'Layout specific',
    'option.text.logo': 'Logo',

    'option.text.title.text': 'Title',
    'option.text.title.style.font': 'Text font',
    'option.text.title.font.style': 'Text style',
    'option.text.title.style.textAlign': 'Position',
    'option.text.title.style.backgroundColor': 'Background color',
    'option.text.title.style.background': 'Background image URL',
    'option.text.title.enabled': 'Enabled',
    'option.text.subtitle.text': 'Subtitle',
    'option.text.subtitle.style.font': 'Text font',
    'option.text.subtitle.font.style': 'Text style',
    'option.text.subtitle.style.textAlign': 'Position',
    'option.text.subtitle.style.backgroundColor': 'Background color',
    'option.text.subtitle.enabled': 'Enabled',
    'option.text.caption.text': 'Caption',
    'option.text.caption.style.font': 'Text font',
    'option.text.caption.font.style': 'Text style',
    'option.text.caption.style.textAlign': 'Position',
    'option.text.caption.style.backgroundColor': 'Background color',
    'option.text.caption.enabled': 'Enabled',
    'option.text.canvas.style.padding': 'Padding (px)',
    'option.text.canvas.style.backgroundColor': 'Background Color',
    'option.text.canvas.style.background': 'Background image URL',

    'option.text.layout.visualizations': 'Visualizations',
    'option.text.layout.buttonPosition': 'Page button positions',
    'option.text.layout.style.color': 'Icon color',
    'option.text.layout.style.backgroundColor': 'Button color',
    'option.text.layout.auto': 'Auto',
    'option.text.layout.slidercolor': 'Slider color',
    'option.text.layout.animDuration': 'Duration Length (Secs)',
    'option.text.layout.animation': 'Animation',

    'option.text.logo.url': 'Image',
    'option.text.logo.position': 'Position',
    'option.text.logo.width': 'Width (%)',
    'option.text.logo.minWidth': 'Min width (px)',
    'option.text.logo.style.padding': 'Padding (px)'
  }
};

//});
