import React from 'react';
import PropTypes from 'prop-types';
import TeamWebhooksForm from './components/TeamWebhooksForm';
import Table from '../../shared/table/Table';
import Modal from 'shared/modal/Modal';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import TextButton from 'shared/buttons/TextButton';
import { goToPage } from 'utils/profileHelper';

class TeamWebhooksPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.saveWebhook = this.saveWebhook.bind(this);
    this.saveWebhookRow = this.saveWebhookRow.bind(this);
    this.openModal = this.openModal.bind(this);
    this.loadWebhooks = this.loadWebhooks.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onFormChange = this.onFormChange.bind(this);

    this.columns = [
      {
        id: 'title',
        title: 'Title'
      },
      {
        id: 'url',
        title: 'URL'
      },
      {
        id: 'type',
        title: 'Type',
        type: 'select'
      }
    ];
  }

  componentDidMount() {
    this.props.getSupportedWebhooks();
    this.loadWebhooks();
  }

  componentDidUpdate(prevProps) {
    const isDifferentTeam = this.props.team && (!prevProps.team || prevProps.team.id !== this.props.team.id);
    if (isDifferentTeam) {
      this.loadWebhooks();
    }
  }

  loadWebhooks() {
    this.props.getWebhooks(this.props.team);
  }

  saveWebhook() {
    this.props.setWebhook(this.props.team, this.props.form);
  }

  saveWebhookRow(row) {
    this.props.updateWebhook(this.props.team, row);
  }

  onFormChange(value, name) {
    let form = this.props.form;
    form[name] = value.val;
    return this.props.set({ form });
  }

  openModal() {
    this.props.set({ modalIsOpen: true });
  }

  closeModal() {
    this.props.set({ modalIsOpen: false });
  }

  render() {
    let { webhooks, supported } = this.props;
    const parsedSupported = (supported ?? []).map((type) => ({ label: type.name, value: type.id }));
    const hasPermission = this.props.checkPlanPermission('webhooks') || false;

    const editableColumns = {
      url: {},
      title: {},
      type: {
        validValues: parsedSupported
      }
    };

    const bulkActions = [
      {
        text: 'Delete selected',
        className: ' danger',
        hasPermission,
        onClick: (webhooks) => {
          this.props.deleteWebhooks(this.props.team, webhooks);
        }
      },
      {
        text: 'Add webhook',
        hasPermission,
        bypassCheck: true,
        className: 'ml-2',
        onClick: () => {
          this.openModal();
        }
      }
    ];

    return (
      <ProfilePageContainer
        title="Webhooks"
        header={<>Webhooks</>}
        description={
          <>
            Webhooks are triggered by specific events. Whenever that trigger event occurs in everviz, we will send the
            relevant data to the URL specified by you.{' '}
            <TextButton
              onClick={() => goToPage('https://help.everviz.com/article/819-webhooks-settings')}
              text="More information about this page can be found in our knowledge base."
            />
          </>
        }
      >
        <ProfilePageSectionContainer>
          <Modal
            isOpen={this.props.modalIsOpen}
            onClose={this.closeModal}
            title="Add webhook"
            width={'w-7/12 max-w-xl'}
            bg={'bg-ev-yellow-1'}
            bodyClass={'px-8 py-6'}
          >
            <TeamWebhooksForm
              onSave={this.saveWebhook}
              onClose={this.closeModal}
              onFormChange={this.onFormChange}
              form={this.props.form}
              types={parsedSupported}
            />
          </Modal>

          <Table
            onRowSave={this.saveWebhookRow}
            editableColumns={editableColumns}
            columns={this.columns}
            rows={webhooks}
            dataReturned={true}
            bulkActions={bulkActions}
            hideLoading={true}
            hasEditPermission={hasPermission}
            isActionCell={true}
          />
        </ProfilePageSectionContainer>
      </ProfilePageContainer>
    );
  }
}

TeamWebhooksPage.propTypes = {
  team: PropTypes.object.isRequired,
  paths: PropTypes.object.isRequired
};

export default TeamWebhooksPage;
