import React from 'react';
import { getTeamChartWithTeamidAndChartid } from '../../api/cloudApiGenerated';
import MetricContainer from './components/MetricContainer';
import evervizTheme from './../../editor/meta/highed.meta.everviz.theme';
import { merge } from '../../editor/core/highcharts-editor';

var clickIcon = '/static/images/analytic/cursor-default-click.png';
var hoverIcon = '/static/images/analytic/hover-chart.png';
var visibilityIcon = '/static/images/analytic/eye.png';

const chartUrl = `https://app-api-cube-dev.everviz.com/all?chart_id={chartId}`;

function msToTime(duration) {
  var milliseconds = Math.floor(duration % 1000),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  var time = '';
  if (hours > 0) time += hours + 'h ';
  if (minutes > 0) time += minutes + 'm ';

  return time + seconds + 's';
}

class Analytics extends React.Component {
  constructor(props, context) {
    super(props, context);

    const params = props.match.params;
    const chartId = params.chartid && parseInt(params.chartid, 10);
    this.state = {
      chartId,
      team: this.props.team,
      chartInfo: [{}],
    };
  }

  //Overwrite function (remove analytic on chart)
  componentDidUpdate() {
    const { chartInfo } = this.state;
    if (chartInfo) {
      const script = document.createElement('script');
      script.src = 'https://app.everviz.com/inject/' + chartInfo.uuid;
      script.defer = true;
      setTimeout(() => {
        window['everviz'].analytics.clearAnalytics();
      }, 1000);
      document.head.appendChild(script);
    }
  }

  async componentDidMount() {
    const data = await fetch(chartUrl.replace(`{chartId}`, this.state.chartId)).then(function (res) {
      return res.json();
    });

    const chartInfo = await getTeamChartWithTeamidAndChartid(this.props.team.id, this.state.chartId);

    data.clicks = data.clicks.filter((click) => click != null);
    data.hovers = data.hovers.filter((hover) => hover != null);
    data.visibility = data.visibility.filter((vis) => vis != null);

    const clicks = data.clicks.map((click) => click?.value);
    const hovers = data.hovers.map((hover) => hover?.value);
    const visibility = data.visibility.map((vis) => vis?.value);

    this.setState({
      clicks,
      hovers,
      visibility,
      sumClicks: clicks.reduce((partialSum, a) => partialSum + a, 0),
      sumHovers: hovers.reduce((partialSum, a) => partialSum + a, 0),
      sumVisibility: msToTime(visibility.reduce((partialSum, a) => partialSum + a, 0)),
      chartInfo,
    });

    var metricArray = ['clicks', 'hovers', 'visibility'];

    for (let i in metricArray) {
      let metrics = metricArray[i];

      let parsedValue = [];
      let parsedCategories = [];
      let metricData = data[metrics];

      metricData.forEach((previousPoint, i) => {
        const nextPoint = metricData[i + 1];
        const previousDate = new Date(previousPoint.date);

        parsedCategories.push(previousDate.toString().slice(4, 16));
        parsedValue.push(previousPoint.value);

        if (!nextPoint) return;

        const nextDate = new Date(nextPoint.date);

        var difference = Math.abs(nextDate - previousDate);
        const days = difference / (1000 * 3600 * 24);

        if (days <= 1) return;

        for (let x = 0; x < days - 1; x++) {
          var result = previousDate.setDate(previousDate.getDate() + 1);

          parsedCategories.push(new Date(result).toString().slice(4, 16));
          parsedValue.push(0);
        }
      });

      this.createChart(metrics, parsedValue, parsedCategories);
      // data[metrics].map((metric) => metric.value),
      // data[metrics].map((metric) => {
      // var d = new Date(metric.date);
      // return d.toString().slice(0, 16); //Slices time (00:00:00) and timezone
      // })
    }
  }

  createChart(name, value, categories) {
    var textName = name;
    if (textName == 'visibility') {
      textName = 'viewing time (in ms)';
    }
    new Highcharts.chart(
      `highchart-container-${name}`,
      merge(evervizTheme, {
        title: {
          text: `Total ${textName} for each day`,
        },
        chart: {
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        stockTools: {
          gui: {
            enabled: false,
          },
        },
        xAxis: {
          categories,
          type: 'datetime',
          ordinal: false,
        },
        series: [
          {
            name,
            data: value,
          },
        ],
      })
    );
  }

  getDateFromDateStr(str) {
    let date = new Date(str);
    const [month, day, year] = [date.toLocaleString('default', { month: 'long' }), date.getDate(), date.getFullYear()];
    return { month, day, year };
  }

  render() {
    const { sumClicks, sumHovers, sumVisibility, chartInfo } = this.state;
    const { month, day, year } = this.getDateFromDateStr(chartInfo.creation_time);
    return (
      <>
        <div className="flex-col space-y-4 p-4 max-w-screen-2xl mx-auto">
          <div className="title-container">
            <h2 class="pl-0">Analytics dashboard</h2>
          </div>
          <div className="flex justify-between bg-[#FFFFFF] rounded-md p-2 pl-9 border-solid border">
            <div className="space-y-8">
              <div>
                <h3>Project name: </h3>
                <p className="text-xl">{chartInfo.name}</p>
              </div>
              <div>
                <h3>Date of creation: </h3>
                <p className="text-xl">
                  {month} {day} {year}
                </p>
              </div>
              <div>
                <h3>Published by: </h3>
                <p className="text-xl">{chartInfo.username}</p>
              </div>
            </div>

            <div className="">
              <div id={`everviz-${chartInfo.uuid}`} className={`everviz-${chartInfo.uuid}`}>
                <script src={`https://app.everviz.com/inject/everviz-${chartInfo.uuid}/`} defer="defer"></script>
              </div>
            </div>
          </div>
          <MetricContainer title="Total number of clicks" icon={clickIcon} sumMetric={sumClicks} metric="clicks" />
          <MetricContainer title="Total number of hovers" icon={hoverIcon} sumMetric={sumHovers} metric="hovers" />
          <MetricContainer
            title="Total viewing time"
            icon={visibilityIcon}
            sumMetric={sumVisibility}
            metric="visibility"
          />
        </div>
      </>
    );
  }
}

export default Analytics;
