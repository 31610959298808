import classNames from 'classnames';
import * as React from 'react';
import { DivMouseHandler } from 'shared/types/commonPropTypes';

const PageDimmer = ({ onClick, className }: { onClick: DivMouseHandler; className?: string }) => {
  const classes = classNames(
    'fixed w-full h-full bg-dimmed top-0 left-0',
    {
      'opacity-70 z-[1299]': !className
    },
    className
  );

  return <div className={classes} onClick={onClick} />;
};

export default PageDimmer;
