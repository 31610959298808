import { EvervizChart } from 'shared/types/chart';

export const removeHighchartsStyling = (chart: EvervizChart | undefined) => {
  const editorContainer = document.querySelector('.hc-editor');
  if (editorContainer instanceof HTMLElement) {
    editorContainer.style.removeProperty('height');
    editorContainer.style.removeProperty('overflow');
    editorContainer.style.removeProperty('display');
  }

  if (chart?.container) {
    const container = chart.container;
    container.style.overflow = 'auto';
  }
};
