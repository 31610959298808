import React, { useState } from 'react';

export default (props) => {
  const { inputType, value } = props;
  // eslint-disable-next-line wrap-regex
  let bgUrl = /^url\((['"]?)(.*)\1\)$/.exec(value);
  bgUrl = bgUrl ? bgUrl[2] : '';

  const [actualValue, setActualValue] = useState(bgUrl);

  const onChange = (e) => {
    let val = e.target.value,
      urlVal = 'url(' + val + ')';
    setActualValue(val);
    props.onChange({ val: urlVal });
  };

  return (
    <div className={`${props.label ? 'mt-2' : ''} ${props.className}`}>
      {props.label && <div className="text-ev-navy-blue font-bold mb-2">{props.label}</div>}
      <input className="highed-field-input" type={inputType} value={actualValue} onChange={onChange} />
    </div>
  );
};
